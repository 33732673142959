import React, { Component } from 'react'
import { Container, Tabs, Tab, } from 'react-bootstrap'
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import CRMFilter from '../../components/CRMFilter/CRMFilter';
import { filterVOD, selectedTabHandler } from '../productPage/VodUtils'
import VODCustomCarousel from "../productPage/VODCustomCarousel"
import { allProductsActions } from './ducks'
import productTypeIDs from './ducks/productTypeIDs'
import Card from '../../components/CardComponent/Card'
import "./styles/OnDemandContentPage.scss"
import DummyVod from './../../components/DummyComponent/DummyVod';
import { getLoggedInStatus, getConnectionId, purchaseStatusValidation, getProductPrice, sortTabOrderVods, isDiscounted, discountType } from '../../util/ArrayValidator';
import { PAYMENT_SUCCESS, PRODUCT_COUNT, INCREASE_PRODUCT_COUNT_TIMEOUT } from '../../util/Constants';

class AllVodPage extends Component {
    constructor() {
        super();
        this.state = {
            selectedlanguageIndex: undefined,
            selectedGenreIndex: undefined,
            tabContent: [],
            tabActiveKey: undefined,
            connectionDetails: undefined,
            allUserVODs: {
                allUserVODs: null,
            },
            allVODs: {
                allVODs: null,
            },
            selectedTab: null,
            selectedTrendingTab: null,
            VODSubType: null,
            VODLanguages: null,
            VODGenre: null,
            keyGenreFilter: false,
            keyLanguageFilter: false,
            genreKey: null,
            languageKey: null,
            selectedTabName: "#DOCUMENTARY",
            loading: true,
            vodIndex: PRODUCT_COUNT
        };
    }

    componentDidMount() {
        const isLoggedIn = getLoggedInStatus();
        const connectionId = getConnectionId();
        window.addEventListener('scroll', this.scrollEventListner, false)
        if (this.props.location.hash !== "") {
            var selectedTabNameIndex = this.props.location.hash

            this.setState({
                selectedTabName: selectedTabNameIndex
            })
        }
        if (isLoggedIn) {
            //After login VODs
            if (!!connectionId) {
                this.props.getAllProducts({
                    conid: localStorage.conID,
                    subTypeId: productTypeIDs.VOD_ID
                });
            }
            else {
                this.props.history.push('/')
            }
        }
        else {
            this.props.getAllProducts(productTypeIDs.VOD_ID);
        }
        // this.props.getOnDemandSubTypes(productTypeIDs.VOD_ID)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.VODSubType && nextProps.VODSubType !== prevState.VODSubType) {
            return ({
                VODSubType: nextProps.VODSubType
            })
        }
        if (getLoggedInStatus() && (nextProps.allUserVODs && nextProps.allUserVODs.allUserVODs !== prevState.allUserVODs.allUserVODs)) {
            return ({
                allVods: nextProps.allUserVODs.allVods
            })
        }
        if (getLoggedInStatus() === false && (nextProps.allVODs && nextProps.allVODs.allVODs !== prevState.allVODs.allVODs)) {
            return ({
                allVods: nextProps.allVODs.allVods
            })
        }
    }

    componentWillUnmount() {
        this.props.resetForm()
        window.removeEventListener('scroll', this.scrollEventListner, false)
        this.setState({
            // selectedTab: null,
            // selectedTrendingTab: null,
            // allVODs: null,
            // unique_content_genre: null
            selectedTab: null,
            selectedTrendingTab: null,
            allVODs: null,
            VODTV: null,
            VODMovies: null,
            VODEducational: null,
            VODDocumentary: null,
            VODMusic: null,
            TrendingVODTV: null,
            TrendingVODMovies: null,
            TrendingVODEducational: null,
            TrendingVODDocumentary: null,
            TrendingVODMusic: null,
            unique_content_genre: null
        })
    }

    SampleNextArrow(props) {
        const { nextSlide } = props;
        return (
            <div onClick={nextSlide}>
                <img src={require("../../images/icons/next-white.svg")} className="vod-next-img" alt="nextSlide" />
            </div>
        );
    }

    SamplePrevArrow(props) {
        const { previousSlide } = props;
        return (
            <div onClick={previousSlide} >
                <img src={require("../../images/icons/back-white.svg")} className="vod-prev-img" alt="prevSlide" />
            </div>
        );
    }

    onSelect = (key, e) => {
        this.props.history.push(`/ondemand/${key}`)
        this.setState({
            languageKey: null,
            genreKey: null,
        })
        var selectedTabHandlerResponse = selectedTabHandler(this.state, key)
        if (selectedTabHandlerResponse) {
            this.setState({
                selectedTab: selectedTabHandlerResponse.selectedTab,
                selectedTrendingTab: selectedTabHandlerResponse.selectedTrendingTab,
                unique_content_language: selectedTabHandlerResponse.unique_content_language,
                unique_content_genre: selectedTabHandlerResponse.unique_content_genre,
                vodIndex: PRODUCT_COUNT
            })
        }
    }

    handleChangeLanguage = async (e) => {
        let hash = this.props.location.hash
        var key = e.target.value
        await this.setState({
            languageKey: key
        })
        var filteredVOD = filterVOD(this.state, hash)
        this.setState({
            selectedTab: filteredVOD.filterTab,
            selectedTrendingTab: filteredVOD.filterTrendingTab,
            keyGenreFilter: filteredVOD.keyGenreFilter,
            vodIndex: PRODUCT_COUNT
        })
    }

    handleChangeGenre = async (e) => {
        let hash = this.props.location.hash
        var genreKey = e.target.value
        await this.setState({
            genreKey: genreKey,
        })
        var filteredVOD = filterVOD(this.state, hash)
        this.setState({
            selectedTab: filteredVOD.filterTab,
            selectedTrendingTab: filteredVOD.filterTrendingTab,
            keyGenreFilter: filteredVOD.keyGenreFilter,
            vodIndex: PRODUCT_COUNT
        })
    }

    onClick = (e) => {
        this.props.history.push(`/products/${e.target.id}`)
    }

    selectedTabHandler = () => {
        let hash = this.props.location.hash
        var tabTypeName = hash.substr(1);
        var selectedTanHandlerResponse
        //Check whether the hash is exists as a type in vod array
        if (this.state.allVods.some(x => x.typeName === tabTypeName)) {
            selectedTanHandlerResponse = selectedTabHandler(this.state, hash)
        }

        if (selectedTanHandlerResponse) {
            this.setState({
                selectedTab: selectedTanHandlerResponse.selectedTab,
                selectedTrendingTab: selectedTanHandlerResponse.selectedTrendingTab,
                unique_content_language: selectedTanHandlerResponse.unique_content_language,
                unique_content_genre: selectedTanHandlerResponse.unique_content_genre,
                loading: selectedTanHandlerResponse.loading
            })
        }
    }
    scrollEventListner = () => {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            const { selectedTab, vodIndex } = this.state
            if (vodIndex <= (selectedTab && selectedTab.length)) {
                setTimeout(() => {
                    this.loadMore()
                },
                    INCREASE_PRODUCT_COUNT_TIMEOUT
                );

            }
        }
    }
    loadMore = () => {
        const { selectedTab, vodIndex } = this.state
        if (selectedTab && selectedTab.length >= this.state.vodIndex) {
            this.setState({
                vodIndex: vodIndex + PRODUCT_COUNT
            })
        }
    }

    render() {

        let isLoggedIn = (getLoggedInStatus())
        const { selectedTab, selectedTrendingTab, allVods, loading, vodIndex } = this.state;
        let readyVodSubType = allVods && Array.isArray(allVods)
        loading && readyVodSubType && this.selectedTabHandler()

        let isEmptyTabContent = this.state.selectedTab && this.state.selectedTab && Array.isArray(this.state.selectedTab) && this.state.selectedTab.length === 0
        let alteredSubTypes;

        if (readyVodSubType) {
            alteredSubTypes = sortTabOrderVods(allVods)
        }
        let hash = this.props.location.hash
        let tabName = hash.substring(1)
        //Check whether the hash is exists as a type in vod array
        let validateTabName = readyVodSubType && alteredSubTypes && alteredSubTypes.some(el => el.typeName === tabName);

        let tabIndex = validateTabName ? hash : alteredSubTypes && `#${alteredSubTypes[0].typeName}`

        // If hash is not available redirects to the first tab
        if (readyVodSubType && !validateTabName && alteredSubTypes) {
            this.onSelect(alteredSubTypes && tabIndex)
        }

        return (
            <>
                <div className="on-demand-title-container">
                    <Container className="on-demand-title">
                        <h4>ON DEMAND</h4>
                    </Container>
                </div>
                <Container>
                    {readyVodSubType && alteredSubTypes ?
                        <Tabs
                            className="on-demand-tabs"
                            defaultActiveKey={tabIndex}
                            transition={false}
                            id="noanim-tab-example"
                            onSelect={this.onSelect}
                        >
                            {
                                alteredSubTypes.map((v, index) => {
                                    if (v != null && (v.displayName === "Educational" || v.displayName === "Movies" || v.displayName === "Tele Films" || v.displayName === "Music" || v.displayName === "Handmade" || v.displayName === "Sinhala Tele Drama" || v.displayName === "Helawood Popcorn" || v.displayName === "Highlights" || v.displayName === "PEO Originals" || v.displayName === "PEO Tunes" || v.displayName === "Dishes" || v.displayName === "Short Films" || v.displayName === "Premiere Movies")) {
                                        return (
                                            <Tab
                                                // key={index}
                                                // eventKey={`#${index}`}
                                                key={v.typeName}
                                                eventKey={`#${v.typeName}`}
                                                title={v.displayName}
                                                // activeKey={v.id}
                                                activeKey={v.typeName}
                                            >
                                                <div className="row">
                                                    <div className="col-12">
                                                        <CRMFilter
                                                            type="LANGUAGE"
                                                            showFilter={v.displayName === "Premiere Movies" ? false : true}
                                                            handleFilterChange={this.handleChangeLanguage}
                                                            readyFiltersArray={v.languages}
                                                            filtersArray={v.languages}
                                                            label={"Language"}
                                                            selected={this.state.languageKey === null}
                                                        />
                                                        <CRMFilter
                                                            type="GENRE"
                                                            showFilter={v.displayName === "Premiere Movies" ? false : !(v.genre.length === 0 || v.genre.length === 1)}
                                                            handleFilterChange={this.handleChangeGenre}
                                                            readyFiltersArray={v.genre}
                                                            filtersArray={v.genre}
                                                            label={"Genre"}
                                                            selected={this.state.genreKey === null}
                                                        />
                                                    </div>
                                                </div>
                                                <br />
                                                <span className="trending-label">
                                                    Trending {v.displayName}
                                                </span>
                                                <div >
                                                    <Container>
                                                        <div className="carousel-container">
                                                            {
                                                                selectedTrendingTab && selectedTrendingTab
                                                                    ?
                                                                    <VODCustomCarousel
                                                                        selectedTrendingTab={selectedTrendingTab}
                                                                        SamplePrevArrow={this.SamplePrevArrow}
                                                                        SampleNextArrow={this.SampleNextArrow}
                                                                        onClick={this.onClick}
                                                                        isLoggedIn={isLoggedIn}
                                                                    // showPrice={isLoggedIn && price}

                                                                    />
                                                                    :
                                                                    <div >
                                                                        No Products
                                                                        </div>
                                                            }
                                                        </div>
                                                    </Container>
                                                    <hr className="hr-line" />
                                                    <div className={isEmptyTabContent ? "emptyTab tab-container" : "tab-container"}>
                                                        {
                                                            selectedTab && selectedTab.slice(0, vodIndex).map((o, i) => {
                                                                if (o.status === "PRODUCT_STATUS_ACTIVE") {
                                                                    let purchaseTypeStatus = purchaseStatusValidation(o && o.purchaseStatus);
                                                                    let price = o && o.purchaseOption && o.purchaseOption.defaultPrice;
                                                                    let discount = discountType(o)
                                                                    return (
                                                                        <div className="display-inline-block">
                                                                            {o.id !== undefined ?
                                                                                <Card
                                                                                    key={index}
                                                                                    id={o.id}
                                                                                    isLoggedIn={isLoggedIn}
                                                                                    name={o.name}
                                                                                    imageURL={o.imageURL}
                                                                                    price={getProductPrice(o)}
                                                                                    isPurchased={(purchaseTypeStatus.productPurchased || purchaseTypeStatus.productPendingRemove) || o.paymentStatus === PAYMENT_SUCCESS}
                                                                                    onClick={this.onClick}
                                                                                    showAddNew={true}
                                                                                    svod={o.daysRemainingToExpire}
                                                                                    hover={true}
                                                                                    onDemand={true}
                                                                                    showPrice={isLoggedIn && price}
                                                                                    type="onDemand"
                                                                                    isDummy={false}
                                                                                    isBundled={o.isBundled}
                                                                                    hasDiscount={isDiscounted(o)}
                                                                                    discount={discount}
                                                                                />
                                                                                : null
                                                                            }
                                                                        </div>
                                                                    )
                                                                }
                                                                return null
                                                            })
                                                        }
                                                        {
                                                            isEmptyTabContent ?
                                                                <div className="tab-empty-container">
                                                                    There is no product available
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                            </Tab>
                                        )
                                    }
                                    return null
                                })
                            }
                        </Tabs>
                        :
                        <DummyVod defaultActiveKey={tabIndex} />
                    }
                </Container>
            </>
        )
    }
}
const mapStateToProps = (state, props) => {
    return {
        allUserVODs: state.AllProducts.allUserVODs,
        allVODs: state.AllProducts.allVODs,
        VODSubType: state.AllProducts.VODSubType,
        allVods: state.AllProducts.allVods
    }
};
export default withRouter(connect(
    mapStateToProps,
    allProductsActions
)(AllVodPage));
        //871
