// Redux-login service

import { createLogic } from 'redux-logic';
import { getConnectionId, getLoggedInStatus, getServiceType } from '../../../util/ArrayValidator';

import actions from './actions';
import types from './types';
import endPoints from './../../../util/EndPoints';
import API from './../../../util/HTTPClient';
import history from '../../../_helpers/history';
import axios from 'axios';

import { MOBITEL_SERVICE_TYPE } from "./../../../util/Constants";
import allProductsActions from '../../AllProducts/ducks/actions';

export default [
  createLogic({

    type: types.GET_PRODUCT_TYPES, // only apply this logic to this type
    latest: true, // only take latest
    debounce: 1000,  // Wait 1 s before triggering another call

    // your code here, hook into one or more of these execution
    // phases: validate, transform, and/or process
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }

      HTTPClient.Get(endPoints.PRODUCT_TYPES)
        .then(res => res.data)
        .then(data => dispatch(actions.getProductTypesSuccess(data)))
        .catch(err => dispatch(actions.getProductTypesFailed(err)))
        .then(() => done()); // call done when finished dispatching
    }
  }),
  createLogic({
    type: types.GET_FEATURED_CHANNELS, // only apply this logic to this type
    latest: true, // only take latest
    debounce: 6000,  // Wait 1 s before triggering another call

    // your code here, hook into one or more of these execution
    // phases: validate, transform, and/or process
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }
      dispatch(actions.getFeaturedChannelsLoading())
      if (window.location.pathname === "/") {
        if (getLoggedInStatus() === false) {
          HTTPClient.Post(endPoints.PRODUCTS_BY_TYPES_AND_TAGS, {
            productTypes: ["channel","channelbundle"],
            tags: ["featured"]
          })
            .then(res => res.data)
            .then(data => dispatch(actions.getFeaturedChannelsSuccess(data)))
            .catch(err => dispatch(actions.getFeaturedChannelsFailed(err)))
            .then(() => done()); // call done when finished dispatching
        }
        else {
          HTTPClient.Get(`${endPoints.GET_FEATURED_ALLOCATED_PRODUCTS}?conid=${action.payload.connectionId}&tagIds=${action.payload.tagId}&typeIds=${action.payload.typeId[0]}&typeIds=${action.payload.typeId[1]}`)
            .then(res => res.data)
            .then(data => dispatch(actions.getFeaturedChannelsSuccess(data)))
            .catch(err => dispatch(actions.getFeaturedChannelsFailed(err)))
            .then(() => done()); // call done when finished dispatching
        }

      }
    }
  }),

  createLogic({
    type: types.GET_FEATURED_PACKAGES, // only apply this logic to this type
    latest: true, // only take latest
    debounce: 2000,  // Wait 1 s before triggering another call

    // your code here, hook into one or more of these execution
    // phases: validate, transform, and/or process
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }
      dispatch(actions.getFeaturedPackagesLoading())
      HTTPClient.Post(endPoints.PRODUCT_TYPE_AND_TAGS, {
        productType: "package",
        tags: ["featured"]
      })
        .then(res => res.data)
        .then(data => dispatch(actions.getFeaturedPackagesSuccess(data)))
        .catch(err => dispatch(actions.getFeaturedPackagesFailed(err)))
        .then(() => done()); // call done when finished dispatching
    }
  }),

  createLogic({
    type: types.GET_SECONDARY_FEATURED_PACKAGES, // only apply this logic to this type
    latest: true, // only take latest
    debounce: 2000,  // Wait 1 s before triggering another call

    // your code here, hook into one or more of these execution
    // phases: validate, transform, and/or process
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }
      dispatch(actions.getFeaturedPackages())
      HTTPClient.Post(endPoints.PRODUCT_ID_LIST, action.payload)//change endoint
        .then(res => res.data)
        .then(data => dispatch(actions.getFeaturedPackagesSuccess(data)))
        .catch(err => dispatch(actions.getFeaturedPackagesFailed(err)))
        .then(() => done()); // call done when finished dispatching
    }
  }),

  createLogic({
    type: types.GET_ON_DEMAND_CONTENT, // only apply this logic to this type
    latest: true, // only take latest
    debounce: 8000,  // Wait before triggering another call

    // your code here, hook into one or more of these execution
    // phases: validate, transform, and/or process
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }
      dispatch(actions.getOnDemandContentsLoading())
      if (window.location.pathname === "/") {
        if (getLoggedInStatus() === false) {
          HTTPClient.Post(endPoints.PRODUCT_TYPE_AND_TAGS, {
            productType: "vod",
            tags: ["featured"]
          })
            .then(res => res.data)
            .then(data => dispatch(actions.getOnDemandContentsSuccess(data)))
            .catch(err => dispatch(actions.getOnDemandContentsFailed(err)))
            .then(() => done()); // call done when finished dispatching
        }
        else {
          HTTPClient.Get(`${endPoints.GET_FEATURED_ALLOCATED_PRODUCTS}?conid=${action.payload.connectionId}&tagIds=${action.payload.tagId}&typeIds=${action.payload.typeId}`)
            .then(res => res.data)
            .then(data => dispatch(actions.getOnDemandContentsSuccess(data)))
            .catch(err => dispatch(actions.getOnDemandContentsFailed(err)))
            .then(() => done()); // call done when finished dispatching
        }
      }
    }
  }),

  createLogic({
    type: types.GET_FEATURED_SVOD, // only apply this logic to this type
    latest: true, // only take latest
    debounce: 3000,  // Wait 1 s before triggering another call

    // your code here, hook into one or more of these execution
    // phases: validate, transform, and/or process
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }
      dispatch(actions.getFeaturedSvodsLoading())
      if (window.location.pathname === "/") {
        if (getLoggedInStatus() === false) {
          HTTPClient.Post(endPoints.PRODUCT_TYPE_AND_TAGS, {
            productType: "svod",
            tags: ["featured"]
          })
            .then(res => res.data)
            .then(data => dispatch(actions.getFeaturedSvodsSuccess(data)))
            .catch(err => dispatch(actions.getFeaturedSvodsFailed(err)))
            .then(() => done()); // call done when finished dispatching
        }
        else {
          HTTPClient.Get(`${endPoints.GET_FEATURED_ALLOCATED_PRODUCTS}?conid=${action.payload.connectionId}&tagIds=${action.payload.tagId}&typeIds=${action.payload.typeId}`)
            .then(res => res.data)
            .then(data => dispatch(actions.getFeaturedSvodsSuccess(data)))
            .catch(err => dispatch(actions.getFeaturedSvodsSuccess(err)))
            .then(() => done()); // call done when finished dispatching
        }
      }
    }
  }),

  createLogic({

    type: types.GET_BANNER, // only apply this logic to this type
    latest: true, // only take latest
    debounce: 1000, // Wait 1 s before triggering another call
    cancelType: types.GET_ALL_PACKAGES,

    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }
      if (window.location.pathname === "/") {
        if (getLoggedInStatus()) {
          // HTTPClient.Get(endPoints.GET_BANNER, { params: {connectionType: parseInt(action.payload)} })
          HTTPClient.Get(`${endPoints.GET_BANNER}?basePackageId=${action.payload}`)
            .then(res => res.data)
            .then(data => {
              dispatch(actions.getBannerContentSuccess(data));
              dispatch(actions.getBannerProducts(data))
            })
            .catch(err => dispatch(actions.getBannerContentFailed(err)))
            .then(() => done()); // call done when finished dispatching

        } else {
          HTTPClient.Get(endPoints.GET_BANNER)
            .then(res => res.data)
            .then(data => {
              dispatch(actions.getBannerContentSuccess(data))
              dispatch(actions.getBannersLoadingBeforeLogin())
            })
            .catch(err => dispatch(actions.getBannerContentFailed(err)))
            .then(() => done()); // call done when finished dispatching
        }

      }



    }
  }),

  //after login

  createLogic({

    type: types.GET_USER_DETAILS, // only apply this logic to this type
    latest: true, // only take latest
    debounce: 1000, // Wait 1 s before triggering another call

    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }
      // const data ={"username": "jinaliw@gmail.com", "name": "Jinali", "accountId": 2,"email": "jinaliw@gmail.com"}

      // dispatch(actions.getUserDetailsSuccess(data))
      // return
      // debugger
      HTTPClient.Get(endPoints.GET_USER_DETAILS)
        .then(res => {
          return res.data
        })
        .then(data => {
          localStorage.setItem("userId", data.accountId)
          dispatch(actions.getUserDetailsSuccess(data))
        })
        .catch(err => {
          dispatch(actions.getUserDetailsFailed(err))
        })
        .then(() => done()); // call done when finished dispatching
    }
  }),
  createLogic({


    type: types.GET_CONNECTION_DETAILS, // only apply this logic to this type
    latest: true, // only take latest
    debounce: 1000, // Wait 1 s before triggering another call

    async process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }

      await HTTPClient.Post(endPoints.GET_CONNECTIONS)
        //   .then(token => { 
        //     var decodedToken = jwtDecode(token.token);
        //     localStorage.setItem("jwt", decodedToken.exp);
        //     var user = {
        //         userName: "admin",
        //         roleName: "admin",
        //         name: "Super",
        //         roleID: 1
        //     };
        //     localStorage.setItem("user", JSON.stringify(user));
        //     HTTPClient.setAuth(); // Set token for subsequent calls
        //     return token;
        // })
        .then(res => res.data)
        .then(data => {
          // localStorage.setItem("jwtsfdf", JSON.stringify(data));
          // localStorage.setItem('conID', data[0].connectionId);
          // localStorage.setItem('selectedUserIndex', "0");
          if (!localStorage.conID) {
            localStorage.setItem('conID', data[0].connectionId);
            localStorage.setItem('selectedUserIndex', "0");
            localStorage.setItem('serviceType', data[0].serviceType)
            localStorage.setItem('isBroadBand', data[0].broadband)
            localStorage.setItem('connectionStatus', data[0].status)
            localStorage.setItem('userTelephoneNumber', data[0].telephoneNumber)
            history.push('/')
          }
          if (localStorage.conID) {
            let selectedUserIndex = localStorage.selectedUserIndex
            localStorage.setItem('connectionStatus', data[selectedUserIndex].status)
          }



          dispatch(actions.getConnectionDetailsSuccess(data))

        })
        .catch(err => {
          dispatch(actions.getConnectionDetailsFailed(err))
          // if(!localStorage.conID){
          // dispatch(loginAction.loginFailed("Something went wrong, please contact your service provider"))

          // TO DO 
          // IF connection Details Fail -> Logout
          // Review to be
          dispatch(actions.logout())

          // }
        })
        .then(() => done()); // call done when finished dispatching
    }
  }),

  createLogic({

    type: types.GET_CONNECTION_CONTENT, // only apply this logic to this type
    latest: true, // only take latest
    debounce: 1000, // Wait 1 s before triggering another call

    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }


      // const data = [
      //   { "connectionId": 1, "accountNumber": "00010012012", "telephoneNumber": "0112729729", "serviceType": "Peo TV" },
      //   { "connectionId": 2, "accountNumber": "000100222eeee", "telephoneNumber": "01129099090", "serviceType": "Meaga-Line" }
      // ]

      // dispatch(actions.getConnectionContentSuccess(data))
      // return

      HTTPClient.Get(`${endPoints.GET_CONNECTION_CONTENT}/${action.payload}`)
        .then(res => res.data)
        .then(data => {
          dispatch(actions.getConnectionContentSuccess(data))
        })
        .catch(err => dispatch(actions.getConnectionContentFailed(err)))
        .then(() => done()); // call done when finished dispatching
    }
  }),

  createLogic({

    type: types.GET_FEATURED_CHANNELS_PACKAGE, // only apply this logic to this type
    latest: true, // only take latest
    debounce: 1000, // Wait 1 s before triggering another call

    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }
      //debugger

      // dispatch(actions.getFeaturedPackageChannelSuccess(datas))
      // return

      HTTPClient.Get(`${endPoints.GET_FEATURED_ALLOCATED_PRODUCTS}?conid=${action.payload.connectionId}&tagIds=${action.payload.tagId}&typeIds=${action.payload.typeId}`)
        .then(res => {
          return res.data
        })
        .then(data => dispatch(actions.getFeaturedPackageChannelSuccess(data)))
        .catch(err => dispatch(actions.getFeaturedPackageChannelFailed(err)))
        .then(() => done()); // call done when finished dispatching
    }
  }),
  createLogic({

    type: types.GET_FEATURED_VOD_PACKAGE, // only apply this logic to this type
    latest: true, // only take latest
    debounce: 6000, // Wait 1 s before triggering another call

    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }
      //debugger

      // dispatch(actions.getFeaturedPackageVodSuccess(datas))
      // return

      HTTPClient.Get(`${endPoints.GET_FEATURED_ALLOCATED_PRODUCTS}?conid=${action.payload.connectionId}&tagIds=${action.payload.tagId}&typeIds=${action.payload.typeId}`)
        .then(res => {
          // debugger
          return res.data
        })
        .then(data => dispatch(actions.getFeaturedPackageVodSuccess(data)))
        .catch(err => dispatch(actions.getFeaturedPackageVodFailed(err)))
        .then(() => done()); // call done when finished dispatching
    }
  }),
  createLogic({

    type: types.GET_FEATURED_SVOD_PACKAGE, // only apply this logic to this type
    latest: true, // only take latest
    debounce: 1000, // Wait 1 s before triggering another call

    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }

      // dispatch(actions.getFeaturedPackageSvodSuccess(datas))
      // return

      HTTPClient.Get(`${endPoints.GET_FEATURED_ALLOCATED_PRODUCTS}?conid=${action.payload.connectionId}&tagIds=${action.payload.tagId}&typeIds=${action.payload.typeId}`)
        .then(res => {
          return res.data
        })
        .then(data => dispatch(actions.getFeaturedPackageSvodSuccess(data)))
        .catch(err => dispatch(actions.getFeaturedPackageSvodFailed(err)))
        .then(() => done()); // call done when finished dispatching
    }
  }),

  createLogic({

    type: types.GET_PURCHASED_PRIMARY_PACKAGE, // only apply this logic to this type
    latest: true, // only take latest
    debounce: 1000, // Wait 1 s before triggering another call

    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }
      //debugger

      // dispatch(actions.getPurchasedPrimaryPackageSuccess(datas))
      // return

      HTTPClient.Get(`${endPoints.GET_PURCHASED_PACKAGE}?conid=${action.payload.connectionId}&typeId=${action.payload.typeId}`)
        .then(res => {
          return res.data
        })
        .then(data => dispatch(actions.getPurchasedPrimaryPackageSuccess(data)))
        .catch(err => dispatch(actions.getPurchasedPrimaryPackageFailed(err)))
        .then(() => done()); // call done when finished dispatching
    }
  }),

  createLogic({
    type: types.GET_ONDEMAND_SUB_TYPES,
    latest: true,
    debounce: 1000,
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }
      HTTPClient.Get(`${endPoints.GET_PARENT_TYPES}/${action.payload}${endPoints.GET_SUB_TYPES}`)
        .then(res => res.data)
        .then(data => dispatch(actions.getOnDemandSubTypesSuccess(data)))
        .catch(err => dispatch(actions.getOnDemandSubTypesFailed(err)))
        .then(() => done()); // call done when finished dispatching
    }
  }),
  createLogic({
    type: types.GET_ALLOWED_SECONDARY_PACKAGES,
    latest: true,
    debounce: getLoggedInStatus() ? 1000 : 3000,

    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }
      // var userType = JSON.parse(localStorage.getItem("@connectionID"))

      // dispatch(actions.fetchSecondaryProducts())
      // return
      if (getLoggedInStatus() === false) {
        HTTPClient.Post(`${endPoints.PRODUCT_ID_LIST}`, action.payload)
          .then(resp => resp.data)
          .then((data) => {
            dispatch(actions.getAllowedSecondaryPackagesSuccess(data))

          })
          .catch(err => {
            // Log error
            dispatch(actions.getAllowedSecondaryPackagesFailed("Something went wrong, please contact your service provider"))
          })
          .then(() => done());
      }
      else {
        let endPoint
        if (localStorage.connectionStatus === "suspended") {
          endPoint = endPoints.GET_REACTIVE_PACKAGE
        } else {
          endPoint = endPoints.PURCHASED_PACKAGE
        }

        HTTPClient.Get(`${endPoint}?conid=${action.payload.connectionId}&packagetypeid=${action.payload.typeId}`)
          .then(resp => resp.data)
          .then((data) => {
            if(localStorage.connectionStatus === "suspended"){
              dispatch(actions.getPackagePurchaseStatus())
            }
            let purchaseOptionGroupId = data[0] && data[0].purchaseOptionGroupId
            dispatch(actions.getPackagePurchaseTable(purchaseOptionGroupId))
            localStorage.setItem('basepackage', data[0].id)
            localStorage.setItem("basepackageEndDate", data[0].billingEndDate)
            localStorage.setItem("basepackagePurchaseType", data[0].purchaseOption.purchaseType)
            dispatch(actions.getAllowedSecondaryPackagesSuccess(data))
            dispatch(actions.getBannerContent(data[0].id))
            // debugger
          })
          .catch(err => {
            // Log error
            dispatch(actions.getAllowedSecondaryPackagesFailed("Something went wrong, please contact your service provider"))
          })
          .then(() => done());
      }
    }
  }),
  createLogic({
    type: types.GET_SECONDARY_ID,
    latest: true,
    debounce: 1000,

    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }


      // dispatch(actions.fetchSecondaryProducts())
      // return
      if (getLoggedInStatus() === false) {
        HTTPClient.Get(`${endPoints.FETCH_PRODUCTS_ID}`)
          .then(resp => resp.data)
          .then((data) => {
            dispatch(actions.getSecondaryIdSuccess(data))

          })
          .catch(err => {
            // Log error
            dispatch(actions.getSecondaryIdSuccess("Something went wrong, please contact your service provider"))
          })
          .then(() => done()); // call done when finished dispatching
      }

      else {
        HTTPClient.Get(`${endPoints.FETCH_PRIMARY_PRODUCTS_ID}${action.payload}`)
          .then(resp => resp.data)
          .then((data) => {
            dispatch(actions.getSecondaryIdSuccess(data))

          })
          .catch(err => {
            dispatch(actions.getSecondaryIdFailed("Something went wrong, please contact your service provider"))
          })
          .then(() => done()); // call done when finished dispatching
      }
    }

  }),

  // createLogic({

  //   type: types.GET_SELECTED_PRODUCT, // only apply this logic to this type
  //   latest: true, // only take latest
  //   debounce: 1000, // Wait 1 s before triggering another call

  //   process({ MockHTTPClient, getState, action }, dispatch, done) {
  //     let HTTPClient
  //     if (MockHTTPClient) {
  //       HTTPClient = MockHTTPClient
  //     } else {
  //       HTTPClient = API
  //     }
  //     //debugger

  //     // dispatch(actions.getFeaturedPackageChannelSuccess(datas))
  //     // return

  //     HTTPClient.Get(`${endPoints.PURCHASED_PRODUCTS}?${action.payload.id}?conid=${action.payload.connectionId}`)
  //       .then(res => {
  //         // return res.data
  //         return console.log("landingPage : GET_SELECTED_PRODUCT : res : ",res.data)

  //       })
  //       // .then(data => dispatch(actions.getFeaturedPackageChannelSuccess(data)))
  //       // .catch(err => dispatch(actions.getFeaturedPackageChannelFailed(err)))
  //       // .then(() => done()); // call done when finished dispatching
  //   }
  // }),

  createLogic({
    type: types.LOGOUT, // only apply this logic to this type
    latest: true, // only take latest
    debounce: 1000,  // Wait 1 s before triggering another call

    // your code here, hook into one or more of these execution
    // phases: validate, transform, and/or process
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }
      if (getLoggedInStatus() === false) {
        HTTPClient.Post(endPoints.LOGOUT)
          .then(res => res.data)
          .then(data => {
            dispatch(actions.logoutSuccess(data))
            localStorage.clear()
            history.push('/login')
          }

          )
          .catch(err => dispatch(actions.logoutFailed(err)))
          .then(() => done()); // call done when finished dispatching
      }
      else {
        HTTPClient.Post(endPoints.LOGOUT)
          .then(res => res.data)
          .then(data => {
            dispatch(actions.logoutSuccess(data))
            dispatch(allProductsActions.resetPackages())
            localStorage.clear()
            history.push('/login')
          }

          )
          .catch(err => dispatch(actions.logoutFailed(err)))
          .then(() => done()); // call done when finished dispatching
      }
    }
  }),

  //data bundles
  createLogic({
    type: types.GET_PURCHASED_PRIMARY_DATA_BUNDLE, // only apply this logic to this type
    latest: true, // only take latest
    debounce: 1000,  // Wait 1 s before triggering another call

    // your code here, hook into one or more of these execution
    // phases: validate, transform, and/or process
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }
      HTTPClient.Get(`${endPoints.GET_DATA_BUNDLE}?conId=${action.payload.connectionId}`)
        .then(res => res.data)
        .then(data => {
          dispatch(actions.getPurchasedPrimaryDataBundleSuccess(data))
        })
        .catch(err => dispatch(actions.getPurchasedPrimaryDataBundleFailed(err)))
        .then(() => done()); // call done when finished dispatching
    }
  }),

  createLogic({
    type: types.UNSUB_CONNECTION,
    latest: true,
    debounce: 1000,
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      function delay(t, v) {
        return new Promise(function (resolve) {
          setTimeout(resolve.bind(null, v), t)
        });
      }
      let serviceType = localStorage.getItem('serviceType')
      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }
      let endPoint
      if (serviceType === "Prepaid" || serviceType === "Mobitel") {
        endPoint = endPoints.UNSUB_PREPAID_CONNECTION

      } else {
        endPoint = endPoints.UNSUB_CONNECTION
      }
      HTTPClient.Post(`${endPoint}/${action.payload}`)
        .then(res => res.data)
        .then(async data => {
          dispatch(actions.unsubConnectionSuccess(data));
          await delay(3000);
          dispatch(actions.userUnsubLoading());
        })
        // .then(data => {
        //   dispatch(actions.unsubConnectionSuccess(data))
        //   data.length == 0 ? actions.logout()
        //   }
        .catch(err => dispatch(actions.unsubConnectionFailed(err && err.response && err.response.data && err.response.data.message && err.response.data.message) || "Something went wrong, please contact your service provider"))
        .then(() => done()); // call done when finished dispatching
    }
  }),

  //This will provide the selected index of the dropdowns(connection lists)
  createLogic({
    type: types.GET_SELECTED_CONNECTION_INDEX,
    latest: true,
    debounce: 1000,
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      //debugger;
      dispatch(actions.getSelectedConnectionIndexSuccess(action.payload))
    }
  }),

  createLogic({
    type: types.PASSWORD_UPDATE,
    latest: true,
    debounce: 1000,

    process({
      MockHTTPClient,
      getState,
      action
    }, dispatch, done) {


      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }

      // let params = action.payload
      // let data = `?typeid=${params}` 
      let obj = {
        "oldPassword": action.payload.oldPassword,
        "password": action.payload.newPassword,
        "token": localStorage.jwt,
        "username": action.payload.userData.username
      }
      HTTPClient.Post(endPoints.PASSWORD_UPDATE, obj)
        .then(resp => resp.data)
        .then(data => {
          // debugger
          return data
        })
        .then(data => {
          dispatch(actions.passwordUpdateSuccess({
            title: "Success!",
            // message: errorMessage,
            // responseErr: err
            responseErr: "Your password has been changed"
          }))
          dispatch(actions.logout())
        }
        )
        .catch(err => {
          var errorMessage = "Failed to get products";
          if (err && err.code === "ECONNABORTED") {
            errorMessage = "Please check your internet connection.";
          }
          dispatch(actions.passwordUpdateFail({
            title: "Error!",
            message: errorMessage,
            // responseErr: err
            responseErr: "Current password is missed match."
          }))
        })
        .then(() => done());
    }
  }),
  createLogic({
    type: types.REMOVE_PROFILE,
    latest: true,
    debounce: 1000,

    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }
      HTTPClient.Delete(`${endPoints.REMOVE_PROFILE}/${action.payload}`)
        .then(resp => resp.data)
        .then((data) => {
          dispatch(actions.removeProfileSuccess(data))
        })
        .catch(err => {
          // Log error
          dispatch(actions.removeProfileFailed(err.response.data.message || "Something went wrong, please contact your service provider"))
        })
        .then(() => done()); // call done when finished dispatching
    }
  }),

  createLogic({
    type: types.REACTIVATE_PRODUCT, // only apply this logic to this type
    latest: true, // only take latest
    debounce: 1000,  // Wait 1 s before triggering another call

    // your code here, hook into one or more of these execution
    // phases: validate, transform, and/or process
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }
      HTTPClient.Post(`${endPoints.REACTIVATE_PRODUCT}`, action.payload)
        .then(res => res.data)
        .then(data => {
          if (data.status === "SUCCESS") {
            localStorage.setItem('connectionStatus', "active")
          }
          if (data.status === "PENDING") {
            dispatch(actions.getPackagePurchaseStatusSuccess(data))
          }
          dispatch(actions.reactivateProductSuccess(data))
        })
        .catch(err => dispatch(actions.reactivateProductFailed((err && err.response && err.response.data && err.response.data.message) || "Something went wrong, please contact your service provider")))
        .then(() => done()); // call done when finished dispatching
    }
  }),

  createLogic({
    type: types.GET_BANNER_TYPE_PRODUCTS,
    latest: true,
    debounce: 1000,
    process({
      MockHTTPClient,
      getState,
      action
    }, dispatch, done) {
      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }

      let promises = [];
      action.payload.map((product, index) => {
        if (product.productId) {
          promises.push(
            // HTTPClient.Get(`${endPoints.PURCHASED_PRODUCTS}${product.productId}?conid=${getConnectionId()}`)
            HTTPClient.Get(`${endPoints.GET_PRODUCT}${product.productId}`)
              .then(resp => {
                let passObj = {
                  id: index,
                  data: resp.data.productTypeTypeName
                }
                return passObj;
              })
          )
          // HTTPClient.Get(`${endPoints.GET_PRODUCT}${product.productId}`)
          // .then(resp => console.log(resp.data))
        }
        return promises
      })

      axios.all(promises)
        .then(data => {
          dispatch(actions.getBannerProductsSuccess(data))
        })
        .catch(err => dispatch(actions.getBannerProductsFailed(err)))
        .then(() => done()); // call done when finished dispatching
    }
  }),

  createLogic({
    type: types.GET_PACKAGE_PURCHASE_TABLE,
    latest: true,
    debounce: 1000,

    process({
      MockHTTPClient,
      getState,
      action
    }, dispatch, done) {
      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }
      HTTPClient.Get(endPoints.GET_PURCHASE_TABLE + action.payload)
        .then(resp => resp.data)
        .then(data => {
          return data;
        })
        .then(purchaseTable => {
          dispatch(actions.getPackagePurchaseTableSuccess(purchaseTable))
        })
        .catch(err => {
          var errorMessage = "Failed to get prerequisites";
          if (err && err.code === "ECONNABORTED") {
            errorMessage = "Please check your internet connection.";
          }
          dispatch(
            actions.getPackagePurchaseTableFailed({
              title: "Error!",
              message: errorMessage,
              respErr: err.message
            })
          );
        })
        .then(() => done());
    }
  }),
  createLogic({
    type: types.GET_PACKAGE_STATUS,
    latest: true,
    debounce: 1000,

    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }
      let connectionTypeName = getServiceType() === MOBITEL_SERVICE_TYPE ? "mobitel" : "prepaid"
      let connectionId = getConnectionId()
      HTTPClient.Get(`${endPoints.CHECK_PACKAGE_PROVISION_STATUS}?connectionId=${connectionId}&connectionType=${connectionTypeName}`)
        .then(resp => resp.data)
        .then(async data => {
          if (localStorage.connectionStatus === "suspended") {
            if (data.status === "FAILURE") {
              dispatch(actions.getPackagePurchaseStatusSuccess(data))
              history.push('/provisioning-failed')
            }
             else {
              dispatch(actions.getConnectionContent(getConnectionId()))
              dispatch(actions.getConnectionDetails({ connectionId: getConnectionId() }))
              dispatch(actions.getPackagePurchaseStatusSuccess(data))
            }
          }

        })
        .catch(err => {
          // Log error
          err.response && err.response.data && dispatch(actions.getPackagePurchaseStatusFailed(err.response.data.message || "Something went wrong, please contact your service provider"));
          // dispatch(actions.verifyProductPurchaseFailed());
        })
        .then(() => done()); // call done when finished dispatching
    }
  }),
  createLogic({
    type: types.VERIFY_PREPAID_PAYMENT,
    latest: true,
    debounce: 1000,

    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }
      function delay(t, v) {
        return new Promise(function (resolve) {
          setTimeout(resolve.bind(null, v), t)
        });
      }
      HTTPClient.Get(`${endPoints.HAS_PAID}${action.payload.transactionId}${endPoints.PAID}`)
        .then(resp => resp.data)
        .then(async data => {
          let connId = localStorage.conID;
          localStorage.setItem('connectionStatus', "active")
          dispatch(actions.reset())
          dispatch(actions.verifyPrepaidPaymentSuccess("Reactivated Successfully"))
          await delay(3000);
          return dispatch(actions.productActions.getUserDetails()),
            dispatch(actions.getConnectionContent(connId)),
            dispatch(actions.getPackagePurchaseStatus())
        })
        .catch(err => {
          // Log error
          err.response && err.response.data && dispatch(actions.verifyPrepaidPaymentFailed(err.response.data.message || "Something went wrong, please contact your service provider"));
          // dispatch(actions.verifyProductPurchaseFailed());
        })
        .then(() => done()); // call done when finished dispatching
    }
  }),
]

