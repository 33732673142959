import React from 'react';
import './DataBundleCard.scss'

function DataBundle({ purchased, name, price, imageURL, data, isDummy, hasDiscount }) {
    return (
        <div className="col data-bundle-card-container">
            <span className="data-bundle-card-check-mark">{hasDiscount}</span>
            {purchased &&
                <i className="fa fa-check-square data-bundle-card-check-mark"></i>
            }
            <img src={isDummy ? require("../../assets/images/data_bundlePlaceHolder.png") : `/${imageURL}`} className="data-bundle-card-image" alt="" />
            <div className="data-bundle-card-label-box" >
                <span className="data-bundle-card-gb-label" >
                    {isDummy ?
                        ` Loading...`
                        :
                        `${name}`}
                </span>
                <hr className="data-bundle-card-hr" />
                {isDummy ?
                    null
                    :
                    !isNaN(price) &&<span className="data-bundle-card-price-container">
                        <span className="data-bundle-card-price">Rs.</span>
                        {price}
                    </span>
                }
            </div>
        </div>
    )
}

export default DataBundle
//64
