import React from 'react'
import { Row } from 'react-bootstrap';
import { getLoggedInStatus } from '../../util/ArrayValidator';
import FreeIcon from '../../images/icons/free-icon-resized.svg';

function SVODCard({ name, isPurchased, imageURL, id, onClick, isDummy, isBundled, hasDiscount, discount, price, showPrice }) {

    let showFreeIcon;
    if (getLoggedInStatus() && isBundled) {
        showFreeIcon = <img src={FreeIcon} alt="Free-Logo" className={`free-image-icon-svod`} />
    }

    let showDiscountedIcon;
    if (getLoggedInStatus && !isBundled) {
        if (hasDiscount) {
            showDiscountedIcon = <div class="discount-div-bg">
                <div class="red-bg-container">
                    <span>{discount}</span>
                </div>
            </div>
        }
    }
    let priceView;
    if(showPrice === false){
        priceView = ""
    }else{
        if (getLoggedInStatus() && (!isPurchased || isPurchased == null) && (!isBundled)) {
            price = price || 0
            priceView = (<h6 id={id} className="price"> Rs. {price}</h6>)
        }
    }

    return (
        <>
            {/* <div id='svod-card'>
                <h6 id='svod-name'>{name}</h6>
                <Row className='svod-container'>
                    {getLoggedInStatus() && isPurchased &&
                        <i class="fas fa-check-square check-icon"></i>}
                    {showFreeIcon}
                    {showDiscountedIcon}
                    <img
                        className="svod-image"
                        src={isDummy ? require("./../../assets/images/svod-banner-img.png") : `/${imageURL}`}
                        id={id}
                        onClick={onClick}
                        alt=''
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = require("./../../assets/images/svod-banner-img.png")
                        }}
                    />
                </Row>
            </div> */}

            <div id='svod-card'>                
                <Row>
                    <div className="outside-svod-catalog-card">
                        <div className="svod-catalog-card-content">
                            {getLoggedInStatus() && isPurchased &&
                                <i class="fas fa-check-square check-icon"></i>}
                            {showFreeIcon}
                            {showDiscountedIcon}
                                <img
                                    className="svod-image"
                                    src={isDummy ? require("./../../assets/images/svod-banner-img.jpg") : `/${imageURL}`}
                                    id={id}
                                    onClick={onClick}
                                    alt=''
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = require("./../../assets/images/svod-banner-img.jpg")
                                    }}
                                />
                            <div className="inside-svod-catalog-card" id={id} onClick={onClick}>
                                <h6 className="svod-name" id={id} >{name}</h6>
                                <h6 id={id} className="price">{priceView}</h6>
                            </div>
                        </div>
                    </div>
                </Row>
            </div>

        </>
    )
}

export default SVODCard
