import React from 'react'

export default function () {
    return (
        <>
            <div className="container content-box page-confirm-header" style={{ marginTop: 90, padding: "10px 35px" }}>
                <div className="row align-items-center" style={{ marginTop: 20 }}>
                    <div style={{ marginTop: 10, marginBottom: 40 }}>

                        <div className="row">
                            <div className="col">
                                <div className="terms-content scrollbar-info">
                                    <div className="row">
                                        <div className="col">
                                            <div className="terms-and-conditions">
                                                <h6>PEOTV TERMS OF USE</h6>
                                            </div>
                                        </div>
                                    </div>

                                    <p><b>Welcome to PEOTV!</b></p>
                                    <p>Thank You for using our products and services. </p>
                                    <p>
                                        The products and services are provided by Sri Lanka Telecom PLC, a company duly incorporated under the Conversion of Public Corporations and Government Owned Business Undertakings Into Public Companies Act No. 23 of 1987 and by order published in the Gazette (Extraordinary) No. 942/7 dated 25th September 1996 and re-registered under the Companies Act No. 7 of 2007, bearing registration No. PQ 7, and having its registered office at Lotus Road, Colombo 01, Sri Lanka (<b>“PEOTV”</b>)
            </p>
                                    <p><b>Effective April 01, 2019</b></p>

                                    <p><b>DESCRIPTION OF SERVICE AND ACCEPTANCE OF TERMS OF USE</b></p>

                                    <p>PEOTV <span style={{ fontSize: 12 }}><i>inter alia</i></span> provides an IPTV service, app and website as an interactive platform for its viewers and any associated mobile sites, applications ("Service") to inter alia view channels, music, serials and programs, films, sports and other entertainment content including viewing schedules of content offered etc. on PEOTV and any other features, tools, applications, materials, or other services offered from time to time by PEOTV in connection with its business, provided on the Service
            </p>

                                    <p>
                                        The Service can be availed through platforms, software and devices, which PEOTV approves from time to time. In some cases, whether a device is (or remains) compatible may depend on software or platform provided or maintained by the device manufacturer or other third parties. As a result, devices that are compatible at one time may cease to be compatible in the future.
            </p>

                                    <p>
                                        This is an electronic record in terms of the Electronic Transactions Act No.19 of 2006 as amended, and rules and regulations thereunder as applicable and various other statutes.
            </p>

                                    <p>
                                        This is a legal and binding agreement between You, the user (“User”) of the Service, as defined below, and Sri Lanka Telecom PLC stating the terms that govern Your use of the Service. By accepting these Terms of Use in any manner or accessing the app or accessing the device or accessing this website, You consent, agree and undertake to abide, be bound by and adhere to the Terms of Use and the Privacy Policy accessible at <a href="https://www.peotv.com/terms-of-use" style={{ textDecoration: 'underline' }}>www.peotv.com/terms-of-use</a> and <a href="https://www.peotv.com/privacy-policy/" style={{ textDecoration: 'underline' }}> www.peotv.com/privacy-policy</a> respectively and any other terms which govern the Service (together referred to as "Terms of Use") and if You do not agree to these Terms of Use, You are not entitled to avail of / use the Service and any use thereafter shall be unauthorized.
            </p>

                                    <p><b>USE OF THE SERVICE IS SUBJECT TO COMPLIANCE WITH THESE TERMS OF USE</b></p>

                                    <p>
                                        Unless otherwise specified, the Service is available for individuals who have attained the age of majority in Sri Lanka. If You are under the relevant age of majority in Your jurisdiction, You may access the Service under the supervision of an adult and in accordance with applicable law which enables a minor in Your jurisdiction to access such a service.
            </p>

                                    <p>
                                        Some content offered on the Service may not be suitable for some viewers and therefore viewer discretion is advised. Also, some content offered on the Service may not be appropriate for viewership by children. Parents and/or legal guardians are advised to exercise discretion before allowing their children and/or wards to access content on this website. Your access to and use of the Service is subject to these Terms of Use, Privacy Policy and all applicable laws, rules, and regulations.
            </p>

                                    <p><b>ACCESS AND USE OF THE SERVICE </b></p>

                                    <p style={{ textDecoration: 'underline' }}>Limited use:</p>

                                    <p>
                                        You are given a limited, non-exclusive, non-transferable, non-sub-licensable, revocable permission to use the Service during the subsistence of Your Account with PEOTV in respect of specified content and as set forth in these Terms of Use and no right, title or interest in any content will be deemed transferred to You.
            </p>

                                    <p style={{ textDecoration: 'underline' }}>By agreeing to use/avail the Service:</p>

                                    <ul>
                                        <li>
                                            <p>You confirm and warrant that all the data and information supplied by You when You register (if applicable) and provided by You to PEOTV and or any of PEOTV’s affiliates, distributors ("Registration Data") is true, current, complete and accurate in all respects.</p>
                                        </li>
                                        <li>
                                            <p>You agree to promptly update Your Registration Data, so that Your information remains true, current, complete, and accurate at all times.</p>
                                        </li>
                                        <li>
                                            <p>You agree that Your Registration Data and any other personal data collected by PEOTV is subject to PEOTV's Privacy Policy. </p>
                                        </li>
                                        <li>
                                            <p>You agree that certain other data may be collected / recorded from Your device via the Service and third parties engaged by PEOTV, in accordance with the Terms of Use and for the purposes specified in PEOTV’s Privacy Policy.</p>
                                        </li>
                                        <li>
                                            <p>You acknowledge, consent and agree that PEOTV may access, preserve, transfer and disclose Your information subject to the Privacy Policy.</p>
                                        </li>
                                        <li>
                                            <p>You agree that PEOTV has the right to temporarily suspend access to the whole or any part of the Service for any technical/operational reason and shall be under no liability to You in such an event. PEOTV may, but shall not be obliged to, give You as much notice of any interruption of access to the Service as is reasonably practicable. </p>
                                        </li>
                                        <li>
                                            <p>You agree that PEOTV shall be under no liability whatsoever to You in the event of non-availability of the Service or any portion thereof occasioned by an Act of God, war, disease, revolution, riot, civil commotion, strike, lockout, flood, fire, satellite failure, failure of any public utility, man-made disaster, satellite failure or any other cause whatsoever beyond the control of PEOTV.</p>
                                        </li>
                                        <li>
                                            <p>You agree not to either directly or through the use of any device, software, internet site, web-based service, or other means remove, alter, bypass, avoid, interfere with, violate, or circumvent any patent; trade secret; copyright, trademark, or other proprietary notice marked on the content or any digital rights management mechanism or device; any content protection or access control measure associated with the content, including geo-filtering mechanisms; privacy; publicity; or other proprietary right under applicable law.</p>
                                        </li>
                                        <li>
                                            <p>You agree not to either directly or through the use of any device, software, internet site, web-based service, or other means copy, download, capture, reproduce, duplicate, archive, distribute, upload, publish, modify, translate, broadcast, perform, display, sell, transmit or retransmit the content or create any work or material that is derived from or based on the content. This prohibition applies regardless of whether such derivative works or materials are sold, bartered, or given away.</p>
                                        </li>
                                        <li>
                                            <p>You agree that PEOTV and/or its affiliates or licensors owns and/or retains all rights to the Service in perpetuity. You further agree that the content You access and view as part of the Service is owned or controlled by PEOTV, its affiliates and or its licensors. The Service is protected by copyright, trademark, design, patent, trade secret, and other intellectual property laws.</p>
                                        </li>
                                        <li>
                                            <p>You agree that third parties and/or its affiliates or licensors owns and/or retains all rights to the third party content. The third party content is protected by copyright, trademark, design, patent, trade secret, and other intellectual property laws.</p>
                                        </li>
                                        <li>
                                            <p>Except as provided in these Terms of Use, You may not copy, download, stream capture, reproduce, duplicate, archive, upload, modify, translate, publish, broadcast, transmit, retransmit, distribute, perform, display, sell, frame or deep-link, make available, or otherwise use any content contained in the Service. You further agree not to use the Service to: </p>
                                            <ul >
                                                <li>
                                                    <p>
                                                        Post, upload or otherwise transmit or link to content that is: unlawful; threatening; abusive; obscene; vulgar; sexually explicit;
                        pornographic or inclusive of nudity; offensive; excessively violent; invasive of another's privacy, publicity, contract or other rights; tortious; false or misleading; defamatory; libelous; hateful; or discriminatory;
                            </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        Harass or harm another person;
                            </p>
                                                </li>
                                                <li>
                                                    <p>Exploit or endanger a minor;</p>
                                                </li>
                                                <li>
                                                    <p>Impersonate or attempt to impersonate any person or entity;</p>
                                                </li>
                                                <li>
                                                    <p>Violate any patent, trademark, trade secret, copyright, privacy, publicity or other proprietary right;</p>
                                                </li>
                                                <li>
                                                    <p>Introduce or engage in activity that involves the use of viruses, software disabling codes, bots, worms, or any other computer code, files or programs that interrupt, destroy, or limit the functionality of any computer software or hardware or telecommunications equipment, or otherwise permit the unauthorized use of or access to a computer or a computer network;</p>
                                                </li>
                                                <li>
                                                    <p>Attempt to decipher, decompile, disassemble or reverse engineer any of the software, applications and/or any element comprising the Service, the application for accessing the content;</p>
                                                </li>
                                                <li>
                                                    <p>Interfere with, damage, disable, disrupt, impair, create an undue burden on, or gain unauthorized access to the Service, including PEOTV servers, networks or accounts;</p>
                                                </li>
                                                <li>
                                                    <p>Cover, remove, disable, manipulate, block or obscure advertisements or other portions of the Service;</p>
                                                </li>
                                                <li>
                                                    <p>Delete or revise any information provided by or pertaining to any other user of the Service.</p>
                                                </li>
                                                <li>
                                                    <p>Use technology or any automated system in order to collect or disseminate usernames, passwords, email addresses or other data from the Service, or to circumvent, delete, deactivate, decompile, reverse engineer, disassemble or modify any security technology or software that is part of the Service;</p>
                                                </li>
                                                <li>
                                                    <p>Send or cause to send unsolicited bulk messages or other unsolicited bulk communications of any kind through the Service. </p>
                                                </li>
                                                <li>
                                                    <p>Solicit, collect or request any personal information for commercial or unlawful purposes;</p>
                                                </li>
                                                <li>
                                                    <p>Post, upload or otherwise transmit an image or video of another person without that person's consent;</p>
                                                </li>
                                                <li>
                                                    <p>Engage in commercial activity without PEOTV's prior written consent;</p>
                                                </li>
                                                <li>
                                                    <p>Use technology or other means to access, index, frame, or link to the Service  that is not authorized by PEOTV;</p>
                                                </li>
                                                <li>
                                                    <p>Access the Service through any automated means;</p>
                                                </li>
                                                <li>
                                                    <p>Use the Service to advertise or promote competing services;</p>
                                                </li>
                                                <li>
                                                    <p>Use the Service in a manner inconsistent with any and all applicable law;</p>
                                                </li>
                                                <li>
                                                    <p>Attempt, facilitate, induce, aid and abet, or encourage others to do any of the foregoing.</p>
                                                </li>
                                            </ul>


                                        </li>
                                        <li>
                                            <p>You are responsible for the security and proper use of Your unique user ID and password that You enter while registering on the Service and must take all necessary steps to ensure that they are kept confidential, used properly, and not disclosed to unauthorized people.</p>
                                        </li>
                                        <li>
                                            <p>You further agree that You shall not post, publish, transfer, upload, transmit or submit any of the content, including without limitation videos, images or comments of the Service on any other website, webpage or software, whatsoever.</p>
                                        </li>
                                        <li>
                                            <p>You agree that the content provided on the Service is strictly for Your private viewing only and not for public exhibition irrespective of whether the public is charged for such exhibition or not. You hereby agree to refrain from further broadcasting or making available the content provided on the Service to any other person(s), or in any manner communicate such content to the public.
        
                    </p>
                                        </li>
                                    </ul>
                                    <p>
                                        In case the Service allows You to download or stream any of the content therein, You agree that You will not use, sell, transfer or transmit the content to any person or upload the content on any other website, webpage or software, which may violate/cause damages or injuries to any rights of PEOTV or its affiliates or any third party including privacy rights, publicity rights, and intellectual property rights.
                </p>
                                    <p>
                                        You may encounter third party applications while using the Service including, without limitation, websites, widgets, software, services that interact with the Service. Your use of these third party applications shall be subject to such third party terms of use or license terms. PEOTV shall not be liable for any representations or warranties or obligations made by such third party applications to You under contract or law.
                </p>
                                    <p>
                                        Using PEOTV does not give You ownership of any intellectual property rights in the Service or the content you access. You may not use content from our Service unless You obtain permission from its owner or are otherwise permitted by law. These terms do not grant You the right to use any branding or logos used in the Service. Do not remove, obscure, or alter any legal notices displayed in or along with the Service.</p>
                                    <p>
                                        <b>SUBSCRIPTION SERVICES </b>
                                    </p>
                                    <p>
                                        Some of the content made available on the Service can be availed only by registered Users who have registered under a subscription model ("Subscription User"). You may need to register Yourself on the Service to avail these services on a subscription model ("Subscription"). To become a Subscription User, You may be required to provide certain personal information to PEOTV and may be required to make payments ("Subscription Fee") as per the applicable membership plan through a payment method accepted by PEOTV. On successful registration to the Subscription, PEOTV will intimate You of the same. </p>
                                    <p>
                                        <b>PAYMENTS </b></p>
                                    <p>
                                        PEOTV will automatically start billing upon Your use of the Service at the time of registration. You will be responsible and liable for any uncollected amounts and PEOTV reserves the right to terminate the subscription offered to You. The subscription fee will be billed at the beginning of Your subscription and will continue, unless and until You cancel Your subscription or the subscription is otherwise suspended or discontinued pursuant to these Terms of Use. </p>
                                    <p>
                                        PEOTV will be automatically billing You on a monthly basis. PEOTV will notify you in advance if there is any change in the subscription fee payable. The subscription fees billed are non-refundable irrespective of whether the subscription has been availed by You or not.</p>
                                    <p>
                                        PEOTV reserves the right to change, terminate or otherwise amend the subscription fees for the subscription and billing cycles at its sole discretion and at any time. Such amendments shall be effective upon posting on the Service and Your continued use of the Service shall be deemed to be Your conclusive acceptance of such amendments.</p>
                                    <p>
                                        PEOTV reserves the right to change, supplement, alter or remove any of the content that is subject to a subscription fee as it deems fit. PEOTV does not guarantee the availability of a specific content or a minimum content that is subject to the subscription fee.</p>
                                    <p>
                                        <b>TERMINATION OF SERVICE  </b></p>
                                    <p>
                                        PEOTV reserves the right to immediately terminate, suspend, limit, or restrict Your account or Your use of the Service or access to content at any time, without notice or liability, if PEOTV so determines in its sole discretion, for any reason whatsoever, including that You have breached these Terms of Use, the Privacy Policy, violated any law, rule, or regulation, engaged in any inappropriate conduct, provided false or inaccurate information, or for any other reason. You hereby consent, agree and acknowledge that PEOTV can, at its sole discretion, exercise its right in relation to any or all of the above, and that PEOTV, its directors, officers, employees, affiliates, agents, contractors, principals or licensors shall not be liable in any manner for the same.</p>
                                    <p>
                                        <b>LINKED DESTINATIONS</b></p>
                                    <p>
                                        Part of this Service may contain links to third party sites and third party content. You agree and acknowledge that PEOTV does not endorse or sponsor such third party sites, content, advertising or other material on such third party sites.</p>
                                    <p>
                                        If PEOTV provides links or pointers to other websites or destinations, You should not infer or assume that PEOTV operates, controls, or is otherwise connected with these other websites or destinations. By using the Service, You acknowledge and agree that PEOTV is not responsible or liable to You for any content or other materials hosted and served from any other application, website or destination.</p>
                                    <p>
                                        <b>ADVERTISING</b></p>
                                    <p>
                                        PEOTV expressly disclaim any liability arising out of the advertisements, usage or viewing of these products or services advertised on the Service or the (third party) content made available / hosted on the third party sites.</p>
                                    <p>
                                        PEOTV takes no responsibility for advertisements or any third party material posted on the Service nor does it take any responsibility for the products or services provided by advertisers. Any dealings You have with advertisers found while using the Service are between You and the advertiser, and You agree that PEOTV is not liable for any loss or claim that You may have against an advertiser.</p>
                                    <p>
                                        <b>THIRD PARTY CONTENT </b></p>
                                    <p>
                                        PEOTV’s role is limited to providing a communication platform along with hosting services to third parties, to enable the transmission of the third party content directly from third parties to You. PEOTV has no responsibility and / or liability in respect of any third party content on the Service, including for intellectual property rights infringement, defamation, obscenity or any other violation under applicable law.</p>
                                    <p>
                                        PEOTV does not pre-screen the third party content and has no obligation to monitor any third party content. Hence, PEOTV does not have actual or specific knowledge of any third party content on the Service. PEOTV at its discretion, may review the third party content when, and only when, complaints are received from You. Such actions do not in any manner negate or dilute PEOTV’s position as an intermediary or impose any liability on PEOTV with respect to third party content.</p>
                                    <p>
                                        <b>SUPPORT</b></p>
                                    <p>
                                        If You have any questions, complaints or support requests with respect to the Service and/or Content, then such correspondence should be directed to <a href="mailto:ruchiraw@slt.com.lk" style={{ textDecoration: 'underline' }}>ruchiraw@slt.com.lk</a></p>
                                    <p>
                                        <b>DISCLAIMER OF WARRANTIES </b></p>
                                    <p>
                                        You agree that use of the Service is at Your own risk. The Service including the content, the video player, and any other materials contained on or provided through the Service are provided "AS IS" and, to the fullest extent permitted by law, are provided without warranties of any kind, either express or implied. Without limiting the foregoing, PEOTV does not make any warranties of fitness for a particular purpose, title, merchantability, completeness, availability, security, compatibility or non-infringement; or that the Service will be uninterrupted, free of viruses, malware or other harmful components, accurate, error free or reliable.</p>
                                    <p>
                                        <b>LIMITATION OF LIABILITY  </b></p>
                                    <p>In no case shall PEOTV, its directors, officers, employees, affiliates, agents, contractors, principals, or licensors be liable for any direct, indirect, incidental, punitive, special, or consequential damages arising from Your use of the Service or any content or material thereof or for any other claim related in any way to Your use of the services or any content or material thereof, including, but not limited to, any errors or omissions in any content or material, or any loss or damage of any kind incurred as a result of the use of the Service or any content or material thereof, even if advised of their possibility. Where the laws do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such jurisdictions, PEOTV's liability shall be limited to the extent permitted by law.</p>
                                    <p>
                                        <b>INDEMNITY</b>
                                    </p>
                                    <p>
                                        You agree to defend, indemnify and hold harmless PEOTV, its affiliates, officers, directors, employees and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorneys' fees) arising from: (i) Your use of and access to the Service; (ii) Your violation of any term of these Terms of Use; (iii) Your violation of any third party right, including without limitation any publicity, privacy, or intellectual property right; (iv) Your breach of any applicable laws; and (v) any unauthorized, improper, illegal or wrongful use of Your Account by any person, including a third party, whether or not authorized or permitted by You. This indemnification obligation will survive the expiry or termination of these Terms of Use and Your use of the Service.
            </p>
                                    <p>
                                        <b>SEVERABILITY</b>
                                    </p>
                                    <p>
                                        If any provision of these Terms of Use is held invalid, void, or unenforceable, then that provision shall be considered severable from the remaining provisions, and the remaining provisions given full force and effect.
            </p>
                                    <p>
                                        <b>GOVERNING LAW AND JURISDICTION</b>
                                    </p>
                                    <p>The relationship between You and PEOTV shall be governed by the laws of Sri Lanka. You agree that any proceeding by either party shall be before any court/forum of competent jurisdiction of Sri Lanka.</p>
                                    <p>
                                        <b>CHANGES</b>
                                    </p>
                                    <p>
                                        PEOTV reserves the right, at its sole discretion, to change, modify, add or remove any portion of the Terms of Use in whole or in part, at any time. Changes to the Terms of Use will be effective when posted. You agree to review the Privacy Policy periodically to become aware of any changes. The use of the Service after any changes to the Privacy Policy are posted will be considered acceptance of those changes by you and will constitute your agreement to be bound thereby. If you object to any such changes, your sole recourse will be to stop using the Service. If there is a conflict between these Terms of Use and the additional terms, the additional terms will control for that conflict.
            </p>
                                    <p>
                                        <b>SURVIVAL </b>
                                    </p>
                                    <p>You acknowledge that Your representations, undertakings, and warranties and the clauses relating to indemnities, limitation of liability, grant of license, governing law, confidentiality shall survive the efflux of time and the termination of these Terms of Use.</p>
                                    <p>
                                        <b>ENTIRE AGREEMENT</b>
                                    </p>
                                    <p>These Terms of Use and Privacy Policy constitute the entire agreement between You and PEOTV governing Your use of the Service, superseding any prior agreements between You and PEOTV regarding such use. These Terms of Use do not create any third party beneficiary rights.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
} 