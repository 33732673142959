import Home from './home/Home.jsx';
import Login from './login/Login';
import NotFound from './errorPages/NotFound.jsx'
import Customers from './registration/Customers.jsx'
import CustomerSelection from './registration/CustomerSelection.jsx';
import AccountLogin from './registration/AccountLogin.jsx'
import PersonalDetails from './registration/PersonalDetails.jsx';
import InsertOtp from './registration/InsertOtp.jsx';
import AuthenticationCode from './registration/AuthenticationCode.jsx';
import ShowAuthCode from './registration/ShowAuthCode.jsx';
import Subscription from './subscription/Subscription.jsx';
import ForgetPassword from './passwordRecovery/ForgetPassword.jsx';
import RecoverPassword from './passwordRecovery/RecoverPassword.jsx';
import ConfirmEmail from './generalPages/ConfirmEmail.jsx';
import LandingPage from './landingPage/LandingPage'
import SvodPage from './svodsPage/SvodPage';
import SubSvodPage from './svodsPage/SubSvodPage';
import AlreadyRegistered from './generalPages/AlreadyRegisteredMessage'
import GoToPeotv from './registration/GoToPeotv'
import LinkToProvider from './registration/LinkToProvider'
import ViewPackagePage from './subscription/ViewPackage';
import Profile from './profile/Profile'
import TermsOfUse from './subscription/TermsOfUse';
import AllVodPage from './AllProducts/AllVodPage'
import AllSVODPage from './AllProducts/AllSVODPage'
import AllDevicesPage from './AllProducts/AllDevicesPage'
import AllDataBundlesPage from './AllProducts/AllDataBundlesPage'
import AllChannelPage from './AllProducts/AllChannelPage'
import ProductPage from './ProductContent/ProductPage';
import AllPackagePage from './AllProducts/AllPackagePage';
import PaymentDetails from './PaymentDetails/PaymentStatus';
import ProductVod from './productPage/ProductVod';
import LoadingPage from './subscription/LoadingPage'
import AllPromotions from './promotions/AllPromotions'
import PromotionalPackages from  './promotions/PromotionalPackages'
import ProvisioningErrors from './provisioningErrors/ProvisioningErrors'
import ChannelBundlePage from './ProductContent/ChannelBundlePage'
import SubProduct from './ProductContent/SubProduct'

export default {
    HomeView: Home,
    LoginView: Login,
    CustomersView: Customers,
    CustomerSelectionView: CustomerSelection,
    AccountLoginView: AccountLogin,
    PersonalDetailsView: PersonalDetails,
    InsetOtpView: InsertOtp,
    SubscriptionView: Subscription,
    AuthenticationCodeView: AuthenticationCode,
    ShowAuthCodeView: ShowAuthCode,
    ForgetPasswordView: ForgetPassword,
    RecoverPasswordView: RecoverPassword,
    ConfirmEmailView: ConfirmEmail,
    NotFoundView: NotFound,
    LandingPageView: LandingPage,
    SvodPageView: SvodPage,
    SubSvodPageView: SubSvodPage,
    AlreadyRegisteredView: AlreadyRegistered,
    GoToPeotvView: GoToPeotv,
    LinkToProviderView: LinkToProvider,
    ViewPackagePage: ViewPackagePage,
    Profile: Profile,
    TermsOfUse: TermsOfUse,
    AllVodPage: AllVodPage,
    AllSVODPage: AllSVODPage,
    AllDevicesPage: AllDevicesPage,
    AllDataBundlesPage: AllDataBundlesPage,
    AllChannelPage: AllChannelPage,
    ProductPage: ProductPage,
    AllPackagePage : AllPackagePage,
    PaymentDetails: PaymentDetails,
    ProductVod: ProductVod,
    LoadingPage: LoadingPage,
    AllPromotions: AllPromotions,
    PromotionalPackages: PromotionalPackages,
    ProvisioningErrorsView : ProvisioningErrors,
    ChannelBundleView : ChannelBundlePage,
    SubProductView : SubProduct
} 