import React, { Component } from 'react';
import { singleProductActions, singleProductSelectors } from './ducks';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import history from '../../_helpers/history';
import ProductView from '../../components/ProductPageView/ProductPageView';
import './ProductPage.scss';
import { getNestedValue, getLoggedInStatus, getConnectionId, getServiceType, getPurchaseOptionAmount, getIndex, getBasepackage, getProductPrice, validArray, getRegion, getUtilizationPeriod, purchaseStatusValidation } from '../../util/ArrayValidator';
// import {DiscountPrices} from '../../components/DiscountPrices/DiscountPrices';
import { PRODUCT, USER, TRANSACTIONAL, PREPAID_SERVICE_TYPE, SCREEN_PRODUCT_TYPE_TYPE_NAME, PAYMENT_SUCCESS } from '../../util/Constants';
import { DiscountPriceComponent } from '../../components/DiscountPriceComponent/DiscountPriceComponent';
import { DiscountPrices } from '../../components/DiscountPrices/DiscountPrices';
import { Payhere } from '../../util/PayhereScripts'
import DummyChannelBundlePage from '../../components/DummyComponent/DummyChannelBundlePage';
import { Container, Row, Col } from 'react-bootstrap';
import moment from 'moment';

import { ProductModal } from '../../components/controls/ProductModal';
import { PurchaseModal } from '.../../components/controls/PurchaseModal';
import { UnsubscribeModal } from '../../components/controls/UnsubscribeModal'
import NotifyModal from '../../components/controls/NotifyModal'
import Card from '../../components/CardComponent/Card';
import { CustomButton } from '../../components/controls/CustomButton';
import { CustomButtonPlaceholder } from "../../components/DummyComponent/DummyComponent";
import { ExpiryDate } from '../../components/ProductPageView/ExpiryDate'
import { ProductProvisioningError } from '../../components/ProductPageView/ProductProvisioningError'

export class ChannelBundlePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            productDetails: null,
            productDetailsPurchase: true
        }
    }

    componentDidMount() {
        let getBasePackageId = getBasepackage()
        let id = this.props.match && this.props.match.params.id
        let price = Boolean(this.props.match && this.props.match.params.purchasable);
       
        this.setState({ purchasable: price })
        if (getLoggedInStatus()) {
            if (!!getConnectionId()) {
                const params = this.props.location.state && this.props.location.state.dataBundle
                this.props.getBundledProducts({ productId: id, basePackageId: getBasePackageId })
                this.props.getProduct({ productId: id, connectionId: getConnectionId(), dataBundle: params });

                if (this.props.location && this.props.location.search) {
                    let purchaseOptionId = this.props.location.search.split("=")[1];

                    this.setState({
                        purchaseOption: purchaseOptionId
                    })

                    this.getPriceOnPromotions();
                }
            } else {
                history.push("/")
            }
        }
        else {
            this.props.getProduct(id)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (getServiceType() === PREPAID_SERVICE_TYPE && !this.props.purchaseLoading && !this.props.payherePurchaseStatus && this.props.payhereDetails !== null && this.state.showPayhere && this.state.notifyStatus) {
            // let id = this.props.match && this.props.match.params.id
            const { payhereDetails, purchaseOptions, userDetails } = this.props;

            let payementStatus = Payhere(purchaseOptions, userDetails, payhereDetails.transactionDetails, this.state.purchaseOption)
            window.payhere.onCompleted = (orderId) => {

                console.log("Payment completed. OrderID:" + orderId)
                this.getPurchaseSatus(payhereDetails.transactionDetails.transactionId)
                //Note: validate the payment and show success or failure page to the customer
            };

            // Called when user closes the payment without completing
            window.payhere.onDismissed = () => {
                //Note: Prompt user to pay again or show an error page
                console.log("Payment dismissed");
                this.closeNotify()

            };

            // Called when error happens when initializing payment such as invalid parameters
            window.payhere.onError = (error) => {
                // Note: show an error page
                console.log("Error:" + error);
                // this.getPurchaseSatusDissmissed()
            };

            window.payhere.startPayment(payementStatus);
        }
        //carousel resize issue fixed on bundled products carousel slider arrows not working
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 0);
    }

    componentWillUnmount() {
        this.props.clearProductDetails();
        this.setState({
            password: undefined,
            purchaseOption: undefined,
            purchaseOptionValues: undefined
        });
    }

    onClick = (e) => {
        history.push(`/channel-bundle/${e.target.id}/channel`)
    }
    onClose = () => {
        this.setState({ showModal: false, showModals: false })
    }

    onClickOk = (e) => {
        this.setState({
            showModal: false,
            showModals: true
        })
    }

    closeNotify = () => {
        // debugger
        this.setState({
            notifyStatus: false,
            purchaseOption: undefined,
            password: undefined
        })
        this.props.clearPurchaseMessage();
        this.props.getPrepaidPurchaseStatusLoading()
        this.setState({
            password: null,
            purchaseOption: null,
            showPayhere: false,
            purchaseOptionValues: undefined
        })
    }

    handleShow = () => {
        this.setState({
            showModal: true,
            purchaseOption: this.props.productDetails && this.props.productDetails.purchaseOption && this.props.productDetails.purchaseOption.id
        })

    }

    onCloseUnsub = () => {
        this.setState({ showUnsub: false })
    }

    handleShowUnsub = () => {
        this.setState({ showUnsub: true })
    }

    onClickYes = () => {
        const { productDetails, purchaseOptions } = this.props;
        let id = getNestedValue(productDetails, ['id']);
        let productTypeId = getNestedValue(productDetails, ['productTypeId']);
        let productPOGroupId = getNestedValue(productDetails, ['purchaseOptionGroupId']);
        // let amount = getNestedValue(productDetails, ['purchaseOption', 'defaultPrice']);
        let amount
        let purchaseOptionId
        let selectedPurchaseOptionGID
        let purchaseOptionIndex = parseInt(this.state['purchaseOptionValues'])
        let params = this.props.location.state && this.props.location.state.dataBundle

        let defaultPurchaseOptionType = getNestedValue(productDetails, ['purchaseOption', 'purchaseType']);

        if (purchaseOptionIndex) {
            var value = getIndex(purchaseOptions, purchaseOptionIndex)
            selectedPurchaseOptionGID = getNestedValue(value, ['purchaseOptionGroupId'])
            amount = getProductPrice(productDetails);
            purchaseOptionId = value && value.id
        } else {
            purchaseOptionId = getNestedValue(productDetails, ['purchaseOption', 'id']);
            selectedPurchaseOptionGID = getNestedValue(productDetails, ['purchaseOptionGroupId']);
            amount = getProductPrice(productDetails);
        }
        this.setState({
            showModal: false,
            showModals: false,
            notifyStatus: true,
            showPayhere: true
        })
        this.props.productSubscribe({
            // connectionId: this.props.connectionDetails && Array.isArray(this.props.connectionDetails) && this.props.connectionDetails[localStorage.selectedUserIndex].connectionId,
            connectionId: getConnectionId(),
            pin: this.state["password"],
            productId: id,
            productType: productTypeId,
            purchaseOption: purchaseOptionId,
            amount: amount,
            productPOGroupId: productPOGroupId,
            selectedPurchaseOptionGID: selectedPurchaseOptionGID,
            defaultPurchaseOptionType: defaultPurchaseOptionType,
            dataBundle: params
            // amount: getPurchaseOptionAmount(purchaseOptions, this.state["purchaseOption"])
        });
        this.setState({
            password: null
        });
        // if(getServiceType()){ 
        //     this.Payhere()
        // }
    }

    onClickYesUnsub = () => {
        const { productDetails, purchaseOptions } = this.props;
        const id = getNestedValue(productDetails, ['id']);
        const productTypeId = getNestedValue(productDetails, ['productTypeId']);
        let purchaseOptionId
        let purchaseOptionIndex = this.state['purchaseOptionValues']
        let params = this.props.location.state && this.props.location.state.dataBundle
        if (purchaseOptionIndex) {
            var value = getIndex(purchaseOptions, purchaseOptionIndex)
            purchaseOptionId = value.id
        } else {
            purchaseOptionId = getNestedValue(productDetails, ['purchaseOption', 'id']);
        }

        const purchaseId = getNestedValue(productDetails, ['purchaseId'])
        let defaultPurchaseOptionType = getNestedValue(productDetails, ['purchaseOption', 'purchaseType']);

        this.setState({
            showUnsub: false,
            notifyStatus: true
        })
        this.props.productUnsubscribe({
            // connectionId: this.props.connectionDetails && Array.isArray(this.props.connectionDetails) && this.props.connectionDetails[localStorage.selectedUserIndex].connectionId,
            connectionId: getConnectionId(),
            productId: id,
            productType: productTypeId,
            purchaseOption: purchaseOptionId,
            purchaseId: purchaseId,
            defaultPurchaseOptionType: defaultPurchaseOptionType,
            dataBundle: params
        })
    }

    onClickNo = () => {
        this.setState({
            showModal: false,
            showModals: false,
            showUnsub: false,
            password: null,
            showPayhere: false
        })
    }

    handleChangePurchaseOptions = (e) => {
        const { purchaseOptions, getProductDetailsWithPurchaseOption } = this.props;
        let id = this.props.match && this.props.match.params.id
        this.setState({
            [e.target.id]: e.target.value, selectedIndex: e.target.value
        });
        let selectedPurchaseOptionId = purchaseOptions && purchaseOptions[e.target.value].id;
        getProductDetailsWithPurchaseOption({
            productId: id,
            connectionId: getConnectionId(),
            purchaseOptionId: selectedPurchaseOptionId
        })
    }

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value })
    }

    static getDerivedStateFromProps(nextProps, state) {
        let newProps = {}

        if (nextProps.productDetails && nextProps.productDetails !== state.productDetails) {
            newProps.productDetails = nextProps.productDetails
        }

        if (nextProps.subscribe !== null) {
            newProps.purchaseStatus = nextProps.purchaseStatus
        }

        if (nextProps.payhereDetails !== null) {
            newProps.payhereDetails = nextProps.payhereDetails
        }

        if (nextProps.location && nextProps.location.search && state.productDetailsPurchase && (nextProps.productDetailsLoading === false)) {
            if (!nextProps.productDetails.isBundled) {
                debugger
                if (getServiceType()) {
                    if (nextProps.productDetails.purchaseStatus === "NOT_PURCHASED") {
                        newProps.showModal = true
                        newProps.productDetailsPurchase = false
                    }
                } else {
                    if (nextProps.productDetails.purchaseStatus === "NOT_PURCHASED" || nextProps.productDetails.purchaseStatus === "PURCHASE_PENDING_REMOVE") {
                        newProps.showModal = true
                        newProps.productDetailsPurchase = false
                    }
                }
            }
        }

        return {
            ...newProps
        };
    }

    getPurchaseSatus = (transactionId) => {
        this.setState({ showPayhere: false })
        let id = this.props.match && this.props.match.params.id
        this.props.getPrepaidPurchaseStatus({ transactionId: transactionId, productId: id })

    }

    getPriceOnPromotions = () => {
        const { getProductDetailsWithPurchaseOption } = this.props;
        let id = this.props.match && this.props.match.params.id;
        let selectedPurchaseOptionId = this.props.location.search.split("=")[1];
        getProductDetailsWithPurchaseOption({
            productId: id,
            connectionId: getConnectionId(),
            purchaseOptionId: selectedPurchaseOptionId
        })
    }

    render() {
        const { productDetails, showModal, showModals, password, showUnsub, notifyStatus, selectedIndex } = this.state;
        const { productDetailsLoading, purchaseOptions, purchaseLoading, getPurchaseStatus, getProductPurchaseStatus, payherePurchaseStatus, payherePurchaseStatusLoading, productPurchaseStatusError, discountLoading, bundledProducts, submitBtnLoading, referenceId } = this.props;
        let defaultPrice = parseFloat(getNestedValue(productDetails, ['discountOptionGroup', 'purchasePrice'])).toFixed(2);
        let selectedPurchaseOptionDefaultPrice = getPurchaseOptionAmount(purchaseOptions, this.state["purchaseOption"])
        let bundledStatus = getNestedValue(productDetails, ['isBundled']);
        let purchaseStatus = getNestedValue(productDetails, ['isPurchased']);
        let productTypeTypeName = getNestedValue(productDetails, ['productTypeTypeName']);
        let absoluteDiscount = getNestedValue(productDetails, ['purchaseOption', 'absoluteDiscount'])
        let defaultPurchaseOptionType = productTypeTypeName === SCREEN_PRODUCT_TYPE_TYPE_NAME ? getNestedValue(productDetails, ['purchaseType']) : getNestedValue(productDetails, ['purchaseOption', 'purchaseType']);
        let serviceType = getServiceType();
        let regionType = getRegion(productDetails && productDetails.regions)
        let priceDisplay, customButtonText
        let showTstv, showPip, showCpvr
        let showPeriod, showUtilizationPeriod, showPurchasedIcon;
        let utilizationPeriodUnit = getNestedValue(productDetails && productDetails.purchaseOption, ['utilization', 'utilizationPeriodUnit'])
        //show the utilization period of the purchase option
        showUtilizationPeriod = getUtilizationPeriod(productDetails, utilizationPeriodUnit)
        let purchaseStatusType = productDetails && purchaseStatusValidation(productDetails.purchaseStatus);
        let paymentStatus = getNestedValue(productDetails, ['paymentStatus']);
        let provisioningStatus = paymentStatus && paymentStatus === PAYMENT_SUCCESS && purchaseStatusType.productPurchasedFailed
        let billingDateIsAfter = moment().add(showUtilizationPeriod, utilizationPeriodUnit).isAfter(localStorage.getItem('basepackageEndDate'));
        let basepackagePurchaseType = localStorage.getItem('basepackagePurchaseType')
        let billingEndDate = getNestedValue(productDetails, ['billingEndDate']);
        let feature = getNestedValue(productDetails, ['feature']);
        let discountLevelProduct, discountLevelUser;
        if (productDetails && productDetails.discountOptionGroup && productDetails.discountOptionGroup.discountOptions) {
            discountLevelProduct = productDetails.discountOptionGroup && productDetails.discountOptionGroup.discountOptions.filter(productDetails => productDetails.discountLevel === PRODUCT);
            discountLevelUser = productDetails.discountOptionGroup && productDetails.discountOptionGroup.discountOptions.filter(productDetails => productDetails.discountLevel === USER);
        }
        let pricing
        if (productDetails && productDetails.discountOptionGroup) {
            if (productDetails.discountOptionGroup.discountOptions && validArray(productDetails.discountOptionGroup.discountOptions)) {
                pricing =
                    <div className="product-purchase-content">
                        <div className="price-box">
                            <DiscountPrices price={productDetails.discountOptionGroup.purchasePrice === null ? productDetails.discountOptionGroup.actualPrice : productDetails.discountOptionGroup.purchasePrice} showTax={true} oldPrice={
                                productDetails.discountOptionGroup.actualPrice} precentage={
                                    productDetails.purchaseOption.discountPercentage} productPeriod={showPeriod} discountLevelProduct={discountLevelProduct && discountLevelProduct[0]} discountLevelUser={discountLevelUser && discountLevelUser[0]}
                                discountPriceLoading={false} type="package" isProduct={true} purchaseOptionType={productDetails.purchaseOption.purchaseType} showUtilizationPeriod={showUtilizationPeriod} utilizationPeriodUnit={utilizationPeriodUnit}
                            />
                        </div>
                    </div>
            } else {
                pricing = <div className="price-box"><DiscountPriceComponent price={productDetails && productDetails.discountOptionGroup && productDetails.discountOptionGroup.purchasePrice} showTax={true} period={showUtilizationPeriod} /></div>
            }
        }

        if (getLoggedInStatus()) {
            if (productDetails && (!bundledStatus && !purchaseStatus)) {
                if (defaultPrice === 0) {
                    priceDisplay = <div style={{ display: 'grid' }}> <h4>Rs. 0 +tax</h4></div>
                } else {
                    priceDisplay = pricing
                }
            }
        } else {
            if (this.props.match && this.props.match.params.purchasable === "true") {
                priceDisplay = <div style={{ display: 'grid' }}>
                    {/* <h4>{"Rs." + defaultPrice + " +tax"}</h4> */}
                    {/* <DiscountPriceComponent price={defaultPrice} showTax={true} oldPrice={absoluteDiscount} precentage={discountPercentage} period={showPeriod} /> */}
                </div>
            } else {
                priceDisplay = null
            }
        }

        if (defaultPurchaseOptionType === TRANSACTIONAL) {
            customButtonText = "PURCHASE";
        } else {
            customButtonText = "SUBSCRIBE";
        }

        //set the purchase option id of the promotion for the default PO to auto select on purchase window
        if (this.props.location && this.props.location.search) {
            let purchaseOptionId = this.props.location.search.split("=")[1];
            if (productDetails) {
                productDetails.purchaseOption.id = purchaseOptionId;
            }
        }
        if (getLoggedInStatus && productDetails && (purchaseStatusType.productPurchased || purchaseStatusType.productPendingRemove || provisioningStatus)) {
            showPurchasedIcon = <span> <i className="fa fa-check-square" style={{ color: "#4FA1D9", fontSize: 18 }}></i>{" "}{defaultPurchaseOptionType === TRANSACTIONAL ? "Already Purchased" : "Already Subscribed"}</span>
        }
        let showFreeTag;

        //check usertype and enable subscribe button
        let subsBtnDisabled = false;
        if (productDetails && getServiceType() === PREPAID_SERVICE_TYPE) {
            if (purchaseStatusType.productPendingRemove) {
                subsBtnDisabled = true;
            }
        } else {
            subsBtnDisabled = false;
        }
        let unsubMessage = "";

        if (getServiceType()) {
            unsubMessage = ""
        } else {
            unsubMessage = "Please re-subscribe to cancel the unsubscription.";
        }
        //show the features in the channels
        if (productDetails && productDetails.feature !== null) {
            if (getNestedValue(productDetails, ['feature', 'tstv'])) {
                showTstv = <span><i className="fa fa-history margin-right-5" /> TSTV</span>
            } else {
                showTstv = null
            }
            // if (productDetails && productDetails.feature.pip) {
            if (getNestedValue(productDetails, ['feature', 'pip'])) {
                showPip = <span><i className="fa fa-battery-quarter margin-right-5" /> PIP</span>
            } else {
                showPip = null;
            }
            if (getNestedValue(productDetails, ['feature', 'cpvr'])) {
                showCpvr = <span><i className="fa fa-video margin-right-5" /> CPVR</span>
            } else {
                showCpvr = null
            }
        }
        console.log("productDetails", this.props)
        return (
            <>
                {productDetailsLoading ?
                    < DummyChannelBundlePage />
                    :
                    <div style={{ marginTop: 40 }}>
                        {productDetails && <Container style={{ color: "#d0d0d0" }}>
                            <Row>
                                <Col sm={5}><h4>{productDetails && productDetails.name}</h4></Col>
                                <Col sm="3"></Col>
                                <Col className="price-display-col" style={{ "display": "flex" }}>
                                    {showPurchasedIcon}
                                    {priceDisplay}
                                    {/* {getLoggedInStatus() && svod && ((purchaseStatusType.productNotPurchased || purchaseStatusType.productPendingRemove) ? true : false) && !svod.isBundled && pricing} */}

                                </Col>
                            </Row>
                            <p className="text-align-justify">{productDetails && productDetails.description}</p>

                            <div style={{ marginTop: 40 }}>
                                <h4 className="color-bf800c">Included Channels</h4>
                                <hr className="border-width-2 background-color-blue" />
                                {feature && (feature.tstv || feature.pip || feature.cpvr) ?
                                    <Col className="d-flex justify-content-end">
                                        <div style={{ display: 'grid' }}>
                                            {showTstv}
                                            {showPip}
                                            {showCpvr}
                                        </div>
                                    </Col> : null
                                }
                                <Row>
                                    {
                                        productDetails && productDetails.channelsList && Array.isArray(productDetails.channelsList) && productDetails.channelsList.map(content => {
                                            let purchaseStatusType = purchaseStatusValidation(content.purchaseStatus);
                                            if (content.status === "PRODUCT_STATUS_ACTIVE") {
                                                return <div className="display-inline-block">
                                                    <Card
                                                        id={content.id}
                                                        isLoggedIn={getLoggedInStatus()}
                                                        name={content.name}
                                                        imageURL={content.imageURL}
                                                        isPurchased={content && (purchaseStatusType.productPurchased || purchaseStatusType.productPendingRemove)}
                                                        showAddNew={false}
                                                        daysToExpire={content.daysRemainingToExpire}
                                                        hover={true}
                                                        showPrice={false}
                                                        onClick={this.onClick}
                                                        type="channel"
                                                    />
                                                </div>
                                            }
                                            return false
                                        })
                                    }
                                </Row>
                                
                            </div>
                            {submitBtnLoading ? <CustomButtonPlaceholder /> : (getLoggedInStatus() && productDetails && !productDetails.isBundled && <Row className="svod-purchase-btn">
                                    <Col className="d-flex justify-content-end">
                                        {(productDetails && (purchaseStatusType.productPurchased)) && productDetails.purchaseOption.purchaseType !== TRANSACTIONAL && <CustomButton
                                          style={{ marginRight: "0" }}
                                            onClick={this.handleShowUnsub}
                                            text="UNSUBSCRIBE"></CustomButton>}
                                        {productDetails && ((purchaseStatusType.productPendingRemove || purchaseStatusType.productNotPurchased)) && <CustomButton
                                         style={{ marginRight: "0" }}
                                            onClick={this.handleShow}
                                            text={defaultPurchaseOptionType === TRANSACTIONAL ? "PURCHASE" : "SUBSCRIBE"}
                                            disabled={subsBtnDisabled}
                                        ></CustomButton>}
                                    </Col>
                                </Row>)}
                                <Row style={{ display: 'initial' }}>
                                    {!productDetails.isBundled && billingDateIsAfter && basepackagePurchaseType === TRANSACTIONAL && serviceType && !provisioningStatus && <ExpiryDate expireDate={moment(localStorage.getItem('basepackageEndDate')).format('DD/MM/YYYY')} />}
                                </Row>
                                <Row style={{ display: 'initial' }}>
                                {purchaseStatusType.productPurchasedFailed && paymentStatus === PAYMENT_SUCCESS && provisioningStatus && <Row className="provision-alert-row">
                                    <ProductProvisioningError />
                                </Row>}
                            </Row>

                            {
                                getLoggedInStatus() && purchaseStatusType.productPendingRemove &&
                                <Row className="renew-alert">
                                    <div className="account-suspended">
                                        <p>Unsubscribed content. You will have access to this only till {billingEndDate}. {unsubMessage}</p>
                                    </div>
                                </Row>
                            }
                        </Container>

                        }
                    </div>
                }
                <ProductModal
                    showModal={showModal}
                    modalStyle={{ display: showModal ? 'flex' : 'none', overflow: "overlay" }}
                    handleChange={this.handleChangePurchaseOptions}
                    onClose={this.onClose}
                    modalBodyText="Please confirm to unsubscribe?"
                    buttonText={defaultPurchaseOptionType === TRANSACTIONAL ? "PURCHASE" : "SUBSCRIBE"}
                    data={productDetails}
                    purchaseOption={purchaseOptions}
                    onClick={this.onClickOk}
                    serviceType={serviceType}
                    selectedIndex={selectedIndex}
                    discountPriceLoading={discountLoading}
                    bundledProducts={bundledProducts}
                    showUtilizationPeriod={showUtilizationPeriod}
                    regionType={regionType}
                    utilizationPeriodUnit={utilizationPeriodUnit}
                    defaultPrice={parseFloat(getProductPrice(productDetails))}
                />
                <PurchaseModal
                    heading={`Are you sure you want to ${customButtonText.toLowerCase()}?`}
                    showModal={showModals}
                    modalStyle={{ display: showModals ? 'flex' : 'none', overflow: "overlay" }}
                    handleChange={this.handleChange}
                    onClose={this.onClose}
                    modalBodyText="Please confirm to unsubscribe?"
                    data={productDetails}
                    onClick={this.onClickOk}
                    onClickYes={this.onClickYes}
                    onClickNo={this.onClickNo}
                    value={this.state.password}
                    disable={this.state["password"] === undefined}
                />
                <UnsubscribeModal
                    showUnsub={showUnsub}
                    modalStyle={{ display: showUnsub ? 'flex' : 'none', overflow: "overlay" }}
                    onClose={this.onCloseUnsub}
                    data={productDetails}
                    onClickYes={this.onClickYesUnsub}
                    onClickNo={this.onClickNo}
                />
                <NotifyModal
                    showModal={this.state.notifyStatus}
                    modalStyle={{
                        display: this.state.notifyStatus ? "flex" : "none",
                        overflow: "overlay",
                    }}
                    onClose={this.closeNotify}
                    type={(getProductPurchaseStatus)}
                    message={getPurchaseStatus || productPurchaseStatusError}
                    loading={purchaseLoading || payherePurchaseStatusLoading}
                    dummyType="channel"
                    purchaseStatus={productDetails && productDetails.purchaseStatus}
                    // loading={purchaseStatusLoading}
                    serviceType={serviceType}
                    payherePurchaseStatus={payherePurchaseStatus}
                    data={productDetails}
                    purchaseType={defaultPurchaseOptionType}
                    defaultPrice={parseFloat(getProductPrice(productDetails))}
                    // defaultPrice={svod && svod.discountOptionGroup && svod.discountOptionGroup.purchasePrice && svod.discountOptionGroup.purchasePrice===null ? svod.discountOptionGroup.actualPrice :svod && svod.discountOptionGroup && svod.discountOptionGroup.purchasePrice}
                    referenceId={referenceId}
                    provisioningStatus={provisioningStatus}
                />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        productDetails: singleProductSelectors.getProductDetails(state),
        productDetailsLoading: singleProductSelectors.getProductDetailsLoading(state),
        purchaseOptions: singleProductSelectors.getPurchaseOptions(state),
        purchaseLoading: singleProductSelectors.purchaseLoading(state),
        getPurchaseStatus: singleProductSelectors.getPurchaseStatus(state),
        getProductPurchaseStatus: singleProductSelectors.getProductPurchaseStatus(state),
        getBasePackageId: singleProductSelectors.getBasePackageId(state),
        bundledProducts: singleProductSelectors.getBundledProducts(state),
        payhereDetails: singleProductSelectors.getPayhereDetails(state),
        userDetails: singleProductSelectors.getUserDetails(state),
        discountLoading: singleProductSelectors.getDiscountPriceLoading(state),
        ...singleProductSelectors.payherePurchaseStatus(state),
        submitBtnLoading: singleProductSelectors.getBtnLoadingStatus(state),
        referenceId: singleProductSelectors.getReferenceId(state)
    }
}

export default withRouter(connect(mapStateToProps, singleProductActions)(ChannelBundlePage))
