import React, { Component } from 'react'
import { Container } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import PromoCard from "./PromoCard"
import DummyAllPromotions from '../../components/DummyComponent/DummyAllPromotions';
import { promotionActions, promotionSelectors } from './ducks';
import { getLoggedInStatus, getBasepackage } from '../../util/ArrayValidator';
class AllPromotions extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {

        if (getLoggedInStatus()) {
            if (this.props.loading && this.props.bannerContent === null) {
                this.props.getBannerContent(getBasepackage());
            }
        } else {
            this.props.getBannerContent();
        }
    }

    componentDidUpdate() {

        if (this.props.loading && this.props.bannerContent === null) {
            this.props.getBannerContent(getBasepackage());
        }
    }

    componentWillUnmount() {
        this.props.resetBannerState();
    }


    render() {
        const { loading, bannerContent } = this.props;

        //select banners specific to the base package
        // let bannerArray = [];
        // if(getLoggedInStatus()){
        //     if(localStorage.getItem("baseId")){
        //         if(bannerContent){
        //             bannerContent.map((banner) => {
        //             if(banner.basePackageIds){
        //                 banner.basePackageIds.map((id) => {
        //                 if(id === localStorage.getItem("baseId")){
        //                     bannerArray.push(banner)
        //                 }
        //                 })
        //             }
        //             })
        //         }
        //     }
        // }else{
        //     bannerArray = bannerContent
        // }


        if (loading) {
            return <DummyAllPromotions />;
        } else {
            if (bannerContent && bannerContent.length !== 0) {
                return (
                    <Container className="all-svods-container">
                        <div className="all-svods-inside-container">
                            <Container className="container-style">
                                <h3 className="all-svods-header">ALL PROMOTIONS</h3>
                                <br />
                                {
                                    bannerContent && bannerContent.map((promo) => {
                                        return <PromoCard
                                            key={1}
                                            name=""
                                            isPurchased={false}
                                            imageURL={promo.imageURL}
                                            id={12}
                                            type={promo.type}
                                            promo={promo}
                                        // onClick={this.onClick}
                                        />
                                    })
                                }
                            </Container>
                        </div>
                    </Container>
                )
            } else {
                return <Container className="all-svods-container">
                    <div className="all-svods-inside-container">
                        <Container className="container-style">
                            <h3 className="all-svods-header">ALL PROMOTIONS</h3>
                            <br />
                            <h4>No Promotions</h4>
                        </Container>
                    </div>
                </Container>
            }
        }
    }
}


const mapStateToProps = state => {
    return {
        connectionDetails: promotionSelectors.getConnectionDeatils(state),
        featuredPackage: promotionSelectors.getFeaturedPackage(state),
        ...promotionSelectors.getBannerContent(state)
    };
};

export default withRouter(connect(
    mapStateToProps, promotionActions
)(AllPromotions));
//107

