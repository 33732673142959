import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import DummyLoadingPage from '../../components/DummyComponent/DummyLoadingPage';
import { Container } from 'react-bootstrap';
import { PaymentSuccess } from '../../components/paymentStatus/paymentSuccess'
import { PaymentFailed } from '../../components/paymentStatus/paymentFailed'
import { subscriptionSelectors, subscriptionActions } from './ducks/index.js';
import { connect } from "react-redux";
import history from '../../_helpers/history'
import { getConnectionType } from '../../util/ArrayValidator'

class LoadingPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: "",
            loading: true,
        }
    }
    componentDidMount() {
        //convert parmeters to lowercase
        const oldParams = new URLSearchParams(this.props.location.search)
        const newParams = new URLSearchParams();
        for (const [name, value] of oldParams) {
            newParams.append(name.toLowerCase(), value);
        }
        //get the parameter
        const params =  newParams.get("bridgeid")
        var user = getConnectionType()
        // if (data) {
        if (user === "6") {
            if (params === null) {
                if (!this.props.subscribeLoading) {
                    // this.props.validateConsentFailed({ message: "Consent not granted" })
                } else {
                    this.props.validateConsentFailed({ message: "Consent not granted" })
                }

            } else {
                let obj = { bridgeId: params, msisdn: sessionStorage.msisdn }
                this.props.validateConsent(obj)
            }
        }
        else if (user === "7" && this.props.subscribeLoading) {
            history.push('/viewpackage')
        }
        // } else {
        //     history.push('/viewpackage')
        // }

    }

    static getDerivedStateFromProps(nextProps, state) {
        let newProps = {}

        if (nextProps.error === undefined) {
            newProps.error = undefined
        }
        else {
            if (nextProps.error !== state.error) {
                newProps.error = nextProps.error
                newProps.loading = false
            }
            if (nextProps.secondaryAllowedProductsError !== state.secondaryAllowedProductsError) {
                newProps.secondaryAllowedProductsError = nextProps.secondaryAllowedProductsError
                newProps.secondaryLoading = false

            }
        }

        return {
            ...newProps
        }
    }
    onClickBack = () => {
        history.push('/viewpackage')

    }
    onClickRetry = () => {
        const { paymentDetails } = this.props
        this.props.retryMobitelPackage(paymentDetails.transactionId)
    }
    onClickContinue = () => {
        localStorage.removeItem('basepackagePurchased')
        history.push('/auth')
    }

    render() {
        const { error } = this.state
        const { retryError, retryLoading, subscribe, subscribeLoading, paymentDetails, consentValidationError } = this.props
        let connectionType = getConnectionType()

        return (
            <div style={{ marginLeft: "-5%" }}>
                <div className="container card-page" >
                    {(subscribeLoading || retryLoading) ? <div className="row">
                        <DummyLoadingPage />
                    </div> :
                        <Container style={{ marginTop: '10%', marginLeft: '10%' }}>
                            {(error || retryError || consentValidationError) ? <PaymentFailed error={error || consentValidationError} retryError={paymentDetails} onClickBack={this.onClickBack} onClickRetry={this.onClickRetry} /> :
                                < PaymentSuccess subscribe={subscribe} onClickContinue={this.onClickContinue} connectionType={connectionType} />}

                        </Container>}
                </div>
            </div>


        )
    }
}
const mapStateToProps = (state) => {
    return {
        ...subscriptionSelectors.addConnection(state),
        ...subscriptionSelectors.addConnectionError(state),
        ...subscriptionSelectors.retryPayment(state)

    }
};

export default withRouter(connect(
    mapStateToProps,
    subscriptionActions
)(LoadingPage));

