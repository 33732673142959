import { createStructuredSelector } from 'reselect'

const getTypesSelector = state => state.Registration.connectionTypes.connectionTypes
const typesLoadingSelector = state => state.Registration.connectionTypes.connectionTypesLoading

const getDistrictsSelector = state => state.Registration.districts.districts

const nonSltDetailsSubmitSelector = state => state.Registration.nonSltDetails.nonSltDetails
const nonSltDetailsSubmitErrorSelector = state => state.Registration.nonSltDetails.nonSltDetailsError

const submitAccountDetails = state => state.Registration.userAccount.userAccount
const submitAccountDetailsError = state => state.Registration.userAccount.userAccountError

const submitDetailsSelector = state => state.Registration.submitUserDetails.submitUserDetails
const submitDetailsErrorSelector = state => state.Registration.submitUserDetails.submitUserDetailsFailed

const submitOtpSelector = state => state.Registration.otp.otp
const submitOtpErrorSelector = state => state.Registration.otp.otpError
const resendOtpSelector = state => state.Registration.resendOtp.resendOtp
const resendOtpErrorSelector = state => state.Registration.resendOtp.resendOtpError

const getCountrySelector = state => state.Registration.country.country
const getCountryLoadingSelector = state => state.Registration.country.countryLoading

const countryErrorSelector = state => state.Registration.country.countryError

const getAuthCodeSelector = state => state.Registration.authcode.authcode
const getAuthCodeErrorSelector = state => state.Registration.authcode.authcodeError

export default {
    getTypes: createStructuredSelector({
        types: getTypesSelector,
        typesLoading: typesLoadingSelector
    }),

    getDistricts: createStructuredSelector({
        districts: getDistrictsSelector
    }),

    submitDetails: createStructuredSelector({
        details: nonSltDetailsSubmitSelector,
        userAccount: submitAccountDetails,
        submitUserDetails: submitDetailsSelector,
        otp: submitOtpSelector,
        resendOtpMessage: resendOtpSelector,
        country : getCountrySelector,
        countryLoading : getCountryLoadingSelector
    }),

    getErrors: createStructuredSelector({
        detailsError: nonSltDetailsSubmitErrorSelector,
        userAccountError: submitAccountDetailsError,
        submitUserDetailsFailed: submitDetailsErrorSelector,
        otpError: submitOtpErrorSelector,
        resendOtpError: resendOtpErrorSelector,
        countryError : countryErrorSelector
    }),

    getAuthCode : createStructuredSelector({
        authcode : getAuthCodeSelector,
        authcodeError : getAuthCodeErrorSelector
    })
}

