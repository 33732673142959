
export default {
    GET_TYPES: "generalPages/GET_TYPES",
    GET_TYPES_FAILED: "generalPages/GET_TYPES_FAILED",
    GET_TYPES_SUCCESS: "generalPages/GET_TYPES_SUCCESS",

    GET_DISTRICTS: "generalPages/GET_DISTRICTS",
    GET_DISTRICTS_SUCCESS: "generalPages/GET_DISTRICTS_SUCCESS",
    GET_DISTRICTS_FAILED: "generalPages/GET_DISTRICTS_FAILED",

    NON_SLT_DETAILS: "generalPages/NON_SLT_DETAILS",
    NON_SLT_DETAILS_SUCCESS: "generalPages/NON_SLT_DETAILS_SUCCESS",
    NON_SLT_DETAILS_FAILED: "generalPages/NON_SLT_DETAILS_FAILED",

    CONFIRM_EMAIL: "generalPages/CONFIRM_EMAIL",
    CONFIRM_EMAIL_SUCCESS: "generalPages/CONFIRM_EMAIL_SUCCESS",
    CONFIRM_EMAIL_FAILED: "generalPages/CONFIRM_EMAIL_FAILED"

}