import React from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import { ProductCardPlaceholder, ProductAdditionDetailsPlaceholder} from './DummyComponent'


export default function DummyMyPackage({ customButtonText }) {
    return (
        <Container style={{ color: "#d0d0d0", marginTop: 35 }}>
            <Row>
                <Col sm={3} md={3} lg={2}>
                    <ProductCardPlaceholder />
                </Col>

                <Col>
                    <ProductAdditionDetailsPlaceholder />
                </Col>
            </Row>
        </Container>
    )
}