import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { purchasedProduct, bundledProduct,getTotalScreens } from '../../util/ArrayValidator';
import AdditionalChannels from './AdditionalChannels';
import MyVods from './MyVods';
import MySvods from './MySvods'
import { MyPackage } from './MyPackage'
import { Link } from "react-router-dom";
import DummyMyPackage from '../DummyComponent/DummyMyPackage';
import "./styles.scss";
import { MyDataBundle } from './MyDataBundle'
import { PEOTV_SERVICE_TYPE, PREPAID_SERVICE_TYPE, MOBITEL_SERVICE_TYPE, TRANSACTIONAL, PURCHASE_COMPLETE } from '../../util/Constants';
import MyPromoPackage from "./MyPromoPackage";

const getPurchasedPromotionalPackages = (data) => {
    let purchasedPromotionalPackages = [];
    data && data.map((pack, index) => {
        if (pack.purchaseStatus !== "NOT_PURCHASED") {
            purchasedPromotionalPackages.push(pack)
        }
        return purchasedPromotionalPackages
    })
    return purchasedPromotionalPackages
}

function MyContent(featuredPackages) {
    // let purchasedPrimaryDataBundle = getNestedValue(featuredPackages, ['purchasedPrimaryDataBundle'])
    let purchasedPrimaryDataBundle = featuredPackages && featuredPackages.purchasedPrimaryDataBundle
    let purchasedChannelValue = purchasedProduct(featuredPackages.additionalChannels && featuredPackages.additionalChannels.allUserChannels)
    let purchasedVodValue = purchasedProduct(featuredPackages.onDemandContents && featuredPackages.onDemandContents.myVODs)
    let purchasedSvodValue = purchasedProduct(featuredPackages.svod && featuredPackages.svod.mySVODs)
    let bundledScreen = bundledProduct(featuredPackages.screen && featuredPackages.screen.myScreen)
    let purchasedScreen = getTotalScreens(featuredPackages.featuredPackages &&featuredPackages.featuredPackages[0].purchasableProductList)
    let dataBundlesPurchased = false
    //check if user has purchased a databundle
    if (purchasedPrimaryDataBundle && purchasedPrimaryDataBundle.purchaseStatus===PURCHASE_COMPLETE) { dataBundlesPurchased = true } 

    //check if user has purchased a databundle
    // validArray(purchasedPrimaryDataBundle) && purchasedPrimaryDataBundle.map(data => { if (data.isPurchased) { dataBundlesPurchased = true } return dataBundlesPurchased });

    //get the purchased promo packs
    let purchasedPromotionalPackageArray = getPurchasedPromotionalPackages(featuredPackages.myPromotionalPackage);
    let isSubscriptional = featuredPackages.featuredPackages[0] && featuredPackages.featuredPackages[0].purchaseOption && featuredPackages.featuredPackages[0].purchaseOption.purchaseType === TRANSACTIONAL ? false : true

    return (
        <Container className="mt-4" style={{ color: "#fff" }}>
            {featuredPackages.featuredPackages === false && < DummyMyPackage />}

            {featuredPackages && featuredPackages.featuredPackages[0] &&
                <MyPackage packages={featuredPackages.featuredPackages[0]} onClick={featuredPackages.onClickPackage} />}
            <hr style={{ backgroundColor: "#4fa1d9", height: "1px" }} />

            <Row>
                {(featuredPackages.serviceType !== PEOTV_SERVICE_TYPE && featuredPackages.serviceType !== PREPAID_SERVICE_TYPE && featuredPackages.serviceType !== MOBITEL_SERVICE_TYPE) && localStorage.isBroadBand==="true"&& featuredPackages.featuredPackages !== false && <><MyDataBundle featuredPackages={featuredPackages.featuredPackages}
                    purchasedPrimaryDataBundle={purchasedPrimaryDataBundle}
                    dataBundlesPurchased={dataBundlesPurchased}
                    onClickDataBundle={featuredPackages.onClickDataBundle}
                />
                    {(featuredPackages.serviceType !== PEOTV_SERVICE_TYPE && featuredPackages.serviceType !== PREPAID_SERVICE_TYPE && featuredPackages.serviceType !== MOBITEL_SERVICE_TYPE) && <div className="vl" style={{
                        borderLeft: "2px solid #c2c2c2",
                        height: "185px"
                    }}></div>}
                </>}
                <Col xs={10} sm={3} md={12} lg={7}>
                    <Col ><p>DEVICES</p>
                        {featuredPackages && featuredPackages.screen && featuredPackages.screen.loading === true && < DummyMyPackage />}
                        {featuredPackages && featuredPackages.screen && featuredPackages.screen.loading === false && <Row >
                            <Col md={5}>
                                <img
                                    src={require("../../assets/images/Group.svg")}
                                    alt=''
                                    className="profile-screens-image"
                                />
                            </Col>
                            <Col md={7} className="number-of-devices-container">
                                {/* <p style={{ width: "fit-content", background: "#000000", padding: "10px" }}>Default number of devices <span style={{ background: "#4fa1d9", padding: "10px", color: "black", marginRight: "-10px", float: "right", marginTop: "-11px" }}>{bundledScreen[0] && bundledScreen[0].numberOfScreens}</span></p> */}
                                <Row>
                                    <Col md={9} className="profile-default-screens">
                                        <p className="def-number-screens">Default number of devices</p>
                                    </Col>
                                    <Col md={2} className="default-screens-number">
                                        {bundledScreen[0] && bundledScreen[0].numberOfScreens ? bundledScreen[0].numberOfScreens : 0}
                                    </Col>
                                </Row>
                                {purchasedScreen !== 0 && <Row>
                                    <Col md={12}>
                                        {/* <p style={{ width: "190px", background: "#000000", padding: "10px" }}>Additional devices <span style={{ background: "#f8921a", padding: "10px", color: "black", marginRight: "-10px", float: "right", marginTop: "-11px" }}>{purchasedScreen[0] && purchasedScreen[0].numberOfScreens ? purchasedScreen[0].numberOfScreens : 0}</span></p> */}
                                        <Row className="padding-top-10" >
                                            <Col md={9} className="profile-default-screens">
                                                <p className="def-number-screens">Additional devices</p>
                                            </Col>
                                            <Col md={2} className="additional-screens-number">
                                                {purchasedScreen  ? purchasedScreen : 0}
                                            </Col>
                                            {/* <Col md={1}> */}
                                            <Link className="screen-link"
                                                to={"/screens"}
                                                style={{ textDecoration: "none", paddingLeft: "2px" }}
                                            >
                                                <div style={styles.plus}>
                                                    <i
                                                        className="fas fa-plus"
                                                        style={{ color: "#F7921A", fontSize: 15 }}
                                                    ></i>
                                                </div>
                                            </Link>
                                            {/* </Col> */}
                                        </Row>
                                    </Col>
                                </Row>}
                                <Row>
                                    {purchasedScreen > 0 && isSubscriptional && <Col md={12} className="padding-top-10">
                                        <Link id="submit"
                                            type="submit"
                                            className="submit-button"
                                            to={"/screens"}
                                            style={{ textDecoration: "none", marginLeft: "-14px", width: "100%" }}
                                        >
                                            UNSUBSCRIBE
                                            </Link>
                                    </Col>}
                                </Row>
                            </Col>
                        </Row>

                        }
                    </Col>
                </Col>
            </Row>
            <hr style={{ backgroundColor: "#4fa1d9", height: "1px" }} />
            {featuredPackages.featuredPackages && purchasedChannelValue && purchasedChannelValue.length !== 0 && <><Row>
                <Col>
                    <p>ADDITIONAL CHANNELS</p>
                    <AdditionalChannels additionalChannels={featuredPackages.additionalChannels && featuredPackages.additionalChannels} channels={purchasedChannelValue}
                        showDate={false} isLoggedIn={true} channelProduct={true} informative={true} onClick={featuredPackages.onClickChannel} />
                </Col>
            </Row>
                <hr style={{ backgroundColor: "#4fa1d9", height: "1px" }} /></>}
            {featuredPackages.featuredPackages && purchasedVodValue && purchasedVodValue.length !== 0 && <><Row>
                <Col ><p>ON DEMAND CONTENT</p>
                    <MyVods onDemandContents={featuredPackages.onDemandContents && featuredPackages.onDemandContents} vod={purchasedVodValue} onClick={featuredPackages.onClickOndemand} />
                </Col>
            </Row>
                <hr style={{ backgroundColor: "#4fa1d9", height: "1px" }} /></>}
            {featuredPackages.featuredPackages && purchasedSvodValue && purchasedSvodValue.length !== 0 && <><Row>
                <Col ><p>SVOD</p>
                    <MySvods svod={featuredPackages.svod && featuredPackages.svod} purchasedSvod={purchasedSvodValue} onClick={featuredPackages.onClickSvod} />
                </Col>
            </Row><hr style={{ backgroundColor: "#4fa1d9", height: "1px" }} /></>}
            {featuredPackages.featuredPackages && purchasedPromotionalPackageArray.length !== 0 && <Row>
                <Col ><p>PROMOTIONAL PACKAGES</p>
                    <MyPromoPackage promotionalPackages={purchasedPromotionalPackageArray} onClick={featuredPackages.onClickPromo} />
                </Col>
            </Row>}
        </Container>
    )
}

export default MyContent
const styles = {
    plus: {
        display: "flex",
        justifyContent: "center",
        padding: 5,
        alignItems: "center",
        backgroundColor: "#fff",
        borderRadius: 5,
        // margin: 5,
        // marginLeft: 10,
        cursor: "pointer",
        width: "120%",
        height: "100%"
    }
}