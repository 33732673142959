import React, { Component } from 'react'
import ProgressiveImage from 'react-progressive-image';
import './index.scss';
import {
    getLoggedInStatus
} from '../../util/ArrayValidator';


class Channel extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <>
                <style type="text/css">
                    {`
                        .channel-image {
                            padding: 10px;
                            width: 90%;
                            border-radius: 5px;
                            border: solid 1px #000000;
                            background-color: rgba(0, 0, 0, 0.37);
                        }
                        @media only screen and (max-width: 575px) {
                            .channel-image {
                                width: 140px;
                            }
    
                            .inside-channel{
                                width: 140px !important;
                            }
                            .outside-channel {
                                width: 140px !important;
                            }
                            .outside-channel:hover .inside-channel{
                                width: 140px !important;
                            }
                            .triangle-options {
                                right: 0px !important;
                            }
                            .add-new-icon {
                                right: 5px !important;
                            }
                        }
    
                        .outside-channel{
                            position:relative;
                            margin-bottom: 20px;
                        }
                              
                        .inside-channel{
                            position:absolute;
                            height: 0;
                            bottom:0;
                            width:100%;
                            transition: .5s ease;
                            background-color: rgba(29, 29, 29, 0.9);
                            overflow:hidden;
                            display:flex;
                            flex-direction:column;
                            justify-content:center;
                            text-align:center;
                            align-self:center;
                            z-index:100;
                            width: 90%;
                            
                        }
                        .outside-channel:hover .inside-channel{
                            height: 100%;
                            display:flex;
                            flex-direction:column;
                            justify-content:center;
                            text-align:center;
                            align-self:center;
                            width: 90%;
                            border-radius: 5px;
                            cursor : pointer 
                        }
    
                        .outside-content{
                            padding:20px;
                        }
                        .inside-channel {
                            color:#ffff;
                            z-index:200;
                        }
    
                        .inside-channel > h6 {
                            font-size: 0.8rem;
                        }
                        
                        .triangle-options {
                            width: 0;
                            height: 0;
                            position: absolute;
                            right: 16px;
                            bottom: 0;
                            border-bottom: 60px solid #b3b3b3;
                            border-left: 50px solid transparent;
                        }
    
                        .triangle-options > div{
                            display: grid;
                            position: relative;
                            font-size: 10px;
                            top: 35px;
                            position: absolute;
                            right: 5px;
                        }
    
                        .triangle-options i {
                            color : #171717;
                            margin-bottom : 2px;
                        }
    
                        .add-new {
                            border: 1px solid #f8921a !important;
                        }
                        .add-new-no {
                            border: 1px solid #4fa1d9 !important;
                        }
                        .add-new-icon {
                            position: absolute;
                            background-color: #f8921a;
                            font-size: 12px;
                            padding: 4px;
                            border-radius: 50%;
                            right: 12px;
                            top: -10px;
                            z-index: 999
                        }
                        .inside-channel .price{
                            color:#eb8b1b;
                            z-index:200;
                            border-radius:10px;
                        }
    
                        .already-purchased{
                            position: absolute;
                            font-size: 15px;
                            padding: 4px;
                            right: 15px;
                            top: -4px;
                            color: #4fa1d9;
                            z-index:999;
                        }
                    
                    `}
                </style>
                <div className="outside-channel">
                    {

                        this.props.hover &&
                        <div className="inside-channel"
                            id={this.props.id}
                            onClick={this.props.onClick} >
                            <h6 id={this.props.id} onClick={this.props.onClick} style={{ marginTop: "auto" }}>{this.props.channelName}</h6>
                            {/* <h6 id={id} onClick={onClick} className="price">Rs. {price}</h6> */}
                            {(this.props.isLoggedIn && this.props.showAddNew) && <h6 style={{ color: "#a86d01" }} id={this.props.id} onClick={this.props.onClick}>  {`Rs ${this.props.price}`}</h6>}

                            {this.props.showPrice && this.props.svod && this.props.svod <= 30 ? this.props.svod !== undefined && this.props.svod <= 3 ? <div id={this.props.id} style={{ backgroundColor: "red", marginTop: "auto" }}>
                                {this.props.svod} days to expire</div> :
                                <div id={this.props.id} style={{ backgroundColor: "#4FA1D9", marginTop: "auto" }}>
                                    {this.props.svod} days to expire</div> : <div style={{ marginTop: "auto" }}></div>
                            }

                            <br />
                            {/* {svod && <div style={{ backgroundColor: "red", marginTop: "auto" }}>{svod} days to expire</div>} */}
                        </div>
                    }
                    {/* <img
                        // src={require("./001.jpg")}
                        // src={imageURL != null ? imageURL : require("./../../images/ChannelnotFound.jpg")}
                        // src={"http://172.17.250.85:8080/" + imageURL}
                        src={`/${this.props.imageURL}`}
                        className={`channel-image ${this.props.showAddNew ? 'add-new' : 'add-new-no'}`}
                        onClick={this.props.onClick}
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = require("./../../images/ChannelnotFound.jpg")
                        }}
                    /> */}

                    <ProgressiveImage
                        src={`/${this.props.imageURL}`}
                        placeholder={require("./../../images/ChannelnotFound.jpg")}
                        className={`channel-image ${this.props.showAddNew ? 'add-new' : 'add-new-no'}`}

                    >
                        {(src, loading) => (
                            <img style={{
                                width: '90%',
                                // marginBottom: 20,
                                opacity: loading ? 0.5 : 1,
                                padding: "10px",
                                borderRadius: 5,
                                backgroundColor: 'rgba(0, 0, 0, 0.37)',
                                border: '1px solid #000',
                                transition: '2s filter linear',
                                filter: `${loading ? 'blur(1px)' : ''}`,
                            }}
                                className={`channel-image ${this.props.showAddNew ? 'add-new' : 'add-new-no'}`}
                                src={src}
                                alt=''
                            />

                        )}
                    </ProgressiveImage>
                    {this.props.showAddNew && <i className="add-new-icon fas fa-plus"></i>}
                    {getLoggedInStatus() && this.props.isPurchased && <i class="already-purchased fas fa-check-square"></i>}
                    {
                        (this.props.showOptions && (this.props.showOptions.tstv || this.props.showOptions.pip || this.props.showOptions.cpvr)) &&
                        <div className="triangle-options">
                            <div>
                                {this.props.showOptions.tstv && <i className="fa fa-history" />}
                                {/* <i className="fa fa-battery-quarter" />
                                <i className="fa fa-video" /> */}
                            </div>
                        </div>
                    }
                    {
                        this.props.showViewBundleButton &&
                        <div>
                            <button style={{
                                backgroundColor: '#4fa1d9',
                                border: 0,
                                color: '#fff',
                                fontSize: 13,
                                borderBottomLeftRadius: 5,
                                borderBottomRightRadius: 5,
                                width: "90%",
                                position: 'absolute',
                                marginTop: -22
                            }}>View Bundle</button>
                        </div>
                    }
                </div>
            </>
        )
    }
}

export default Channel;

// export default function Channel({
//     hover,
//     showOptions,
//     showAddNew,
//     channelName,
//     imageURL,
//     price,
//     onClick,
//     id,
//     isPurchasable,
//     svod,
//     showPrice,
//     showViewBundleButton,
//     isPurchased,
//     isBundled,
//     showPurchaseIcon,
//     isLoggedIn
// }) {
    // return (
    //     <>
    //         <style type="text/css">
    //             {`
    //                 .channel-image {
    //                     padding: 10px;
    //                     width: 90%;
    //                     border-radius: 5px;
    //                     border: solid 1px #000000;
    //                     background-color: rgba(0, 0, 0, 0.37);
    //                 }
    //                 @media only screen and (max-width: 575px) {
    //                     .channel-image {
    //                         width: 140px;
    //                     }

    //                     .inside-channel{
    //                         width: 140px !important;
    //                     }
    //                     .outside-channel {
    //                         width: 140px !important;
    //                     }
    //                     .outside-channel:hover .inside-channel{
    //                         width: 140px !important;
    //                     }
    //                     .triangle-options {
    //                         right: 0px !important;
    //                     }
    //                     .add-new-icon {
    //                         right: -10px !important;
    //                     }
    //                 }

    //                 .outside-channel{
    //                     position:relative;
    //                     margin-bottom: 20px;
    //                 }

    //                 .inside-channel{
    //                     position:absolute;
    //                     height: 0;
    //                     bottom:0;
    //                     width:100%;
    //                     transition: .5s ease;
    //                     background-color: rgba(29, 29, 29, 0.9);
    //                     overflow:hidden;
    //                     display:flex;
    //                     flex-direction:column;
    //                     justify-content:center;
    //                     text-align:center;
    //                     align-self:center;
    //                     z-index:100;
    //                     width: 90%;

    //                 }
    //                 .outside-channel:hover .inside-channel{
    //                     height: 100%;
    //                     display:flex;
    //                     flex-direction:column;
    //                     justify-content:center;
    //                     text-align:center;
    //                     align-self:center;
    //                     width: 90%;
    //                     border-radius: 5px;
    //                     cursor : pointer 
    //                 }

    //                 .outside-content{
    //                     padding:20px;
    //                 }
    //                 .inside-channel {
    //                     color:#ffff;
    //                     z-index:200;
    //                 }

    //                 .inside-channel > h6 {
    //                     font-size: 0.8rem;
    //                 }

    //                 .triangle-options {
    //                     width: 0;
    //                     height: 0;
    //                     position: absolute;
    //                     right: 16px;
    //                     bottom: 0;
    //                     border-bottom: 60px solid #b3b3b3;
    //                     border-left: 50px solid transparent;
    //                 }

    //                 .triangle-options > div{
    //                     display: grid;
    //                     position: relative;
    //                     font-size: 10px;
    //                     top: 35px;
    //                     position: absolute;
    //                     right: 5px;
    //                 }

    //                 .triangle-options i {
    //                     color : #171717;
    //                     margin-bottom : 2px;
    //                 }

    //                 .add-new {
    //                     border: 1px solid #f8921a;
    //                 }
    //                 .add-new-no {
    //                     border: 1px solid #4fa1d9;
    //                 }
    //                 .add-new-icon {
    //                     position: absolute;
    //                     background-color: #f8921a;
    //                     font-size: 12px;
    //                     padding: 4px;
    //                     border-radius: 50%;
    //                     right: 12px;
    //                     top: -10px;
    //                     z-index: 999
    //                 }
    //                 .inside-channel .price{
    //                     color:#eb8b1b;
    //                     z-index:200;
    //                     border-radius:10px;
    //                 }

    //                 .already-purchased{
    //                     position: absolute;
    //                     font-size: 15px;
    //                     padding: 4px;
    //                     right: 15px;
    //                     top: -4px;
    //                     color: #4fa1d9;
    //                     z-index:999;
    //                 }

    //             `}
    //         </style>
    //         <div className="outside-channel">
    //             {

    //                 hover &&
    //                 <div className="inside-channel"
    //                     id={id}
    //                     onClick={onClick} >
    //                     <h6 id={id} onClick={onClick} style={{ marginTop: "auto" }}>{channelName}</h6>
    //                     {/* <h6 id={id} onClick={onClick} className="price">Rs. {price}</h6> */}
    //                     {(isLoggedIn && showAddNew) && <h6 style={{ color: "#a86d01" }} id={id} onClick={onClick}>  {`Rs ${price}`}</h6>}

    //                     {showPrice && svod && svod <= 30 ? svod != undefined && svod <= 3 ? <div id={id} style={{ backgroundColor: "red", marginTop: "auto" }}>
    //                         {svod} days to expire</div> :
    //                         <div id={id} style={{ backgroundColor: "#4FA1D9", marginTop: "auto" }}>
    //                             {svod} days to expire</div> : <div style={{ marginTop: "auto" }}></div>
    //                     }

    //                     <br />
    //                     {/* {svod && <div style={{ backgroundColor: "red", marginTop: "auto" }}>{svod} days to expire</div>} */}
    //                 </div>
    //             }
    //             <img
    //                 // src={require("./001.jpg")}
    //                 // src={imageURL != null ? imageURL : require("./../../images/ChannelnotFound.jpg")}
    //                 // src={"http://172.17.250.85:8080/" + imageURL}
    //                 src={`/${imageURL}`}
    //                 className={`channel-image ${showAddNew ? 'add-new' : 'add-new-no'}`}
    //                 onClick={onClick}
    //                 onError={(e) => {
    //                     e.target.onerror = null;
    //                     e.target.src = require("./../../images/ChannelnotFound.jpg")
    //                 }}
    //             />
    //             {showAddNew && <i className="add-new-icon fas fa-plus"></i>}
    //             {localStorage.jwt && isPurchased && <i class="already-purchased fas fa-check-square"></i>}
    //             {
    //                 (showOptions && (showOptions.tstv || showOptions.pip || showOptions.cpvr)) &&
    //                 <div className="triangle-options">
    //                     <div>
    //                         {showOptions.tstv && <i className="fa fa-history" />}
    //                         {/* <i className="fa fa-battery-quarter" />
    //                         <i className="fa fa-video" /> */}
    //                     </div>
    //                 </div>
    //             }
    //             {
    //                 showViewBundleButton &&
    //                 <div>
    //                     <button style={{
    //                         backgroundColor: '#4fa1d9',
    //                         border: 0,
    //                         color: '#fff',
    //                         fontSize: 13,
    //                         borderBottomLeftRadius: 5,
    //                         borderBottomRightRadius: 5,
    //                         width: "90%",
    //                         position: 'absolute',
    //                         marginTop: -22
    //                     }}>View Bundle</button>
    //                 </div>
    //             }

    //         </div>
    //     </>
    // )
// }
