export default {
    GET_TYPES: "registration/GET_TYPES",
    GET_TYPES_FAILED: "registration/GET_TYPES_FAILED",
    GET_TYPES_SUCCESS: "registration/GET_TYPES_SUCCESS",

    GET_DISTRICTS: "registration/GET_DISTRICTS",
    GET_DISTRICTS_SUCCESS: "registration/GET_DISTRICTS_SUCCESS",
    GET_DISTRICTS_FAILED: "registration/GET_DISTRICTS_FAILED",

    NON_SLT_DETAILS: "registration/NON_SLT_DETAILS",
    NON_SLT_DETAILS_SUCCESS: "registration/NON_SLT_DETAILS_SUCCESS",
    NON_SLT_DETAILS_FAILED: "registration/NON_SLT_DETAILS_FAILED",

    ACCOUNT_LOGIN: "registration/ACCOUNT_LOGIN",
    ACCOUNT_LOGIN_FAILED: "registration/ACCOUNT_LOGIN_FAILED",
    ACCOUNT_LOGIN_SUCCESS: "registration/ACCOUNT_LOGIN_SUCCESS",

    SUBMIT_DETAILS: "registration/SUBMIT_DETAILS",
    SUBMIT_DETAILS_SUCCESS: "registration/SUBMIT_DETAILS_SUCCESS",
    SUBMIT_DETAILS_FAILED: "registration/SUBMIT_DETAILS_FAILED",

    SUBMIT_OTP: "registration/SUBMIT_OTP",
    SUBMIT_OTP_SUCCESS: "registration/SUBMIT_OTP_SUCCESS",
    SUBMIT_OTP_FAILED: "registration/SUBMIT_OTP_FAILED",

    RESEND_OTP: "registration/ RESEND_OTP",
    RESEND_OTP_SUCCESS: "registration/ RESEND_OTP_SUCCESS",
    RESEND_OTP_FAILED: "registration/ RESEND_OTP_FAILED",

    CLEAR_ERROR_MESSAGE: "registration/CLEAR_ERROR_MESSAGE",

    GET_COUNTRY: "registration/GET_COUNTRY",
    GET_COUNTRY_SUCCESS: "registration/ GET_COUNTRY_SUCCESS",
    GET_COUNTRY_FAILED: "registration/ GET_COUNTRY_FAILED",

    //prepaid registration
    GET_AUTH_CODE : "registration/GET_AUTH_CODE",
    GET_AUTH_CODE_SUCCESS : "registration/GET_AUTH_CODE_SUCCESS",
    GET_AUTH_CODE_FAILED : "registration/GET_AUTH_CODE_FAILED"
}