import React from 'react'

export const DataBundle = ({
    loading,
    dataQuota

}) => {
    return <>{dataQuota &&
        <div className="data-bundle-header-label">
            <span className="data-bundle-text">DATA BUNDLE</span>
            <span className="data-bundle-gb-label">
                {dataQuota === -1 ? "Unlimited Data" : dataQuota + " GB"}</span></div>}
    </>
}
