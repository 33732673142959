import reducers from "./reducers";

import registrationSelectors from "./selectors";
import registrationService from "./service";
import registrationTypes from "./types";
import registrationActions from "./actions";

export {
    registrationSelectors,
    registrationService,
    registrationActions,
    registrationTypes
};

export default reducers;