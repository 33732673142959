const action_header = "allProducts/"
export default {
    GET_ALL_PRODUCTS: action_header + "GET_ALL_PRODUCTS",
    //---------------------------------------------------------------------
    GET_ALL_SVOD_SUCCESS: action_header + "GET_ALL_SVOD_SUCCESS",
    GET_ALL_SVOD_FAIL: action_header + "GET_ALL_SVOD_FAIL",

    GET_USER_SVOD_SUCCESS: action_header + "GET_USER_SVOD_SUCCESS",
    GET_USER_SVOD_FAIL: action_header + "GET_USER_SVOD_FAIL",
    //---------------------------------------------------------------------
    //---------------------------------------------------------------------
    GET_USER_DEVICE_SUCCESS: action_header + "GET_USER_DEVICE_SUCCESS",
    GET_USER_DEVICE_FAIL: action_header + "GET_USER_DEVICE_FAIL",
    //---------------------------------------------------------------------
    //---------------------------------------------------------------------
    GET_USER_DATA_BUNDLE_SUCCESS: action_header + "GET_USER_DATA_BUNDLE_SUCCESS",
    GET_USER_DATA_BUNDLE_FAIL: action_header + "GET_USER_DATA_BUNDLE_FAIL",
    //---------------------------------------------------------------------
    //---------------------------------------------------------------------
    GET_ALL_CHANNEL_SUCCESS: action_header + "GET_ALL_CHANNEL_SUCCESS",
    GET_ALL_CHANNEL_FAIL: action_header + "GET_ALL_CHANNEL_FAIL",

    GET_USER_CHANNEL_SUCCESS: action_header + "GET_USER_CHANNEL_SUCCESS",
    GET_USER_CHANNEL_FAIL: action_header + "GET_USER_CHANNEL_FAIL",
    //---------------------------------------------------------------------
    //---------------------------------------------------------------------
    GET_ALL_VOD_SUCCESS: action_header + "GET_ALL_VOD_SUCCESS",
    GET_ALL_VOD_FAIL: action_header + "GET_ALL_VOD_FAIL",

    GET_USER_VOD_SUCCESS: action_header + "GET_USER_VOD_SUCCESS",
    GET_USER_VOD_FAIL: action_header + "GET_USER_VOD_FAIL",
    //---------------------------------------------------------------------
    //------------------------VOD UTILS------------------------------------
    //------------------------VOD GET SUB TYPES----------------------------
    GET_ONDEMAND_SUB_TYPES: action_header + "GET_ONDEMAND_SUB_TYPES",
    GET_ONDEMAND_SUB_TYPES_SUCCESS: action_header + "GET_ONDEMAND_SUB_TYPES_SUCCESS",
    GET_ONDEMAND_SUB_TYPES_FAIL: action_header + "GET_ONDEMAND_SUB_TYPES_FAIL",
    //---------------------------------------------------------------------

    //------------------------CHANNEL GET CATEGORIES----------------------------
    GET_CHANNEL_CATEGORIES: action_header + "GET_CHANNEL_CATEGORIES",
    GET_CHANNEL_CATEGORIES_SUCCESS: action_header + "GET_CHANNEL_CATEGORIES_SUCCESS",
    GET_CHANNEL_CATEGORIES_FAIL: action_header + "GET_CHANNEL_CATEGORIES_FAIL",

    //---------------------------------------------------------------------
    //------------------------PACKAGE UTILS------------------------------------
    //------------------------ALLOWED PACKAGE IDS----------------------------
    GET_ALLOWED_PACKAGE_IDS: action_header + "GET_ALLOWED_PACKAGE_IDS",
    GET_ALLOWED_PACKAGE_IDS_SUCCESS: action_header + "GET_ALLOWED_PACKAGE_IDS_SUCCESS",
    GET_ALLOWED_PACKAGE_IDS_FAILED: action_header + "GET_ALLOWED_PACKAGE_IDS_FAILED",

    //------------------------GET ALL PACKAGES FROM ALLOWED IDS----------------------------
    GET_ALL_PACKAGES: action_header + "GET_ALL_PACKAGES",
    GET_ALL_PACKAGES_SUCCESS: action_header + "GET_ALL_PACKAGES_SUCCESS",
    GET_ALL_PACKAGES_FAILED: action_header + " GET_ALL_PACKAGES_FAILED",

    //------------------------GET ALL PACKAGES FROM ALLOWED IDS----------------------------
    GET_DISTINCT_PRODUCTS: action_header + "GET_DISTINCT_PRODUCTS",
    GET_DISTINCT_PRODUCTS_SUCCESS : action_header + "GET_DISTINCT_PRODUCTS_SUCCESS",
    GET_DISTINCT_PRODUCTS_FAILED :action_header +"GET_DISTINCT_PRODUCTS_FAILED",
    GET_DISTINCT_PRODUCTS_LOADING : action_header + "GET_DISTINCT_PRODUCTS_LOADING",

    //------------------------GET PRODUCT TYPES----------------------------
    GET_PRODUCT_TYPES : action_header +"GET_PRODUCT_TYPES",
    GET_PRODUCT_TYPES_SUCCESS : action_header +"GET_PRODUCT_TYPES_SUCCESS",
    GET_PRODUCT_TYPES_FAILED : action_header + "GET_PRODUCT_TYPES_FAILED",

    //---------------------------------------------------------------------

    RESET_FORM: action_header + "RESET_FORM",
    RESET_PACKAGES : action_header + "RESET_PACKAGES",

    //get the promotional packages
    GET_PROMOTIONAL_PACKAGES: action_header + "GET_PROMOTIONAL_PACKAGES",
    GET_PROMOTIONAL_PACKAGES_SUCCESS: action_header + "GET_PROMOTIONAL_PACKAGES_SUCCESS",
    GET_PROMOTIONAL_PACKAGES_FAILED: action_header + "GET_PROMOTIONAL_PACKAGES_FAILED",


    FILTER_PACKAGES : action_header + "FILTER_PACKAGES"

}  