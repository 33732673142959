import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { Row, Col, Container } from 'react-bootstrap'
import { getBaseProductsSelectors, getBaseProductsActions } from './ducks';
import { productSelectors } from './../landingPage/ducks';
import Channel from '../../components/channel'
import Description from './../../components/controls/Description';
import DummyProductPageView from '../../components/DummyComponent/DummyProductPageView';

const styles = {
    information: {
        display: 'flex',
        fontSize: 'small',
        backgroundColor: '#1c1b1b',
        padding: 10,
        flexDirection: 'column',
        borderRadius: 5
    }
}

class SubVodPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            channel: undefined,
            connectionDetails: undefined,
            id: undefined,
            showModal: false,
            showModals: false,
            notifyStatus: false
        }
    }

    componentDidMount() {
        let id = this.props.match && this.props.match.params.id;
        this.props.getVodItems(id);
    }

    componentDidUpdate(prevProps, state) {
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.channel && nextProps.channel !== prevState.channel) {
            return ({
                channel: nextProps.channel
            })
        }

    }

    componentWillUnmount() {
        this.props.clearAllChannels();
    }

    render() {
        const { channelsLoading, channel } = this.props;
        return (
            <>
                <style type="text/css">
                    {`
                    .information > div {
                        padding : 2px 0px
                    }
                    .purchase-btn{
                       background: #f8921a;
                       outline:0;
                       border: 0;
                       width: 210px;
                       font-weight:bold;                       
                    }
                    .tag-list{
                        padding-bottom: 10px;
                    }
                    .information p{
                        margin: 0;
                        margin-bottom: 2px;
                    }
                    .vod-description{
                        margin-top: 20px;
                    }
                    @font-face {
                        font-family: SegoeUI;
                        src: local("Segoe UI");
                             url('./ui/segoeui.eot?#iefix') format('embedded-opentype')
                    }
                `}
                </style>
                <>
                    {channelsLoading ?
                        <React.Fragment>
                            <DummyProductPageView
                                productType={true}
                                showPurchasedIcon={true}
                                priceDisplay={true}
                            />
                        </React.Fragment>
                        :
                        <Container style={{ color: "#d0d0d0", marginTop: "40px" }}>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>{channel && <h4>{channel.name}</h4>}</Col>
                                    </Row>
                                    <Row>
                                        {channel && channel.daysRemainingToExpire <= 30 && <Col>{channel && (channel.daysRemainingToExpire !== undefined && channel.daysRemainingToExpire <= 3) ? <div><span style={{ fontSize: 15, marginTop: "auto", color: "red" }}>{channel && channel.daysRemainingToExpire} days to expire</span></div> : <div><span style={{ fontSize: 15, marginTop: "auto" }}>{channel && channel.daysRemainingToExpire} days to expire</span></div>}<br /></Col>}
                                    </Row>
                                </Col>
                                <Col sm={8}></Col>
                                <Col>
                                    {channel && channel.isPurchased ? (<span> <i className="fa fa-check-square" style={{ color: "#4FA1D9", fontSize: 18 }}></i>{" "}Already Purchased</span>) : ("")}
                                    {/* {isLoggedIn && channel && (!channel.isBundled && !channel.isPurchased ) && <h4 style={{paddingTopp:"10px"}}>Rs. {channel && channel.purchaseOption && channel.purchaseOption.defaultPrice} + tax</h4>} */}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3} md={3} lg={2}>
                                    <Channel hover={false} showAddNew={false} imageURL={channel && channel.imageURL} />
                                </Col>
                                <Col>{channel && (channel.pgRating || (channel.additionalDetails && Array.isArray(channel.additionalDetails))) &&
                                    <div className="information" style={styles.information}>
                                        <div>
                                            {channel && channel.additionalDetails && Array.isArray(channel.additionalDetails) && channel.additionalDetails.map(details => {
                                                return <><p>{details.code} : <span>{details.name}</span></p></>
                                            })
                                            }
                                            {channel && channel.pgRating && <><span>PG Rating</span> : <span>{channel && channel.pgRating}</span></>}
                                        </div>
                                    </div>}
                                </Col>
                            </Row>
                            <Row className="vod-description" style={{ textAlign: "justify" }}>
                                <Col>
                                    {/* {channel && <p className="text-justify">{channel && channel.description}</p>} */}
                                    {/* <textarea disabled style={{
                                        backgroundColor: 'transparent', minHeight: "300px", width: '100%', color: "#ffffff", borderStyle: "none",
                                        borderColor: "Transparent",
                                        // overflow: "hidden",
                                        // lineHeight: "100%",
                                        scrollbarBaseColor: "red"
                                    }}
                                    // onkeyup="new do_resize(this);"
                                    // onKeyUp={(e) => this.do_resize(e)} 

                                    >
                                        {channel && channel.description}
                                    </textarea> */}

                                    {/* <TextareaAutosize
                                        style={{
                                            backgroundColor: 'transparent',
                                            // minHeight: "300px",
                                            width: '100%',
                                            color: "#ffffff",
                                            borderStyle: "none",
                                            borderColor: "Transparent",
                                            // display: "table"
                                            overflow: "hidden",
                                            textAlign: "justify"
                                            // lineHeight: "100%",
                                        }}
                                        // rows={4}
                                        disabled
                                        defaultValue={channel && channel.description}
                                        useCacheForDOMMeasurements
                                        onHeightChange={(height, instance) => console.log("TextareaAutosize : ", height, instance.rowCount)}
                                    /> */}
                                    <Description
                                        description={channel.description}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    }
                </>
            </>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        ...getBaseProductsSelectors.getBaseProducts(state),
        ...productSelectors.getDetails(state),
        connectionDetails: getBaseProductsSelectors.getConnectionDetails(state),
        connectionDetailsLoading: getBaseProductsSelectors.getConnectionDetailsLoading(state)

    }
};
export default withRouter(connect(
    mapStateToProps,
    getBaseProductsActions
)(SubVodPage));     
