//URL constants
export const AllChannels = "/channels";
export const AllOnDemand = "/ondemand";
export const AllSvods = "/svod";
export const AllScreens = "/screens";
export const AllDataBundles = "/databundles";
export const SingleProductPage = "/products";

//Products Type IDs
export const SVOD_ID = "5d2e005cacd856260c231b01";
export const DEVICE_ID = "5d48fc3fee679725a0717187";
export const DATA_BUNDLE_ID = "5d444c40ee6797225cdead4b";
export const CHANNEL_ID = "5d22e5e222224e2be4ad4045";
export const VOD_ID = "5d22e60422224e2be4ad4046";
export const PACKAGE_ID = "5d22e59e22224e2be4ad4044";
export const PROMOTIONAL_PACKAGE_ID = "5e7e12feb36fb236bc25a371"
export const CHANNEL_BUNDLE_ID = "5d35fee9acd8562438d09757"

//Placeholder component
export const primaryColor = "#252525"
export const secondaryColor = "#313131"

export const cutomerBtnprimaryColor = "#f9ae5b"
export const cutomerBtnsecondaryColor = "#ffc58a"

export const PEOTV_SERVICE_TYPE = "SLT PEO TV";
export const PREPAID_SERVICE_TYPE = "Prepaid";
export const MOBITEL_SERVICE_TYPE= "Mobitel"
export const SLT_SERVICE_TYPE ="slt"


export const PURCHASE_COMPLETE = "PURCHASE_COMPLETE";
export const PURCHASE_PENDING_REMOVE = "PURCHASE_PENDING_REMOVE";
export const PRODUCT_NOT_PURCHASED = "NOT_PURCHASED";
export const PURCHASE_FAILED = "PURCHASE_FAILED";

export const postpaid = "POSTPAID";
export const months = "MONTHS";

export const carouselWidth = () => {
    if (1200 < window.innerWidth) {
        return 225
    } else if (990 < window.innerWidth) {
        return 240
    } else if (900 < window.innerWidth) {
        return 180
    } else if (770 < window.innerWidth) {
        return 300
    } else if (550 < window.innerWidth) {
        return 180
    } else if (440 < window.innerWidth) {
        return 210
    } else if (330 < window.innerWidth) {
        return 220
    } else {
        return 180
    }

}


//purchase constants
export const TRANSACTIONAL = "PURCHASE_TYPE_TRANSACTIONAL";
export const SUBSCRIPTIONAL = "PURCHASE_TYPE_SUBSCRIPTION";

export const PRODUCT = "PRODUCT";
export const USER = "USER";

export const IS_SANDBOX = false
export const trackingId = "UA-19164359-5";

export const SCREEN_PRODUCT_TYPE_TYPE_NAME= "screens"

export const MOBITEL_CONSENT_RETURN_URL ="https://selfcare.peotv.com/paymentStatus"

export const MOBITEL_CONSET_PORTAL_URL = "https://services.mobitel.lk/MCCPortal/service/?compId=SLT&reqType=ACTIVE&servId=PTVG&"

export const PAYMENT_SUCCESS ="PAYMENT_SUCCESS"

export const DEFAULT_ONDEMAND_SUBTYPE = "PremiereMovies"

export const DURATION = 5

export const PRODUCT_COUNT = 20

export const INCREASE_PRODUCT_COUNT_TIMEOUT = 2000

export const MOBITEL_HEADER_ENRICHMENT = "http://mobitel.peotv.com"

export const CHANNELS_PRODUCT_TYPE = {
    "id": "5d22e5e222224e2be4ad4045",
    "name": "Channels",
    "productTypeId": "channel",
    "typeName": "channel",
    "isActive": true
}

export const PRODUCT_STATUS_ACTIVE = "PRODUCT_STATUS_ACTIVE"