import React, { Component } from "react";
import { Container } from "react-bootstrap";
// import Channel from './../channel'
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { InputField } from "../../components/controls/Fields";
import { CustomButton } from './../../components/controls/CustomButton';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.loading !== prevState.loading) {
      return ({
        loading: nextProps.loading
      })
    }
    return null
  }

  componentWillUnmount() {
    this.props.initialize({
      currentPassword: "",
      newPassword: "",
      confirmPassword: ""
    });
  }


  passwordUpdateHandler = values => {
    this.props.passwordUpdateHandler(
      values.currentPassword,
      values.confirmPassword
    );
    this.setState({
      loading: true
    })
    this.props.initialize({
      currentPassword: "",
      newPassword: "",
      confirmPassword: ""
    });

  };

  onClick = () => {
    this.props.initialize({
      currentPassword: "",
      newPassword: "",
      confirmPassword: ""
    });
    this.props.onClick();
  };
  render() {
    const { handleSubmit } = this.props;

    return (
      <div
        className={`modal fade  bd-example-modal-lg ${
          this.props.showModal ? "show" : ""
          }`}
        // id="exampleModal"
        tabIndex="-1"
        role="dialog"
        style={this.props.modalStyle}
      >
        {/* <div className="modal-dialog col-sm-12" role="document"> */}
        <div className="modal-dialog modal-lg">
          {/* {
            this.props.isForm ? */}
          <div className="modal-content">
            <div className="modal-header-one">
              <h5 className="modal-title" id="exampleModalLabel">
                {this.props.modalHeaderText}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                // onClick={this.props.onClose}
                onClick={() => this.onClick()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={handleSubmit(this.passwordUpdateHandler)}>
              <div className="modal-body modal-change-password scrollbar-info">
                <div className="header-margin">
                  <Container style={{ color: "#d0d0d0" }}>
                    <div className="register-form">
                      <div className="row" style={{ margin: 5 }}>
                        <div className="col-sm-1"></div>
                        <div className="col-sm-5">Current Password </div>
                        <div className="col-sm-5">
                          <Field
                            type="password"
                            className="form-control"
                            placeholder=""
                            name="currentPassword"
                            component={InputField}
                            style={{ backgroundColor: "rgba(0, 0, 0, 0)", color: "#ffffff" }}
                          />
                        </div>

                        <div className="col-sm-1"></div>
                      </div>

                      <div className="row" style={{ margin: 5 }}>
                        <div className="col-sm-1"></div>
                        <div className="col-sm-5">New Password </div>
                        <div className="col-sm-5">
                          <Field
                            type="password"
                            className="form-control"
                            placeholder=""
                            name="newPassword"
                            component={InputField}
                            style={{ backgroundColor: "rgba(0, 0, 0, 0)", color: "#ffffff" }}
                          />
                        </div>

                        <div className="col-sm-1"></div>
                      </div>

                      <div className="row" style={{ margin: 5 }}>
                        <div className="col-sm-1"></div>
                        <div className="col-sm-5">Confirm Password </div>
                        <div className="col-sm-5">
                          <Field
                            type="password"
                            className="form-control"
                            placeholder=""
                            name="confirmPassword"
                            component={InputField}
                            style={{ backgroundColor: "rgba(0, 0, 0, 0)", color: "#ffffff" }}
                          />
                        </div>

                        <div className="col-sm-1"></div>
                      </div>

                      <div
                        className="row"
                        style={{ margin: "12px 0px 12px 0px" }}
                      >
                        <div className="col-sm-1"></div>
                        <div className="col-sm-10">
                          <p className="text-sm">
                            Password should be at least 8 character long.
                            Password can only contain letters, number or special
                            characters.
                            <span style={{ color: "red" }}>*</span>
                          </p>
                        </div>
                        <div className="col-sm-1"></div>
                      </div>

                      <div className="row" >
                        <div className="col-sm-1"></div>
                        <div class="btn-group" role="group" style={{ marginLeft: '25px' }}>
                          {" "}
                          <CustomButton
                            id="changePassword"
                            disabled={this.state.loading}
                            loading={this.state.loading}
                            text="Change"
                            // style={{ float: "right" }} 
                            style={{
                              display: "inline-flex",
                              justifyContent: "center",
                              alignItems: "center",
                              // margin: -10
                            }}
                          />
                          <div className="col-sm-1"></div>
                          <CustomButton
                            id="changePasswordCancel"
                            text="Cancel"
                            onClick={() => this.props.onClose()}
                            style={{ margin: 0, backgroundColor: "#a0a0a0", float: "right" }}
                          />

                        </div>
                        {/* <div className="col-sm-2"></div> */}
                      </div>
                    </div>
                  </Container>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      // </div>
    );
  }
}

const validate = values => {
  const errors = {};
  if (!values.currentPassword) {
    errors.currentPassword = "Current Password is Required";
  }

  // if (values.directMapping == 'Yes' && !values.ern) {
  //     errors.ern = 'External reference number is Required'
  // }
  if (!values.newPassword) {
    errors.newPassword = "New Password is Required";
  }
  if (values.newPassword && !/^[a-zA-Z0-9!@#$%^&*)(+=._-]{8,}$/i.test(values.newPassword)) {
    errors.newPassword = "Password should be at least 8 character long.";
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = "Confirm Password is Required";
  }
  if (values.confirmPassword && !/^[a-zA-Z0-9!@#$%^&*)(+=._-]{8,}$/i.test(values.confirmPassword)) {
    errors.confirmPassword = "Password should be at least 8 character long.";
  }
  if (values.newPassword !== values.confirmPassword) {
    errors.confirmPassword = "Does not match password";
  }
  return errors
};

const mapStateToProps = state => {
  return {
    loading: state.Products.passwordUpdate.loading,

  };
};
export default reduxForm({
  form: "changePassword",
  validate
})(
  connect(
    mapStateToProps,
    null
  )(ChangePassword)
);
