import React from 'react'
import { Row, Col } from 'react-bootstrap';
import { getProductPrice } from '../../util/ArrayValidator';
import moment from 'moment';
import { TRANSACTIONAL } from '../../util/Constants';
export const MyPackage = ({
    packages,
    onClick

}) => {
    return <Row>

        <Col md={3}>
            <img
                id={1}
                className={'selectedImage'}
                src={`/${packages.imageURL}`}
                // className={`${selectedImage ? 'selectedImage' : 'not-selected'}`}
                // src={require("./image.png")}
                style={{ width: '100%', height: 'auto' }}
                onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = require("./../../images/ChannelnotFound.jpg")
                }}
                alt="my-package"
            />
        </Col>
        <Col md={9} className="profile-package">
            <Row>
                <Col md={12}>
                    <span>NAME : {packages.name}</span>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <span>PRICE: Rs. {getProductPrice(packages)} /=</span>
                </Col>
            </Row>
            {localStorage.basepackagePurchaseType === TRANSACTIONAL && <Row>
                <Col md={12}>
                    <span>EXPIRY DATE: {moment(packages.billingEndDate).format('DD/MM/YYYY')}</span>
                </Col>
            </Row>}
            <Row>
                <Col md={12} className="mt-3">
                    <span>{packages.description}</span>
                </Col>
            </Row>
            {/* {!getServiceType() &&  <Row className="mt-3">
            <Col md={12}>
                <CustomButton text="UPGRADE" className="upgrade-btn" onClick={onClick} />
            </Col>
        </Row>} */}
        </Col>
    </Row>
}