import React from 'react'
import { Row } from 'react-bootstrap';
import { getLoggedInStatus } from '../../util/ArrayValidator';
import { Link } from 'react-router-dom';

function PromoCard({ name, isPurchased, imageURL, id, onClick, isDummy, type, promo }) {
   
    //with product id and purchase option id
    let promotionsRender;

    if(type === "vod" || type === "vod" || type === "channel" || type === "databundle" || type === "screens"){
        promotionsRender = <Link to={`products/${promo.productId}?purchaseOptionId=${promo.purchaseOptionId}`}>
                                <img
                                    className="svod-image"
                                    src={imageURL}
                                    id={id}
                                    onClick={onClick}
                                    alt=''
                                // onError={(e) => {
                                //     e.target.onerror = null
                                //     e.target.src = require("./promor.png")
                                // }}
                                />
                            </Link>
    }else if(type === "package"){
        promotionsRender = <Link to={`promotions/packages/${promo.productId}?purchaseOptionId=${promo.purchaseOptionId}`}>
                                <img 
                                    className="svod-image"
                                    src={imageURL}
                                    id={id}
                                    onClick={onClick}
                                    alt=''
                                // onError={(e) => {
                                //     e.target.onerror = null
                                //     e.target.src = require("./promor.png")
                                // }}
                                />
                            </Link>
    }else if(type === "svod"){
        promotionsRender = <Link to={`svod/${promo.productId}?purchaseOptionId=${promo.purchaseOptionId}`}>
                                <img
                                    className="svod-image"
                                    src={imageURL}
                                    id={id}
                                    onClick={onClick}
                                    alt=''
                                // onError={(e) => {
                                //     e.target.onerror = null
                                //     e.target.src = require("./slider.png")
                                // }}
                                />
                            </Link>
    }

    //with only product id
    let promotionsWithProductOnlyRender;

    if(type === "vod" || type === "vod" || type === "channel" || type === "databundle" || type === "screens"){
        promotionsWithProductOnlyRender = <Link to={`products/${promo.productId}`}>
                                <img
                                    className="svod-image"
                                    src={imageURL}
                                    id={id}
                                    onClick={onClick}
                                    alt=''
                                // onError={(e) => {
                                //     e.target.onerror = null
                                //     e.target.src = require("./promor.png")
                                // }}
                                />
                            </Link>
    }else if(type === "package"){
        promotionsWithProductOnlyRender = <Link to={`promotions/packages/${promo.productId}`}>
                                <img 
                                    className="svod-image"
                                    src={imageURL}
                                    id={id}
                                    onClick={onClick}
                                    alt=''
                                // onError={(e) => {
                                //     e.target.onerror = null
                                //     e.target.src = require("./promor.png")
                                // }}
                                />
                            </Link>
    }else if(type === "svod"){
        promotionsWithProductOnlyRender = <Link to={`svod/${promo.productId}`}>
                                <img
                                    className="svod-image"
                                    src={imageURL}
                                    id={id}
                                    onClick={onClick}
                                    alt=''
                                // onError={(e) => {
                                //     e.target.onerror = null
                                //     e.target.src = require("./slider.png")
                                // }}
                                />
                            </Link>
    }



    if(getLoggedInStatus()){
        if(promo.redirectURL){
            return (
                <>
                    <div id='svod-card'>
                        <h6 id='svod-name'>{name}</h6>
                        <Row className='svod-container'>
                            <Link to={`/${promo.redirectURL}`}>
                                <img
                                    className="svod-image"
                                    src={imageURL}
                                    id={id}
                                    onClick={onClick}
                                    alt=''
                                />
                            </Link>
                        </Row>
                    </div>
                </>
            )
        }else{
            if(promo.productId && promo.purchaseOptionId){
                return (
                    <>
                        <div id='svod-card'>
                            <h6 id='svod-name'>{name}</h6>
                            <Row className='svod-container'>
                                {getLoggedInStatus() && isPurchased &&
                                    <i class="fas fa-check-square check-icon"></i>}
                                {
                                    promotionsRender
                                }
            
                            </Row>
                        </div>
                    </>
                )
            } else if(promo.productId){
                return (
                    <>
                        <div id='svod-card'>
                            <h6 id='svod-name'>{name}</h6>
                            <Row className='svod-container'>
                                {getLoggedInStatus() && isPurchased &&
                                    <i class="fas fa-check-square check-icon"></i>}
                                {
                                    promotionsWithProductOnlyRender
                                }
            
                            </Row>
                        </div>
                    </>
                )
            }
            else{
                return (
                    <>
                        <div id='svod-card'>
                            <h6 id='svod-name'>{name}</h6>
                            <Row className='svod-container' style={{"cursor" : "default"}}>
                                <img
                                    className="svod-image"
                                    src={imageURL}
                                    id={id}
                                    // onClick={onClick}
                                    alt=''
                                />
                            </Row>
                        </div>
                    </>
                )
            }
        }
    }else{
        if(Array.isArray(promo.basePackageIds) && promo.basePackageIds.length === 0){
            if(promo.redirectURL){
                return (
                    <>
                        <div id='svod-card'>
                            <h6 id='svod-name'>{name}</h6>
                            <Row className='svod-container'>
                                <Link to={`/${promo.redirectURL}`}>
                                    <img
                                        className="svod-image"
                                        src={imageURL}
                                        id={id}
                                        onClick={onClick}
                                        alt=''
                                    />
                                </Link>
                            </Row>
                        </div>
                    </>
                )
            }else if(!promo.productId && !promo.purchaseOptionId){
                return (
                    <>
                        <div id='svod-card'>
                            <h6 id='svod-name'>{name}</h6>
                            <Row className='svod-container' style={{"cursor" : "default"}}>
                                <img
                                    className="svod-image"
                                    src={imageURL}
                                    id={id}
                                    // onClick={onClick}
                                    alt=''
                                />
                            </Row>
                        </div>
                    </>
                )
            }else{
                return null;
            }
        }else{
            return null;
        }
    }
}

export default PromoCard;
