import React from "react";

const CustomListboxOptions = props => {
    const { handleOptionsEvents, setOptionRef, focusedOption, options } = props;
    const setsize = options.length;
    return (
        <div className="options-items">
            {
                options && options.map((option, index) => {
                    return (
                        <div
                            tabIndex="0"
                            role="option"
                            className="option"
                            id={option.value}
                            aria-selected={focusedOption === option.value}
                            key={option.value}
                            onClick={e => handleOptionsEvents(option, index, e)}
                            onKeyDown={e => handleOptionsEvents(option, index, e)}
                            ref={setOptionRef}
                            aria-posinset={index}
                            aria-setsize={setsize}
                        >
                            <span className="option-item">{option.label}</span>
                            <span style={{ fontSize: 12, paddingLeft: 25 }}>{option.subText}</span>
                        </div>
                    );
                })
            }
            <div
                tabIndex="0"
                role="option"
                className="option"
                id="add"
                aria-selected={focusedOption === "add"}
                key="add"
                onClick={e => handleOptionsEvents({ value: "add" }, options.length, e)}
                onKeyDown={e => handleOptionsEvents({ value: "add" }, options.length, e)}
                ref={setOptionRef}
                aria-posinset={options.length}
                aria-setsize={setsize}
            >
                <span className="option-item">Add Account</span>
            </div>
        </div>
    );
};

export default CustomListboxOptions;
