import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "./PaymentStyles.scss";
import { CustomButton } from '../controls/CustomButton';

function SuccessfullPayment() {
    return (
        <Container className="successful-payment">
            <div>
                <Row className="successful-icon-row">
                    <Col className="align-center"><i class="far fa-times-circle payment-failed-icon"></i></Col>
                </Row>
                <Row>
                    <Col className="align-center"><p>Payment Unsuccessful</p></Col>
                </Row>                
                <Row>
                    <Col className="continue-btn"><CustomButton text="CONTINUE"/></Col>
                </Row>
            </div>
        </Container>
    )
}

export default SuccessfullPayment
