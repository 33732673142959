import React from 'react'

function AlreadyPurchased({isLoggedIn, isPurchased, cardStyle}) {
    if (isLoggedIn && isPurchased) {
        return <i className={`fas fa-check-square already-purchased${cardStyle}`}></i>;
    }
    else{
        return null;
    }
}

export default AlreadyPurchased
