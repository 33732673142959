export default {
    GET_ALL_SVOD: "svod/GET_ALL_SVOD",
    GET_ALL_SVOD_SUCCESS: "svod/GET_ALL_SVOD_SUCCESS",
    GET_ALL_SVOD_FAILED: "svod/GET_ALL_SVOD_FAILED",

    GET_SVOD: "svod/GET_SVOD",
    GET_SVOD_SUCCESS: "svod/GET_SVOD_SUCCESS",
    GET_SVOD_FAILED: "svod/GET_SVOD_FAILED",

    GET_SUB_SVOD: "svod/GET_SUB_SVOD",
    GET_SUB_SVOD_SUCCESS: "svod/GET_SUB_SVOD_SUCCESS",
    GET_SUB_SVOD_FAILED: "svod/GET_SUB_SVOD_FAILED",

    CLEAR_SVOD: "svod/CLEAR_SVOD",

    PRODUCT_SUBSCRIBE: "svod/PRODUCT_SUBSCRIBE",
    PRODUCT_SUBSCRIBE_SUCCESS: "svod/PRODUCT_SUBSCRIBE_SUCCESS",
    PRODUCT_SUBSCRIBE_FAILED: "svod/PRODUCT_SUBSCRIBE_FAILED",

    PRODUCT_UNSUBSCRIBE: "svod/PRODUCT_UNSUBSCRIBE",
    PRODUCT_UNSUBSCRIBE_SUCCESS: "svod/PRODUCT_UNSUBSCRIBE_SUCCESS",
    PRODUCT_UNSUBSCRIBE_FAILED: "svod/PRODUCT_UNSUBSCRIBE_FAILED",

    GET_PURCHASE_TABLE: "svods/purchaseTable/GET_PURCHASE_TABLE",
    GET_PURCHASE_TABLE_SUCCESS: "svods/purchaseTable/GET_PURCHASE_TABLE_SUCCESS",
    GET_PURCHASE_TABLE_FAILED: "svods/purchaseTable/GET_PURCHASE_TABLE_FAILED",

    VERIFY_SVOD_PURCHASE: "svods/VERIFY_SVOD_PURCHASE",
    VERIFY_SVOD_PURCHASE_SUCCESS: "svods/VERIFY_SVOD_PURCHASE_SUCCESS",
    VERIFY_SVOD_PURCHASE_FAILED: "svods/VERIFY_SVOD_PURCHASE_FAILED",

    VERIFY_SVOD_UNSUB: "svods/VERIFY_SVOD_UNSUB",
    VERIFY_SVOD_UNSUB_SUCCESS: "svods/VERIFY_SVOD_UNSUB_SUCCESS",
    VERIFY_SVOD_UNSUB_FAILED: "svods/VERIFY_SVOD_UNSUB_FAILED",

    CLEAR_PURCHASE: "svods/CLEAR_PURCHASE",
    CLEAR_ALL_SVOD_CONTENT: "svods/CLEAR_ALL_SVOD_CONTENT",

    GET_BUNDLED_PRODUCTS:  "svods/GET_BUNDLED_PRODUCTS",
    GET_BUNDLED_PRODUCTS_SUCCESS:  "svods/GET_BUNDLED_PRODUCTS_SUCCESS",
    GET_BUNDLED_PRODUCTS_FAILED:  "svods/GET_BUNDLED_PRODUCTS_FAILED",
    GET_SVOD_PRODUCT_PURCHASE_STATUS : "svods/GET_SVOD_PRODUCT_PURCHASE_STATUS",
    GET_SVOD_PRODUCT_PURCHASE_STATUS_SUCCESS : "svods/GET_SVOD_PRODUCT_PURCHASE_STATUS_SUCCESS",
    GET_SVOD_PRODUCT_PURCHASE_STATUS_FAILED : "svods/GET_SVOD_PRODUCT_PURCHASE_STATUS_FAILED",
    GET_SVOD_PRODUCT_PURCHASE_STATUS_LOADING : "svods/GET_SVOD_PRODUCT_PURCHASE_STATUS_LOADING",

    GET_SVOD_PURCHASE_OPTIONS: "svods/GET_SVOD_PURCHASE_OPTIONS",
    GET_SVOD_PURCHASE_OPTIONS_SUCCESS: "svods/GET_SVOD_PURCHASE_OPTIONS_SUCCESS",
    GET_SVOD_PURCHASE_OPTIONS_FAILED: "svods/GET_SVOD_PURCHASE_OPTIONS_FAILED",
    

    GET_REFERENCE_NUMBER: "product/GET_REFERENCE_NUMBER",
    GET_REFERENCE_NUMBER_SUCCESS: "product/GET_REFERENCE_NUMBER_SUCCESS",
    GET_REFERENCE_NUMBER_FAILED: "product/GET_REFERENCE_NUMBER_FAILED",
    
}  