import { IS_SANDBOX, SUBSCRIPTIONAL } from './Constants'
import { taxCalculation } from './ArrayValidator'
export const Payhere = (purchaseOptions, userDetails, details, purchaseOption, paymentStatus) => {
  let selectedPurchaseType;
  selectedPurchaseType = purchaseOptions.filter(option => option.id === purchaseOption)
  // let details = this.props.payhereDetails && this.props.payhereDetails.transactionDetails;
  let email = userDetails.userEmail && userDetails.userEmail.username;
  let userInformation = userDetails.userDetails && userDetails.userDetails[0] && userDetails.userDetails[0]
  let firstName, lastName
  let payableAmount = taxCalculation(details.amount)
  if (userDetails.userDetails && userDetails.userDetails[0] && userDetails.userDetails[0].name) {
    let splitData = userDetails.userDetails && userDetails.userDetails[0] && userDetails.userDetails[0].name.split(" ");
    firstName = splitData[0];
    lastName = splitData[1];
  }
  var payment = {
    "sandbox": IS_SANDBOX,
    "merchant_id": details.merchantId,       // Replace your Merchant ID
    "return_url": details.returnUrl,
    "cancel_url": details.cancelUrl,
    "notify_url": details.notifyUrl,
    "order_id": details.referenceNumber,
    "items": details.purchaseItemName,
    "amount": payableAmount,
    "currency": "LKR",
    "first_name": firstName,
    "last_name": lastName,
    "email": email,
    "phone": userInformation.telephoneNumber,
    "address": "",
    "city": "",
    "country": ""
  };
  if (selectedPurchaseType && selectedPurchaseType[0] && selectedPurchaseType[0].purchaseType === SUBSCRIPTIONAL) {
    payment.recurrence = details.recurrence;
    payment.duration = details.duration;
  }
  return payment
}