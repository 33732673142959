import React from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import { ProductAdditionDetailsPlaceholder } from './DummyComponent'


export default function DummyPaymentTable({ customButtonText }) {
    return (
        <Container style={{ color: "#d0d0d0", marginTop: 35 }}>
            <Row>

                <Col>
                    <ProductAdditionDetailsPlaceholder />
                </Col>
            </Row>
        </Container>
    )
}