import React from 'react'
import { Card, Alert } from 'react-bootstrap';
import { CustomButton } from './../controls/CustomButton'
export const MyDevice = ({
    deviceArray,
    onClick

}) => {
    return <>
        {deviceArray && deviceArray.length!==0 && deviceArray.map((device, index) => {

            return <div style={{ border: '0.6px solid #4fa1d9', marginTop: '20px', marginRight: '30px', backgroundColor: '#ffff', borderRadius: '5px', width: '15rem' }}>
                <Card style={{ border: 'none', borderRadius: '0px', }} >
                    {/* <Card.Header> */}
                    <Card.Title style={{ textAlign: 'center', fontSize: '15px' }}>{device.name}</Card.Title>
                    <Card.Img variant="top" src={require("../../images/mobile.png")} style={{ height: 'auto', width: 'fit-content', display: 'block', margin: 'auto' }} />
                    {/* </Card.Header> */}
                </Card>
                <Card style={{ backgroundColor: '#ffff', backgroundImage: 'initial', border: 'none', padding: '8px', borderRadius: '0px', marginLeft: '1px' }} >
                    <Card.Body >
                        <Card.Text style={{ color: 'black', fontSize: '13px' }}>
                            Device ID: {device.deviceUid}
                        </Card.Text>
                        <Card.Text style={{ color: 'black', fontSize: '12px' }}>
                            Device Model : {device.deviceInfo.deviceModelType}
</Card.Text>
                    </Card.Body>
                    <CustomButton id={device.deviceUid}
                        onClick={onClick}
                        style={{ color: '#ffff', marginLeft: '15px' }}
                        text="Remove"
                    />

                </Card>
            </div>
        })
        }
        {!deviceArray && <Alert variant="warning" className="devices-empty-msg" style={{margin : "auto", marginTop: "3%"}}>Currently, there are no devices connected.<br/> Please use the validation code in the "Connection Info" to login to PEOTVGO mobile app.</Alert>}
    </>
}

