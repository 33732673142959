import React from 'react'
import { Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
export const CustomScreenTab = ({
    viewPackage,
    unionArray,
    selectedCategory,
    product,
    selectedCategoryName,
    purchasableProducts,
    onClick,
    loggedIn,
    beforeLogin
}) => {
    return <div style={{ marginTop: '15px' }}>
        <div className={viewPackage && "terms-contents scrollbar-info"}>
            {product && product.map((item, index) => {
                if ((selectedCategoryName === item.productTypeName)) {

                    return <>
                        <Link to={`/products/${item.id}`} key={index} >
                            <div className="device-container-box">
                                <div className="device-content-default" >
                                    <Row>
                                        <Col className="device-image-container">
                                            <img src={`/${item.imageURL}`} className="device-img-background" alt='' />
                                        </Col>
                                        <Col className="device-details-container">
                                            <div className="device-name-container" >
                                                <Col className="device-name-col" >
                                                    <span className="device-name" >
                                                        {item.name}
                                                    </span>
                                                </Col>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Link>
                    </>

                } else {
                    return null
                }

            })
            }
        </div >
    </div >
}