// Redux-login service

import { createLogic } from 'redux-logic';

import actions from './actions';
import types from './types';
import endPoints from './../../../util/EndPoints';
import API from './../../../util/HTTPClient'
import productTypeIDs from './productTypeIDs'
import _ from "lodash";

import { getLoggedInStatus, getConnectionId } from '../../../util/ArrayValidator';
import { CHANNELS_PRODUCT_TYPE, PROMOTIONAL_PACKAGE_ID } from '../../../util/Constants';
import moment from 'moment';

const getAllProducts = createLogic({
  type: types.GET_ALL_PRODUCTS, // only apply this logic to this type
  latest: true, // only take latest
  debounce: 1000, // Wait 1 s before triggering another call

  // your code here, hook into one or more of these execution
  // phases: validate, transform, and/or process
  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    //Before login product fetch
    if (getLoggedInStatus() === false) {
      let endPoint
      if (Array.isArray(action.payload)) {
        endPoint = `${endPoints.GET_ALL_PRODUCTS_BY_TYPES}?typeid=${action.payload[0]}&typeid=${action.payload[1]}`
      } else {
        endPoint = `${endPoints.GET_ALL_CHANNEL}?typeid=${action.payload}`
      }
      HTTPClient.Get(endPoint)
        .then(res => res.data)
        .then(data => {
          if (Array.isArray(action.payload)) {
            //If data is CHANNEL
            if (action.payload[0] === productTypeIDs.CHANNEL_ID) {
              dispatch(actions.getAllChannelSuccess(data))
            }
          } else {
            //If data is SVOD
            if (action.payload === productTypeIDs.SVOD_ID) {
              dispatch(actions.getAllSVODSuccess(data))
            }
            //If data is VOD
            if (action.payload === productTypeIDs.VOD_ID) {

              var categoryList = []
              Array.isArray(data) &&
                data.reverse().map((prods, i) => {
                  let displayName = prods.productSubTypeDisplayName
                  let productSubTypeTypeName = prods.productSubTypeTypeName
                  let obj = { displayName: displayName, typeName: productSubTypeTypeName }

                  categoryList.push(obj);
                  return null
                });
              var uniq = _.uniqBy(categoryList, function (o) {
                return o.typeName;
              });
              dispatch(actions.getOnDemandSubTypesSuccess(uniq))
              dispatch(actions.getAllVODSuccess(data))
            }
          }
        })
        .catch(err => {
          if (Array.isArray(action.payload)) {
            //If data is CHANNEL
            if (action.payload === productTypeIDs.CHANNEL_ID) {
              dispatch(actions.getAllChannelFail(err))
            }
          } else {
            //If data is SVOD
            if (actions.payload === productTypeIDs.SVOD_ID) {
              dispatch(actions.getAllSvodFail(err))
            }
            //If data is VOD
            if (action.payload === productTypeIDs.VOD_ID) {
              dispatch(actions.getAllVODFail(err))
            }
          }



        })
        .then(() => done()); // call done when finished dispatching
    }
    //after login product fetch
    else {
      let endPoint
      if (localStorage.connectionStatus === "suspended") {
        endPoint = `${endPoints.GET_REACTIVE_PRODUCTS}?conid=${action.payload.conid}&typeid=${action.payload.subTypeId}`
      } else {
        if (action.payload.dataBundle) {
          endPoint = `${endPoints.ALL_DATA_BUNDLES}?conId=${action.payload.conid}`
        } else {
          if (Array.isArray(action.payload.subTypeId)) {
            endPoint = `${endPoints.GET_PRODUCTS_BY_TYPES_BILLING}?conid=${action.payload.conid}&typeIds=${action.payload.subTypeId[0]}&typeIds=${action.payload.subTypeId[1]}`
          } else {
            endPoint = `${endPoints.GET_SUB_VODS_BILLING}?conid=${action.payload.conid}&typeid=${action.payload.subTypeId}`
          }

        }
      }
      HTTPClient.Get(`${endPoint}`)
        .then(res => res.data)
        .then(data => {
          if (Array.isArray(action.payload.subTypeId)) {
            //if data is Channel
            if (action.payload.subTypeId[0] === productTypeIDs.CHANNEL_ID) {
              dispatch(actions.getUserChannelSuccess(data))
            }
          }
          else {
            //if data is SVOD
            if (action.payload.subTypeId === productTypeIDs.SVOD_ID) {
              dispatch(actions.getUserSVODSuccess(data))
            }
            //if data is Device
            if (action.payload.subTypeId === productTypeIDs.DEVICE_ID) {
              dispatch(actions.getUserDeviceSuccess(data))
            }
            //if data is DataBundle
            if (action.payload.subTypeId === productTypeIDs.DATA_BUNDLE_ID) {
              dispatch(actions.getUserDataBundleSuccess(data))
            }

            //if data is VOD
            if (action.payload.subTypeId === productTypeIDs.VOD_ID) {

              var categoryList = []
              Array.isArray(data) &&
                data.reverse().map((prods, i) => {
                  let displayName = prods.productSubTypeDisplayName
                  let productSubTypeTypeName = prods.productSubTypeTypeName
                  let obj = { displayName: displayName, typeName: productSubTypeTypeName }

                  categoryList.push(obj);
                  return null
                });
              var uniq = _.uniqBy(categoryList, function (o) {
                return o.typeName;
              });
              dispatch(actions.getOnDemandSubTypesSuccess(uniq))
              dispatch(actions.getUserVODSuccess(data))
            }
          }
        })
        .catch(err => {
          if (Array.isArray(action.payload.subTypeId)) {
            //if data is Channel
            if (action.payload.subTypeId[0] === productTypeIDs.CHANNEL_ID) {
              dispatch(actions.getUserChannelFail(err))
            }
          }
          else {
            //if data is SVOD
            if (action.payload.subTypeId === productTypeIDs.SVOD_ID) {
              dispatch(actions.getUserSVODFail(err))
            }
            //if data is Device
            if (action.payload.subTypeId === productTypeIDs.DEVICE_ID) {
              dispatch(actions.getUserDeviceFail(err))
            }
            //if data is DataBundle
            if (action.payload.subTypeId === productTypeIDs.DATA_BUNDLE_ID) {
              dispatch(actions.getUserDataBundleFail(err))
            }
            //if data is VOD
            if (action.payload.subTypeId === productTypeIDs.VOD_ID) {
              dispatch(actions.getUserVODFail(err))
            }
          }
        })
        .then(() => done()); // call done when finished dispatching
    }
  }
})

const getVODSubTypes = createLogic({
  type: types.GET_ONDEMAND_SUB_TYPES,
  latest: true,
  debounce: 1000,
  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(
      `${endPoints.GET_PARENT_TYPES}/${action.payload}${endPoints.GET_SUB_TYPES}`
    )
      .then(res => res.data)
      .then(data => dispatch(actions.getOnDemandSubTypesSuccess(data)))
      .catch(err => dispatch(actions.getOnDemandSubTypesFail(err)))
      .then(() => done()); // call done when finished dispatching
  }
})

const getChennelCategories = createLogic({
  type: types.GET_CHANNEL_CATEGORIES,
  latest: true,
  debounce: 1000,
  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Post(
      `${endPoints.GET_ALL_CHANNEL_CATEGORIES}`, action.payload
    )
      .then(res => res.data)
      .then(data => dispatch(actions.getChennelCategoriesSuccess(data)))
      .catch(err => dispatch(actions.getChennelCategoriesFail(err)))
      .then(() => done()); // call done when finished dispatching
  }
})

const getAllowedPackageIds = createLogic({
  type: types.GET_ALLOWED_PACKAGE_IDS,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient
    } else {
      HTTPClient = API
    }


    // dispatch(actions.fetchSecondaryProducts())
    // return
    if (localStorage.jwt === undefined) {
      var day = new Date();
      var dayWrapper = moment(day);
      localStorage.setItem("packageLoadedAt", day)
      console.log("dayWrapper", dayWrapper)
      HTTPClient.Get(`${endPoints.FETCH_PRODUCTS_ID}`)
        .then(resp => resp.data)
        .then((data) => {
          dispatch(actions.getAllowedPackageIdsSuccess(data))
          dispatch(actions.getAllPackages({ idList: data }))

        })
        .catch(err => {
          // Log error
          err.response && err.response.data && dispatch(actions.getAllowedPackageIdsSuccess(err.response.data.message || "Something went wrong, please contact your service provider"))
        })
        .then(() => done()); // call done when finished dispatching
    }

    else {
      HTTPClient.Get(`${endPoints.FETCH_PRIMARY_PRODUCTS_ID}${action.payload}`)
        .then(resp => resp.data)
        .then((data) => {
          dispatch(actions.getAllPackages({ idList: data }))

        })
        .catch(err => {
          err.response && err.response.data && dispatch(actions.getAllowedPackageIdsFailed(err.response.data.message || "Something went wrong, please contact your service provider"))
        })
        .then(() => done()); // call done when finished dispatching
    }
  }

})

const getAllPackages = createLogic({
  type: types.GET_ALL_PACKAGES,
  latest: true,
  debounce: 3000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    let apiEndpoint
    if (getLoggedInStatus() === false) {
      apiEndpoint = HTTPClient.Post(`${endPoints.PRODUCT_ID_LIST}`, action.payload)
    } else {
      apiEndpoint = HTTPClient.Get(`${endPoints.PURCHASED_PACKAGE}?conid=${localStorage.conID}&packagetypeid=5d22eda922224e2be4ad4047`)
    }
    apiEndpoint
      .then(resp => resp.data)
      .then(data => {
        let allPackages = [];
        let prodList = [];
        let categoryList = [];
        // let product = [];
        let dataBundle = []
        let primaryPacakgeStatus = false
        Array.isArray(data) &&
          data.map((v, index) => {
            if (v.status === "PRODUCT_STATUS_ACTIVE" && v.standalone === "PURCHASE_MODE_STANDALONE") {
              let basePackage = {};
              let products = {};
              let dbun = {};
              let product = [];
              basePackage.id = v.id;
              basePackage.productTypeId = v.productTypeId;
              basePackage.name = v.name;
              basePackage.price =
                v.purchaseOption && v.purchaseOption.defaultPrice;
              basePackage.imageURL = v.imageURL;
              basePackage.description = v.description;
              basePackage.numberOfScreens = v.numberOfScreens;
              basePackage.purchaseOption = v.purchaseOption
              basePackage.additionalDetails = v.additionalDetails
              Array.isArray(v.productList) &&
                v.productList.map((prods, index) => {
                  if (prods.productTypeName === "Package" && prods.status === "PRODUCT_STATUS_ACTIVE") {
                    // if (prods.productTypeId == productTypeIDs.PACKAGE_ID) {
                    prodList.push(prods.productTypeName);
                    products.description = prods.description;
                    products.id = prods.id;
                    products.imageURL = prods.imageURL;
                    products.name = prods.name;
                    products.price = prods.price;
                    products.numberOfScreens = prods.numberOfScreens;
                    products.productTypeName = prods.productTypeName;
                    products.productTypeId = prods.productTypeId;
                    products.purchaseOption = prods.purchaseOption
                    product.push(products);

                    Array.isArray(prods.categories) &&
                      prods.categories.map((h, i) => {
                        categoryList.push(h.category);
                        return null
                      });
                  }
                  if (primaryPacakgeStatus === "PRODUCT_STATUS_ACTIVE" && prods.productTypeId === productTypeIDs.DATA_BUNDLE_ID) {
                    dbun.dataId = prods.id
                    dbun.name = v.name
                    dbun.dataQuota = prods.dataQuota

                  }
                  return null
                });
              dataBundle.push(dbun)
              basePackage.dataBundle = dataBundle
              // basePackage.products=v.productList
              basePackage.categories = _.uniq(categoryList);
              // })
              basePackage.tabs = _.uniq(prodList);
              // basePackage.categories= _.uniq(categoryList)
              basePackage.product = _.uniq(product);
              //basepackage discountroup
              basePackage.discountOptionGroup = v.discountOptionGroup;
              basePackage.purchaseStatus = v.purchaseStatus

              allPackages.push(basePackage);
              return null
              // }
            }
            return false
          });
        dispatch(actions.getAllowedPackageIdsSuccess(data))
        dispatch(actions.getAllPackagesSuccess(allPackages));
      })
      .catch(err => {
        // Log error
        dispatch(
          actions.getAllPackagesFailed(
            "Something went wrong, please contact your service provider"
          )
        );
      })
      .then(() => done()); // call done when finished dispatching
  }
})

const getDistinctProducts = createLogic({
  type: types.GET_DISTINCT_PRODUCTS,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    // debugger;
    let HTTPClient
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Post(`${endPoints.BASE_PACKAGE_PRODUCT}`, action.payload)
      .then(resp => resp.data)
      .then(data => {
        dispatch(actions.getDistinctProductsSuccess(data));
      })
      .catch(err => {
        dispatch(
          actions.getDistinctProductsFailed(
            "Something went wrong, please contact your service provider"
          )
        );

      })
      .then(() => done()); // call done when finished dispatching
  }
})
const getProductTypes = createLogic({
  type: types.GET_PRODUCT_TYPES,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    // debugger;
    let HTTPClient
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(
      `${endPoints.GET_PRODUCTS_TYPES}?productId=${action.payload}`
    )
      .then(resp => resp.data)
      .then(data => {
        _.remove(data, { typeName: "screens" })
        let channelsAvailable = data.some(e => e.typeName === 'channel');
        let channelBundlesAvailable = data.some(e => e.typeName === 'channelbundle');
        if(!channelsAvailable && channelBundlesAvailable){
          data.unshift(CHANNELS_PRODUCT_TYPE)
        }
        _.remove(data, {typeName: "channelbundle"})
        dispatch(actions.getProductTypeSuccess(data));
      })
      .catch(err => {
        dispatch(
          actions.getDistinctProductsFailed(
            "Something went wrong, please contact your service provider"
          )
        );
      })
      .then(() => done()); // call done when finished dispatching
  }
})

const getPromotionalPackages = createLogic({

  type: types.GET_PROMOTIONAL_PACKAGES, // only apply this logic to this type
  latest: true, // only take latest
  debounce: 1000, // Wait 1 s before triggering another call

  process({
    MockHTTPClient,
    getState,
    action
  }, dispatch, done) {
    let HTTPClient
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient
    } else {
      HTTPClient = API
    }
    let endPoint
    let conid = getConnectionId()
    if (getLoggedInStatus()) {

      // endPoint = `${endPoints.BASE_PRODUCT}?typeid=${PROMOTIONAL_PACKAGE_ID}`
      endPoint = `${endPoints.PURCHASED_PACKAGE}?conid=${conid}&packagetypeid=${PROMOTIONAL_PACKAGE_ID}`
    }
    // else {
    //     endPoint = `${endPoints.BASE_PRODUCT}?typeid=${PROMOTIONAL_PACKAGE_ID}`
    // }

    HTTPClient.Get(`${endPoint}`)
      .then(res => res.data)
      .then(data => {
        dispatch(actions.getPromotionalPackagesSuccess(data))
      })
      .catch(err => dispatch(actions.getPromotionalPackagesFailed(err)))
      .then(() => done()); // call done when finished dispatching
  }
})

export default [
  getAllProducts, getVODSubTypes, getChennelCategories, getAllowedPackageIds, getAllPackages, getDistinctProducts, getProductTypes, getPromotionalPackages
]