import React from 'react';
import { Container, Row } from 'react-bootstrap';
import './AccountSuspended.scss';

function AccountUnsubscribed(billingEndDate) {
    let expirationDate= billingEndDate.billingEndDate // get this from bacend
    return (
        <Container>
            <Row className="account-suspended-row">
                <div className="account-suspended" style={{color: "#fff", }}>
                   {expirationDate && <p className="account-terminated">{`Your connection has been unsubscribed. Account will be terminated on ${expirationDate}`} </p>}
                </div>
            </Row>
        </Container>
    )
}

export default AccountUnsubscribed
