import React from 'react'
import TextareaAutosize from 'react-textarea-autosize';


export default function Description({ description }) {
    return (
        <div>
            <TextareaAutosize
                style={{
                    backgroundColor: 'transparent',
                    // minHeight: "300px",
                    width: '100%',
                    color: "#ffffff",
                    borderStyle: "none",
                    borderColor: "Transparent",
                    // display: "table"
                    overflow: "hidden",
                    textAlign: "justify",
                    resize: "none"
                    // lineHeight: "100%",
                }}
                // rows={4} 
                disabled
                defaultValue={description}
                useCacheForDOMMeasurements
                onHeightChange={(height, instance) => {
                }}
            />
        </div>
    )
}
