import { createLogic } from 'redux-logic';
import actions from './actions'
import types from './types'
import endPoints from './../../../util/EndPoints'
import API from './../../../util/HTTPClient'
import featuredActions from '../../landingPage/ducks/actions';
import { getLoggedInStatus, purchaseStatusValidation } from '../../../util/ArrayValidator';
import { PREPAID_SERVICE_TYPE, MOBITEL_SERVICE_TYPE ,TRANSACTIONAL, PAYMENT_SUCCESS} from "../../../util/Constants";

export default [
  createLogic({
    type: types.GET_ALL_SVOD,
    latest: true,
    debounce: 1000,
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }
      if (getLoggedInStatus() === false) {
        HTTPClient.Get(`${endPoints.GET_ALL_CHANNEL}?typeid=${action.payload}`)
          .then(res => res.data)
          .then(data => dispatch(actions.getAllSvodSuccess(data)))
          .catch(err => dispatch(actions.getAllSvodFailed(err)))
          .then(() => done()); // call done when finished dispatching
      } else {
        HTTPClient.Get(`${endPoints.GET_SUB_VODS_BILLING}?conid=${action.payload.conid}&typeid=${action.payload.subTypeId}`)
          .then(res => res.data)
          .then(data => dispatch(actions.getAllSvodSuccess(data)))
          .catch(err => dispatch(actions.getAllSvodFailed(err)))
          .then(() => done()); // call done when finished dispatching
      }
    }
  }),

  createLogic({
    type: types.GET_SVOD,
    latest: true,
    debounce: 1000,
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }

      if (getLoggedInStatus() === false) {
        HTTPClient.Get(`${endPoints.GET_PRODUCT}${action.payload}`)
          .then(res => res.data)
          .then(data => dispatch(actions.getSvodSuccess(data)))
          .catch(err => dispatch(actions.getSvodFailed(err)))
          .then(() => done()); // call done when finished dispatching
      } else {
        HTTPClient.Get(`${endPoints.PURCHASED_PRODUCTS}${action.payload.productId}?conid=${action.payload.connectionId}`)
          .then(res => res.data)
          .then(data => {
            let purchaseOptionGroupId = data.purchaseOption && data.purchaseOptionGroupId
            dispatch(actions.getSvodSuccess(data))
            dispatch(actions.getPurchaseTable(purchaseOptionGroupId))
          })
          .catch(err => dispatch(actions.getSvodFailed(err)))
          .then(() => done()); // call done when finished dispatching
      }
    }
  }),
  createLogic({
    type: types.GET_SUB_SVOD,
    latest: true,
    debounce: 1000,
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }
      HTTPClient.Get(`${endPoints.GET_PRODUCT}${action.payload}`)
        .then(res => res.data)
        .then(data => dispatch(actions.getSubSvodSuccess(data)))
        .catch(err => dispatch(actions.getSubSvodFailed(err)))
        .then(() => done()); // call done when finished dispatching
    }
  }),
  createLogic({
    type: types.PRODUCT_UNSUBSCRIBE,
    latest: true,
    debounce: 1000,
    process({ MockHTTPClient, getState, action }, dispatch, done) {

      function delay(t, v) {
        return new Promise(function (resolve) {
          setTimeout(resolve.bind(null, v), t)
        });
      }

      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }
      let endPoint
      let payload
      let serviceType = localStorage.serviceType
      if (serviceType === PREPAID_SERVICE_TYPE || serviceType === MOBITEL_SERVICE_TYPE) {

        let connectionId = localStorage.conID
        let purchaseId = action.payload.purchaseId
        let productId = action.payload.productId
        let payloadServiceType = serviceType === PREPAID_SERVICE_TYPE ? "prepaid" : "mobitel"
        let productIdPayload = serviceType === PREPAID_SERVICE_TYPE ? `&purchaseId=${purchaseId}` : `&productId=${productId}`
        endPoint = serviceType === PREPAID_SERVICE_TYPE ? endPoints.PREPAID_PRODUCT_PURCHASE : endPoints.MOBITEL_PRODUCT_SUBSCRIPTION
        payload = `?connectionId=${connectionId}${productIdPayload}&connectionType=${payloadServiceType}`


        HTTPClient.Delete(endPoint + payload)
          .then(res => res.status === 200)
          .then(async res => {
            await delay(3000)
            return dispatch(actions.verifyUnsubSvod({
              productId: action.payload.productId,
              connectionId: localStorage.conID,
              res: res
            }))
          })
          .catch(err => {
            // Log error
            err && err.response && err.response.data && dispatch(actions.productUnSubscribeFailed(err.response.data.message || "Something went wrong, please contact your service provider"))
          })
          .then(() => done()); // call done when finished dispatching

      } else {

        HTTPClient.Post(endPoints.PRODUCT_UNSUBSCRIBE, action.payload)
          .then(res => res.status === 200)
          .then(async data => {
            await delay(3000);
            dispatch(actions.verifyUnsubSvod({ productId: action.payload.productId, connectionId: action.payload.connectionId, res: data }))
          })
          .catch(err => dispatch(actions.productUnSubscribeFailed(err && err.response && err.response.data && err.response.data.message && err.response.data.message) || "Something went wrong, please contact your service provider"))
          .then(() => done()); // call done when finished dispatching
      }
    }
  }),


  // connectionId: this.props.connectionDetails && Array.isArray(this.props.connectionDetails) && this.props.connectionDetails[this.props.selectedConId].connectionId,
  // pin: this.state["password"],
  // productId: this.props.svod && this.props.svod.id,
  // productType: this.props.svod && this.props.svod.productTypeId,
  // purchaseOption: this.state["purchaseOption"]
  createLogic({
    type: types.PRODUCT_SUBSCRIBE,
    latest: true,
    debounce: 1000,
    process({ MockHTTPClient, getState, action }, dispatch, done) {

      function delay(t, v) {
        return new Promise(function (resolve) {
          setTimeout(resolve.bind(null, v), t)
        });
      }

      let serviceType = localStorage.serviceType
      let endPoint
      let payload = {}
      let { productPurchaseOptionGID, selectedPOGID } = action.payload;
      if (productPurchaseOptionGID === selectedPOGID) {
        if (serviceType === PREPAID_SERVICE_TYPE) {
          endPoint = endPoints.PREPAID_PRODUCT_PURCHASE
          payload.purchaseOptionId = action.payload.purchaseOption
          payload.currency = "LKR"
          payload.amount = action.payload.amount
          payload.productId = action.payload.productId
          payload.productTypeId = action.payload.productTypeId
          payload.userId = localStorage.userId
          payload.userConnectionId = action.payload.connectionId
          payload.userConnectionType = serviceType
          payload.userTelephoneNumber = localStorage.userTelephoneNumber
          payload.pin = action.payload.pin

        } else if (serviceType === MOBITEL_SERVICE_TYPE) {
          endPoint = endPoints.MOBITEL_PRODUCT_SUBSCRIPTION
          payload.purchaseOptionId = action.payload.purchaseOption
          payload.currency = "LKR"
          payload.amount = action.payload.amount
          payload.productId = action.payload.productId
          payload.userId = localStorage.userId
          payload.userConnectionId = action.payload.connectionId
          payload.userConnectionType = serviceType
          payload.pin = action.payload.pin
        }
        else {
          endPoint = endPoints.PRODUCT_SUBSCRIBE
          payload = action.payload
        }

        let HTTPClient;
        if (MockHTTPClient) {
          HTTPClient = MockHTTPClient;
        } else {
          HTTPClient = API;
        }
        if (serviceType !== PREPAID_SERVICE_TYPE && serviceType !== MOBITEL_SERVICE_TYPE) {
          HTTPClient.Post(endPoints.PRODUCT_SUBSCRIBE, action.payload)
            .then(res => res.status === 200)
            .then(async res => {
              if (serviceType === PREPAID_SERVICE_TYPE) {
                dispatch(actions.productSubscribeSuccess(res))
              }
              else {
                await delay(3000);
                return dispatch(actions.verifyPurchaseSvod({
                  productId: action.payload.productId,
                  connectionId: localStorage.conID,
                  res: res
                }));
              }
            })
            .catch(err => dispatch(actions.productSubscribeFailed(err && err.response && err.response.data && err.response.data.message && err.response.data.message) || "Something went wrong, please contact your service provider"))
            .then(() => done()); // call done when finished dispatching

        } else if (serviceType === MOBITEL_SERVICE_TYPE) {

          HTTPClient.Post(endPoint, payload)
            .then(resp => resp.data)
            .then(data => {
              var type = null
              switch (action.payload.defaultPurchaseOptionType) {
                case "PURCHASE_TYPE_SUBSCRIPTION":
                  type = "Subscription"
                  break;
                default:
                  type = "Purchase"
              }

              if (data.paymentStatus === "FAILURE") {
                data.message = `${data.description}`
                dispatch(actions.productSubscribeFailed(data.message))
              } else if (data.status === "SUCCESS" && data.paymentStatus === null) {
                data.message = data.description
                data.retry = true
                dispatch(actions.productSubscribeSuccess({ transactionDetails: data, status: "transaction" }));
                dispatch(actions.verifyPurchaseSvod({
                  productId: action.payload.productId,
                  connectionId: localStorage.conID,
                  res: data
                }));
              } else {
                data.message = `${type} Successful`
                dispatch(actions.getReferenceNumberSuccess({ referenceId: data.referenceNo }))
                dispatch(actions.productSubscribeSuccess({ transactionDetails: data, status: "transaction" }));
                dispatch(actions.verifyPurchaseSvod({
                  productId: action.payload.productId,
                  connectionId: localStorage.conID,
                  res: data
                }));
              }
            })
            .catch(err => dispatch(actions.productSubscribeFailed(err && err.response && err.response.data && err.response.data.message && err.response.data.message) || "Something went wrong, please contact your service provider"))
            .then(() => done()); // call done when finished dispatching
        }
        else {
          HTTPClient.Post(endPoint, payload)
            .then(resp => resp.data)
            .then(data => {
              dispatch(actions.productSubscribeSuccess({ transactionDetails: data, status: "transaction" }))
            })
            .catch(err => dispatch(actions.productSubscribeFailed(err && err.response && err.response.data && err.response.data.message && err.response.data.message) || "Something went wrong, please contact your service provider"))
            .then(() => done()); // call done when finished dispatching
        }
      } else {
        dispatch(actions.productSubscribeFailed("Error purchasing the product. Please refresh the browser and try again."));
      }
    }
  }),

  createLogic({
    type: types.GET_PURCHASE_TABLE,
    latest: true,
    debounce: 1000,

    process({
      MockHTTPClient,
      getState,
      action
    }, dispatch, done) {
      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }
      // console.log("Running get prerequisites Service");
      HTTPClient.Get(endPoints.GET_PURCHASE_TABLE + action.payload)
        // .then(resp => console.log("productPage : service : resp : ", resp))
        .then(resp => resp.data)
        .then(data => {
          return data;
        })
        .then(purchaseTable => {
          dispatch(actions.getPurchaseTableSuccess(purchaseTable))
        }
        )
        .catch(err => {
          var errorMessage = "Failed to get prerequisites";
          if (err && err.code === "ECONNABORTED") {
            errorMessage = "Please check your internet connection.";
          }
          dispatch(
            actions.getPurchaseTableFailed({
              title: "Error!",
              message: errorMessage,
              respErr: err.message
            })
          );
        })
        .then(() => done());
    }
  }),
  createLogic({
    type: types.VERIFY_SVOD_PURCHASE,
    latest: true,
    debounce: 1000,
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }

      HTTPClient.Get(
        `${endPoints.PURCHASED_PRODUCTS}${action.payload.productId}?conid=${action.payload.connectionId}`
      )
        .then(res => res.data)
        .then(data => {
          let purchaseStatusType = purchaseStatusValidation(data.purchaseStatus)
          let type = null
          let paymentStatus = data.paymentStatus === PAYMENT_SUCCESS
          switch (data.purchaseOption && data.purchaseOption.purchaseType) {
              case TRANSACTIONAL:
                  type = "Purchase"
                  break;
              default:
                  type = "Subscription"

          }
          let mobitelResub = action.payload.res && action.payload.res.retry
          dispatch(actions.productSubscribeSuccess({ data: data, message: mobitelResub ? action.payload.res.message : (purchaseStatusType.productPurchased || paymentStatus) ?  `${type} Successful` : `${type} Failed` }))
          dispatch(featuredActions.productPurchaseContent(data));
          dispatch(actions.verifyPurchaseSvodSuccess(mobitelResub ? action.payload.res.message : (purchaseStatusType.productPurchased || paymentStatus) ? `${type} Successful` : `${type} Failed`));
        })
        .catch(err => {
          dispatch(actions.productSubscribeFailed(err && err.response && err.response.data && err.response.data.message && err.response.data.message) || "Something went wrong, please contact your service provider")
          dispatch(actions.verifyPurchaseSvodFailed())
        })
        .then(() => done()); // call done when finished dispatching
      // }
    }
  }),

  createLogic({
    type: types.VERIFY_SVOD_UNSUB,
    latest: true,
    debounce: 1000,
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }

      HTTPClient.Get(
        `${endPoints.PURCHASED_PRODUCTS}${action.payload.productId}?conid=${action.payload.connectionId}`
      )
        .then(res => res.data)
        .then(data => {
          let purchaseStatusType = purchaseStatusValidation(data.purchaseStatus)
          dispatch(actions.productUnSubscribeSuccess({ data: data, message: (purchaseStatusType.productPendingRemove || purchaseStatusType.productNotPurchased) ? "Unsubscription Successful" : "Unsubscription Failed. Please try again later." }))
          dispatch(featuredActions.productPurchaseContent(data));
          dispatch(actions.verifyUnsubSvodSuccess((purchaseStatusType.productPendingRemove || purchaseStatusType.productNotPurchased) ? "Unsubscription Successful" : "Unsubscription Failed. Please try again later")); 
        })
        .catch(err => {
          dispatch(actions.productUnSubscribeFailed(err && err.response && err.response.data && err.response.data.message && err.response.data.message) || "Something went wrong, please contact your service provider")
          dispatch(actions.verifyUnsubSvodFailed());
        })
        .then(() => done()); // call done when finished dispatching
      // }
    }
  }),
  createLogic({
    type: types.GET_BUNDLED_PRODUCTS,
    latest: true,
    debounce: 1000,
    process({
      MockHTTPClient,
      getState,
      action
    }, dispatch, done) {
      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }
      HTTPClient.Get(`${endPoints.GET_BUNDLED_PRODUCTS}?basePackageId=${action.payload.basePackageId}&productId=${action.payload.productId}`)
        .then(res => res.data)
        .then(data => dispatch(actions.getBundledProductsSuccess(data)))
        .catch(err => dispatch(actions.getBundledProductsFailed(err)))
        .then(() => done()); // call done when finished dispatching
    }
  }),

  createLogic({
    type: types.GET_SVOD_PRODUCT_PURCHASE_STATUS,
    latest: true,
    debounce: 1000,

    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }
      HTTPClient.Get(`${endPoints.HAS_PAID}${action.payload.transactionId}${endPoints.PAID}`)
        .then(resp => resp.data)
        .then((data) => {
          dispatch(actions.getReferenceNumber(action.payload.transactionId))
          dispatch(actions.getPrepaidSvodPurchaseStatusSuccess(data))
          dispatch(actions.verifyPurchaseSvod({
            productId: action.payload.productId,
            connectionId: localStorage.conID
          }))
        })
        .catch(err => {
          // Log error
          err.response && err.response.data && dispatch(actions.getPrepaidSvodPurchaseStatusFailed(err.response.data.message || "Something went wrong, please contact your service provider"))
        })
        .then(() => done()); // call done when finished dispatching
    }
  }),
  createLogic({
    type: types.GET_SVOD_PURCHASE_OPTIONS,
    latest: true,
    debounce: 1000,
    process({
      MockHTTPClient,
      getState,
      action
    }, dispatch, done) {
      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }
      HTTPClient.Get(`${endPoints.PURCHASED_PRODUCTS}${action.payload.productId}?conid=${action.payload.connectionId}&purchaseOptionId=${action.payload.purchaseOptionId}`)
        .then(res => res.data)
        .then(data => {
          dispatch(actions.getSvodPurchaseOptionsSuccess(data))
        })
        .catch(err => dispatch(actions.getSvodPurchaseOptionsFailed(err)))
        .then(() => done()); // call done when finished dispatching            
    }
  }),
  createLogic({
    type: types.GET_REFERENCE_NUMBER,
    latest: true,
    debounce: 1000,
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }
      HTTPClient.Get(`${endPoints.GET_MY_PURCHASES}/${action.payload}`)
        .then(res => res.data)
        .then(data => dispatch(actions.getReferenceNumberSuccess(data)))
        .catch(err => dispatch(actions.getReferenceNumberFailed(err)))
        .then(() => done()); // call done when finished dispatching
    }
  })

]