import React from 'react';

const Features = ({feature}) => {
    if( feature && ( feature.tstv || feature.pip || feature.cpvr)){
        return(
            <div className="triangle-options">
                <div>
                    {feature.tstv && <i className="fa fa-history" />}
                </div>
            </div>
        )
    }else{
        return null;
    }
}

export default Features;