import React, { Component } from 'react'
import { connect } from "react-redux";
import { loginSelectors } from './../login/ducks'

class Home extends Component {

  componentWillMount() {
    if (!this.props.isAuthenticated) {
      this.props.history.push('/login')
    }
  }

  render() {
    return (
      <div>Home</div>
    )
  }
}

const mapStateToProps = (state) => ({
  ...loginSelectors.getAuthenticated(state)
})

export default connect(mapStateToProps, {})(Home);
