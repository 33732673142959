import React from 'react'
import { Container, Row } from 'react-bootstrap';
import FeaturedSvodsContent from "../LandingPageComponents/FeaturedSvods";
import ViewAllButton from '../ViewAllButton/ViewAllButton';
import { AllSvods } from '../../util/Constants';

function FeaturedSvods({ featuredSvods, onClickSvod }) {
  return ( 
    <div className="featured-Package-Content">
      <Container>
        <div>
          <h4 className="title">Featured Svods</h4>
          <Row className="justify-content-center">
            <FeaturedSvodsContent featuredSvods={featuredSvods} onClick={onClickSvod} />

            {featuredSvods && featuredSvods.featuredSvods &&
              Array.isArray(featuredSvods.featuredSvods) &&
              featuredSvods.featuredSvods.length === 0 && !featuredSvods.loading?
              <div className="svod-row">
                Coming soon...
                   </div>
              :
              null
            } 
          </Row>
          <Row>
            <ViewAllButton path={AllSvods} className="btn-round" btnText='View All' />
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default FeaturedSvods;
