import types from "./types";
import { handleActions } from "redux-actions";

const initialState = {
  baseProducts: null,
  baseProductsError: null,
  baseProductsLoading: true,
  channelsLoading: true,
  allChannelsLoading: true,
  getAllBaseProductsLoading: true,
  trendingLoading: true,
  allVodsLoading: true,
  onDemandSubTypesLoading: true,
  selectedProduct: {
    selectedProduct: null,
    loading: true,
    hasError: false,
    ErrorMessage: null
  },
  userProduct: {
    userProduct: null,
    loading: true,
    hasError: false,
    ErrorMessage: null
  },
  allVODs: {
    allVODs: null,
    loading: true,
    hasError: false,
    ErrorMessage: null
  },
  VODSubType: {
    loading: true,
    hasError: false,
    VODSubType: null,
    VODSubTypeError: null
  },
  VODLanguages: {
    VODLanguages: null,
    loading: true,
    hasError: false,
    VODLanguagesError: null
  },
  VODGenre: {
    loading: false,
    hasError: false,
    VODGenre: null,
    VODGenreError: null
  },
  purchase: {
    purchaseLoading: true,
    unSubLoading: true
  },
  dataBundleLoading: true
};

export default handleActions(
  {
    [types.RESET_FORM]: (state, { payload }) => ({
      ...state,
      selectedProduct: {
        selectedProduct: null,
        loading: true,
        hasError: false,
        ErrorMessage: null
      },
      channel: null,
      purchase: {
        purchaseStatus: null,
        purchaseLoading: true,
        unSubLoading: true

      },
      allVODs: {
        allVODs: null,
        loading: true,
        hasError: false,
        ErrorMessage: null
      },
      userProduct: {
        userProduct: null,
        loading: true,
        hasError: false,
        ErrorMessage: null
      },
      dataBundleLoading: true,
      channelsLoading: true,
      baseProducts: null,
      allPackageTypes: null,
      allProducts: undefined,
      baseProductsLoading: true,
      onDemandSubTypes: null,
      secondaryAllowedIdLoading: true
    }),
    [types.GET_BASE_PRODUCT_SUCCESS]: (state, { payload }) => ({
      ...state,
      baseProducts: payload,
      baseProductsError: null,
      baseProductsLoading: false
    }),
    [types.GET_BASE_PRODUCT_FAILED]: (state, { payload }) => ({
      ...state,
      baseProducts: null,
      baseProductsError: payload,
      baseProductsLoading: false
    }),
    [types.GET_BASE_PRODUCT_LOADING]: (state, { payload }) => ({
      ...state,
      baseProducts: null,
      baseProductsError: payload,
      baseProductsLoading: true
    }),
    [types.GET_ALL_PRODUCTS_LOADING]: (state, { payload }) => ({
      ...state,
      getAllBaseProductsLoading: true
    }),
    [types.GET_ALL_PRODUCTS_SUCCESS]: (state, { payload }) => ({
      ...state,
      allProducts: payload,
      allProductsError: null,
      getAllBaseProductsLoading: false
    }),
    [types.GET_ALL_PRODUCTS_FAILED]: (state, { payload }) => ({
      ...state,
      allProducts: {},
      allProductsError: payload,
      getAllBaseProductsLoading: false
    }),
    [types.GET_CHANNELS_SUCCESS]: (state, { payload }) => ({
      ...state,
      channel: payload,
      channelError: null,
      channelsLoading: false,
      subscribe: false
    }),
    [types.GET_CHANNELS_FAILED]: (state, { payload }) => ({
      ...state,
      channel: {},
      channelError: payload,
      channelsLoading: false
    }),
    [types.GET_CHANNELS_CLEAR]: (state, { payload }) => ({
      ...state,
      channel: null,
      channelError: null,
      channelsLoading: true,
      allProducts: undefined
    }),
    [types.GET_ALL_CHANNELS_SUCCESS]: (state, { payload }) => ({
      ...state,
      allChannels: payload,
      allChannelsError: {},
      allChannelsLoading: false
    }),
    [types.GET_ALL_CHANNELS_FAILED]: (state, { payload }) => ({
      ...state,
      allChannels: {},
      allChannelsError: payload,
      allChannelsLoading: false
    }),
    [types.GET_ALL_CHANNEL_CATEGORIES_SUCCESS]: (state, { payload }) => ({
      ...state,
      allChannelCategories: payload,
      allChannelCategoriesError: {},
      VODGenre: {
        ...state.VODGenre,
        loading: false,
        hasError: false,
        VODGenre: payload
      }
    }),
    [types.GET_ALL_CHANNEL_CATEGORIES_FAILED]: (state, { payload }) => ({
      ...state,
      allChannelCategories: {},
      allChannelCategoriesError: payload,
      VODGenre: {
        ...state.VODGenre,
        loading: false,
        hasError: false,
        VODGenreError: payload
      }
    }),
    [types.GET_ALL_CHANNEL_CATEGORIES_LOADING]: (state, { payload }) => ({
      ...state,
      allChannelCategories: undefined
    }),
    [types.GET_PRODUCTS_TYPES_SUCCESS]: (state, { payload }) => ({
      ...state,
      productTypes: payload,
      productTypesError: {}
    }),
    [types.GET_PRODUCTS_TYPES_FAILED]: (state, { payload }) => ({
      ...state,
      productTypes: {},
      productTypesError: payload
    }),
    [types.GET_ONDEMAND_SUB_TYPES_SUCCESS]: (state, { payload }) => ({
      ...state,
      onDemandSubTypes: payload,
      onDemandSubTypesError: {},
      onDemandSubTypesLoading: false,
      VODSubType: {
        ...state.VODSubType,
        loading: false,
        hasError: false,
        VODSubType: payload
      }
    }),
    [types.GET_ONDEMAND_SUB_TYPES_FAILED]: (state, { payload }) => ({
      ...state,
      onDemandSubTypes: {},
      onDemandSubTypesError: payload,
      onDemandSubTypesLoading: false,
      VODSubType: {
        ...state.VODSubType,
        loading: false,
        hasError: true,
        VODSubTypeError: payload
      }
    }),
    [types.GET_ONDEMAND_SUB_TYPES_LOADING]: (state, { payload }) => ({
      ...state,
      onDemandSubTypesLoading: true,
      onDemandSubTypes: undefined
    }),
    [types.GET_ALL_LANGUAGES_SUCCESS]: (state, { payload }) => ({
      ...state,
      languages: payload,
      languagesError: {},
      VODLanguages: {
        ...state.VODLanguages,
        loading: false,
        hasError: false,
        VODLanguages: payload
      }
    }),
    [types.GET_ALL_LANGUAGES_FAILED]: (state, { payload }) => ({
      ...state,
      languages: {},
      languagesError: payload,
      VODLanguages: {
        ...state.VODLanguages,
        loading: false,
        hasError: true,
        VODLanguagesError: payload
      }
    }),
    [types.GET_TRENDING_VOD_LOADING]: (state, { payload }) => ({
      ...state,
      trendingLoading: true
    }),
    [types.GET_TRENDING_VOD_SUCCESS]: (state, { payload }) => ({
      ...state,
      trending: payload,
      trendingError: {},
      trendingLoading: false
    }),
    [types.GET_TRENDING_VOD_FAILED]: (state, { payload }) => ({
      ...state,
      trending: {},
      trendingError: payload,
      trendingLoading: false
    }),
    [types.GET_ALL_BASE_PRODUCT_TYPES_SUCCESS]: (state, { payload }) => ({
      ...state,
      allBaseProductTypes: payload,
      allBaseProductTypesError: {}
    }),
    [types.GET_ALL_BASE_PRODUCT_TYPES_FAILED]: (state, { payload }) => ({
      ...state,
      allBaseProductTypes: {},
      allBaseProductTypesError: payload
    }),
    // [types.GET_ALL_PACKAGE_TYPES_SUCCESS]: (state, { payload }) => ({
    //   ...state,
    //   allPackageTypes: payload,
    //   allPackageTypesError: {},
    //   secondaryAllowedIdLoading: true
    // }),
    // [types.GET_ALL_PACKAGE_TYPES_FAILED]: (state, { payload }) => ({
    //   ...state,
    //   allPackageTypes: {},
    //   allPackageTypesError: payload
    // }),
    [types.GET_ALL_CHANNEL_BUNDLE_CATEGORIES_SUCCESS]: (
      state,
      { payload }
    ) => ({
      ...state,
      allChannelBundleCategories: payload,
      allChannelBundleCategoriesFailed: {}
    }),
    [types.GET_ALL_CHANNEL_BUNDLE_CATEGORIES_FAILED]: (state, { payload }) => ({
      ...state,
      allChannelBundleCategories: {},
      allChannelBundleCategoriesFailed: payload
    }),
    // [types.GET_DATA_BUNDLE_SUCCESS]: (state, { payload }) => ({
    //   ...state,
    //   dataBundle: payload,
    //   dataBundleError: null,
    //   dataBundleLoading: false
    // }),
    // [types.GET_DATA_BUNDLE_FAILED]: (state, { payload }) => ({
    //   ...state,
    //   dataBundle: {},
    //   dataBundleError: payload,
    //   dataBundleLoading: false
    // }),
    [types.CLEAR_ALL_CHANNELS]: (state, { payload }) => ({
      ...state,
      channelsLoading: true,
      allBaseProductTypes: undefined,
      allChannels: undefined,
      allChannelsLoading: true,
      baseProducts: null,
      productTypes: null,
      dataBundle: null
    }),
    [types.GET_SELECTED_PRODUCT_SUCCESS]: (state, { payload }) => ({
      ...state,
      selectedProduct: {
        ...state.selectedProduct,
        loading: false,
        hasError: false,
        selectedProduct: payload
      },
      // selectedProduct: payload,
      selectedProductError: null,
      selectedProductLoading: false
    }),
    [types.GET_SELECTED_PRODUCT_FAILED]: (state, { payload }) => ({
      ...state,
      selectedProduct: {
        ...state.selectedProduct,
        loading: false,
        hasError: true,
        ErrorMessage: payload,
        selectedProduct: null
      },
      // selectedProductError: payload,
      selectedProductLoading: false
    }),

    [types.PRODUCT_SUBSCRIBE]: (state, { payload }) => {
      return {
        ...state,
        // channel: payload.data,
        purchase: {
          ...state.purchase,
          // status: null,
          // purchaseStatus: "Your request will be processed in few seconds",
          purchaseStatus: null,
          purchaseLoading: true
        }
      };
    },

    [types.PRODUCT_SUBSCRIBE_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        channel: payload.data,
        purchase: {
          ...state.purchase,
          status: payload.data.isPurchased ? "success" : "failed",
          // purchaseStatus: "Your request will be processed in few seconds",
          purchaseStatus: payload.message,
          purchaseLoading: false
        }
      };
    },
    [types.PRODUCT_SUBSCRIBE_FAILED]: (state, { payload }) => ({
      ...state,
      purchase: {
        ...state.purchase,
        status: "failed",
        purchaseStatus: payload,
        purchaseLoading: false
      }
    }),

    [types.PRODUCT_UNSUBSCRIBE]: (state, { payload }) => {
      return {
        ...state,
        // channel: payload.data,
        purchase: {
          ...state.purchase,
          // status: null,
          // purchaseStatus: "Your request will be processed in few seconds",
          purchaseStatus: null,
          // unSubLoading: false
          purchaseLoading: true
        }
      };
    },
    [types.PRODUCT_UNSUBSCRIBE_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        channel: payload.data,
        purchase: {
          ...state.purchase,
          status: !payload.data.isPurchased ? "success" : "failed",
          // purchaseStatus: "Your request will be processed in few seconds",
          purchaseStatus: payload.message,
          // unSubLoading: false
          purchaseLoading: false
        }
      };
    },
    [types.PRODUCT_UNSUBSCRIBE_FAILED]: (state, { payload }) => ({
      ...state,
      purchase: {
        ...state.purchase,
        status: "failed",
        purchaseStatus: payload,
        purchaseLoading: false
      }
    }),
    [types.FILTER_VOD]: (state, { payload }) => {
      return {
        ...state
      };
    },

    [types.GET_PURCHASE_TABLE]: (state, {
      payload
    }) => {
      return {
        ...state,
        purchaseTable: null,
        purchaseTableLoading: true,
        puchaseTableError: null
      };
    },
    [types.GET_PURCHASE_TABLE_SUCCESS]: (state, {
      payload
    }) => {
      return {
        ...state,
        purchaseTable: payload,
        purchaseTableLoading: false,
        puchaseTableError: null
      };
    },
    [types.GET_PURCHASE_TABLE_FAILED]: (state, {
      payload
    }) => {
      return {
        ...state,
        purchaseTable: null,
        purchaseTableLoading: false,
        puchaseTableError: payload
      };
    },
    [types.GET_VOD_ITEM_SUCCESS]: (state, { payload }) => ({
      ...state,
      channel: payload,
      channelError: null,
      channelsLoading: false,
      subscribe: false
    }),
    [types.GET_VOD_ITEM_FAILED]: (state, { payload }) => ({
      ...state,
      channel: {},
      channelError: payload,
      channelsLoading: false
    }),
    [types.CLEAR_PURCHASE_MESSAGE]: (state, { payload }) => ({
      ...state,
      purchase: {
        purchaseStatus: null,
        purchaseLoading: true
      }
    }),
  },
  initialState
);
//1169