import { createLogic } from "redux-logic";
import actions from "./actions";
import types from "./types";
import endPoints from "./../../../util/EndPoints";
import API from "./../../../util/HTTPClient";
import history from "../../../_helpers/history";

export default [
  createLogic({ 
    type: types.GET_TYPES,
    latest: true,
    debounce: 1000,

    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }
      HTTPClient.Get(endPoints.GET_TYPES, action.payload)
        .then(resp => resp.data)
        .then(data => {
          dispatch(actions.getTypesSuccess(data));
        })
        .catch(err => {
          // Log error
          dispatch(actions.getTypesFailed(err.response));
        })
        .then(() => done()); // call done when finished dispatching
    }
  }),

  createLogic({
    type: types.GET_DISTRICTS,
    latest: true,
    debounce: 1000,

    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }
      HTTPClient.Get(endPoints.GET_DISTRICTS)
        .then(resp => resp.data)
        .then(data => {
          dispatch(actions.getDistrictsSuccess(data));
        })
        .catch(err => {
          // Log error
          dispatch(actions.getDistrictsFailed(err.response));
        })
        .then(() => done()); // call done when finished dispatching
    }
  }),

  createLogic({
    type: types.NON_SLT_DETAILS,
    latest: true,
    debounce: 1000,

    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }

      HTTPClient.Post(endPoints.NON_SLT_DETAILS, action.payload)
        .then(resp => resp.data)
        .then(data => {
          dispatch(actions.nonSltDetailsSubmitSuccess(data));
          history.push("/furtherdetails");
        })
        .catch(err => {
          // Log error
          dispatch(
            actions.nonSltDetailsSubmitFailed(
              (err.response.data && err.response.data.message) ||
              "Something went wrong, please contact your service provider"
            )
          );
        })
        .then(() => done()); // call done when finished dispatching
    }
  }),
  createLogic({
    type: types.CONFIRM_EMAIL,
    latest: true,
    debounce: 1000,

    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }

      HTTPClient.Get(`${endPoints.CONFIRM_EMAIL}/${action.payload}`)
        .then(resp => resp.data)
        .then(data => {
          dispatch(actions.confirmEmailSuccess(data));
          history.push("/login");
        })
        .catch(err => {
          // Log error
          dispatch(
            actions.confirmEmailFailed(
              (err.response.data && err.response.data.message) ||
              "Something went wrong, please contact your service provider"
            )
          );
        })
        .then(() => done()); // call done when finished dispatching
    }
  })
];
