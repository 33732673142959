import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form';

import { InputField } from './../../components/controls/Fields'
import { CustomButton } from './../../components/controls/CustomButton'
import { ErrorMessage } from './../../components/customMessages/ErrorMessage'
import './../login/LoginStyle.scss';
import { registrationSelectors, registrationActions } from './ducks';
import { getConnectionType } from '../../util/ArrayValidator';
import { MOBITEL_HEADER_ENRICHMENT } from '../../util/Constants';


class AccountLoginView extends Component {
    constructor() {
        super();

        this.state = {
            telephoneNumber: '',
            accountNumber: '',
            userAccountError: undefined,
            loading: false,
            userType: null
        }
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    componentDidMount() {
        var user = getConnectionType()
        this.setState({ userType: user, userAccountError: undefined })
        if (user === undefined) {
            window.location = MOBITEL_HEADER_ENRICHMENT
        }
        this.props.clearErrorMsg()

    }
    static getDerivedStateFromProps(nextProps, state) {
        let newProps = {}

        if (nextProps.userAccountError === undefined) {
            newProps.userAccountError = undefined
        }
        else {
            if (nextProps.userAccountError && nextProps.userAccountError !== state.userAccountError) {
                newProps.userAccountError = nextProps.userAccountError
                newProps.loading = false
            }
        }
        return {
            ...newProps
        }
    }

    handleSubmit(values) {
        // var jwt = localStorage.getItem("@token")
        this.setState({
            loading: true,
            userAccountError: undefined
        })

        var connectionType = parseInt(this.state.userType)
        var extension = null
        switch (this.state.userType) {
            case "1":
                extension = ""
                break;
            case "2":
                extension = "94"
                break;
            case "3":
                extension = "94"
                break;
            default:
                extension = "0"

        }

        const { accountNumber } = values;
        let telephoneNumber = extension + values.telephoneNumber
        this.props.accountLogin({
            telephoneNumber,
            accountNumber,
            connectionType
        })

    }

    render() {
        const { loading, userType, userAccountError } = this.state
        const { handleSubmit, alreadyUser } = this.props;
        let dynamic = [
            {
                "type": "1",
                "extention": null,
                "label": "SLT Telephone Number"
            },
            {
                "type": "2",
                "extention": "+94",
                "label": "SLT Telephone Number"
            },
            {
                "type": "3",
                "extention": "+94",
                "label": "SLT Telephone Number"
            },
            {
                "type": "4",
                "extention": "0",
                "label": "SLT Telephone Number"
            },
            {
                "type": "5",
                "extention": "0",
                "label": "SLT Telephone Number"
            },
            {
                "type": "6",
                "extention": "0",
                "label": "SLT Telephone Number"
            },
            {
                "type": "7",
                "extention": null,
                "label": "Mobitel Number"
            }

        ]

        return (
            <div className="container card-page account-login-scrollable" >
                <div className="card col-sm-12">
                    <form className="register-form" onSubmit={handleSubmit(this.handleSubmit)}>
                        {dynamic.map(v => {
                            if (userType === v.type) {
                                return <div className="form-group row">
                                    <label className="col-sm-12 col-md-4 col-form-label" required><span style={{ color: "red" }}> * </span>{v.label}</label>
                                    <div className="col-sm-12 col-md-8" >
                                        <Field
                                            key="telephoneNumber"
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            name="telephoneNumber"
                                            component={InputField}
                                            userType={v.extention ? v.extention : null}
                                        />

                                    </div>
                                </div>
                            }
                            return null
                        })}
                        <div className="form-group row">
                            <label className="col-sm-12 col-md-4 col-form-label" required> <span style={{ color: "red" }}> * </span>SLT Account Number <img src={require("../../images/icons/icon.svg")} style={{ width: "25px", marginLeft: -10, paddingLeft: 3 }} alt="info"/></label>
                            <div className="col-sm-12 col-md-8">
                                <Field
                                    key="accountNumber"
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    name="accountNumber"
                                    component={InputField}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <CustomButton id="accountlogin" text="Submit" disabled={loading} loading={loading} />
                        </div>
                        <p style={{ color: "#ffffff", fontSize: 12, textAlign: 'center', margin: 0 }}> <span style={{ color: "red" }}> * </span>Required Fields</p>
                        { userType !==7 &&<p style={{ color: "#ffffff", fontSize: 12, textAlign: 'center', margin: 0 }}>
                            <img src={require("../../images/icons/icon.svg")} style={{ width: "25px", marginLeft: 20 }} alt="info"/> Your SLT Account number is mentioned in your monthly SLT bill</p>}

                    </form>
                    <ErrorMessage
                        errorStatus={userAccountError}
                        errorMessage={userAccountError}
                    />
                    {
                        alreadyUser &&
                        <div className="alert alert-warning" role="alert">
                            You have already registerd. Redirecting to login page ...
                        </div>
                    }
                </div>
            </div >
        )
    }
}

const validate = values => {
    const errors = {}
    if (!values.telephoneNumber) {
        errors.telephoneNumber = 'Telephone Number is required'
    }
    if (!values.accountNumber) {
        errors.accountNumber = 'Account Number is required'
    }
    if (getConnectionType() === "1") {
        if (values.telephoneNumber && values.telephoneNumber && (!/^[0-9]{10}_[0-9]{1}$|[0-9]{10}$/i.test(values.telephoneNumber))) {
            errors.telephoneNumber = 'Invalid telephone peo number'
        }
    }
    else if (getConnectionType() === "7") {
        if (values.telephoneNumber && values.telephoneNumber && (!/^070[0-9]{7}$|071[0-9]{7}$/i.test(values.telephoneNumber))) {
            errors.telephoneNumber = 'Invalid telephone number'
        }
    }
    else {
        if (values.telephoneNumber && values.telephoneNumber && (!/^[0-9]{9}$/i.test(values.telephoneNumber))) {
            errors.telephoneNumber = 'Invalid telephone number'
        }
    }

    if (values.accountNumber && (!/^[a-zA-Z0-9]{1,20}$/i.test(values.accountNumber))) {
        errors.accountNumber = 'Invalid account number'
    }
    return errors;
}

const mapStateToProps = (state) => {
    return {
        ...registrationSelectors.submitDetails(state),
        ...registrationSelectors.getErrors(state)

    }
};

export default reduxForm({
    form: 'profile',
    validate,
    //enableReinitialize: true,
})(connect(mapStateToProps, registrationActions)(AccountLoginView))
