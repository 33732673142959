// layouts
import DefaultLayout from "./layouts/DefaultLayout.jsx";
import LandingLayout from "./layouts/LandingLayout";
// views
import Views from "./modules";

// const loggedUser = Authorization([UserRole.SUPER_USER])

const routes = [
  {
    path: "/login",
    layout: DefaultLayout,
    exact: true,
    component: Views.LoginView
  },
  {
    path: "/customer",
    layout: DefaultLayout,
    exact: true,
    component: Views.CustomersView
  },
  {
    path: "/customer/selection",
    layout: DefaultLayout,
    exact: true,
    component: Views.CustomerSelectionView
  },
  {
    path: "/account-login",
    layout: DefaultLayout,
    exact: true,
    component: Views.AccountLoginView
  },
  {
    path: "/personaldetails",
    layout: DefaultLayout,
    exact: true,
    component: Views.PersonalDetailsView
  },
  {
    path: "/verify",
    layout: DefaultLayout,
    exact: true,
    component: Views.InsetOtpView
  },
  {
    path: "/subscribe",
    layout: DefaultLayout,
    exact: true,
    component: Views.SubscriptionView
  },
  {
    path: "/auth",
    layout: DefaultLayout,
    exact: true,
    component: Views.AuthenticationCodeView
  },
  {
    path: "/authcode",
    layout: DefaultLayout,
    exact: true,
    component: Views.ShowAuthCodeView
  },
  {
    path: "/customer/already_registered",
    layout: DefaultLayout,
    exact: true,
    component: Views.AlreadyRegisteredView
  },
  {
    path: "/getconnection",
    layout: DefaultLayout,
    exact: true,
    component: Views.LinkToProviderView
  },
  {
    path: "/furtherdetails",
    layout: DefaultLayout,
    exact: true,
    component: Views.GoToPeotvView
  },
  {
    path: "/password",
    layout: DefaultLayout,
    exact: true,
    component: Views.ForgetPasswordView
  },
  {
    path: "/pwd_recovery/:id",
    layout: DefaultLayout,
    exact: true,
    component: Views.RecoverPasswordView
  },
  {
    path: "/email/confirmation/:id",
    layout: DefaultLayout,
    exact: true,
    component: Views.ConfirmEmailView
  },
  {
    path: "/viewpackage",
    layout: DefaultLayout,
    exact: true,
    component: Views.ViewPackagePage
  },
  {
    path: "/",
    layout: LandingLayout,
    exact: true,
    component: Views.LandingPageView
  },
  {
    path: "/existingcustomer",
    layout: DefaultLayout,
    exact: true,
    component: Views.CustomersView
  },
  {
    path: "/profile",
    layout: LandingLayout,
    exact: true,
    component: Views.Profile
  },
  {
    path: "/packages",
    layout: LandingLayout,
    exact: true,
    component: Views.AllPackagePage
  },
  {
    path: "/products/:id/:purchasable",
    layout: LandingLayout,
    exact: true,
    component: Views.ProductPage
  },
  {
    path: "/products/:id",
    layout: LandingLayout,
    exact: true,
    component: Views.ProductPage
  },
  {
    path: "/channels",
    layout: LandingLayout,
    exact: true,
    component: Views.AllChannelPage
  },
  {
    path: "/ondemand",
    layout: LandingLayout,
    exact: true,
    // component: Views.OnDemandContentPageView 
    component: Views.AllVodPage

  },
  {
    path: "/svod",
    layout: LandingLayout,
    exact: true,
    component: Views.AllSVODPage
  },
  {
    path: "/screens",
    layout: LandingLayout,
    exact: true,
    component: Views.AllDevicesPage
  },
  {
    path: "/databundles",
    layout: LandingLayout,
    exact: true,
    component: Views.AllDataBundlesPage
  },
  {
    path: "/payment-status",
    layout: LandingLayout,
    exact: true,
    component: Views.PaymentDetails
  },
  {
    path: "/screen/:id",
    layout: LandingLayout,
    exact: true,
    component: Views.ProductPage
  },
  {
    path: "/ondemand/:id",
    layout: LandingLayout,
    exact: true,
    component: Views.OnDemandContentPageView
  },
  {
    path: "/svod/:id",
    layout: LandingLayout,
    exact: true,
    component: Views.SvodPageView
  },
  {
    path: "/vod/:id",
    layout: LandingLayout,
    exact: true,
    component: Views.ProductPage
  },
  {
    path: "/svod/:id/vods",
    layout: LandingLayout,
    exact: true,
    component: Views.SubSvodPageView
  },
  {
    path: "/termsandconditions",
    layout: LandingLayout,
    exact: true,
    component: Views.TermsOfUse
  },
  {
    path: "/svod/:id/vod",
    layout: LandingLayout,
    exact: true,
    component: Views.ProductVod
  },
  {
    path: "/paymentStatus",
    layout: DefaultLayout,
    exact: true,
    component: Views.LoadingPage
  },
  {
    path: "/promotions",
    layout: LandingLayout,
    exact: true,
    component: Views.AllPromotions
  },
  {
    path: "/promotions/packages",
    layout: LandingLayout,
    exact: true,
    component: Views.PromotionalPackages
  },
  {
    path: "/promotions/packages/:id",
    layout: LandingLayout,
    exact: true,
    component: Views.PromotionalPackages
  },
  {
    path: "/provisioning-failed",
    layout: LandingLayout,
    exact: true,
    component: Views.ProvisioningErrorsView
  },
  {
    path: "/channel-bundle/:id",
    layout: LandingLayout,
    exact: true,
    component: Views.ChannelBundleView
  },
  {
    path: "/channel-bundle/:id/channel/",
    layout: LandingLayout,
    exact: true,
    component: Views.SubProductView
  },
  {
    layout: DefaultLayout,
    component: Views.NotFoundView
  }
];

export default routes;
