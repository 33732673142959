import types from './types'
import { handleActions } from 'redux-actions'
import { PREPAID_SERVICE_TYPE,MOBITEL_SERVICE_TYPE } from '../../../util/Constants'

const initialState = {
	featuredChannelsLoading: true,
	featuredPackagesLoading: true,
	onDemandContentsLoading: true,
	bannerContentLoading: true,
	loading: true,
	userDetailsLoading: true,
	connectionDetailsLoading: true,
	connectionContentLoading: true,
	tagsLoading: true,
	featuredPackageChannelsLoading: true,
	featuredPackageVodLoading: true,
	featuredSvodsLoading: true,
	featuredPackageSvodLoading: true,
	purchasedPrimaryPackageLoading: true,
	secondaryAllowedProductsLoading: true,
	secondaryAllowedIdLoading: true,
	purchasedPrimaryDataBundleLoading: true,
	selectedChannelIndex: 0,
	passwordUpdateMessage: null,
	showModal: null,
	showNotifyModal: false,
	passwordUpdate: {
		passwordUpdateMessage: null,
		showModal: null,
		showNotifyModal: false,
		hasError: false,
		loading: false
	},
	passwordUpdateShowModal: false,
	passwordUpdateShowNotifyModal: false,
	unsubLoading: null,
	removeProfileLoading: false
}

// Reducers from redux-actions
export default handleActions({

	[types.RESET]: (state, { payload }) => (
		{
			...state, passwordUpdateMessage: null, showModal: null, showNotifyModal: false,
			passwordUpdateShowModal: false,
			passwordUpdateShowNotifyModal: false,
			passwordUpdate: {
				passwordUpdateMessage: null,
				showModal: null,
				showNotifyModal: false,
				hasError: false,
				loading: false
			},
			// secondaryAllowedIdLoading: true,
			secondaryAllowedId: undefined,
			packagePurchaseStatusError : undefined,
			reactivateProductError: undefined,
			reactivateProduct: undefined
			// bannerContent: null, bannerContentLoading: true 
			// featuredChannels: null,
		}
	),
	[types.GET_PRODUCT_TYPES_FAILED]: (state, { payload }) => (
		{ ...state, productTypesError: payload, loading: false }
	),

	[types.GET_PRODUCT_TYPES_SUCCESS]: (state, { payload }) => (
		{ ...state, productTypes: payload, productTypesError: null, loading: false }
	),

	[types.GET_FEATURED_CHANNELS_LOADING]: (state, { payload }) => (
		{ ...state, featuredChannelsLoading: true }
	),

	[types.GET_FEATURED_CHANNELS_FAILED]: (state, { payload }) => (
		{ ...state, featuredChannelsError: payload, featuredChannelsLoading: false }
	),

	[types.GET_FEATURED_CHANNELS_SUCCESS]: (state, { payload }) => (
		{ ...state, featuredChannels: payload, featuredChannelsError: null, featuredChannelsLoading: false }
	),

	[types.GET_FEATURED_PACKAGES_LOADING]: (state, { payload }) => (
		{ ...state, featuredPackagesLoading: true }
	),

	[types.GET_FEATURED_PACKAGES_FAILED]: (state, { payload }) => (
		{ ...state, featuredPackagesError: payload, featuredPackagesLoading: false }
	),

	[types.GET_FEATURED_PACKAGES_SUCCESS]: (state, { payload }) => (
		{ ...state, featuredPackages: payload, featuredPackagesError: null, featuredPackagesLoading: false }
	),

	[types.GET_ON_DEMAND_CONTENT_LOADING]: (state, { payload }) => (
		{ ...state, onDemandContentsLoading: true }
	),

	[types.GET_ON_DEMAND_CONTENT_FAILED]: (state, { payload }) => (
		{ ...state, onDemandContentsError: payload, onDemandContentsLoading: false }
	),

	[types.GET_ON_DEMAND_CONTENT_SUCCESS]: (state, { payload }) => (
		{ ...state, onDemandContents: payload, onDemandContentsError: null, onDemandContentsLoading: false }
	),

	[types.GET_FEATURED_SVOD_LOADING]: (state, { payload }) => (
		{ ...state, featuredSvodsLoading: true }
	),

	[types.GET_FEATURED_SVOD_FAILED]: (state, { payload }) => (
		{ ...state, featuredSvodsError: payload, featuredSvodsLoading: false }
	),

	[types.GET_FEATURED_SVOD_SUCCESS]: (state, { payload }) => (
		{ ...state, featuredSvods: payload, featuredSvodsError: null, featuredSvodsLoading: false }
	),
	[types.GET_BANNER]: (state, { payload }) => (
		{ ...state, bannerContent: "", bannerContentLoading: true, bannerContentError: null }
	),
	[types.GET_BANNER_SUCCESS]: (state, { payload }) => (
		{ ...state, bannerContent: payload, bannerContentError: null }
	),
	[types.GET_BANNER_FAILED]: (state, { payload }) => (
		{ ...state, bannerContent: null, bannerContentError: payload, bannerContentLoading: false }
	),
	[types.GET_USER_DETAILS_SUCCESS]: (state, { payload }) => (
		{ ...state, userDetails: payload, error: null, userDetailsLoading: false, connectionDetailsLoading: true }
	),
	[types.GET_USER_DETAILS_FAILED]: (state, { payload }) => (
		{ ...state, userDetails: null, error: payload, userDetailsLoading: false, connectionDetailsLoading: true }
	),
	[types.GET_FEATURED_CHANNELS_PACKAGE_SUCCESS]: (state, { payload }) => (
		{ ...state, featuredPackageChannels: payload, error: null, featuredPackageChannelsLoading: false }
	),
	[types.GET_FEATURED_CHANNELS_PACKAGE_FAILED]: (state, { payload }) => (
		{ ...state, featuredPackageChannels: null, error: payload, featuredPackageChannelsLoading: false }
	),
	[types.GET_CONNECTION_DETAILS_SUCCESS]: (state, { payload }) => (
		{ ...state, connectionDetails: payload, error: null, connectionDetailsLoading: false }
	),
	[types.GET_CONNECTION_DETAILS_FAILED]: (state, { payload }) => (
		{ ...state, connectionDetails: null, error: payload, connectionDetailsLoading: false }
	),
	// [types.GET_CONNECTION_CONTENT]: (state, { payload }) => (
	// 	{ ...state, connectionContentLoading: true,
	// 		//  connectionDetailsLoading: true 
	// 		}
	// ),
	[types.GET_CONNECTION_CONTENT_SUCCESS]: (state, { payload }) => (
		{ ...state, connectionContent: payload, error: null, connectionContentLoading: false, reactivateProduct: undefined }
	),
	[types.GET_CONNECTION_CONTENT_FAILED]: (state, { payload }) => (
		{ ...state, connectionContent: null, error: payload, connectionContentLoading: false }
	),
	[types.GET_ALL_TAGS_SUCCESS]: (state, { payload }) => (
		{ ...state, tags: payload, error: null, tagsLoading: false }
	),
	[types.GET_ALL_TAGS_FAILED]: (state, { payload }) => (
		{ ...state, tags: null, error: payload, tagsLoading: false }
	),
	[types.GET_FEATURED_VOD_PACKAGE_SUCCESS]: (state, { payload }) => (
		{ ...state, featuredPackageVod: payload, error: null, featuredPackageVodLoading: false }
	),
	[types.GET_FEATURED_VOD_PACKAGE_FAILED]: (state, { payload }) => (
		{ ...state, featuredPackageVod: null, error: payload, featuredPackageVodLoading: false }
	),
	[types.GET_FEATURED_SVOD_PACKAGE_SUCCESS]: (state, { payload }) => (
		{ ...state, featuredPackageSvod: payload, error: null, featuredPackageSvodLoading: false }
	),
	[types.GET_FEATURED_SVOD_PACKAGE_FAILED]: (state, { payload }) => (
		{ ...state, featuredPackageSvod: null, error: payload, featuredPackageSvodLoading: false }
	),
	[types.GET_PURCHASED_PRIMARY_PACKAGE_SUCCESS]: (state, { payload }) => (
		{ ...state, purchasedPrimaryPackage: payload, error: null, purchasedPrimaryPackageLoading: false }
	),
	[types.GET_PURCHASED_PRIMARY_PACKAGE_FAILED]: (state, { payload }) => (
		{ ...state, purchasedPrimaryPackage: null, error: payload, purchasedPrimaryPackageLoading: false }
	),
	[types.GET_ONDEMAND_SUB_TYPES_SUCCESS]: (state, { payload }) => (
		{ ...state, onDemandSubTypes: payload, onDemandSubTypesError: {}, onDemandSubTypesLoading: false }
	),
	[types.GET_ONDEMAND_SUB_TYPES_FAILED]: (state, { payload }) => (
		{ ...state, onDemandSubTypes: {}, onDemandSubTypesError: payload, onDemandSubTypesLoading: false }
	),

	[types.GET_ALLOWED_SECONDARY_PACKAGES_SUCCESS]: (state, { payload }) => (
		{ ...state, secondaryAllowedProducts: payload, secondaryAllowedProductsError: null, secondaryAllowedProductsLoading: false }
	),
	[types.GET_ALLOWED_SECONDARY_PACKAGES_FAILED]: (state, { payload }) => (
		{ ...state, secondaryAllowedProducts: "", secondaryAllowedProductsError: payload, secondaryAllowedProductsLoading: false }
	),
	[types.GET_ALLOWED_SECONDARY_PACKAGES_LOADING]: (state, { payload }) => (
		{ ...state, secondaryAllowedProductsLoading: true, secondaryAllowedProducts: undefined }
	),
	[types.GET_SECONDARY_ID_SUCCESS]: (state, { payload }) => (
		{ ...state, secondaryAllowedId: payload, secondaryAllowedIdError: null, secondaryAllowedIdLoading: false }
	),
	[types.GET_SECONDARY_ID_FAILED]: (state, { payload }) => (
		{ ...state, secondaryAllowedId: "", secondaryAllowedIdError: payload, secondaryAllowedIdLoading: false }
	),
	[types.LOGOUT_SUCCESS]: (state, { payload }) => (
		{ ...state, logout: payload, logoutError: null, bannerContent: null, bannerContentLoading: true, userDetails: null }
	),
	[types.LOGOUT_FAILED]: (state, { payload }) => (
		{ ...state, logout: "", logoutError: payload, userDetails: null }
	),
	[types.GET_PURCHASED_PRIMARY_DATA_BUNDLE_SUCCESS]: (state, { payload }) => (
		{ ...state, purchasedPrimaryDataBundle: payload, purchasedPrimaryDataBundleLoading: false }
	),
	[types.GET_PURCHASED_PRIMARY_DATA_BUNDLE_FAILED]: (state, { payload }) => (
		{ ...state, purchasedPrimaryDataBundle: [], error: payload, purchasedPrimaryDataBundleLoading: false }
	),
	[types.UNSUB_CONNECTION]: (state, { payload }) => (
		{ ...state, unsubId: payload, unsubError: undefined, unsubLoading: true }
	),
	[types.UNSUB_CONNECTION_SUCCESS]: (state, {
		payload
	}) => {
		if(localStorage.serviceType !== MOBITEL_SERVICE_TYPE && localStorage.serviceType!==PREPAID_SERVICE_TYPE){
			if (state.connectionDetails && Array.isArray(state.connectionDetails) && state.connectionDetails.length !== 0) {
				state.connectionDetails && state.connectionDetails.map((unsub, index) => {
					if (unsub.connectionId === parseInt(state.unsubId)) {
						return state.connectionDetails.splice(index, 1);
					}
					return null
				})
			}
			// localStorage.removeItem('conID');
			if (state.connectionDetails && Array.isArray(state.connectionDetails) && state.connectionDetails.length !== 0) {
				localStorage.setItem('conID', state.connectionDetails[0].connectionId);
				localStorage.setItem('selectedUserIndex', 0);
				localStorage.setItem('serviceType', state.connectionDetails[0].serviceType)
				localStorage.setItem('isBroadBand',state.connectionDetails[0].broadband)
				localStorage.setItem('connectionStatus',state.connectionDetails[0].status)
				localStorage.setItem('userTelephoneNumber', state.connectionDetails[0].telephoneNumber)
			}
		}
		return {
			...state,
			unsub: payload,
			unsubError: undefined,
			connectionDetailsLoading: true
		}
	},
	[types.UNSUB_CONNECTION_FAILED]: (state, { payload }) => (
		{ ...state, unsub: undefined, unsubError: payload, unsubLoading: false }
	),
	[types.GET_SELECTED_CONNECTION_INDEX_SUCCESS]: (state, { payload }) => (
		{ ...state, selectedChannelIndex: payload }
	),
	[types.PASSWORD_UPDATE]: (state, { payload }) => (
		{
			...state,
			passwordUpdate: {
				...state.passwordUpdate,
				loading: true
			}
		}
	),
	[types.PASSWORD_UPDATE_SUCCESS]: (state, { payload }) => (
		{
			...state,
			passwordUpdateShowModal: false,
			passwordUpdateShowNotifyModal: true,
			passwordUpdateMessage: payload,
			passwordUpdate: {
				passwordUpdateMessage: payload,
				showModal: false,
				showNotifyModal: true,
				passwordUpdateShowModal: false,
				hasError: false,
				loading: false
			}
		}
	), [types.PASSWORD_UPDATE_FAIL]: (state, { payload }) => (
		{
			...state,
			passwordUpdateShowModal: false,
			passwordUpdateShowNotifyModal: true,
			passwordUpdateMessage: payload.responseErr,
			passwordUpdate: {
				passwordUpdateMessage: payload,
				showModal: false,
				showNotifyModal: true,
				passwordUpdateShowModal: false,
				hasError: true,
				loading: false
			}
		}
	),
	[types.PASSWORD_UPDATE_SHOW_MODAL_OPEN]: (state, { payload }) => (
		{
			...state,
			passwordUpdateShowModal: true
		}
	),
	[types.PASSWORD_UPDATE_SHOW_MODAL_CLOSE]: (state, { payload }) => (
		{
			...state,
			passwordUpdateShowModal: false
		}
	),
	[types.PASSWORD_UPDATE_SHOW_NOTIFY_MODAL_OPEN]: (state, { payload }) => (
		{
			...state,
			passwordUpdateShowNotifyModal: true
		}
	),
	[types.PASSWORD_UPDATE_SHOW_NOTIFY_MODAL_CLOSE]: (state, { payload }) => (
		{
			...state,
			passwordUpdateShowNotifyModal: false
		}
	),
	[types.CLEAR_LANDING_STATE]: (state, { payload }) => ({
		...state,
		secondaryAllowedProductsLoading: true,
		secondaryAllowedId: "",
		connectionDetailsLoading: true,
		featuredChannelsLoading: true,
		onDemandContentsLoading: true,
		featuredSvodsLoading: true,
		purchasedPrimaryDataBundleLoading: true,
		secondaryAllowedIdLoading: true,
		featuredChannels: null,
		onDemandContents: null,
		featuredSvods: null,
		bannerContent: null,
		bannerContentLoading: true
	}),
	[types.PRODUCT_PURCHASE_CONTENT]: (state, {
		payload
	}) => {
		if (payload.productTypeTypeName === 'channel' || payload.productTypeTypeName === 'channelbundle') {
			state.featuredChannels.forEach((element, index) => {
				if (element.id === payload.id) {
					state.featuredChannels[index] = payload;
				}
			});
		} else if (payload.productTypeTypeName === 'vod') {
			state.onDemandContents.forEach((element, index) => {
				if (element.id === payload.id) {
					state.onDemandContents[index] = payload;
				}
			});
		} else if (payload.productTypeTypeName === 'svod') {
			state.featuredSvods.forEach((element, index) => {
				if (element.id === payload.id) {
					state.featuredSvods[index] = payload;
				}
			});
		}
		else if (payload.productTypeTypeName === 'screens') {
			state.secondaryAllowedProducts && state.secondaryAllowedProducts[0] && state.secondaryAllowedProducts[0].purchasableProductList.forEach((element, index) => {
				if (element.id === payload.id) {
					state.secondaryAllowedProducts[0].purchasableProductList[index] = payload;
				}
			});
		}
		return {
			...state,
			featuredChannels: state.featuredChannels,
			onDemandContents: state.onDemandContents,
			featuredSvods: state.featuredSvods
		}
	},
	[types.USER_UNSUB_STATUS]: (state, { payload }) => (
		{
			...state,
			unsubLoading: false,
		}
	),
	[types.CLEAR_UNSUB_STATUS]: (state, { payload }) => (
		{
			...state,
			unsub: undefined
		}
	),

	[types.REMOVE_PROFILE]: (state, { payload }) => {
		return {
			...state,
			removeProfileLoading: true,
			removeProfileId: payload,

		}
	},

	[types.REMOVE_PROFILE_SUCCESS]: (state, { payload }) => {
		if (state.connectionContent && state.connectionContent.profiles && Array.isArray(state.connectionContent.profiles) && state.connectionContent.profiles !== 0) {
			state.connectionContent.profiles.map((removeId, index) => {
				if (removeId.profileUid === state.removeProfileId) {
					return state.connectionContent.profiles.splice(index, 1);
				}
				return null
			})
		}
		return {
			...state,
			removeProfileLoading: false,
			removeProfile: true,
			removeProfileError: undefined
		}
	},

	[types.REMOVE_PROFILE_FAILED]: (state, { payload }) => (
		{
			...state,
			removeProfileLoading: false,
			removeProfile: undefined,
			removeProfileError: payload
		}),

	[types.REACTIVATE_PRODUCT]: (state, { payload }) => (
		{
			...state,
			reactivateProductLoading: true,
			packagePurchaseStatus: undefined,
			reactivateProduct: undefined
		}),
	[types.REACTIVATE_PRODUCT_SUCCESS]: (state, { payload }) => (
		{
			...state,
			reactivateProductLoading: false,
			reactivateProduct: payload,
			reactivateProductError: null
		}),
	[types.REACTIVATE_PRODUCT_FAILED]: (state, { payload }) => (
		{
			...state,
			reactivateProductLoading: false,
			reactivateProduct: null,
			reactivateProductError: payload
		}),
		[types.GET_PACKAGE_PURCHASE_TABLE_SUCCESS]: (state, {
			payload
		}) => {
			return {
				...state,
				packagePurchaseTable: payload,
				packagePurchaseTableLoading: false,
				packagePurchaseTableError: null
			};
		},
		[types.GET_PACKAGE_PURCHASE_TABLE_FAILED]: (state, {
			payload
		}) => {
			return {
				...state,
				packagePurchaseTable: null,
				packagePurchaseTableLoading: false,
				packagePurchaseTableError: payload
			};
		},
		[types.GET_PURCHASE_TABLE_SUCCESS]: (state, {
        payload
    }) => {
        return {
            ...state,
            purchaseTable: payload,
            purchaseTableLoading: false,
            puchaseTableError: null
        };
    },
    [types.GET_PURCHASE_TABLE_FAILED]: (state, {
        payload
    }) => {
        return {
            ...state,
            purchaseTable: null,
            purchaseTableLoading: false,
            puchaseTableError: payload
        };
	},
	[types.GET_PACKAGE_STATUS]: (state, { payload }) => (
        { ...state, packagePurchaseStatus: undefined, packagePurchaseStatusError: undefined, packagePurchaseStatusLoading: true, reactivateProductLoading: true }
    ),
    [types.GET_PACKAGE_STATUS_SUCCESS]: (state, { payload }) => (
        { ...state, packagePurchaseStatus: payload, packagePurchaseStatusError: null, reactivateProductLoading: false, packagePurchaseStatusLoading: false }
    ),
    [types.GET_PACKAGE_STATUS_FAILED]: (state, { payload }) => (
        { ...state, packagePurchaseStatus: undefined, packagePurchaseStatusError: payload, packagePurchaseStatusLoading: false, reactivateProductLoading: false }
    ),
    [types.GET_PACKAGE_STATUS_LOADING]: (state, { payload }) => (
        { ...state, packagePurchaseStatus: undefined, packagePurchaseStatusError: undefined, packagePurchaseStatusLoading: false }
    ),
	[types.GET_BANNER_TYPE_PRODUCTS]: (state, { payload }) => (
		{ 
			...state, 
		}
	),
	[types.GET_BANNER_TYPE_PRODUCTS_SUCCESS]: (state, { payload }) => 
		{
			payload.map((obj) => {
				state.bannerContent.map((banner, index) => {
					if(obj.id === index){
						state.bannerContent[index].type = obj.data
					}
					return null
				})
				return null
			})
			
			return { 
			...state, 
			bannerContentLoading: false
		}
	},
	[types.VERIFY_PREPAID_PAYMENT]: (state, { payload }) => (
        { ...state, verifyPayment: undefined, verifyPaymentError: undefined, verifyPaymentLoading: true, reactivateProductLoading: true }
    ),
    [types.VERIFY_PREPAID_PAYMENT_SUCCESS]: (state, { payload }) => (
        { ...state, verifyPayment: payload, verifyPaymentError: null, reactivateProductLoading: false, verifyPaymentLoading: false }
    ),
    [types.VERIFY_PREPAID_PAYMENT_FAILED]: (state, { payload }) => (
        { ...state, verifyPayment: undefined, verifyPaymentError: payload, verifyPaymentLoading: false, reactivateProductLoading: false }
    ),
	[types.GET_BANNER_TYPE_PRODUCTS_FAILED]: (state, { payload }) => 
		{
			return { 
			...state
		}
	},
	[types.GET_BANNER_SUCCESS_BEFORE_LOGIN]: (state, { payload }) => 
		{
			return { 
			...state,
			bannerContentLoading: false
		}
	}
}, initialState)
