import React from 'react'
import { Container} from 'react-bootstrap'

export const PurchaseModal = ({
    onClickNo,
    onClickYes,
    disable,
    onClose,
    modalStyle,
    showModal,
    modalHeaderText,
    handleChange,
    buttonText,
    isForm,
    style,
    data,
    touched, error, warning, districts,
    loading,
    value,
    heading
}) => {
    return <div className={`modal fade ${showModal ? "show" : ""}`} id="exampleModal" tabIndex="-1" role="dialog" style={modalStyle}>
        <div className=".modal-dialog-centered" role="document">
            <div className="modal-content background-gradient-notifymodal">
                <div className="modal-header-one">
                    <h5 className="modal-title" id="exampleModalLabel">{modalHeaderText}</h5>
                    <button type="button" className="close" data-dismiss="modal" onClick={onClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div style={{ marginTop: 40 }}>
                        <Container style={{ color: "#d0d0d0" }}>                            
                            <h6>{heading}</h6><br />
                            <div className="row">
                                <label className="col-sm-12 col-md-5 form-label" required>Parental PIN</label>
                                <div className="col-sm-12 col-md-7">
                                    <input type="password" value={value ? value : ""} id="password" name="password" onChange={handleChange} />
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
                <div className="modal-footer">
                    <> <button type="button" className="btn submit-button" style={{ float: "right", width: "90px" }} onClick={onClickYes} disabled={disable}>Yes</button>
                        <button type="button" className="btn submit-button" style={{ float: "right", width: "90px" }} onClick={onClose}>No</button></>
                </div>
            </div>
        </div>
    </div>
}
