import reducers from "./reducers";

import svodSelectors from "./selectors";
import svodService from "./service";
import svodTypes from "./types";
import svodActions from "./actions";

export {
    svodSelectors,
    svodService,
    svodActions,
    svodTypes
};

export default reducers;