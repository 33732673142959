export default {
    GET_DEVICES: "profile/GET_DEVICES",
    GET_DEVICES_SUCCESS: "profile/GET_DEVICES_SUCCESS",
    GET_DEVICES_FAILED: "profile/GET_DEVICES_FAILED",

    REMOVE_DEVICE: "profile/REMOVE_DEVICE",
    REMOVE_DEVICE_SUCCESS: "profile/REMOVE_DEVICE_SUCCESS",
    REMOVE_DEVICE_FAILED: "profile/REMOVE_DEVICE_FAILED",

    MY_SVOD: "profile/MY_SVOD",
    MY_SVOD_SUCCESS: "profile/MY_SVOD_SUCCESS",
    MY_SVOD_FAIL: "profile/MY_SVOD_FAIL",

    MY_VOD: "profile/MY_VOD",
    MY_VOD_SUCCESS: "profile/MY_VOD_SUCCESS",
    MY_VOD_FAIL: "profile/MY_VOD_FAIL",

    MY_SCREEN: "profile/MY_SCREEN",
    MY_SCREEN_SUCCESS: "profile/MY_SCREEN_SUCCESS",
    MY_SCREEN_FAIL: "profile/MY_SCREEN_FAIL",

    GET_PAYMENTS: "profile/GET_PAYMENTS",
    GET_PAYMENTS_SUCCESS: "profile/GET_PAYMENTS_SUCCESS",
    GET_PAYMENTS_FAIL: "profile/GET_PAYMENTS_FAIL",

    MY_PROMOTIONAL_PACKAGE: "profile/MY_PROMOTIONAL_PACKAGE",
    MY_PROMOTIONAL_PACKAGE_SUCCESS: "profile/MY_PROMOTIONAL_PACKAGE_SUCCESS",
    MY_PROMOTIONAL_PACKAGE_FAILED: "profile/MY_PROMOTIONAL_PACKAGE_FAILED"

}
