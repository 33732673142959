import React from 'react';
import { Row, Col } from "react-bootstrap";
import SuspendedProductsList from '../SuspendedProductsList/SuspendedProductsList';
import SuspendedPackage from '../SuspendedProductsList/SuspendedPackage';


function ReactivationProductView({ name, products, loading, packageStatus }) {
    return (
        <>
            <Row className="suspended-product-background margin-top-10">
                <Col md={4} className="vertical-center">
                    <p className="margin-none" style={{ color: "#6d6969" }}>{name}</p>
                </Col>
                <Col md={8} className="vertical-center">
                    {name === "Package Name " ? <SuspendedPackage products={products} loading={loading} packageStatus={packageStatus} /> : <SuspendedProductsList products={products} loading={loading} />}
                </Col>
            </Row>
        </>
    )
}

export default ReactivationProductView
