import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import ProgressiveImage from 'react-progressive-image';
import { BeforeLogInPackages } from '../DummyComponent/DummyComponent'

export default class FeaturedPackage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultScreen: null
    }
  }

  componentDidMount() {
  }

  render() {
    const {
      name,
      isLoggedIn,
      onClick,
      id,
      description,
      price,
      imagePath,
      purchasedScreens,
      isDummy,
      purchaseType
    } = this.props;
    const styles = {
      containerBox: {
        display: "grid",
        // backgroundColor: "#ffffff4d",
        borderRadius: 5,
        overflow: "hidden",
        marginBottom: 20,
        // paddingRight: 0,
        // paddingLeft: 0,
        cursor: "pointer"
      },
      dataPackage: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        cursor: "pointer"
      },
      screens: {
        backgroundColor: "rgba(31, 64, 84, 0.74)",
        padding: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        color: "#ffff",
        cursor: "pointer"
      },
      plus: {
        display: "flex",
        justifyContent: "center",
        padding: 12,
        alignItems: "center",
        backgroundColor: "#fff",
        borderRadius: 5,
        margin: 5,
        marginLeft: 10,
        cursor: "pointer",
        marginTop: 14,
      }
    };

    var purchasebleScreen = []
    purchasedScreens && purchasedScreens.map((product, i) => {
      if (product.productTypeTypeName === "screens") {
        purchasebleScreen.push(product)
      }
      return null
    })
    var isPurchasedNullScreens = []
    purchasebleScreen && purchasebleScreen.map((screen, i) => {
      if (screen.isPurchased == null){
        isPurchasedNullScreens.push(screen)
      }
      return null 
    })

    if (isDummy) {
      return (
        <BeforeLogInPackages />
      )

    } else {
      return (
        <>
          <style type="text/css">
            {`
                          .outside-package{
                            position:relative;
                            margin-bottom: -20px;
                          }
                              
                          .inside-package{
                              position:absolute;
                              height: 0;
                              bottom:0;
                              width:100%;
                              transition: .5s ease;
                              background-color: rgba(29, 29, 29, 0.9);
                              overflow:hidden;
                              display:flex;
                              flex-direction:column;
                              justify-content:center;
                              text-align:center;
                              align-self:center;
                              z-index:100;
                             
                          }
                          .outside-package:hover .inside-package{
                              height: 100%;
                              display:flex;
                              flex-direction:column;
                              justify-content:center;
                              text-align:center;
                              align-self:center;
                          }
  
                          .outside-content{
                              padding:20px !important;
                              height:100%
                          }
                          .inside-package {
                              color:#ffff;
                              z-index:200;
                          }
  
                          @media only screen and (max-width: 1200px) {
                            .responsivePackage{
                              margin-left:0;
                              justify-content: end;
                              margin-top: 10px;
                            }
  
                          }
                      `}
          </style>
          <div style={{ display: isLoggedIn ? "content" : "flex" }}>
            <Row className="justify-content-center">
              <div
                id={id}
                style={styles.containerBox}
                className={isLoggedIn ? "col-sm-6 col-12" : "col-sm-10 col-12"}
              >
                <div id={id} className="outside-package">
                  <div className="inside-package" id={id} onClick={onClick}>
                    <h4 id={id} >{name}</h4>
                    {isDummy ? null : purchaseType &&<div className="package-time-period"><span id={id} >{purchaseType}</span></div>}
                    <br />
                    {isDummy ? null : <h3 id={id} >Rs. {price}</h3>}
                  </div>
                  <Row>
                    <Col>
                      <div>
                        <ProgressiveImage
                          src={isDummy ? require("../../assets/images/package-image.jpg") : `/${imagePath}`}
                          placeholder={require("../../assets/images/package-image.jpg")}
                        >
                          {(src, loading) => (
                            <img style={{
                              width: '100%',
                              marginBottom: "20px",
                            }}
                              src={src}
                              alt=""
                            />
                          )}
                        </ProgressiveImage>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              {isLoggedIn && (
                <div className="col-sm-6 col-12" style={{ color: "#e8e8e8", textAlign: "justify" }}>
                  <span>{description}</span>
                </div>
              )}
            </Row>
            {isLoggedIn && (
              <Row className="responsivePackage">
                <div className="col-sm-6 col-9" style={styles.screens}>
                  <img
                    src={require("../../assets/images/platforms.png")}
                    style={{ width: 120 }}
                    alt=''
                  />
                  <span className="numberOfDevices">Number of Devices</span>
                  <span
                    style={{ border: "1.5px solid #ffff", height: "100%" }}
                  ></span>
                  <span style={{ fontSize: 32, fontWeight: "700",padding: "10px" }}>
                    {this.props.dataArr}
                  </span>
                </div>
                <Link
                  to={"/screens"}
                  style={{ textDecoration: "none" }}
                >
                  <div style={styles.plus}>
                    <i
                      className="fas fa-plus"
                      style={{ color: "#F7921A", fontSize: 34 }}
                    ></i>
                  </div>
                </Link>
              </Row>
            )}
          </div>
        </>
      );
    }
  }
}
