import React from 'react';
import { Row, Col } from "react-bootstrap";
import ProductStatus from "../ProductStatus/ProductStatus";
import { TRANSACTIONAL } from "./../../util/Constants"

function SuspendedProductsList({ products, loading }) {
    return (
        <React.Fragment>
            <Row className="overflow-product-column-scroll scrollbar-info">
                {loading && <><Col md={5} className="vertical-center" />
                    <Col md={2} className="vertical-center" />
                    <Col md={16} className="vertical-center">
                        <ProductStatus status="loading" />
                    </Col></>}
                {products && products.map((item, index) => {
                    return <>
                        <Col md={5} className="vertical-center">
                            <p className="margin-none">{item.name}</p>
                        </Col>
                        <Col md={2} className="vertical-center">
                            <ProductStatus status={item.billingEndDate === undefined ? "loading" : item.purchaseStatus !== "NOT_PURCHASED" ? "success" : "failed"} />

                        </Col>
                        {item.billingEndDate !== null && item.purchaseType === TRANSACTIONAL && <Col md={16} className="vertical-center">
                            <p className="margin-none">(Expires on {item.billingEndDate})</p>
                        </Col>}
                    </>

                })
                }
            </Row>
        </React.Fragment>
    )
}

export default SuspendedProductsList
