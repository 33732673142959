export default {
    ADD_CONNECTION: " subscription/ ADD_CONNECTION",
    ADD_CONNECTION_SUCCESS: " subscription/ ADD_CONNECTION_SUCCESS",
    ADD_CONNECTION_FAILED: " subscription/ ADD_CONNECTION_FAILED",
    FETCH_PRODUCTS: "subscription/FETCH_PRODUCTS",
    FETCH_PRODUCTS_SUCCESS: "subscription/FETCH_PRODUCTS_SUCCESS",
    FETCH_PRODUCTS_FAILED: "subscription/FETCH_PRODUCTS_FAILED",
    FETCH_SECONDARY_PRODUCTS: "subscription/FETCH_SECONDARY_PRODUCTS",
    FETCH_SECONDARY_PRODUCTS_SUCCESS: "subscription/FETCH_SECONDARY_PRODUCTS_SUCCESS",
    FETCH_SECONDARY_PRODUCTS_FAILED: "subscription/FETCH_SECONDARY_PRODUCTS_FAILED",
    GET_ALLOWED_SECONDARY_PACKAGES: "subscription/GET_ALLOWED_SECONDARY_PACKAGES",
    GET_ALLOWED_SECONDARY_PACKAGES_SUCCESS: "subscription/GET_ALLOWED_SECONDARY_PACKAGES_SUCCESS",
    GET_ALLOWED_SECONDARY_PACKAGES_FAILED: "subscription/GET_ALLOWED_SECONDARY_PACKAGES_FAILED",
    GET_PRODUCT_TYPES: "subscription/ GET_PRODUCT_TYPES",
    GET_PRODUCT_TYPES_SUCCESS: "subscription/ GET_PRODUCT_TYPES_SUCCESS",
    GET_PRODUCT_TYPES_FAILED: "subscription/ GET_PRODUCT_TYPES_FAILED",
    GET_PRODUCTS: "subscription/ GET_PRODUCTS",
    GET_PRODUCTS_SUCCESS: "subscription/ GET_PRODUCTS_SUCCESS",
    GET_PRODUCTS_FAILED: "subscription/ GET_PRODUCTS_FAILED",
    GET_PRODUCTS_LOADING: "subscription/ GET_PRODUCTS_LOADING",
    GET_DATA_BUNDLE: "subscription/GET_DATA_BUNDLE",
    GET_DATA_BUNDLE_SUCCESS: "subscription/GET_DATA_BUNDLE_SUCCESS",
    GET_DATA_BUNDLE_FAILED: "subscription/GET_DATA_BUNDLE_FAILED",
    RETRY_PACKAGE: "subscription/RETRY_PACKAGE",
    RETRY_PACKAGE_SUCCESS: "subscription/RETRY_PACKAGE_SUCCESS",
    RETRY_PACKAGE_FAILED: "subscription/RETRY_PACKAGE_FAILED",
    CLEAR_PRODUCTS: "subscription/CLEAR_PRODUCTS",
    CREATE_CONSENT: "subscription/CREATE_CONSENT",
    CREATE_CONSENT_SUCCESS: "subscription/CREATE_CONSENT_SUCCESS",
    CREATE_CONSENT_FAILED: "subscription/CREATE_CONSENT_FAILED",
    //Prepaid user registraion

    PREPAID_PACKAGE_PURCHASED_STATUS: "subscription/PREPAID_PACKAGE_PURCHASED_STATUS",
    PREPAID_PACKAGE_PURCHASED_STATUS_SUCCESS: "subscription/PREPAID_PACKAGE_PURCHASED_STATUS_SUCCESS",
    PREPAID_PACKAGE_PURCHASED_STATUS_FAILED: "subscription/PREPAID_PACKAGE_PURCHASED_STATUS_FAILED",
    PREPAID_PACKAGE_PURCHASED_STATUS_LOADING: "subscription/PREPAID_PACKAGE_PURCHASED_STATUS_LOADING",

    //save mobitel payment details
    SAVE_PAYMENT_DETAILS: "subscription/SAVE_PAYMENT_DETAILS",

    FILTER_PACKAGES: "subscription/FILTER_PACKAGES",

    VALIDATE_CONSENT: "subscription/VALIDATE_CONSENT",
    VALIDATE_CONSENT_SUCCESS: "subscription/VALIDATE_CONSENT_SUCCESS",
    VALIDATE_CONSENT_FAILED: "subscription/VALIDATE_CONSENT_FAILED",

    CHECK_PACKAGE_PROVISIONING: "subscription/CHECK_PACKAGE_PROVISIONING",
    CHECK_PACKAGE_PROVISIONING_SUCCESS: "subscription/CHECK_PACKAGE_PROVISIONING_SUCCESS",
    CHECK_PACKAGE_PROVISIONING_FAILED: "subscription/CHECK_PACKAGE_PROVISIONING_FAILED",

    GET_DATA_BUNDLE_PURCHASE_STATUS: "subscription/GET_DATA_BUNDLE_PURCHASE_STATUS",
    GET_DATA_BUNDLE_PURCHASE_STATUS_SUCCESS: "subscription/GET_DATA_BUNDLE_PURCHASE_STATUS_SUCCESS",
    GET_DATA_BUNDLE_PURCHASE_STATUS_FAILED: "subscription/GET_DATA_BUNDLE_PURCHASE_STATUS_FAILED",

     

}