const action_header = "allPromotions/"

export default {
    GET_BANNER: action_header + "GET_BANNER",
    GET_BANNER_SUCCESS: action_header + "GET_BANNER_SUCCESS",
    GET_BANNER_FAILED: action_header + "GET_BANNER_FAILED",

    GET_BANNER_TYPE_PRODUCTS: action_header + "GET_BANNER_TYPE_PRODUCTS",
    GET_BANNER_TYPE_PRODUCTS_SUCCESS: action_header + "GET_BANNER_TYPE_PRODUCTS_SUCCESS",
    GET_BANNER_TYPE_PRODUCTS_FAILED: action_header + "GET_BANNER_TYPE_PRODUCTS_FAILED",

    GET_BANNER_CONTENT_BEFORE_LOGIN_SUCCESS: action_header + "GET_BANNER_CONTENT_BEFORE_LOGIN_SUCCESS",

    GET_PROMOTIONAL_PACKAGES: action_header + "GET_PROMOTIONAL_PACKAGES",
    GET_PROMOTIONAL_PACKAGES_SUCCESS: action_header + "GET_PROMOTIONAL_PACKAGES_SUCCESS",
    GET_PROMOTIONAL_PACKAGES_FAILED: action_header + "GET_PROMOTIONAL_PACKAGES_FAILED",
    
    RESET_BANNER_STATE: action_header + "RESET_BANNER_STATE",

    VERITFY_PACKAGE_PURCHASE: action_header + "VERITFY_PACKAGE_PURCHASE",

    GET_PROMOTIONAL_PACKAGE_PRODUCTS : action_header + "GET_PROMOTIONAL_PACKAGE_PRODUCTS",
    GET_PROMOTIONAL_PACKAGE_PRODUCTS_SUCCESS : action_header + "GET_PROMOTIONAL_PACKAGE_PRODUCTS_SUCCESS",
    GET_PROMOTIONAL_PACKAGE_PRODUCTS_FAILED : action_header + "GET_PROMOTIONAL_PACKAGE_PRODUCTS_FAILED"
}