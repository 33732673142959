import React from 'react'
import { sortVodsForMovies, isDiscounted } from '../../util/ArrayValidator';
import Card from '../../components/CardComponent/Card'
import CardOptions from '../../components/CardOptions/CardOptions';
import Features from '../../components/Features/Features';
export const CustomVodTab = ({
    viewPackage,
    unionArray,
    selectedCategory,
    product,
    selectedCategoryName,
    purchasableProducts,
    onClick,
    loggedIn,
    beforeLogin,
    vodIndex
}) => {
    let displayProduct = product && product.slice(0, vodIndex)
    return <div style={{ marginTop: '15px' }}>
        <div className={viewPackage && "terms-contents scrollbar-info"}>
            <><div>
                {displayProduct && sortVodsForMovies(displayProduct).map((item, index) => {
                    if ((selectedCategoryName === item.productTypeName)) {
                        return <div className="display-inline-block"><Card
                            key={index}
                            id={item.id}
                            isLoggedIn={loggedIn}
                            name={item.name}
                            imageURL={item.imageURL}
                            price={item && item.purchaseOption && item.purchaseOption.defaultPrice}
                            onClick={onClick}
                            showAddNew={false}
                            svod={item.daysRemainingToExpire}
                            hover={true}
                            showPrice={true}
                            type="onDemand"
                            isDummy={false}
                            hasDiscount={isDiscounted(item)}
                            isBundled={item.isBundled}
                        >
                            <CardOptions isBundled={item.isBundled} isLoggedIn={loggedIn} />
                            <Features feature={item.feature} />
                        </Card></div>
                    }
                    return null
                })
                }
            </div></>)
        </div>
    </div>
}