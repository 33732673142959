import React from "react";
import Card from '../../components/CardComponent/Card';
import './SvodPackage.scss';
import { getLoggedInStatus } from '../../util/ArrayValidator';
export default function SvodPackage({ name, description, id, onClick, price, isBundled, imageURL, isPurchased, showDate, purchaseOption, isDummy, showAddNew, hasDiscount, discount }) {
  return (
    <>
      <div className="container-box">
        <Card
          id={id}
          hover={true}
          showAddNew={showAddNew}
          showPrice={showDate === false ? false : true}          
          name={name}
          imageURL={imageURL}
          price={price}
          onClick={onClick}          
          isLoggedIn={getLoggedInStatus()}
          isPurchased={isPurchased}
          showPurchaseIcon={true}
          type="svod"
          isBundled={isBundled}
          hasDiscount={hasDiscount}
          discount={discount}
      ></Card>
      </div>
    </>
  );
}
