import reducers from "./reducers";

import profileSelectors from "./selectors";
import profileService from "./service";
import profileTypes from "./types";
import profileActions from "./actions";

export {
    profileSelectors,
    profileService,
    profileActions,
    profileTypes
};

export default reducers;