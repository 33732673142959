import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { Container } from 'react-bootstrap';
import { CustomCarousel } from '../../components/carousel/CustomCarousel';
import { allProductsActions, allProductsSelectors } from './ducks'
import { getIndex, validArray, getServiceTypeName } from "../../util/ArrayValidator"
import "./styles/AllPackagePage.scss"
import { PackageDetails } from '../../components/Package/PackageDetails'
import { DataBundle } from '../../components/Package/DataBundle'
import { DeviceCount } from '../../components/Package/DeviceCount'
import { Pricing } from '../../components/Package/Pricing'
import { AdditionalDevicesDropdown } from '../../components/Package/AdditionalDevicesDropdown'
import constants from "../../util/Helper"
import { productSorting, purchasableProductSorting, getBundleScreen, getpurchasedScreen, filterScreen, isActiveScreenProduct, filterVod } from './functions/PackagePageFuctions'
import _ from "lodash";
import { CustomTabHeader } from '../../components/customTabs/CustomTabHeader'
import { CustomTabBody } from '../../components/customTabs/CustomTabBody'
import { CustomTabBodyContent } from '../../components/customTabs/CustomTabBodyContent'
import history from '../../_helpers/history'
import { getLoggedInStatus, getConnectionId, getServiceType, loadAfter } from '../../util/ArrayValidator';
import DummyAllChannelPage from '../../components/DummyComponent/DummyAllChannelPage';
import DummyPackageCarousel from '../../components/DummyComponent/DummyPackageCarousel';
import { CustomVodTab } from "../../components/customTabs/CustomVodTab"
import { CustomSvodTab } from "../../components/customTabs/CustomSvodTab"
import { PackageFilter } from '../../components/customTabs/PackageFilter'
import { MOBITEL_SERVICE_TYPE, PREPAID_SERVICE_TYPE, PRODUCT_COUNT, INCREASE_PRODUCT_COUNT_TIMEOUT } from "../../util/Constants";
class AllPackagePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedImages: [],
            selectedIndex: 0,
            additionalPrice: 0,
            screenId: 0,
            selectedCategory: undefined,
            isLoggedIn: undefined,
            selectedTabIndex: "0",
            initialLoading: true,
            distinctCall: true, 
            vodIndex : PRODUCT_COUNT
        }
    }

    componentDidMount() {
        let hash = this.props.location.hash
        let tabActiveKeys = hash ? hash : "#0"
        var ret = tabActiveKeys.replace('#', '');
        var selected = [];
        if (selected[ret] !== -1) selected.push(ret);
        this.setState({
            selectedImages: selected,
            selectedIndex: ret,
            allProductsLoading: true
        });
        let loggedIn = getLoggedInStatus()
        let connId = getConnectionId()
        this.setState({
            isLoggedIn: loggedIn
        })
        this.props.secondaryAllowedProduct===undefined && this.props.getAllowedPackageIds(connId)
        window.addEventListener('scroll', this.scrollEventListner,false)
    }
    componentDidUpdate() {

        let loggedIn = !!this.state.isLoggedIn
        if (loggedIn) {
            this.fetchDistinctTypesOfPrimaryPackages()
        } else {
            this.fetchDistinctTypesOfSecondaryPackages()
        }

    }

    componentWillUnmount() {
        let loggedIn = this.state.isLoggedIn
        window.removeEventListener('scroll', this.scrollEventListner, false)
        if (!loggedIn && this.state.selectedIndex !=="0") {
            this.props.resetPackages()
            
        } else {
            if(!loggedIn){
                if(loadAfter(localStorage.packageLoadedAt)){
                    this.props.resetPackages()
                }
            }else{
                this.props.resetPackages() 
            }
            
        }

    }
    fetchDistinctTypesOfSecondaryPackages() {
        let loggedIn = this.state.isLoggedIn
        let allProductsState = getIndex(this.props.allPackages, this.state.selectedIndex)
        //******************* distinct-types-of-product ************//
        if (!loggedIn && allProductsState && this.state.distinctCall
            && this.props.distinctProductsLoading && this.props.productTypes === undefined
        ) {
            let packages = getIndex(allProductsState.product, 0)
            this.props.productTypes === undefined && this.props.getProductType(packages.id)
            this.props.getDistinctProducts({
                "productId": packages.id,
                "productTypeIds": [packages.productTypeId]
            })
            this.setState({ distinctCall: false })
        }
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.productTypes && nextProps.productTypes !== prevState.productTypes) {
            return ({
                productTypes: nextProps.productTypes
            })
        }
    }

    fetchDistinctTypesOfPrimaryPackages() {
        let { connectionContent, secondaryAllowedProduct } = this.props
        let loggedIn = this.state.isLoggedIn
        let serviceTypeName = getServiceTypeName(connectionContent && connectionContent.serviceType)
        //******************* fetch distinct types of primary packages ************//
        let allProductsState = getIndex(this.props.allPackages, this.state.selectedIndex)
        if (loggedIn && allProductsState) {
            if (this.state.distinctCall && this.props.distinctProductsLoading && this.props.productTypes === undefined) {
                let packageId = allProductsState.id
                this.props.getProductType(packageId)
                this.props.getDistinctProducts({
                    "productId": packageId,
                    "productTypeIds": [allProductsState.productTypeId],
                    connectionType: serviceTypeName
                })
                this.setState({ distinctCall: false })
            }
        }
        //Check for purchase additional screen proce on initial loading
        if (loggedIn) {
            let secondaryProductProps = getIndex(secondaryAllowedProduct, 0)
            let secondaryPurchasableArray = validArray(secondaryProductProps.purchasableProductList)
            if (this.state.initialLoading && secondaryProductProps && secondaryPurchasableArray) {
                let index = secondaryPurchasableArray.findIndex(x => x.isPurchased && x.productTypeName === constants.ScreenProductTypeName);
                this.setState({
                    initialLoading: false,
                    additionalPrice:  index===-1 ? 0 : secondaryPurchasableArray[index].discountOptionGroup && secondaryPurchasableArray[index].discountOptionGroup.purchasePrice,
                    screenId: index===-1 ? 0: secondaryPurchasableArray[index].id
                })
            }
        }



    }

    handleChange = (e) => {
        let index = e.target.selectedIndex
        let baseProductsProps = validArray(this.props.distinctProducts.purchasableProductList)
        if (e.target[index].value > 0) {
            let price = 0
            baseProductsProps.map(i => {
                if (i.productTypeName === constants.ScreenProductTypeName && i.id === e.target[index].id) {
                    price = i.discountOptionGroup && i.discountOptionGroup.purchasePrice
                }
                return null
            })
            this.setState({
                additionalPrice: price,
                screenId: e.target[index].id
            })
        }
        else {
            this.setState({
                additionalPrice: 0,
                screenId: 0
            })
        }
    }

    handleChangeCustomTab = (e) => {
        this.setState({
            selectedCategory: e.target.value
        })
    }

    onClickChannel = (e, isChannelBundle) => {
        this.setState({ show: true, selectedImage: e });
        if (!this.props.userRegiter) {
            if(isChannelBundle){
                history.push(`/channel-bundle/${e}`)
            }else{
                history.push(`/products/${e}`)
            }  
        }
    }

    onClick = (e) => {
        let allProductsState = getIndex(this.props.allPackages, this.state.selectedIndex)
        let validDataArray = getIndex(this.props.allPackages, e.target.id)
        let loggedIn = this.state.isLoggedIn
        if ((loggedIn && this.props.packageID !== allProductsState.id) || (!loggedIn && this.props.packageID !== validDataArray.product[0].id)) {
            this.setState({
                selectedIndex: e.target.id,
                additionalPrice: 0,
                screenId: undefined,
                selectedCategory: undefined,
                selectedTabIndex: "0",
                vodIndex : PRODUCT_COUNT
            })
            var selected = [];
            if (selected[e.target.id] !== -1) selected.push(e.target.id);
            this.setState({ selectedImages: selected });
            var productTypeIds = []
            if (loggedIn) {
                productTypeIds.push(allProductsState.productTypeId)
                this.props.getProductType(this.props.allPackages[e.target.id].id)
                this.props.getDistinctProducts({
                    "productId": this.props.allPackages[e.target.id].id,
                    "productTypeIds": productTypeIds
                })
                // products of base packaages
                this.props.getDistinctProductsLoading()
            }
            else {
                let newProductIds = getIndex(allProductsState.product, 0)
                let primaryId = getIndex(validDataArray.product, 0)

                productTypeIds.push(newProductIds.productTypeId)
                this.props.getProductType(primaryId.id)
                this.props.getDistinctProducts({
                    "productId": primaryId.id,
                    "productTypeIds": productTypeIds
                })
                // products of base packaages
                this.props.getDistinctProductsLoading()
            }
        }
    }
    onSelect = (e) => {
        this.setState({
            selectedTabIndex: e
        })

    }
    onClickSvod = (e) => {
        history.push(`/svod/${e.target.id}`)
    };

    onSelectFilters = (e) => {
        this.props.filterPackages(e)
        let target = { target: { id: "0" } }
        this.onClick(target)

    }
    //event handler for window scroll to load more products
    scrollEventListner = () =>{
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            const {productTypes, distinctProducts } = this.props
            const {selectedTabIndex, vodIndex} = this.state
            let selectedTabIndexTypeName = productTypes && validArray(productTypes) && productTypes[parseInt(selectedTabIndex)].typeName
            let product = distinctProducts && distinctProducts.productsList
            let vods = filterVod(product).reverse();
            if(selectedTabIndexTypeName === "vod" && vodIndex<= vods.length ){
                setTimeout(()=> {
                        this.loadMore()
                    },
                    INCREASE_PRODUCT_COUNT_TIMEOUT
                );
               
            }
         }
    }

    //Handle product count on scroll
    loadMore =()=>{
        const {distinctProducts} = this.props;
        let product = distinctProducts && distinctProducts.productsList
        let vods = filterVod(product).reverse();
        if(vods && vods.length>= this.state.vodIndex){
            this.setState({
                vodIndex: this.state.vodIndex + PRODUCT_COUNT
            })
        }
    }

    render() {
        const { allPackages, allPackagesLoading, distinctProducts, distinctProductsLoading, secondaryAllowedProduct, filteredPackages } = this.props
        const { selectedImages, selectedIndex, additionalPrice, screenId, selectedCategory, selectedTabIndex, productTypes } = this.state
        let loggedIn = getLoggedInStatus()
        let bundleScreen = getBundleScreen(distinctProducts)
        let purchasedScreen = getpurchasedScreen(distinctProducts)
        let selectedTabIndexTypeName = productTypes && validArray(productTypes) && productTypes[parseInt(selectedTabIndex)].typeName
        let product = distinctProducts && distinctProducts.productsList
        let purchasableProducts = distinctProducts && distinctProducts.purchasableProductList
        let channelArray = productSorting(product, selectedTabIndexTypeName)
        let purchasableChannelArray = purchasableProductSorting(purchasableProducts)

        let unionArray = _.unionBy(channelArray, purchasableChannelArray, "id");
        let productPackageCatalog

        // let a = productTypes && productTypes.map(function (e) { return e.typeName; }).indexOf(constants.channelProductTypeName);
        // let defaultActiveKey = a == 1 || a == -1 ? 0 : 1

        let secondaryProductProps = getIndex(secondaryAllowedProduct, 0)
        let allProductState = getIndex(allPackages, selectedIndex)
        let isbasePurchasableProductListArray = validArray(distinctProducts && distinctProducts.purchasableProductList)
        let isProductArray = validArray(distinctProducts && distinctProducts.productsList)
        let secondaryPurchasableArray = validArray(secondaryProductProps.purchasableProductList)
        let screenProduct = loggedIn ? secondaryProductProps && allProductState && allProductState.id === secondaryProductProps.id ? secondaryPurchasableArray : isbasePurchasableProductListArray : isbasePurchasableProductListArray
        let deviceOptions = filterScreen(screenProduct)
        let serviceType = getServiceType();

        if (allPackagesLoading || distinctProductsLoading) {
            // if (distinctProductsLoading) {
            productPackageCatalog = <>
                <DummyAllChannelPage />
            </>
            // } 
        }
        else {
            if (!!loggedIn && secondaryProductProps && allProductState) {
                let purchasedPackage = allProductState.id === secondaryProductProps.id && secondaryProductProps
                let allowedSecondaryPackages = allProductState.id !== secondaryProductProps.id && isProductArray
                let productPurchased = (purchasedPackage && purchasedPackage.productList) || allowedSecondaryPackages
                let purchasablePurchased = secondaryPurchasableArray || (!purchasedPackage && isbasePurchasableProductListArray)
                let vods = filterVod(productPurchased).reverse();
                let purchasableVods = filterVod(purchasablePurchased).reverse();

                productPackageCatalog = <>
                    <CustomTabHeader tabs={productTypes}
                        defaultActiveKey={"0"}
                        onSelect={this.onSelect}
                    >
                        {selectedTabIndexTypeName === "channel" && <><CustomTabBody handleChange={this.handleChangeCustomTab} unionArray={unionArray} name="select" id={1} />
                            <CustomTabBodyContent
                                unionArray={unionArray}
                                product={productPurchased}
                                purchasableProducts={purchasablePurchased}
                                selectedCategory={selectedCategory}
                                selectedCategoryName="Channels"
                                onClick={this.onClickChannel}
                                loggedIn={loggedIn}
                            /></>}
                        {selectedTabIndexTypeName === "vod" && <CustomVodTab
                            unionArray={unionArray}
                            product={vods}
                            purchasableProducts={purchasableVods}
                            selectedCategory={selectedCategory}
                            selectedCategoryName="Video on Demand"
                            onClick={this.onClickChannel}
                            loggedIn={loggedIn}
                            vodIndex={this.state.vodIndex}
                        />}
                        {selectedTabIndexTypeName === "svod" && <CustomSvodTab
                            unionArray={unionArray}
                            product={productPurchased}
                            purchasableProducts={purchasablePurchased}
                            selectedCategory={selectedCategory}
                            selectedCategoryName="SVOD"
                            onClick={this.onClickSvod}
                            loggedIn={loggedIn}
                        />}
                    </CustomTabHeader></>
            } else {
                let vods = filterVod(product).reverse();
                let purchasableVods = filterVod(purchasableProducts).reverse();
                productPackageCatalog =
                    <><CustomTabHeader tabs={productTypes} defaultActiveKey={"0"} onSelect={this.onSelect}>
                        {selectedTabIndexTypeName === "channel" && <> <CustomTabBody handleChange={this.handleChangeCustomTab} unionArray={unionArray} name="select" id={1} />
                            <CustomTabBodyContent
                                unionArray={unionArray}
                                product={product}
                                purchasableProducts={purchasableProducts}
                                selectedCategory={selectedCategory}
                                selectedCategoryName="Channels"
                                onClick={this.onClickChannel}
                                loggedIn={loggedIn}
                                beforeLogin={true}
                            /></>}
                        {selectedTabIndexTypeName === "vod" && <CustomVodTab
                            unionArray={unionArray}
                            product={vods}
                            purchasableProducts={purchasableVods}
                            selectedCategory={selectedCategory}
                            selectedCategoryName="Video on Demand"
                            beforeLogin={true}
                            onClick={this.onClickChannel}
                            vodIndex={this.state.vodIndex}
                        />}
                        {selectedTabIndexTypeName === "svod" && <CustomSvodTab
                            unionArray={unionArray}
                            product={product}
                            purchasableProducts={purchasableProducts}
                            selectedCategory={selectedCategory}
                            selectedCategoryName="SVOD"
                            beforeLogin={true}
                            onClick={this.onClickSvod}
                        />}
                    </CustomTabHeader></>
            }
        }

        let deviceCount = isProductArray && isProductArray.map(screenProduct => {
            if (screenProduct.productTypeName === constants.ScreenProductTypeName && screenProduct.status === "PRODUCT_STATUS_ACTIVE") {
                return (
                    <DeviceCount allScreens={parseInt(bundleScreen) + parseInt(purchasedScreen)} />
                )
            }
            return null
        })

        let showPrice
        let purchasedScreenPrice = loggedIn ? secondaryProductProps && secondaryPurchasableArray && allProductState && allProductState.id === secondaryProductProps.id &&
            secondaryPurchasableArray.map(screen => {
                if (screen.productTypeName === constants.ScreenProductTypeName && screen.id === screenId && getServiceTypeName(localStorage.getItem("serviceType")) !== "prepaid") {
                    showPrice = screen.purchaseOption && screen.purchaseOption.defaultPrice
                    return <Pricing price={screen.purchaseOption && screen.purchaseOption.defaultPrice} />
                }
                return null
            }) : isbasePurchasableProductListArray && screenId !== 0 && isbasePurchasableProductListArray && isbasePurchasableProductListArray.map(screen => {
                if (screen.productTypeName === constants.ScreenProductTypeName && screenId !== 0 && screen.id === screenId && getServiceTypeName(localStorage.getItem("serviceType")) !== "prepaid") {
                    showPrice = screen.purchaseOption && screen.purchaseOption.defaultPrice
                    return <Pricing price={screen.purchaseOption && screen.purchaseOption.defaultPrice} />
                }
                return null
            })

        let initialSlideHeight = (1200 < window.innerWidth ? 290 :
            (990 < window.innerWidth ? 240 :
                (900 < window.innerWidth ? 180 :
                    (770 < window.innerWidth ? 300 :
                        (550 < window.innerWidth ? 180 :
                            (440 < window.innerWidth ? 210 :
                                (330 < window.innerWidth ? 220 : 180)))))))
        //check whether are there any screen products available
        let isScreenProduct = isActiveScreenProduct(distinctProducts && distinctProducts.productsList) || (getServiceType() !== PREPAID_SERVICE_TYPE && getServiceType() !== MOBITEL_SERVICE_TYPE && isActiveScreenProduct(distinctProducts && distinctProducts.purchasableProducts))


        return (
            <div>
                <Container>
                    {allPackagesLoading && <DummyPackageCarousel
                        onClick={this.onClick}
                        isLoggedIn={loggedIn}
                        isDummy={true}
                    />}

                    {!allPackagesLoading && <>
                        {filteredPackages && filteredPackages.length === 3 && <><div className="row" style={{ marginLeft: '15px', marginTop: '15px' }}>
                            <div className="col-md-9">
                                <ion-icon name="options" style={{ color: "#c2c2c2" }} ></ion-icon><span style={{ margin: '10px', color: "#c2c2c2" }}>Filter</span>
                            </div>
                        </div>
                            <div className="row" style={{ marginLeft: '15px', marginTop: '10px' }}>
                                <div className="col-md-9">
                                    <PackageFilter onSelect={this.onSelectFilters} />
                                </div>
                            </div></>}
                        <div className="package-carousel-container">
                            <CustomCarousel
                                slidesToShow={(900 < window.innerWidth ? 3 : (550 < window.innerWidth ? 2 : (440 < window.innerWidth ? 2 : 1)))}
                                renderBottomCenterControls={false}
                                heightMode="last"
                                slideWidth="275px"
                                initialSlideHeight={initialSlideHeight}
                                allProducts={Array.isArray(allPackages) && allPackages}
                                informative={true}
                                onClick={this.onClick}
                                featuredPackage={true}
                                selectedImage={selectedImages}
                                slideIndex={selectedImages}
                                isLoggedIn={loggedIn ? allProductState && allProductState.name : allProductState && allProductState.name}
                                viewPackage={false}
                                isDummy={false}
                            /><br />
                        </div>
                        <div className="row">
                            <div className="col-md-9" >
                                <PackageDetails
                                    name={allProductState.name}
                                    description={allProductState.description}
                                    selectedProduct={allProductState.price != null}
                                    price={showPrice ? parseInt(allProductState.price + showPrice) : parseInt(allProductState.price + additionalPrice)}
                                    purchaseOption={allProductState.purchaseOption}
                                    isPrepaid={serviceType}
                                    productDetails={allProductState}
                                    additionalScreenPrice={additionalPrice ? additionalPrice : 0}
                                    products={isProductArray}
                                    additionalDetails={allProductState.additionalDetails && allProductState.additionalDetails.length !== 0 && allProductState.additionalDetails}
                                />
                                {allProductState.name !== "PEOTVGO" && <div className="package-include-header"><p className="package-includes-p">This Package Includes: </p></div>}
                            </div>
                            <div className="col-md-3 package-data-bundle-container" >
                                {!loggedIn &&
                                    <DataBundle loading={!allPackagesLoading} dataQuota={allProductState && allProductState.dataBundle[selectedIndex].dataQuota} />
                                }
                                {!distinctProductsLoading && isScreenProduct && <div className="package-additional-device-container" >
                                    {deviceCount}
                                    {deviceOptions.length !== 0 && <AdditionalDevicesDropdown
                                            purchasableProductList={isbasePurchasableProductListArray}
                                            onChange={this.handleChange} option={deviceOptions}
                                        />
                                    }
                                    {purchasedScreenPrice}
                                    {/* {additionalPrice !== 0 && <Pricing price={additionalPrice} />} */}
                                </div>}
                            </div>
                        </div></>
                    }
                </Container>
                <Container className="package-products-catelog-container">
                    <div className="package-products-catelog" >
                        {productPackageCatalog}
                    </div>
                </Container>
            </div>
        )
    }

}
const mapStateToProps = (state) => {
    return {
        ...allProductsSelectors.getPackageContent(state)
    }
}

export default withRouter(connect(
    mapStateToProps,
    allProductsActions
)(AllPackagePage));