import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { svodSelectors, svodActions } from './ducks';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import history from './../../_helpers/history';
import { ProductModal } from '../../components/controls/ProductModal';
import { getBaseProductsActions } from '../productPage/ducks';
import { PurchaseModal } from './../../components/controls/PurchaseModal'
import { UnsubscribeModal } from './../../components/controls/UnsubscribeModal';
import { CustomButton } from '../../components/controls/CustomButton';
import NotifyModal from './../../components/controls/NotifyModal'
import Card from '../../components/CardComponent/Card';
import DummySvodPage from '../../components/DummyComponent/DummySvodPage';
import { getNestedValue, getLoggedInStatus, purchaseStatusValidation, getServiceType, getConnectionId, getProductPrice, validArray, getBasepackage, getRegion, getUtilizationPeriod } from '../../util/ArrayValidator';
import moment from 'moment';
import { DiscountPrices } from '../../components/DiscountPrices/DiscountPrices'
import { PRODUCT, USER, PREPAID_SERVICE_TYPE, TRANSACTIONAL, PAYMENT_SUCCESS } from '../../util/Constants';
import "./SvodStyles.scss";
import { CustomButtonPlaceholder } from "../../components/DummyComponent/DummyComponent";
import { Payhere } from '../../util/PayhereScripts'
import _ from "lodash";
import { DiscountPriceComponent } from '../../components/DiscountPriceComponent/DiscountPriceComponent';
import { ExpiryDate } from '../../components/ProductPageView/ExpiryDate'
import { ProductProvisioningError } from '../../components/ProductPageView/ProductProvisioningError'

class SvodPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            showModals: false,
            showUnsub: false,
            purchaseOption: undefined,
            password: undefined,
            svod: undefined,
            notifyStatus: false,
            productDetailsPurchase: true
        }
    }
    componentDidMount() {
        let getBasePackageId = getBasepackage()
        let id = this.props.match && this.props.match.params.id
        if (getLoggedInStatus()) {
            // debugger
            if (!!localStorage.conID) {
                let id = this.props.match && this.props.match.params.id;
                this.props.svodActions.getSvod({ productId: id, connectionId: localStorage.conID });
                this.props.svodActions.getBundledProducts({ productId: id, basePackageId: getBasePackageId });
                if (this.props.location && this.props.location.search) {
                    let purchaseOptionId = this.props.location.search.split("=")[1];
                    this.setState({
                        purchaseOption: purchaseOptionId
                    })
                    this.getPriceOnPromotions();
                }
            }
        } else {
            this.props.svodActions.getSvod(id);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (getServiceType() === PREPAID_SERVICE_TYPE && !this.props.paymentLoading && this.state.showPayhere && this.state.notifyStatus && this.props.payhereDetails) {
            // let id = this.props.match && this.props.match.params.id
            const { payhereDetails, purchaseOptions, userDetails } = this.props;

            let payementStatus = Payhere(purchaseOptions, userDetails, payhereDetails.transactionDetails, this.state.purchaseOption)
            window.payhere.onCompleted = (orderId) => {

                console.log("Payment completed. OrderID:" + orderId)
                this.getPurchaseSatus(payhereDetails.transactionDetails.transactionId)
                //Note: validate the payment and show success or failure page to the customer
            };

            // Called when user closes the payment without completing
            window.payhere.onDismissed = () => {
                //Note: Prompt user to pay again or show an error page
                console.log("Payment dismissed");
                this.closeNotify()

            };

            // Called when error happens when initializing payment such as invalid parameters
            window.payhere.onError = (error) => {
                // Note: show an error page
                console.log("Error:" + error);
                let id = this.props.match && this.props.match.params.id
                this.props.svodActions.verifyPurchaseSvod({
                    productId: id,
                    connectionId: localStorage.conID
                })
                // this.getPurchaseSatusDissmissed()
            };

            window.payhere.startPayment(payementStatus);
        }
        //fixed bundled carousel on slider arrows unclickable issue
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 0);
    }

    componentWillUnmount() {
        this.props.svodActions.clearSvod();
    }

    onClick = (e) => {
        history.push(`/svod/${e.target.id}/vods`)
    }

    handleShow = () => {
        this.setState({
            showModal: true, purchaseOption: this.props.svod &&
                this.props.svod.purchaseOption &&
                this.props.svod.purchaseOption.id
        })
    }

    onClose = () => {
        this.setState({ showModal: false, showModals: false, password: "", purchaseOption: "" })
    }

    onClickOk = (e) => {
        this.setState({
            showModal: false,
            showModals: true
        })
    }

    closeNotify = () => {
        this.setState({
            notifyStatus: false,
            purchaseOption: undefined,
            password: undefined,
            showPayhere: false
        })
        this.props.svodActions.clearPurchase();
        // this.props.svodActions.clearSvod();
        // let id = this.props.match && this.props.match.params.id;        
        // this.props.svodActions.getSvod({productId: id, connectionId: this.props.connectionDetails[this.props.selectedConId].connectionId});
    }

    onCloseUnsub = () => {
        this.setState({ showUnsub: false })
    }

    handleShowUnsub = () => {
        this.setState({ showUnsub: true })
    }

    onClickYes = () => {
        this.setState({
            showModal: false,
            showModals: false,
            notifyStatus: true,
            showPayhere: true
        });
        let selectedPurchaseOption = this.state.purchaseOption;
        //extract purchase option object details
        let selectedPO = _.find(this.props.purchaseOptions, function (o) { return o.id === selectedPurchaseOption; });
        let selectedPOGID = getNestedValue(selectedPO, ['purchaseOptionGroupId']);
        let productPurchaseOptionGID = getNestedValue(this.props.svod, ['purchaseOptionGroupId']);
        let defaultPurchaseOptionType = getNestedValue(this.props.svod, ['purchaseOption', 'purchaseType']);

        this.props.svodActions.productSubscribe({
            // connectionId: this.props.connectionDetails && Array.isArray(this.props.connectionDetails) && this.props.connectionDetails[this.props.selectedConId].connectionId,
            connectionId: localStorage.conID,
            pin: this.state["password"],
            productId: this.props.svod && this.props.svod.id,
            productType: this.props.svod && this.props.svod.productTypeId,
            purchaseOption: this.state["purchaseOption"],
            amount: getProductPrice(this.props.svod),
            productPurchaseOptionGID: productPurchaseOptionGID,
            selectedPOGID: selectedPOGID,
            defaultPurchaseOptionType: defaultPurchaseOptionType
        });
        this.setState({
            password: null
        });
    }

    onClickYesUnsub = () => {
        this.setState({
            showUnsub: false,
            notifyStatus: true
        })
        this.props.svodActions.productUnSubscribe({
            // connectionId: this.props.connectionDetails && Array.isArray(this.props.connectionDetails) && this.props.connectionDetails[this.props.selectedConId].connectionId,
            connectionId: localStorage.conID,
            // productId: this.props.svod && this.props.svod.id,
            productId: getNestedValue(this.props.svod, ['id']),
            productType: getNestedValue(this.props.svod, ['productTypeId']),
            // purchaseOption: this.props.svod && this.props.svod.purchaseOption && this.props.svod.purchaseOption.id
            purchaseOption: getNestedValue(this.props.svod, ['purchaseOption', 'id']),
            purchaseId: getNestedValue(this.props.svod, ['purchaseId'])
        })
    }

    onClickNo = () => {
        this.setState({
            showModal: false,
            showModals: false,
            showUnsub: false,
            password: null,
            showPayhere: false
        })
    }

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value })
    }
    handleChangePurchaseOptions = (e) => {
        const { purchaseOptions } = this.props;
        let id = this.props.match && this.props.match.params.id
        this.setState({ [e.target.id]: e.target.value, selectedIndex: e.target.value });
        let selectedPurchaseOptionId = purchaseOptions && purchaseOptions[e.target.value].id;
        this.props.svodActions.getSvodPurchaseOptions({
            productId: id,
            connectionId: getConnectionId(),
            purchaseOptionId: selectedPurchaseOptionId
        })
    }


    static getDerivedStateFromProps(nextProps, state) {
        let newProps = {}
        if (nextProps.svod && nextProps.svod !== null) {
            newProps.svod = nextProps.svod
        }
        if (nextProps.location && nextProps.location.search && state.productDetailsPurchase && (nextProps.svodLoading === false)) {
            if (!nextProps.svod.isBundled) {
                if (getServiceType()) {
                    if (nextProps.svod.purchaseStatus === "NOT_PURCHASED") {
                        newProps.showModal = true
                        newProps.productDetailsPurchase = false
                    }
                } else {
                    if (nextProps.svod.purchaseStatus === "NOT_PURCHASED" || nextProps.svod.purchaseStatus === "PURCHASE_PENDING_REMOVE") {
                        newProps.showModal = true
                        newProps.productDetailsPurchase = false
                    }
                }
            }
        }

        if (nextProps.subscribe !== null || nextProps.unsubscribe !== null) {
            newProps.subscribeStatus = nextProps.subscribe
            newProps.unsubscribeStatus = nextProps.unsubscribe
        }

        return {
            ...newProps
        };

    }


    getPurchaseSatus = (transactionId) => {
        this.setState({ showPayhere: false })
        let id = this.props.match && this.props.match.params.id
        this.props.svodActions.getPrepaidSvodPurchaseStatus({ transactionId: transactionId, productId: id })

    }

    getPriceOnPromotions = () => {
        let id = this.props.match && this.props.match.params.id;
        let selectedPurchaseOptionId = this.props.location.search.split("=")[1];
        this.props.svodActions.getSvodPurchaseOptions({
            productId: id,
            connectionId: getConnectionId(),
            purchaseOptionId: selectedPurchaseOptionId
        })
    }

    render() {
        const { svodLoading, purchaseOptions, purchaseStatus, payhereSvodPurchaseStatus, payhereSvodPurchaseStatusLoading, purchaseOptionsLoading, submitBtnLoading, productPurchaseStatusError, referenceId, bundledProducts } = this.props;
        const { showModal, showModals, showUnsub, svod, selectedIndex } = this.state;
        // var purchaseStatusLoading = svod && svod.isPurchased == null ?
        //     (this.props.purchaseStatus && this.props.purchaseStatus.unSubLoading) :
        //     (this.props.purchaseStatus && this.props.purchaseStatus.purchaseLoading)

        //get the last day of the month and the present year
        let purchaseStatusType = purchaseStatusValidation(svod && svod.purchaseStatus)
        let isPurchased = getNestedValue(svod, ['isPurchased']);
        let svodPurchaseStatus = getNestedValue(svod, ['purchaseStatus'])
        let serviceType = getServiceType();
        let purchaseType = getNestedValue(svod && svod.purchaseOption, ["purchaseType"])
        let regionType = getRegion(svod && svod.regions)
        let bundledStatus = getNestedValue(svod, ['isBundled']);
        let defaultPrice = parseFloat(getNestedValue(svod, ['discountOptionGroup', 'purchasePrice'])).toFixed(2);

        if (this.props.location && this.props.location.search) {
            let purchaseOptionId = this.props.location.search.split("=")[1];
            if (svod) {
                svod.purchaseOption.id = purchaseOptionId;
            }
        }


        let billingEndDate = moment(getNestedValue(svod, ['billingEndDate'])).format("DD/MM/YYYY");

        //check usertype and enable subscribe button
        let subsBtnDisabled = false;
        if (getServiceType() === PREPAID_SERVICE_TYPE) {
            if (purchaseStatusType.productPendingRemove) {
                subsBtnDisabled = true;
            }
        } else {
            subsBtnDisabled = false;
        }

        let unsubMessage = "";

        if (getServiceType()) {
            unsubMessage = ""
        } else {
            unsubMessage = "Please re-subscribe to cancel the unsubscription.";
        }
        let showUtilizationPeriod, showPeriod;
        // if (purchaseType !== TRANSACTIONAL) {
        //     showPeriod = "(Monthly)"
        //     showUtilizationPeriod = "(Monthly)"
        // } else {
            let utilizationPeriodUnit = getNestedValue(svod && svod.purchaseOption, ['utilization', 'utilizationPeriodUnit'])
            //show the utilization period of the purchase option
            showUtilizationPeriod = getUtilizationPeriod(svod, utilizationPeriodUnit)
            // `(${getNestedValue(svod && svod.purchaseOption, ['utilization', 'utilizationPeriod']) === 1 ? getNestedValue(svod && svod.purchaseOption, ['utilization', 'utilizationPeriod']) + " " + _.startCase(_.toLower(utilizationPeriodUnit.substring(0, utilizationPeriodUnit.length - 1))) : getNestedValue(svod && svod.purchaseOption, ['utilization', 'utilizationPeriod']) + " " +
            //     _.startCase(_.toLower(utilizationPeriodUnit))})`
        // }

        let showFreeTag;
        if (getLoggedInStatus() && svod && svod.isBundled) {
            showFreeTag = <div className="free-tag"><h6 className="free-tag-font">Free</h6></div>
        }

        let discountLevelProduct, discountLevelUser;
        if (svod && svod.discountOptionGroup && svod.discountOptionGroup.discountOptions) {
            discountLevelProduct = svod.discountOptionGroup && svod.discountOptionGroup.discountOptions.filter(svod => svod.discountLevel === PRODUCT);
            discountLevelUser = svod.discountOptionGroup && svod.discountOptionGroup.discountOptions.filter(svod => svod.discountLevel === USER);
        }
        let pricing
        if (svod && svod.discountOptionGroup) {
            if (svod && svod.discountOptionGroup && svod.discountOptionGroup.discountOptions && validArray(svod.discountOptionGroup.discountOptions)) {
                pricing =
                    <div className="product-purchase-content">
                        <div className="price-box">
                            <DiscountPrices price={svod.discountOptionGroup.purchasePrice === null ? svod.discountOptionGroup.actualPrice : svod.discountOptionGroup.purchasePrice} showTax={true} oldPrice={
                                svod.discountOptionGroup.actualPrice} precentage={
                                    svod.purchaseOption.discountPercentage} productPeriod={showPeriod} discountLevelProduct={discountLevelProduct && discountLevelProduct[0]} discountLevelUser={discountLevelUser && discountLevelUser[0]}
                                discountPriceLoading={false} type="package" isProduct={true} purchaseOptionType={svod.purchaseOption.purchaseType} showUtilizationPeriod={showUtilizationPeriod} utilizationPeriodUnit={utilizationPeriodUnit}
                            />
                        </div>
                    </div>
            } else {
                pricing = <div className="price-box"><DiscountPriceComponent price={svod && svod.discountOptionGroup && svod.discountOptionGroup.purchasePrice} showTax={true} period={showUtilizationPeriod} /></div>
                // <div className="price-box">
                //     <div className="discount-container-row-svod">
                //         <h4>{`Rs. ${svod && svod.discountOptionGroup && svod.discountOptionGroup.purchasePrice.toFixed(2)} +tax`}</h4>
                //     </div>
                //     <div className="discount-container-row-svod">
                //         <h4 className="period">(Monthly)</h4>
                //     </div>
                // </div>
            }
        }
        let priceDisplay
        if (getLoggedInStatus()) {
            if (svod && ((!bundledStatus) && !isPurchased)) {
                if (defaultPrice === 0) {
                    priceDisplay = <div style={{ display: 'grid' }}> <h4>Rs. 0 +tax</h4></div>
                } else {
                    priceDisplay = pricing
                }
            }
        } else {
            if (this.props.match && this.props.match.params.purchasable === "true") {
                priceDisplay = <div style={{ display: 'grid' }}>
                    {/* <h4>{"Rs." + defaultPrice + " +tax"}</h4> */}
                    {/* <DiscountPriceComponent price={defaultPrice} showTax={true} oldPrice={absoluteDiscount} precentage={discountPercentage} period={showPeriod} /> */}
                </div>
            } else {
                priceDisplay = null
            }
        }
        let purchaseText = purchaseStatusType.productPurchased && svod.purchaseOption.purchaseType === TRANSACTIONAL ? "Already Purchased" : "Already Subscribed"
        let customButtonText
        if (purchaseType === TRANSACTIONAL) {
            customButtonText = "PURCHASE";
        } else {
            customButtonText = "SUBSCRIBE";
        }

        let productPurchaseOption = getNestedValue(svod, ['purchaseOption'])
        let purchaseUtilizationPeriodUnit = productPurchaseOption && productPurchaseOption.utilization && productPurchaseOption.utilization.utilizationPeriodUnit.toLowerCase()
        let purchaseUtilizationPeriod = productPurchaseOption && productPurchaseOption.utilization && productPurchaseOption.utilization.utilizationPeriod
        let billingDateIsAfter = moment().add(purchaseUtilizationPeriod, purchaseUtilizationPeriodUnit).isAfter(localStorage.getItem('basepackageEndDate'));
        let basepackagePurchaseType = localStorage.getItem('basepackagePurchaseType')
        let paymentStatus = getNestedValue(svod, ['paymentStatus']);
        let provisioningStatus =paymentStatus && paymentStatus === PAYMENT_SUCCESS && purchaseStatusType.productPurchasedFailed

        return (
            <>
                {svodLoading ?
                    < DummySvodPage />
                    :
                    <div style={{ marginTop: 40 }}>
                        {svod && <Container style={{ color: "#d0d0d0" }}>
                            <Row>
                                <Col sm={5}><h4>{svod && svod.name}</h4></Col>
                                <Col sm="3"></Col>
                                <Col className={svod && svod.isBundled ? "free-tag-col-style" : ((svod && (purchaseStatusType.productPurchased)) ? "sovd-purchased-icon" : "")}>
                                    {(svod && ((purchaseStatusType.productPurchased || purchaseStatusType.productPendingRemove || paymentStatus === PAYMENT_SUCCESS))) ? (<span className={purchaseStatusType.productPendingRemove || paymentStatus === PAYMENT_SUCCESS ? "justify-already-purchased-svod" : ""}> <i className="fa fa-check-square" style={{ color: "#4FA1D9", fontSize: 18, padding: '4px' }}></i>{" "}{purchaseText}</span>) : ("")}
                                    {priceDisplay}
                                    {/* {getLoggedInStatus() && svod && ((purchaseStatusType.productNotPurchased || purchaseStatusType.productPendingRemove) ? true : false) && !svod.isBundled && pricing} */}
                                    {showFreeTag}
                                </Col>
                            </Row>
                            <p className="text-align-justify">{svod && svod.description}</p>
                            <Row style={{ display: 'initial' }}>
                                {!svod.isBundled && billingDateIsAfter && basepackagePurchaseType === TRANSACTIONAL && serviceType && !provisioningStatus && <ExpiryDate expireDate={moment(localStorage.getItem('basepackageEndDate')).format('DD/MM/YYYY')} />}
                            </Row>
                            <Row style={{ display: 'initial' }}>
                                {purchaseStatusType.productPurchasedFailed && paymentStatus === PAYMENT_SUCCESS && provisioningStatus && <Row className="provision-alert-row">
                                    <ProductProvisioningError />
                                </Row>}
                            </Row>
                            {submitBtnLoading ? <CustomButtonPlaceholder /> : (getLoggedInStatus() && svod && !svod.isBundled && <Row className="svod-purchase-btn">
                                <Col className="d-flex justify-content-end">
                                    {(svod && (purchaseStatusType.productPurchased)) && svod.purchaseOption.purchaseType !== TRANSACTIONAL && <CustomButton
                                        // loading={loading}
                                        onClick={this.handleShowUnsub}
                                        text="UNSUBSCRIBE"></CustomButton>}
                                    {svod && ((purchaseStatusType.productPendingRemove || purchaseStatusType.productNotPurchased)) && <CustomButton
                                        // loading={loading}
                                        onClick={this.handleShow}
                                        text={purchaseType === TRANSACTIONAL ? "PURCHASE" : "SUBSCRIBE"}
                                        disabled={subsBtnDisabled}
                                    ></CustomButton>}
                                </Col>
                            </Row>)}
                            {
                                getLoggedInStatus() && purchaseStatusType.productPendingRemove &&
                                <Row className="renew-alert svod-page-alert">
                                    <div className="account-suspended">
                                        <p>Unsubscribed content. You will have access to this only till {billingEndDate}. {unsubMessage}</p>
                                    </div>
                                </Row>
                            }
                            <div style={{ marginTop: 40 }}>
                                <h4 className="color-bf800c">Content this month</h4>
                                <hr className="border-width-2 background-color-blue" />
                                <Row>
                                    {
                                        svod && svod.productList && Array.isArray(svod.productList) && svod.productList.map(content => {
                                            let purchaseStatusType = purchaseStatusValidation(content.purchaseStatus);
                                            if (content.status === "PRODUCT_STATUS_ACTIVE") {
                                                return <div className="display-inline-block">
                                                    <Card
                                                        id={content.id}
                                                        isLoggedIn={getLoggedInStatus()}
                                                        name={content.name}
                                                        imageURL={content.imageURL}
                                                        isPurchased={content && (purchaseStatusType.productPurchased || purchaseStatusType.productPendingRemove)}
                                                        showAddNew={false}
                                                        daysToExpire={content.daysRemainingToExpire}
                                                        hover={true}
                                                        showPrice={false}
                                                        onClick={this.onClick}
                                                        type="onDemand"
                                                    />
                                                </div>
                                            }
                                            return false
                                        })
                                    }
                                </Row>

                            </div>
                        </Container>
                        }
                    </div>
                }
                <ProductModal
                    showModal={showModal}
                    modalStyle={{ display: showModal ? 'flex' : 'none', overflow: "overlay" }}
                    handleChange={this.handleChangePurchaseOptions}
                    onClose={this.onClose}
                    modalBodyText="Please confirm to unsubscribe?"
                    buttonText={purchaseType === TRANSACTIONAL ? "PURCHASE" : "SUBSCRIBE"}
                    data={svod}
                    purchaseOption={purchaseOptions}
                    onClick={this.onClickOk}
                    svodContent={true}
                    serviceType={serviceType}
                    selectedIndex={selectedIndex}
                    discountPriceLoading={purchaseOptionsLoading}
                    bundledProducts={bundledProducts}
                    showUtilizationPeriod={showUtilizationPeriod}
                    regionType={regionType}
                    utilizationPeriodUnit={utilizationPeriodUnit}
                />
                <PurchaseModal
                    heading={`Are you sure you want to ${customButtonText.toLowerCase()}?`}
                    showModal={showModals}
                    modalStyle={{ display: showModals ? 'flex' : 'none', overflow: "overlay" }}
                    handleChange={this.handleChange}
                    onClose={this.onClose}
                    modalBodyText="Please confirm to unsubscribe?"
                    data={svod}
                    onClick={this.onClickOk}
                    onClickYes={this.onClickYes}
                    onClickNo={this.onClickNo}
                    value={this.state.password}
                    disable={this.state["password"] === undefined}
                />
                <UnsubscribeModal
                    showUnsub={showUnsub}
                    modalStyle={{ display: showUnsub ? 'flex' : 'none', overflow: "overlay" }}
                    onClose={this.onCloseUnsub}
                    data={svod}
                    onClickYes={this.onClickYesUnsub}
                    onClickNo={this.onClickNo}
                />
                <NotifyModal
                    showModal={this.state.notifyStatus}
                    modalStyle={{
                        display: this.state.notifyStatus ? "flex" : "none",
                        overflow: "overlay",
                    }}
                    onClose={this.closeNotify}
                    type={(purchaseStatus && purchaseStatus.status)}
                    message={(purchaseStatus && purchaseStatus.purchaseStatus) || productPurchaseStatusError}
                    loading={(purchaseStatus && purchaseStatus.purchaseLoading) || payhereSvodPurchaseStatusLoading}
                    dummyType="svod"
                    purchaseStatus={svodPurchaseStatus}
                    // loading={purchaseStatusLoading}
                    serviceType={serviceType}
                    payherePurchaseStatus={payhereSvodPurchaseStatus}
                    data={svod}
                    purchaseType={purchaseType}
                    defaultPrice={parseFloat(getProductPrice(svod))}
                    // defaultPrice={svod && svod.discountOptionGroup && svod.discountOptionGroup.purchasePrice && svod.discountOptionGroup.purchasePrice===null ? svod.discountOptionGroup.actualPrice :svod && svod.discountOptionGroup && svod.discountOptionGroup.purchasePrice}
                    referenceId={referenceId}
                    provisioningStatus ={provisioningStatus}
                />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...svodSelectors.getAllSvod(state),
        // connectionDetails: getBaseProductsSelectors.getConnectionDetails(state),
        // connectionDetailsLoading: getBaseProductsSelectors.getConnectionDetailsLoading(state)
        getBasePackageId: svodSelectors.getBasePackageId(state),
        bundledProducts: svodSelectors.getBundledProducts(state),
        payhereDetails: svodSelectors.getPayhereDetails(state),
        userDetails: svodSelectors.getUserDetails(state),
        paymentLoading: svodSelectors.getPurchaseLoading(state),
        purchaseOptionsLoading: svodSelectors.getSvodPurchaseOptionLoading(state),
        ...svodSelectors.getPayhereSvodPurchaseStatus(state),
        submitBtnLoading: svodSelectors.getSubmitLoading(state),
        referenceId: svodSelectors.getReferenceId(state)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getBaseProductsActions: bindActionCreators(getBaseProductsActions, dispatch),
        svodActions: bindActionCreators(svodActions, dispatch)
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SvodPage));
