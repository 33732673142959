import React from 'react';
import FeaturedPackage from './FeaturedPackage';
import { Col } from "react-bootstrap";
import DummyPackageView from '../DummyComponent/DummyPackageView';
import { getProductPrice, validArray } from '../../util/ArrayValidator';
import _ from "lodash";
import {TRANSACTIONAL} from '../../util/Constants'

const FeaturedPackageView = ({ featuredPackages, isLoggedIn, onClickPackage, featuredChannels, numberOfScreensBundled, numberOfScreensPurchased }) => {
    if (isLoggedIn) {
        if (featuredPackages.secondaryAllowedProductsLoading) {
            // if (true) {
            return (
                // <Spinner /> 
                <DummyPackageView />
            )
        } else {
            if (featuredPackages.secondaryAllowedProducts && Array.isArray(featuredPackages.secondaryAllowedProducts) && featuredPackages.secondaryAllowedProducts.length !== 0 && featuredPackages.secondaryAllowedProducts) {
                let secondaryAllowedProductsState = featuredPackages.secondaryAllowedProducts[0] && featuredPackages.secondaryAllowedProducts[0]

                let promotionalPackageIndex = featuredPackages.secondaryAllowedProducts && validArray(featuredPackages.secondaryAllowedProducts) && featuredPackages.secondaryAllowedProducts[0].purchasableProductList
                let promoPacksWithScreens = _.filter(promotionalPackageIndex, { 'packageTypeName': 'Promotional', 'isPurchased': true, 'productList': [{ 'productTypeName': 'Screens' }] });
                let totalPromoScreens= 0
                promoPacksWithScreens.map((o, index) => {
                    o.productList.map((x, y) => {
                        if (x.productTypeName === "Screens") {
                             totalPromoScreens = totalPromoScreens + x.numberOfScreens
                        }
                        return false
                    })
                    return false
                })
                let utilization = secondaryAllowedProductsState.purchaseOption && secondaryAllowedProductsState.purchaseOption.utilization  
                let purchaseType=secondaryAllowedProductsState.purchaseOption && secondaryAllowedProductsState.purchaseOption.purchaseType=== TRANSACTIONAL? utilization && utilization.utilizationPeriod===1? "For "  +  utilization.utilizationPeriod + " "+ utilization.utilizationPeriodUnit.substring(0, utilization.utilizationPeriodUnit.length - 1).toLowerCase(): "For "  + utilization.utilizationPeriod + " " + utilization.utilizationPeriodUnit.toLowerCase() : utilization.utilizationPeriodUnit==="MONTHS"?"Monthly subscription" :"Daily subscription"
                return (
                    <Col key={0} xs={12} md={isLoggedIn ? 12 : 6} lg={isLoggedIn ? 12 : 4}>
                        <FeaturedPackage
                            id={0}
                            name={featuredPackages.secondaryAllowedProducts[0].name}
                            description={featuredPackages.secondaryAllowedProducts[0].description}
                            imagePath={featuredPackages.secondaryAllowedProducts[0].imageURL}
                            price={getProductPrice(featuredPackages.secondaryAllowedProducts && featuredPackages.secondaryAllowedProducts[0])}
                            dataArr={numberOfScreensBundled + numberOfScreensPurchased + totalPromoScreens}
                            // dataArr={(parseInt(featuredPackages.secondaryAllowedProducts[0] && featuredPackages.secondaryAllowedProducts[0].productList[numberOfScreensBundled] && featuredPackages.secondaryAllowedProducts[0].productList[numberOfScreensBundled].numberOfScreens && featuredPackages.secondaryAllowedProducts[0].productList[numberOfScreensBundled].numberOfScreens)) + (featuredPackages.secondaryAllowedProducts && featuredPackages.secondaryAllowedProducts[0] && featuredPackages.secondaryAllowedProducts[0].purchasableProductList && featuredPackages.secondaryAllowedProducts[0].purchasableProductList[numberOfScreensPurchased] && featuredPackages.secondaryAllowedProducts[0].purchasableProductList[numberOfScreensPurchased].isPurchased && parseInt(featuredPackages.secondaryAllowedProducts[0].purchasableProductList[numberOfScreensPurchased].isPurchased && featuredPackages.secondaryAllowedProducts[0].purchasableProductList[numberOfScreensPurchased].numberOfScreens))}
                            purchasedScreens={featuredPackages.secondaryAllowedProducts[0].purchasableProductList}
                            isLoggedIn={isLoggedIn}
                            onClick={onClickPackage}
                            purchaseType={purchaseType}
                        />
                    </Col>
                )
            } else {
                return null;
            }
        }
    } else {
        if (featuredChannels.loading) {
            // if (true) {
            return (
                // <Spinner />
                <DummyPackageView key="dummyPackage" />

            )
        } else {
            if (featuredPackages.secondaryAllowedProducts) {
                return featuredPackages.secondaryAllowedProducts.slice(0, isLoggedIn ? 1 : 3).map((featuredPackage, index) => {
                    if (featuredPackage.status === "PRODUCT_STATUS_ACTIVE") {
                        let utilization = featuredPackage.purchaseOption && featuredPackage.purchaseOption.utilization  
                        let purchaseType=featuredPackage.purchaseOption && featuredPackage.purchaseOption.purchaseType=== TRANSACTIONAL? utilization && utilization.utilizationPeriod===1? "For "  +  utilization.utilizationPeriod + " "+ utilization.utilizationPeriodUnit.substring(0, utilization.utilizationPeriodUnit.length - 1).toLowerCase(): "For "  + utilization.utilizationPeriod + " " + utilization.utilizationPeriodUnit.toLowerCase() : utilization.utilizationPeriodUnit==="MONTHS"?"Monthly subscription" :"Daily subscription"
                        return <>
                            <Col
                                key={featuredPackage.id}
                                xs={12}
                                md={isLoggedIn ? 12 : 6}
                                lg={isLoggedIn ? 12 : 4}
                            >
                                <FeaturedPackage
                                    id={index}
                                    imagePath={featuredPackage.imageURL}
                                    // id={`${featuredPackage.id}#${featuredPackages.productTypeId}`}
                                    name={featuredPackage.name}
                                    isLoggedIn={isLoggedIn}
                                    onClick={onClickPackage}
                                    price={getProductPrice(featuredPackage)}
                                    purchaseType={purchaseType}
                                />
                            </Col>
                        </>
                    }
                    else {
                        return (
                            <DummyPackageView />

                        );
                    }
                })
            } else {
                return (
                    <DummyPackageView />

                );
            }
        }
    }
}

export default FeaturedPackageView;