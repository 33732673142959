import React from "react";
import Table from 'react-bootstrap/Table'
import { taxCalculation } from '../../util/ArrayValidator'
import DummyPaymentTable from '../DummyComponent/DummyPaymentTable';

export const MyPaymentsTable = ({
    paymentDetails,
    serviceType,
    loading
}) => {
    if (loading) {
        return <DummyPaymentTable />
    }
    else {
        if (serviceType === "Mobitel") {
            return <Table striped bordered hover variant="dark" responsive>
                <thead>
                    <tr className="text-align-center">
                        <th className="data-time-payments">Date/Time</th>
                        <th>Product Name</th>
                        <th>Price Excluding Tax</th>
                        <th>Status</th>
                        <th className="reference-number-payments">Ref No</th>
                    </tr>
                </thead>

                <tbody>
                    {paymentDetails && paymentDetails.map((detail, index) => {
                        if (detail.paymentStatus === "PENDING") {
                            return null
                        } else {
                            var date = new Date(detail.createdDate);
                            return <tr key={index}>
                                <td>{date.toLocaleString("sv-SE")}</td>
                                <td>{detail.productName}</td>
                                <td>{"Rs." + detail.amount.toFixed(2)}</td>
                                <td>{detail.paymentStatus}</td>
                                <td>{detail.referenceNumber}</td>
                            </tr>
                        }

                    })
                    }
                </tbody>
            </Table>
        } else {
            return <Table striped bordered hover variant="dark" responsive>
                <thead>
                    <tr className="text-align-center">
                        <th className="data-time-payments">Date/Time</th>
                        <th>Type</th>
                        <th>Product Name</th>
                        <th>Price Including Tax</th>
                        <th>Method</th>
                        <th>Card Details</th>
                        <th>Status</th>
                        <th className="reference-number-payments">Ref No</th>
                        <th className="reason-payments">Reason</th>

                    </tr>
                </thead>

                <tbody>
                    {paymentDetails && paymentDetails.map((detail, index) => {
                        if (detail.paymentStatus === "PENDING") {
                            return null
                        } else {
                            var date = new Date(detail.createdDate);
                            var amount = taxCalculation(detail.amount)
                            return <tr key={index}>
                                <td>{date.toLocaleString("sv-SE")}</td>
                                <td>{detail.paymentType}</td>
                                <td>{detail.productName}</td>
                                <td>{"Rs." + amount.toFixed(2)}</td>
                                <td>{detail.paymentMethod}</td>
                                <td>{detail.cardNumber}</td>
                                <td>{detail.paymentStatus}</td>
                                <td>{detail.referenceId}</td>
                                <td>{detail.details}</td>
                            </tr>
                        }

                    })
                    }
                </tbody>
            </Table>
        }
    }
}