import React from 'react'
import { Nav } from 'react-bootstrap';
import { TRANSACTIONAL, SUBSCRIPTIONAL } from '../../util/Constants';

export const PackageFilter = ({onSelect}) => {
    return <>
        <Nav variant="pills" defaultActiveKey="ALL" onSelect={onSelect}>
            <Nav.Item className="package-filters-button">
                <Nav.Link eventKey="ALL" className="package-filters">All</Nav.Link>
            </Nav.Item>
            <Nav.Item className="package-filters-button">
                <Nav.Link eventKey={SUBSCRIPTIONAL} className="package-filters">Subscriptional</Nav.Link>
            </Nav.Item>
            <Nav.Item className="package-filters-button">
                <Nav.Link eventKey={TRANSACTIONAL} className="package-filters">
                    Transactional
                </Nav.Link>
            </Nav.Item>
        </Nav>
    </>
}