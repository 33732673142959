import React from 'react'

export const DeviceCount = ({
    allScreens

}) => {
    return <><span className="no-devices-label">
        NO OF DEVICES :  {allScreens}
    </span>
    </>
}
