import types from './types'
import { handleActions } from 'redux-actions'
import { vodNormalization } from '../../../util/VODUtils'
import { packageFiltering } from '../../../util/FilterPackages'
import { getUniqueData } from '../../../util/ArrayValidator'

const basicState = {
    loading: true,
    hasError: false,
    error: {},
}

const initialState = {
    //----------------------------------
    allChannels: {
        ...basicState,
        allChannels: []
    },
    allSVODs: {
        ...basicState,
        allSVODs: []
    },
    allVODs: {
        ...basicState,
        allVODs: []
    },

    //----------------------------------
    allUserChannels: {
        ...basicState,
        allUserChannels: []
    },
    allUserSVODs: {
        ...basicState,
        allUserSVODs: []
    },
    allUserDevices: {
        ...basicState,
        allUserDevices: []
    },
    allUserDataBundles: {
        ...basicState,
        allUserDataBundles: []
    },
    allUserVODs: {
        ...basicState,
        allUserVODs: []
    },
    // allUserSvods: [],
    allUserPackages: [],
    // allUserScreens: [],
    VODSubType: {
        ...basicState,
        VODSubType: null,
    },
    ChannelCategory: {
        ...basicState,
        ChannelCategory: null
    },
    secondaryAllowedProduct: {
        secondaryAllowedProductLoading: true
    },
    allPackages: {
        ...basicState,
        allPackagesLoading: true
    },
    distinctProducts: {
        ...basicState,
        distinctProductsLoading: true
    },
    productTypes: {
        ...basicState,
        productTypesLoading: true
    },
    //promotional package initial state
    promotionalPackages: {
        promotionalPackagesLoading: true,
        promotionalPackages: []
    }
}

// Reducers from redux-actions
export default handleActions({
    [types.RESET_FORM]: (state, { payload }) => ({
        ...state,
        allChannels: {
            ...basicState,
            allChannels: []
        },
        allSVODs: {
            ...basicState,
            allSVODs: []
        },
        allVODs: {
            ...basicState,
            allVODs: []
        },

        //----------------------------------
        allUserChannels: {
            ...basicState,
            allUserChannels: []
        },
        allUserSVODs: {
            ...basicState,
            allUserSVODs: []
        },
        allUserDevices: {
            ...basicState,
            allUserDevices: []
        },
        allUserDataBundles: {
            ...basicState,
            allUserDataBundles: []
        },
        allUserVODs: {
            ...basicState,
            allUserVODs: []
        },
        // allUserSvods: [],
        allUserPackages: [],
        // allUserScreens: [],
        VODSubType: {
            ...basicState,
            VODSubType: null,
        },
        ChannelCategory: {
            ...basicState,
            ChannelCategory: null
        },
        secondaryAllowedProduct: {
            secondaryAllowedProductLoading: true
        },
        allPackages: {
            ...basicState,
            allPackagesLoading: true
        },
        distinctProducts: {
            ...basicState,
            distinctProductsLoading: true
        },
        productTypes: {
            ...basicState,
            productTypesLoading: true
        },
        //promotional package initial state
        promotionalPackages: {
            promotionalPackagesLoading: true,
            promotionalPackages: []
        }
    }),
    [types.RESET_PACKAGES]: (state, { payload }) => ({
        ...initialState
    }),
    //--------------------------BEFORE LOGIN----------------------------------------
    //-------------------BEFORE LOGIN CHANNEL----------------------------------------
    [types.GET_ALL_CHANNEL_SUCCESS]: (state, { payload }) => {
        var content_categories = []
        payload.map((channel, i) => {
            channel.categories && channel.categories.map((category, j) => {
                if(channel.status === "PRODUCT_STATUS_ACTIVE"){
                    content_categories.push(category);
                }
                return null
            })
            return null
        })

        //get unique categories
        let unique_categories = getUniqueData(content_categories, "category");
        return {
            ...state,
            allChannels: {
                ...state.allChannels,
                loading: false,
                unique_categories: unique_categories,
                allChannels: payload,
            }
        }
    },
    [types.GET_ALL_CHANNEL_FAIL]: (state, { payload }) => ({
        ...state,
        allChannels: {
            ...state.allChannels,
            loading: false,
            hasError: true,
            error: { payload }
        }
    }),
    //---------------------------------------------------------------------------
    //-------------------BEFORE LOGIN SVOD----------------------------------------
    [types.GET_ALL_SVOD_SUCCESS]: (state, { payload }) => ({
        ...state,
        allSVODs: {
            ...state.allSVODs,
            loading: false,
            allSVODs: payload
        }
    }),
    [types.GET_ALL_SVOD_FAIL]: (state, { payload }) => ({
        ...state,
        allSVODs: {
            ...state.allSVODs,
            loading: false,
            hasError: true,
            error: { payload }
        }
    }),
    //---------------------------------------------------------------------------------
    //-------------------BEFORE LOGIN VOD----------------------------------------
    [types.GET_ALL_VOD_SUCCESS]: (state, { payload }) => {
        var structuredVODS = vodNormalization(state.VODSubType, payload)
        return {
            ...state,
            allVODs: {
                ...state.allVODs,
                loading: false,
                allVODs: payload,
                allVods: structuredVODS,
            }
        }
    },
    [types.GET_ALL_VOD_FAIL]: (state, { payload }) => ({
        ...state,
        allVODs: {
            ...state.allVODs,
            loading: false,
            hasError: true,
            error: { payload }
        }
    }),
    //---------------------------------------------------------------------------------
    //------------------------AFTER LOGIN----------------------------------------------
    //---------------------AFTER LOGIN CHANNEL-----------------------------------------
    [types.GET_USER_CHANNEL_SUCCESS]: (state, { payload }) => {
        var content_categories = []
        payload.map((channel, i) => {
            channel.categories && channel.categories.map((category, j) => {
                if(channel.status === "PRODUCT_STATUS_ACTIVE"){
                    content_categories.push(category);
                }
                return null
            })
            return null
        })

        return {
            ...state,
            allUserChannels: {
                ...state.allUserChannels,
                loading: false,
                allUserChannels: payload,
                unique_categories: getUniqueData(content_categories, "category")
            }
        }
    },
    [types.GET_USER_CHANNEL_FAIL]: (state, { payload }) => ({
        ...state,
        allUserChannels: {
            ...state.allUserChannels,
            loading: false,
            hasError: true,
            error: { payload }
        }
    }),
    //------------------------------------------------------------------------------
    //---------------------AFTER LOGIN SVOD-----------------------------------------
    [types.GET_USER_SVOD_SUCCESS]: (state, { payload }) => ({
        ...state,
        allUserSVODs: {
            ...state.allUserSVODs,
            loading: false,
            allUserSVODs: payload
        }
    }),
    [types.GET_USER_SVOD_FAIL]: (state, { payload }) => ({
        ...state,
        allUserSVODs: {
            ...state.allUserSVODs,
            loading: false,
            hasError: true,
            error: { payload }
        }
    }),
    //------------------------------------------------------------------------------
    //------------------AFTER LOGIN DEVICES-----------------------------------------
    [types.GET_USER_DEVICE_SUCCESS]: (state, { payload }) => ({
        ...state,
        allUserDevices: {
            ...state.allUserDevices,
            loading: false,
            allUserDevices: payload
        }
    }),
    [types.GET_USER_DEVICE_FAIL]: (state, { payload }) => ({
        ...state,
        allUserDevices: {
            ...state.allUserDevices,
            loading: false,
            hasError: true,
            error: { payload }
        }
    }),
    //--------------------------------------------------------------------------------
    //------------------AFTER LOGIN DATABUNDLES----------------------------------------
    [types.GET_USER_DATA_BUNDLE_SUCCESS]: (state, { payload }) => ({
        ...state,
        allUserDataBundles: {
            ...state.allUserDataBundles,
            loading: false,
            allUserDataBundles: payload
        }
    }),
    [types.GET_USER_DATA_BUNDLE_FAIL]: (state, { payload }) => ({
        ...state,
        allUserDataBundles: {
            ...state.allUserDataBundles,
            loading: false,
            hasError: true,
            error: { payload }
        }
    }),
    //--------------------------------------------------------------------------------
    //------------------AFTER LOGIN VOD----------------------------------------
    [types.GET_USER_VOD_SUCCESS]: (state, { payload }) => {
        var structuredVODS = vodNormalization(state.VODSubType, payload)
        // var filteredVodObj = getFilteredVODs(payload)
        return {
            ...state,
            allUserVODs: {
                ...state.allUserVODs,
                // allUserVODs: payload
                // allVODs: {
                //     ...state.allVODs,
                loading: false,
                allUserVODs: payload,
                allVods: structuredVODS,
            }
        }
    },
    [types.GET_USER_VOD_FAIL]: (state, { payload }) => ({
        ...state,
        allUserVODs: {
            ...state.allUserVODs,
            loading: false,
            hasError: true,
            error: { payload }
        }
    }),
    //----------------------------VOD UTILS----------------------------------------------------
    [types.GET_ONDEMAND_SUB_TYPES_SUCCESS]: (state, { payload }) => {
        return {
            ...state,
            VODSubType: {
                ...state.VODSubType,
                loading: false,
                VODSubType: payload
            }
        }
    },
    [types.GET_ONDEMAND_SUB_TYPES_FAIL]: (state, { payload }) => ({
        ...state,
        VODSubType: {
            ...state.VODSubType,
            loading: false,
            hasError: true,
            error: { payload }
        }
    }),

    //----------------------------CHANNELS UTILS----------------------------------------------------
    [types.GET_CHANNEL_CATEGORIES_SUCCESS]: (state, { payload }) => {
        return {
            ...state,
            ChannelCategory: {
                ...state.ChannelCategory,
                loading: false,
                ChannelCategory: payload
            }
        }
    },
    [types.GET_CHANNEL_CATEGORIES_FAIL]: (state, { payload }) => ({
        ...state,
        ChannelCategory: {
            ...state.ChannelCategory,
            loading: false,
            hasError: true,
            error: { payload }
        }
    }),

    //----------------------------PACKAGE UTILS----------------------------------------------------
    [types.GET_ALLOWED_PACKAGE_IDS_SUCCESS]: (state, { payload }) => (
        { ...state, secondaryAllowedProduct: { secondaryAllowedProduct: payload, secondaryAllowedProductError: null, secondaryAllowedProductLoading: false } }
    ),
    [types.GET_ALLOWED_PACKAGE_IDS_FAILED]: (state, { payload }) => (
        { ...state, secondaryAllowedProduct: { secondaryAllowedProduct: "", secondaryAllowedProductError: payload, secondaryAllowedProductLoading: false } }
    ),
    [types.GET_ALL_PACKAGES_SUCCESS]: (state, { payload }) => {
        let filteredPackages = packageFiltering(payload)
        return {
            ...state,
            allPackages: {
                allPackages: payload,
                allPackagesError: null,
                allPackagesLoading: false,
                filteredPackages: filteredPackages
            }
        }
    },
    [types.GET_ALL_PACKAGES_FAILED]: (state, { payload }) => ({
        ...state,
        allPackages: {
            allPackages: {},
            allPackagesError: payload,
            allPackagesLoading: false
        }
    }),
    [types.GET_DISTINCT_PRODUCTS]: (state, { payload }) => ({
        ...state,
        distinctProducts: {
            distinctProductsLoading: true, 
            packageID : payload.productId
        }
    }),
    [types.GET_DISTINCT_PRODUCTS_SUCCESS]: (state, { payload }) => ({
        ...state,
        distinctProducts: {
            distinctProducts: payload,
            distinctProductsError: null,
            distinctProductsLoading: false,
            packageID : state.distinctProducts.packageID
        }
    }),
    [types.GET_DISTINCT_PRODUCTS_FAILED]: (state, { payload }) => ({
        ...state,
        distinctProducts: {
            distinctProducts: null,
            distinctProductsError: payload,
            distinctProductsLoading: false
        }
    }),
    [types.GET_DISTINCT_PRODUCTS_LOADING]: (state, { payload }) => ({
        ...state,
        distinctProducts: {
            distinctProductsLoading: true
        }
    }),
    [types.GET_PRODUCT_TYPES_SUCCESS]: (state, { payload }) => (
        {
            ...state,
            productTypes: { productTypes: payload, productTypesError: null, productTypesLoading: false }
        }
    ),

    [types.GET_PRODUCT_TYPES_FAILED]: (state, { payload }) => (
        { ...state, productTypes: { productTypesError: payload, productTypesLoading: false } }
    ),

    [types.GET_PROMOTIONAL_PACKAGES_SUCCESS]: (state, { payload }) => {
        return {
            ...state,
            promotionalPackages: {
                promotionalPackagesLoading: false,
                promotionalPackages: payload,
                promotionalPackagesError: null
            }
        }
    },
    [types.GET_PROMOTIONAL_PACKAGES_FAILED]: (state, { payload }) => {
        return {
            ...state,
            promotionalPackages: {
                promotionalPackagesLoading: false,
                promotionalPackages: null,
                promotionalPackagesError: payload
            }
        }
    },
    [types.FILTER_PACKAGES]: (state, { payload }) => {
        let filteredPackages = state.allPackages.filteredPackages && state.allPackages.filteredPackages.map(function (e) { return e.filterType; }).indexOf(payload);
        return {
            ...state,
            allPackages: {
                allPackages: state.allPackages.filteredPackages[filteredPackages].filteredPackages,
                allPackagesError: null,
                allPackagesLoading: false,
                filteredPackages: state.allPackages.filteredPackages
            }
        }
    },

}, initialState)
//533