import { createAction } from 'redux-actions'
import types from './types'

export default {
    getUsername: createAction(types.GET_USERNAME),
    getUsernameSuccess: createAction(types.GET_USERNAME_SUCCESS),
    getUsernameFailed: createAction(types.GET_USERNAME_FAILED),

    changePassword: createAction(types.CHANGE_PASSWORD),
    changePasswordSuccess: createAction(types.CHANGE_PASSWORD_SUCCESS),
    changePasswordFailed: createAction(types.CHANGE_PASSWORD_FAILED)

}