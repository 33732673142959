import reducers from "./reducers";

import getBaseProductsSelectors from "./selectors";
import getBaseProductsService from "./service";
import getBaseProductsTypes from "./types";
import getBaseProductsActions from "./actions";

export {
    getBaseProductsSelectors,
    getBaseProductsService,
    getBaseProductsActions,
    getBaseProductsTypes
};

export default reducers;  