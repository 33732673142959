import React from 'react'
import { CustomCarousel } from '../../components/carousel/CustomCarousel'



function VODCustomCarousel({ selectedTrendingTab, onClick, SampleNextArrow, SamplePrevArrow, isDummy, isLoggedIn }) {
    return (
        <div className="row">
            <CustomCarousel
                slidesToShow={(900 < window.innerWidth ? 3 : (550 < window.innerWidth ? 2 : (440 < window.innerWidth ? 2 : 1)))}
                renderCenterLeftControls={SamplePrevArrow}
                renderCenterRightControls={SampleNextArrow}
                renderBottomCenterControls={false}
                heightMode="last"
                slideWidth="165px"
                initialSlideHeight={(1200 < window.innerWidth ? 225 :
                    (990 < window.innerWidth ? 240 :
                        (900 < window.innerWidth ? 180 :
                            (770 < window.innerWidth ? 300 :
                                (550 < window.innerWidth ? 180 :
                                    (440 < window.innerWidth ? 210 :
                                        (330 < window.innerWidth ? 220 : 180)))))))}
                allProducts={isDummy ? Array.isArray(selectedTrendingTab.data) && selectedTrendingTab.data : Array.isArray(selectedTrendingTab) && selectedTrendingTab}
                informative={true}
                onClick={onClick}
                onDemand={true}
                isDummy={isDummy}
                type="onDemand"
                isLoggedIn={isLoggedIn}
            />
        </div>
    )
}

export default VODCustomCarousel
