import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { DummyCategoryLabel, DummyFilter } from './DummyComponent'

export default function DummyAllChannelPage({ handleChange }) {
    return (
        <React.Fragment>
            <Container className="channels-container">
                <Container className="channels-child-container">
                    <div className="channels-content" ></div>
                    <Row>
                        <Col>
                            <DummyFilter />
                            <br />
                            <br />
                        </Col>
                        <Col className="channels-info-tags-container">
                            <div className="channels-info-tags-">
                                {/* <span><i className="fa fa-history" /> TSTV</span>
                                {localStorage.jwt &&
                                    <span>
                                        <i className="fas fa-plus channels-fa-plus" ></i>
                                        A-la-carte
                                 </span>
                                } */}
                            </div>
                            <br />
                        </Col>
                    </Row>
                    <DummyCategoryLabel />
                    <DummyCategoryLabel />
                    <DummyCategoryLabel />
                    <DummyCategoryLabel />
                </Container>
            </Container>
        </React.Fragment>
    )
}

