import React from 'react'
import { Container } from 'react-bootstrap'


export const UnsubscribeModal = ({
    onClose,
    modalStyle,
    onClickNo,
    onClickYes,
    showUnsub,
    modalHeaderText,
    onClickUnsub,
    handleChange,
    buttonText,
    isForm,
    style,
    data,
    touched, error, warning, districts
}) => {
    return <div className={`modal fade ${showUnsub ? "show" : ""}`} id="exampleModalUnsub" tabIndex="-1" role="dialog" style={modalStyle}>
        <div className=".modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header-one">
                    <button type="button" className="close" data-dismiss="modal" onClick={onClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div style={{ marginTop: 40 }}>
                        <Container style={{ color: "#d0d0d0" }}>
                            <h6>Are you sure you want to unsubscribe?</h6><br/>
                        </Container>
                    </div>
                </div>
                <div className="modal-footer">
                     
                        <> <button type="button" className="btn submit-button" style={{ float: "right", width:"90px" }} onClick={onClickYes}>Yes</button>
                        <button type="button" className="btn submit-button" style={{ float: "right", width:"90px" }} onClick={onClickNo}>No</button></>
                    
                </div>
            </div>
        </div>
    </div>
}
