import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter, Link } from 'react-router-dom';
import { subscriptionSelectors,subscriptionActions } from './../subscription/ducks';
import { getConnectionType } from '../../util/ArrayValidator';
import { CustomerSelectionCard } from './../../components/DummyComponent/DummyComponent';
import { SLT_SERVICE_TYPE } from '../../util/Constants';




const styles = {
    app: {
        fontSize: 11
    },
    check: {
        color: "rgb(36,148, 188)",
        marginRight: 5
    }
}

class AuthenticationCode extends Component {
    componentDidMount() {
        const { history, subscribe } = this.props;
        var connectionType = sessionStorage.getItem('@connectionTypeName')
        var databundleIncluded = localStorage.getItem('databundleIncluded')
        if (connectionType === SLT_SERVICE_TYPE && databundleIncluded === 'true') {
            var conId = sessionStorage.getItem('@connectionID')
            this.props.getDataBundlePurchaseStatus(conId)
        }


        if (subscribe && subscribe.authCode) {
            localStorage.setItem('showAuth', 'true')
        } else {
            history.push('/')
        }

        sessionStorage.removeItem('details','@connectionID','@connectionID')
        
    }
    getMobileOperatingSystem = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "other";
    }

    render() {
        const { subscribe, dataBundlePurchaseStatusError, dataBundlePurchaseStatusLoading } = this.props;
        let user = getConnectionType()
        let sltType = sessionStorage.getItem('@connectionTypeName')
        var databundleIncluded = localStorage.getItem('databundleIncluded')
        let jwt = localStorage.jwt;

        return (
            <>
                {(dataBundlePurchaseStatusLoading && databundleIncluded === 'true') ? <CustomerSelectionCard/> :<div className="container card-page" >

                    <div className={`card col-sm-12 ${this.getMobileOperatingSystem() === "other" ? "authentication_card" : "authentication_card-mobile-devices"}`}>
                        {
                            this.getMobileOperatingSystem() === "other" && <div className="authentication_header-slot">
                                <img src={require("../../assets/images/auth-page-header.png")} alt="customBoxImage" className="authentication_header-img" />
                            </div>
                        }

                        <form className="confirm-form" onSubmit={this.onSubmit}>
                            <div class="" role="alert" style={{ color: '#fff', fontWeight: 'bold', marginBottom: 20, textAlign: 'center' }}>
                                Your account has been created successfully
                            </div>
                            {jwt == undefined && this.getMobileOperatingSystem() === "other" ? <div className="" role="alert" style={{ color: '#3ab5e7', fontWeight: 'bold', marginBottom: 20, textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                                Please verify the link sent to your email and log in to PEOTVGO selfcare portal.
                            </div> :
                                jwt == undefined && <div role="alert" className="" style={{ color: '#3ab5e7', fontWeight: 'bold', marginBottom: 20, textAlign: 'center' }}>
                                    Please verify the link sent to your
                            <br /> email and log in to PEOTVGO selfcare portal.
                         </div>
                            }
                            <h6 className="text-center" style={{ color: "#ffffff", fontWeight: "normal" }}>Please use below Validation Code to login to the PEOTVGO App from any device <br /> for the first time.</h6>
                            <div className="col-sm-10" style={{ margin: "auto", textAlign: 'center' }}>
                                <span style={{ color: "#3ab5e7", fontSize: 21, fontWeight: 'bold', fontFamily: 'SegoeUI-Bold' }}>{subscribe && subscribe.authCode}</span>
                            </div>
                            <br />
                            <div className="col-sm-10" style={{ margin: "auto", justifyContent: "space-evenly", display: "flex" }}>
                                <span style={styles.app}><i className="fas fa-check" style={styles.check}></i> Android App</span>
                                <span style={styles.app}><i className="fas fa-check" style={styles.check}></i> iOS App</span>
                                <span style={styles.app}><i className="fas fa-check" style={styles.check}></i> Web App</span>
                                {user === "1" ? null :
                                    <span style={styles.app}><i className="fas fa-check" style={styles.check}></i>TV<img src={require("../../images/icons/icon.svg")} style={{ width: "20px", marginBottom: 5 }} alt="info" />
                                    </span>
                                }
                            </div>
                            {
                                this.getMobileOperatingSystem() === "other" &&
                                <>
                                    <div className="webapp-link-container">
                                        <img src={require("../../assets/images/webapp-link.png")} alt="customBoxImage" className="webbapp-link-img" />
                                        <a href="https://webapp.peotv.com/" target="_blank" >
                                            <div className="webapp-link-text">
                                                webapp.peotv.com
                                        </div>
                                        </a>
                                    </div>
                                    <div className="webapp-link-icons">
                                        <img src={require("../../assets/images/webapp-icons.png")} alt="customBoxImage" className="webbapp-icons-img" />
                                    </div>
                                </>
                            }
                            {
                                this.getMobileOperatingSystem() === "Android" &&
                                <a href="https://play.google.com/store/apps/details?id=com.peotv.peotvgo&hl=en&gl=US" target="_blank">
                                    <div className="webapp-link-icons">
                                        <img src={require("../../assets/images/android-app-icon.png")} alt="customBoxImage" className="webbapp-icons-img" />
                                    </div>
                                </a>
                            }
                            {
                                this.getMobileOperatingSystem() === "iOS" &&
                                <a href="https://apps.apple.com/lk/app/peo-tv-go/id1458020690" target="_blank">
                                    <div className="webapp-link-icons">
                                        <img src={require("../../assets/images/ios-app-icon.png")} alt="customBoxImage" className="webbapp-icons-img" />
                                    </div>
                                </a>
                            }
                            <br />
                            {dataBundlePurchaseStatusError && sltType == "slt" && <div className="col-sm-10" style={{ margin: "auto", textAlign: 'center', color: 'red', display: "flex" }}>
                                <div >
                                    Your PEOTVGO Data Bundle has not been activated due to a technical issue. The activation process is in progress. Extremely sorry for the inconvenience caused
                            </div>
                            </div>}
                            <br />
                            {/* <div className="form-group row"> */}
                            {/* <div className="form-group row">
                                <div className=" col-lg-12" style={{ textAlign: "center" }}>
                                    <Link to={{
                                        pathname: '/authcode',
                                        state: {
                                            authPin: subscribe
                                        }
                                    }}
                                        id="submit"
                                        type="submit"
                                        className="submit-button"
                                    >Close</Link>
                                </div>

                            </div> */}
                            {user === "1" ? null : <div className="col-sm-10" style={{ marginRight: 'auto', marginLeft: 'auto' }}><div className="form-group row">
                                <p style={{ fontSize: 13, textAlign: 'center', width: '100%' }}>
                                    <img src={require("../../images/icons/icon.svg")} style={{ width: "25px", marginLeft: 20, }} alt="info" /> You need to purchase the PEOTVGO TV Box from SLTMOBITEL to enjoy this service on your TV
                                </p>
                            </div>

                            </div>}
                        </form>
                    </div>


                </div>}
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        ...subscriptionSelectors.addConnection(state)
    }
}
// function mapStateToProps(state) {
//     return {
//         authPin: state.agreement && state.agreement.authPin
//     }
// }
export default withRouter(connect(
    mapStateToProps,
    subscriptionActions
)(AuthenticationCode));