import { createStructuredSelector } from 'reselect'

const getUsernameSelector = state => state.PasswordRecovery.username
const getUsernameErrorSelector = state => state.PasswordRecovery.usernameError
const passwordSelector = state => state.PasswordRecovery.password
const passwordErrorSelector = state => state.PasswordRecovery.passwordError

export default {
    getUsername: createStructuredSelector({
        username: getUsernameSelector,
        password: passwordSelector
    }),
    getUsernameError: createStructuredSelector({
        usernameError: getUsernameErrorSelector,
        passwordError: passwordErrorSelector
    }),
}