import React from 'react'
import { Row } from 'react-bootstrap';

export const ExpiryDate = ({ expireDate }) => {
    return (
        <Row className="purchase-alert">
            <div className="purchase-alert-box">
                <span>{"This content will be accessible only till the expiry of your base package on  " }<span className="bold-date">{expireDate}</span></span>
            </div>
        </Row>
    )
}
