import React, { Component } from 'react'
class GoToPeotv extends Component {

    render() {
        return (
            <div className="container card-page" >
                <div className="card col-sm-12">
                    <div className="">
                        <div class="" role="alert" style={{ color: '#fff', marginBottom: 20, textAlign: 'center' }}>
                            Thank you for your valuable feedback and we will get back to you soon.
                        <br />
                            {/* <div class="" style={{ color: '#fff', fontWeight: 'normal', marginBottom: 20, textAlign: 'center' }}>
                                For your valuable interest.
                        <br /> */}
                                {/* <div class="" style={{ color: '#fff', marginBottom: 20, textAlign: 'center' }}>
                                We will revert when this service is ready for you. 
                                    <br />
                                </div> */}
                                <div class="" style={{ color: '#fff', marginBottom: 20, textAlign: 'center', fontSize: '14px' }}>
                                    Further details: <span style={{ textDecoration: 'underline' }} target="_blank"><a style={{ fontSize: '14px' }} href="https://www.peotv.com" >www.peotv.com</a></span>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            // </div>

        )
    }
}

export default GoToPeotv
