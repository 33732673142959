import React from 'react'

export const CustomBox= ({
   id,
   name,
   value,
   onChange,
   titleText,
   description,
   src,
   loading
}) => {
    return  <div style={{padding:15, paddingLeft: 15, height: "100%"}}>
    <div className="custom-control custom-radio" >
        <input type="radio" id={id} name={name} value={value} className="custom-control-input" onChange={onChange} defaultChecked={!value} disabled={loading}/>
        <label className="custom-control-label customer-selection-radio" htmlFor={id} disabled={loading}>{titleText}</label>
        {/* <img src={src} style={{ width: '100%' }} alt="customBoxImage"/>
        <span className="custom-control-info">{description}</span> */}
    </div>
</div>
}
