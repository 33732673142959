import React from 'react';

const CardOptions = ({isLoggedIn, isPurchased, isBundled, beforeLogin}) => {
    if(isLoggedIn || beforeLogin){
        if((!isPurchased && !isBundled)||beforeLogin){
            return(
                <i className="add-new-icon fas fa-plus"></i>
            )
        }else{
            return null
        }
    }
    else{
        return null;
    }
}

export default CardOptions;