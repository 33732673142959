import _ from "lodash";
import {
    PURCHASE_COMPLETE,
    PURCHASE_PENDING_REMOVE,
    PRODUCT_NOT_PURCHASED,
    PREPAID_SERVICE_TYPE,
    PRODUCT,
    USER,
    MOBITEL_SERVICE_TYPE,
    PURCHASE_FAILED,
    TRANSACTIONAL,
    DURATION
} from "./Constants";
import moment from "moment";
export const getIndex = (array, selectedIndex) => {

    let arrayValidation = Array.isArray(array) && array.length !== 0 && array[selectedIndex];

    return arrayValidation

}

export const validArray = (array) => {

    let arrayValidation = Array.isArray(array) && array.length !== 0 && array;

    return arrayValidation
}


//check if the specific attribute is present in the object passed.
//use the function in the following way 
//getNestedValue(objectOfTheAttribute, pass the array that needed to be found in the object)
//eg: getNestedValue(featuredChannels , ['loading'])
//eg: getNestedObject(objectNeededToBeTested, [parameter1,parameter2, indexIfArray, parameter3]);
export const getNestedValue = (recievedObj, pathArray) => {
    return pathArray.reduce((obj, key) =>
        (obj && obj[key] !== 'undefined') ? obj[key] : undefined, recievedObj);
}

//get the logged in status 
export const getLoggedInStatus = () => {
    if (!!localStorage.jwt) {
        return true
    } else {
        return false
    }
}

//get the connection Id 
export const getConnectionId = () => {
    if (!!localStorage.conID) {
        return localStorage.conID
    }
}

export const purchasedProduct = (productArray) => {

    let product = _.map(productArray, function (o) {
        if (o.isPurchased === true) return o;
    });
    product = _.without(product, undefined)
    return product
    // Remove undefines from the array
    // /product = _.without(product, undefined)

}

export const bundledProduct = (productArray) => {

    let product = _.map(productArray, function (o) {
        if (o.isBundled === true) return o;
    });
    product = _.without(product, undefined)
    return product
    // Remove undefines from the array
    // /product = _.without(product, undefined)

}


export const purchaseStatusValidation = (purchaseStatus) => {
    let productPurchased = false,
        productNotPurchased = false,
        productPendingRemove = false,
        productPurchasedFailed = false;
    if (purchaseStatus === PURCHASE_COMPLETE) {
        productPurchased = true
    }
    if (purchaseStatus === PRODUCT_NOT_PURCHASED) {
        productNotPurchased = true
    }
    if (purchaseStatus === PURCHASE_PENDING_REMOVE) {
        productPendingRemove = true
    }
    if (purchaseStatus === PURCHASE_FAILED) {
        productPurchasedFailed = true
    }
    return {
        productPurchased,
        productNotPurchased,
        productPendingRemove,
        productPurchasedFailed
    }
}

export const getServiceType = () => {
    if (localStorage.serviceType === PREPAID_SERVICE_TYPE) {
        return PREPAID_SERVICE_TYPE
    } else if (localStorage.serviceType === MOBITEL_SERVICE_TYPE) {
        return MOBITEL_SERVICE_TYPE
    } else {
        return false
    }
}


//get the connectionType
export const getConnectionType = () => {
    let connectionType = sessionStorage.getItem('@connectionType')
    if (!!connectionType) {
        return connectionType
    }
}

//get the connectionId => registration flow
export const getRegistrationConnectionId = () => {
    let connectionType = sessionStorage.getItem('@connectionID')
    if (!!connectionType) {
        return connectionType
    }
}

//get the selected purchase option amount
export const getPurchaseOptionAmount = (purchaseOptions, selectedPurchaseOptionID) => {
    let amount = 0
    purchaseOptions && purchaseOptions.map((purchaseOption, i) => {
        if (purchaseOption.id === selectedPurchaseOptionID) {
            amount = purchaseOption.defaultPrice
        }
        return 0
    })
    return amount

}

//calculate duration
export const getDuration = (amount) => {


    return amount

}


//get price of a product or a discount
export const getProductPrice = (price) => {
    let productPrice = parseFloat(getNestedValue(price, ["discountOptionGroup", "purchasePrice"])).toFixed(2);

    return productPrice;
}
export const getBasepackage = () => {
    if (!!localStorage.basepackage) {
        return localStorage.basepackage
    }
}


//get service type after login --> add new service type if introduced
export const getServiceTypeName = (serviceName) => {
    if (serviceName && serviceName.includes("PEO")) {
        return "peotv"
    } else if (serviceName && serviceName.includes("Prepaid")) {
        return "prepaid"
    } else if (serviceName && serviceName.includes("Mobitel")) {
        return "mobitel"
    } else {
        return "slt"
    }
}
export const taxCalculation = (price) => {
    return price += price * 0.08
}


//sort the tab on vods
export const sortTabOrderVods = (arr) => {
    let exactOrder = ["PremiereMovies", "MVOD", "TF", "MUSIC", "EOD", "PEOOriginals", "HelawoodPopcorn", "PEOTunes", "Dishes", "Handmade", "SF", "STD", "Highlights"];
    let result = []
    exactOrder.forEach(function (key) {
        var found = false;
        arr = arr.filter(function (item) {
            if (!found && item.typeName === key) {
                result.push(item);
                found = true;
                return false;
            } else
                return true;
        })
    })
    return result;
}


//sort the vod array list for the movies to come first
export const sortVodsForMovies = (data) => {
    let moviesArr = [];
    let otherProductsArr = [];
    data && data.map((item, index) => {
        if (item.productTypeTypeName === "vod") {
            if (item.productSubTypeTypeName === "MVOD") {
                moviesArr.push(item)
            } else {
                otherProductsArr.push(item)
            }
        }
        return false
    })
    return moviesArr.concat(otherProductsArr);
}

export const loadAfter = (loadedTime) => {
    let diff = moment(new Date())
        .diff(moment(loadedTime), 'minutes')
    console.log(diff);
    if(diff>DURATION){
        return true
    }
}


export const isDiscounted = (data) => {
    if (data && data.discountOptionGroup && data.discountOptionGroup.discountOptions && Array.isArray(data.discountOptionGroup.discountOptions) && data.discountOptionGroup.discountOptions.length !== 0 && data.purchaseStatus !== "PURCHASE_COMPLETE") {
        return true
    } else {
        return false
    }

}

//check which discount to be applied first
export const discountType = (productDetails) => {
    let discountLevelProduct, discountLevelUser, discount, productDiscountType, productDiscountValue, userDiscountType, userDiscountValue;

    if (productDetails && productDetails.discountOptionGroup && productDetails.discountOptionGroup.discountOptions) {
        discountLevelProduct = productDetails.discountOptionGroup && productDetails.discountOptionGroup.discountOptions.filter(productDetails => productDetails.discountLevel === PRODUCT);
        discountLevelUser = productDetails.discountOptionGroup && productDetails.discountOptionGroup.discountOptions.filter(productDetails => productDetails.discountLevel === USER);
    }
    //if both discount options are available
    if ((discountLevelProduct && discountLevelProduct[0]) && (discountLevelUser && discountLevelUser[0])) {
        //check if the discount product level is less than discount user level
        if (Date.parse(moment(discountLevelProduct[0].start).format()) < Date.parse(moment(discountLevelUser[0].start).format())) {
            productDiscountType = discountLevelProduct[0].discountType;
            productDiscountValue = discountLevelProduct[0].value;
            if (productDiscountType === "AMOUNT") {
                discount = `Rs.${productDiscountValue} OFF`
            } else {
                discount = `${productDiscountValue}% OFF`
            }
        } else {
            userDiscountType = discountLevelUser[0].discountType;
            userDiscountValue = discountLevelUser[0].value;
            if (userDiscountType === "AMOUNT") {
                discount = `Rs.${userDiscountValue} OFF`
            } else {
                discount = `${userDiscountValue}% OFF`
            }
        }
    } else if (discountLevelProduct && discountLevelProduct[0]) {
        productDiscountType = discountLevelProduct[0].discountType;
        productDiscountValue = discountLevelProduct[0].value;
        if (productDiscountType === "AMOUNT") {
            discount = `Rs.${productDiscountValue} OFF`
        } else {
            discount = `${productDiscountValue}% OFF`
        }
    } else if (discountLevelUser && discountLevelUser[0]) {
        userDiscountType = discountLevelUser[0].discountType;
        userDiscountValue = discountLevelUser[0].value;
        if (userDiscountType === "AMOUNT") {
            discount = `Rs.${userDiscountValue} OFF`
        } else {
            discount = `${userDiscountValue}% OFF`
        }
    }
    return discount;
}

//get the page footer to bottom on specific pages
export const pageFooterClass = (route) => {
    if (route.includes('products')) {
        return "page-footer-fixed"
    } else {
        return null
    }
}

//get the srilankan region
export const getRegion = (array) => {
    const found = array && array.some(region => region.code === "LK");
    if (found) {
        return true
    } else {
        return false
    }
}

export const getUtilizationPeriod = (productDetails, utilizationPeriodUnit) => {
    let unit = null
    let utilizationPeriod = getNestedValue(productDetails && productDetails.purchaseOption, ['utilization', 'utilizationPeriod'])
    if (productDetails && productDetails.purchaseOption && productDetails.purchaseOption.purchaseType === TRANSACTIONAL) {
        if (utilizationPeriod === 1) {
            unit = utilizationPeriod + " " + _.startCase(_.toLower(utilizationPeriodUnit.substring(0, utilizationPeriodUnit.length - 1)))
            return `(${unit})`
        } else {
            unit = utilizationPeriod + " " + _.startCase(_.toLower(utilizationPeriodUnit))
            return `(${unit})`
        }
    } else {
        if (utilizationPeriod === 1) {
            if (utilizationPeriodUnit === "MONTHS") {
                return "(Monthly)"
            } else {
                return "(Daily)"
            }
        } else {
            return "(Monthly)"
        }
    }




}

export const getTotalScreens = (productList) => {

    var newArray = _.filter(productList, function (el) {
        return el.productTypeName === "Screens" && (el.isBundled || el.purchaseStatus === PURCHASE_COMPLETE)
    });
    var sum = newArray.reduce((a, { numberOfScreens }) => a + numberOfScreens, 0);
    return sum

}
//Filter banners on before and after login
export const bannerRedirection = (obj) => {
    let filteredArray = []
    //check login status
    if (!getLoggedInStatus()) {
        obj && obj.forEach((item) => {
            //check for product id , purchaseOptionId and redirection URL
            if ((_.isEmpty(item.productId) && _.isEmpty(item.purchaseOptionId)) || !_.isEmpty(item.redirectURL)) {
                filteredArray.push(item)
            }
        })
        return filteredArray
    } else {
        return obj
    }
}

export const getUniqueData = (array, propertyName) => {
    return array.filter((e, i) => array.findIndex(a => a[propertyName] === e[propertyName]) === i);
}