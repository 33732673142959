import React from 'react'
import { CustomButton } from '../controls/CustomButton'

export const PaymentSuccess = ({ loading, onClickContinue, subscribe, connectionType }) => {
    let basePackage = JSON.parse(localStorage.getItem('basepackagePurchased'))

    return <div className="mobitel-payment-success"><div className="row" style={{
        color: "#fff", display: "flex",
        justifyContent: "center",width:'100%'
    }}>
        <ion-icon
            name="checkmark-circle-outline"
            size="large"
            style={{
                color: "#4a8900",
                size: "large"
            }}
        ></ion-icon>
    </div>

        <div className="row pament-status" >Payment Successful</div>
        <div className="row pament-status" >{connectionType==="6"? basePackage && basePackage.name  : subscribe&& subscribe.productName}</div>
        <div className="row pament-amount">Rs. {connectionType==="6"? basePackage && basePackage.price : subscribe && subscribe.purchasedPrice} +tax</div>
        {connectionType==="6" && <div className="row payment-info">Using your Mobitel Connection {sessionStorage.msisdn}</div>}
        {connectionType==="7" && <div className="row payment-info">Using your {subscribe && subscribe.paymentMethod} {subscribe && subscribe.cardDetails}</div>}
        <div className="row payment-info">Reference No : {subscribe && subscribe.referenceNo}</div>
        {(subscribe.status==="PENDING"||subscribe.status==="FAILURE") && <div className="row package-provisioning-error">{subscribe && subscribe.description}</div>}
        {(subscribe.status!=="PENDING" && subscribe.status!=="FAILURE")  &&<div className="row" style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}><CustomButton id="accountlogin" text="CONTINUE" disabled={loading} loading={loading} onClick={onClickContinue} /></div>}
    </div>
}
