// Reducers: combine all reducers of the app
import { combineReducers } from 'redux'
import { reducer as reduxFormReducer } from 'redux-form'

import login from './modules/login/ducks'
import generalPages from './modules/generalPages/ducks'
import subscription from './modules/subscription/ducks'
import products from './modules/landingPage/ducks'
import baseProducts from './modules/productPage/ducks'
import svod from './modules/svodsPage/ducks';
import passwordRecovery from './modules/passwordRecovery/ducks'
import allProducts from './modules/AllProducts/ducks'
import singleProduct from './modules/ProductContent/ducks';
import registration from './modules/registration/ducks'
import profile from './modules/profile/ducks';
import promotions from './modules/promotions/ducks';

export default combineReducers({
    form: reduxFormReducer,
    Login: login,
    GeneralPages: generalPages,
    Subscription: subscription,
    Products: products,
    BaseProducts: baseProducts,
    Svod: svod,    
    PasswordRecovery: passwordRecovery,
    AllProducts: allProducts,
    ProductDetails: singleProduct,
    Registration : registration,
    Profile: profile,
    Promotions: promotions
})
