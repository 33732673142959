import React from 'react';
import { Col } from 'react-bootstrap';
import SvodPackage from '../Svod/SvodPackage'; //import from landing page in modules can be refactored from the new card component
import DummyFeaturedSvod from '../DummyComponent/DummyFeaturedSvod';
import { purchaseStatusValidation, getProductPrice, getNestedValue, isDiscounted, discountType } from '../../util/ArrayValidator';
import { PAYMENT_SUCCESS } from '../../util/Constants'
const FeaturedSvodsContent = ({ featuredSvods, onClick }) => {
  if (featuredSvods && featuredSvods.loading) {
    return (
      <DummyFeaturedSvod />
    )
  }
  if (featuredSvods && featuredSvods.featuredSvods && Array.isArray(featuredSvods.featuredSvods)) {
    return featuredSvods.featuredSvods.slice(0, 3).map(featuredSvod => {
      if (featuredSvod.status === "PRODUCT_STATUS_ACTIVE") {
        let purchaseTypeStatus = purchaseStatusValidation(featuredSvod.purchaseStatus);
        let price = getNestedValue(featuredSvod, ["purchaseOption","defaultPrice"]);
        let discount = discountType(featuredSvod)
        return <Col key={featuredSvod.id} xs={12} md={6} lg={4}>
          <SvodPackage
            id={featuredSvod.id}
            name={featuredSvod.name}
            description={featuredSvod.description}
            price={getProductPrice(featuredSvod)}
            isBundled={featuredSvod.isBundled}
            onClick={onClick}
            imageURL={featuredSvod.thumbnailURL}
            isPurchased={(purchaseTypeStatus.productPurchased || purchaseTypeStatus.productPendingRemove || featuredSvod.paymentStatus === PAYMENT_SUCCESS) ? true : false}
            showAddNew={(price === undefined || price === 0 ) ? false : true}
            hasDiscount={isDiscounted(featuredSvod)}
            discount={discount}
          />
        </Col>
      }
      return null
    })
  } else {
    return null
  }
}

export default FeaturedSvodsContent;