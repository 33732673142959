import { createStructuredSelector } from 'reselect'

const addConnectionSelector = state => state.Subscription.subscribe
const addConnectionErrorSelector = state => state.Subscription.error
const productsSelector = state => state.Subscription.products
const productsErrorSelector = state => state.Subscription.productsError
const secondaryProductsSelector = state => state.Subscription.secondaryProducts
const secondaryProductsErrorSelector = state => state.Subscription.secondaryProductsError
const secondaryAllowedProductsErrorSelector = state => state.Subscription.secondaryAllowedProductsError
const secondaryAllowedProductSelector = state => state.Subscription.secondaryAllowedProducts
const productsLoadingSelector = state => state.Subscription.productsLoading
const getProductTypesSelector = state => state.Subscription.allPackageTypes

const getBaseProductsSelector = state => state.Subscription.baseProducts
const getAllProductsLoadingSelector = state => state.Subscription.baseProductsLoading
const getDataBundle = state => state.Subscription.dataBundle

const retryPaymentLoadingSelector = state => state.Subscription.retryLoading
const retryPaymentSelector = state => state.Subscription.retryPayment
const retryPaymentErrorSelector = state => state.Subscription.retryError
const getPackagePaymentStatus = state => state.Subscription.status
const getPackagePaymentStatusError = state => state.Subscription.statusError
const getPackagePaymentStatusLoading = state => state.Subscription.statusLoading

const getSubscribeLoading = state => state.Subscription.subscribeLoading
const getPaymentDetails = state => state.Subscription.paymentDetails

const getfilteredPackages = state => state.Subscription.filteredPackages

const getConsent = state => state.Subscription.consent
const getConsentValidation = state => state.Subscription.consentValidation
const getConsentValidationFailed = state => state.Subscription.consentValidationError

const getDataBundlePurchaseStatusLoading = state => state.Subscription.dataBundlePurchaseStatusLoading
const getDataBundlePurchaseStatusSuccess = state => state.Subscription.dataBundlePurchaseStatus
const getDataBundlePurchaseStatusFailed = state => state.Subscription.dataBundlePurchaseStatusError

export default {
    addConnection: createStructuredSelector({
        subscribe: addConnectionSelector,
        products: productsSelector,
        productsLoading: productsLoadingSelector,
        secondaryProducts: secondaryProductsSelector,
        secondaryAllowedProducts: secondaryAllowedProductSelector,
        allPackageTypes: getProductTypesSelector,
        baseProducts: getBaseProductsSelector,
        baseProductsLoading: getAllProductsLoadingSelector,
        dataBundle: getDataBundle,
        status: getPackagePaymentStatus,
        statusLoading: getPackagePaymentStatusLoading,
        subscribeLoading: getSubscribeLoading,
        paymentDetails: getPaymentDetails,
        filteredPackages: getfilteredPackages,
        consent: getConsent,
        consentValidationError: getConsentValidationFailed,
        consentValidation: getConsentValidation,
        dataBundlePurchaseStatus: getDataBundlePurchaseStatusSuccess,
        dataBundlePurchaseStatusLoading: getDataBundlePurchaseStatusLoading,
        dataBundlePurchaseStatusError: getDataBundlePurchaseStatusFailed

    }),
    addConnectionError: createStructuredSelector({
        error: addConnectionErrorSelector,
        productsError: productsErrorSelector,
        secondaryProductsError: secondaryProductsErrorSelector,
        secondaryAllowedProductsError: secondaryAllowedProductsErrorSelector,
        statusError: getPackagePaymentStatusError
    }),
    retryPayment: createStructuredSelector({
        retry: retryPaymentSelector,
        retryLoading: retryPaymentLoadingSelector,
        retryError: retryPaymentErrorSelector
    })
}