import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';

class AlreadyRegisteredMessage extends Component {

    componentDidMount(){}

    render() {
        return (
            <div className="container card-page">
                <div class="card">
                    <p class="text-center" style={{ color: "#ffffff" }}>Email address {this.props.profileError} has already been registered with PEOTVGO. </p><br />
                    <p class="text-center" style={{ color: "#ffffff" }}>Please <a href="/login" style={{
                        fontSize: "inherit", textDecoration: 'underline', color: "#f8921a"
                    }}> login</a> and use the add connection functionality to add more connections to the existing account or retry registering with a different email address.
                    </p><br />
                    {/* <div class="btn-group">
                        <Link to="/personaldetails" className="form-control submit-button">Back</Link>
                        <Link to="/" className="form-control submit-button">Selfcare</Link>
                    </div> */}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { profile } = state
    return {
        profileError: profile && profile.error
    }
}

export default withRouter(connect(
    mapStateToProps,
    null
)(AlreadyRegisteredMessage));
