import React from 'react'
import { Container, Row } from 'react-bootstrap';
import FeaturedOnDemandContent from '../LandingPageComponents/FeaturedOnDemandContent';
import OnDemandSubTypes from '../OnDemandSubTypes/OnDemandSubTypes';
import ViewAllButton from '../ViewAllButton/ViewAllButton';
import { AllOnDemand, DEFAULT_ONDEMAND_SUBTYPE } from "../../util/Constants";
import NoProductFound from '../../components/NoProductAvailable/NoProductAvailable';

function FeaturedOnDemand({ onDemandContents, onClickOndemand, isLoggedIn, renderCenterLeftControls, renderCenterRightControls }) {
    return (
        <Container>
            <div className='landing-page-carousel'>
                <h4 className="title">On Demand Content</h4>
                <div
                    className="row on-demand-content-row"
                >
                    <OnDemandSubTypes onDemandContents={onDemandContents} />
                </div>
                <div className="justify-content-space-evenly">
                    <FeaturedOnDemandContent onDemandContents={onDemandContents} onClick={onClickOndemand} renderCenterLeftControls={renderCenterLeftControls} renderCenterRightControls={renderCenterRightControls} isLoggedIn={isLoggedIn} />
                    {onDemandContents && onDemandContents.onDemandContents && Array.isArray(onDemandContents.onDemandContents) &&
                        onDemandContents.onDemandContents.length === 0 && !onDemandContents.loading ?
                        <NoProductFound />
                        :
                        null
                    }
                </div>
                <Row>
                    <ViewAllButton path={`${AllOnDemand}/#${DEFAULT_ONDEMAND_SUBTYPE}`} className="btn-round" btnText='View All' />
                </Row>
            </div>
        </Container>
    )
}

export default FeaturedOnDemand
