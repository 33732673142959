import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "../../components/Slider/Slider";
import history from "./../../_helpers/history";
import { productActions, productSelectors } from "./ducks";
import './LandingPage.scss';
import FeaturedChannels from '../../components/FeaturedContent/FeaturedChannels';
import FeaturedOnDemand from '../../components/FeaturedContent/FeaturedOnDemand';
import FeaturedSvods from '../../components/FeaturedContent/FeaturedSvods';
import FeaturedPackage from "../../components/FeaturedContent/FeaturedPackage";
import { PREPAID_SERVICE_TYPE, SingleProductPage, TRANSACTIONAL, MOBITEL_SERVICE_TYPE, PURCHASE_COMPLETE, CHANNEL_ID, CHANNEL_BUNDLE_ID } from '../../util/Constants';
import { getNestedValue, validArray, getLoggedInStatus, getConnectionId, purchasedProduct, getIndex, taxCalculation, getServiceType, getTotalScreens, bannerRedirection } from '../../util/ArrayValidator';
import AccountSuspended from '../../components/SuspendedState/AccountSuspended';
import DummySlider from "../../components/DummyComponent/DummySlider";
import AccountUnsubscribed from '../../components/SuspendedState/AccountUnsubscribed'
import SuspendedProducts from '../SuspendedProducts/SuspendedProducts';
import { SUBSCRIPTIONAL, IS_SANDBOX } from '../../util/Constants';
import { CustomButton } from '../../components/controls/CustomButton';
class LandingPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedIndex: 0,
    }
  }

  componentDidMount() {
    this.props.getOnDemandSubTypes("5d22e60422224e2be4ad4046");

    if (getLoggedInStatus()) {
      this.initialLoading();

      // if (bannerContent.bannerContent == null) {
      //   this.props.getBannerContent(connId);
      // }
    } else {
      //get data for public landing page
      const { featuredChannels, onDemandContents, featuredSvods, bannerContent } = this.props
      this.props.getProductTypes();
      this.props.getSecondaryId()
      if (featuredChannels.featuredChannels == null) {
        this.props.getFeaturedChannels();
      }
      if (onDemandContents.onDemandContents == null) {
        this.props.getOnDemandContents();
      }
      if (featuredSvods.featuredSvods == null) {
        this.props.getFeaturedSvods();
      }
      if (bannerContent.bannerContent == null) {
        this.props.getBannerContent();
      }
    }
  }

  componentDidUpdate(prevProps) {

    if ((this.props.featuredPackages.secondaryAllowedProductsLoading) && (this.props.featuredPackages.secondaryAllowedId) && (Array.isArray(this.props.featuredPackages.secondaryAllowedId))) {
      this.props.getAllowedSecondaryPackages({ idList: this.props.featuredPackages.secondaryAllowedId });
    }
    if (!this.state.reactivate && !this.state.isProvisioningData && !this.props.featuredPackages.packagePurchaseStatus && !this.props.featuredPackages.packagePurchaseStatusLoading && localStorage.connectionStatus !== "suspended" && localStorage.connectionStatus !== "pending_terminated" && getLoggedInStatus()) {
      this.initialLoading()
    }
    if (this.props.reactivateProductDetails && this.state.showPayhere) {
      this.payhere()
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.bannerContent && nextProps.bannerContent !== prevState.bannerContent) {
      return ({
        bannerContent: nextProps.bannerContent
      })
    }
  }

  initialLoading = () => {
    const { featuredChannels, featuredPackages, onDemandContents, featuredSvods } = this.props;
    let connId = localStorage.conID;
    if (this.state.reactivate) {
      this.props.getUserDetails()
      this.props.getConnectionContent(getConnectionId());
      this.props.getConnectionDetails({ connectionId: getConnectionId() })
    }
    if (localStorage.connectionStatus !== "pending_terminated" && localStorage.connectionStatus !== "suspended") {
      if (!this.props.featuredPackages.packagePurchaseStatus && !this.props.featuredPackages.packagePurchaseStatusLoading) {
        this.setState({ isProvisioningData: true })
      }
      //get featured data for the user when logged in
      if (featuredChannels.featuredChannels == null) {
        this.props.getFeaturedChannels({
          connectionId: connId,
          tagId: "5d22e08b22224e2be4ad4042",
          typeId: [CHANNEL_ID, CHANNEL_BUNDLE_ID]
        })
      }
      if (onDemandContents.onDemandContents == null) {
        this.props.getOnDemandContents({
          connectionId: connId,
          tagId: "5d22e08b22224e2be4ad4042",
          typeId: "5d22e60422224e2be4ad4046"
        })
      }
      if (featuredSvods.featuredSvods == null) {
        this.props.getFeaturedSvods({
          connectionId: connId,
          tagId: "5d22e08b22224e2be4ad4042",
          typeId: "5d2e005cacd856260c231b01"
        })
      }
    }
    if (featuredPackages.featuredPackages == null) {

      this.props.getAllowedSecondaryPackages({
        connectionId: connId,
        typeId: "5d22eda922224e2be4ad4047"
      })
      let isBroadband = localStorage.getItem('isBroadBand')

      setTimeout(
        () => isBroadband === "true" && this.props.getPurchasedPrimaryDataBundle({
          connectionId: connId,
          typeId: "5d444c40ee6797225cdead4b"
        }),
        3000
      );

    }
  }
  onClick = (e, isChannelBundle) => {
    this.setState({ show: true, selectedImage: e });
    if (isChannelBundle) {
      history.push(`/channel-bundle/${e}`)
    } else {
      history.push(`${SingleProductPage}/${e}/false`)
    }
  };

  onClickOndemand = (e) => {
    history.push(`${SingleProductPage}/${e.target.id}`)
  }

  onClickPackage = (e) => {
    history.push(`/packages/#${e.target.id}`)
  }
  onClickSvod = (e) => {
    history.push(`/svod/${e.target.id}`)
  }
  SampleNextArrow(props) {
    const { nextSlide } = props;
    return (
      <div onClick={nextSlide}>
        <img src={require("../../assets/images/icons/next-white.svg")} className='arrow-next-style' alt='' />
      </div>
    );
  }

  SamplePrevArrow(props) {
    const { previousSlide } = props;
    return (
      <div onClick={previousSlide} >
        <img src={require("../../assets/images/icons/back-white.svg")} className='arrow-prev-style' alt='' />
      </div>
    );
  }

  getActiveStatus = (connectionContent, selectedConnection) => {
    let activeStatus, serviceType
    connectionContent && connectionContent.map((connectionData, index) => {
      if (connectionData.connectionId === parseInt(selectedConnection)) {
        if (connectionData.status === "active") {
          activeStatus = "active";
          serviceType = connectionData.serviceType;
        } else if (connectionData.status === "pending_terminated") {
          activeStatus = "pending_terminated";
          serviceType = connectionData.serviceType;
        }
        else {
          activeStatus = "suspended";
          serviceType = connectionData.serviceType;
        }
      }
      return null
    })
    return { activeStatus, serviceType }
  }
  onClickReactivate = () => {
    const { packagePurchaseTable } = this.props
    var user = localStorage.conID
    var basepackagePurchaseType = localStorage.getItem('basepackagePurchaseType')
    if (basepackagePurchaseType === TRANSACTIONAL) {
      let serviceType = getServiceType() === PREPAID_SERVICE_TYPE ? "7" : "6"
      let connectionTypeName = getServiceType() === PREPAID_SERVICE_TYPE ? "prepaid" : "mobitel"
      let msisdn = localStorage.userTelephoneNumber
      sessionStorage.setItem('@connectionType', serviceType)
      sessionStorage.setItem("@connectionTypeName", connectionTypeName)
      sessionStorage.setItem('@connectionID', user)
      sessionStorage.setItem('msisdn', msisdn)
      localStorage.removeItem('connectionStatus')


      history.push('/viewpackage')

    } else {
      sessionStorage.clear()
      let products = getIndex(this.props.featuredPackages.secondaryAllowedProducts, 0)
      let serviceType = getServiceType()
      this.setState({ reactivate: true })
      // this.payhere()
      this.props.reactivateProduct({
        "userConnectionId": user,
        "productTypeId": products.productTypeId,
        "purchaseOptionId": packagePurchaseTable[0].id,
        "productId": products.id,
        "currency": "LKR",
        "userConnectionType": serviceType === PREPAID_SERVICE_TYPE ? "prepaid" : "mobitel",
        "userId": localStorage.userId,
        "amount": packagePurchaseTable[0].defaultPrice,
        // products.purchaseOption.defaultPrice
      })
      if (serviceType === PREPAID_SERVICE_TYPE) {
        setTimeout(
          () => this.setState({ showPayhere: true }),
          3000
        );
      }
      // products.purchaseOption.id
    }

  }
  payhere = (data) => {
    debugger

    this.setState({ showPayhere: false })
    const { connectionDetails, reactivateProductDetails, userDetails, connectionContent } = this.props;

    // let selectedPurchaseType;
    // selectedPurchaseType = purchaseOptions.filter(option => option.id === this.state.purchaseOption)

    let details = reactivateProductDetails
    let email = userDetails.email
    let firstName, lastName
    let payableAmount = taxCalculation(details.amount)
    if (connectionContent && connectionContent.profiles[0] && connectionContent.profiles[0].name) {
      let splitData = connectionContent && connectionContent.profiles[0] && connectionContent.profiles[0].name.split(" ");
      firstName = splitData[0];
      lastName = splitData[1];
    }
    var payment = {
      "sandbox": IS_SANDBOX,
      "merchant_id": details.merchantId,       // Replace your Merchant ID
      "return_url": details.returnUrl,
      "cancel_url": details.cancelUrl,
      "notify_url": details.notifyUrl,
      "items": details.purchaseItemName,
      "amount": payableAmount,
      "currency": "LKR",
      "first_name": firstName,
      "last_name": lastName,
      "email": email,
      "phone": connectionDetails.telephoneNumber,
      "address": "",
      "city": "",
      "country": "",
      recurrence: details.recurrence,
      duration: details.duration,
      order_id: details.referenceNumber
    };

    // if (selectedPurchaseType && selectedPurchaseType[0] && selectedPurchaseType[0].purchaseType === SUBSCRIPTIONAL) {

    // }


    window.payhere.onCompleted = (orderId) => {

      console.log("Payment completed. OrderID:" + orderId)
      this.getPurchaseSatus(details.transactionId)
      //Note: validate the payment and show success or failure page to the customer
    };

    // Called when user closes the payment without completing
    window.payhere.onDismissed = () => {
      //Note: Prompt user to pay again or show an error page
      console.log("Payment dismissed");


      this.getPurchaseSatus(details.transactionId)

    };

    // Called when error happens when initializing payment such as invalid parameters
    window.payhere.onError = (error) => {
      // Note: show an error page
      console.log("Error:" + error);
      // this.getPurchaseSatusDissmissed()
    };

    window.payhere.startPayment(payment);
  }

  getPurchaseSatus = () => {

    const { reactivateProductDetails } = this.props
    this.props.verifyPrepaidPayment({ transactionId: reactivateProductDetails.transactionId, productId: reactivateProductDetails.purchaseItemId })

  }

  render() {
    const isLoggedIn = getLoggedInStatus();
    const { featuredChannels, featuredPackages, onDemandContents, featuredSvods, connectionDetails, reactivateProductError, reactivateProductLoading, reactivateProductDetails } = this.props;
    const { bannerContent } = this.state

    let connectionStatus = this.getActiveStatus(connectionDetails, getConnectionId());

    let purchasedPrimaryDataBundle = getNestedValue(featuredPackages, ['purchasedPrimaryDataBundle']);

    let numberOfScreensBundled = getTotalScreens(featuredPackages.secondaryAllowedProducts && validArray(featuredPackages.secondaryAllowedProducts) && featuredPackages.secondaryAllowedProducts[0].productList)

    //check the nmber of screens user purchased 
    let numberOfScreensPurchased = getTotalScreens(featuredPackages.secondaryAllowedProducts && validArray(featuredPackages.secondaryAllowedProducts) && featuredPackages.secondaryAllowedProducts[0].purchasableProductList)
    let dataBundlesPurchased = false
    //check if user has purchased a databundle
    if (purchasedPrimaryDataBundle && purchasedPrimaryDataBundle.purchaseStatus === PURCHASE_COMPLETE) { dataBundlesPurchased = true }
    //check whether availability of redirection urls
    let bannersAvailable = bannerRedirection(bannerContent.bannerContent)
    //render banner component
    let bannerComponent
    if (bannerContent.loading) {
      bannerComponent = <DummySlider />
    } else {
      if (bannerContent.bannerContent && bannerContent.bannerContent.length !== 0 && bannersAvailable) {
        bannerComponent = <Slider bannerContent={bannerContent.bannerContent} bannerContentLoading={bannerContent.loading} />
      } else {
        bannerComponent = null
      }
    }

    let basepackagePurchaseType = localStorage.getItem('basepackagePurchaseType')
    let billingEndDate = isLoggedIn && featuredPackages && featuredPackages.secondaryAllowedProducts && featuredPackages.secondaryAllowedProducts[0] && featuredPackages.secondaryAllowedProducts[0].billingEndDate

    //check of the connection is in active status and display relevant views
    if (connectionStatus.activeStatus === "suspended") {
      let serviceType = getServiceType()
      if (serviceType && featuredPackages.packagePurchaseStatus && featuredPackages.packagePurchaseStatus.status === "FAILURE" && featuredPackages.packagePurchaseStatus.paymentStatus === "SUCCESS") {
        return <div className="landing-provisioning">
          <AccountSuspended packageloading={true} />
        </div>
      }
      else {
        return (
          <>
            <AccountSuspended serviceType={serviceType} onClickReactivate={this.onClickReactivate} error={reactivateProductError || (reactivateProductDetails && reactivateProductDetails.status === "FAILURE" && reactivateProductDetails.description) || featuredPackages.verifyPaymentError} loading={reactivateProductLoading || featuredPackages.verifyPaymentLoading} disable={featuredPackages.secondaryAllowedProductsLoading} status={(reactivateProductDetails && reactivateProductDetails.status === "SUCCESS" && reactivateProductDetails.description) || featuredPackages.verifyPayment} basepackagePurchaseType={basepackagePurchaseType} packageloading={featuredPackages.secondaryAllowedProductsLoading || featuredPackages.packagePurchaseStatusLoading} />
            {basepackagePurchaseType === SUBSCRIPTIONAL && <><SuspendedProducts name="Package Name " products={featuredPackages.secondaryAllowedProducts} loading={featuredPackages.secondaryAllowedProductsLoading || reactivateProductLoading} packageStatus={connectionStatus.serviceType === MOBITEL_SERVICE_TYPE ? reactivateProductDetails && reactivateProductDetails.status : featuredPackages.verifyPayment && "SUCCESS"} />
              {(featuredPackages.verifyPayment || (reactivateProductDetails && reactivateProductDetails.status === "SUCCESS")) && <CustomButton text="CONTINUE" style={{ margin: 'auto' }} onClick={this.initialLoading} />}
              {/* {reactivateProductDetails && reactivateProductDetails.status === "SUCCESS" && <CustomButton text="CONTINUE" style={{ margin: 'auto' }} onClick={this.initialLoading} />} */}
            </>}
          </>
        );
      }
    } else if (connectionStatus.activeStatus === "pending_terminated") {
      return (
        <>
          {/* <Slider bannerContent={banners} /> */}
          <AccountUnsubscribed billingEndDate={billingEndDate} />
        </>
      );
    }
    else if (connectionStatus.activeStatus === "unsubscribed") { // TODO : Put the correct status
      return (<>
        <AccountUnsubscribed />
      </>
      );
    } else {
      return (
        <>
          {bannerComponent}
          {bannerContent.bannerContent && bannerContent.bannerContent.length !== 0 && bannersAvailable && <Link to="/promotions" className="promotion-button">
            <i className="fa fa-external-link-alt" style={{ marginRight: 5 }} />
            VIEW PROMOTIONS
          </Link>}
          <FeaturedChannels featuredChannels={featuredChannels} onClick={this.onClick} showDate={false} isLoggedIn={isLoggedIn} channelProduct={true} informative={true} renderCenterRightControls={this.SampleNextArrow} renderCenterLeftControls={this.SamplePrevArrow} />
          <FeaturedPackage isLoggedIn={isLoggedIn} connectionDetails={connectionDetails} featuredPackages={featuredPackages} featuredChannels={featuredChannels} numberOfScreensBundled={numberOfScreensBundled} numberOfScreensPurchased={numberOfScreensPurchased} dataBundlesPurchased={dataBundlesPurchased} onClickPackage={this.onClickPackage} />
          <FeaturedOnDemand onDemandContents={onDemandContents} onClickOndemand={this.onClickOndemand} renderCenterLeftControls={this.SamplePrevArrow} renderCenterRightControls={this.SampleNextArrow} isLoggedIn={!!localStorage.jwt} />
          <FeaturedSvods featuredSvods={featuredSvods} onClickSvod={this.onClickSvod} />
        </>
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    productTypes: productSelectors.getProductTypes(state),
    featuredChannels: productSelectors.getFeaturedChannels(state),
    featuredPackages: productSelectors.getFeaturedPackages(state),
    onDemandContents: productSelectors.getOnDemandContents(state),
    featuredSvods: productSelectors.getFeaturedSvods(state),
    bannerContent: productSelectors.getBannerContent(state),
    userDetails: productSelectors.getDetails(state),
    selectedChannelIndex: state.Products.selectedChannelIndex,
    ...productSelectors.getDetails(state),
    allVODs: state.Profile.myVODs,
    additionalChannels: state.AllProducts.allUserChannels,
    allSvods: state.Profile.mySVODs,
    ...productSelectors.getReactivationDetails(state)
  };
};

export default connect(
  mapStateToProps,
  productActions
)(LandingPage);
