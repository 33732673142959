import React from 'react'
import { MOBITEL_HEADER_ENRICHMENT } from '../../util/Constants'

export const ErrorMessage = ({
    errorStatus,
    errorMessage,
    style,
    className,
    mobitelError,
    onClick
}) => {
    return (
       <div>
            {errorStatus && !mobitelError&&
                <div className={className|| "alert alert-danger"} role="alert" style={style}>
                    {errorMessage}
                </div>
            }
            {mobitelError&& <div className={className|| "alert alert-danger"} role="alert" style={style}>
                    {mobitelError + " is already subscribed to PEOTVGO. Please change your Mobitel Connection or try "}<a onClick={onClick} style={{color: "#721c24", textDecoration: "underline", cursor:'pointer'}}>another option</a>
                </div>}
        </div>
    )
}