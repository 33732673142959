import React, { Component } from 'react'
import { Carousel } from 'react-bootstrap'
import { getLoggedInStatus } from '../../util/ArrayValidator';
import { Link } from 'react-router-dom';

export default class Slider extends Component {

    constructor(props, context) {
        super(props, context);

        this.handleSelect = this.handleSelect.bind(this);

        this.state = {
            index: 0,
            direction: null,
        };
    }

    handleSelect(selectedIndex, e) {
        this.setState({
            index: selectedIndex,
            direction: e && e.direction,
        });
    }

    render() {
        const { index, direction } = this.state;
        if (getLoggedInStatus()) {
            return (
                <Carousel
                    activeIndex={index}
                    direction={direction}
                    onSelect={this.handleSelect}
                >
                    {
                        this.props.bannerContent && Array.isArray(this.props.bannerContent) && this.props.bannerContent.map((slide, index) => {
                            if (slide.redirectURL) {
                                return <Carousel.Item key={index}>
                                    <Link to={`/${slide.redirectURL}`}>
                                        <img
                                            className="d-block w-100"
                                            src={slide.imageURL}
                                            alt="First slide"
                                            onError={(e) => {
                                                e.target.onerror = null
                                                e.target.src = require("./slider.png")
                                            }}
                                        />
                                    </Link>
                                </Carousel.Item>
                            } else {
                                if (slide.productId && slide.purchaseOptionId) {
                                    if (slide.type === "vod" || slide.type === "channel" || slide.type === "databundle" || slide.type === "screens") {
                                        return <Carousel.Item key={index}>
                                            <Link to={`products/${slide.productId}?purchaseOptionId=${slide.purchaseOptionId}`}>
                                                <img
                                                    className="d-block w-100"
                                                    src={slide.imageURL}
                                                    alt="First slide"
                                                    onError={(e) => {
                                                        e.target.onerror = null
                                                        e.target.src = require("./slider.png")
                                                    }}
                                                />
                                            </Link>
                                        </Carousel.Item>
                                    } else if (slide.type === "package") {
                                        return <Carousel.Item key={index}>
                                            <Link to={`promotions/packages/${slide.productId}?purchaseOptionId=${slide.purchaseOptionId}`}>
                                                <img
                                                    className="d-block w-100"
                                                    src={slide.imageURL}
                                                    alt="First slide"
                                                    onError={(e) => {
                                                        e.target.onerror = null
                                                        e.target.src = require("./slider.png")
                                                    }}
                                                />
                                            </Link>
                                        </Carousel.Item>
                                    }
                                    else if (slide.type === "svod") {
                                        return <Carousel.Item key={index}>
                                            <Link to={`svod/${slide.productId}?purchaseOptionId=${slide.purchaseOptionId}`}>
                                                <img
                                                    className="d-block w-100"
                                                    src={slide.imageURL}
                                                    alt="First slide"
                                                    onError={(e) => {
                                                        e.target.onerror = null
                                                        e.target.src = require("./slider.png")
                                                    }}
                                                />
                                            </Link>
                                        </Carousel.Item>
                                    }
                                } else if (slide.productId) {
                                    if (slide.type === "vod" || slide.type === "channel" || slide.type === "databundle" || slide.type === "screens") {
                                        return <Carousel.Item key={index}>
                                            <Link to={`products/${slide.productId}`}>
                                                <img
                                                    className="d-block w-100"
                                                    src={slide.imageURL}
                                                    alt="First slide"
                                                    onError={(e) => {
                                                        e.target.onerror = null
                                                        e.target.src = require("./slider.png")
                                                    }}
                                                />
                                            </Link>
                                        </Carousel.Item>
                                    } else if (slide.type === "package") {
                                        return <Carousel.Item key={index}>
                                            <Link to={`promotions/packages/${slide.productId}`}>
                                                <img
                                                    className="d-block w-100"
                                                    src={slide.imageURL}
                                                    alt="First slide"
                                                    onError={(e) => {
                                                        e.target.onerror = null
                                                        e.target.src = require("./slider.png")
                                                    }}
                                                />
                                            </Link>
                                        </Carousel.Item>
                                    }
                                    else if (slide.type === "svod") {
                                        return <Carousel.Item key={index}>
                                            <Link to={`svod/${slide.productId}`}>
                                                <img
                                                    className="d-block w-100"
                                                    src={slide.imageURL}
                                                    alt="First slide"
                                                    onError={(e) => {
                                                        e.target.onerror = null
                                                        e.target.src = require("./slider.png")
                                                    }}
                                                />
                                            </Link>
                                        </Carousel.Item>
                                    }
                                }
                                else {
                                    return <Carousel.Item key={index}>
                                        <img
                                            className="d-block w-100"
                                            src={slide.imageURL}
                                            alt="First slide"
                                            onError={(e) => {
                                                e.target.onerror = null
                                                e.target.src = require("./slider.png")
                                            }}
                                        />
                                    </Carousel.Item>
                                }
                            }
                            return null
                        })
                    }
                </Carousel>
            )
        } else {
            return (
                <Carousel
                    activeIndex={index}
                    direction={direction}
                    onSelect={this.handleSelect}
                >
                    {
                        this.props.bannerContent && Array.isArray(this.props.bannerContent) && this.props.bannerContent.map((slide, index) => {
                            if(Array.isArray(slide.basePackageIds) && slide.basePackageIds.length === 0){
                                if (slide.redirectURL) {
                                    return <Carousel.Item key={index}>
                                        <Link to={`/${slide.redirectURL}`}>
                                            <img
                                                className="d-block w-100"
                                                src={slide.imageURL}
                                                alt="First slide"
                                                onError={(e) => {
                                                    e.target.onerror = null
                                                    e.target.src = require("./slider.png")
                                                }}
                                            />
                                        </Link>
                                    </Carousel.Item>
                                } else if(!slide.productId && !slide.purchaseOptionId) {
                                    return <Carousel.Item key={index}>
                                        <img
                                            className="d-block w-100"
                                            src={slide.imageURL}
                                            alt="First slide"
                                            onError={(e) => {
                                                e.target.onerror = null
                                                e.target.src = require("./slider.png")
                                            }}
                                        />
                                    </Carousel.Item>
                                }else{
                                    return null;
                                }
                            }else{
                                return null;
                            }
                        })
                    }
                </Carousel>
            )
        }
    }
}
