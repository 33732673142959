import React from 'react';
import { Row } from 'react-bootstrap';
import { PURCHASE_COMPLETE } from '../../util/Constants';
import { PurchasedDatabundlePlaceHolder } from '../DummyComponent/DummyComponent'


const DataBundle = ({ featuredPackages, dataBundlesPurchased }) => {

    let header = <h4 className="title">Data Bundle</h4>

    if (featuredPackages && featuredPackages.purchasedPrimaryDataBundleLoading) {
        // if (true) {
        return (
            <>
                {header}
                {/* <Spinner /> */}
                <PurchasedDatabundlePlaceHolder />
            </>
        )
    } else if (featuredPackages.purchasedPrimaryDataBundle) {
        if (dataBundlesPurchased === true) {
            // return featuredPackages.purchasedPrimaryDataBundle.map(data => {
                if (featuredPackages.purchasedPrimaryDataBundle.purchaseStatus===PURCHASE_COMPLETE) {
                    return (
                        <>
                            {header}
                            <Row className="data-bundle-image-row">
                                <div className="data-bundle-image">
                                    <img src={featuredPackages.purchasedPrimaryDataBundle.imageURL} style={{ width: 170 }} alt="" />
                                </div>
                            </Row>
                        </>
                    )
                }
            //     return null
            // })
        } else {
            return <>
                {header}
                <Row className="data-bundle-image-row">
                    <div className="data-bundle-image">
                        <img src={require("../../assets/images/dataBundleNotFound.png")} style={{ width: 170 }} alt="" />
                    </div>
                </Row>
            </>
        }
    }
}

export default DataBundle;