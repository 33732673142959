import { createAction } from 'redux-actions'
import types from './types'

export default {
    addConnection: createAction(types.ADD_CONNECTION),
    addConnectionSuccess: createAction(types.ADD_CONNECTION_SUCCESS),
    addConnectionFailed: createAction(types.ADD_CONNECTION_FAILED),
    fetchProducts: createAction(types.FETCH_PRODUCTS),
    fetchProductsSuccess: createAction(types.FETCH_PRODUCTS_SUCCESS),
    fetchProductsFailed: createAction(types.FETCH_PRODUCTS_FAILED),
    fetchSecondaryProducts: createAction(types.FETCH_SECONDARY_PRODUCTS),
    fetchSecondaryProductsSuccess: createAction(types.FETCH_SECONDARY_PRODUCTS_SUCCESS),
    fetchSecondaryProductsFailed: createAction(types.FETCH_SECONDARY_PRODUCTS_FAILED),
    getAllowedSecondaryPackages: createAction(types.GET_ALLOWED_SECONDARY_PACKAGES),
    getAllowedSecondaryPackagesSuccess: createAction(types.GET_ALLOWED_SECONDARY_PACKAGES_SUCCESS),
    getAllowedSecondaryPackagesFailed: createAction(types.GET_ALLOWED_SECONDARY_PACKAGES_FAILED),
    getProductTypes: createAction(types.GET_PRODUCT_TYPES),
    getProductTypesSuccess: createAction(types.GET_PRODUCT_TYPES_SUCCESS),
    getProductTypesFailed: createAction(types.GET_PRODUCT_TYPES_FAILED),
    getProducts: createAction(types.GET_PRODUCTS),
    getProductsSuccess: createAction(types.GET_PRODUCTS_SUCCESS),
    getProductsFailed: createAction(types.GET_PRODUCTS_FAILED),
    getProductsLoading: createAction(types.GET_PRODUCTS_LOADING),
    getDataBundle: createAction(types.GET_DATA_BUNDLE),
    getDataBundleSuccess: createAction(types.GET_DATA_BUNDLE_SUCCESS),
    getDataBundleFailed: createAction(types.GET_DATA_BUNDLE_FAILED),
    clearProducts: createAction(types.CLEAR_PRODUCTS),
    createConsent: createAction(types.CREATE_CONSENT),
    createConsentSuccess: createAction(types.CREATE_CONSENT_SUCCESS),
    createConsentFailed: createAction(types.CREATE_CONSENT_FAILED),
    retryMobitelPackage: createAction(types.RETRY_PACKAGE),
    retryMobitelPackageSuccess: createAction(types.RETRY_PACKAGE_SUCCESS),
    retryMobitelPackageFailed: createAction(types.RETRY_PACKAGE_FAILED),

    //prerpaid registration
    getPackagePurchasedStatus: createAction(types.PREPAID_PACKAGE_PURCHASED_STATUS),
    getPackagePurchasedStatusSuccess: createAction(types.PREPAID_PACKAGE_PURCHASED_STATUS_SUCCESS),
    getPackagePurchasedStatusFailed: createAction(types.PREPAID_PACKAGE_PURCHASED_STATUS_FAILED),
    getPackagePurchasedStatusLoading: createAction(types.PREPAID_PACKAGE_PURCHASED_STATUS_LOADING),

    //save mobitel payment details
    savePaymentDetails: createAction(types.SAVE_PAYMENT_DETAILS),
    filterPackages: createAction(types.FILTER_PACKAGES),

    validateConsent: createAction(types.VALIDATE_CONSENT),
    validateConsentSuccess: createAction(types.VALIDATE_CONSENT_SUCCESS),
    validateConsentFailed: createAction(types.VALIDATE_CONSENT_FAILED),

    checkPackageProvisioning: createAction(types.CHECK_PACKAGE_PROVISIONING),
    checkPackageProvisioningSuccess: createAction(types.CHECK_PACKAGE_PROVISIONING_SUCCESS),
    checkPackageProvisioningFailed: createAction(types.CHECK_PACKAGE_PROVISIONING_FAILED),

    getDataBundlePurchaseStatus: createAction(types.GET_DATA_BUNDLE_PURCHASE_STATUS),
    getDataBundlePurchaseStatusSuccess: createAction(types.GET_DATA_BUNDLE_PURCHASE_STATUS_SUCCESS),
    getDataBundlePurchaseStatusFailed : createAction(types.GET_DATA_BUNDLE_PURCHASE_STATUS_FAILED)
}