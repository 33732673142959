import React, { Component } from "react";
import { Link } from 'react-router-dom'

import "./NotFound.scss";

class Error extends Component {
  render() {
    return (
      <>
        <div className="container">
          <div className="row error-heading">
            <div className="col-sm-12">
              <h1 style={{ fontSize: "100px" }}>404</h1>
            </div>
            <div className="col-sm-12">
              <span>Page not found</span>
            </div>
            <div className="col-sm-12">
              <p>The requested page could not be found</p>
            </div>
            <div className="col-sm-12">
              <Link id="home" to="/" className="btn btn-warning">
                Home
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Error;
