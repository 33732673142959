import React from 'react';
import { Link } from 'react-router-dom';
import { sortTabOrderVods } from '../../util/ArrayValidator';
import { DEFAULT_ONDEMAND_SUBTYPE } from '../../util/Constants';

const OnDemandSubTypes = ({ onDemandContents }) => {
  if (onDemandContents.onDemandSubTypes && Array.isArray(onDemandContents.onDemandSubTypes)) {
    let sortedSubTypeArray = [];
    sortedSubTypeArray =  sortTabOrderVods(onDemandContents.onDemandSubTypes);
    let moreObj = {
      active: true,
      displayName: "More...",
      id: "5ff9e2569daec20001d1f1db",
      parentTypeId: "5d22e60422224e2be4ad4046",
      typeName: DEFAULT_ONDEMAND_SUBTYPE
    }
    sortedSubTypeArray.push(moreObj)
    return sortedSubTypeArray.map((type, i) => {
      if (type.displayName === "Educational" || type.displayName === "Movies" || type.displayName === "Tele Films" || type.displayName === "Music" || type.displayName === "PEO Originals"  || type.displayName ==="Premiere Movies" ||type.displayName === "More..." ) {
        // return <Link to={`/ondemand/#${i}`} className="onDemandLinks">{type.displayName}</Link>
        return <Link to={`/ondemand/#${type.typeName}`} className="onDemandLinks">{type.displayName}</Link>
      }
      return null
    })
  } else {
    return null;
  }
}

export default OnDemandSubTypes;