import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { CustomButton } from '../../components/controls/CustomButton'
import { registrationActions, registrationSelectors } from './ducks';
import './styles.scss';
import { getRegistrationConnectionId } from '../../util/ArrayValidator';
class InsertOtp extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {},
            errors: {},
            otpValue: '',
            otpError: undefined,
            resendOtpError: undefined,
            otpSubmitLoading: false,
            resendOtpLoading: false,
            resendOtp: undefined
        }
    }

    static getDerivedStateFromProps(nextProps, state) {

        let newProps = {}

        if (nextProps.otpError && nextProps.otpError !== state.otpError) {
            newProps.otpSubmitLoading = false
            newProps.otpError = nextProps.otpError
        }

        if (nextProps.resendOtpError && nextProps.resendOtpError !== state.resendOtpError) {
            newProps.resendOtpError = nextProps.resendOtpError
            newProps.resendOtpLoading = false
        }

        if (nextProps.otpError === undefined) {
            newProps.otpError = undefined
        }

        if (nextProps.resendOtpError === undefined) {
            newProps.resendOtpError = undefined
        }

        if (nextProps.resendOtp && nextProps.resendOtp !== state.resendOTP) {
            newProps.resendOtpLoading = false
        }

        return {
            ...newProps
        };
    }

    componentWillUnmount() {
        this.props.clearErrorMsg()
    }

    handleValidation = () => {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //Name
        if (!fields["otp"]) {
            formIsValid = false;
            errors["otp"] = "OTP is required";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({
            fields,
            errors: {},
            otpError: false,
            resendOtpError: false,
            [e.target.id]: e.target.value,

        })
    }

    onSubmit = (e) => {
        e.preventDefault();
        let connectionId = getRegistrationConnectionId()
        if (this.handleValidation()) {
            this.setState({
                otpSubmitLoading: true,
                otpError: false,
                resendOtpError: false
            })
            // if (connectionId) {
            this.props.submitOtp({ otp: this.state.otpValue, connectionId: connectionId });
            // }
        } else {

        }

    }

    onResendSubmit = (e) => {
        e.preventDefault();
        this.setState({
            resendOtpLoading: true,
            otpError: false,
            resendOtpError: false
        })
        let connectionId = getRegistrationConnectionId()
        this.props.resendOtp(connectionId);
    }


    render() {
        const { otpSubmitLoading, resendOtpLoading, otpError, resendOtpError } = this.state;
        const { resendOtpMessage } = this.props;
        let errorMsgs = (otpError || resendOtpError) && (<React.Fragment>
            <div class="alert alert-danger offset-sm-2 col-sm-9" role="alert" style={{ margin: "auto" }}>
                {otpError || resendOtpError}
            </div>
        </React.Fragment>
        );
        
        let successMsg = (resendOtpMessage) && (<React.Fragment>
            <div className="alert alert-success offset-sm-2 col-sm-9" role="alert" style={{ margin: "auto" }}>
                {resendOtpMessage}
            </div>
        </React.Fragment>
        );
        return (
            <>
                <div className="container card-page" >
                    <div className="card col-sm-12">
                        <form className="confirm-form" onSubmit={this.onSubmit}>
                            <div className="form-group row">
                                <div className="offset-sm-2 col-sm-9" style={{ margin: "auto" }}>
                                    <h4 style={{ color: "#ffffff", justifyContent: 'center' }}> Enter Verification Code</h4>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-sm-6" style={{ margin: "auto" }}>
                                    <input type="text" id="otpValue" name="otp" ref="otp" className="form-control" placeholder="Verification code" onChange={this.handleChange.bind(this, "otp")} />
                                    <span style={{ color: "red", fontSize: 12 }}>{this.state.errors["otp"]}</span>
                                </div>
                            </div>
                            <p style={{ color: "#ffffff", fontSize: 12, textAlign: 'center' }}>A verification code has been sent to you as a SMS. Please enter the code you received.</p>

                            <div className="form-group row">
                                <div className="offset-sm-12" style={{ margin: "auto" }}>
                                    <CustomButton id="otp" disabled={otpSubmitLoading || resendOtpLoading || !this.state.otpValue} loading={otpSubmitLoading} text="Submit" />

                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="offset-sm-12" style={{ margin: "auto" }}>
                                    <button className="link-button" id="resendOtp"
                                        onClick={(e) => {
                                            if (otpSubmitLoading || resendOtpLoading) return false
                                            this.onResendSubmit(e)
                                        }}

                                        style={{
                                            color: "#4FA1D9",
                                            textDecoration: 'underline',
                                            display: "flex",
                                            justifyContent: 'center'
                                        }}>
                                        <div style={{
                                            display: 'inline-flex',
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}>
                                            {
                                                resendOtpLoading &&
                                                <span className="spinner-border spinner-border-sm" style={{ marginRight: 5, color: '#fff' }} ></span>
                                            }
                                            <span> Resend OTP</span>
                                        </div>
                                    </button>
                                </div>
                            </div>

                        </form>
                        {successMsg}
                        {errorMsgs}
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = (state) => { 
    return {
        ...registrationSelectors.submitDetails(state),
        ...registrationSelectors.getErrors(state)

    }
};

export default withRouter(connect(
    mapStateToProps,
    registrationActions
)(InsertOtp));