import React from 'react'
import Carousel from 'nuka-carousel';
import { FeaturedPackage } from './FeaturedPackage'
import Card from '../CardComponent/Card';
import Features from "../Features/Features";
import CardOptions from '../CardOptions/CardOptions';
import { Col} from 'react-bootstrap'
import { getLoggedInStatus, purchaseStatusValidation, getProductPrice, isDiscounted, discountType } from '../../util/ArrayValidator';
import {PAYMENT_SUCCESS, TRANSACTIONAL, CHANNEL_BUNDLE_ID} from '../../util/Constants'
import _ from "lodash";

export const CustomCarousel = ({
    slidesToShow,
    renderCenterLeftControls,
    renderCenterRightControls,
    renderBottomCenterControls,
    heightMode,
    initialSlideHeight,
    channels,
    style,
    featuredPackage,
    onDemand,
    channelProduct,
    imageNotFoundStyle,
    informative,
    allProducts,
    slideWidth,
    onClick,
    showExpire,
    showDate,
    isLoggedIn,
    selectedImage,
    viewPackage,
    trendingVOD,
    daysRemainingToExpire,
    slideIndex,
    isDummy,
    svod
}) => {
    return <Carousel
        slidesToShow={slidesToShow}
        renderCenterLeftControls={SamplePrevArrow}
        renderCenterRightControls={SampleNextArrow}
        renderBottomCenterControls={renderBottomCenterControls}
        heightMode={heightMode}
        initialSlideHeight={initialSlideHeight}
        slidesToScroll={slidesToShow === 1 ? undefined : "auto"}
        slideWidth={slideWidth}
        slideIndex={slidesToShow === 1 ? slideIndex : undefined}
    // slideIndex={allProducts && Array.isArray(allProducts) && allProducts.length != 0 && allProducts[3]}
    >
        {informative ?

            (featuredPackage && !isDummy && allProducts && Array.isArray(allProducts) && allProducts.length !== 0 && allProducts.map((image, index) => {  
                let utilization = image.purchaseOption && image.purchaseOption.utilization            
                return (
                    <div style={{ margin: 30}}  >
                        <FeaturedPackage id={index} header={image.name}
                            description={image.productTypeName}
                            price={image.discountOptionGroup ? getProductPrice(image) : image.purchaseOption.defaultPrice }
                            imagePath={image.imageURL}
                            onClick={onClick}
                            isLoggedIn={localStorage.jwt && viewPackage === false && (image.purchaseStatus==="PURCHASE_COMPLETE" || image.purchaseStatus==="PURCHASE_PENDING_REMOVE" || image.paymentStatus===PAYMENT_SUCCESS) ? true : false}
                            // className={isLoggedIn == image.name ? "selected" : "not-selected"}
                            // classNameTwo={(selectedImage[0] != undefined && selectedImage[0] == index) ? "inside" : ""}
                            active={selectedImage[0] !== undefined && selectedImage[0] === index.toString()}
                            purchaseType={image.purchaseOption && image.purchaseOption.purchaseType=== TRANSACTIONAL? utilization && utilization.utilizationPeriod===1? "For "  + utilization.utilizationPeriod + " "+ _.startCase(_.toLower(utilization.utilizationPeriodUnit.substring(0, utilization.utilizationPeriodUnit.length - 1))): "For "  +  utilization.utilizationPeriod + " " + _.startCase(_.toLower(utilization.utilizationPeriodUnit)): utilization.utilizationPeriodUnit==="MONTHS"?"Monthly subscription" :"Daily subscription"}
                        />
                    </div>
                )
            })) ||
            (channelProduct && allProducts && Array.isArray(allProducts) && allProducts.length !== 0 && allProducts.map((o, index) => {
                if (o.status === "PRODUCT_STATUS_ACTIVE") {
                    let purchaseTypeStatus = purchaseStatusValidation(o.purchaseStatus)
                    let discount = discountType(o)
                    return (
                        <Card
                            key={index}
                            id={o.id}
                            hover={true}
                            showAddNew={!o.isPurchased && !o.isBundled && getLoggedInStatus()}
                            showPrice={isLoggedIn && !o.isBundled && !o.isPurchased }
                            name={o.name}
                            imageURL={o.imageURL}
                            price={getProductPrice(o)}
                            onClick={()=>onClick(o.id,o.productTypeId == CHANNEL_BUNDLE_ID)}
                            showOptions={o.feature}
                            isLoggedIn={isLoggedIn}
                            isPurchased={(purchaseTypeStatus.productPurchased || purchaseTypeStatus.productPendingRemove || o.paymentStatus===PAYMENT_SUCCESS)}
                            showPurchaseIcon={true}
                            type="channel"
                            isDummy={isDummy}
                            channelProduct={channelProduct}
                            hasDiscount={isDiscounted(o)}
                            discount={discount}
                            isChannelBundle={o.productTypeId == CHANNEL_BUNDLE_ID}
                        >
                            <CardOptions isPurchased={(purchaseTypeStatus.productPurchased || purchaseTypeStatus.productPendingRemove || o.paymentStatus===PAYMENT_SUCCESS)} isBundled={o.isBundled} isLoggedIn={isLoggedIn} />
                            <Features feature={o.feature} />
                        </Card>
                    )
                }
                return null
            }))
            ||
            (onDemand && allProducts && Array.isArray(allProducts) && allProducts.length !== 0 && allProducts.map((content, index) => {
                if (content.status === "PRODUCT_STATUS_ACTIVE") {
                    let purchaseTypeStatus = purchaseStatusValidation(content && content.purchaseStatus)
                    let price = content && content.purchaseOption && content.purchaseOption.defaultPrice
                    let discount = discountType(content)
                    return (
                        <Card
                            key={index}
                            id={content.id}
                            isLoggedIn={isLoggedIn}
                            name={content.name}
                            imageURL={content.imageURL}
                            price={getProductPrice(content)}
                            isPurchased={(purchaseTypeStatus.productPurchased || purchaseTypeStatus.productPendingRemove ||  content.paymentStatus === PAYMENT_SUCCESS)}
                            onClick={onClick}
                            showAddNew={true}
                            svod={content.daysRemainingToExpire}
                            hover={true}
                            onDemand={true}
                            // showPrice={getLoggedInStatus()}
                            type="onDemand"
                            isDummy={isDummy}
                            showPrice={isLoggedIn && price}
                            isBundled={content.isBundled}
                            hasDiscount={isDiscounted(content)}
                            discount={discount}
                        />
                    )
                }
                return null
            }))
            || (svod && allProducts && Array.isArray(allProducts) && allProducts.length !== 0 && allProducts.map((content, index) => {
                if (content.status === "PRODUCT_STATUS_ACTIVE") {
                    let purchaseTypeStatus = purchaseStatusValidation(content.purchaseStatus);
                    let discount = discountType(content)
                    return (
                        <div key={index} style={{ marginLeft: "10px" }}>
                            <Col xs={14} style={{marginTop:"15%"}}>
                            <Card
                                key={index}
                                id={content.id}
                                isLoggedIn={isLoggedIn}
                                name={content.name}
                                imageURL={content.imageURL}
                                price={getProductPrice(content)}
                                isPurchased={content && (purchaseTypeStatus.productPurchased || purchaseTypeStatus.productPendingRemove || content.paymentStatus === PAYMENT_SUCCESS)}
                                onClick={onClick}
                                showAddNew={true}
                                svod={content.daysRemainingToExpire}
                                hover={true}
                                showPrice={getLoggedInStatus()}
                                type="svod"
                                isDummy={isDummy}
                                isBundled={content.isBundled}
                                hasDiscount={isDiscounted(content)}
                                discount={discount}
                            />
                            </Col>
                        </div>
                    )
                }
                return null
            }))
            : (allProducts && Array.isArray(allProducts) && allProducts.length !== 0 && allProducts.map((content, index) => {
                let purchaseTypeStatus = purchaseStatusValidation(content.purchaseStatus)
                return (
                    // <Col style={{width : content.productTypeTypeName=="svod"? "200px": 100}}>
                    <div key={index} className="No-ClassName"
                        style={{ marginTop: (content.productTypeTypeName === "svod") ? "25%" : (content.productTypeTypeName === "package" && "15%"), marginRight: (content.productTypeTypeName === "svod"||content.productTypeTypeName === "package") && '10%' }}
                    >{content.productTypeTypeName === "package" ?
                        <FeaturedPackage id={index} header={content.name}
                            description={content.productTypeName}
                            price={content.price}
                            imagePath={content.imageURL}
                            onClick={onClick}
                            // isLoggedIn={isLoggedIn === image.name && localStorage.jwt && viewPackage === false ? true : false}
                            // className={isLoggedIn == image.name ? "selected" : "not-selected"}
                            // classNameTwo={(selectedImage[0] != undefined && selectedImage[0] == index) ? "inside" : ""}
                            // active={selectedImage[0] !== undefined && selectedImage[0] === index.toString()}
                        />
                        :
                        <Col xs={content.productTypeTypeName==="svod"?14:10} style={{marginTop:content.productTypeTypeName==="svod" && "30px"}}>
                        <Card
                            key={index}
                            id={content.id}
                            isLoggedIn={isLoggedIn}
                            name={content.name}
                            imageURL={content.imageURL}
                            price={content && content.purchaseOption && content.purchaseOption.defaultPrice}
                            isPurchased={content && (purchaseTypeStatus.productPurchased || purchaseTypeStatus.productPendingRemove ||  content.paymentStatus === PAYMENT_SUCCESS)}
                            onClick={onClick}
                            // showAddNew={true}
                            svod={content.daysRemainingToExpire}
                            hover={true}
                            showPrice={getLoggedInStatus()}
                            // type="svod"
                            type={content.productTypeTypeName}
                            isDummy={isDummy}
                        />
                        </Col>
                        }

                    </div>
                    // </Col>
                    // <div key={index}>
                    //     <div className="carasoul-image width-100-margin-40">
                    //         <img
                    //             key={index}
                    //             src={require("./image.png")}
                    //             className="img-responsive"
                    //             style={style}
                    //             onError={(e) => {
                    //                 e.target.onerror = null
                    //                 let errors = entitledChannelsErrors
                    //                 errors[index] = true;
                    //                 entitledChannelsErrors.push(errors)
                    //             }}
                    //             alt=""
                    //         />
                    //     </div>
                    // </div>
                )
            }))
        }
    </Carousel>

}

const SampleNextArrow = (props) => {
    const { nextSlide } = props;
    return (
        <div onClick={nextSlide}>
            <img src={require("../../images/icons/next-white.svg")} className="sample-next-arrow" alt="" />
        </div>
    );
}

const SamplePrevArrow = (props) => {
    const { previousSlide } = props;
    return (
        <div onClick={previousSlide} >
            <img src={require("../../images/icons/back-white.svg")} className="sample-prev-arrow" alt="" />
        </div>
    );
}



