import { createLogic } from 'redux-logic';
import actions from './actions'
import types from './types'
import endPoints from './../../../util/EndPoints'
import API from './../../../util/HTTPClient'
import history from './../../../_helpers/history'

export default [

    createLogic({
        type: types.GET_USERNAME,
        latest: true,
        debounce: 1000,

        process({ MockHTTPClient, getState, action }, dispatch, done) {
            let HTTPClient
            if (MockHTTPClient) {
                HTTPClient = MockHTTPClient
            } else {
                HTTPClient = API
            }
            HTTPClient.Get(`${endPoints.GET_USERNAME}?username=${action.payload}`)
                .then(resp => resp.data)
                .then((data) => {
                    dispatch(actions.getUsernameSuccess(data))
                })
                .catch(err => {
                    // Log error
                    dispatch(actions.getUsernameFailed((err && err.response &&  err.response.data) || "Something went wrong, please contact your service provider"))
                })
                .then(() => done()); // call done when finished dispatching
        }
    }),
    createLogic({
        type: types.CHANGE_PASSWORD,
        latest: true,
        debounce: 1000,

        process({ MockHTTPClient, getState, action }, dispatch, done) {
            let HTTPClient
            if (MockHTTPClient) {
                HTTPClient = MockHTTPClient
            } else {
                HTTPClient = API
            }
            HTTPClient.Post(`${endPoints.FORGOT_PASSOWRD}`,action.payload)
                .then(resp => resp.data)
                .then((data) => {
                    dispatch(actions.changePasswordSuccess(data))
                    history.push('/login')
                })
                .catch(err => {
                    // Log error
                    dispatch(actions.changePasswordFailed("Something went wrong, please contact your service provider"))
                })
                .then(() => done()); // call done when finished dispatching
        }
    })
]