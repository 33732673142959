import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import { allProductsActions } from './ducks'
import "./styles/AllScreens.scss"
import DeviceCard from '../../components/screens/DeviceCard'
import productTypeIDs from './ducks/productTypeIDs'
import DummyDevicesPage from "../../components/DummyComponent/DummyDevicesPage";
import { TRANSACTIONAL } from "../../util/Constants";

class AllDevicesPage extends Component {

    onClickDevices = (id) => {
        // history.push(`/products/${id}`)
    }

    componentDidMount() {
        if (!!localStorage.conID) {
            this.props.getAllProducts({
                conid: localStorage.conID,
                subTypeId: productTypeIDs.DEVICE_ID
            });
            this.props.getPromotionalPackages()
        }
    }

    componentWillUnmount() {
        this.props.resetForm();
    }

    getPromotionalPackageDevices = (promoPack) => {
        let purchasedDeviceArray = [];
        const { allUserDevices } = this.props.allUserDevices;
        promoPack && promoPack.map((data) => {
            if( data.purchaseStatus !== "NOT_PURCHASED" ){
                data.productList.filter((obj) => {
                    if(obj.productTypeTypeName === "screens") {
                        obj.isBundled = null;
                        obj.isPurchased = true;
                        obj.purchaseStatus = "PURCHASE_COMPLETE"
                        purchasedDeviceArray.push(obj)
                    }
                    return null
                }
            )
            }
            return null
        });
        return allUserDevices && allUserDevices.map((device, index) => 
            purchasedDeviceArray.find(o => o.id === device.id) || device
        )
    }

    render() {
        const { loading } = this.props.allUserDevices;
        const { promotionalPackagesLoading, promotionalPackages} = this.props.promotionalPackages;
        let deviceArrayWithPromo = this.getPromotionalPackageDevices(promotionalPackages);
        let getPurchasedPackageType = localStorage.getItem('basepackagePurchaseType')
        if (loading && promotionalPackagesLoading) {
            return (
                <>
                    <Container>                    
                        <DummyDevicesPage />
                    </Container>
                </>
            )
        } else {
            return (
                <>
                    <Container>
                        <div className="device-container">
                            <DeviceCard
                                onClickDevices={this.onClickDevices}
                                type="DEFAULT_DEVICES"
                                data={deviceArrayWithPromo}
                                isAlreadySubscribedLabel={getPurchasedPackageType===TRANSACTIONAL ?" Already Purchased": "Already Subscribed"}
                                label="DEFAULT DEVICES"

                            />
                        </div>
                        <div className="device-container" >
                            <DeviceCard
                                onClickDevices={this.onClickDevices}
                                type="ADDITIONAL_DEVICES"
                                data={deviceArrayWithPromo}
                                label="ADDITIONAL DEVICES"
                            />
                        </div>
                    </Container>
                </>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        allUserDevices: state.AllProducts.allUserDevices,
        promotionalPackages: state.AllProducts.promotionalPackages
    };
}
export default withRouter(connect(mapStateToProps, allProductsActions)(AllDevicesPage));
