import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "./SuspendedProducts.scss";
import ReactivationProductView from '../../components/ReactivationProductView/ReactivationProductView';

function SuspendedProducts({ name, products, loading, packageStatus }) {

    return (
        <Container>
            {products && products.length !== 0 && <Row className="suspended-products-view">
                <Col md={6}>
                    <ReactivationProductView name={name} products={products} loading={loading} packageStatus={packageStatus} />
                </Col>
            </Row>}
            {loading && name !== "Package Name " && <Row className="suspended-products-view">
                <Col md={6}>
                    <ReactivationProductView loading={loading} />
                </Col>
            </Row>}
        </Container>
    )

}

export default SuspendedProducts;
