import React from 'react';
import { Spinner } from "react-bootstrap";

function ProductStatus({ status }) {
    if (status === "loading") {
        return <Spinner animation="border" role="status" size="sm" className="spinner-color" />
    } else if (status === "success") {
        return <i class="fas fa-check check-mark"></i>
    } else if (status === "failed") {
        return <i class="fas fa-times times-color"></i>
    } else if (status === "packageSuccess") {
        return <p class="margin-none" style={{ color: "green" }}>Active</p>
    } else if (status === "packageFailed") {
        return <p class="margin-none" style={{ color: "red" }}>Suspended</p>
    } else {
        return null;
    }
}

export default ProductStatus
