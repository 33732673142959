import React from 'react'
import { getUniqueData } from '../../util/ArrayValidator';
import './CustomTab.scss'
export const CustomTabBody = ({
    handleChange, unionArray, name, id, promotional

}) => {
    return <>
        <select name={name} id={id}
            className="custom-select-dropdown"
            onChange={handleChange}>
            <option value="ALL">Select a category</option>
            {
                unionArray && Array.isArray(unionArray) && getUniqueData(unionArray, "category").map((item, index) => {
                    return (
                        <option id={index} name={item.category} value={item.category}>{item.category}</option>

                    )
                })
            }
        </select>
        <br />
        <div className="option-grid" style={{marginTop: '-25px'}}>
            <span className="span-tstv"><i className="fa fa-history" /> TSTV</span>
            {!promotional && <span><i className="fas fa-plus ala-cart-tab"></i> A-la-carte</span>}

        </div>
    </>
}