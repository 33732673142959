import React, { Component } from 'react'
import { Container } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import SVODCard from "../svodsPage/SVODCard"
import { allProductsActions } from '../AllProducts/ducks'
import productTypeIDs from './ducks/productTypeIDs'
import './styles/AllSvodsPage.scss'
import DummyAllSvodPage from '../../components/DummyComponent/DummyAllSvodPage';
import { getConnectionId, getLoggedInStatus, purchaseStatusValidation, isDiscounted, discountType, getProductPrice } from '../../util/ArrayValidator';
import { PAYMENT_SUCCESS } from '../../util/Constants';

class AllSVODPage extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        const isLoggedIn = getLoggedInStatus();
        const connectionId = getConnectionId();
        if (isLoggedIn) {
            if (!!getConnectionId()) {
                this.props.getAllProducts({
                    conid: connectionId,
                    subTypeId: productTypeIDs.SVOD_ID
                });
            } else {
                if (!!getConnectionId()) {
                    this.props.getAllProducts({
                        conid: connectionId,
                        subTypeId: productTypeIDs.SVOD_ID
                    });
                }
            }
        } else {
            this.props.getAllProducts(productTypeIDs.SVOD_ID)
        }
    }

    componentWillUnmount() {
        this.props.resetForm();
    }

    onClick = (e) => {
        this.props.history.push(`/svod/${e.target.id}`)
    };

    render() {
        const isLoggedIn = getLoggedInStatus();
        const { allSVODs, allUserSVODs } = this.props
        const SVOD = isLoggedIn ? allUserSVODs.allUserSVODs : allSVODs.allSVODs
        const loading = isLoggedIn ? allUserSVODs.loading : allSVODs.loading
        const readyData = SVOD && Array.isArray(SVOD)
        const isCommingSoon = SVOD && SVOD.length === 0

        if (loading) {
            // if (true) {
            return (
                <DummyAllSvodPage />
            )
        } else {
            return (
                <Container className="all-svods-container">
                    <div className="all-svods-inside-container">
                        <Container className="container-style">
                            <h3 className="all-svods-header">ALL SVODs</h3>
                            <br />
                            {readyData && SVOD.map((svod, i) => {
                                let purchaseStatusType = purchaseStatusValidation(svod.purchaseStatus)
                                let discount = discountType(svod)
                                if (svod.status === "PRODUCT_STATUS_ACTIVE") {
                                    return (
                                        <SVODCard
                                            key={i}
                                            name={svod.name}
                                            isPurchased={(purchaseStatusType.productPurchased || purchaseStatusType.productPendingRemove || svod.paymentStatus === PAYMENT_SUCCESS)}
                                            imageURL={svod.imageURL}
                                            id={svod.id}
                                            onClick={this.onClick}
                                            isBundled={svod.isBundled}
                                            hasDiscount={isDiscounted(svod)}
                                            discount={discount}
                                            price={getProductPrice(svod)}
                                        />
                                    )
                                }
                                return null
                            })}
                            {isCommingSoon ?
                                <div className="coming-soon-label">Coming soon...</div> :
                                null
                            }
                        </Container>
                    </div>
                </Container>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        allSVODs: state.AllProducts.allSVODs,
        allUserSVODs: state.AllProducts.allUserSVODs,
    }
};
export default withRouter(connect(
    mapStateToProps,
    allProductsActions
)(AllSVODPage));
//107

