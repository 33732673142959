import React from 'react'
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { purchaseStatusValidation, getProductPrice, getLoggedInStatus, isDiscounted, discountType } from '../../util/ArrayValidator';
import { PAYMENT_SUCCESS } from '../../util/Constants';

function DeviceCard(props) {
    const { data, type, isAlreadySubscribedLabel, label, onClickDevices, isDummy } = props;

    //Check for additional device availability
    let containAdditionalDevices = data.some(el => (el.isBundled === null || el.isBundled === false));

    let showHeader = true;

    if (!containAdditionalDevices) {
        showHeader = false;
    }

    return (
        <>
            <div className="device-header">
                {showHeader && <h4 className="default-devices-label">{label}</h4>}
                {isAlreadySubscribedLabel ? <span className='already-sub-devices'>
                    <i className="fas fa-check-square already-sub-label" ></i>{" "} {isAlreadySubscribedLabel}
                </span> : null}
            </div>
            <div className="row">
                {type === "DEFAULT_DEVICES" ?
                    data && data.map((screen, i) => {
                        if (screen.isBundled) {
                            return (
                                <Link to={`/products/${screen.id}`} className="col-sm-4" key={i} >
                                    <div className="device-container-box">
                                        <div className="device-content-default">
                                            <Row>
                                                <Col className="device-image-container">
                                                    <img src={isDummy ? require("./../../assets/images/AdditionalDevice.jpg") : `/${screen.imageURL}`} className="device-img-background" alt='' />
                                                </Col>
                                                <Col className="device-details-container">
                                                    <i className="fas fa-check-square device-check-mark" ></i>
                                                    <div className="device-name-container" >
                                                        <Col className="device-name-col" >
                                                            <span className="device-name" >
                                                                {screen.name}
                                                            </span>
                                                        </Col>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {/* {screen.promotionObject && screen.promotionObject.name && <Row className="included-promo-row">
                                                <div className="col-10 included-promo-col">
                                                    <p className="promo-pack-device-name">Included in: {screen.promotionObject.name}</p>
                                                </div>                                            
                                            </Row>} */}
                                        </div>
                                    </div>
                                </Link>
                            );
                        }
                        return null
                    }) :
                    containAdditionalDevices && data && data.length !== 0 && data.map((screen, i) => {
                        if (!screen.isBundled) {
                            let purchaseStatusType = purchaseStatusValidation(screen.purchaseStatus);

                            let showDiscountedIcon;
                            if (getLoggedInStatus()) {
                                if (isDiscounted(screen)) {
                                    let discount = discountType(screen)
                                    showDiscountedIcon = <div class="discount-div-bg-device">
                                        <div class="red-bg-container">
                                            <span>{discount}</span>
                                        </div>
                                    </div>
                                }
                            }


                            let showPrice;
                            if (!screen.isBundled && !screen.isPurchased) {
                                showPrice = <span className="additional-device-price-tag" >
                                    Rs. {getProductPrice(screen)}/=
                                            </span>
                            }

                            return (
                                <Link to={`/products/${screen.id}`} className="col-sm-4" key={i} >
                                    <div className="device-container-box" onClick={onClickDevices(screen.id)}>
                                        <div className={purchaseStatusType.productNotPurchased ? "device-content-one" : "device-content-default"} >
                                            <Row>
                                                <Col className="device-image-container" >
                                                    <img src={`/${screen.imageURL}`} className="device-img-background" alt='' />
                                                </Col>
                                                <Col className="device-details-container" >
                                                    {(purchaseStatusType.productPurchased || purchaseStatusType.productPendingRemove || screen.paymentStatus === PAYMENT_SUCCESS) ? (<i class="fas fa-check-square device-check-mark"></i>)
                                                        : ("")
                                                    }
                                                    {showDiscountedIcon}
                                                    <div className="additional-device-details-container" >
                                                        <Col className="additional-device-name-container">
                                                            <span className="device-name"   >
                                                                {screen.name}
                                                            </span>
                                                            <br />
                                                            {
                                                                isDummy ? null :
                                                                    showPrice
                                                            }
                                                        </Col>
                                                    </div>
                                                    <br />
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Link>
                            );
                        }
                        
                        return null
                    })}
            </div>
        </>
    )
}

export default DeviceCard
