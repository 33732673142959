const action_header_password_update = "passwordUpdate/"
// Types
export default {
  GET_PRODUCT_TYPES: "products/GET_PRODUCT_TYPES",
  GET_PRODUCT_TYPES_FAILED: "products/GET_PRODUCT_TYPES_FAILED",
  GET_PRODUCT_TYPES_SUCCESS: "products/GET_PRODUCT_TYPES_SUCCESS",

  GET_FEATURED_CHANNELS: "products/GET_FEATURED_CHANNELS",
  GET_FEATURED_CHANNELS_LOADING: "products/GET_FEATURED_CHANNELS_LOADING",
  GET_FEATURED_CHANNELS_FAILED: "products/GET_FEATURED_CHANNELS_FAILED",
  GET_FEATURED_CHANNELS_SUCCESS: "products/GET_FEATURED_CHANNELS_SUCCESS",

  GET_FEATURED_PACKAGES: "products/GET_FEATURED_PACKAGES",
  GET_FEATURED_PACKAGES_LOADING: "products/GET_FEATURED_PACKAGES_LOADING",
  GET_FEATURED_PACKAGES_FAILED: "products/GET_FEATURED_PACKAGES_FAILED",
  GET_FEATURED_PACKAGES_SUCCESS: "products/GET_FEATURED_PACKAGES_SUCCESS",

  GET_SECONDARY_FEATURED_PACKAGES: "products/GET_SECONDARY_FEATURED_PACKAGES",
  GET_SECONDARY_FEATURED_PACKAGES_LOADING:
    "products/GET_SECONDARY_FEATURED_PACKAGES_LOADING",
  GET_SECONDARY_FEATURED_PACKAGES_FAILED:
    "products/GET_SECONDARY_FEATURED_PACKAGES_FAILED",
  GET_SECONDARY_FEATURED_PACKAGES_SUCCESS:
    "products/GET_SECONDARY_FEATURED_PACKAGES_SUCCESS",

  GET_ON_DEMAND_CONTENT: "products/GET_ON_DEMAND_CONTENT",
  GET_ON_DEMAND_CONTENT_LOADING: "products/GET_ON_DEMAND_CONTENT_LOADING",
  GET_ON_DEMAND_CONTENT_SUCCESS: "products/GET_ON_DEMAND_CONTENT_SUCCESS",
  GET_ON_DEMAND_CONTENT_FAILED: "products/GET_ON_DEMAND_CONTENT_FAILED",

  GET_FEATURED_SVOD: "products/GET_FEATURED_SVOD_CONTENT",
  GET_FEATURED_SVOD_LOADING: "products/GET_FEATURED_SVOD_LOADING",
  GET_FEATURED_SVOD_SUCCESS: "products/GET_FEATURED_SVOD_SUCCESS",
  GET_FEATURED_SVOD_FAILED: "products/GET_FEATURED_SVOD_FAILED",

  GET_BANNER: "products/GET_BANNER",
  GET_BANNER_SUCCESS: "products/GET_BANNER_SUCCESS",
  GET_BANNER_FAILED: "products/GET_BANNER_FAILED",

  GET_ALLOWED_SECONDARY_PACKAGES: "products/GET_ALLOWED_SECONDARY_PACKAGES",
  GET_ALLOWED_SECONDARY_PACKAGES_SUCCESS:
    "products/GET_ALLOWED_SECONDARY_PACKAGES_SUCCESS",
  GET_ALLOWED_SECONDARY_PACKAGES_FAILED:
    "products/GET_ALLOWED_SECONDARY_PACKAGES_FAILED",
  GET_ALLOWED_SECONDARY_PACKAGES_LOADING: "products/GET_ALLOWED_SECONDARY_PACKAGES_LOADING",

  //after login
  GET_FEATURED_CHANNELS_PACKAGE: "product/GET_FEATURED_CHANNELS_PACKAGE",
  GET_FEATURED_CHANNELS_PACKAGE_SUCCESS:
    "product/GET_FEATURED_CHANNELS_PACKAGE_SUCCESS",
  GET_FEATURED_CHANNELS_PACKAGE_FAILED:
    "product/GET_FEATURED_CHANNELS_PACKAGE_FAILED",

  GET_USER_DETAILS: "product/GET_USER_DETAILS",
  GET_USER_DETAILS_SUCCESS: "product/GET_USER_DETAILS_SUCCESS",
  GET_USER_DETAILS_FAILED: "products/GET_USER_DETAILS_FAILED",

  GET_CONNECTION_DETAILS: "product/GET_CONNECTION_DETAILS",
  GET_CONNECTION_DETAILS_SUCCESS: "product/GET_CONNECTION_DETAILS_SUCCESS",
  GET_CONNECTION_DETAILS_FAILED: "product/GET_CONNECTION_DETAILS_FAILED",

  GET_CONNECTION_CONTENT: "product/GET_CONNECTION_CONTENT",
  GET_CONNECTION_CONTENT_SUCCESS: "product/GET_CONNECTION_CONTENT_SUCCESS",
  GET_CONNECTION_CONTENT_FAILED: "product/GET_CONNECTION_CONTENT_FAILED",

  GET_ALL_TAGS: "product/GET_ALL_TAGS",
  GET_ALL_TAGS_SUCCESS: "products/GET_ALL_TAGS_SUCCESS",
  GET_ALL_TAGS_FAILED: "product/GET_ALL_TAGS_FAILED",

  GET_FEATURED_VOD_PACKAGE: "product/GET_FEATURED_VOD_PACKAGE",
  GET_FEATURED_VOD_PACKAGE_SUCCESS: "product/GET_FEATURED_VOD_PACKAGE_SUCCESS",
  GET_FEATURED_VOD_PACKAGE_FAILED: "product/GET_FEATURED_VOD_PACKAGE_FAILED",

  GET_FEATURED_SVOD_PACKAGE: "product/GET_FEATURED_SVOD_PACKAGE",
  GET_FEATURED_SVOD_PACKAGE_SUCCESS:
    "product/GET_FEATURED_SVOD_PACKAGE_SUCCESS",
  GET_FEATURED_SVOD_PACKAGE_FAILED: "product/GET_FEATURED_SVOD_PACKAGE_FAILED",

  GET_PURCHASED_PRIMARY_PACKAGE: "product/GET_PURCHASED_PRIMARY_PACKAGE",
  GET_PURCHASED_PRIMARY_PACKAGE_SUCCESS:
    "product/GET_PURCHASED_PRIMARY_PACKAGE_SUCCESS",
  GET_PURCHASED_PRIMARY_PACKAGE_FAILED:
    "product/GET_PURCHASED_PRIMARY_PACKAGE_FAILED",

  GET_PURCHASED_PRIMARY_DATA_BUNDLE:
    "product/GET_PURCHASED_PRIMARY_DATA_BUNDLE",
  GET_PURCHASED_PRIMARY_DATA_BUNDLE_SUCCESS:
    "product/GET_PURCHASED_PRIMARY_DATA_BUNDLE_SUCCESS",
  GET_PURCHASED_PRIMARY_DATA_BUNDLE_FAILED:
    "product/GET_PURCHASED_PRIMARY_DATA_BUNDLE_FAILED",

  GET_ONDEMAND_SUB_TYPES: "product/ GET_ONDEMAND_SUB_TYPES",
  GET_ONDEMAND_SUB_TYPES_SUCCESS: "product/ GET_ONDEMAND_SUB_TYPES_SUCCESS",
  GET_ONDEMAND_SUB_TYPES_FAILED: "product/ GET_ONDEMAND_SUB_TYPES_FAILED",

  GET_SECONDARY_ID: "product/GET_SECONDARY_ID",
  GET_SECONDARY_ID_SUCCESS: "product/GET_SECONDARY_ID_SUCCESS",
  GET_SECONDARY_ID_FAILED: "product/GET_SECONDARY_ID_FAILED",

  //   GET_SELECTED_PRODUCT: "product/GET_SELECTED_PRODUCT",
  //   GET_SELECTED_PRODUCT_SUCCESS: "product/GET_SELECTED_PRODUCT_SUCCESS",
  //   GET_SELECTED_PRODUCT_FAILED: "product/GET_SELECTED_PRODUCT_FAILED",

  LOGOUT: "product/LOGOUT",
  LOGOUT_SUCCESS: "product/LOGOUT_SUCCESS",
  LOGOUT_FAILED: "product/ LOGOUT_FAILED",


  UNSUB_CONNECTION: "product/UNSUB_CONNECTION",
  UNSUB_CONNECTION_SUCCESS: "product/UNSUB_CONNECTION_SUCCESS",
  UNSUB_CONNECTION_FAILED: "product/UNSUB_CONNECTION_FAILED",

  GET_SELECTED_CONNECTION_INDEX: "product/GET_SELECTED_CONNECTION_INDEX",
  GET_SELECTED_CONNECTION_INDEX_SUCCESS: "product/GET_SELECTED_CONNECTION_INDEX_SUCCESS",

  PASSWORD_UPDATE: action_header_password_update + "products/PASSWORD_UPDATE",
  PASSWORD_UPDATE_FAIL: action_header_password_update + "products/PASSWORD_UPDATE_FAIL",
  PASSWORD_UPDATE_SUCCESS: action_header_password_update + "products/PASSWORD_UPDATE_SUCCESS",

  RESET: "products/RESET",
  PASSWORD_UPDATE_SHOW_MODAL_OPEN: action_header_password_update + "products/PASSWORD_UPDATE_SHOW_MODAL_OPEN",
  PASSWORD_UPDATE_SHOW_MODAL_CLOSE: action_header_password_update + "products/PASSWORD_UPDATE_SHOW_MODAL_CLOSE",

  PASSWORD_UPDATE_SHOW_NOTIFY_MODAL_OPEN: action_header_password_update + "products/PASSWORD_UPDATE_SHOW_NOTIFY_MODAL_OPEN",
  PASSWORD_UPDATE_SHOW_NOTIFY_MODAL_CLOSE: action_header_password_update + "products/PASSWORD_UPDATE_SHOW_NOTIFY_MODAL_CLOSE",

  CLEAR_LANDING_STATE: "products/CLEAR_LANDING_STATE",

  PRODUCT_PURCHASE_CONTENT: "purchase/PRODUCT_PURCHASE_CONTENT",

  USER_UNSUB_STATUS: "user/USER_UNSUB_STATUS",

  CLEAR_UNSUB_STATUS: 'unsub/CLEAR_UNSUB_STATUS',


  REMOVE_PROFILE: "product/REMOVE_PROFILE",
  REMOVE_PROFILE_FAILED: "product/REMOVE_PROFILE_FAILED",
  REMOVE_PROFILE_SUCCESS: "product/REMOVE_PROFILE_SUCCESS",

  REACTIVATE_PRODUCT: "product/REACTIVATE_PRODUCT",
  REACTIVATE_PRODUCT_SUCCESS: "product/REACTIVATE_PRODUCT_SUCCESS",
  REACTIVATE_PRODUCT_FAILED: "product/REACTIVATE_PRODUCT_FAILED",

  GET_PACKAGE_PURCHASE_TABLE: "product/GET_PACKAGE_PURCHASE_TABLE",
  GET_PACKAGE_PURCHASE_TABLE_SUCCESS: "product/GET_PACKAGE_PURCHASE_TABLE_SUCCESS",
  GET_PACKAGE_PURCHASE_TABLE_FAILED: "product/GET_PACKAGE_PURCHASE_TABLE_FAILED",

  GET_PACKAGE_STATUS : "product/GET_PACKAGE_STATUS",
  GET_PACKAGE_STATUS_SUCCESS : "product/GET_PACKAGE_STATUS_SUCCESS",
  GET_PACKAGE_STATUS_FAILED : "product/GET_PACKAGE_STATUS_FAILED",
  GET_PACKAGE_STATUS_LOADING : "product/GET_PACKAGE_STATUS_LOADING",

  VERIFY_PREPAID_PAYMENT : "product/VERIFY_PREPAID_PAYMENT",
  VERIFY_PREPAID_PAYMENT_SUCCESS : "product/VERIFY_PREPAID_PAYMENT_SUCCESS",
  VERIFY_PREPAID_PAYMENT_FAILED : "product/VERIFY_PREPAID_PAYMENT_FAILED",

  GET_BANNER_TYPE_PRODUCTS: "banners/GET_BANNER_TYPE_PRODUCTS",
  GET_BANNER_TYPE_PRODUCTS_SUCCESS: "banners/GET_BANNER_TYPE_PRODUCTS_SUCCESS",
  GET_BANNER_TYPE_PRODUCTS_FAILED: "banners/GET_BANNER_TYPE_PRODUCTS_FAILED",  

  GET_BANNER_SUCCESS_BEFORE_LOGIN: "banners/GET_BANNER_SUCCESS_BEFORE_LOGIN"
};
