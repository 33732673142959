import { createStructuredSelector } from 'reselect'

const getTypesSelector = state => state.GeneralPages.types
const errorSelector = state => state.GeneralPages.typesError
const typesLoadingSelector = state => state.GeneralPages.typesLoading

const getDistrictsSelector = state => state.GeneralPages.districts
const submitSuccessSelector = state => state.GeneralPages.details
const submitFailedSelector = state => state.GeneralPages.detailsError

export default {
    getTypes: createStructuredSelector({
        types: getTypesSelector,
        typesLoading: typesLoadingSelector
    }),
    getError: createStructuredSelector({
        typesError: errorSelector,
        detailsError: submitFailedSelector
    }),

    getDistricts: createStructuredSelector({
        districts: getDistrictsSelector,
        details: submitSuccessSelector
    })
}