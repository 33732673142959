import { validArray } from "./ArrayValidator"

export const vodNormalization = (vodSubtypes, allVods) => {

    let array = []

    vodSubtypes.VODSubType.map((type, index) => {

        let languages = []
        let genre = []
        let trending = []
        let vods = []

        allVods.map((vod, i) => {
            if (type.typeName === vod.productSubTypeTypeName) {
                vod.languages && vod.languages.map((language, j) => {
                    languages.push(language);
                    return null
                })
                vod.categories && vod.categories.map((category, g) => {
                    genre.push(category)
                    return genre
                })
                vod.tags && vod.tags.map((tag, t) => {
                    if (tag.tag === "Trending") {
                        trending.push(vod)
                        return null
                    }
                    return trending
                })
                vods.push(vod)
            }

            return null
        })
        if (validArray(vods) || validArray(trending)) {
            array.push({
                typeName: type.typeName,
                displayName: type.displayName,
                languages: [...new Map(languages.map(obj => [JSON.stringify(obj), obj])).values()],
                genre: [...new Map(genre.map(obj => [JSON.stringify(obj), obj])).values()],
                trending: trending,
                vods: vods
            })
        }


    })
    return array

}