import React from 'react'
const ViewChannelBundle = ({isChannelBundle, onClick, id}) => {
   
   if(isChannelBundle){
       return (<div className={`view-channel-bundle`} id={id} onClick={onClick}>

        <div id={id} style={{ backgroundColor: "#4fa1d9", marginTop: "auto" }}>
            View Bundle
        </div>
    </div>)
   }else{
       return null
   }
}

export default ViewChannelBundle