import React from 'react'
import { Container, Row } from 'react-bootstrap';
import FeaturedPackageView from '../LandingPageComponents/FeaturedPackageView';
import DataBundle from '../LandingPageComponents/DataBundle';
import ViewAllButton from '../ViewAllButton/ViewAllButton';

function FeaturedPackage({ isLoggedIn, connectionDetails, featuredPackages, featuredChannels, numberOfScreensBundled, numberOfScreensPurchased, dataBundlesPurchased, onClickPackage, numberOfScreensPurchasedPromotional, numberOfScreensBundledPromotional }) {
  let connectionDetailsClassName, dataBundleRender
  if (isLoggedIn && connectionDetails && connectionDetails[localStorage.selectedUserIndex] && connectionDetails[localStorage.selectedUserIndex].broadband) {
    connectionDetailsClassName = "col-9 dataBundlerResponsive";
  } else {
    connectionDetailsClassName = 'col-12';
  }

  if (isLoggedIn && connectionDetails && connectionDetails[localStorage.selectedUserIndex] && connectionDetails[localStorage.selectedUserIndex].broadband) {
    dataBundleRender = <div className="col-3 dataBundlerResponsive background-color-254E68">
      <div className="height-70">
        <DataBundle featuredPackages={featuredPackages} dataBundlesPurchased={dataBundlesPurchased} />
      </div>
      <div className="all-data-bundles">
        <Row>
          <ViewAllButton path='/databundles' className="btn-round" btnText='All Data Bundles' />
        </Row>
      </div>
    </div>
  }

  return (
    <div className="featured-Package-Content">
      <Container>
        <Row>
          <div className={connectionDetailsClassName}>
            <h4 className="title">{isLoggedIn ? "My Package" : "Featured Packages"} </h4>
            <Row style={{ justifyContent: "center" }}>
              <FeaturedPackageView featuredPackages={featuredPackages} isLoggedIn={isLoggedIn} onClickPackage={onClickPackage} featuredChannels={featuredChannels} numberOfScreensBundled={numberOfScreensBundled} numberOfScreensPurchased={numberOfScreensPurchased} numberOfScreensBundledPromotional={numberOfScreensBundledPromotional} numberOfScreensPurchasedPromotional={numberOfScreensPurchasedPromotional} />
            </Row>
            <Row>
              <ViewAllButton path='/packages' className="btn-round" btnText='View All' />
            </Row>
          </div>
          {
            dataBundleRender
          }
        </Row>
      </Container>
    </div>
  )
}

export default FeaturedPackage
