import React from 'react'
import { Col } from 'react-bootstrap';
import dummy from '../../util/dummy.json'
import { FeaturedSVODCard } from './DummyComponent'

export default function DummyFeaturedSvod() {
    return (
        dummy.featuredSvod.slice(0, 3).map(featuredSvod => {
            if (featuredSvod.status === "PRODUCT_STATUS_ACTIVE") {
                return (
                    <Col key={featuredSvod.id} xs={12} md={6} lg={4}>
                        <FeaturedSVODCard />
                    </Col>
                )
            }
            return null
        })
    )
}
