const action_header_filter = "filter/";
const action_header_user_product_by_type = "user_product/"
export default {
  GET_BASE_PRODUCT: "productPage/GET_BASE_PRODUCT",
  GET_BASE_PRODUCT_SUCCESS: "productPage/GET_BASE_PRODUCT_SUCCESS",
  GET_BASE_PRODUCT_FAILED: "productPage/GET_BASE_PRODUCT_FAILED",
  GET_BASE_PRODUCT_LOADING: "productPage/GET_BASE_PRODUCT_LOADING",

  GET_ALL_PRODUCTS: "product/GET_ALL_PRODUCTS",
  GET_ALL_PRODUCTS_SUCCESS: "product/GET_ALL_PRODUCTS_SUCCESS",
  GET_ALL_PRODUCTS_FAILED: "product/GET_ALL_PRODUCTS_FAILED",
  GET_ALL_PRODUCTS_LOADING: "products/GET_ALL_PRODUCTS_LOADING",

  GET_CHANNELS: "products/GET_CHANNELS",
  GET_CHANNELS_SUCCESS: "products/GET_CHANNELS_SUCCESS",
  GET_CHANNELS_FAILED: "products/GET_CHANNELS_FAILED",
  GET_CHANNELS_CLEAR: "products/GET_CHANNELS_CLEAR",

  GET_ALL_CHANNELS: "products/GET_ALL_CHANNELS",
  GET_ALL_CHANNELS_SUCCESS: "products/GET_ALL_CHANNELS_SUCCESS",
  GET_ALL_CHANNELS_FAILED: "products/GET_ALL_CHANNELS_FAILED",

  GET_ALL_CHANNEL_CATEGORIES: "products/GET_ALL_CHANNEL_CATEGORIES",
  GET_ALL_CHANNEL_CATEGORIES_SUCCESS:
    "products/GET_ALL_CHANNEL_CATEGORIES_SUCCESS",
  GET_ALL_CHANNEL_CATEGORIES_FAILED:
    "products/GET_ALL_CHANNEL_CATEGORIES_FAILED",
  GET_ALL_CHANNEL_CATEGORIES_LOADING:
    "products/GET_ALL_CHANNEL_CATEGORIES_LOADING",

  GET_PRODUCTS_TYPES: "products/ GET_PRODUCTS_TYPES",
  GET_PRODUCTS_TYPES_SUCCESS: "products/ GET_PRODUCTS_TYPES_SUCCESS",
  GET_PRODUCTS_TYPES_FAILED: "products/ GET_PRODUCTS_TYPES_FAILED",

  GET_ONDEMAND_SUB_TYPES: "products/ GET_ONDEMAND_SUB_TYPES",
  GET_ONDEMAND_SUB_TYPES_SUCCESS: "products/ GET_ONDEMAND_SUB_TYPES_SUCCESS",
  GET_ONDEMAND_SUB_TYPES_FAILED: "products/ GET_ONDEMAND_SUB_TYPES_FAILED",
  GET_ONDEMAND_SUB_TYPES_LOADING: "products/GET_ONDEMAND_SUB_TYPES_LOADING",

  GET_ALL_LANGUAGES: "products/GET_ALL_LANGUAGES",
  GET_ALL_LANGUAGES_SUCCESS: "products/GET_ALL_LANGUAGES_SUCCESS",
  GET_ALL_LANGUAGES_FAILED: "products/GET_ALL_LANGUAGES_FAILED",

  GET_TRENDING_VOD: "products/GET_TRENDING_VOD",
  GET_TRENDING_VOD_LOADING: "products/GET_TRENDING_VOD_LOADING",
  GET_TRENDING_VOD_SUCCESS: "products/GET_TRENDING_VOD_SUCCESS",
  GET_TRENDING_VOD_FAILED: "products/GET_TRENDING_VOD_FAILED",

  GET_ALL_VODS: "products/GET_ALL_VODS",
  GET_ALL_VODS_LOADING: "products/GET_ALL_VODS_LOADING",
  GET_ALL_VODS_SUCCESS: "products/GET_ALL_VODS_SUCCESS",
  GET_ALL_VODS_FAILED: "products/GET_ALL_VODS_FAILED",

  GET_ALL_BASE_PRODUCT_TYPES: "products/GET_ALL_BASE_PRODUCT_TYPES",
  GET_ALL_BASE_PRODUCT_TYPES_SUCCESS:
    "products/GET_ALL_BASE_PRODUCT_TYPES_SUCCESS",
  GET_ALL_BASE_PRODUCT_TYPES_FAILED:
    "products/GET_ALL_BASE_PRODUCT_TYPES_FAILED",

  // GET_ALL_PACKAGE_TYPES: "products/GET_ALL_PACKAGE_TYPES",
  // GET_ALL_PACKAGE_TYPES_SUCCESS: "products/GET_ALL_PACKAGE_TYPES_SUCCESS",
  // GET_ALL_PACKAGE_TYPES_FAILED: "products/GET_ALL_PACKAGE_TYPES_FAILED",

  GET_ALL_CHANNEL_BUNDLE_CATEGORIES:
    "products/GET_ALL_CHANNEL_BUNDLE_CATEGORIES",
  GET_ALL_CHANNEL_BUNDLE_CATEGORIES_SUCCESS:
    "products/GET_ALL_CHANNEL_BUNDLE_CATEGORIES_SUCCESS",
  GET_ALL_CHANNEL_BUNDLE_CATEGORIES_FAILED:
    "products/GET_ALL_CHANNEL_BUNDLE_CATEGORIES_FAILED",

  GET_DATA_BUNDLE: "products/GET_DATA_BUNDLE",
  GET_DATA_BUNDLE_SUCCESS: "products/GET_DATA_BUNDLE_Success",
  GET_DATA_BUNDLE_FAILED: "products/GET_DATA_BUNDLE_FAILED",

  CLEAR_ALL_CHANNELS: "products/CLEAR_ALL_CHANNELS",

  GET_SELECTED_PRODUCT: "product/GET_SELECTED_PRODUCT",
  GET_SELECTED_PRODUCT_SUCCESS: "product/GET_SELECTED_PRODUCT_SUCCESS",
  GET_SELECTED_PRODUCT_FAILED: "product/GET_SELECTED_PRODUCT_FAILED",

  RESET_FORM: "product/RESET_FORM",

  PRODUCT_SUBSCRIBE: "product/PRODUCT_SUBSCRIBE",
  PRODUCT_SUBSCRIBE_SUCCESS: "product/PRODUCT_SUBSCRIBE_SUCCESS",
  PRODUCT_SUBSCRIBE_FAILED: "product/PRODUCT_SUBSCRIBE_FAILED",

  PRODUCT_UNSUBSCRIBE: "product/PRODUCT_UNSUBSCRIBE",
  PRODUCT_UNSUBSCRIBE_SUCCESS: "product/PRODUCT_UNSUBSCRIBE_SUCCESS",
  PRODUCT_UNSUBSCRIBE_FAILED: "product/PRODUCT_UNSUBSCRIBE_FAILED",

  FILTER_VOD: action_header_filter + "product/FILTER_VOD",

  GET_USER_PRODUCT_BY_TYPE: action_header_user_product_by_type + "GET_USER_PRODUCT_BY_TYPE",
  GET_USER_PRODUCT_BY_TYPE_SUCCESS: action_header_user_product_by_type + "GET_USER_PRODUCT_BY_TYPE_SUCCESS",
  GET_USER_PRODUCT_BY_TYPE_FAILED: action_header_user_product_by_type + "GET_USER_PRODUCT_BY_TYPE_FAILED",

  GET_PURCHASE_TABLE: "purchaseTable/GET_PURCHASE_TABLE",
  GET_PURCHASE_TABLE_SUCCESS: "purchaseTable/GET_PURCHASE_TABLE_SUCCESS",
  GET_PURCHASE_TABLE_FAILED: "purchaseTable/GET_PURCHASE_TABLE_FAILED",

  GET_VOD_ITEM: "product/GET_VOD_ITEM",
  GET_VOD_ITEM_SUCCESS: "product/GET_VOD_ITEM_SUCCESS",
  GET_VOD_ITEM_FAILED: "product/GET_VOD_ITEM_FAILED",

  VERIFY_CHANNEL_PURCHASE: "products/VERIFY_CHANNEL_PURCHASE",
  VERIFY_CHANNEL_PURCHASE_SUCCESS: "products/VERIFY_CHANNEL_PURCHASE_SUCCESS",
  VERIFY_CHANNEL_PURCHASE_FAILED: "products/VERIFY_CHANNEL_PURCHASE_FAILED",

  VERIFY_CHANNEL_UNSUB: "products/VERIFY_CHANNEL_UNSUB",
  VERIFY_CHANNEL_UNSUB_SUCCESS: "products/VERIFY_CHANNEL_UNSUB_SUCCESS",
  VERIFY_CHANNEL_UNSUB_FAILED: "products/VERIFY_CHANNEL_UNSUB_FAILED",

  CLEAR_PURCHASE_MESSAGE: "products/CLEAR_PURCHASE_MESSAGE"
};