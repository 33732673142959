import { createAction } from "redux-actions";
import types from "./types";

export default {
  getBaseProducts: createAction(types.GET_BASE_PRODUCT),
  getBaseProductsSuccess: createAction(types.GET_BASE_PRODUCT_SUCCESS),
  getBaseProductsFailed: createAction(types.GET_BASE_PRODUCT_FAILED),
  getBaseProductsLoading: createAction(types.GET_BASE_PRODUCT_LOADING),

  getAllProducts: createAction(types.GET_ALL_PRODUCTS),
  getAllProductsSuccess: createAction(types.GET_ALL_PRODUCTS_SUCCESS),
  getAllProductsFailed: createAction(types.GET_ALL_PRODUCTS_FAILED),
  getAllProductsLoading: createAction(types.GET_ALL_PRODUCTS_LOADING),

  getChannels: createAction(types.GET_CHANNELS),
  getChannelsSuccess: createAction(types.GET_CHANNELS_SUCCESS),
  getChannelsFailed: createAction(types.GET_CHANNELS_FAILED),
  getChannelsClear: createAction(types.GET_CHANNELS_CLEAR),

  getAllChannels: createAction(types.GET_ALL_CHANNELS),
  getAllChannelsSuccess: createAction(types.GET_ALL_CHANNELS_SUCCESS),
  getAllChannelsFailed: createAction(types.GET_ALL_CHANNELS_FAILED),

  getAllChannelCategories: createAction(types.GET_ALL_CHANNEL_CATEGORIES),
  getAllChannelCategoriesSuccess: createAction(
    types.GET_ALL_CHANNEL_CATEGORIES_SUCCESS
  ),
  getAllChannelCategoriesFailed: createAction(
    types.GET_ALL_CHANNEL_CATEGORIES_FAILED
  ),
  getAllChannelCategoriesLoading: createAction(
    types.GET_ALL_CHANNEL_CATEGORIES_LOADING
  ),

  getProductTypes: createAction(types.GET_PRODUCTS_TYPES),
  getProductTypesSuccess: createAction(types.GET_PRODUCTS_TYPES_SUCCESS),
  getProductTypesFailed: createAction(types.GET_PRODUCTS_TYPES_FAILED),

  getOnDemandSubTypes: createAction(types.GET_ONDEMAND_SUB_TYPES),
  getOnDemandSubTypesSuccess: createAction(
    types.GET_ONDEMAND_SUB_TYPES_SUCCESS
  ),
  getOnDemandSubTypesFailed: createAction(types.GET_ONDEMAND_SUB_TYPES_FAILED),
  getOnDemandSubTypesLoading: createAction(
    types.GET_ONDEMAND_SUB_TYPES_LOADING
  ),

  getAllLanguages: createAction(types.GET_ALL_LANGUAGES),
  getAllLanguagesSuccess: createAction(types.GET_ALL_LANGUAGES_SUCCESS),
  getAllLanguagesFailed: createAction(types.GET_ALL_LANGUAGES_FAILED),

  getTrendingVod: createAction(types.GET_TRENDING_VOD),
  getTrendingVodLoading: createAction(types.GET_TRENDING_VOD_LOADING),
  getTrendingVodSuccess: createAction(types.GET_TRENDING_VOD_SUCCESS),
  getTrendingVodFailed: createAction(types.GET_TRENDING_VOD_FAILED),

  getAllVod: createAction(types.GET_ALL_VODS),
  getAllVodLoading: createAction(types.GET_ALL_VODS_LOADING),
  getAllVodSuccess: createAction(types.GET_ALL_VODS_SUCCESS),
  getAllVodFailed: createAction(types.GET_ALL_VODS_FAILED),

  getAllBaseProductsTypes: createAction(types.GET_ALL_BASE_PRODUCT_TYPES),
  getAllBaseProductsTypesSuccess: createAction(
    types.GET_ALL_BASE_PRODUCT_TYPES_SUCCESS
  ),
  getAllBaseProductsTypesFailed: createAction(
    types.GET_ALL_BASE_PRODUCT_TYPES_FAILED
  ),

  // getAllPackageTypes: createAction(types.GET_ALL_PACKAGE_TYPES),
  // getAllPackageTypesSuccess: createAction(types.GET_ALL_PACKAGE_TYPES_SUCCESS),
  // getAllPackageTypesFailed: createAction(types.GET_ALL_PACKAGE_TYPES_FAILED),

  getAllChannelBundleCategories: createAction(
    types.GET_ALL_CHANNEL_BUNDLE_CATEGORIES
  ),
  getAllChannelBundleCategoriesSuccess: createAction(
    types.GET_ALL_CHANNEL_BUNDLE_CATEGORIES_SUCCESS
  ),
  getAllChannelBundleCategoriesFailed: createAction(
    types.GET_ALL_CHANNEL_BUNDLE_CATEGORIES_FAILED
  ),

  // getDataBundle: createAction(types.GET_DATA_BUNDLE),
  // getDataBundleSuccess: createAction(types.GET_DATA_BUNDLE_SUCCESS),
  // getDataBundleFailed: createAction(types.GET_DATA_BUNDLE_FAILED),

  clearAllChannels: createAction(types.CLEAR_ALL_CHANNELS),

  getSelectedProduct: createAction(types.GET_SELECTED_PRODUCT),
  getSelectedProductSuccess: createAction(types.GET_SELECTED_PRODUCT_SUCCESS),
  getSelectedProductFailed: createAction(types.GET_SELECTED_PRODUCT_SUCCESS),

  resetForm: createAction(types.RESET_FORM),

  productSubscribe: createAction(types.PRODUCT_SUBSCRIBE),
  productSubscribeSuccess: createAction(types.PRODUCT_SUBSCRIBE_SUCCESS),
  productSubscribeFailed: createAction(types.PRODUCT_SUBSCRIBE_FAILED),

  productUnSubscribe: createAction(types.PRODUCT_UNSUBSCRIBE),
  productUnSubscribeSuccess: createAction(types.PRODUCT_UNSUBSCRIBE_SUCCESS),
  productUnSubscribeFailed: createAction(types.PRODUCT_UNSUBSCRIBE_FAILED),

  filterVOD: createAction(types.FILTER_VOD),

  getUserProductByType: createAction(types.GET_USER_PRODUCT_BY_TYPE),
  getUserProductByTypeSuccess: createAction(types.GET_USER_PRODUCT_BY_TYPE_SUCCESS),
  getUserProductByTypeFailed: createAction(types.GET_USER_PRODUCT_BY_TYPE_FAILED),

  getPurchaseTable: createAction(types.GET_PURCHASE_TABLE),
  getPurchaseTableSuccess: createAction(types.GET_PURCHASE_TABLE_SUCCESS),
  getPurchaseTableFailed: createAction(types.GET_PURCHASE_TABLE_FAILED),

  getVodItems: createAction(types.GET_VOD_ITEM),
  getVodItemsSuccess: createAction(types.GET_VOD_ITEM_SUCCESS),
  getVodItemsFailed: createAction(types.GET_VOD_ITEM_FAILED),

  verifyPurchaseChannel: createAction(types.VERIFY_CHANNEL_PURCHASE),
  verifyPurchaseChannelSuccess: createAction(types.VERIFY_CHANNEL_PURCHASE_SUCCESS),
  verifyPurchaseChannelFailed: createAction(types.VERIFY_CHANNEL_PURCHASE_FAILED),

  verifyUnsubChannel: createAction(types.VERIFY_CHANNEL_UNSUB),
  verifyUnsubChannelSuccess: createAction(types.VERIFY_CHANNEL_UNSUB_SUCCESS),
  verifyUnsubChannelFailed: createAction(types.VERIFY_CHANNEL_UNSUB_FAILED),

  clearProductMessage: createAction(types.CLEAR_PURCHASE_MESSAGE)
};