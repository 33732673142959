import reducers from "./reducers";

import passwordRecoverySelectors from "./selectors";
import passwordRecoveryService from "./service";
import passwordRecoveryTypes from "./types";
import passwordRecoveryActions from "./actions";

export {
    passwordRecoverySelectors,
    passwordRecoveryService,
    passwordRecoveryActions,
    passwordRecoveryTypes
};

export default reducers;