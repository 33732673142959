import types from './types'
import { handleActions } from 'redux-actions'
import { getConnectionType } from '../../../util/ArrayValidator'
import { packageFiltering } from '../../../util/FilterPackages'

const initialState = {
    subscribe: null,
    error: "",
    productsLoading: true,
    baseProductsLoading: true,
    dataBundleLoading: true,
    consentLoading: true,
    statusLoading: false,
    subscribeLoading: true,
    dataBundlePurchaseStatusLoading: true
}

export default handleActions({

    [types.ADD_CONNECTION]: (state, { payload }) => (
        { ...state, subscribe: null, error: "", subscribeLoading: true }
    ),
    [types.ADD_CONNECTION_SUCCESS]: (state, { payload }) => (
        { ...state, subscribe: payload, error: "", subscribeLoading: false }
    ),
    [types.ADD_CONNECTION_FAILED]: (state, { payload }) => (
        { ...state, subscribe: null, error: payload, subscribeLoading: false }
    ),
    [types.FETCH_PRODUCTS_SUCCESS]: (state, { payload }) => {
        let userType = getConnectionType()
        let allProductsArray;
        if (payload && Array.isArray(payload) && payload) {
            if (userType === "7" || userType === "6") {
                allProductsArray = payload.reverse();
            } else {
                allProductsArray = payload;
            }
        }
        let filteredPackages = packageFiltering(allProductsArray)
        return {
            ...state,
            products: allProductsArray,
            productsError: null,
            productsLoading: false,
            filteredPackages: filteredPackages
        }
    },
    [types.FETCH_PRODUCTS_FAILED]: (state, { payload }) => (
        { ...state, products: "", productsError: payload, productsLoading: false }
    ),
    [types.FETCH_SECONDARY_PRODUCTS_SUCCESS]: (state, { payload }) => (
        { ...state, secondaryProducts: payload, secondaryProductsError: null }
    ),
    [types.FETCH_SECONDARY_PRODUCTS_FAILED]: (state, { payload }) => (
        { ...state, secondaryProducts: "", secondaryProductsError: payload }
    ),
    [types.GET_ALLOWED_SECONDARY_PACKAGES_SUCCESS]: (state, { payload }) => (
        { ...state, secondaryAllowedProducts: payload, secondaryAllowedProductsError: null }
    ),
    [types.GET_ALLOWED_SECONDARY_PACKAGES_FAILED]: (state, { payload }) => (
        { ...state, secondaryAllowedProducts: "", secondaryAllowedProductsError: payload }
    ),
    [types.GET_PRODUCT_TYPES_SUCCESS]: (state, { payload }) => (
        { ...state, allPackageTypes: payload, allPackageTypesError: {} }
    ),
    [types.GET_PRODUCT_TYPES_FAILED]: (state, { payload }) => (
        { ...state, allPackageTypes: {}, allPackageTypesError: payload }
    ),
    [types.GET_PRODUCTS_SUCCESS]: (state, { payload }) => (
        { ...state, baseProducts: payload, baseProductsError: null, baseProductsLoading: false, statusError: undefined }
    ),
    [types.GET_PRODGET_PRODUCTSUCTS_FAILED]: (state, { payload }) => (
        { ...state, baseProducts: {}, baseProductsError: payload, baseProductsLoading: false, statusError: undefined }
    ),
    [types.GET_PRODUCTS_LOADING]: (state, { payload }) => (
        { ...state, baseProducts: {}, baseProductsError: payload, baseProductsLoading: true }
    ),
    [types.GET_PRODUCTS_LOADING]: (state, { payload }) => (
        { ...state, baseProductsLoading: true, dataBundle: undefined }
    ),
    [types.GET_DATA_BUNDLE_SUCCESS]: (state, { payload }) => (
        { ...state, dataBundle: payload, dataBundleError: null, dataBundleLoading: false }
    ),
    [types.GET_DATA_BUNDLE_FAILED]: (state, { payload }) => (
        { ...state, dataBundle: {}, dataBundleError: payload, dataBundleLoading: false }
    ),
    [types.CLEAR_PRODUCTS]: (state, {
        payload
    }) => ({
        ...state,
        // products: "",
        secondaryProducts: "",
        baseProducts: undefined,
        productsLoading: true,
        baseProductsLoading: true,
        dataBundleLoading: true,
        dataBundle: null,
        allPackageTypes: undefined,
        products: undefined,
        statusLoading: false,
        statusError: undefined,
        status: undefined,
        error: "",
    }),
    [types.CREATE_CONSENT_SUCCESS]: (state, { payload }) => (
        { ...state, consent: payload, consentError: null, consentLoading: false, error: null }
    ),
    [types.CREATE_CONSENT_FAILED]: (state, { payload }) => (
        { ...state, consent: {}, consentError: payload, consentLoading: false, error: null }
    ),
    [types.RETRY_PACKAGE]: (state, { payload }) => (
        { ...state, retryLoading: true, error: null }
    ),
    [types.RETRY_PACKAGE_SUCCESS]: (state, { payload }) => {
        if (payload.status === "SUCCESS") {
            return { ...state, retry: payload, retryError: undefined, retryLoading: false, error: null, subscribe: state.paymentDetails, error: "" }
        } else {
            return { ...state, retry: payload, subscribe: payload, retryError: undefined, retryLoading: false, error: null, error: "" }
        }

    },
    [types.RETRY_PACKAGE_FAILED]: (state, { payload }) => {
        return { ...state, retry: {}, retryError: payload, retryLoading: false, error: null }
    },
    [types.PREPAID_PACKAGE_PURCHASED_STATUS]: (state, { payload }) => (
        { ...state, status: undefined, statusError: undefined, statusLoading: true }
    ),
    [types.PREPAID_PACKAGE_PURCHASED_STATUS_SUCCESS]: (state, { payload }) => (
        { ...state, status: payload, statusError: null, statusLoading: false }
    ),
    [types.PREPAID_PACKAGE_PURCHASED_STATUS_FAILED]: (state, { payload }) => (
        { ...state, status: {}, statusError: payload, statusLoading: false }
    ),
    [types.PREPAID_PACKAGE_PURCHASED_STATUS_LOADING]: (state, { payload }) => (
        { ...state, status: undefined, statusError: undefined, statusLoading: false }
    ),
    [types.SAVE_PAYMENT_DETAILS]: (state, { payload }) => (
        { ...state, paymentDetails: payload }
    ),
    [types.FILTER_PACKAGES]: (state, { payload }) => {
        let filteredPackages = state.filteredPackages && state.filteredPackages.map(function (e) { return e.filterType; }).indexOf(payload);
        return {
            ...state,
            products: state.filteredPackages[filteredPackages].filteredPackages,
        }
    },
    [types.VALIDATE_CONSENT]: (state, { payload }) => (
        { ...state, consentValidationLoading: true, consentValidation: false, consentValidationError: false }
    ),
    [types.VALIDATE_CONSENT_SUCCESS]: (state, { payload }) => (
        { ...state, consentValidationLoading: false, consentValidation: payload, consentValidationError: false }
    ),
    [types.VALIDATE_CONSENT_FAILED]: (state, { payload }) => (
        { ...state, consentValidationLoading: false, consentValidation: false, consentValidationError: payload, subscribeLoading: false }
    ),
    [types.CHECK_PACKAGE_PROVISIONING_SUCCESS]: (state, { payload }) => (
        { ...state, provisioningStatus: payload, subscribe: payload, provisioningStatusError: null, subscribeLoading: false }
    ),
    [types.CHECK_PACKAGE_PROVISIONING_FAILED]: (state, { payload }) => (
        { ...state, provisioningStatus: {}, provisioningStatusError: payload }
    ),
    [types.GET_DATA_BUNDLE_PURCHASE_STATUS]: (state, { payload }) => (
        { ...state, dataBundlePurchaseStatusLoading: true, dataBundlePurchaseStatus: false, dataBundlePurchaseStatusError: false }
    ),
    [types.GET_DATA_BUNDLE_PURCHASE_STATUS_SUCCESS]: (state, { payload }) => (
        { ...state, dataBundlePurchaseStatusLoading: false, dataBundlePurchaseStatus: payload, dataBundlePurchaseStatusError: false }
    ),
    [types.GET_DATA_BUNDLE_PURCHASE_STATUS_FAILED]: (state, { payload }) => (
        { ...state, dataBundlePurchaseStatusLoading: false, dataBundlePurchaseStatus: false, dataBundlePurchaseStatusError: payload }
    ),
}, initialState)
