import React from 'react'
import { Tabs, Tab } from 'react-bootstrap';
import { CHANNEL_BUNDLE_ID } from '../../util/Constants';

export const CustomTabHeader = ({
    tabs, defaultActiveKey, children, onSelect, className, serviceType, activeKey, isPromotional

}) => {
    console.log(defaultActiveKey)
    return <>
        {/* checkid */}
        <Tabs className="custom-tab" defaultActiveKey={defaultActiveKey} transition={false} id="custom-tab" onSelect={onSelect} activeKey={activeKey}>
            {tabs && Array.isArray(tabs) && tabs.map((v, index) => {
                if (v != null) {
                    if (((serviceType !== "Prepaid" && serviceType !== "Mobitel") && v.name === "MY PAYMENTS")) {
                        return null
                    }
                    if (v.id !== CHANNEL_BUNDLE_ID) {
                        if (!isPromotional && v.typeName !== "screens") {
                            return <Tab eventKey={index} title={v.name} className={className}>
                                {children}
                            </Tab>
                        } else {
                            return <Tab eventKey={index} title={v.name} className={className}>
                                {children}
                            </Tab>
                        }
                    }
                }
                return null
            })
            }
        </Tabs>
    </>
}