import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { Row, Col, Container } from 'react-bootstrap'
import { singleProductSelectors, singleProductActions } from './ducks';
import Card from '../../components/CardComponent/Card';
import { getNestedValue, getLoggedInStatus, getConnectionId } from "../../util/ArrayValidator"
import './ProductPage.scss';
import DaysToExpire from "../../components/DaysToExpire/DaysToExpire";
import Description from '../../components/controls/Description';
import DummyProductPageView from '../../components/DummyComponent/DummyProductPageView';

class SubProduct extends Component {
    constructor(props) {
        super(props);

        this.state = {
            channel: undefined,
        }
    }

    componentDidMount() {
        let id = this.props.match && this.props.match.params.id
        if(getLoggedInStatus()){
            this.props.getProduct({ productId: id, subProduct: true})
        }else{
            this.props.getProduct(id)
        }
        
    }
    componentWillUnmount(){
        this.props.clearProductDetails()
    }

    render() {
        const { productDetails, productDetailsLoading } = this.props
        let daysRemainingToExpire = getNestedValue(productDetails, ['daysRemainingToExpire']);
        let additionalDetails = getNestedValue(productDetails, ['additionalDetails']);
        let description = getNestedValue(productDetails, ['description']);
        let feature = getNestedValue(productDetails, ['feature']);
        let additionalDetailsList
        let showTstv, showPip, showCpvr
        if (productDetails && productDetails.productTypeTypeName === 'vod') {
            additionalDetailsList = <Col>{productDetails && (productDetails.pgRating || (additionalDetails && Array.isArray(additionalDetails))) &&
                <div className="information-vod">
                    <div>
                        {additionalDetails && Array.isArray(additionalDetails) && additionalDetails.map(details => {
                            return <><p>{details.code} : <span>{details.name}</span></p></>
                        })
                        }
                        {productDetails && productDetails.pgRating && <><span>PG Rating</span> : <span>{productDetails.pgRating}</span></>}
                    </div>
                </div>}
            </Col>
        } else {
            additionalDetailsList = null
        }

         //show the features in the channels
    if (productDetails && productDetails.feature !== null) {
        if (getNestedValue(productDetails, ['feature', 'tstv'])) {
            showTstv = <span><i className="fa fa-history margin-right-5" /> TSTV</span>
        } else {
            showTstv = null
        }
        // if (productDetails && productDetails.feature.pip) {
        if (getNestedValue(productDetails, ['feature', 'pip'])) {
            showPip = <span><i className="fa fa-battery-quarter margin-right-5" /> PIP</span>
        } else {
            showPip = null;
        }
        if (getNestedValue(productDetails, ['feature', 'cpvr'])) {
            showCpvr = <span><i className="fa fa-video margin-right-5" /> CPVR</span>
        } else {
            showCpvr = null
        }
    }
        
        if(productDetailsLoading){
            return (<React.Fragment>
                <DummyProductPageView
                    productType="vod"
                    showPurchasedIcon={false}
                    priceDisplay={false}
                    productDetails={productDetails}
                />
            </React.Fragment>)
        }else{
            return (
                <Container style={{ color: "#d0d0d0", marginTop: 35 }}>
                <Row>
               
                    <Col sm={6}>
                        <Row>
                            <Col><h4>{productDetails.name}</h4></Col>
                            {feature && (feature.tstv || feature.pip || feature.cpvr) ?
                                <Col>
                                    <div style={{ display: 'grid' }}>
                                        {showTstv}
                                        {showPip}
                                        {showCpvr}
                                    </div>
                                </Col> : null
                            }
                        </Row>
                        <Row className="days-to-expire">
                            <DaysToExpire daysToExpire={daysRemainingToExpire} />
                        </Row>
                    </Col>
                    <Col sm={7}></Col>
                </Row>
                <br />
                <Row>
                    <Col sm={3} md={3} lg={2}>
                        <Card
                            hover={false}
                            showOptions={productDetails.feature}
                            showAddNew={false}
                            imageURL={productDetails.imageURL}
                            type={productDetails.productTypeTypeName} >
                        </Card>
                    </Col>
                    {additionalDetailsList}
                </Row>
                <Row style={{ textAlign: "justify" }}>
                    <Col>
                        <Description
                            description={description}
                        />
                    </Col>
                </Row>
            </Container>
            )
        }
    }
}


const mapStateToProps = (state) => {
    return {
       
        productDetails: singleProductSelectors.getProductDetails(state),
        productDetailsLoading: singleProductSelectors.getProductDetailsLoading(state),
    }
};
export default withRouter(connect(
    mapStateToProps,
    singleProductActions
)(SubProduct));