import React from 'react'
import LandingNavBar from '../components/navbar/LandingNavBar';
import PageFooter from '../components/footer/PageFooter';

const LandingLayout = (ViewComponent) => {
    return class extends React.Component {
        render() {
            return (
                <>
                    <LandingNavBar />
                    <div className="page-footer-fixed">
                        <ViewComponent />
                    </div>
                    <PageFooter/>
                </>
            )
        }
    }
};

export default LandingLayout
