import React from 'react';
import { Container, Row } from 'react-bootstrap';
import FeaturedChannels from '../LandingPageComponents/FeaturedChannels';
import ViewAllButton from '../ViewAllButton/ViewAllButton';
import { AllChannels } from "../../util/Constants"

const FeaturedChannelsContent = ({ featuredChannels, onClick, showDate, isLoggedIn, channelProduct, informative, renderCenterRightControls, renderCenterLeftControls }) => {
  return (
    <Container>
      <div>
        <FeaturedChannels
          featuredChannels={featuredChannels}
          onClick={onClick}
          showDate={false}
          isLoggedIn={isLoggedIn}
          channelProduct={true}
          informative={true}
          renderCenterRightControls={renderCenterRightControls}
          renderCenterLeftControls={renderCenterLeftControls}
        />
        <Row>
          <ViewAllButton path={AllChannels} className="btn-round" btnText='View All' />
        </Row>
      </div>
    </Container>
  )
}

export default FeaturedChannelsContent;