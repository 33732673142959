import React from 'react'
import ProgressiveImage from 'react-progressive-image';
import { DummyCard } from '../DummyComponent/DummyComponent'

function ProgressiveImageComponent({ imageURL, showAddNew, type, isDummy }) {
    let cardBorder
    if (type === 'channel') {
        if (showAddNew) {
            cardBorder = 'channel-image add-new image-background'
        } else {
            cardBorder = 'channel-image add-new-no image-background'
        }
    } else if (type === 'svod') {
        cardBorder = 'image-background-svod'
    } else if (type === 'onDemand' || type === 'vod' || type === 'databundle' || type === 'screens') {
        cardBorder = 'image-background'
    }
    // var srcImage = ''
    // if (type === 'channel') {
    //     srcImage = './../../images/ChannelnotFound.jpg'
    // } else if (type === 'onDemand') {
    //     srcImage = './../../images/movieImageNotFound.jpg'
    // } else if (type === 'svod') {
    //     srcImage = './../../images/svod-placeholder.png'
    // }
    if (isDummy) {
        return (
            <DummyCard />
        )
    }
    else {
        return (
            <ProgressiveImage
                src={`/${imageURL}`}
                placeholder={type === "onDemand" ?
                    require("./../../images/movieImageNotFound.jpg") :
                    type === "channel" ?
                        require("./../../images/ChannelnotFound.jpg") :
                        require("./../../images/movieImageNotFound.jpg")
                }
            >
                {(src, loading) => (
                    <img
                        className={cardBorder}
                        src={src}
                        alt=''
                    />
                )}
            </ProgressiveImage>
        )
    }
}

export default ProgressiveImageComponent
