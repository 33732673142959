import { CHANNELS_PRODUCT_TYPE, CHANNEL_BUNDLE_ID } from "../../../util/Constants"

export const productSorting = (product, selectedTabIndex) => {
    let channelArray = []

    product && product.map((a, index) => {
        if ((a.productTypeName === "Channels" || a.productTypeId === CHANNEL_BUNDLE_ID)) {
            a.categories && Array.isArray(a.categories) && a.categories.length !== 0 && a.categories.map((y, index) => {
                channelArray.push(y)
                return null
            })
        }
        if (a.productTypeName === "vod" && selectedTabIndex === "1") {
            // a.categories && Array.isArray(a.categories) && a.categories.length !== 0 && a.categories.map((y, index) => {
            channelArray.push(a)
            return channelArray
            // })
        }
        if (a.productTypeName === "svod" && selectedTabIndex === "3") {
            // a.categories && Array.isArray(a.categories) && a.categories.length !== 0 && a.categories.map((y, index) => {
            channelArray.push(a)
            return channelArray
            // })
        }
        return channelArray
    })
    return channelArray
}

export const purchasableProductSorting = (purchasableProducts) => {
    let purchasableChannelArray = []
    purchasableProducts && purchasableProducts.map((x, index) => {
        if (x.productTypeName === "Channels" || x.productTypeId === CHANNEL_BUNDLE_ID) {
            x.categories && Array.isArray(x.categories) && x.categories.length !== 0 && x.categories.map((y, index) => {
                purchasableChannelArray.push(y)
                return purchasableChannelArray
            })
        }
        return purchasableChannelArray
    })
    return purchasableChannelArray
}


export const getBundleScreen = (baseProducts) => {
    let bundleScreen = 0
    baseProducts && baseProducts.productsList && Array.isArray(baseProducts.productsList) && baseProducts.productsList.map(screenProduct => {
        if (screenProduct.productTypeName === "Screens") {
            return bundleScreen = screenProduct.numberOfScreens
        }
        return bundleScreen
    })
    return bundleScreen
}

export const getpurchasedScreen = (baseProducts) => {
    let purchasedScreen = 0
    baseProducts && baseProducts.purchasableProductList && Array.isArray(baseProducts.purchasableProductList) && baseProducts.purchasableProductList.map(screenProducts => {
        if (screenProducts.productTypeName === "Screens" && screenProducts.isPurchased) {
            return purchasedScreen = screenProducts.numberOfScreens
        }
        return purchasedScreen
    })
    return purchasedScreen
}

export const filterScreen = (option) => {
    let deviceOptions = []
    option && Array.isArray(option) && option.map((v, index) => {
        if (v.productTypeName === "Screens" && v.status === "PRODUCT_STATUS_ACTIVE") {
            var id = v.id
            var value = v.numberOfScreens
            var selected = v.isPurchased

            var obj = {
                id: id,
                value: value,
                selected: selected,

            };

            deviceOptions.push(obj)
        }
        return deviceOptions
    })
    return deviceOptions
}
export const isActiveScreenProduct = (option) => {
    let isScreenProd = false
    option && Array.isArray(option) && option.map((v, index) => {
        if (v.productTypeName === "Screens" && v.status === "PRODUCT_STATUS_ACTIVE") {
            isScreenProd = true
        }
        return isScreenProd
    })
    return isScreenProd
}

export const getTabHeaders = (product) => {
    let array = []
    product && Array.isArray(product) && product.map(v => {
        if(v.productTypeId !== CHANNEL_BUNDLE_ID){
            let obj = {
                id: v.id,
                name: v.productTypeName,
                productTypeId: v.productTypeId,
                typeName: v.productTypeTypeName,
                isActive: true
            }
            array.push(obj)
        }
        
        return array
    });
    let channelsAvailable = array.some(e => e.typeName === 'channel');
    let channelBundlesAvailable = product && Array.isArray(product) && product.some(e => e.productTypeId === CHANNEL_BUNDLE_ID);
    if(!channelsAvailable && channelBundlesAvailable){
        array.unshift(CHANNELS_PRODUCT_TYPE)
    }

    return array
}
export const filterVod = (option) => {
    let vods = []
    option && Array.isArray(option) && option.map((v, index) => {
        if (v.productTypeName === "Video on Demand" && v.status === "PRODUCT_STATUS_ACTIVE") {

            vods.push(v)
        }
        return vods
    })
    return vods
}


