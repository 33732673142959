import React from 'react' 
import { DiscountPriceSlot } from './DummyComponent'

export default function DummyDevicesPage() {
    return (
        <> 
                <DiscountPriceSlot />  
        </>
    )
}
