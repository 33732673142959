// Services: all redux-logic services
import { loginService } from './modules/login/ducks'
import { generalPagesService } from './modules/generalPages/ducks'
import { subscriptionService } from './modules/subscription/ducks';
import { productService } from './modules/landingPage/ducks'
import { getBaseProductsService } from './modules/productPage/ducks'
import { svodService } from './modules/svodsPage/ducks'
import { passwordRecoveryService } from './modules/passwordRecovery/ducks'
import { allProductsService } from './modules/AllProducts/ducks'
import { singleProductService } from './modules/ProductContent/ducks';
import { registrationService } from './modules/registration/ducks'
import { profileService } from './modules/profile/ducks'
import { promotionService } from './modules/promotions/ducks';

// Export all redux-logics
export default [
    ...loginService,
    ...generalPagesService,
    ...subscriptionService,
    ...productService,
    ...getBaseProductsService,
    ...svodService,
    ...passwordRecoveryService,
    ...allProductsService,
    ...singleProductService,
    ...registrationService,
    ...profileService,
    ...promotionService
]