import React, { Component } from 'react'
import history from './../../_helpers/history'
import { CustomButton } from './../../components/controls/CustomButton.jsx'
import { CustomBox } from './../../components/customBoxView/CustomBox.jsx'
import { Row, Col } from "react-bootstrap";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { registrationActions, registrationSelectors } from './ducks'
import { ErrorMessage } from "./../../components/customMessages/ErrorMessage";
import { MOBITEL_HEADER_ENRICHMENT } from '../../util/Constants';

class Customers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            option: null
        }
    }

    componentDidMount() {
        //added redirection logic since mobitel redirection cannot be identified by mobile app
        if(!this.props.location.search.includes("t")){
            window.location = MOBITEL_HEADER_ENRICHMENT
        }
        const params = new URLSearchParams(this.props.location.search).get("t")
        if (params !== null) {
            this.setState({ isMobitel: params })
        }
        localStorage.removeItem('basepackagePurchased')
    }
    componentDidUpdate() {
        if (this.props.countryError && this.state.loading) {
            this.setState({ loading: false })
        }
    }

    handleChange(field, e) {
        this.setState({
            option: e.target.value,

        });
    }

    onSubmit = () => {
        if (this.state.option === "1") {
            // localStorage.setItem('@connectionType', this.state.option)
            sessionStorage.setItem('@connectionType', this.state.option)
            sessionStorage.setItem('@connectionTypeName', "peotv")
            history.push("/account-login")
        }
        else if (this.state.option === "6") {
            // localStorage.setItem('@connectionType', this.state.option)
            sessionStorage.setItem('@connectionType', this.state.option)
            sessionStorage.setItem('@connectionTypeName', "mobitel")
            sessionStorage.setItem("mobitelNumber", this.state.isMobitel)
            history.push("/personaldetails")
        }
        else if (this.state.option === "7") {
            this.setState({ loading: true })
            sessionStorage.setItem('@connectionType', this.state.option)
            sessionStorage.setItem('@connectionTypeName', "prepaid")
            this.props.getCountry();
        }

        else if (this.state.option === "nonpeotv") {
            history.push('/customer/selection')
        }

    }

    render() {
        const { countryError } = this.props
        const { loading, isMobitel } = this.state
        return (
            <div className="container card-page customer-selection-scrollable">
                <div>
                    <Row>
                        <Col md={12}>
                            <div className="slot-1">
                                <Row className="flex-column-reverse flex-md-row justify-content-center" style={{ alignItems: "center" }}>
                                    <Col md={8}>
                                        {/* <Row>
                                            <Col sm>
                                                    <CustomBox
                                                        id="customer1"
                                                        name="customers"
                                                        value="1"
                                                        onChange={this.handleChange.bind(this, "customer1")}
                                                        titleText="For PEOTV Customers"
                                                        src={require("./../../images/peo_existing.png")}
                                                        description="Enable your existing PEOTV service to another 1 or 2 devices (Tablet/Laptop/PC/Mobile)"
                                                        loading={loading}
                                                    />
                                            
                                            </Col>
                                        </Row> */}
                                        <Row>
                                            <Col sm >
                                                <CustomBox
                                                    id="customer2"
                                                    name="customers"
                                                    value="nonpeotv"
                                                    onChange={this.handleChange.bind(this, "customer2")}
                                                    titleText="For SLTMobitel Home Broadband / Voice customers"
                                                    src={require("./../../images/peo_everyone.png")}
                                                    description="Enjoy PEOTVGO Service on any device (TV/Tablet/Laptop/PC/Mobile)"
                                                    loading={loading}
                                                />
                                            </Col>
                                        </Row>
                                        {/* <Row>
                                            {isMobitel && <Col sm >
                                                <div>
                                                    <CustomBox
                                                        id="customer3"
                                                        name="customers"
                                                        value="6"
                                                        onChange={this.handleChange.bind(this, "customer2")}
                                                        titleText="For SLTMobitel Mobile Customers"
                                                        src={require("./../../images/peo_everyone.png")}
                                                        description="Enjoy PEOTVGO Service on any device (TV/Tablet/Laptop/PC/Mobile)"
                                                        loading={loading}
                                                    />
                                                </div>
                                            </Col>}
                                        </Row> */}
                                    </Col>
                                    <Col md={4}>
                                        <img 
                                            src={require("../../assets/images/SLTMobitel.png")} 
                                            style={{width: "270px"}} alt="customBoxImage" 
                                            className={`d-block mx-auto mx-md-0 ${!!isMobitel ? "slt-mobitel-logo-with-option" : "slt-mobitel-logo"}`}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                    {/* <Row className="justify-content-center" style={{marginTop: '30px'}}>
                        <Col md={12}>
                            <div className="slot-1">
                                <Row>
                                    <Col md={10}>
                                        <CustomBox
                                            id="customer4"
                                            name="customers"
                                            value="7"
                                            onChange={this.handleChange.bind(this, "customer2")}
                                            titleText="For other Networks and Credit/Debit Card Users"
                                            src={require("./../../images/peo_everyone.png")}
                                            description="Enjoy PEOTVGO Service on any device (TV/Tablet/Laptop/PC/Mobile)"
                                        />
                                    </Col>
                                    <Col md={2} className="visa-master-logo-col">
                                        <img src={require("../../assets/images/cutomer-selection-cards.png")} style={{width: "100px"}} alt="customBoxImage" className="visa-master-logo"/>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row> */}
                    <div className="proceed-btn-slot">
                        <CustomButton id="customers" disabled={!this.state.option} text="Proceed" loading={loading} onClick={this.onSubmit} style={{ margin: 0 }} />

                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <div>
                            {countryError !== undefined && (
                                <ErrorMessage
                                    errorStatus={countryError}
                                    errorMessage={countryError}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        ...registrationSelectors.getErrors(state)

    }
};

export default withRouter(connect(
    mapStateToProps,
    registrationActions
)(Customers));


