import React from 'react';
import { CustomCarousel } from '../carousel/CustomCarousel'
import DummyFeaturedVod from '../DummyComponent/DummyFeaturedVod';
import { carouselWidth } from "../../util/Constants";

const FeaturedOnDemandContent = ({ onDemandContents, onClick, renderCenterLeftControls, renderCenterRightControls, isLoggedIn }) => {
  if (onDemandContents.loading) {
    // if (true) {
    return (
      //  <Spinner />
      <DummyFeaturedVod
        renderCenterLeftControls={renderCenterLeftControls}
        renderCenterRightControls={renderCenterRightControls}
        onClick={onClick}
        isLoggedIn={isLoggedIn}
        isDummy={true}
      />
    )
  }
  if (onDemandContents.onDemandContents && onDemandContents.onDemandContents.length !== 0) {
    return <CustomCarousel
      slidesToShow={(900 < window.innerWidth ? 3 : (550 < window.innerWidth ? 2 : (440 < window.innerWidth ? 2 : 1)))}
      renderCenterLeftControls={renderCenterLeftControls}
      renderCenterRightControls={renderCenterRightControls}
      renderBottomCenterControls={false}
      heightMode="last"
      slideWidth="185px"
      initialSlideHeight={carouselWidth()}
      allProducts={Array.isArray(onDemandContents.onDemandContents) && onDemandContents.onDemandContents}
      informative={true}
      onDemand={true}
      onClick={onClick}
      isLoggedIn={isLoggedIn}
      isDummy={false}
    />
  }else {
    return null;
  }
}

export default FeaturedOnDemandContent;