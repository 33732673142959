import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import history from './../../_helpers/history';
import DataBundleCard from '../../components/DataBundle/DataBundleCard'
import { allProductsActions } from './ducks'
import productTypeIDs from './ducks/productTypeIDs'
import "./styles/AllDataBundles.scss"
import { SingleProductPage } from '../../util/Constants';
import DummyAllDataBundle from "../../components/DummyComponent/DummyAllDataBundle";
import { getLoggedInStatus, getConnectionId, purchaseStatusValidation, getProductPrice, isDiscounted, discountType } from '../../util/ArrayValidator';

class AllDataBundlesPage extends Component {
    componentDidMount() {
        if (getLoggedInStatus()) {
            if (!!getConnectionId()) {
                this.props.getAllProducts({
                    conid: getConnectionId(),
                    subTypeId: productTypeIDs.DATA_BUNDLE_ID,
                    dataBundle: true
                })
            } else {
                history.push("/")
            }
        }
    }

    componentWillUnmount() {
        this.props.resetForm();
    }

    onClick = (id) => {
        history.push({pathname:`${SingleProductPage}/${id}`, state:{
            dataBundle: true
        }});
        history.push()
    }

    render() {
        const { allUserDataBundles, loading } = this.props.allUserDataBundles;
        
        if (loading) {
            return (
                < DummyAllDataBundle />
            )
        }
        else {            
            return (
                <div className="all-databundle-container">
                    <Container>
                        <div className="all-databundle-header">
                            <h2 className="all-databundle-header-label" >SUBSCRIPTION DATA BUNDLES</h2>
                            <span><i className="fa fa-check-square data-bundle-already-sub-label"></i> Already Subscribed</span>
                        </div>
                        <br />
                        <Row>
                            {
                                allUserDataBundles && allUserDataBundles.map((data, i) => { 
                                    let purchaseStatusType = purchaseStatusValidation(data.purchaseStatus);
                                    
                                    let showDiscountedIcon;
                                    if(getLoggedInStatus()){
                                        if(isDiscounted(data)){
                                            let discount = discountType(data)
                                            showDiscountedIcon = <div class="discount-div-bg">
                                                                    <div class="red-bg-container">
                                                                        <span>{discount}</span>
                                                                    </div>
                                                                </div>
                                        }
                                    }

                                    return (
                                        <Col key={i} sm={12} md={6} lg={4} >
                                            <Link to={{pathname:`${SingleProductPage}/${data.id}`, state:{dataBundle : true}}}>
                                                <DataBundleCard
                                                    purchased={purchaseStatusType.productPurchased}
                                                    name={data.dataQuota=== -1 ? "Unlimited Data" : `${data.dataQuota}GB`}
                                                    price={getProductPrice(data)}
                                                    id={data.id}
                                                    imageURL={data.imageURL}
                                                    hasDiscount={showDiscountedIcon}
                                                />
                                            </Link>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Container>
                    <div style={{paddingBottom:"100px"}}/>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        allUserDataBundles: state.AllProducts.allUserDataBundles
    }
}

export default connect(mapStateToProps, allProductsActions)(AllDataBundlesPage);
//83