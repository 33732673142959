import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import DummyNotifyModal from '../DummyComponent/DummyNotifyModal';
import PaymentSucess from '../PaymentDetailsViews/PaymentSuccessfull'
import { CustomButton } from '../controls/CustomButton';
class NotifyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClick = () => {
    this.props.onClose();
  };
  render() {
    let paymentStatus;
    let { provisioningStatus } = this.props
    if (this.props.serviceType) {
      if (!this.props.loading) {
        if (this.props.type === "success" && (this.props.message === "Subscription Successful" || this.props.message === "Purchase Successful")) {
          paymentStatus = <PaymentSucess data={this.props.data} defaultPrice={this.props.defaultPrice} purchaseDetails={this.props.payherePurchaseStatus} referenceId={this.props.referenceId} serviceType={this.props.serviceType} provisioningStatus={provisioningStatus} />
        } else if (this.props.type === "success" && this.props.message === "Unsubscription Successful") {
          paymentStatus = <ion-icon name="checkmark-circle-outline" size="large" style={{ color: "#4a8900", size: "large" }} ></ion-icon>
        } else if (this.props.type === "success" && this.props.message === "Product un-subscription cancelled successfully") {
          paymentStatus = <ion-icon name="checkmark-circle-outline" size="large" style={{ color: "#4a8900", size: "large" }} ></ion-icon>
        } else {
          paymentStatus = <ion-icon name="close-circle-outline" size="large" style={{ color: "#B00020", size: "large" }} ></ion-icon>
        }
      }
    } else {
      if (!this.props.loading) {
        if (this.props.type === "success") {
          paymentStatus = <ion-icon name="checkmark-circle-outline" size="large" style={{ color: "#4a8900", size: "large" }} ></ion-icon>
        } else {
          paymentStatus = <ion-icon name="close-circle-outline" size="large" style={{ color: "#B00020", size: "large" }} ></ion-icon>
        }
      }
    }

    return (
      <div
        className={`modal fade  bd-example-modal-xl ${this.props.showModal ? "show" : ""
          }`}
        // id="exampleModal"
        tabIndex="-1"
        role="dialog"
        style={this.props.modalStyle}
      >
        {/* <div className="modal-dialog col-sm-12" role="document"> */}
        <div className=".modal-dialog-centered modal-dialog modal-xl subcription-loading">
          <div className="modal-content background-gradient-notifymodal">
            <div className="modal-header-one">
              <h5 className="modal-title" id="exampleModalLabel">
                {this.props.modalHeaderText}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                // onClick={this.props.onClose}
                onClick={() => this.onClick()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {this.props.loading ? <DummyNotifyModal type={this.props.dummyType} purchaseStatus={this.props.purchaseStatus} user={this.props.user} serviceType={this.props.serviceType} purchaseType={this.props.purchaseType} /> :

                <div style={{ marginTop: 40 }}>
                  <Container style={{ color: "#d0d0d0" }}>
                    <div className="row">
                      <div
                        className="col-sm-12"
                        style={{
                          color: "#4fa1d9",
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        {/* { this.props.serviceType ?  this.props.payherePurchaseStatus && this.props.type === "success" ? <PaymentSucess data ={this.props.data} defaultPrice={this.props.defaultPrice} purchaseDetails={this.props.payherePurchaseStatus}/>:
                        // <DummyNotifyModal type={this.props.dummyType} purchaseStatus={this.props.purchaseStatus} user={this.props.user} serviceType={ this.props.serviceType}/> 
                        null */}
                        {/* { this.props.type === "success" ?  this.props.serviceType ? this.props.payherePurchaseStatus && this.props.message!=="Unsubscription Unsuccessfull" ?<PaymentSucess data ={this.props.data} defaultPrice={this.props.defaultPrice} purchaseDetails={this.props.payherePurchaseStatus}/>:
                         this.props.message?<ion-icon
                         name="checkmark-circle-outline"
                         size="large"
                         style={{
                           color: "#4a8900",
                           size: "large"
                         }}
                       ></ion-icon> :<DummyNotifyModal type={this.props.dummyType} purchaseStatus={this.props.purchaseStatus} user={this.props.user} serviceType={ this.props.serviceType}/> : (
                          <ion-icon
                            name="checkmark-circle-outline"
                            size="large"
                            style={{
                              color: "#4a8900",
                              size: "large"
                            }}
                          ></ion-icon>
                        ) : (
                            <ion-icon
                              name="close-circle-outline"
                              size="large"
                              style={{
                                color: "#B00020",
                                size: "large"
                              }}
                            ></ion-icon>
                          )} */}
                        {paymentStatus}
                      </div>
                    </div>

                    <div className="row" style={{ margin: "12px 0px 12px 0px", textAlign: "center" }}>
                      <div className="col-sm-1"></div>
                      <div className="col-sm-10">
                        <p className="text-sm">
                          {/* Your password has been changed */}
                          {!provisioningStatus && this.props.message}
                        </p>
                      </div>
                      <div className="col-sm-1"></div>
                    </div>

                    <div className="row">
                      <div className="col-sm-1"></div>
                      <div className="col-sm-10 d-flex justify-content-center">
                        {this.props.NoBtn ? "" : !provisioningStatus && <button
                          type="button"
                          className="btn"
                          style={{ margin: 0, backgroundColor: "#a0a0a0" }}
                          onClick={() => this.onClick()}
                        >
                          <p
                            style={{ color: "#000000", fontWeight: "bold", margin: "0" }}
                          >
                            Close
                        </p>
                        </button>}
                        {provisioningStatus && <Row>
                          <Col className="continue-btn"><CustomButton text="CONTINUE" onClick={this.onClick}
                           /></Col>
                        </Row>}
                      </div>
                      <div className="col-sm-1"></div>
                    </div>
                  </Container>
                </div>}
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
      // </div>
    );
  }
}

export default NotifyModal;
