import React, { Component } from 'react'
import { Container, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import DummyPackageCarousel from '../../components/DummyComponent/DummyPackageCarousel';
import DummyAllChannelPage from '../../components/DummyComponent/DummyAllChannelPage'
import { promotionActions, promotionSelectors } from './ducks';
import { getConnectionId, getLoggedInStatus, getIndex, getNestedValue, getServiceType, purchaseStatusValidation, getBasepackage, getProductPrice, getUtilizationPeriod, validArray } from '../../util/ArrayValidator';
import { CustomCarousel } from '../../components/carousel/CustomCarousel';
import { PackageDetails } from '../../components/Package/PackageDetails'
import { CustomTabHeader } from '../../components/customTabs/CustomTabHeader'
import { CustomTabBody } from '../../components/customTabs/CustomTabBody'
import { CustomTabBodyContent } from '../../components/customTabs/CustomTabBodyContent'
import { productSorting, purchasableProductSorting, getTabHeaders, filterVod } from './../AllProducts/functions/PackagePageFuctions'
import _ from 'lodash'
import { CustomButton } from '../../components/controls/CustomButton';
import { ProductModal } from '../../components/controls/ProductModal';
import { PurchaseModal } from '../../components/controls/PurchaseModal';
import { singleProductActions, singleProductSelectors } from '../../modules/ProductContent/ducks';
import { UnsubscribeModal } from '../../components/controls/UnsubscribeModal'
import { bindActionCreators } from "redux"
import NotifyModal from '../../components/controls/NotifyModal'
import { TRANSACTIONAL, PURCHASE_COMPLETE, PREPAID_SERVICE_TYPE, PAYMENT_SUCCESS, PRODUCT_COUNT, INCREASE_PRODUCT_COUNT_TIMEOUT } from '../../util/Constants';
import moment from 'moment';
import { CustomVodTab } from "../../components/customTabs/CustomVodTab"
import { CustomSvodTab } from "../../components/customTabs/CustomSvodTab"
import { CustomScreenTab } from "../../components/customTabs/CustomTabScreen"
import history from '../../_helpers/history'
import { Payhere } from '../../util/PayhereScripts'
import { ProductProvisioningError } from '../../components/ProductPageView/ProductProvisioningError'
class PromotionalPackages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedImages: 0,
            additionalPrice: 0,
            selectedCategory: undefined,
            showModal: false,
            selectedTabIndex: 0,
            promotionPrices: true,
            productDetailsPurchase: true,
            productDetails: null,
            vodIndex: PRODUCT_COUNT
        }
    }

    componentDidMount() {
        let hash = this.props.location.hash
        let tabActiveKeys = hash ? hash : "#0"
        var ret = tabActiveKeys.replace('#', '');
        var selected = [];
        if (selected[ret] !== -1) selected.push(ret);
        this.setState({
            selectedImages: selected,
            selectedIndex: ret,
        });
        this.props.promotionActions.getPromotionalPackages()
        window.addEventListener('scroll', this.scrollEventListner, false)
    }

    componentDidUpdate() {
        if (getLoggedInStatus()) {
            if (this.props.promotionalPackages && this.state.promotionPrices) {
                let getBasePackageId = getBasepackage()
                let allProductState = getIndex(this.props.promotionalPackages, this.state.selectedImages)
                let id = allProductState.id
                let price = getLoggedInStatus()
                this.setState({ purchasable: price })
                if (getLoggedInStatus()) {
                    if (!!getConnectionId()) {
                        this.props.singleProductActions.getBundledProducts({ productId: id, basePackageId: getBasePackageId })
                        if (this.props.location && this.props.location.search) {
                            let purchaseOptionId = this.props.location.search.split("=")[1];

                            this.setState({
                                purchaseOption: purchaseOptionId,
                            })

                            this.getPriceOnPromotions();

                        }
                        this.setState({ promotionPrices: false })
                    }
                }
            }
        }

        if (getServiceType() === PREPAID_SERVICE_TYPE && !this.props.purchaseLoading && !this.props.payherePurchaseStatus && this.props.payhereDetails !== null && this.state.showPayhere && this.state.notifyStatus && this.state.purchaseOption != null) {
            // let id = this.props.match && this.props.match.params.id
            const { payhereDetails, purchaseOptions, userDetails } = this.props;

            let payementStatus = Payhere(purchaseOptions, userDetails, payhereDetails.transactionDetails, this.state.purchaseOption)
            window.payhere.onCompleted = (orderId) => {

                console.log("Payment completed. OrderID:" + orderId)
                this.getPurchaseSatus(payhereDetails.transactionDetails.transactionId)
                //Note: validate the payment and show success or failure page to the customer
            };

            // Called when user closes the payment without completing
            window.payhere.onDismissed = () => {
                //Note: Prompt user to pay again or show an error page
                console.log("Payment dismissed");
                this.closeNotify()

            };

            // Called when error happens when initializing payment such as invalid parameters
            window.payhere.onError = (error) => {
                // Note: show an error page
                console.log("Error:" + error);
                // this.getPurchaseSatusDissmissed()
            };

            window.payhere.startPayment(payementStatus);
        }

        if (this.props.purchaseData !== null) {
            this.props.promotionActions.verifyPackagePurchase(this.props.purchaseData)
        }
    }
    static getDerivedStateFromProps(nextProps, state) {
        let newProps = {}
        if (nextProps.location && nextProps.location.search && state.productDetailsPurchase && (nextProps.productDetailsLoading === false)) {
            if (getServiceType()) {
                if (nextProps.productDetails.purchaseStatus === "NOT_PURCHASED") {
                    newProps.showModal = true
                    newProps.productDetailsPurchase = false
                }
            } else {
                if (nextProps.productDetails.purchaseStatus === "NOT_PURCHASED" || nextProps.productDetails.purchaseStatus === "PURCHASE_PENDING_REMOVE") {
                    newProps.showModal = true
                    newProps.productDetailsPurchase = false
                }
            }
        }

        if (nextProps.subscribe !== null) {
            newProps.purchaseStatus = nextProps.purchaseStatus
        }

        if (nextProps.payhereDetails !== null) {
            newProps.payhereDetails = nextProps.payhereDetails
        }

        return {
            ...newProps
        };
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollEventListner, false)
    }
    getPriceOnPromotions = () => {
        this.setState({ promotionPrices: false })
        let allProductState = getIndex(this.props.promotionalPackages, this.state.selectedImages)
        let id = allProductState.id

        let selectedPurchaseOptionId = this.props.location.search.split("=")[1];
        this.props.singleProductActions.getProductDetailsWithPurchaseOption({
            productId: id,
            connectionId: getConnectionId(),
            purchaseOptionId: selectedPurchaseOptionId
        })
    }
    handleChangeCustomTab = (e) => {
        this.setState({
            selectedCategory: e.target.value
        })
    }
    onClick = (e) => {
        const { promotionalPackages } = this.props
        this.setState({
            selectedIndex: e.target.id, additionalPrice: 0, additionalScreen: undefined, dataBundle: undefined, selectedCategory: undefined, selectedImages: e.target.id,
            selectedTabIndex: 0, promotionPrices: true, vodIndex: PRODUCT_COUNT
        })
        let purchaseOptionGroupId = promotionalPackages.length !== 0 && promotionalPackages[e.target.id].purchaseOption && promotionalPackages[e.target.id].purchaseOption.purchaseOptionGroupId
        let packageId = promotionalPackages.length !== 0 && promotionalPackages[e.target.id].id
        this.props.promotionActions.getPromotionalPackageProducts(packageId)
        this.props.singleProductActions.getPurchaseTable(purchaseOptionGroupId)
    }
    handleShow = () => {
        const { promotionalPackages } = this.props
        let index = this.state.selectedImages
        this.setState({
            showModal: true,
            purchaseOption: promotionalPackages.length !== 0 && promotionalPackages[index].purchaseOption && promotionalPackages[index].purchaseOption.id
        })

    }
    onClose = () => {
        this.setState({ showModal: false, showModals: false })
    }
    onClickOk = (e) => {
        this.setState({
            showModal: false,
            showModals: true
        })
    }
    onClickYes = () => {
        const { promotionalPackages, purchaseTable } = this.props;
        const { selectedImages } = this.state
        let id = getNestedValue(promotionalPackages[selectedImages], ['id']);
        let productTypeId = getNestedValue(promotionalPackages[selectedImages], ['productTypeId']);
        let productPOGroupId = getNestedValue(promotionalPackages[selectedImages], ['purchaseOptionGroupId']);
        // let amount = getNestedValue(productDetails, ['purchaseOption', 'defaultPrice']);
        let amount
        let purchaseOptionId
        let purchaseOptionIndex = parseInt(this.state['purchaseOptionValues'])
        let selectedPurchaseOptionGID
        let defaultPurchaseOptionType = getNestedValue(promotionalPackages[selectedImages], ['purchaseOption', 'purchaseType']);

        if (purchaseOptionIndex) {
            var value = getIndex(purchaseTable, purchaseOptionIndex)
            amount = value && value.defaultPrice
            purchaseOptionId = value && value.id
            selectedPurchaseOptionGID = getNestedValue(value, ['purchaseOptionGroupId'])
        } else {
            purchaseOptionId = getNestedValue(promotionalPackages[selectedImages], ['purchaseOption', 'id']);
            amount = getNestedValue(promotionalPackages[selectedImages], ['purchaseOption', 'defaultPrice']);
            selectedPurchaseOptionGID = getNestedValue(promotionalPackages[selectedImages], ['purchaseOptionGroupId']);
        }

        this.setState({
            showModal: false,
            showModals: false,
            notifyStatus: true,
            showPayhere: true
        })
        this.props.singleProductActions.productSubscribe({
            // connectionId: this.props.connectionDetails && Array.isArray(this.props.connectionDetails) && this.props.connectionDetails[localStorage.selectedUserIndex].connectionId,
            connectionId: getConnectionId(),
            pin: this.state["password"],
            productId: id,
            productType: productTypeId,
            purchaseOption: purchaseOptionId,
            amount: amount,
            productPOGroupId: productPOGroupId,
            selectedPurchaseOptionGID: selectedPurchaseOptionGID,
            defaultPurchaseOptionType: defaultPurchaseOptionType
            // amount: getPurchaseOptionAmount(purchaseOptions, this.state["purchaseOption"])
        });
        this.setState({
            password: null
        });
        // if(getServiceType()){ 
        //     this.Payhere()
        // }
    }

    onClickNo = () => {
        this.setState({
            showModal: false,
            showModals: false,
            showUnsub: false,
            password: null,
            showPayhere: false
        })
    }
    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value })
    }

    onCloseUnsub = () => {
        this.setState({ showUnsub: false })
    }

    handleShowUnsub = () => {
        this.setState({ showUnsub: true })
    }
    onClickYesUnsub = () => {
        const { promotionalPackages, purchaseTable } = this.props;
        const { selectedImages } = this.state
        const id = getNestedValue(promotionalPackages[selectedImages], ['id']);
        const productTypeId = getNestedValue(promotionalPackages[selectedImages], ['productTypeId']);
        let purchaseOptionId
        let purchaseOptionIndex = this.state['purchaseOptionValues']
        let defaultPurchaseOptionType = getNestedValue(promotionalPackages[selectedImages], ['purchaseOption', 'purchaseType']);

        if (purchaseOptionIndex) {
            var value = getIndex(purchaseTable, purchaseOptionIndex)
            purchaseOptionId = value.id
        } else {
            purchaseOptionId = getNestedValue(promotionalPackages[selectedImages], ['purchaseOption', 'id']);
        }

        const purchaseId = getNestedValue(promotionalPackages[selectedImages], ['purchaseId'])

        this.setState({
            showUnsub: false,
            notifyStatus: true
        })
        this.props.singleProductActions.productUnsubscribe({
            // connectionId: this.props.connectionDetails && Array.isArray(this.props.connectionDetails) && this.props.connectionDetails[localStorage.selectedUserIndex].connectionId,
            connectionId: getConnectionId(),
            productId: id,
            productType: productTypeId,
            purchaseOption: purchaseOptionId,
            purchaseId: purchaseId,
            defaultPurchaseOptionType: defaultPurchaseOptionType
        })
    }

    closeNotify = () => {
        this.setState({
            notifyStatus: false,
            purchaseOption: undefined,
            password: undefined
        })
        this.props.singleProductActions.clearPurchaseMessage();
        this.props.singleProductActions.getPrepaidPurchaseStatusLoading()
        this.setState({
            password: null,
            purchaseOption: null,
            showPayhere: false,
            purchaseOptionValues: undefined
        })
    }
    onSelect = (e) => {
        this.setState({
            selectedTabIndex: parseInt(e)
        })

    }
    handleChangePurchaseOptions = (e) => {
        const { purchaseTable, promotionalPackages } = this.props;
        const { selectedImages } = this.state
        let id = promotionalPackages[selectedImages].id
        this.setState({
            [e.target.id]: e.target.value, selectedIndex: e.target.value
        });
        let selectedPurchaseOptionId = purchaseTable && purchaseTable[e.target.value].id;
        this.props.singleProductActions.getProductDetailsWithPurchaseOption({
            productId: id,
            connectionId: getConnectionId(),
            purchaseOptionId: selectedPurchaseOptionId
        })
    }



    getPurchaseSatus = (transactionId) => {
        this.setState({ showPayhere: false });
        let allProductState = getIndex(this.props.promotionalPackages, this.state.selectedImages)
        let id = allProductState.id
        this.props.singleProductActions.getPrepaidPurchaseStatus({ transactionId: transactionId, productId: id })

    }

    uniqueArray = a => [...new Set(a.map(o => JSON.stringify(o)))].map(s => JSON.parse(s));

    onClickSvod = (e) => {
        history.push(`/svod/${e.target.id}`)
    };
    onClickChannel = (e, isChannelBundle) => {
        this.setState({ show: true, selectedImage: e });
        if (!this.props.userRegiter) {
            if(isChannelBundle){
                history.push(`/channel-bundle/${e}`)
            }else{
                history.push(`/products/${e}`)
            }  
        }
    }
    //event handler for window scroll to load more products
    scrollEventListner = () => {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            const { promotionalPackageProduct } = this.props
            const { selectedTabIndex, vodIndex } = this.state
            let product = promotionalPackageProduct && promotionalPackageProduct.productList
            let tabHeaders = getTabHeaders(product)
            let productTypes = _.uniqBy(tabHeaders, function (o) {
                return o.typeName;
            });
            let selectedTabIndexTypeName = productTypes && validArray(productTypes) && productTypes[parseInt(selectedTabIndex)].typeName
            let vods = filterVod(product).reverse();
            console.log(selectedTabIndexTypeName, vodIndex, vods.length)
            if (selectedTabIndexTypeName === "vod" && vodIndex <= vods.length) {
                setTimeout(() => {
                    this.loadMore()
                },
                    INCREASE_PRODUCT_COUNT_TIMEOUT
                );

            }
        }
    }

    //Handle product count on scroll
    loadMore = () => {
        const { promotionalPackageProduct } = this.props
        const { vodIndex } = this.state
        let product = promotionalPackageProduct && promotionalPackageProduct.productList
        let vods = filterVod(product).reverse();
        if (vods && vods.length >= vodIndex) {
            this.setState({
                vodIndex: vodIndex + PRODUCT_COUNT
            })
        }
    }

    render() {
        const { loading, promotionalPackages, purchaseTable, purchaseLoading, getPurchaseStatus, getProductPurchaseStatus, payherePurchaseStatus, payherePurchaseStatusLoading, discountLoading, bundledProducts, referenceId, promotionalPackageProduct, promotionalPackageProductLoading } = this.props;
        const { selectedImages, showModal, selectedCategory, showModals, password, showUnsub, onCloseUnsub, notifyStatus, selectedTabIndex } = this.state

        let allProductState = getIndex(promotionalPackages, selectedImages)
        let product = promotionalPackageProduct && promotionalPackageProduct.productList
        let purchasableProducts = promotionalPackageProduct && promotionalPackageProduct.purchasableProductList
        let tabHeaders = getTabHeaders(product)
        let allPackageTypes = _.uniqBy(tabHeaders, function (o) {
            return o.typeName;
        });

        let typeName = allPackageTypes.length !== 0 && allPackageTypes[selectedTabIndex].typeName
        let channelArray = allPackageTypes && allPackageTypes.length !== 0 && productSorting(product, typeName)
        let purchasableChannelArray = allPackageTypes.length !== 0 && purchasableProductSorting(purchasableProducts)
        let unionArray = _.uniq(channelArray, purchasableChannelArray, "id");

        var unique = this.uniqueArray(unionArray);

        let productTypeTypeName = getNestedValue(allProductState, ['productTypeTypeName']);
        let purchaseStatus = getNestedValue(allProductState, ['isPurchased']);
        let serviceType = getServiceType()
        let purchaseType = getNestedValue(allProductState.purchaseOption, ["purchaseType"])
        let purchaseStatusValue = getNestedValue(allProductState, ['purchaseStatus']);
        let purchaseTypeValidation = purchaseStatusValidation(purchaseStatusValue);
        let billingEndDate = getNestedValue(allProductState, ['billingEndDate']);
        let expirationDate = moment(billingEndDate).add(1, 'days').format("DD/MM/YYYY")
        let purchaseStatusType = getNestedValue(allProductState, ['purchaseStatus']);
        let loggedIn = getLoggedInStatus()
        let paymentStatus = getNestedValue(allProductState, ['paymentStatus']);
        let provisioningStatus = paymentStatus && paymentStatus === PAYMENT_SUCCESS && purchaseTypeValidation.productPurchasedFailed
        let utilizationPeriodUnit = getNestedValue(allProductState && allProductState.purchaseOption, ['utilization', 'utilizationPeriodUnit'])
        let showUtilizationPeriod = getUtilizationPeriod(allProductState, utilizationPeriodUnit)
        let vods = filterVod(promotionalPackageProduct && promotionalPackageProduct.productList).reverse();
        //set the purchase option id of the promotion for the default PO to auto select on purchase window
        if (this.props.location && this.props.location.search) {
            let purchaseOptionId = this.props.location.search.split("=")[1];
            if (allProductState) {
                allProductState.purchaseOption.id = purchaseOptionId;
            }
        }

        //check usertype and enable subscribe button
        let subsBtnDisabled = false;
        if (getServiceType()) {
            if (purchaseTypeValidation.productPendingRemove) {
                subsBtnDisabled = true;
            }
        } else {
            subsBtnDisabled = false;
        }

        let unsubMessage = "";

        if (getServiceType()) {
            unsubMessage = ""
        } else {
            unsubMessage = "Please re-subscribe to cancel the unsubscription.";
        }


        //all active promotional packages
        let activePromoPacks = [];

        if (promotionalPackages && promotionalPackages.length !== 0) {
            promotionalPackages.map((data, index) => {
                if (data.status === "PRODUCT_STATUS_ACTIVE") {
                    activePromoPacks.push(data)
                }
                return activePromoPacks
            })
        }

        if (loading) {
            return <>   <Container>
                {/* <div style={{ marginLeft: 20, marginRight: 20 }}> */}
                <div style={{ textAlign: "center" }}><DummyPackageCarousel />
                </div>
                {/* </div> */}
                <DummyAllChannelPage />
            </Container>
            </>
        } else {
            if (activePromoPacks && activePromoPacks.length !== 0) {
                return (
                    <>
                        <Container>
                            {/* <h3 className="all-svods-header">PROMOTIONAL PACKAGES</h3> */}
                            <div style={{ marginLeft: 20, marginRight: 20 }}>
                                <div className="row promotional-package" style={{ textAlign: "center", margin: "0px 20px" }}>
                                    <CustomCarousel
                                        slidesToShow={(900 < window.innerWidth ? 3 : (550 < window.innerWidth ? 2 : (440 < window.innerWidth ? 2 : 1)))}
                                        renderBottomCenterControls={false}
                                        heightMode="last"
                                        slideWidth="300px"
                                        initialSlideHeight={(1200 < window.innerWidth ? 290 :
                                            (990 < window.innerWidth ? 240 :
                                                (900 < window.innerWidth ? 180 :
                                                    (770 < window.innerWidth ? 300 :
                                                        (550 < window.innerWidth ? 180 :
                                                            (440 < window.innerWidth ? 210 :
                                                                (330 < window.innerWidth ? 220 : 180)))))))}
                                        allProducts={activePromoPacks && Array.isArray(activePromoPacks) && activePromoPacks}
                                        featuredPackage={true}
                                        informative={true}
                                        onClick={this.onClick}
                                        isLoggedIn={allProductState && allProductState.name}
                                        selectedImage={this.state.selectedImages.toString()}
                                        slideIndex={this.state.selectedImages}
                                        viewPackage={false}
                                    //  imageNotFoundStyle={{ bottom: 10, fontSize: 11 }}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-md-9" >
                                        <PackageDetails
                                            name={allProductState.name}
                                            description={allProductState.description}
                                            selectedProduct={allProductState.price != null}
                                            price={parseInt(allProductState && allProductState.purchaseOption.defaultPrice)}
                                            purchaseOption={allProductState.purchaseOption}
                                            // isPrepaid={serviceType}
                                            productDetails={allProductState}
                                            additionalScreenPrice={0}
                                        />
                                    </div>
                                </div>
                                {
                                    loggedIn && purchaseTypeValidation.productPendingRemove &&
                                    <Row className="renew-alert" style={{ margin: 'auto', marginBottom: "10px", marginTop: "10px" }}>
                                        <div className="account-suspended" style={{ width: "100%" }}>
                                            <p>Unsubscribed content. You will have access to this only till {expirationDate}. {unsubMessage}</p>
                                        </div>
                                    </Row>
                                }
                                <Row style={{ display: 'initial' }}>
                                    {purchaseTypeValidation.productPurchasedFailed && paymentStatus === PAYMENT_SUCCESS && <Row>
                                        <ProductProvisioningError />
                                    </Row>}
                                </Row>
                                {loggedIn && ((purchaseTypeValidation.productNotPurchased || purchaseTypeValidation.productPendingRemove)) && <CustomButton
                                    style={{ marginLeft: "0", marginTop: "10px" }}
                                    disabled={subsBtnDisabled}
                                    onClick={this.handleShow}
                                    text={purchaseType === TRANSACTIONAL ? "PURCHASE" : purchaseStatus === PURCHASE_COMPLETE ? "UNSUBSCRIBE" : "SUBSCRIBE"}>
                                </CustomButton>}
                                {
                                    loggedIn && (purchaseTypeValidation.productPurchased && purchaseType !== TRANSACTIONAL)
                                    &&
                                    <CustomButton style={{ marginRight: "0", marginLeft: 'initial', marginTop: "10px" }} onClick={this.handleShowUnsub} text="UNSUBSCRIBE" />
                                }
                            </div>
                            <Container>
                                <div className="row">
                                    <div className="col-md-9" >
                                        <div className="package-include-header"><p className="package-includes-p">This Package Includes: </p></div>
                                    </div>
                                </div>
                            </Container>
                        </Container>
                        {promotionalPackageProductLoading ? <Container style={{ marginTop: 20, paddingLeft: 0 }}><div style={{ marginLeft: 30, marginRight: 20 }}><DummyAllChannelPage /></div></Container> :
                            <Container style={{ marginTop: 20, paddingLeft: 0 }}>
                                <div style={{ marginLeft: 30, marginRight: 20 }}>
                                    <CustomTabHeader tabs={allPackageTypes} onSelect={this.onSelect} defaultActiveKey={selectedTabIndex} activeKey={selectedTabIndex} isPromotional={true}>
                                        {typeName === "channel" && <><CustomTabBody handleChange={this.handleChangeCustomTab} channelArray={channelArray}
                                            unionArray={unique}
                                            name="select" id={1}
                                            promotional={true}
                                        />
                                            <CustomTabBodyContent
                                                unionArray={unique}
                                                product={promotionalPackageProduct && promotionalPackageProduct.productList}
                                                purchasableProducts={promotionalPackageProduct && promotionalPackageProduct.purchasableProductList}
                                                selectedCategory={selectedCategory}
                                                selectedCategoryName="Channels"
                                                beforeLogin={true}
                                                onClick={this.onClickChannel}
                                            /></>}
                                        {typeName === "screens" && <CustomScreenTab
                                            unionArray={unique}
                                            product={promotionalPackageProduct && promotionalPackageProduct.productList}
                                            purchasableProducts={promotionalPackageProduct && promotionalPackageProduct.purchasableProductList}
                                            selectedCategory={selectedCategory}
                                            selectedCategoryName="Screens"
                                            beforeLogin={true}
                                            onClick={this.onClickChannel}
                                        />}
                                        {typeName === "vod" && <CustomVodTab
                                            unionArray={unique}
                                            product={vods}
                                            purchasableProducts={promotionalPackageProduct && promotionalPackageProduct.purchasableProductList}
                                            selectedCategory={selectedCategory}
                                            selectedCategoryName="Video on Demand"
                                            beforeLogin={true}
                                            onClick={this.onClickChannel}
                                            vodIndex={this.state.vodIndex}
                                        />}
                                        {typeName === "svod" && <CustomSvodTab
                                            unionArray={unique}
                                            product={promotionalPackageProduct && promotionalPackageProduct.productList}
                                            purchasableProducts={promotionalPackageProduct && promotionalPackageProduct.purchasableProductList}
                                            selectedCategory={selectedCategory}
                                            selectedCategoryName="SVOD"
                                            beforeLogin={true}
                                            onClick={this.onClickSvod}
                                        />}
                                    </CustomTabHeader>
                                </div>
                            </Container>
                        }
                        <ProductModal
                            showModal={showModal}
                            modalStyle={{ display: showModal ? 'flex' : 'none', overflow: "overlay" }}
                            onClose={this.onClose}
                            modalBodyText="Please confirm to unsubscribe?"
                            buttonText={purchaseType === TRANSACTIONAL ? "PURCHASE" : "SUBSCRIBE"}
                            data={allProductState}
                            purchaseOption={purchaseTable}
                            onClick={this.onClickOk}
                            handleChange={this.handleChangePurchaseOptions}
                            // defaultPrice={defaultPrice}
                            serviceType={serviceType}
                            selectedIndex={selectedImages}
                            discountPriceLoading={discountLoading}
                            bundledProducts={bundledProducts}
                            productType="promo"
                            utilizationPeriodUnit={utilizationPeriodUnit}
                            showUtilizationPeriod={showUtilizationPeriod}
                        />
                        <PurchaseModal
                            heading="Are you sure you want to subscribe?"
                            showModal={showModals}
                            modalStyle={{ display: showModals ? 'flex' : 'none', overflow: "overlay" }}
                            handleChange={this.handleChange}
                            onClose={this.onClose}
                            modalBodyText="Please confirm to unsubscribe?"
                            data={promotionalPackages && Array.isArray(promotionalPackages) && promotionalPackages[selectedImages]}
                            onClick={this.onClickOk}
                            onClickYes={this.onClickYes}
                            onClickNo={this.onClickNo}
                            value={password}
                            disable={password === undefined ? true : false}
                        />
                        <UnsubscribeModal
                            showUnsub={showUnsub}
                            modalStyle={{ display: showUnsub ? 'flex' : 'none', overflow: "overlay" }}
                            onClose={onCloseUnsub}
                            data={allProductState}
                            onClickYes={this.onClickYesUnsub}
                            onClickNo={this.onClickNo}
                        />
                        <NotifyModal
                            showModal={notifyStatus}
                            modalStyle={{
                                display: notifyStatus ? "flex" : "none",
                                overflow: "overlay",
                            }}
                            onClose={this.closeNotify}
                            type={getProductPurchaseStatus}
                            message={getPurchaseStatus}
                            loading={purchaseLoading || payherePurchaseStatusLoading}
                            dummyType={productTypeTypeName}
                            purchaseStatus={purchaseStatusType}
                            serviceType={serviceType}
                            purchaseType={purchaseType}
                            payherePurchaseStatus={payherePurchaseStatus}
                            data={allProductState}
                            defaultPrice={parseFloat(getProductPrice(promotionalPackages && Array.isArray(promotionalPackages) && promotionalPackages[selectedImages]))}
                            referenceId={referenceId}
                            provisioningStatus={provisioningStatus}
                        />
                    </>
                )
            } else {
                return <Container className="all-svods-container">
                    <Container className="container-style">
                        <h3 className="all-svods-header">PROMOTIONAL PACKAGES</h3>
                        <br />
                        <h4>There are no promotional packages available currently</h4>
                    </Container>
                </Container>
            }
        }
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        promotionActions: bindActionCreators(promotionActions, dispatch),
        singleProductActions: bindActionCreators(singleProductActions, dispatch),

    }
}

const mapStateToProps = state => {
    return {
        ...promotionSelectors.getPromotionalPackages(state),
        purchaseTable: promotionSelectors.getPurchaseOptionTable(state),
        purchaseLoading: singleProductSelectors.purchaseLoading(state),
        getPurchaseStatus: singleProductSelectors.getPurchaseStatus(state),
        getProductPurchaseStatus: singleProductSelectors.getProductPurchaseStatus(state),
        discountLoading: singleProductSelectors.getDiscountPriceLoading(state),
        bundledProducts: singleProductSelectors.getBundledProducts(state),
        payhereDetails: singleProductSelectors.getPayhereDetails(state),
        userDetails: singleProductSelectors.getUserDetails(state),
        ...singleProductSelectors.payherePurchaseStatus(state),
        referenceId: singleProductSelectors.getReferenceId(state),
        purchaseOptions: singleProductSelectors.getPurchaseOptions(state),
        purchaseData: promotionSelectors.getPurchasedData(state)
    };
};

export default withRouter(connect(
    mapStateToProps, mapDispatchToProps
)(PromotionalPackages));

