import { createLogic } from 'redux-logic';
import actions from './actions'
import types from './types'
import endPoints from './../../../util/EndPoints'
import API from './../../../util/HTTPClient'
import history from './../../../_helpers/history'
import _ from 'lodash';
import { getLoggedInStatus, getConnectionType, getRegistrationConnectionId } from '../../../util/ArrayValidator';
import { CHANNELS_PRODUCT_TYPE, MOBITEL_CONSENT_RETURN_URL, MOBITEL_CONSET_PORTAL_URL } from '../../../util/Constants';

export default [

  createLogic({
    type: types.ADD_CONNECTION,
    latest: true,
    debounce: 1000,

    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }
      var userType = getConnectionType()
      let endpoint
      if (getLoggedInStatus() !== false) {
        if (userType === "7") {
          endpoint = endPoints.ADD_NEW_PREPAID_CONNECTION
        } else if (userType === "6") {
          endpoint = endPoints.SUBSCRIBE_ADD_NEW_MOBITEL_SECONDARY_PACKAGE
        }
        else {
          endpoint = endPoints.SUBSCRIBE
        }
      }
      else {
        if (userType === "7") {
          endpoint = endPoints.SUBMIT_PREPAID_CONNECTIONS
        }
        else if (userType === "6") {
          endpoint = endPoints.SUBSCRIBE_MOBITEL_SECONDARY_PACKAGE
        }
        else {
          endpoint = endPoints.ADD_CONNECTION
        }

      }
      // const data ={"status":"RETRY","referenceNo":"OTT-M-B5A6-00090","transactionId":"4","authCode":"8sx9zlvjmy","message":"Error purchasing secondary package. Insufficient credits","paymentStatus":"RETRY"}
      // dispatch(actions.addConnectionFailed(data))
      // dispatch(actions.savePaymentDetails(data))
      // return
      HTTPClient.Post(endpoint, action.payload)
        .then(resp => resp.data)
        .then(async data => {
          dispatch(actions.addConnectionSuccess(data))
          if (userType === "7") {
            dispatch(actions.getPackagePurchasedStatusLoading())
          }
          else if (userType === "6") {
            if (data.status === "RETRY" || data.status === "FAILURE") {
              var rearrange = {
                authCode: data.authCode,
                message: data.description,
                status: data.status,
                transactionId: data.transactionId,
                referenceNo: data.referenceNo
              }
              dispatch(actions.addConnectionFailed(rearrange || "Something went wrong, please contact your service provider"))
              dispatch(actions.savePaymentDetails(rearrange || "Something went wrong, please contact your service provider"))
            } else {
              dispatch(actions.addConnectionSuccess(data))
            }
          } else {
            dispatch(actions.addConnectionSuccess(data))
            history.push('/auth')
          }
        })
        .catch(err => {
          debugger
          // Log error
          dispatch(actions.addConnectionFailed(((err.response && err.response.data && err.response.data) || "Something went wrong, please contact your service provider")))
        })
        .then(() => done()); // call done when finished dispatching
    }
  }),

  createLogic({
    type: types.FETCH_PRODUCTS,
    latest: true,
    debounce: 1000,

    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }


      // dispatch(actions.fetchProductsSuccess(data))
      // return
      HTTPClient.Post(`${endPoints.PRODUCT_ID_LIST_REGISTER}`, action.payload)// product endpoint
        .then(resp => resp.data)
        .then((data) => {
          let allPackages = []
          let prodList = []
          let categoryList = []
          // let product = []
          let dataBundle = []
          let primaryPacakgeStatus= false
          Array.isArray(data) && data.map((v, i) => {
            if(v.status==="PRODUCT_STATUS_ACTIVE" && v.standalone==="PURCHASE_MODE_STANDALONE"){
            let basePackage = {}
            let products = {}
            let dbun = {}
            let product = []
            basePackage.id = v.id
            basePackage.productTypeId = v.productTypeId
            basePackage.name = v.name
            basePackage.price = v.purchaseOption && v.purchaseOption.defaultPrice
            basePackage.imageURL = v.imageURL
            basePackage.description = v.description
            basePackage.numberOfScreens = v.numberOfScreens
            basePackage.purchaseOptionGroupId = v.purchaseOptionGroupId
            basePackage.purchaseOption = v.purchaseOption
            Array.isArray(v.productList) && v.productList.map((prods, index) => {
             
              if (prods.productTypeName === "Package" && prods.status === "PRODUCT_STATUS_ACTIVE") {
                primaryPacakgeStatus=prods.status
                // debugger
                // /prod.productList && Array.isArray(prod.productList) && prod.productList.map((prods, x) => {
                prodList.push(prods.productTypeName)
                products.description = prods.description
                products.id = prods.id
                products.imageURL = prods.imageURL
                products.name = prods.name
                products.price = prods.price
                products.numberOfScreens = prods.numberOfScreens
                products.productTypeName = prods.productTypeName
                products.productTypeId = prods.productTypeId
                products.purchaseOption = prods.purchaseOption
                product.push(products)
                Array.isArray(prods.categories) && prods.categories.map((h, i) => {
                  categoryList.push(h.category)
                  return null
                })
              }
              if(getConnectionType() !== "7" && getConnectionType() !== "6" && getConnectionType() !== "1"){
                if (primaryPacakgeStatus === "PRODUCT_STATUS_ACTIVE" && prods.productTypeName === "Data Bundle") {
                  dbun.dataId = prods.id
                  dbun.name = v.name
  
                }
              }
              

              return null
            })
            dataBundle.push(dbun)
            basePackage.dataBundle = dataBundle
            // basePackage.products=v.productList
            basePackage.categories = _.uniq(categoryList)
            // })
            basePackage.tabs = _.uniq(prodList)
            // basePackage.categories= _.uniq(categoryList)
            basePackage.product = _.uniq(product)

            //basepackage discountroup
            basePackage.discountOptionGroup = v.discountOptionGroup;

            primaryPacakgeStatus === "PRODUCT_STATUS_ACTIVE" && allPackages.push(basePackage)
            return null
            }
            return false
          })
          dispatch(actions.fetchProductsSuccess(allPackages))
        })
        .catch(err => {
          // Log error
          err && err.response && err.response.data && dispatch(actions.fetchProductsFailed(err.response.data.message || "Something went wrong, please contact your service provider"))
        })
        .then(() => done()); // call done when finished dispatching
    }
  }),

  createLogic({
    type: types.GET_ALLOWED_SECONDARY_PACKAGES,
    latest: true,
    debounce: 1000,

    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }
      var userType = getRegistrationConnectionId()

      // dispatch(actions.fetchSecondaryProducts())
      // return
      HTTPClient.Get(`${endPoints.FETCH_PRODUCTS}/${userType}`)
        .then(resp => resp.data)
        .then((data) => {
          dispatch(actions.getAllowedSecondaryPackagesSuccess(data))
          dispatch(actions.fetchProducts({ idList: data, connectionType: sessionStorage.getItem("@connectionTypeName") }))

        })
        .catch(err => {
          // Log error
          err && err.response && err.response.data && dispatch(actions.getAllowedSecondaryPackagesFailed(err.response.data.message || "Something went wrong, please contact your service provider"))
        })
        .then(() => done()); // call done when finished dispatching
    }
  }),
  createLogic({

    type: types.GET_PRODUCT_TYPES, // only apply this logic to this type
    latest: true, // only take latest
    debounce: 1000,  // Wait 1 s before triggering another call

    // your code here, hook into one or more of these execution
    // phases: validate, transform, and/or process
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }

      HTTPClient.Get(`${endPoints.GET_PRODUCTS_TYPES}?productId=${action.payload}`)
        .then(res => res.data)
        .then((data) => {
          _.remove(data, { typeName: "screens" })
          let channelsAvailable = data.some(e => e.typeName === 'channel');
          let channelBundlesAvailable = data.some(e => e.typeName === 'channelbundle');
          if(!channelsAvailable && channelBundlesAvailable){
              data.unshift(CHANNELS_PRODUCT_TYPE)
          }
          _.remove(data, {typeName: "channelbundle"})
          dispatch(actions.getProductTypesSuccess(data))
        })
        .catch(err => dispatch(actions.getProductTypesFailed(err)))
        .then(() => done()); // call done when finished dispatching
    }
  }),
  createLogic({
    type: types.GET_PRODUCTS,
    latest: true,
    debounce: 1000,

    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }
      HTTPClient.Post(`${endPoints.BASE_PACKAGE_PRODUCT}`, action.payload)
        .then(resp => resp.data)
        .then((data) => {
          // debugger
          dispatch(actions.getProductsSuccess(data))
        })
        .catch(err => {
          // Log error
          // if (err.response.data == undefined) {
          //   "Something went wrong, please contact your service provider"
          // err.response.data.message
          dispatch(actions.getProductsFailed("Something went wrong, please contact your service provider"))
          // }
          // else {
          //   { dispatch(actions.getBaseProductsFailed()) }
          // }
        })
        .then(() => done()); // call done when finished dispatching
    }
  }),
  createLogic({
    type: types.GET_DATA_BUNDLE,
    latest: true,
    debounce: 1000,
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }
      if (!!action.payload) {
        HTTPClient.Get(`${endPoints.GET_PRODUCT}${action.payload}`)
          // HTTPClient.Get(`${endPoints.GET_PRODUCT}${"5d22eda922224e2be4ad4047"}`)
          .then(res => res.data)
          .then(data => dispatch(actions.getDataBundleSuccess(data)))
          .catch(err => dispatch(actions.getDataBundleFailed(err)))
          .then(() => done()); // call done when finished dispatching
      }
    }
  }),
  createLogic({
    type: types.CREATE_CONSENT,
    latest: true,
    debounce: 1000,

    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }
      let payload = {
        "connectionId": sessionStorage.getItem('@connectionID'),
        "msisdn": sessionStorage.msisdn
      }
      HTTPClient.Post(`${endPoints.CREATE_CONSENT}`, payload)
        .then(resp => resp.data)
        .then((data) => {
          dispatch(actions.createConsentSuccess(data))
          let bridgeId = data.bridgeID
          debugger
          window.location = `${MOBITEL_CONSET_PORTAL_URL}bridgeID=${bridgeId}&returnUrl=${MOBITEL_CONSENT_RETURN_URL}`
        })
        .catch(err => {
          dispatch(actions.getProductsFailed("Something went wrong, please contact your service provider"))
        })
        .then(() => done()); // call done when finished dispatching
    }
  }),
  createLogic({
    type: types.RETRY_PACKAGE,
    latest: true,
    debounce: 1000,

    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }
      HTTPClient.Post(`${endPoints.RETRY_PACKAGE}`, action.payload)
        .then(resp => resp.data)
        .then((data) => {
          if (data.paymentStatus === "RETRY" || data.paymentStatus === "FAILURE") {
            dispatch(actions.retryMobitelPackageFailed(data))
          } else {
            dispatch(actions.retryMobitelPackageSuccess(data))
          }
        })
        .catch(err => {
          err && err.response && err.response.data && dispatch(actions.retryMobitelPackageFailed(((err.response.data) || "Something went wrong, please contact your service provider")))
        })
        .then(() => done()); // call done when finished dispatching
    }
  }),
  createLogic({
    type: types.CHECK_PACKAGE_PROVISIONING,
    latest: true,
    debounce: 1000,

    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }
      HTTPClient.Get(`${endPoints.CHECK_PACKAGE_PROVISION_STATUS}?connectionId=${action.payload.connectionId}&connectionType=${action.payload.connectionType}`)
        .then(resp => resp.data)
        .then((data) => {
          if (data.status === "FAILURE" && data.paymentStatus === "SUCCESS") {
            data.status = "PENDING"
            dispatch(actions.checkPackageProvisioningSuccess(data))
            history.push('/paymentStatus')
          } else if (data.status === "FAILURE" && data.paymentStatus === "FAILURE") {
            dispatch(actions.checkPackageProvisioningFailed((data && "Payment cancelled. ") || "Something went wrong, please contact your service provider"))
          } else {
            if (action.payload.isPayhere) {
              dispatch(actions.getPackagePurchasedStatus(action.payload.transactionId))
            }
            // dispatch(actions.getPackagePurchasedStatusSuccess(data))
            // history.push('/auth')
          }

        })
        .catch(err => {
          // Log error
          err.response && err.response.data && dispatch(actions.checkPackageProvisioningFailed((err.response.data.message && "Payment cancelled. " )|| "Something went wrong, please contact your service provider"))
        })
        .then(() => done()); // call done when finished dispatching
    }
  }),
  createLogic({
    type: types.VALIDATE_CONSENT,
    latest: true,
    debounce: 1000,

    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }
      HTTPClient.Post(`${endPoints.VALIDATE_CONSENT}?bridgeId=${action.payload.bridgeId}&msisdn=${action.payload.msisdn}`)
        .then(resp => resp.data)
        .then((data) => {
          dispatch(actions.validateConsentSuccess(data))
          let packageData = localStorage.package
          if (packageData) {
            dispatch(actions.addConnection(JSON.parse(packageData)))
          }
        })
        .catch(err => {
          // Log error
          err.response && err.response.data && dispatch(actions.validateConsentFailed(err.response.data || "Something went wrong, please contact your service provider"))
        })
        .then(() => done()); // call done when finished dispatching
    }
  }),

  createLogic({
    type: types.PREPAID_PACKAGE_PURCHASED_STATUS,
    latest: true,
    debounce: 1000,

    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }

      HTTPClient.Get(`${endPoints.HAS_PAID}${action.payload}${endPoints.PAID}`)
        .then(resp => resp.data)
        .then((data) => {
          dispatch(actions.getPackagePurchasedStatusSuccess(data))
          history.push('/auth')
        })
        .catch(err => {
          // Log error
          err.response && err.response.data && dispatch(actions.getPackagePurchasedStatusFailed((err.response.data.message && "Payment cancelled. " )|| "Something went wrong, please contact your service provider"))
        })
        .then(() => done());
    }
  }),
  createLogic({
    type: types.GET_DATA_BUNDLE_PURCHASE_STATUS,
    latest: true,
    debounce: 1000,

    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient
      } else {
        HTTPClient = API
      }

      HTTPClient.Get(`${endPoints.DATA_BUNDLE_PURCHASE_STATUS}?conId=${action.payload}`)
        .then(resp => resp.data)
        .then((data) => {
          if (data.trim() !== "") {
            dispatch(actions.getDataBundlePurchaseStatusFailed(data))
          } else {
            dispatch(actions.getDataBundlePurchaseStatusSuccess(data))
          }

        })
        .catch(err => {
          // Log error
          err.response && err.response.data && dispatch(actions.getDataBundlePurchaseStatusFailed(err.response.data.message && "Payment cancelled. " || "Something went wrong, please contact your service provider"))
        })
        .then(() => done());
    }
  }),
]