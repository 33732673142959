// const BASE_URL = `http://172.17.251.89:80` //TODO : REMOVE
// const REGISTER_PORT = `:8000`
const V1 = "/v1"
const API = "/api"
const USER = "/users"
const REGISTER = "/register"
const PRODUCTS = "/products"
const PACKAGE = "/package"
const PROMOTIONS = "/promotions"
const CATEGORIES = "/categories"
const TYPE = "/type"
const SUB_TYPE = "/subtype"
const BILLING = "/billing"
const USER_PRODUCTS = "/user-products"
const AUTH = "/auth"
const MOBITEL = "/mobitel"
const PREPAID = "/prepaid"
const SECONDARY = "/secondary"

export default {
    LOGIN: `${API}${AUTH}${V1}/login`,
    LOGOUT: `${API}${AUTH}${V1}/logout`,
    PRODUCT_TYPE_AND_TAGS: `${API}${PRODUCTS}${V1}${PRODUCTS}/by-product-type-and-tags`,
    GET_TYPES: API + USER + V1 + REGISTER + "/connection-types",
    GET_CONNECTION_DETAILS: API + USER + V1 + REGISTER + "/connection-details",
    ADD_CONNECTION: API + USER + V1 + REGISTER + "/connections",
    SUBMIT_OTP: API + USER + V1 + REGISTER + "/verify-otp",
    RESEND_OTP: API + USER + V1 + REGISTER + "/resend-otp/",
    FETCH_PRODUCTS: API + USER + V1 + "/allowedSecondaryPackages/product",
    PRODUCT_TYPES: `${API}${PRODUCTS}${V1}${PRODUCTS}/product-types`,
    BASE_PRODUCT: `${API}${PRODUCTS}${V1}${PRODUCTS}${PACKAGE}/all/by-package-type`,
    GET_BANNER: `${API}${PROMOTIONS}${V1}${PROMOTIONS}/all/banners`,
    GET_CHANNEL: `${API}${PRODUCTS}${V1}${PRODUCTS}/channel`,
    GET_ALL_CHANNEL: `${API}${PRODUCTS}${V1}${PRODUCTS}/all/active/by-type`,
    GET_ALL_CHANNEL_CATEGORIES: `${API}${PRODUCTS}${V1}${PRODUCTS}${CATEGORIES}/all-categories/product-type`,
    GET_PRODUCTS_TYPES: `${API}${PRODUCTS}${V1}${PRODUCTS}/distinct-types-of-product`,
    BASE_PACKAGE_PRODUCT: `${API}${PRODUCTS}${V1}${PRODUCTS}/products-by-id-and-types`,
    GET_PRODUCT: `${API}${PRODUCTS}${V1}${PRODUCTS}/`,
    GET_PARENT_TYPES: `${API}${PRODUCTS}${V1}${PRODUCTS}${TYPE}`,
    GET_SUB_TYPES: `${SUB_TYPE}/all`,
    GET_LANGUAGES: `${API}${PRODUCTS}${V1}${PRODUCTS}/language/all`,
    GET_SUB_VODS: `${API}${PRODUCTS}${V1}${PRODUCTS}/all/by-subtype`,
    GET_SUB_TRENDING_VODS: `${API}${PRODUCTS}${V1}${PRODUCTS}/by-product-sub-type-and-tags`,
    PACKAGE_TYPES: `${API}${PRODUCTS}${V1}${PRODUCTS}/package-type`,
    GET_DISTRICTS: `${API}${USER}${V1}/districts`,
    NON_SLT_DETAILS: `${API}${USER}${V1}/nonslt/details`,
    PRODUCT_ID_LIST: `${API}${PRODUCTS}${V1}${PRODUCTS}/all/id-list`,
    PRODUCT_ID_LIST_REGISTER: `${API}${PRODUCTS}${V1}${PRODUCTS}/register/all/id-list`,
    FETCH_PRODUCTS_ID: `${API}${USER}${V1}/allowedSecondaryPackages`,
    confirmEmailSuccess: `${API}${USER}${V1}/account/confirmation`,
    UNSUB_CONNECTION: `${API}${USER}${V1}/connection/unsubscribe`,
    PRODUCTS_BY_TYPES_AND_TAGS: `${API}${PRODUCTS}${V1}${PRODUCTS}/by-product-types-and-tags`,
    GET_ALL_PRODUCTS_BY_TYPES: `${API}${PRODUCTS}${V1}${PRODUCTS}/all/active/by-types`,
    //AfterLoginn
    GET_USER_DETAILS: `${API}${USER}${V1}/account/details`,
    GET_CONNECTIONS: `${API}${USER}${V1}/account/connections`,
    GET_CONNECTION_CONTENT: `${API}${USER}${V1}/connection/details`,
    GET_FEATURED_ALLOCATED_PRODUCTS: `${API}${BILLING}${V1}${BILLING}${USER_PRODUCTS}/by-types-and-tags`,
    GET_PURCHASED_PACKAGE: `${API}${BILLING}${V1}${BILLING}${USER_PRODUCTS}/by-packagetype`,
    SAVE_DETAILS: `${API}${USER}${V1}/connection/addNew`,
    SUBSCRIBE: `${API}${USER}${V1}/connection/subscribe`,
    PURCHASED_PACKAGE: `${API}${BILLING}${V1}${BILLING}${USER_PRODUCTS}/by-packagetype`,
    GET_ALL_CHANNEL_PURCHASE: `${API}${BILLING}${V1}${BILLING}${USER_PRODUCTS}/by-type`,
    PURCHASED_PRODUCTS: `${API}${BILLING}${V1}${BILLING}${USER_PRODUCTS}/`,
    FETCH_PRIMARY_PRODUCTS_ID: `${API}${USER}${V1}/allowedPrimaryPackages/`,
    GET_PURCHASED_PRIMARY_DATA_BUNDLE: `${API}${BILLING}${V1}${BILLING}${USER_PRODUCTS}/by-type`,
    GET_ALL_DATA_BUNDLES: `${API}${BILLING}${V1}${BILLING}${USER_PRODUCTS}/by-type`,

    GET_SUB_TRENDING_BILLING: `${API}${BILLING}${V1}${BILLING}${USER_PRODUCTS}/by-subtype-and-tag`,
    GET_SUB_VODS_BILLING: `${API}${BILLING}${V1}${BILLING}${USER_PRODUCTS}/by-type`,
    GET_PRODUCTS_BY_TYPES_BILLING : `${API}${BILLING}${V1}${BILLING}${USER_PRODUCTS}/by-types`,

    PRODUCT_SUBSCRIBE: `${API}${USER}${V1}/product/subscription`,
    PRODUCT_UNSUBSCRIBE: `${API}${USER}${V1}/product/unsubscription`,
    PASSWORD_UPDATE: `${API}${AUTH}${V1}/password-update`,
    // PASSWORD_UPDATE: `/api/auth/v1/password-update`,

    // LOGIN: `${API}${AUTH}${V1}/login`,

    IMAGE_BASE_URL: "http://172.17.250.86:8080/",

    GET_USER_PRODUCT_BY_TYPE: `${API}${BILLING}${V1}${BILLING}${USER_PRODUCTS}/by-type`,
    GET_PURCHASE_TABLE: `${API}${PRODUCTS}${V1}${PRODUCTS}/admin/purchase-options-all/`,
    CONFIRM_EMAIL: `${API}${USER}${V1}/account/confirmation`,

    GET_USERNAME: `${API}${AUTH}${V1}/recover`,
    FORGOT_PASSOWRD: `${API}${AUTH}${V1}/pwd-recovery`,

    REMOVE_PROFILE: `${API}${USER}${V1}/profiles`,
    REMOVE_DEVICE_CONNECTION: `${API}${USER}${V1}/connections/`,
    REMOVE_DEVICE: `devices`,
    GET_DEVICES: `${API}${USER}${V1}/connections`,

    //Mobitel endpoints
    SUBMIT_MOBITEL_USER_DETAILS: `${API}${USER}${V1}${REGISTER}${MOBITEL}/connection-details`,
    SUBSCRIBE_MOBITEL_SECONDARY_PACKAGE: `${API}${USER}${V1}${REGISTER}${MOBITEL}/connections`,
    MOBITEL_PRODUCT_SUBSCRIPTION: `${API}${BILLING}${V1}${BILLING}/user-purchase${MOBITEL}`,
    CREATE_CONSENT: `${API}${MOBITEL}/consent/local`,
    SUBMIT_MOBITEL_ADD_NEW_USER_DETAILS: `${API}${USER}${V1}${SECONDARY}${MOBITEL}/connections-details`,
    SUBSCRIBE_ADD_NEW_MOBITEL_SECONDARY_PACKAGE: `${API}${USER}${V1}${SECONDARY}${MOBITEL}/connections`,
    VALIDATE_CONSENT : `${API}${MOBITEL}/consent/validate`,
    //promotions
    GET_USER_BANNERS: `${API}${PROMOTIONS}${V1}${PROMOTIONS}/all/banners`,

    GET_BUNDLED_PRODUCTS: `${API}${PRODUCTS}${V1}${PRODUCTS}${PACKAGE}/all/by-base-package-id-and-product-id`,


    GET_COUNTRY: `https://ip-location.icu/api/v1/`,

    //prepaid endpoints
    SUBMIT_PREPAID_USER_DETAILS: `${API}${USER}${V1}${REGISTER}${PREPAID}/connection-details`,
    GET_AUTH_CODE: `${API}${USER}${V1}${PREPAID}/authcode/`,
    SUBSCRIBE_PREPAID_SECONDARY_PACKAGE: `${API}${USER}${V1}${PREPAID}/connection/subscribe`,
    PREPAID_PRODUCT_SUBSCRIPTION: `${API}${USER}${V1}${PREPAID}/product/subscription`,
    PREPAID_PACKEAGE_STATUS: `${API}${PREPAID}${V1}/payments/notify`,
    SUBMIT_PREPAID_CONNECTIONS: `${API}${USER}${V1}${REGISTER}${PREPAID}/connections`,
    HAS_PAID: `${API}${PREPAID}${V1}/payments/`,
    PAID: `/paid`,
    REACTIVATE_PRODUCT: `${API}${BILLING}${V1}${BILLING}/user-purchase/reactivate-base-package`,
    CHECK_PACKAGE_PROVISION_STATUS : `${API}${BILLING}${V1}${BILLING}/base-provision-status`,

    //prepaid add new flow end points 
    ADD_NEW_PREPAID_CONNECTION: `${API}${USER}${V1}${SECONDARY}${PREPAID}/connections`,
    SUBMIT_ADD_NEW_PREPAID_CONNECTIONS: `${API}${USER}${V1}${SECONDARY}${PREPAID}/connections-details`,
    UNSUB_PREPAID_CONNECTION: `${API}${USER}${V1}/connections/unsubscribe`,
    PREPAID_PRODUCT_PURCHASE: `${API}${BILLING}${V1}${BILLING}/user-purchase/prepaid`,

    GET_MY_PURCHASES: `${API}${BILLING}${V1}${BILLING}/prepaid/payments`,
    RETRY_PACKAGE: `${API}${BILLING}${V1}${BILLING}/user-purchase/purchase-retry`,

    //mobitel payment history api
    GET_MOBITEL_PURCHASES: `${API}${BILLING}${V1}${BILLING}/mobitel/payments`,

    //Fetch package for suspended users
    GET_REACTIVE_PACKAGE: `${API}${BILLING}${V1}${BILLING}/user-products/reactivate/by-packagetype`,
    GET_REACTIVE_PRODUCTS: `${API}${BILLING}${V1}${BILLING}/user-products/reactivate/by-type`,


    //data bundles
    ALL_DATA_BUNDLES: `${API}${BILLING}${V1}${BILLING}/user-products/data-bundles`,
    GET_DATA_BUNDLE: `${API}${BILLING}${V1}${BILLING}/user-products/data-bundle`,
    DATA_BUNDLE_PURCHASE_STATUS: `${API}${BILLING}${V1}${BILLING}/user-products/data-bundle/status`
}