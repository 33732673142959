import React, { Component } from 'react'
import { PaymentSuccess } from '../../components/paymentStatus/paymentSuccess'
import { productSelectors } from "../landingPage/ducks";
import { MOBITEL_SERVICE_TYPE } from '../../util/Constants';
import { getServiceType } from '../../util/ArrayValidator';
import { connect } from "react-redux";
import history from '../../_helpers/history';

class ProvisioningErrors extends Component {

  componentDidMount() {
    const { featuredPackages } = this.props
    if (!featuredPackages.packagePurchaseStatus) {
      history.push('/')
    }
  }

  render() {
    const { featuredPackages } = this.props
    let serviceType = getServiceType()
    let packageDetails = featuredPackages.packagePurchaseStatus
    return (
      <div> {packageDetails ? <div className="landing-provisioning"><PaymentSuccess subscribe={packageDetails} connectionType={serviceType === MOBITEL_SERVICE_TYPE ? "6" : "7"} /></div> : <div></div>}</div>
    )
  }
}

const mapStateToProps = state => {
  return {
    featuredPackages: productSelectors.getFeaturedPackages(state)
  }
}
export default connect(
  mapStateToProps,
  null
)(ProvisioningErrors);