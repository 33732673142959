import types from './types'
import { handleActions } from 'redux-actions';

const basicState = {
    loading: true,
    hasError: false,
    error: {},
}

const initialState = {
    devices: {
        ...basicState,
        devicesLoading: true
    },
    removeDevice: {
        ...basicState,
        removeDeviceLoading: false
    },
    mySVODs: {
        ...basicState,
        mySVODs: []
    },
    myVODs: {
        ...basicState,
        myVODs: []
    },
    myScreen: {
        ...basicState,
        myScreen: []
    },
    paymentDetails: {
        ...basicState,
        paymentDetails: []
    },
    myPromotionalPackage: {
        ...basicState,
        myPromotionalPackage: []
    },
}

export default handleActions({
    [types.GET_DEVICES]: (state, { payload }) => (
        {
            ...state,
            devices: {
                ...state.devices,
                devicesLoading: true,
                devices: undefined,
                devicesError: undefined
            }
        }),
    [types.GET_DEVICES_SUCCESS]: (state, { payload }) => (
        {
            ...state,
            devices: {
                ...state.devices,
                devicesLoading: false,
                devices: payload,
                devicesError: null
            }
        }),

    [types.GET_DEVICES_FAILED]: (state, { payload }) => (
        {
            ...state,
            devices: {
                ...state.devices,
                devicesLoading: false,
                devices: null,
                devicesError: payload
            }
        }),
    [types.REMOVE_DEVICE]: (state, { payload }) => {
        return {
            ...state,
            removeDevice: {
                ...state.removeDevice,
                removeDeviceLoading: true,
                removeDeviceId: payload
            }
        }
    },
    [types.REMOVE_DEVICE_SUCCESS]: (state, { payload }) => {
        if (state.devices.devices && Array.isArray(state.devices.devices) && state.devices.devices.length !== 0) {
            state.devices.devices && state.devices.devices.map((removeId, index) => {
                if (removeId.deviceUid === state.removeDevice.removeDeviceId) {
                    return state.devices.devices.splice(index, 1);
                }
                return null
            })
        }
        return {
            ...state,
            removeDevice: {
                ...state.removeDevice,
                removeDeviceLoading: false,
                removeDevice: true,
                removeDeviceError: undefined
            }
        }
    }
    ,

    [types.REMOVE_DEVICE_FAILED]: (state, { payload }) => {

        return {
            ...state,
            removeDevice: {
                ...state.removeDevice,
                removeDeviceLoading: false,
                removeDevice: undefined,
                removeDeviceError: payload
            }
        }
    },
    [types.MY_SVOD_SUCCESS]: (state, { payload }) => ({
        ...state,
        mySVODs: {
            ...state.mySVODs,
            loading: false,
            mySVODs: payload
        }
    }),
    [types.MY_SVOD_FAIL]: (state, { payload }) => ({
        ...state,
        mySVODs: {
            ...state.mySVODs,
            loading: false,
            hasError: true,
            error: { payload }
        }
    }),
    [types.MY_VOD_SUCCESS]: (state, { payload }) => ({
        ...state,
        myVODs: {
            ...state.myVODs,
            loading: false,
            myVODs: payload
        }
    }),
    [types.MY_VOD_FAIL]: (state, { payload }) => ({
        ...state,
        myVODs: {
            ...state.myVODs,
            loading: false,
            hasError: true,
            error: { payload }
        }
    }),
    [types.MY_SCREEN]: (state, { payload }) => ({
        ...state,
        myScreen: {
            ...state.myScreen,
            loading: true,
            myScreen: undefined
        }
    }),
    [types.MY_SCREEN_SUCCESS]: (state, { payload }) => ({
        ...state,
        myScreen: {
            ...state.myScreen,
            loading: false,
            myScreen: payload
        }
    }),
    [types.MY_SCREEN_FAIL]: (state, { payload }) => ({
        ...state,
        myScreen: {
            ...state.myScreen,
            loading: false,
            hasError: true,
            error: { payload }
        }
    }),
    [types.GET_PAYMENTS_SUCCESS]: (state, { payload }) => ({
        ...state,
        paymentDetails: {
            ...state.paymentDetails,
            loading: false,
            paymentDetails: payload
        }
    }),
    [types.GET_PAYMENTS_FAIL]: (state, { payload }) => ({
        ...state,
        paymentDetails: {
            ...state.paymentDetails,
            loading: false,
            hasError: true,
            error: { payload }
        }
    }),
    [types.MY_PROMOTIONAL_PACKAGE_SUCCESS]: (state, { payload }) => ({
        ...state,
        myPromotionalPackage: {
            ...state.myPromotionalPackage,
            loading: false,
            myPromotionalPackage: payload
        }
    }),
    [types.MY_PROMOTIONAL_PACKAGE_FAILED]: (state, { payload }) => ({
        ...state,
        myPromotionalPackage: {
            ...state.myPromotionalPackage,
            loading: false,
            hasError: true,
            error: { payload }
        }
    }),
}, initialState)