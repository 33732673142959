import React from 'react'
import { DiscountPriceComponent } from './../DiscountPriceComponent/DiscountPriceComponent'
// import {postpaid, months} from "../../util/Constants"
import { PRODUCT, USER } from "../../util/Constants"
import { validArray, getNestedValue, getUtilizationPeriod } from '../../util/ArrayValidator';
import { DiscountPrices } from './../DiscountPrices/DiscountPrices';

export const PackageDetails = ({
    name,
    description,
    purchaseOption,
    price,
    isPrepaid,
    productDetails,
    isRegistration,
    additionalScreenPrice,
    products,
    additionalDetails
}) => {
    let showPrice, discountLevelProduct, discountLevelUser;
    let utilizationPeriodUnit= getNestedValue(productDetails && productDetails.purchaseOption, ['utilization', 'utilizationPeriodUnit'])
    let showUtilizationPeriod = getUtilizationPeriod(productDetails, utilizationPeriodUnit)
    
    if (productDetails && productDetails.discountOptionGroup && productDetails.discountOptionGroup.discountOptions && validArray(productDetails.discountOptionGroup.discountOptions)) {
        discountLevelProduct = productDetails.discountOptionGroup && productDetails.discountOptionGroup.discountOptions.filter(data => data.discountLevel === PRODUCT);
        discountLevelUser = productDetails.discountOptionGroup && productDetails.discountOptionGroup.discountOptions.filter(data => data.discountLevel === USER);
        showPrice =
            <div className="product-purchase-content">
                <div className="price-box" style={{ "marginLeft": "unset" }}>
                    <DiscountPrices price={productDetails.discountOptionGroup.purchasePrice && productDetails.discountOptionGroup.purchasePrice + additionalScreenPrice} showTax={true} oldPrice={
                        productDetails.discountOptionGroup.actualPrice} precentage={productDetails.purchaseOption.discountPercentage} productPeriod={showUtilizationPeriod} discountLevelProduct={discountLevelProduct && discountLevelProduct[0]} discountLevelUser={discountLevelUser && discountLevelUser[0]}
                        discountPriceLoading={false} type="package" showUtilizationPeriod={showUtilizationPeriod} utilizationPeriodUnit={utilizationPeriodUnit}
                    />
                </div>
            </div>
    } else {
        showPrice = productDetails && productDetails.discountOptionGroup && <div className="price-box" style={{ "marginLeft": "unset" }}><DiscountPriceComponent price={productDetails && productDetails.discountOptionGroup && productDetails.discountOptionGroup.purchasePrice + additionalScreenPrice} showTax={true} oldPrice={productDetails && productDetails.discountOptionGroup && productDetails.discountOptionGroup.actualPrice} precentage={purchaseOption && purchaseOption.discountPercentage} period={ showUtilizationPeriod} /></div>
    }
    // }
    // else{
    //     showPrice = purchaseOption && <DiscountPriceComponent price={price} showTax={isPrepaid} oldPrice={purchaseOption && purchaseOption.absoluteDiscount} precentage={purchaseOption && purchaseOption.discountPercentage} period={utilizationPeriodUnit}/>
    // }

    return <>
        <h4>{name}</h4>
        {additionalDetails && <b className="addtional-package-details">{additionalDetails[0].name}</b>}
        <p className="package-description" >{description}</p>
        {showPrice}
    </>
}