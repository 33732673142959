import React from "react";

const CustomListboxSelect = props => {
    const {
        handleOpenOptions,
        openOptions,
        currentOption,
        selectRef,
        placeHolder
    } = props;
    return (
        <div
            tabIndex="0"
            role="button"
            onClick={handleOpenOptions}
            onKeyDown={handleOpenOptions}
            aria-pressed={openOptions}
            aria-expanded={openOptions}
            className="select-item"
            // Use the `ref` callback to store a reference to the text input DOM
            // element in an instance field
            ref={selectRef}
        >
            {currentOption === undefined ? (
                placeHolder
            ) : (
                    <span>
                        <span>{currentOption}</span>
                    </span>
                )}
            <span className={`chevron ${openOptions ? 'top' : 'bottom'}`}></span>
        </div>
    );
};

export default CustomListboxSelect;
