// import { connect } from "react-redux";
import React, { Component } from "react";
import CustomListboxSelect from "./CustomListboxSelect";
import CustomListboxOptions from "./CustomListboxOptions";

import './select.css'

export default class CustomListboxContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentOption: props.currentOption,
            currentOptionValue: props.currentOptionValue,
            openOptions: false,
            focusedOption: undefined
        };
        this.selectRef = React.createRef();
        this.arrayOfOptionsRefs = [];
    }

    clearOptionsRefs = () => {
        this.arrayOfOptionsRefs = [];
    };

    handleOpenOptions = event => {
        switch (event.type) {
            case "click":
                this._handleOpenOptions(event);
                break;
            case "keydown":
                if (event.key === "Enter" || event.key === " ") {
                    this._handleOpenOptions(event);
                }
                break;
            default:
        }
    };

    _handleOpenOptions = event => {
        this.setState(
            () => {
                return {
                    openOptions: !this.state.openOptions,
                    focusedOption: document.activeElement.id
                };
            },
            () => {
                this.arrayOfOptionsRefs[0] && this.arrayOfOptionsRefs[0].focus();
            }
        );
    };

    handleSubmit = (selectedOption) => {
        this.props.handleSubmit(selectedOption)
    };

    static getDerivedStateFromProps(nextProps){
        return({
            currentOption: nextProps.currentOption
        })
    }

    handleOptionsEvents = (selectedOption, index, event) => {
        selectedOption.selectedIndex = index;
        switch (event.type) {
            case "click":
                this.setState(
                    () => ({
                        currentOption: selectedOption.label,
                        currentOptionValue: selectedOption.value,
                        openOptions: !this.state.openOptions
                    }),
                    () => {
                        this.handleSubmit(selectedOption);
                    }
                );
                this.selectRef.current.focus();
                break;
            case "keydown":
                if (event.key === "Enter" || event.key === " ") {
                    this.setState(
                        () => ({
                            currentOption: selectedOption.label,
                            currentOptionValue: selectedOption.value,
                            openOptions: !this.state.openOptions
                        }),
                        () => {
                            this.handleSubmit(selectedOption);
                        }
                    );
                    this.selectRef.current.focus();
                }
                if (event.key === "ArrowUp") {
                    event.preventDefault();
                    this.arrayOfOptionsRefs[index - 1].focus();
                    this.setState(() => ({
                        focusedOption: document.activeElement.id
                    }));
                }
                if (event.key === "ArrowDown") {
                    event.preventDefault();
                    this.arrayOfOptionsRefs[index + 1].focus();
                    this.setState(() => ({
                        focusedOption: document.activeElement.id
                    }));
                }
                if (event.key === "Escape") {
                    this.setState(
                        () => {
                            return { openOptions: !this.state.openOptions };
                        },
                        () => {
                            this.selectRef.current.focus();
                        }
                    );
                }
                break;
            default:
        }
    };

    setOptionRef = element => {
        // because refs are called when ColorListboxOptions is unmounted
        // don't add it if it's null
        if (element !== null) {
            this.arrayOfOptionsRefs.push(element);
        }
    };

    render() {
        let { currentOption, openOptions, focusedOption } = this.state;
        let { options, placeHolder } = this.props;
        return (
            <div>
                <CustomListboxSelect
                    handleOpenOptions={this.handleOpenOptions}
                    openOptions={this.state.openOptions}
                    // Use the `ref` callback to store a reference to the text input DOM
                    // element in an instance field
                    selectRef={this.selectRef}
                    currentOption={currentOption}
                    placeHolder={placeHolder}
                //openOptions={openOptions}
                />
                <>
                    {openOptions === true ? (
                        <CustomListboxOptions
                            handleOptionsEvents={this.handleOptionsEvents}
                            setOptionRef={this.setOptionRef}
                            currentOption={"currentOption"}
                            focusedOption={focusedOption}
                            options={options}
                        />
                    ) : (
                            // clear the refs array when ColorListbox is not being rendered
                            [this.clearOptionsRefs(), null]
                        )}
                </>
            </div>
        );
    }
}