import { createAction } from 'redux-actions'
import types from './types'

export default {
    getTypes: createAction(types.GET_TYPES),
    getTypesSuccess: createAction(types.GET_TYPES_SUCCESS),
    getTypesFailed: createAction(types.GET_TYPES_FAILED),

    getDistricts: createAction(types.GET_DISTRICTS),
    getDistrictsSuccess: createAction(types.GET_DISTRICTS_SUCCESS),
    getDistrictsFailed: createAction(types.GET_DISTRICTS_FAILED),

    nonSltDetailsSubmit: createAction(types.NON_SLT_DETAILS),
    nonSltDetailsSubmitSuccess: createAction(types.NON_SLT_DETAILS_SUCCESS),
    nonSltDetailsSubmitFailed: createAction(types.NON_SLT_DETAILS_FAILED),

    confirmEmail: createAction(types.CONFIRM_EMAIL),
    confirmEmailSuccess: createAction(types.CONFIRM_EMAIL_SUCCESS),
    confirmEmailFailed: createAction(types.CONFIRM_EMAIL_FAILED)
}