// Selectors
import { createStructuredSelector } from 'reselect'

const allPackages = state => state.AllProducts.allPackages.allPackages;
const allPackagesLoading = state => state.AllProducts.allPackages.allPackagesLoading

const distinctProducts = state => state.AllProducts.distinctProducts.distinctProducts
const distinctProductsLoading = state => state.AllProducts.distinctProducts.distinctProductsLoading

const productTypes = state => state.AllProducts.productTypes.productTypes
const secondaryAllowedProduct = state => state.AllProducts.secondaryAllowedProduct.secondaryAllowedProduct

const connectionContentSelector = state => state.Products.connectionContent

const getfilteredPackages = state => state.AllProducts.allPackages.filteredPackages
const getPackageId = state => state.AllProducts.distinctProducts.packageID

export default {
    getPackageContent: createStructuredSelector({
        allPackages: allPackages,
        allPackagesLoading: allPackagesLoading,
        distinctProducts: distinctProducts,
        distinctProductsLoading: distinctProductsLoading,
        productTypes: productTypes,
        secondaryAllowedProduct: secondaryAllowedProduct,
        connectionContent: connectionContentSelector,
        filteredPackages: getfilteredPackages,
        packageID: getPackageId
    })
} 