import React from 'react';
import "./DiscountSlot.scss";

function DiscountSlot(props) {
    // if(props.type === "package"){
    //     return (
    //         <Col>
    //             <div className="row package-row-till">
    //                 <p className="till">{props.till}</p>
    //             </div>
    //             <div className="row">
    //                 <Col className="align-discount-center"><span className={`discount-value ${props.from}`} >{`${props.discountpercentage} OFF`}</span></Col>
    //             </div>
    //         </Col>
    //     )
    // }else{
        return (
            <>
                <div class={props.from === "user" ? "after-price-user" : "after-price"}>
                    {props.till}
                    <h5><span>{`${props.discountpercentage}`}</span> OFF</h5>
                </div>
            </>
        )
    // }
}

export default DiscountSlot
