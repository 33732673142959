import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "./PaymentStyles.scss";
import { taxCalculation } from '../../util/ArrayValidator';
import { PREPAID_SERVICE_TYPE, MOBITEL_SERVICE_TYPE } from "../../util/Constants";
import { ProductProvisioningError } from '../ProductPageView/ProductProvisioningError'

function SuccessfullPayment({data, defaultPrice, purchaseDetails, referenceId, serviceType, provisioningStatus, onClick}) {
    let price= serviceType===PREPAID_SERVICE_TYPE && taxCalculation(defaultPrice)
    return (
        <Container className="successful-payment">
            <div>
                <Row className="successful-icon-row">
                    <Col className="align-center"><i class="far fa-check-circle successful-payment-icon"></i></Col>
                </Row>
                <Row>
                    <Col className="align-center"><p>Payment Successful</p></Col>
                </Row>
                <Row>
                    <Col className="align-center"><p>{data.name}</p></Col>
                </Row>
                <Row>
                    {serviceType===PREPAID_SERVICE_TYPE &&<Col className="align-center"><h5 className="paid-amount">Rs. {price.toFixed(2)}</h5></Col>}
                    {serviceType===MOBITEL_SERVICE_TYPE &&<Col className="align-center"><h5 className="paid-amount">Rs. {defaultPrice.toFixed(2)} +tax</h5></Col>}
                </Row>
                <Row>
                    {serviceType===PREPAID_SERVICE_TYPE && <Col className="align-center"><p>Using your {purchaseDetails.paymentMethod} {purchaseDetails.cardNo}</p></Col>}
                    {serviceType===MOBITEL_SERVICE_TYPE && <Col className="align-center"><p>Using your Mobitel Connection {localStorage.userTelephoneNumber}</p></Col>}
                </Row>
                <Row>
                    <Col className="align-center"><p>Reference No: {referenceId}</p></Col>
                </Row>
                <Row className ="provision-alert">
                    {provisioningStatus &&<Col className="align-center">
                       <ProductProvisioningError isModal={true}/>
                    </Col>}
                </Row>
                {/* {provisioningStatus && <Row>
                    <Col className="continue-btn"><CustomButton text="CONTINUE" onClick={onClick}/></Col>
                </Row>} */}
            </div>
        </Container>
    )
}

export default SuccessfullPayment
