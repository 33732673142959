import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import { bindActionCreators } from "redux"
import './style.scss'
import history from '../../_helpers/history'
import { Nav, Navbar, Button, NavDropdown } from 'react-bootstrap'
import SideNavBar from './SideNavBar'
import { CustomModal } from './../controls/CustomModal'
import { productSelectors, productActions } from '../../modules/landingPage/ducks';
import { profileActions } from '../../modules/profile/ducks';
import { allProductsActions } from '../../modules/AllProducts/ducks'
// import ProductModal from "../controls/ProductModal"
import ChangePassword from "../../modules/changePassword/ChangePassword";
import NotifyModal from '../controls/NotifyModal';

import CustomSelectBox from '../customSelect/CustomListboxContainer'
import { getLoggedInStatus, getConnectionId } from '../../util/ArrayValidator';

import productTypeIDs from '../../modules/AllProducts/ducks/productTypeIDs'
import { MOBITEL_HEADER_ENRICHMENT } from '../../util/Constants';
// import font from '../../fonts'

let isLoggedIn = false
class LandingNavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: undefined,
            isSideNavBarClose: true,
            openModal: false,
            showModal: false,
            showNotifyModal: !true,
            passwordUpdateMessage: null,
            passwordUpdateDetails: {
                error: null,
                showModal: false,
                showNotifyModal: false
            },
            passwordUpdateShowModal: false,
            passwordUpdateShowNotifyModal: false,
            telephoneNumber: null,
            suspendedLoading: true
        }
    }

    componentDidMount() {
        isLoggedIn = getLoggedInStatus()

        // After Login fire Product Type, UserDetails, getConnectionContent, getConnectionDetails
        if (isLoggedIn) {
            !this.props.userDetails && this.props.productActions.getUserDetails();
            if (this.props.connectionDetails === undefined) {
                this.props.productActions.getConnectionContent(getConnectionId());
                this.props.productActions.getConnectionDetails({ connectionId: getConnectionId() })
            }
        }
        this.setState({
            selectedIndex: localStorage.selectedUserIndex
        })

    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.passwordUpdateShowModal !== prevState.passwordUpdateShowModal) {
            return ({
                passwordUpdateShowModal: nextProps.passwordUpdateShowModal,
                passwordUpdateShowNotifyModal: nextProps.passwordUpdateShowNotifyModal,
                passwordUpdateMessage: nextProps.passwordUpdateMessage,
                passwordUpdateDetails: nextProps.passwordUpdateDetails
            })
        }
        // debugger
        if (nextProps.passwordUpdateShowNotifyModal !== prevState.passwordUpdateShowNotifyModal) {
            return ({
                passwordUpdateShowNotifyModal: nextProps.passwordUpdateShowNotifyModal,
                passwordUpdateMessage: nextProps.passwordUpdateMessage,
                passwordUpdateDetails: nextProps.passwordUpdateDetails

            })
        }

        if (nextProps.connectionContent && nextProps.connectionContent.telephoneNumber !== prevState.telephoneNumber) {
            return ({
                telephoneNumber: nextProps.connectionContent.telephoneNumber,
                selectedIndex: localStorage.selectedUserIndex

            })
        }
        if (localStorage.selectedUserIndex) {
            return ({
                selectedIndex: localStorage.selectedUserIndex

            })
        }
        return null
    }

    componentWillUnmount() {
        this.props.productActions.reset()
    }

    onClickLink = () => {
        this.setState({
            openModal: true
        })
    }
    onClose = () => {
        this.setState({
            openModal: false
        })
    }
    onClickModal = () => {

    }
    onClick = () => {
        // this.props.getUserDetails();
        history.push('/login')
    }

    state = {
        isSideNavBarClose: true
    }

    handleChange = (e) => {

        if (e.value === "add") {
            window.location = MOBITEL_HEADER_ENRICHMENT
        }
        else {
            this.setState({ selectedIndex: e.selectedIndex, suspendedLoading: true })
            const { connectionDetails } = this.props
            this.props.productActions.getAllowedSecondaryPackagesLoading()
            let isBroadBand = connectionDetails && connectionDetails[e.selectedIndex] && connectionDetails[e.selectedIndex].broadband
            let connectionActiveStatus = connectionDetails && connectionDetails[e.selectedIndex] && connectionDetails[e.selectedIndex].status
            this.props.productActions.getConnectionContent(e.value)
            this.props.productActions.getAllowedSecondaryPackages({
                connectionId: e.value,
                // connectionId: this.props.connectionDetails && Array.isArray(this.props.connectionDetails) && this.props.connectionDetails[this.state.selectedIndex].connectionId,
                typeId: "5d22eda922224e2be4ad4047"
            })
            this.props.productActions.getFeaturedSvods({
                connectionId: e.value,
                // connectionId: this.props.connectionDetails && Array.isArray(this.props.connectionDetails) && this.props.connectionDetails[this.state.selectedIndex].connectionId,
                tagId: "5d22e08b22224e2be4ad4042",
                typeId: "5d2e005cacd856260c231b01"
            })
            this.props.productActions.getOnDemandContents({
                connectionId: e.value,
                // connectionId: this.props.connectionDetails && Array.isArray(this.props.connectionDetails) && this.props.connectionDetails[this.state.selectedIndex].connectionId,
                tagId: "5d22e08b22224e2be4ad4042",
                typeId: "5d22e60422224e2be4ad4046"
            })
            this.props.productActions.getFeaturedChannels({
                connectionId: e.value,
                // connectionId: this.props.connectionDetails && Array.isArray(this.props.connectionDetails) && this.props.connectionDetails[this.state.selectedIndex].connectionId,
                tagId: "5d22e08b22224e2be4ad4042",
                typeId: "5d22e5e222224e2be4ad4045"
            })

            isBroadBand && this.props.productActions.getPurchasedPrimaryDataBundle({
                connectionId: e.value,
                typeId: "5d444c40ee6797225cdead4b"
            })

            //get banner content
            // if(featuredPackages){
            //     this.props.productActions.getBannerContent(getBasepackage());
            // }

            this.props.productActions.getSelectedConnectionIndex(e.selectedIndex);

            //set conID and selectedUserIndex to localStorage
            localStorage.setItem('conID', e.value);
            localStorage.setItem('selectedUserIndex', e.selectedIndex);
            localStorage.setItem('serviceType', e.subText)
            localStorage.setItem('isBroadBand', isBroadBand)
            localStorage.setItem('connectionStatus', connectionActiveStatus)
            localStorage.setItem('userTelephoneNumber', connectionDetails[e.selectedIndex].telephoneNumber)
        }
        if (this.props.location.pathname === "/profile" && e.value !== "add") {
            let conid = e.value
            this.props.profileActions.getDevices(conid)
            this.props.productActions.getAllowedSecondaryPackages({
                connectionId: conid,
                typeId: "5d22eda922224e2be4ad4047"
            })
            this.props.productActions.getPurchasedPrimaryDataBundle({
                connectionId: conid,
                typeId: "5d444c40ee6797225cdead4b"
            })
            this.props.allProductsActions.getAllProducts({
                conid: conid,
                subTypeId: productTypeIDs.CHANNEL_ID
            });

            this.props.profileActions.getMyVOD({
                conid: conid,
                subTypeId: productTypeIDs.VOD_ID
            });
            this.props.profileActions.getMySVOD({
                conid: conid,
                subTypeId: productTypeIDs.SVOD_ID
            });
            this.props.profileActions.getMyScreen({
                conid: conid,
                subTypeId: productTypeIDs.DEVICE_ID
            })
            this.props.profileActions.getMyPurchases(conid)
            this.props.profileActions.getMyPromotionalPackage();
        }

    }

    closeSideNavBar() {
        this.setState({
            isSideNavBarClose: true
        })
    }

    logOut = () => {
        // debugger
        this.props.productActions.logout()
    }

    showLoggedUser() {
        return (
            <>
                <span style={{ color: "#ffff", marginRight: 10 }}>{localStorage.selectedUserIndex ? this.props.connectionDetails && Array.isArray(this.props.connectionDetails) && this.props.connectionDetails.length !== 0 && this.props.connectionDetails[localStorage.selectedUserIndex] && this.props.connectionDetails[localStorage.selectedUserIndex].name : this.props.connectionDetails && Array.isArray(this.props.connectionDetails) && this.props.connectionDetails.length !== 0 && this.props.connectionDetails[0].name}</span>
                <img src={require("./../../images/user.png")} className="profile-img" alt="" />
            </>
        )
    }

    goToProfile = () => {
        history.push('/profile')
    }

    handleShow = () => {
        this.setState({ showModal: true });
    };
    onCloseModal = () => {
        this.setState({ showModal: false, showModals: false });
        this.props.productActions.passwordUpdateShowModalClose()
    };

    onClickOk = e => {
        this.setState({
            showModal: false,
            showModals: true
        });
        this.props.productActions.passwordUpdateShowModalClose()
    };

    onClickNotifyOk = e => {
        this.props.productActions.passwordUpdateShowModalClose()
        this.props.productActions.passwordUpdateShowNotifyModalClose()
    };

    handleChangePassword = () => {
        this.setState({ showModal: true });
        this.props.productActions.passwordUpdateShowModalOpen()
    }

    passwordUpdateHandler = (oldPassword, newPassword) => {
        this.props.productActions.passwordUpdate({ oldPassword: oldPassword, newPassword: newPassword, userData: this.props.userDetails })
    }
    getActiveStatus = (connectionContent, selectedConnection) => {
        let activeStatus, serviceType
        connectionContent && connectionContent.map((connectionData, index) => {
            if (connectionData.connectionId === parseInt(selectedConnection)) {
                if (connectionData.status === "active") {
                    activeStatus = "active";
                    serviceType = connectionData.serviceType;
                } else if (connectionData.status === "pending_terminated") {
                    activeStatus = "pending_terminated";
                    serviceType = connectionData.serviceType;
                }
                else {
                    let conid = getConnectionId();
                    activeStatus = "suspended";
                    serviceType = connectionData.serviceType;
                    if (this.props.location.pathname === "/") {
                        this.props.productActions.getAllowedSecondaryPackages({
                            connectionId: conid,
                            typeId: "5d22eda922224e2be4ad4047"
                        })
                        this.props.allProductsActions.getAllProducts({
                            conid: conid,
                            subTypeId: productTypeIDs.CHANNEL_ID
                        });

                        this.props.profileActions.getMyVOD({
                            conid: conid,
                            subTypeId: productTypeIDs.VOD_ID
                        });
                        this.props.profileActions.getMySVOD({
                            conid: conid,
                            subTypeId: productTypeIDs.SVOD_ID
                        });
                        this.setState({ suspendedLoading: false })

                    }
                }
            }
            return null
        })
        return { activeStatus, serviceType }
    }


    render() {
        const isLoggedIn = getLoggedInStatus();
        const showDropDown = isLoggedIn && (this.props.match.path === "/" || this.props.match.path === "/profile")
        const { userDetails, connectionDetails } = this.props
        const { openModal, selectedIndex, suspendedLoading } = this.state;
        let telephoneNumber = connectionDetails && connectionDetails[localStorage.selectedUserIndex] && connectionDetails[localStorage.selectedUserIndex].telephoneNumber;
        let connectionStatus = suspendedLoading && this.getActiveStatus(connectionDetails, getConnectionId());

        return (
            <>
                {/* <SideNavBar closeAction={this.closeSideNavBar.bind(this)} isSideNavBarClose={this.state.isSideNavBarClose} isBroadband={connectionDetails && connectionDetails[localStorage.selectedUserIndex] && connectionDetails[localStorage.selectedUserIndex].broadband} /> */}

                <SideNavBar
                    closeAction={this.closeSideNavBar.bind(this)}
                    isSideNavBarClose={this.state.isSideNavBarClose}
                    goToProfile={this.goToProfile}
                    isBroadband={connectionDetails && connectionDetails[localStorage.selectedUserIndex] && connectionDetails[localStorage.selectedUserIndex].broadband}
                    logOut={() => this.props.productActions.logout()}
                    handleChangePassword={this.handleChangePassword}
                    options={connectionDetails && connectionDetails.map(v => {
                        //   debugger
                        return { value: v.connectionId, label: v.telephoneNumber, subText: v.serviceType }
                    })}
                    currentOption={telephoneNumber}
                    currentOptionValue={localStorage.conID}
                    placeHolder="Select"
                    handleSubmit={this.handleChange}
                    showDropDown={showDropDown}
                    serviceType={this.props.connectionDetails &&
                        Array.isArray(this.props.connectionDetails) &&
                        this.props.connectionDetails.length !== 0 &&
                        this.props.connectionDetails[localStorage.selectedUserIndex] &&
                        this.props.connectionDetails[localStorage.selectedUserIndex].serviceType}
                    userName={localStorage.selectedUserIndex ?
                        this.props.connectionDetails &&
                        Array.isArray(this.props.connectionDetails) &&
                        this.props.connectionDetails.length !== 0 &&
                        this.props.connectionDetails[localStorage.selectedUserIndex] &&
                        this.props.connectionDetails[localStorage.selectedUserIndex].name
                        :
                        this.props.connectionDetails &&
                        Array.isArray(this.props.connectionDetails) &&
                        this.props.connectionDetails.length !== 0 &&
                        this.props.connectionDetails[0].name
                    }
                    userProfilePic={"./../../images/user.png"}
                    connectionStatus={connectionStatus}
                />


                <Navbar className="navbar" expand={isLoggedIn ? "md" : "sm"} variant="blue" >
                    <Navbar.Brand>
                        <i className="fa fa-align-justify" style={{ marginRight: 15, color: '#ffff' }} onClick={() => this.setState({ isSideNavBarClose: false })}></i>
                        <Link to="/"><img className="logo-img" src={require("../../assets/images/PeoTVGOLogo.PNG")}  alt='' style={{width: "50px"}} /></Link>
                        {/* {window.innerWidth < 576 && this.props.match.path !== "/termsandconditions" &&<Button onClick={this.onClick} variant="round" style={{marginLeft:"100%"}}>
                                        <i className="fa fa-sign-in-alt"></i> 
                                    </Button> } */}
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="toggle-navigation" className={isLoggedIn ? "hide-me" : "hide-me-before-login"} ><i className="fa fa-align-justify" /></Navbar.Toggle>
                    <Navbar.Collapse id="responsive-navbar-nav" style={{padding: 3}}>
                        <Nav className="mr-auto" style={{marginTop:'auto', marginBottom:"-10px"}}>
                            {
                                showDropDown ?
                                    <Nav.Link>
                                        {
                                            connectionDetails &&
                                            localStorage.selectedUserIndex &&
                                            <>
                                                <CustomSelectBox
                                                    options={connectionDetails.map(v => {
                                                        return { value: v.connectionId, label: v.telephoneNumber, subText: v.serviceType }
                                                    })}
                                                    currentOption={
                                                        telephoneNumber
                                                    }
                                                    currentOptionValue={localStorage.selectedUserIndex === selectedIndex && connectionDetails[selectedIndex] && connectionDetails[selectedIndex].connectionId}
                                                    placeHolder="Select"
                                                    handleSubmit={this.handleChange}
                                                />
                                            </>
                                        }
                                    </Nav.Link>
                                    :
                                    isLoggedIn &&
                                    <Nav.Link >
                                        <div
                                            style={{
                                                backgroundColor: "#7F7F7F",
                                                width: "180px",
                                                color: "#fff ",
                                                borderRadius: "5px",
                                                position: "relative",
                                                textAlign: "initial",
                                                padding: "0px 6px",
                                            }}
                                        > <p style={{ fontSize: "16px" }} >
                                                {this.props.connectionDetails && Array.isArray(this.props.connectionDetails) &&
                                                    this.props.connectionDetails.length !== 0 &&
                                                    this.props.connectionDetails[localStorage.selectedUserIndex] &&
                                                    this.props.connectionDetails[localStorage.selectedUserIndex].telephoneNumber}
                                            </p>
                                        </div>
                                    </Nav.Link>
                            }
                            {getLoggedInStatus() && <div style={{ paddingTop: "10px" }}>
                                <p>
                                    {this.props.connectionDetails &&
                                        Array.isArray(this.props.connectionDetails) &&
                                        this.props.connectionDetails.length !== 0 &&
                                        this.props.connectionDetails[localStorage.selectedUserIndex] &&
                                        this.props.connectionDetails[localStorage.selectedUserIndex].serviceType}</p></div>
                            }

                        </Nav>
                        <Nav className="mr-auto">
                            <Link
                                className={
                                    isLoggedIn ? 
                                    "nav-link self-care-text hide-me-before-login self-care-label-after-login" : 
                                    "nav-link self-care-text hide-me-before-login"
                                }
                                to="/"
                                style={{
                                    color: '#fff',
                                    paddingBottom: 5,
                                    fontFamily: "SegoeUI-Bold",
                                    fontWeight: "bold",
                                    fontSize: "25px",
                                    // marginRight: "340px"
                                }}
                            >
                                Selfcare
                                </Link>
                        </Nav>
                        <Nav>
                            {
                                isLoggedIn ?
                                    <NavDropdown
                                        alignRight
                                        title={this.showLoggedUser()}
                                        id="collasible-nav-dropdown"
                                        style={{ textAlign: 'center' }}
                                    >
                                        <NavDropdown.Item href="">
                                            <span style={{ fontWeight: '700' }}>Username</span> : {userDetails && userDetails.username}
                                        </NavDropdown.Item>
                                        <NavDropdown.Item href="">
                                            <Button style={{ padding: "5px 25px", borderRadius: 20 }} onClick={this.goToProfile}>Go to Profile</Button>
                                        </NavDropdown.Item>
                                        <NavDropdown.Item onClick={this.handleChangePassword} href="" style={{ color: '#4fa1d9' }}>Change Password</NavDropdown.Item>
                                        <NavDropdown.Item onClick={this.logOut} style={{ fontWeight: '700' }}>Log Out</NavDropdown.Item>
                                    </NavDropdown> :
                                    this.props.match.path !== "/termsandconditions" && <Button onClick={this.onClick} variant="round">
                                        <i className="fa fa-sign-in-alt"></i> Login/Register
                                    </Button>
                            }
                        </Nav>
                    </Navbar.Collapse>
                    {this.props.match.path !== "/termsandconditions" && !isLoggedIn && <Button className="hide-me-before-login-button" onClick={this.onClick} variant="round">
                        <i className="fa fa-sign-in-alt"></i> Login/Register
                                    </Button>}
                </Navbar>
                <ChangePassword
                    showModal={this.state.passwordUpdateShowModal}
                    modalStyle={{
                        display: this.state.passwordUpdateShowModal ? "flex" : "none",
                        overflow: "overlay",
                    }}
                    onClose={this.onCloseModal}
                    modalBodyText="Please confirm to unsubscribe?"
                    buttonText="Subscribe"
                    onClick={this.onClickOk}
                    passwordUpdateHandler={(oldPassword, newPassword) => this.passwordUpdateHandler(oldPassword, newPassword)}
                    isForm={true}
                />
                <NotifyModal
                    showModal={this.state.passwordUpdateShowNotifyModal}
                    modalStyle={{
                        display: this.state.passwordUpdateShowNotifyModal ? "flex" : "none",
                        overflow: "overlay"
                    }}
                    onClose={this.onClickNotifyOk}
                    type={this.state.passwordUpdateDetails && this.state.passwordUpdateDetails.hasError ? "fail" : "success"}
                    message={this.state.passwordUpdateDetails && this.state.passwordUpdateDetails.passwordUpdateMessage && this.state.passwordUpdateDetails.passwordUpdateMessage.responseErr}
                    NoBtn={true}
                    passwordUpdateHandler={(oldPassword, newPassword) => this.passwordUpdateHandler(oldPassword, newPassword)}
                    isForm={true}
                />
                <CustomModal
                    showModal={openModal}
                    modalStyle={{ display: openModal ? 'flex' : 'none' }}
                    onClose={this.onClose}
                    isForm={true}
                    buttonText="Click to Login"
                    onClick={this.onClick}
                />
            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        productTypes: productSelectors.getProductTypes(state),
        featuredChannels: productSelectors.getFeaturedChannels(state),
        featuredPackages: productSelectors.getFeaturedPackages(state),
        onDemandContents: productSelectors.getOnDemandContents(state),
        featuredSvods: productSelectors.getFeaturedSvods(state),
        bannerContent: productSelectors.getBannerContent(state),
        userDetails: productSelectors.getDetails(state),
        passwordUpdateDetails: productSelectors.getPasswordUpdate(state),
        ...productSelectors.getDetails(state),
        passwordUpdateShowModal: state.Products.passwordUpdateShowModal,
        passwordUpdateShowNotifyModal: state.Products.passwordUpdateShowNotifyModal,
        passwordUpdateMessage: state.Products.passwordUpdateMessage,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        profileActions: bindActionCreators(profileActions, dispatch),
        productActions: bindActionCreators(productActions, dispatch),
        allProductsActions: bindActionCreators(allProductsActions, dispatch)
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(LandingNavBar));
//399