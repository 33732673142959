import React from 'react';
import Footer from '../components/footer/Footer'
import { Link } from 'react-router-dom'


const DefaultLayout = (ViewComponent) => {
  return class extends React.Component {
    render() {

      return (
        <>
          <style type="text/css">
            {`
                        .self-care-text{
                           
                        }

                        .center-navbar {
                          width:43%;
                        } 
                        @media only screen and (max-width: 766px) {
                          .center-navbar {
                            // margin-top: 30px;
                            margin-left: auto!important;
                            text-align: center;
                          }
                        }
                        @media (max-width:768px) and (min-width:550px) {
                          .center-navbar {
                            width:43%;
                            margin-left: 40%;
                          }
                      }​
                        
                    `}
          </style>
          {/* font-family: "Times New Roman", Times, serif;
                            font-weight: bold;
                            font-style: oblique;
                            font-size:25px */}
          <nav className="navbar nav_bar fixed-top" >
            <Link to="/">
              <img src={require("../assets/images/PeoTVGOLogo.PNG")} className="default-logo" style={{ width: 55 }} alt="logo" />

            </Link>
            <ul className="navbar-nav mr-auto center-navbar">
              <Link
                className="nav-link self-care-text"
                to="/"
                style={{
                  color: '#fff',
                  // fontStyle: 'italic',
                  fontFamily: "SegoeUI-Bold",
                  fontWeight: "bold",
                  paddingBottom: 5,
                  // marginLeft: 8,
                  fontSize: "21px"

                }}

              // style={{
              //   color: '#dd9513',
              //   paddingBottom: 5,
              //   fontFamily: `"Times New Roman", Times, serif`,
              //   fontWeight: "bolder",
              //   // fontStyle: "oblique",
              //   fontSize: "25px"
              // }}
              >
                {this.props.location.pathname === "/login" ? "Selfcare Login" : "Selfcare"}
              </Link>
            </ul>

          </nav>
          <div className="content_div">
            <ViewComponent />
          </div>
          <Footer />
        </>
      )
    }
  }
};

export default DefaultLayout;

