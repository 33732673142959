import React from 'react'


export const CustomButton = ({
    id,
    loading,
    onClick,
    text,
    style,
    display,
    justifyContent,
    margin,
    alignItems,
    className,
    disabled,
    isDummy
}) => {
    return <button id={id} className={`form-control btn-md btn-block mb-1 submit-button ${className}`} disabled={disabled} onClick={onClick} style={style}>
        <div  id={id} style={{
            display: 'inline-flex',
            justifyContent: "center",
            alignItems: "center",
            margin: -10
        }}>
            {
                loading &&
                <span  id={id} className="spinner-border spinner-border-sm" style={{ marginRight: 5, color: '#fff' }} ></span>
            }
            <span  id={id}>{text}</span>
        </div>
    </button>
}
