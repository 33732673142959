import {
    createAction
} from "redux-actions";
import types from "./types";

export default {
    getBannerContent: createAction(types.GET_BANNER),
    getBannerContentSuccess: createAction(types.GET_BANNER_SUCCESS),
    getBannerContentFailed: createAction(types.GET_BANNER_FAILED),

    getBannerProducts: createAction(types.GET_BANNER_TYPE_PRODUCTS),
    getBannerProductsSuccess: createAction(types.GET_BANNER_TYPE_PRODUCTS_SUCCESS),
    getBannerProductsFailed: createAction(types.GET_BANNER_TYPE_PRODUCTS_FAILED),

    getBannersBeforeLoginSuccess: createAction(types.GET_BANNER_CONTENT_BEFORE_LOGIN_SUCCESS),

    getPromotionalPackages: createAction(types.GET_PROMOTIONAL_PACKAGES),
    getPromotionalPackagesSuccess: createAction(types.GET_PROMOTIONAL_PACKAGES_SUCCESS),
    getPromotionalPackagesFailed: createAction(types.GET_PROMOTIONAL_PACKAGES_FAILED),

    resetBannerState: createAction(types.RESET_BANNER_STATE),

    verifyPackagePurchase: createAction(types.VERITFY_PACKAGE_PURCHASE),

    getPromotionalPackageProducts: createAction(types.GET_PROMOTIONAL_PACKAGE_PRODUCTS),
    getPromotionalPackageProductsSuccess: createAction(types.GET_PROMOTIONAL_PACKAGE_PRODUCTS_SUCCESS),
    getPromotionalPackageProductsFailed: createAction(types.GET_PROMOTIONAL_PACKAGE_PRODUCTS_FAILED)
}