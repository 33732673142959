import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { Row, Col, Container } from 'react-bootstrap'
import { svodSelectors, svodActions } from './ducks';
import Card from '../../components/CardComponent/Card';
import { getNestedValue } from "../../util/ArrayValidator"
import "./SvodStyles.scss";
import DaysToExpire from "../../components/DaysToExpire/DaysToExpire";
import Description from '../../components/controls/Description';
import DummyProductPageView from '../../components/DummyComponent/DummyProductPageView';

class SubSvodPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            channel: undefined,
        }
    }

    componentDidMount() {
        let id = this.props.match && this.props.match.params.id
        this.props.getSubSvod(id)
    }
    componentWillUnmount(){
        this.props.clearSvod()
    }

    render() {
        const { subSvod, subSvodLoading } = this.props
        let daysRemainingToExpire = getNestedValue(subSvod, ['daysRemainingToExpire']);
        let additionalDetails = getNestedValue(subSvod, ['additionalDetails']);
        let description = getNestedValue(subSvod, ['description']);
        let additionalDetailsList
        if (subSvod && subSvod.productTypeTypeName === 'vod') {
            additionalDetailsList = <Col>{subSvod && (subSvod.pgRating || (additionalDetails && Array.isArray(additionalDetails))) &&
                <div className="information-vod">
                    <div>
                        {additionalDetails && Array.isArray(additionalDetails) && additionalDetails.map(details => {
                            return <><p>{details.code} : <span>{details.name}</span></p></>
                        })
                        }
                        {subSvod && subSvod.pgRating && <><span>PG Rating</span> : <span>{subSvod.pgRating}</span></>}
                    </div>
                </div>}
            </Col>
        } else {
            additionalDetailsList = null
        }
        
        if(subSvodLoading){
            return (<React.Fragment>
                <DummyProductPageView
                    productType="vod"
                    showPurchasedIcon={false}
                    priceDisplay={false}
                    productDetails={subSvod}
                />
            </React.Fragment>)
        }else{
            return (
                <Container style={{ color: "#d0d0d0", marginTop: 35 }}>
                <Row>
                    <Col sm={3}>
                        <Row>
                            <Col><h4>{subSvod.name}</h4></Col>
                        </Row>
                        <Row className="days-to-expire">
                            <DaysToExpire daysToExpire={daysRemainingToExpire} />
                        </Row>
                    </Col>
                    <Col sm={7}></Col>
                </Row>
                <br />
                <Row>
                    <Col sm={3} md={3} lg={2}>
                        <Card
                            hover={false}
                            showOptions={subSvod.feature}
                            showAddNew={false}
                            imageURL={subSvod.imageURL}
                            type={subSvod.productTypeTypeName} >
                        </Card>
                    </Col>
                    {additionalDetailsList}
                </Row>
                <Row style={{ textAlign: "justify" }}>
                    <Col>
                        <Description
                            description={description}
                        />
                    </Col>
                </Row>
            </Container>
            )
        }
    }
}


const mapStateToProps = (state) => {
    return {
        ...svodSelectors.getAllSvod(state)
    }
};
export default withRouter(connect(
    mapStateToProps,
    svodActions
)(SubSvodPage));