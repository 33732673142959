import React, { Component } from 'react'
import routes from './routes'
import { Router, Route, Switch } from 'react-router-dom'
import history from './_helpers/history'
import './App.scss';
import ReactGA from 'react-ga';
import { trackingId } from './util/Constants';
class App extends Component {
  render() {
    
    ReactGA.initialize(trackingId);

    // Initialize google analytics page view tracking
    history.listen(location => {
      window.scrollTo(0, 0)//scroll to top on navigation
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.pageview(location.pathname); // Record a pageview for the given page
    });
    
    return (
      <Router history={history}>
        <Switch>
          {routes.map(
            (route, index) => (
              <Route
                key={index}
                exact={route.exact}
                path={route.path}
                component={route.layout(route.component)} />
            )
          )}
        </Switch>
      </Router>
    );
  }
}

export default App;
