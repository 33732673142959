import React from 'react'
import ContentLoader from 'react-content-loader';

export default function DummySlider() {
    return (
        <ContentLoader
            speed={2}
            width={400}
            height={100}
            viewBox="0 0 400 100"
            primaryColor="#252525"
            secondaryColor="#313131"
        >
            <rect x="1" y="0" rx="0" ry="0" width="579" height="100" />
        </ContentLoader>
    )
}
