import React, { Component } from 'react';

import './footer.scss';

export default class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="copyright">&copy; Sri Lanka Telecom PLC All Rights Reserved</div>
        <div className="version">v3.9.1</div>
      </div>
    )
  }
}
