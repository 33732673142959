import React from 'react'

function CRMFilter({ showFilter, handleFilterChange, readyFiltersArray, filtersArray, label, type, selected }) {
    var optionType = (type === "GENRE" || type === "CHANNEL")
    var styleClass = (type === "CHANNEL")
    return (
        <>
            {showFilter ?
                < select
                    className={styleClass || "vod-custom-select"}
                    onChange={handleFilterChange}>
                    <option
                        className={styleClass || "vod-select-option"}
                        value="ALL"
                        selected={selected}
                    >
                        {label}
                    </option>
                    {readyFiltersArray &&
                        filtersArray.map((item, index) => {
                            return (
                                <option
                                    key={index}
                                    className={styleClass || "vod-select-option"}
                                    id={index}
                                    name={optionType ? item.category : item.name}
                                    value={optionType ? item.category : item.code}
                                >
                                    {optionType ? item.category : item.name}
                                </option>
                            )
                        })
                    }
                </select>
                :
                null
            }
        </>
    )
}

export default CRMFilter
