// Actions
import { createAction } from "redux-actions";
import types from "./types";

export default {
  getProductTypes: createAction(types.GET_PRODUCT_TYPES),
  getProductTypesSuccess: createAction(types.GET_PRODUCT_TYPES_SUCCESS),
  getProductTypesFailed: createAction(types.GET_PRODUCT_TYPES_FAILED),

  getFeaturedChannels: createAction(types.GET_FEATURED_CHANNELS),
  getFeaturedChannelsLoading: createAction(types.GET_FEATURED_CHANNELS_LOADING),
  getFeaturedChannelsSuccess: createAction(types.GET_FEATURED_CHANNELS_SUCCESS),
  getFeaturedChannelsFailed: createAction(types.GET_FEATURED_CHANNELS_FAILED),

  getFeaturedPackages: createAction(types.GET_FEATURED_PACKAGES),
  getFeaturedPackagesLoading: createAction(types.GET_FEATURED_PACKAGES_LOADING),
  getFeaturedPackagesSuccess: createAction(types.GET_FEATURED_PACKAGES_SUCCESS),
  getFeaturedPackagesFailed: createAction(types.GET_FEATURED_PACKAGES_FAILED),

  getSecondaryFeaturedPackages: createAction(
    types.GET_SECONDARY_FEATURED_PACKAGES
  ),
  getSecondaryFeaturedPackagesLoading: createAction(
    types.GET_SECONDARY_FEATURED_PACKAGES_LOADING
  ),
  getSecondaryFeaturedPackagesSuccess: createAction(
    types.GET_SECONDARY_FEATURED_PACKAGES_SUCCESS
  ),
  getSecondaryFeaturedPackagesFailed: createAction(
    types.GET_SECONDARY_FEATURED_PACKAGES_FAILED
  ),

  getOnDemandContents: createAction(types.GET_ON_DEMAND_CONTENT),
  getOnDemandContentsLoading: createAction(types.GET_ON_DEMAND_CONTENT_LOADING),
  getOnDemandContentsSuccess: createAction(types.GET_ON_DEMAND_CONTENT_SUCCESS),
  getOnDemandContentsFailed: createAction(types.GET_ON_DEMAND_CONTENT_FAILED),

  getFeaturedSvods: createAction(types.GET_FEATURED_SVOD),
  getFeaturedSvodsLoading: createAction(types.GET_FEATURED_SVOD_LOADING),
  getFeaturedSvodsSuccess: createAction(types.GET_FEATURED_SVOD_SUCCESS),
  getFeaturedSvodsFailed: createAction(types.GET_FEATURED_SVOD_FAILED),

  getBannerContent: createAction(types.GET_BANNER),
  getBannerContentSuccess: createAction(types.GET_BANNER_SUCCESS),
  getBannerContentFailed: createAction(types.GET_BANNER_FAILED),

  //after Login
  getFeaturedPackageChannel: createAction(types.GET_FEATURED_CHANNELS_PACKAGE),
  getFeaturedPackageChannelSuccess: createAction(
    types.GET_FEATURED_CHANNELS_PACKAGE_SUCCESS
  ),
  getFeaturedPackageChannelFailed: createAction(
    types.GET_FEATURED_CHANNELS_PACKAGE_FAILED
  ),

  getUserDetails: createAction(types.GET_USER_DETAILS),
  getUserDetailsSuccess: createAction(types.GET_USER_DETAILS_SUCCESS),
  getUserDetailsFailed: createAction(types.GET_USER_DETAILS_FAILED),

  getConnectionDetails: createAction(types.GET_CONNECTION_DETAILS),
  getConnectionDetailsSuccess: createAction(
    types.GET_CONNECTION_DETAILS_SUCCESS
  ),
  getConnectionDetailsFailed: createAction(types.GET_CONNECTION_DETAILS_FAILED),

  getConnectionContent: createAction(types.GET_CONNECTION_CONTENT),
  getConnectionContentSuccess: createAction(
    types.GET_CONNECTION_CONTENT_SUCCESS
  ),
  getConnectionContentFailed: createAction(types.GET_CONNECTION_CONTENT_FAILED),

  getAllTags: createAction(types.GET_ALL_TAGS),
  getAllTagsSuccess: createAction(types.GET_ALL_TAGS_SUCCESS),
  getAllTagsFailed: createAction(types.GET_ALL_TAGS_FAILED),

  getFeaturedPackageVod: createAction(types.GET_FEATURED_VOD_PACKAGE),
  getFeaturedPackageVodSuccess: createAction(
    types.GET_FEATURED_VOD_PACKAGE_SUCCESS
  ),
  getFeaturedPackageVodFailed: createAction(
    types.GET_FEATURED_VOD_PACKAGE_FAILED
  ),

  getFeaturedPackageSvod: createAction(types.GET_FEATURED_SVOD_PACKAGE),
  getFeaturedPackageSvodSuccess: createAction(
    types.GET_FEATURED_SVOD_PACKAGE_SUCCESS
  ),
  getFeaturedPackageSvodFailed: createAction(
    types.GET_FEATURED_SVOD_PACKAGE_FAILED
  ),

  getPurchasedPrimaryPackage: createAction(types.GET_PURCHASED_PRIMARY_PACKAGE),
  getPurchasedPrimaryPackageSuccess: createAction(
    types.GET_PURCHASED_PRIMARY_PACKAGE_SUCCESS
  ),
  getPurchasedPrimaryPackageFailed: createAction(
    types.GET_SECONDARY_FEATURED_PACKAGES_FAILED
  ),

  getPurchasedPrimaryDataBundle: createAction(
    types.GET_PURCHASED_PRIMARY_DATA_BUNDLE
  ),
  getPurchasedPrimaryDataBundleSuccess: createAction(
    types.GET_PURCHASED_PRIMARY_DATA_BUNDLE_SUCCESS
  ),
  getPurchasedPrimaryDataBundleFailed: createAction(
    types.GET_PURCHASED_PRIMARY_DATA_BUNDLE_FAILED
  ),

  getOnDemandSubTypes: createAction(types.GET_ONDEMAND_SUB_TYPES),
  getOnDemandSubTypesSuccess: createAction(
    types.GET_ONDEMAND_SUB_TYPES_SUCCESS
  ),
  getOnDemandSubTypesFailed: createAction(types.GET_ONDEMAND_SUB_TYPES_FAILED),

  getAllowedSecondaryPackages: createAction(
    types.GET_ALLOWED_SECONDARY_PACKAGES
  ),
  getAllowedSecondaryPackagesSuccess: createAction(
    types.GET_ALLOWED_SECONDARY_PACKAGES_SUCCESS
  ),
  getAllowedSecondaryPackagesFailed: createAction(
    types.GET_ALLOWED_SECONDARY_PACKAGES_FAILED
  ),
  getAllowedSecondaryPackagesLoading: createAction(types.GET_ALLOWED_SECONDARY_PACKAGES_LOADING),
  getSecondaryId: createAction(types.GET_SECONDARY_ID),
  getSecondaryIdSuccess: createAction(types.GET_SECONDARY_ID_SUCCESS),
  getSecondaryIdFailed: createAction(types.GET_SECONDARY_ID_FAILED),

  // getSelectedProduct: createAction(types.GET_SELECTED_PRODUCT),
  // getSelectedProductSuccess: createAction(types.GET_SELECTED_PRODUCT_SUCCESS),
  // getSelectedProductFailed: createAction(types.GET_SELECTED_PRODUCT_SUCCESS),

  logout: createAction(types.LOGOUT),
  logoutSuccess: createAction(types.LOGOUT_SUCCESS),
  logoutFailed: createAction(types.LOGOUT_FAILED),

  unsubConnection: createAction(types.UNSUB_CONNECTION),
  unsubConnectionSuccess: createAction(types.UNSUB_CONNECTION_SUCCESS),
  unsubConnectionFailed: createAction(types.UNSUB_CONNECTION_FAILED),

  getSelectedConnectionIndex: createAction(types.GET_SELECTED_CONNECTION_INDEX),
  getSelectedConnectionIndexSuccess: createAction(types.GET_SELECTED_CONNECTION_INDEX_SUCCESS),

  passwordUpdate: createAction(types.PASSWORD_UPDATE),
  passwordUpdateSuccess: createAction(
    types.PASSWORD_UPDATE_SUCCESS
  ),
  passwordUpdateFail: createAction(types.PASSWORD_UPDATE_FAIL),
  reset: createAction(types.RESET),

  passwordUpdateShowModalOpen: createAction(types.PASSWORD_UPDATE_SHOW_MODAL_OPEN),
  passwordUpdateShowModalClose: createAction(types.PASSWORD_UPDATE_SHOW_MODAL_CLOSE),

  passwordUpdateShowNotifyModalOpen: createAction(types.PASSWORD_UPDATE_SHOW_NOTIFY_MODAL_OPEN),
  passwordUpdateShowNotifyModalClose: createAction(types.PASSWORD_UPDATE_SHOW_NOTIFY_MODAL_CLOSE),

  clearLandingState: createAction(types.CLEAR_LANDING_STATE),

  productPurchaseContent: createAction(types.PRODUCT_PURCHASE_CONTENT),

  userUnsubLoading: createAction(types.USER_UNSUB_STATUS),

  userUnsubClear: createAction(types.CLEAR_UNSUB_STATUS),

  removeProfile: createAction(types.REMOVE_PROFILE),
  removeProfileSuccess: createAction(types.REMOVE_PROFILE_SUCCESS),
  removeProfileFailed: createAction(types.REMOVE_PROFILE_FAILED),

  reactivateProduct : createAction(types.REACTIVATE_PRODUCT),
  reactivateProductSuccess : createAction(types.REACTIVATE_PRODUCT_SUCCESS),
  reactivateProductFailed: createAction(types.REACTIVATE_PRODUCT_FAILED),

  getPackagePurchaseTable: createAction(types.GET_PACKAGE_PURCHASE_TABLE),
  getPackagePurchaseTableSuccess: createAction(types.GET_PACKAGE_PURCHASE_TABLE_SUCCESS),
  getPackagePurchaseTableFailed: createAction(types.GET_PACKAGE_PURCHASE_TABLE_FAILED),

  getPackagePurchaseStatus : createAction(types.GET_PACKAGE_STATUS),
  getPackagePurchaseStatusSuccess : createAction(types.GET_PACKAGE_STATUS_SUCCESS),
  getPackagePurchaseStatusFailed : createAction(types.GET_PACKAGE_STATUS_FAILED),
  getPackagePurchaseStatusLoading : createAction(types.GET_PACKAGE_STATUS_LOADING),

  verifyPrepaidPayment : createAction(types.VERIFY_PREPAID_PAYMENT),
  verifyPrepaidPaymentSuccess : createAction(types.VERIFY_PREPAID_PAYMENT_SUCCESS),
  verifyPrepaidPaymentFailed : createAction(types.VERIFY_PREPAID_PAYMENT_FAILED),
  
  getBannerProducts: createAction(types.GET_BANNER_TYPE_PRODUCTS),
  getBannerProductsSuccess: createAction(types.GET_BANNER_TYPE_PRODUCTS_SUCCESS),
  getBannerProductsFailed: createAction(types.GET_BANNER_TYPE_PRODUCTS_FAILED),

  getBannersLoadingBeforeLogin: createAction(types.GET_BANNER_SUCCESS_BEFORE_LOGIN)

};
