import React from 'react'
import { Col, Row } from 'react-bootstrap';
import DummyMyPackage from '../DummyComponent/DummyMyPackage';
import { CustomButton } from './../controls/CustomButton'

export const MyDataBundle = ({
    featuredPackages,
    purchasedPrimaryDataBundle,
    dataBundlesPurchased,
    onClickDataBundle

}) => {
    if (featuredPackages === false && !purchasedPrimaryDataBundle) {
        return < DummyMyPackage />
    } else {
        return <Col>
            <p>DATA BUNDLE</p>
            {/* {featuredPackages == false && < DummyMyPackage />} */}
            {/* {featuredPackages && featuredPackages[0] && !purchasedPrimaryDataBundle && <Col md={3}> */}
            {<Col md={3}>


                <Row className="mt-3">
                    <div style={{ display: "block", whiteSpace: "nowrap" }}>

                        <img
                            id={1}
                            className={'selectedImage'}
                            src={dataBundlesPurchased ? purchasedPrimaryDataBundle  && purchasedPrimaryDataBundle.imageURL : require("../../assets/images/dataBundleNotFound.png")}
                            style={{ width: '120px', height: 'auto', marginRight: "15px" }}
                            alt="dataBundle"
                        />
                    </div>
                    {!dataBundlesPurchased && <Col md={12} style={{ marginTop: '10px' }}>
                        <CustomButton text="UPGRADE" className="upgrade-btn" onClick={onClickDataBundle} />
                    </Col>}
                </Row>
            </Col>
            }
        </Col>
    }
}