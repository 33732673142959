import React from 'react'
import { Container } from 'react-bootstrap';
import { DummySvodCard } from './DummyComponent'

export default function DummyAllSvodPage() {
    return (
        <Container
            style={{
                backgroundColor: "#a1a1a1a",
                position: 'relative',
                cursor: "poiner",
                marginBottom: '35px'
            }}
        >
            <div className="all-svods-inside-container">
                <Container className="container-style">
                    <h3 className="all-svods-header">ALL SVODs</h3>
                    <br />
                    <DummySvodCard />
                    <DummySvodCard />
                </Container>
            </div>
        </Container>
    )
}
