import React from 'react'

export const AdditionalScreens = ({
    id,
    idYes,
    idNo,
    yesOption,
    noOption,
    onChange,
    titleText,
    description,
    defaultChecked

}) => {
    return <div className="row">
        <div className="col">
            <div className="screen-requirment">
                <div className="row">
                    <div className="col">
                        <div className="terms-and-conditions">
                            <h6>{titleText}</h6>
                        </div>
                    </div>
                </div>
                <div className="row" onChange={onChange}>
                    <div className="col-sm-2">
                        <div className="custom-control custom-radio" >
                            <input type="radio" id={id + "extraScreen1"} name={id} className="custom-control-input" value={true} />
                            <label className="custom-control-label" htmlFor={id + "extraScreen1"}>Yes</label>
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <div className="custom-control custom-radio" >
                            <input type="radio" id={id + "extraScreen2"} name={id} className="custom-control-input" defaultChecked={defaultChecked} value={false} />
                            <label className="custom-control-label" htmlFor={id + "extraScreen2"}>No</label>
                        </div>
                    </div>
                    <div className="col">
                        <p>{description} </p>
                    </div>
                </div>

            </div>
        </div>
    </div>
}
