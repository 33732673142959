import { createAction } from 'redux-actions'
import types from './types'

export default {
    // removeProfile: createAction(types.REMOVE_PROFILE),
    // removeProfileSuccess: createAction(types.REMOVE_PROFILE_SUCCESS),
    // removeProfileFailed: createAction(types.REMOVE_PROFILE_FAILED),

    getDevices: createAction(types.GET_DEVICES),
    getDevicesSuccess: createAction(types.GET_DEVICES_SUCCESS),
    getDevicesFailed: createAction(types.GET_DEVICES_FAILED),

    removeDevice: createAction(types.REMOVE_DEVICE),
    removeDeviceSuccess: createAction(types.REMOVE_DEVICE_SUCCESS),
    removeDeviceFailed: createAction(types.REMOVE_DEVICE_FAILED),

    getMySVOD: createAction(types.MY_SVOD),
    getMySVODSuccess: createAction(types.MY_SVOD_SUCCESS),
    getMySVODFail: createAction(types.MY_SVOD_FAIL),

    getMyVOD: createAction(types.MY_VOD),
    getMyVODSuccess: createAction(types.MY_VOD_SUCCESS),
    getMyVODFail: createAction(types.MY_VOD_FAIL),

    getMyScreen: createAction(types.MY_SCREEN),
    getMyScreenSuccess: createAction(types.MY_SCREEN_SUCCESS),
    getMyScreenFail: createAction(types.MY_SCREEN_FAIL),

    getMyPurchases: createAction(types.GET_PAYMENTS),
    getMyPurchasesSuccess: createAction(types.GET_PAYMENTS_SUCCESS),
    getMyPurchasesFail: createAction(types.GET_PAYMENTS_FAIL),

    getMyPromotionalPackage : createAction(types.MY_PROMOTIONAL_PACKAGE),
    getMyPromotionalPackageSuccess : createAction(types.MY_PROMOTIONAL_PACKAGE_SUCCESS),
    getMyPromotionalPackageFailed : createAction(types.MY_PROMOTIONAL_PACKAGE_FAILED)
}