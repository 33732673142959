import { createAction } from 'redux-actions'
import types from './types'

export default {
    getAllSvod: createAction(types.GET_ALL_SVOD),
    getAllSvodSuccess: createAction(types.GET_ALL_SVOD_SUCCESS),
    getAllSvodFailed: createAction(types.GET_ALL_SVOD_FAILED),

    getSvod: createAction(types.GET_SVOD),
    getSvodSuccess: createAction(types.GET_SVOD_SUCCESS),
    getSvodFailed: createAction(types.GET_SVOD_FAILED),

    getSubSvod: createAction(types.GET_SUB_SVOD),
    getSubSvodSuccess: createAction(types.GET_SUB_SVOD_SUCCESS),
    getSubSvodFailed: createAction(types.GET_SUB_SVOD_FAILED),

    clearSvod: createAction(types.CLEAR_SVOD),

    productSubscribe: createAction(types.PRODUCT_SUBSCRIBE),
    productSubscribeSuccess: createAction(types.PRODUCT_SUBSCRIBE_SUCCESS),
    productSubscribeFailed: createAction(types.PRODUCT_SUBSCRIBE_FAILED),

    productUnSubscribe: createAction(types.PRODUCT_UNSUBSCRIBE),
    productUnSubscribeSuccess: createAction(types.PRODUCT_UNSUBSCRIBE_SUCCESS),
    productUnSubscribeFailed: createAction(types.PRODUCT_UNSUBSCRIBE_FAILED),

    getPurchaseTable: createAction(types.GET_PURCHASE_TABLE),
    getPurchaseTableSuccess: createAction(types.GET_PURCHASE_TABLE_SUCCESS),
    getPurchaseTableFailed: createAction(types.GET_PURCHASE_TABLE_FAILED),

    verifyPurchaseSvod: createAction(types.VERIFY_SVOD_PURCHASE),
    verifyPurchaseSvodSuccess: createAction(types.VERIFY_SVOD_PURCHASE_SUCCESS),
    verifyPurchaseSvodFailed: createAction(types.VERIFY_SVOD_PURCHASE_FAILED),

    verifyUnsubSvod: createAction(types.VERIFY_SVOD_UNSUB),
    verifyUnsubSvodSuccess: createAction(types.VERIFY_SVOD_UNSUB_SUCCESS),
    verifyUnsubSvodFailed: createAction(types.VERIFY_SVOD_UNSUB_FAILED),

    clearPurchase: createAction(types.CLEAR_PURCHASE),
    clearAllSvodContent: createAction(types.CLEAR_ALL_SVOD_CONTENT),

    getBundledProducts: createAction(types.GET_BUNDLED_PRODUCTS),
    getBundledProductsSuccess: createAction(types.GET_BUNDLED_PRODUCTS_SUCCESS),
    getBundledProductsFailed: createAction(types.GET_BUNDLED_PRODUCTS_FAILED),
    getPrepaidSvodPurchaseStatus : createAction(types.GET_SVOD_PRODUCT_PURCHASE_STATUS),
    getPrepaidSvodPurchaseStatusSuccess : createAction(types.GET_SVOD_PRODUCT_PURCHASE_STATUS_SUCCESS),
    getPrepaidSvodPurchaseStatusFailed : createAction(types.GET_SVOD_PRODUCT_PURCHASE_STATUS_FAILED),
    getPrepaidSvodPurchaseStatusLoading : createAction(types.GET_SVOD_PRODUCT_PURCHASE_STATUS_LOADING),

    getSvodPurchaseOptions: createAction(types.GET_SVOD_PURCHASE_OPTIONS),
    getSvodPurchaseOptionsSuccess: createAction(types.GET_SVOD_PURCHASE_OPTIONS_SUCCESS),
    getSvodPurchaseOptionsFailed: createAction(types.GET_SVOD_PURCHASE_OPTIONS_FAILED),
    

    getReferenceNumber: createAction(types.GET_REFERENCE_NUMBER),
    getReferenceNumberSuccess: createAction(types.GET_REFERENCE_NUMBER_SUCCESS),
    getReferenceNumberFailed: createAction(types.GET_REFERENCE_NUMBER_FAILED)
    
} 