import { createLogic } from "redux-logic";
import actions from "./actions";
import types from "./types";
import endPoints from "./../../../util/EndPoints";
import API from "./../../../util/HTTPClient";
import _ from "lodash";
import featuredActions from '../../landingPage/ducks/actions';
import { getLoggedInStatus } from '../../../util/ArrayValidator';

export default [
  createLogic({
    type: types.GET_BASE_PRODUCT,
    latest: true,
    debounce: 1000,

    process({ MockHTTPClient, getState, action }, dispatch, done) {
      // debugger;
      let HTTPClient
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }
      HTTPClient.Post(`${endPoints.BASE_PACKAGE_PRODUCT}`, action.payload)
        .then(resp => resp.data)
        .then(data => {
          dispatch(actions.getBaseProductsSuccess(data));
        })
        .catch(err => {
          
            dispatch(
              actions.getBaseProductsFailed(
                "Something went wrong, please contact your service provider"
              )
            );
          
        })
        .then(() => done()); // call done when finished dispatching
    }
  }),

  createLogic({
    type: types.GET_ALL_PRODUCTS,
    latest: true,
    debounce: 1000,

    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }
      HTTPClient.Post(`${endPoints.PRODUCT_ID_LIST_REGISTER}`, action.payload)
        .then(resp => resp.data)
        .then(data => {
          let allPackages = [];
          let prodList = [];
          let categoryList = [];
          // let product = [];
          let dataBundle = []
          Array.isArray(data) &&
            data.map((v, index) => {
              let basePackage = {};
              let products = {};
              let dbun = {};
              let product = [];
              basePackage.id = v.id;
              basePackage.productTypeId = v.productTypeId;
              basePackage.name = v.name;
              basePackage.price =
                v.purchaseOption && v.purchaseOption.defaultPrice;
              basePackage.imageURL = v.imageURL;
              basePackage.description = v.description;
              basePackage.numberOfScreens = v.numberOfScreens;
              Array.isArray(v.productList) &&
                v.productList.map((prods, index) => {
                  if (prods.productTypeName === "Package") {
                    //   debugger
                    // /prod.productList && Array.isArray(prod.productList) && prod.productList.map((prods, x) => {
                    prodList.push(prods.productTypeName);
                    products.description = prods.description;
                    products.id = prods.id;
                    products.imageURL = prods.imageURL;
                    products.name = prods.name;
                    products.price = prods.price;
                    products.numberOfScreens = prods.numberOfScreens;
                    products.productTypeName = prods.productTypeName;
                    products.productTypeId = prods.productTypeId;
                    product.push(products);

                    Array.isArray(prods.categories) &&
                      prods.categories.map((h, i) => {
                        categoryList.push(h.category);
                        return null
                      });
                  }
                  if (prods.productTypeName === "Data Bundle") {
                    dbun.dataId = prods.id
                    dbun.name = v.name
                  }
                  return null
                });
              dataBundle.push(dbun)
              basePackage.dataBundle = dataBundle
              basePackage.categories = _.uniq(categoryList);
              basePackage.tabs = _.uniq(prodList);
              basePackage.product = _.uniq(product);

              allPackages.push(basePackage);
              return null
            });
          dispatch(actions.getAllProductsSuccess(allPackages));
        })
        .catch(err => {
          // Log error
          dispatch(
            actions.getAllProductsFailed(
              "Something went wrong, please contact your service provider"
            )
          );
        })
        .then(() => done()); // call done when finished dispatching
    }
  }),
  createLogic({
    type: types.GET_CHANNELS,
    latest: true,
    debounce: 1000,
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }
      if (getLoggedInStatus() === false) {

        HTTPClient.Get(`${endPoints.GET_PRODUCT}${action.payload}`)
          .then(res => res.data)
          .then(data => dispatch(actions.getChannelsSuccess(data)))
          .catch(err => dispatch(actions.getChannelsFailed(err)))
          .then(() => done()); // call done when finished dispatching
      } else {
        HTTPClient.Get(
          `${endPoints.PURCHASED_PRODUCTS}${action.payload.productId}?conid=${action.payload.connectionId}`
        )
          .then(res => res.data)
          .then(data => {
            let purchaseOptionGroupId = data.purchaseOption && data.purchaseOption.purchaseOptionGroupId
            dispatch(actions.getChannelsSuccess(data))
            dispatch(actions.getPurchaseTable(purchaseOptionGroupId))
          })
          .catch(err => dispatch(actions.getChannelsFailed(err)))
          .then(() => done()); // call done when finished dispatching
      }
    }
  }),

  createLogic({
    type: types.GET_ALL_CHANNELS,
    latest: true,
    debounce: 1000,

    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }

      if (getLoggedInStatus() === false) {
        HTTPClient.Get(`${endPoints.GET_ALL_CHANNEL}?typeid=${action.payload}`)
          .then(resp => resp.data)
          .then(data => {
            let all = {};
            let allPackages = [];
            let prodList = [];
            let categoryList = [];
            let product = [];
            Array.isArray(data) &&
              Array.isArray(data) &&
              data.map((v, index) => {
                let basePackage = {};
                basePackage.id = v.id;
                basePackage.name = v.name;
                basePackage.price = v.purchaseOption && v.purchaseOption.defaultPrice;
                basePackage.imageURL = v.imageURL;
                basePackage.description = v.description;
                basePackage.feature = v.feature;
                basePackage.productTypeName = v.productTypeName;
                basePackage.categories = v.categories;
                Array.isArray(v.categories) &&
                  v.categories.map((h, i) => {
                    categoryList.push(h.category);
                    return null
                  });
                // basePackage.products=v.productList
                //  / basePackage.categories = _.uniq(categoryList)
                // })
                basePackage.tabs = _.uniq(prodList);
                // basePackage.categories = v.categories
                basePackage.product = _.uniq(product);

                allPackages.push(basePackage);
                return null
              });
            let sorted = categoryList.sort()
            all.channels = allPackages;
            all.categories = _.uniq(sorted);

            dispatch(actions.getAllChannelsSuccess(all));
          })
          .catch(err => {
            // Log error
            dispatch(
              actions.getAllChannelsFailed(
                "Something went wrong, please contact your service provider"
              )
            );
          })
          .then(() => done()); // call done when finished dispatching
      } else {
        HTTPClient.Get(
          `${endPoints.GET_ALL_CHANNEL_PURCHASE}?conid=${action.payload.connectionId}&typeid=${action.payload.productTypeId}`
        )
          .then(resp => resp.data)
          .then(data => {
            let all = {};
            let allPackages = [];
            let prodList = [];
            let categoryList = [];
            let product = [];
            Array.isArray(data) &&
              Array.isArray(data) &&
              data.map((v, index) => {
                let basePackage = {};
                basePackage.id = v.id;
                basePackage.name = v.name;
                basePackage.price = v.purchaseOption && v.purchaseOption.defaultPrice;
                basePackage.imageURL = v.imageURL;
                basePackage.isBundled = v.isBundled
                basePackage.isPurchased = v.isPurchased
                basePackage.description = v.description;
                basePackage.feature = v.feature;
                basePackage.productTypeName = v.productTypeName;
                basePackage.categories = v.categories;
                Array.isArray(v.categories) &&
                  v.categories.map((h, i) => {
                    categoryList.push(h.category);
                    return null
                  });
                // basePackage.products=v.productList
                //  / basePackage.categories = _.uniq(categoryList)
                // })
                basePackage.tabs = _.uniq(prodList);
                // basePackage.categories = v.categories
                basePackage.product = _.uniq(product);

                allPackages.push(basePackage);
                return null
              });
            let sorted = categoryList.sort()
            all.channels = allPackages;
            all.categories = _.uniq(sorted);

            dispatch(actions.getAllChannelsSuccess(all));
          })
          .catch(err => {
            // Log error
            dispatch(
              actions.getAllChannelsFailed(
                "Something went wrong, please contact your service provider"
              )
            );
          })
          .then(() => done()); // call done when finished dispatching
      }
    }
  }),
  createLogic({
    type: types.GET_ALL_CHANNEL_CATEGORIES,
    latest: true,
    debounce: 1000,
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }
      HTTPClient.Post(`${endPoints.GET_ALL_CHANNEL_CATEGORIES}`, action.payload)
        .then(res => res.data)
        .then(data => dispatch(actions.getAllChannelCategoriesSuccess(data)))
        .catch(err => dispatch(actions.getAllChannelCategoriesFailed(err)))
        .then(() => done()); // call done when finished dispatching
    }
  }),
  createLogic({
    type: types.GET_PRODUCTS_TYPES,
    latest: true,
    debounce: 1000,
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }
      // debugger
      HTTPClient.Get(
        `${endPoints.GET_PRODUCTS_TYPES}?productId=${action.payload}`
      )
        .then(res => res.data)
        .then(data => dispatch(actions.getProductTypesSuccess(data)))
        .catch(err => dispatch(actions.getProductTypesFailed(err)))
        .then(() => done()); // call done when finished dispatching
    }
  }),

  createLogic({
    type: types.GET_ONDEMAND_SUB_TYPES,
    latest: true,
    debounce: 1000,
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }
      HTTPClient.Get(
        `${endPoints.GET_PARENT_TYPES}/${action.payload}${endPoints.GET_SUB_TYPES}`
      )
        .then(res => res.data)
        .then(data => dispatch(actions.getOnDemandSubTypesSuccess(data)))
        .catch(err => dispatch(actions.getOnDemandSubTypesFailed(err)))
        .then(() => done()); // call done when finished dispatching
    }
  }),

  createLogic({
    type: types.GET_ALL_LANGUAGES,
    latest: true,
    debounce: 1000,
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }
      HTTPClient.Get(`${endPoints.GET_LANGUAGES}`)
        .then(res => res.data)
        .then(data => dispatch(actions.getAllLanguagesSuccess(data)))
        .catch(err => dispatch(actions.getAllLanguagesFailed(err)))
        .then(() => done()); // call done when finished dispatching
    }
  }),

  createLogic({
    type: types.GET_TRENDING_VOD, // only apply this logic to this type
    latest: true, // only take latest
    debounce: 1000, // Wait 1 s before triggering another call

    // your code here, hook into one or more of these execution
    // phases: validate, transform, and/or process
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }
      if (getLoggedInStatus() === false) {
        HTTPClient.Post(endPoints.GET_SUB_TRENDING_VODS, action.payload)
          .then(res => res.data)
          .then(data => dispatch(actions.getTrendingVodSuccess(data)))
          .catch(err => dispatch(actions.getTrendingVodFailed(err)))
          .then(() => done()); // call done when finished dispatching
      } else {
        HTTPClient.Get(`${endPoints.GET_SUB_TRENDING_BILLING}?conid=${action.payload.conid}&subtypeid=${action.payload.subTypeId}&tagId=${action.payload.tagId}`)
          .then(res => res.data)
          .then(data => dispatch(actions.getTrendingVodSuccess(data)))
          .catch(err => dispatch(actions.getTrendingVodFailed(err)))
          .then(() => done()); // call done when finished dispatching
      }
    }
  }),

  createLogic({
    type: types.GET_ALL_VODS, // only apply this logic to this type
    latest: true, // only take latest
    debounce: 1000, // Wait 1 s before triggering another call

    // your code here, hook into one or more of these execution
    // phases: validate, transform, and/or process
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }
      if (getLoggedInStatus() === false) {
        // debugger
        HTTPClient.Get(`${endPoints.GET_ALL_CHANNEL}?typeid=${action.payload}`)
          .then(res => res.data)
          .then(data => dispatch(actions.getAllVodSuccess(data)))
          .catch(err => dispatch(actions.getAllVodFailed(err)))
          .then(() => done()); // call done when finished dispatching
      } else {
        HTTPClient.Get(`${endPoints.GET_SUB_VODS_BILLING}?conid=${action.payload.conid}&typeid=${action.payload.subTypeId}`)
          .then(res => res.data)
          .then(data => dispatch(actions.getAllVodSuccess(data)))
          .catch(err => dispatch(actions.getAllVodFailed(err)))
          .then(() => done()); // call done when finished dispatching
      }
    }
  }),
  createLogic({
    type: types.GET_ALL_BASE_PRODUCT_TYPES, // only apply this logic to this type
    latest: true, // only take latest
    debounce: 1000, // Wait 1 s before triggering another call

    // your code here, hook into one or more of these execution
    // phases: validate, transform, and/or process
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }

      HTTPClient.Get(endPoints.PRODUCT_TYPES)
        .then(res => res.data)
        .then(data => dispatch(actions.getAllBaseProductsTypesSuccess(data)))
        .catch(err => dispatch(actions.getAllBaseProductsTypesFailed(err)))
        .then(() => done()); // call done when finished dispatching
    }
  }),

  // createLogic({
  //   type: types.GET_ALL_PACKAGE_TYPES, // only apply this logic to this type
  //   latest: true, // only take latest
  //   debounce: 1000, // Wait 1 s before triggering another call

  //   // your code here, hook into one or more of these execution
  //   // phases: validate, transform, and/or process
  //   process({ MockHTTPClient, getState, action }, dispatch, done) {
  //     // debugger;
  //     let HTTPClient
  //     if (MockHTTPClient) {
  //       HTTPClient = MockHTTPClient;
  //     } else {
  //       HTTPClient = API;
  //     }

  //     HTTPClient.Get(endPoints.PACKAGE_TYPES)
  //       .then(res => res.data)
  //       .then(data => dispatch(actions.getAllPackageTypesSuccess(data)))
  //       .catch(err => dispatch(actions.getAllPackageTypesFailed(err)))
  //       .then(() => done()); // call done when finished dispatching
  //   }
  // }),
  createLogic({
    type: types.GET_ALL_CHANNEL_BUNDLE_CATEGORIES,
    latest: true,
    debounce: 1000,
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }
      HTTPClient.Post(`${endPoints.GET_ALL_CHANNEL_CATEGORIES}`, action.payload)
        .then(res => res.data)
        .then(data =>
          dispatch(actions.getAllChannelBundleCategoriesSuccess(data))
        )
        .catch(err =>
          dispatch(actions.getAllChannelBundleCategoriesFailed(err))
        )
        .then(() => done()); // call done when finished dispatching
    }
  }),
  createLogic({
    type: types.GET_SELECTED_PRODUCT, // only apply this logic to this type
    latest: true, // only take latest
    debounce: 1000, // Wait 1 s before triggering another call

    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }
      //debugger

      // dispatch(actions.getFeaturedPackageChannelSuccess(datas))
      // return

      HTTPClient.Get(
        `${endPoints.PURCHASED_PRODUCTS}${action.payload.productId}?conid=${action.payload.connectionId}`
      )
        .then(res => {
          return res.data;
        })
        .then(data => dispatch(actions.getSelectedProductSuccess(data)))
        .catch(err => dispatch(actions.getSelectedProductFailed(err)));
      // .then(() => done()); // call done when finished dispatching
    }
  }),
  createLogic({
    type: types.PRODUCT_UNSUBSCRIBE,
    latest: true,
    debounce: 1000,
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      function delay(t, v) {
        return new Promise(function (resolve) {
          setTimeout(resolve.bind(null, v), t)
        });
      }
      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }
      HTTPClient.Post(endPoints.PRODUCT_UNSUBSCRIBE, action.payload)
        .then(res => res.status === 200)
        .then(async res => {
          await delay(3000)
          return dispatch(actions.verifyUnsubChannel({ productId: action.payload.productId, connectionId: localStorage.conID, res: res }))
        })
        // .then(data => dispatch(actions.productUnSubscribeSuccess(data)))
        .catch(err => dispatch(actions.productUnSubscribeFailed(err && err.response && err.response.data && err.response.data.message && err.response.data.message) || "Something went wrong, please contact your service provider"))
        .then(() => done()); // call done when finished dispatching
      // .then(res => res.data)
      // .then(data => dispatch(actions.productUnSubscribeSuccess(data)))
      // .catch(err => dispatch(actions.productUnSubscribeFailed(err && err.response && err.response.data && err.response.data.message && err.response.data.message) || "Something went wrong, please contact your service provider"))
      // .then(() => done()); // call done when finished dispatching
    }
  }),
  createLogic({
    type: types.PRODUCT_SUBSCRIBE,
    latest: true,
    debounce: 1000,
    process({ MockHTTPClient, getState, action }, dispatch, done) {

      function delay(t, v) {
        return new Promise(function (resolve) {
          setTimeout(resolve.bind(null, v), t)
        });
      }

      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }
      // debugger
      HTTPClient.Post(endPoints.PRODUCT_SUBSCRIBE, action.payload)
        // .then(res => console.log("Product Page : service : res : ", res))
        // `${endPoints.PURCHASED_PRODUCTS}${action.payload.productId}?conid=${action.payload.connectionId}`

        .then(res => res.status === 200)
        .then(async res => {
          await delay(3000);
          return dispatch(actions.verifyPurchaseChannel({ productId: action.payload.productId, connectionId: localStorage.conID, res: res }));
        })
        // .then(res => res.data)
        // .then(data => dispatch(actions.productSubscribeSuccess(data)))
        .catch(err => dispatch(actions.productSubscribeFailed(err && err.response && err.response.data && err.response.data.message && err.response.data.message) || "Something went wrong, please contact your service provider"))
        .then(() => done()); // call done when finished dispatching
    }
  }),



  createLogic({
    type: types.GET_USER_PRODUCT_BY_TYPE,
    latest: true,
    debounce: 1000,

    process({
      MockHTTPClient,
      getState,
      action
    }, dispatch, done) {
      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }
      // console.log("Running get prerequisites Service"); 
      HTTPClient.Get(endPoints.GET_USER_PRODUCT_BY_TYPE + `?conid=${action.payload.conID}&typeid=${action.payload.typeID}`)
        // .then(resp => console.log("productPage : service : resp : ", resp))
        .then(resp => resp.data)
        .then(data => {
          // debugger;
          return data;
        })
        .then(userProduct => {
          // debugger
          dispatch(actions.getUserProductByTypeSuccess(userProduct))

        }
        )
        .catch(err => {
          var errorMessage = "Failed to get prerequisites";
          if (err && err.code === "ECONNABORTED") {
            errorMessage = "Please check your internet connection.";
          }
          dispatch(
            actions.getUserProductByTypeFailed({
              title: "Error!",
              message: errorMessage,
              respErr: err.message
            })
          );
        })
        .then(() => done());
    }
  }),
  createLogic({
    type: types.GET_PURCHASE_TABLE,
    latest: true,
    debounce: 1000,

    process({
      MockHTTPClient,
      getState,
      action
    }, dispatch, done) {
      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }
      // console.log("Running get prerequisites Service");
      HTTPClient.Get(endPoints.GET_PURCHASE_TABLE + action.payload)
        // .then(resp => console.log("productPage : service : resp : ", resp))
        .then(resp => resp.data)
        .then(data => {
          return data;
        })
        .then(purchaseTable => {
          dispatch(actions.getPurchaseTableSuccess(purchaseTable))
        }
        )
        .catch(err => {
          var errorMessage = "Failed to get prerequisites";
          if (err && err.code === "ECONNABORTED") {
            errorMessage = "Please check your internet connection.";
          }
          dispatch(
            actions.getPurchaseTableFailed({
              title: "Error!",
              message: errorMessage,
              respErr: err.message
            })
          );
        })
        .then(() => done());
    }
  }),
  createLogic({
    type: types.GET_VOD_ITEM,
    latest: true,
    debounce: 1000,
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }
      HTTPClient.Get(`${endPoints.GET_PRODUCT}${action.payload}`)
        .then(res => res.data)
        .then(data => dispatch(actions.getVodItemsSuccess(data)))
        .catch(err => dispatch(actions.getVodItemsFailed(err)))
        .then(() => done()); // call d
    }
  }),
  createLogic({
    type: types.VERIFY_CHANNEL_PURCHASE,
    latest: true,
    debounce: 1000,
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }
      // if (localStorage.jwt == undefined) {

      //   HTTPClient.Get(`${endPoints.GET_PRODUCT}${action.payload}`)
      //     .then(res => res.data)
      //     .then(data => dispatch(actions.getChannelsSuccess(data)))
      //     .catch(err => dispatch(actions.getChannelsFailed(err)))
      //     .then(() => done()); // call done when finished dispatching
      // } else {

      HTTPClient.Get(
        `${endPoints.PURCHASED_PRODUCTS}${action.payload.productId}?conid=${action.payload.connectionId}`
      )
        // .then(res => console.log("Product : Service : PURCHASED_PRODUCTS : res", res))
        .then(res => res.data)
        // .then(data => data.isPurchased)
        .then(data => {
          // let purchaseOptionGroupId = data.purchaseOption && data.purchaseOption.purchaseOptionGroupId
          // dispatch(actions.getChannelsSuccess(data))
          // dispatch(actions.getPurchaseTable(purchaseOptionGroupId))
          var type = null
          switch (data.productTypeTypeName) {
            case "vod":
              type = "Purchase"
              break;
            default:
              type = "Subscription"

          }
          dispatch(actions.productSubscribeSuccess({ data: data, message: data.isPurchased ? `${type} Successfull ` : `${type} Failed` }))
          dispatch(featuredActions.productPurchaseContent(data))
        })
        .catch(err => dispatch(actions.productSubscribeFailed(err && err.response && err.response.data && err.response.data.message && err.response.data.message) || "Something went wrong, please contact your service provider"))
        .then(() => done()); // call done when finished dispatching
      // }
    }
  }),

  createLogic({
    type: types.VERIFY_CHANNEL_UNSUB,
    latest: true,
    debounce: 1000,
    process({ MockHTTPClient, getState, action }, dispatch, done) {
      let HTTPClient;
      if (MockHTTPClient) {
        HTTPClient = MockHTTPClient;
      } else {
        HTTPClient = API;
      }
      // if (localStorage.jwt == undefined) {

      //   HTTPClient.Get(`${endPoints.GET_PRODUCT}${action.payload}`)
      //     .then(res => res.data)
      //     .then(data => dispatch(actions.getChannelsSuccess(data)))
      //     .catch(err => dispatch(actions.getChannelsFailed(err)))
      //     .then(() => done()); // call done when finished dispatching
      // } else {

      HTTPClient.Get(
        `${endPoints.PURCHASED_PRODUCTS}${action.payload.productId}?conid=${action.payload.connectionId}`
      )
        // .then(res => console.log("Product : Service : PURCHASED_PRODUCTS : res", res))
        .then(res => res.data)
        // .then(data => data.isPurchased)
        .then(data => {
          // let purchaseOptionGroupId = data.purchaseOption && data.purchaseOption.purchaseOptionGroupId
          // dispatch(actions.getChannelsSuccess(data))
          // dispatch(actions.getPurchaseTable(purchaseOptionGroupId))
          dispatch(actions.productUnSubscribeSuccess({ data: data, message: !data.isPurchased ? "Unsubscription Successfull " : "Unsubscription Failed. Please try again later." }))
          dispatch(featuredActions.productPurchaseContent(data));
        })
        .catch(err => dispatch(actions.productUnSubscribeFailed(err && err.response && err.response.data && err.response.data.message && err.response.data.message) || "Something went wrong, please contact your service provider"))
        .then(() => done()); // call done when finished dispatching
      // }
    }
  })
];


    //  // .then(data => dispatch(actions.productSubscribeSuccess(data)))
    //  .catch(err => dispatch(actions.productSubscribeFailed(err && err.response && err.response.data && err.response.data.message && err.response.data.message) || "Something went wrong, please contact your service provider"))
    //  .then(() => done()); // call done when finished dispatching