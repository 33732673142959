import React, { Component } from "react";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import { Link, withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { CustomModal } from './../../components/controls/CustomModal'
import { productSelectors, productActions } from '../../modules/landingPage/ducks';
import { profileActions } from './ducks'
import { allProductsActions } from '../../modules/AllProducts/ducks'
import history from './../../_helpers/history';
import Spinner from "../../components/Spinner";
import { bindActionCreators } from "redux"
import './Profile.scss';
import { validArray, getNestedValue, getLoggedInStatus, getConnectionId, getServiceType, getIndex, taxCalculation } from '../../util/ArrayValidator';
import { CustomTabHeader } from '../../components/customTabs/CustomTabHeader'
import productTypeIDs from '../../modules/AllProducts/ducks/productTypeIDs'
import { MyDevice } from "../../components/myContent/MyDevice";
import { MyProfiles } from "../../components/myContent/MyProfiles";
import MyContent from "../../components/myContent/MyContent";
import { MOBITEL_SERVICE_TYPE, SingleProductPage, SUBSCRIPTIONAL } from '../../util/Constants';
import AccountSuspended from '../../components/SuspendedState/AccountSuspended'
import NotifyModal from '../../components/controls/NotifyModal'
import AccountUnsubscribed from '../../components/SuspendedState/AccountUnsubscribed'
import { MyPaymentsTable } from "../../components/MyPayments/MyPaymentsTable"
import { TRANSACTIONAL, PREPAID_SERVICE_TYPE, IS_SANDBOX } from "../../util/Constants"
import { DummyProfile } from '../../components/DummyComponent/DummyComponent'
import SuspendedProducts from '../SuspendedProducts/SuspendedProducts';
import { CustomButton } from '../../components/controls/CustomButton';
class Profile extends Component {

    state = {
        showModal: false,
        unSubModal: false,
        account: null,
        connections: null,
        showAccordian: false,
        unSubModalSuccess: false,
        selectedTabIndex: 0,
        selectedProfile: undefined,
        showRemoveProfileModal: false,
        showRemoveProfileModalFailed: false,
        selectedIndex: "0",
        removeDeviceSuceess: false,
        removeProfileSuceess: false
    }

    componentDidMount() {
        this.initialLoading()

    }

    componentDidUpdate() {
        if ((getLoggedInStatus()) && (!this.props.userDetailsLoading) && (this.props.connectionDetailsLoading)) {
            this.props.productActions.getConnectionDetails({ connectionId: this.props.userDetails && this.props.userDetails.accountId })
        }
        if (!this.state.reactivate && !this.state.isProvisioningData && !this.props.featuredPackages.packagePurchaseStatus && !this.props.featuredPackages.packagePurchaseStatusLoading && localStorage.connectionStatus !== "suspended" && localStorage.connectionStatus !== "pending_terminated") {
            this.initialLoading()
        }
        if (this.props.reactivateProductDetails && this.state.showPayhere) {
            this.payhere()
        }
    }
    componentWillUnmount() {
        this.props.productActions.reset();
    }
    initialLoading = () => {
        if (this.state.reactivate) {
            this.props.productActions.getUserDetails()
            this.props.productActions.getConnectionContent(getConnectionId());
            this.props.productActions.getConnectionDetails({ connectionId: getConnectionId() })
        }
        if (!this.props.featuredPackages.packagePurchaseStatus && !this.props.featuredPackages.packagePurchaseStatusLoading) {
            this.setState({ isProvisioningData: true })
        }
        let conid = getConnectionId()
        if (!!conid) {
            let isBroadband = localStorage.getItem('isBroadBand')
            this.props.productActions.getAllowedSecondaryPackagesLoading()
            this.props.productActions.getConnectionContent(getConnectionId());
            this.props.profileActions.getDevices(conid)
            this.props.productActions.getAllowedSecondaryPackages({
                connectionId: conid,
                typeId: "5d22eda922224e2be4ad4047"
            })
            isBroadband === "true" && this.props.productActions.getPurchasedPrimaryDataBundle({
                connectionId: conid,
                typeId: "5d444c40ee6797225cdead4b"
            })
            this.props.allProductsActions.getAllProducts({
                conid: conid,
                subTypeId: [productTypeIDs.CHANNEL_ID, productTypeIDs.CHANNEL_BUNDLE_ID]
            });
            this.props.profileActions.getMyVOD({
                conid: conid,
                subTypeId: productTypeIDs.VOD_ID
            });
            this.props.profileActions.getMySVOD({
                conid: conid,
                subTypeId: productTypeIDs.SVOD_ID
            });
            this.props.profileActions.getMyScreen({
                conid: conid,
                subTypeId: productTypeIDs.DEVICE_ID
            })
            this.props.profileActions.getMyPromotionalPackage();

            if (getServiceType()) {
                this.props.profileActions.getMyPurchases(conid)
            }
        }
    }

    onClose = () => {
        this.setState({ showModal: false, unSubModalSuccess: false, unSubModal: false, showRemoveProfileModal: false, showRemoveProfileModalFailed: false, removeDeviceSuceess: false, removeProfileSuceess: false })
    }
    onCloseSuccess = () => {
        this.setState({ unSubModalSuccess: false })
        // last acc UnSub -> logout -> navigate to login
        if (this.props.connectionDetails && Array.isArray(this.props.connectionDetails) && this.props.connectionDetails.length === 0) {
            this.props.productActions.logout();
            localStorage.clear();
        } else {
            // this.props.productActions.getUserDetails();
            this.props.productActions.getConnectionContent(localStorage.conID)
        }
        this.props.productActions.userUnsubClear();
        this.props.productActions.getAllowedSecondaryPackages({
            connectionId: localStorage.conID,
            typeId: "5d22eda922224e2be4ad4047"
        })
        this.initialLoading()
    }
    onClick = () => {
        this.setState({
            showModal: false,
            unSubModalSuccess: true,
        })
        this.props.productActions.unsubConnection(localStorage.conID)
    }

    handleSubmit = (acc) => {
        this.setState({ showModal: true, unSubModalSuccess: false, unSubModal: true })
    }
    onSelect = (e) => {
        if (e === undefined) {
            this.state["selectedIndex"] = "0"
            // this.setState({
            //     selectedIndex: "0"
            // })
        } else {
            this.setState({
                selectedIndex: e
            })
        }
    }
    removeProfile = (e) => {
        this.setState({
            selectedProfile: e.target.id,
            showRemoveProfileModal: true,
        })
    }
    onClickRemoveProfile = () => {
        this.setState({
            showRemoveProfileModal: false,
            showRemoveProfileModalFailed: true,
        })
        if (this.state.selectedIndex === "2") {
            this.setState({
                removeProfileSuceess: true
            })
            this.props.productActions.removeProfile(this.state.selectedProfile)
        } else {
            this.setState({
                removeDeviceSuceess: true
            })
            this.props.profileActions.removeDevice(this.state.selectedProfile)
        }


    }
    onClickPackage = () => {
        history.push('/packages')
    }
    onClickDataBundle = () => {
        history.push('/databundles')
    }
    onClickSvod = (e) => {
        history.push(`/svod/${e.target.id}`)
    }
    onClickOndemand = (e) => {
        history.push(`${SingleProductPage}/${e.target.id}`)
    }
    onClickChannel = (e, isChannelBundle) => {
        if (isChannelBundle) {
            history.push(`/channel-bundle/${e}`)
        } else {
            history.push(`${SingleProductPage}/${e}/false`)
        }
    };
    onClickPromo = (e) => {
        history.push(`/promotions/packages/#${e.target.id}`)
    }
    getActiveStatus = (connectionContent, selectedConnection) => {
        let activeStatus, serviceType
        connectionContent && connectionContent.map((connectionData, index) => {
            if (connectionData.connectionId === parseInt(selectedConnection)) {
                if (connectionData.status === "active") {
                    activeStatus = "active";
                    serviceType = connectionData.serviceType;
                } else if (connectionData.status === "pending_terminated") {
                    activeStatus = "pending_terminated";
                    serviceType = connectionData.serviceType;
                }
                else {
                    activeStatus = "suspended";
                    serviceType = connectionData.serviceType;
                }
            }
            return { activeStatus, serviceType }
        })
        return { activeStatus, serviceType }
    }
    onClickReactivate = () => {
        const { packagePurchaseTable } = this.props
        var user = localStorage.conID
        var basepackagePurchaseType = localStorage.getItem('basepackagePurchaseType')
        let serviceType = getServiceType() === PREPAID_SERVICE_TYPE ? "7" : "6"
        let connectionTypeName = getServiceType() === PREPAID_SERVICE_TYPE ? "prepaid" : "mobitel"
        if (basepackagePurchaseType === TRANSACTIONAL) {
            let msisdn = localStorage.userTelephoneNumber
            sessionStorage.setItem('@connectionType', serviceType)
            sessionStorage.setItem("@connectionTypeName", connectionTypeName)
            sessionStorage.setItem('@connectionID', user)
            sessionStorage.setItem('msisdn', msisdn)
            localStorage.removeItem('connectionStatus')

            history.push('/viewpackage')

        } else {
            let products = getIndex(this.props.featuredPackages.secondaryAllowedProducts, 0)
            let serviceType = getServiceType()
            this.setState({ reactivate: true })
            // this.payhere()
            this.props.productActions.reactivateProduct({
                "userConnectionId": user,
                "productTypeId": products.productTypeId,
                "purchaseOptionId": packagePurchaseTable[0].id,
                "productId": products.id,
                "currency": "LKR",
                "userConnectionType": serviceType === PREPAID_SERVICE_TYPE ? "prepaid" : "mobitel",
                "userId": localStorage.userId,
                "amount": packagePurchaseTable[0].defaultPrice,
                // products.purchaseOption.defaultPrice
            })
            if (serviceType === PREPAID_SERVICE_TYPE) {
                setTimeout(
                    () => this.setState({ showPayhere: true }),
                    3000
                );
            }
            // products.purchaseOption.id
        }

    }
    payhere = (data) => {

        this.setState({ showPayhere: false })
        const { connectionDetails, reactivateProductDetails, userDetails, connectionContent } = this.props;

        let details = reactivateProductDetails
        let email = userDetails.email
        let firstName, lastName
        let payableAmount = taxCalculation(details.amount)
        if (connectionContent && connectionContent.profiles[0] && connectionContent.profiles[0].name) {
            let splitData = connectionContent && connectionContent.profiles[0] && connectionContent.profiles[0].name.split(" ");
            firstName = splitData[0];
            lastName = splitData[1];
        }
        var payment = {
            "sandbox": IS_SANDBOX,
            "merchant_id": details.merchantId,       // Replace your Merchant ID
            "return_url": details.returnUrl,
            "cancel_url": details.cancelUrl,
            "notify_url": details.notifyUrl,
            "items": details.purchaseItemName,
            "amount": payableAmount,
            "currency": "LKR",
            "first_name": firstName,
            "last_name": lastName,
            "email": email,
            "phone": connectionDetails.telephoneNumber,
            "address": "",
            "city": "",
            "country": "",
            recurrence: details.recurrence,
            duration: details.duration,
            order_id: details.referenceNumber
        };

        // if (selectedPurchaseType && selectedPurchaseType[0] && selectedPurchaseType[0].purchaseType === SUBSCRIPTIONAL) {

        // }


        window.payhere.onCompleted = (orderId) => {

            console.log("Payment completed. OrderID:" + orderId)
            this.getPurchaseSatus(details.transactionId)
            //Note: validate the payment and show success or failure page to the customer
        };

        // Called when user closes the payment without completing
        window.payhere.onDismissed = () => {
            //Note: Prompt user to pay again or show an error page
            console.log("Payment dismissed");


            this.getPurchaseSatus(details.transactionId)

        };

        // Called when error happens when initializing payment such as invalid parameters
        window.payhere.onError = (error) => {
            // Note: show an error page
            console.log("Error:" + error);
            // this.getPurchaseSatusDissmissed()
        };

        window.payhere.startPayment(payment);
    }
    getPurchaseSatus = () => {
        const { reactivateProductDetails } = this.props
        this.props.productActions.getPackagePurchaseStatus({ transactionId: reactivateProductDetails.transactionId, productId: reactivateProductDetails.purchaseItemId })

    }

    render() {
        const { showModal, unSubModalSuccess, unSubModal, selectedIndex, showRemoveProfileModal, showRemoveProfileModalFailed, removeDeviceSuceess, removeProfileSuceess } = this.state
        const { userDetails, connectionDetails, connectionContent, unsub, unsubError, unsubLoading, additionalChannels, featuredPackages, devices, removeProfileError, allVODs, allSvods, screens, removeDeviceLoading, removeDevice, removeProfileLoading, removeProfile, paymentDetails, myPromotionalPackage, paymentDetailsLoading, reactivateProductDetails, reactivateProductLoading, reactivateProductError, packagePurchaseTable } = this.props
        const deviceArray = validArray(devices)
        let userServiceType = getNestedValue(connectionContent, ['serviceType'])
        let reverseOrderProfiles = connectionContent && [...connectionContent.profiles].reverse();
        let selectedIndexText = selectedIndex === "2" ? " profile" : " device"
        let connectionStatus = this.getActiveStatus(connectionDetails, getConnectionId());
        let defaultProfile = connectionContent && connectionContent.profiles.map(function (e) { return e.defaultProfile; }).indexOf(true);
        let unsubView
        let removeDeviceModal
        let removeProfleModal
        let billingEndDate = featuredPackages && featuredPackages.secondaryAllowedProducts && featuredPackages.secondaryAllowedProducts[0].billingEndDate
        let packagePurchaseType = localStorage.getItem('basepackagePurchaseType')

        if (connectionContent) {
            if ((userServiceType !== "Prepaid" && userServiceType !== "Mobitel") && selectedIndex === "3") {
                // this.onSelect()
                this.onSelect()
            }
        }
        if (unsubLoading === true) {
            unsubView = <NotifyModal
                showModal={unsubLoading}
                modalStyle={{
                    display: unsubLoading ? "flex" : "none",
                    overflow: "overlay",
                }}
                user={true}
                // onClose={closeNotify}
                // type={getProductPurchaseStatus}
                // message={getPurchaseStatus}
                loading={unsubLoading}
            />
            // <div className="profile-spinner-container">
            //     <Spinner />
            // </div>
        } else {
            unsubView = unsub &&
                <CustomModal
                    showModal={unSubModalSuccess}
                    modalStyle={{ display: unSubModalSuccess ? 'flex' : 'none' }}
                    modalHeaderText="Unsubscription success"
                    onClose={this.onCloseSuccess}
                    buttonText="Close"
                    modalBodyText="You have un-subscribed the service successfully"
                    onClick={this.onCloseSuccess}
                />
        }
        if (removeDeviceLoading === true) {
            removeDeviceModal = <div className="profile-spinner-container">
                <Spinner />
            </div>
        } else {
            removeDeviceModal = removeDeviceSuceess && removeDevice &&
                <CustomModal
                    showModal={removeDeviceSuceess}
                    modalStyle={{ display: removeDeviceSuceess ? 'flex' : 'none' }}
                    modalHeaderText="Device remove success"
                    onClose={this.onClose}
                    buttonText="Close"
                    modalBodyText="You have removed the device successfully"
                    onClick={this.onClose}
                />
        }
        if (removeProfileLoading === true) {
            removeProfleModal = <div className="profile-spinner-container">
                <Spinner />
            </div>
        } else {
            removeProfleModal = removeProfileSuceess && removeProfile &&
                <CustomModal
                    showModal={removeProfileSuceess}
                    modalStyle={{ display: removeProfileSuceess ? 'flex' : 'none' }}
                    modalHeaderText="Device remove success"
                    onClose={this.onClose}
                    buttonText="Close"
                    modalBodyText="You have removed the device successfully"
                    onClick={this.onClose}
                />
        }
        return (
            <>
                <Container>
                    <Row>
                        <Col sm={2}>
                            <div className="information" >
                                <div className="profile-avater-container" >
                                    <img
                                        src={require("../../images/user.png")}
                                        className="profile-img-bg"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col sm={10} className="information" >
                            {(this.props.userDetailsLoading || this.props.connectionDetailsLoading) ? <DummyProfile /> :
                                <div className="profile-details-container">
                                    <div className="custom-control custom-radio profile-header-color details-content">
                                        <span>Name</span> :
                                    <span className="padding-left-5">
                                            {this.props.connectionDetails &&
                                                Array.isArray(this.props.connectionDetails) &&
                                                this.props.connectionDetails.length !== 0 &&
                                                this.props.connectionDetails[localStorage.selectedUserIndex] &&
                                                this.props.connectionDetails[localStorage.selectedUserIndex].name}
                                        </span>
                                        <br />
                                        <span>Username</span> :
                                    <span className="padding-left-5">
                                            {userDetails &&
                                                userDetails.email}
                                        </span>
                                        <br /><br />
                                        <Tabs
                                            className="profile-tabs-border-color"
                                            defaultActiveKey="profile"
                                            id="noanim-tab-example"
                                        >
                                            <Tab
                                                eventKey="profile"
                                                title="Connection Info">
                                                {connectionContent && <div className="connection-content">
                                                    <span className="connection-info">Account No : {getNestedValue(connectionContent, ['accountNumber'])}</span>
                                                    <span className="connection-info">Telephone No : {getNestedValue(connectionContent, ['telephoneNumber'])}</span>
                                                    <span className="connection-info">Mobile No : {getNestedValue(connectionContent, ['mobile'])}</span>
                                                    <span className="connection-info">Service Type : {getNestedValue(connectionContent, ['serviceType'])}</span>
                                                    <span className="connection-info">Validation Code : {getNestedValue(connectionContent, ['profiles', defaultProfile, 'authPin'])}</span>
                                                </div>}
                                                {(connectionStatus.activeStatus !== "pending_terminated" && packagePurchaseType !== TRANSACTIONAL) && <div className="row profile-unsub-btn-container" >
                                                    <div className="col-sm-12" >
                                                        <Link
                                                            onClick={() => this.handleSubmit()}
                                                            className="profile-unsub-btn"
                                                        >
                                                            Unsubscribe
                                                    </Link>
                                                    </div>
                                                </div>
                                                }
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                            }
                        </Col>
                    </Row>
                </Container>
                <Container style={{ marginTop: '20px' }}>
                    {connectionStatus.activeStatus === "suspended" ?
                        !this.props.userDetailsLoading && !this.props.connectionDetailsLoading &&
                            featuredPackages.packagePurchaseStatus && featuredPackages.packagePurchaseStatus.status === "FAILURE" && featuredPackages.packagePurchaseStatus.paymentStatus === "SUCCESS" ? <></> : <><AccountSuspended serviceType={connectionStatus.serviceType} onClickReactivate={this.onClickReactivate} error={reactivateProductError || (reactivateProductDetails && reactivateProductDetails.status === "FAILURE" && reactivateProductDetails.description) || featuredPackages.packagePurchaseStatusError} loading={reactivateProductLoading} disable={featuredPackages.secondaryAllowedProductsLoading || !Array.isArray(packagePurchaseTable)} status={(reactivateProductDetails && reactivateProductDetails.status === "SUCCESS" && reactivateProductDetails.description) || featuredPackages.packagePurchaseStatus} basepackagePurchaseType={packagePurchaseType} packageloading={featuredPackages.secondaryAllowedProductsLoading || featuredPackages.packagePurchaseStatusLoading} />
                            {packagePurchaseType === SUBSCRIPTIONAL && <><SuspendedProducts name="Package Name " products={featuredPackages.secondaryAllowedProducts} loading={featuredPackages.secondaryAllowedProductsLoading || reactivateProductLoading} packageStatus={connectionStatus.serviceType === MOBITEL_SERVICE_TYPE ? reactivateProductDetails && reactivateProductDetails.status : featuredPackages.packagePurchaseStatus && "SUCCESS"} packageloading={featuredPackages.secondaryAllowedProductsLoading} />
                                {featuredPackages.packagePurchaseStatus && <CustomButton text="CONTINUE" style={{ margin: 'auto' }} onClick={this.initialLoading} />}
                                {reactivateProductDetails && reactivateProductDetails.status === "SUCCESS" && <CustomButton text="CONTINUE" style={{ margin: 'auto' }} onClick={this.initialLoading} />}</>}
                        </> :
                        connectionStatus.activeStatus === "pending_terminated" ?
                            <AccountUnsubscribed billingEndDate={billingEndDate} /> : !connectionStatus.activeStatus ? null :
                                < CustomTabHeader tabs={tabs} onSelect={this.onSelect} className="padding-bottom-20" serviceType={userServiceType} defaultActiveKey={selectedIndex} activeKey={selectedIndex}>
                                    <Row
                                    // style={{ marginLeft: '5px' }}
                                    >
                                        {(selectedIndex === "0") &&
                                            <MyContent
                                                featuredPackages={featuredPackages && Array.isArray(featuredPackages.secondaryAllowedProducts) && featuredPackages.secondaryAllowedProducts}
                                                purchasedPrimaryDataBundle={featuredPackages && featuredPackages.purchasedPrimaryDataBundle}
                                                additionalChannels={additionalChannels}
                                                onDemandContents={allVODs} svod={allSvods} onClickPackage={this.onClickPackage} screen={screens}
                                                onClickDataBundle={this.onClickDataBundle} onClickSvod={this.onClickSvod} onClickOndemand={this.onClickOndemand} onClickChannel={this.onClickChannel}
                                                serviceType={userServiceType}
                                                onClickPromo={this.onClickPromo}
                                                myPromotionalPackage={myPromotionalPackage}
                                            />}</Row>

                                    <Row style={{ marginLeft: '5px' }}> {selectedIndex === "1" && <MyDevice deviceArray={deviceArray} onClick={this.removeProfile} />}
                                        {selectedIndex === "2" && reverseOrderProfiles && <MyProfiles connectionContent={reverseOrderProfiles} onClick={this.removeProfile} />}</Row>
                                    <Row style={{ padding: '15px' }}>{selectedIndex === "3" && (userServiceType === "Prepaid" || userServiceType === "Mobitel") && <MyPaymentsTable paymentDetails={paymentDetails && Array.isArray(paymentDetails) && paymentDetails.length !== 0 && paymentDetails} serviceType={userServiceType} loading={paymentDetailsLoading} />}
                                    </Row>

                                </CustomTabHeader>}
                </Container>
                <CustomModal
                    showModal={showModal}
                    modalStyle={{ display: showModal ? 'flex' : 'none' }}
                    modalHeaderText="Unsubscribe?"
                    onClose={this.onClose}
                    modalBodyText="Please confirm to unsubscribe?"
                    buttonText="Ok"
                    onClick={this.onClick}

                />
                {unsubError &&
                    <CustomModal
                        showModal={unSubModal}
                        modalStyle={{ display: unSubModal ? 'flex' : 'none' }}
                        modalHeaderText="Process failed"
                        onClose={this.onClose}
                        modalBodyText={unsubError}
                    />
                }
                <CustomModal
                    showModal={showRemoveProfileModal}
                    modalStyle={{ display: showRemoveProfileModal ? 'flex' : 'none' }}
                    modalHeaderText={`Remove${selectedIndexText} ?`}
                    onClose={this.onClose}
                    modalBodyText={`Please confirm to remove the ${selectedIndexText}`}
                    buttonText="Ok"
                    onClick={this.onClickRemoveProfile}

                // onClick={this.onClick}

                />
                {removeProfileError &&
                    <CustomModal
                        showModal={showRemoveProfileModalFailed}
                        modalStyle={{ display: showRemoveProfileModalFailed ? 'flex' : 'none' }}
                        modalHeaderText="Process failed"
                        onClose={this.onClose}
                        modalBodyText={removeProfileError}
                    />
                }

                {
                    unsubView
                }
                {removeDeviceModal}
                {removeProfleModal}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...productSelectors.getDetails(state),
        featuredPackages: productSelectors.getFeaturedPackages(state),
        allUserDevices: state.AllProducts.allUserDevices,
        devices: state.Profile.devices.devices,
        removeProfileError: state.Products.removeProfileError,
        removeProfile: state.Products.removeProfile,
        removeProfileLoading: state.Products.removeProfileLoading,
        additionalChannels: state.AllProducts.allUserChannels,
        allVODs: state.Profile.myVODs,
        allSvods: state.Profile.mySVODs,
        screens: state.Profile.myScreen,
        removeDeviceLoading: state.Profile.removeDevice.removeDeviceLoading,
        removeDevice: state.Profile.removeDevice.removeDevice,
        paymentDetails: state.Profile.paymentDetails.paymentDetails,
        paymentDetailsLoading: state.Profile.paymentDetails.loading,
        myPromotionalPackage: state.Profile.myPromotionalPackage.myPromotionalPackage,
        ...productSelectors.getReactivationDetails(state)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        profileActions: bindActionCreators(profileActions, dispatch),
        productActions: bindActionCreators(productActions, dispatch),
        allProductsActions: bindActionCreators(allProductsActions, dispatch)
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Profile));
const tabs = [
    { "id": "1", "name": "MY CONTENT" },
    { "id": "2", "name": "MY DEVICES" },
    { "id": "3", "name": "MY PROFILES" },
    { "id": "4", "name": "MY PAYMENTS" },
]