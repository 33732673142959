import React from 'react'
import { Row } from 'react-bootstrap';
import { isDiscounted } from '../../util/ArrayValidator';
import SVODCard from "../../modules/svodsPage/SVODCard"
export const CustomSvodTab = ({
    viewPackage,
    unionArray,
    selectedCategory,
    product,
    selectedCategoryName,
    purchasableProducts,
    onClick,
    loggedIn,
    beforeLogin
}) => {
    return <div style={{ marginTop: '15px' }}>
        <div className={viewPackage && "terms-contents scrollbar-info"}>
            {product && product.map((item, index) => {
                if ((selectedCategoryName === item.productTypeName)) {

                    return <> <p>{item.name}</p><Row style={{ marginLeft: " 15px", marginRight: "15px" }}><SVODCard
                        key={index}
                        imageURL={item.imageURL}
                        id={item.id}
                        onClick={onClick}
                        hasDiscount={isDiscounted(item)}
                        name={item.name}
                        showPrice={false}
                        isBundled={item.isBundled}
                    /></Row>
                    </>

                } else {
                    return null
                }

            })
            }
        </div >
    </div >
}