import React from 'react';
import { Spinner } from 'react-bootstrap';

function OverlaySpinner() {
    return (
        <div className="loading"> <Spinner animation="grow" role="status" size="xl" className="spinner-color spinner-size"/> </div> 
    )
}

export default OverlaySpinner
