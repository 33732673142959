import React from 'react'
export const FeaturedPackage = ({

    basePackage,
    header,
    value,
    onClick,
    price,
    id,
    imagePath,
    isLoggedIn,
    selectedImage,
    className,
    active,
    purchaseType
}) => {
    return <>
        <style type="text/css">
            {`
            .outside{
              position: absolute;
              margin-bottom: -20px;
            }
                
            .inside{
              
                position:absolute;
                height: 0;
                bottom:0;
                width:100%;
                display:flex;
                transition: .5s ease;
                background-color: rgba(29, 29, 29, 0.9);
                overflow:hidden;
                flex-direction:column;
                justify-content:center;
                text-align:center;
                align-self:center;
                z-index:100;
               
            }
            .outside:hover .inside{
                display:flex;
                height: 100%;
                flex-direction:column;
                justify-content:center;
                text-align:center;
                align-self:center;
                
            }

            .inside {
                color:#ffff;
                z-index:200;
            }
            .selected{
                border: 3px solid #4fa1d9;
            }
            .outside:hover .selected{
                border: 3px solid #4fa1d9;
            }
            
            .not-selected{
                border: 3px solid transparent;
            }
        `}
        </style>
        <div id={id} value={value} style={styles.containerBoxOuter}>
            <div id={id} value={value} className="outside" style={{ border: active && "3px solid #4fa1d9", borderRadius: active && '8px' }}>
                <div id={id} value={value} onClick={onClick} className="inside">
                    {/* {isLoggedIn && <i class="fas fa-check-square" style={{ alignSelf: "flex-end", position: "absolute", top: "2px", color: "#4fa1d9" }}></i>} */}
                    <h5 id={id} value={value} > {header}</h5>
                    <div className="package-time-period" id={id} value={value}>
                        <span id={id} value={value}>{purchaseType}</span><br />
                    </div>

                    <h3 id={id} value={value}> Rs. {price}</h3>
                </div>
                {isLoggedIn && <i class="fas fa-check-square" style={{ alignSelf: "flex-end", position: "absolute", right: "2px", color: "#4fa1d9" }}></i>}
                <div id={id} style={styles.containerBox}>
                    <img
                        id={id}
                        className={'selectedImage'}
                        src={`/${imagePath}`}
                        // className={`${selectedImage ? 'selectedImage' : 'not-selected'}`}
                        // src={require("./image.png")}
                        style={{ width: '100%', height: 'auto', opacity: active ? 1 : 0.5 }}
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = require("./../../images/ChannelnotFound.jpg")
                        }}
                        alt=""
                    />
                    {/* {active && <div id={id} className="package-duration">For {purchaseType}</div>} */}
                </div>

            </div>
            {/*  
            })} */}

        </div>
    </>
}
const styles = {
    containerBoxOuter: {
        display: 'grid',
        // backgroundColor: "#ffffff4d",
        borderRadius: 5,
        overflow: "hidden",
        marginBottom: 20,
        width: "185px"
    },
    containerBox: {
        display: 'grid',
        // backgroundColor: "#ffffff4d",
        borderRadius: 5,
        overflow: "hidden",
        marginBottom: 0
    }
}