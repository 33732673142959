import React from 'react'
import { CustomerSelectionCard, CustomButtonPlaceholder } from './DummyComponent'

export default function DummyCustomerSelection({ customButtonText }) {
    return (
        <div className="container card-page">
            <div className="card col-sm-8">
            <h5>Please select the relevant option.<i className="fas fa-info-circle customer-info-circle"></i></h5>
                <div  >
                    <CustomerSelectionCard />
                    
                </div>
                <div style={{marginTop: "20px"}}>
                <CustomButtonPlaceholder />
                </div>
                <p className="info-description"><i className="fas fa-info-circle description-info-circle"></i>Your subscription charges will be billed upon the option selected above</p>
            </div>
        </div>
    )
}
