import { createLogic } from "redux-logic";
import actions from "./actions";
import types from "./types";
import endPoints from "./../../../util/EndPoints";
import API from "./../../../util/HTTPClient";
import { getConnectionId, getServiceType } from '../../../util/ArrayValidator';
import { PROMOTIONAL_PACKAGE_ID, PREPAID_SERVICE_TYPE, MOBITEL_SERVICE_TYPE } from '../../../util/Constants';

const getDevices = createLogic({
    type: types.GET_DEVICES,
    latest: true,
    debounce: 1000,

    process({ MockHTTPClient, getState, action }, dispatch, done) {
        let HTTPClient
        if (MockHTTPClient) {
            HTTPClient = MockHTTPClient
        } else {
            HTTPClient = API
        }
        HTTPClient.Get(`${endPoints.GET_DEVICES}/${action.payload}/${endPoints.REMOVE_DEVICE}`)
            .then(resp => resp.data)
            .then((data) => {
                dispatch(actions.getDevicesSuccess(data))
            })
            .catch(err => {
                // Log error
                dispatch(actions.getDevicesFailed(err))
            })
            .then(() => done()); // call done when finished dispatching
    }
})
const removeDevice = createLogic({
    type: types.REMOVE_DEVICE,
    latest: true,
    debounce: 1000,

    process({ MockHTTPClient, getState, action }, dispatch, done) {
        let HTTPClient
        if (MockHTTPClient) {
            HTTPClient = MockHTTPClient
        } else {
            HTTPClient = API
        }
        let conId = getConnectionId()
        HTTPClient.Delete(`${endPoints.REMOVE_DEVICE_CONNECTION}${conId}/${endPoints.REMOVE_DEVICE}/${action.payload}`)
            .then(resp => resp.data)
            .then((data) => {
                dispatch(actions.removeDeviceSuccess(data))
            })
            .catch(err => {
                // Log error
                dispatch(actions.removeDeviceFailed(err.response.data.message || "Something went wrong, please contact your service provider"))
            })
            .then(() => done()); // call done when finished dispatching
    }
})

const getMySVODs = createLogic({
    type: types.MY_SVOD,
    latest: true,
    debounce: 1000,
    process({ MockHTTPClient, getState, action }, dispatch, done) {
        let HTTPClient;
        if (MockHTTPClient) {
            HTTPClient = MockHTTPClient;
        } else {
            let endPoint
            if (localStorage.connectionStatus === "suspended") {
                endPoint = endPoints.GET_REACTIVE_PRODUCTS
            } else {
                endPoint = endPoints.GET_SUB_VODS_BILLING
            }

            HTTPClient = API;
            if (localStorage.connectionStatus !== "suspended") {
                HTTPClient.Get(`${endPoint}?conid=${action.payload.conid}&typeid=${action.payload.subTypeId}`)
                    .then(res => res.data)
                    .then(data => dispatch(actions.getMySVODSuccess(data)))
                    .catch(err => dispatch(actions.getMySVODFail(err)))
                    .then(() => done()); // call done when finished dispatching
            }
        }
    }
})

const getMyVODs = createLogic({
    type: types.MY_VOD,
    latest: true,
    debounce: 1000,
    process({ MockHTTPClient, getState, action }, dispatch, done) {
        let HTTPClient;
        if (MockHTTPClient) {
            HTTPClient = MockHTTPClient;
        } else {
            let endPoint
            if (localStorage.connectionStatus === "suspended") {
                endPoint = endPoints.GET_REACTIVE_PRODUCTS
            } else {
                endPoint = endPoints.GET_SUB_VODS_BILLING
            }
            HTTPClient = API;
            if (localStorage.connectionStatus !== "suspended") {
                HTTPClient.Get(`${endPoint}?conid=${action.payload.conid}&typeid=${action.payload.subTypeId}`)
                    .then(res => res.data)
                    .then(data => dispatch(actions.getMyVODSuccess(data)))
                    .catch(err => dispatch(actions.getMyVODFail(err)))
                    .then(() => done()); // call done when finished dispatching
            }
        }
    }
})

const getMyScreens = createLogic({
    type: types.MY_SCREEN,
    latest: true,
    debounce: 1000,
    process({ MockHTTPClient, getState, action }, dispatch, done) {
        let HTTPClient;
        if (MockHTTPClient) {
            HTTPClient = MockHTTPClient;
        } else {
            let endPoint
            if (localStorage.connectionStatus === "suspended") {
                endPoint = endPoints.GET_REACTIVE_PRODUCTS
            } else {
                endPoint = endPoints.GET_SUB_VODS_BILLING
            }
            HTTPClient = API;
            if (localStorage.connectionStatus !== "suspended") {
                HTTPClient.Get(`${endPoint}?conid=${action.payload.conid}&typeid=${action.payload.subTypeId}`)
                    .then(res => res.data)
                    .then(data => dispatch(actions.getMyScreenSuccess(data)))
                    .catch(err => dispatch(actions.getMyScreenFail(err)))
                    .then(() => done()); // call done when finished dispatching
            }
        }
    }
})

const getMyPurchases = createLogic({
    type: types.GET_PAYMENTS,
    latest: true,
    debounce: 1000,
    process({ MockHTTPClient, getState, action }, dispatch, done) {
        let HTTPClient;
        if (MockHTTPClient) {
            HTTPClient = MockHTTPClient;
        } else {
            HTTPClient = API;
            let serviceEndpoint;

            if (getServiceType() === PREPAID_SERVICE_TYPE) {
                serviceEndpoint = endPoints.GET_MY_PURCHASES
            } else if (getServiceType() === MOBITEL_SERVICE_TYPE) {
                serviceEndpoint = endPoints.GET_MOBITEL_PURCHASES
            }

            HTTPClient.Get(`${serviceEndpoint}?connectionId=${action.payload}`)
                .then(res => res.data)
                .then(data => dispatch(actions.getMyPurchasesSuccess(data)))
                .catch(err => dispatch(actions.getMyPurchasesFail(err)))
                .then(() => done()); // call done when finished dispatching
        }
    }
})

const myPromotionalPackage = createLogic({

    type: types.MY_PROMOTIONAL_PACKAGE, // only apply this logic to this type
    latest: true, // only take latest
    debounce: 1000, // Wait 1 s before triggering another call

    process({
        MockHTTPClient,
        getState,
        action
    }, dispatch, done) {
        let HTTPClient
        if (MockHTTPClient) {
            HTTPClient = MockHTTPClient
        } else {
            HTTPClient = API
        }
        let endPoint
        let conid = getConnectionId()

        // endPoint = `${endPoints.BASE_PRODUCT}?typeid=${PROMOTIONAL_PACKAGE_ID}`
        endPoint = `${endPoints.PURCHASED_PACKAGE}?conid=${conid}&packagetypeid=${PROMOTIONAL_PACKAGE_ID}`

        HTTPClient.Get(`${endPoint}`)
            .then(res => res.data)
            .then(data => {
                dispatch(actions.getMyPromotionalPackageSuccess(data))
            })
            .catch(err => dispatch(actions.getMyPromotionalPackageFailed(err)))
            .then(() => done()); // call done when finished dispatching
    }
})

export default [getDevices, removeDevice, getMySVODs, getMyVODs, getMyScreens, getMyPurchases, myPromotionalPackage]