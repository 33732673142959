import React from 'react'
import { Row } from 'react-bootstrap';
import { purchaseStatusValidation, getProductPrice, isDiscounted, discountType, getUniqueData } from '../../util/ArrayValidator';
import Card from '../../components/CardComponent/Card'
import CardOptions from '../../components/CardOptions/CardOptions';
import Features from '../../components/Features/Features';
import { PAYMENT_SUCCESS, CHANNEL_BUNDLE_ID, CHANNEL_ID, PRODUCT_STATUS_ACTIVE } from '../../util/Constants';
export const CustomTabBodyContent = ({
    viewPackage,
    unionArray,
    selectedCategory,
    product,
    selectedCategoryName,
    purchasableProducts,
    onClick,
    loggedIn,
    beforeLogin
}) => {
    return <div style={{ marginTop: '15px' }}>
        <div className={viewPackage && "terms-contents scrollbar-info"}>
            {getUniqueData(unionArray, "category").map((items, index1) => {
                if ((selectedCategory === items.category) || (selectedCategory === undefined || selectedCategory === "ALL")) {
                    return (<> <p>{items.category}</p><Row style={{ marginLeft: 0 }}>
                        {product && product.map((item, index) => {
                            if ((item.productTypeId == CHANNEL_ID || item.productTypeId == CHANNEL_BUNDLE_ID) &&  ((selectedCategoryName === item.productTypeName ||  item.productTypeId == CHANNEL_BUNDLE_ID) || (selectedCategory === undefined || selectedCategory === "ALL"))) {
                                var n = item.categories && Array.isArray(item.categories) && item.categories.length !== 0 && item.categories.some(yoy => yoy.category === items.category)
                                let isChannelBundle = item.productTypeId == CHANNEL_BUNDLE_ID
                                if (n && item.status === PRODUCT_STATUS_ACTIVE) {
                                    return <Card
                                        key={index}
                                        id={item.id}
                                        isLoggedIn={loggedIn}
                                        name={item.name}
                                        imageURL={item.imageURL}
                                        price={item && item.purchaseOption && item.purchaseOption.defaultPrice}
                                        // isPurchased={item && (purchaseTypeStatus.productPurchased || purchaseTypeStatus.productPendingRemove)}
                                        onClick={()=>onClick(item.id, isChannelBundle)}
                                        showAddNew={false}
                                        svod={item.daysRemainingToExpire}
                                        hover={true}
                                        showPrice={true}
                                        type="channel"
                                        isDummy={false}
                                        isChannelBundle={isChannelBundle}
                                    >
                                        <CardOptions isBundled={item.isBundled} isLoggedIn={loggedIn} />
                                        <Features feature={item.feature} />
                                    </Card>
                                    // <Channel id={item.id + "/" + false} hover={true} showOptions={item.feature} showAddNew={false} channelName={item.name} imageURL={item.imageURL} price={item.purchaseOption && item.purchaseOption.defaultPrice}
                                    //     onClick={onClick}
                                    //     showViewBundleButton={item.productTypeName == constants.ChannelBundleProductTypeName ? true : false} />
                                }
                            }
                            return null
                        })
                        }
                        {purchasableProducts && purchasableProducts.map((item, index) => {
                            if (selectedCategoryName === item.productTypeName || item.productTypeId == CHANNEL_BUNDLE_ID) {
                                
                                let purchaseTypeStatus = purchaseStatusValidation(item.purchaseStatus)
                                var n = item.categories && Array.isArray(item.categories) && item.categories.length !== 0 && item.categories.some(yoy => yoy.category === items.category)
                                //check whether its a channel bundle
                                let isChannelBundle = item.productTypeId == CHANNEL_BUNDLE_ID
                                //get the discounted pices if anyon package selection page
                                let actualPrice;
                                if(item.discountOptionGroup){
                                    actualPrice = getProductPrice(item)
                                }else{
                                    actualPrice = item && item.purchaseOption && item.purchaseOption.defaultPrice
                                }
                                let discount = discountType(item)
                                if (n && item.status === PRODUCT_STATUS_ACTIVE) {
                                    return <Card
                                        key={index}
                                        id={item.id}
                                        isLoggedIn={loggedIn}
                                        name={item.name}
                                        imageURL={item.imageURL}
                                        price={actualPrice}
                                        isPurchased={item && (purchaseTypeStatus.productPurchased || purchaseTypeStatus.productPendingRemove)}
                                        onClick={()=>onClick(item.id, isChannelBundle)}
                                        svod={item.daysRemainingToExpire}
                                        hover={true}
                                        showPrice={true}
                                        type="channel"
                                        isDummy={false}
                                        showPurchaseIcon={true}
                                        showAddNew={(purchaseTypeStatus.productNotPurchased && !item.isBundled) || beforeLogin}
                                        beforeLogin={beforeLogin}
                                        hasDiscount={isDiscounted(item)}
                                        discount={discount}
                                        isChannelBundle={isChannelBundle}
                                    > <CardOptions isPurchased={purchaseTypeStatus.productPurchased || purchaseTypeStatus.productPendingRemove || item.paymentStatus===PAYMENT_SUCCESS} isBundled={item.isBundled} isLoggedIn={loggedIn} beforeLogin={beforeLogin} />
                                        <Features feature={item.feature} />
                                    </Card>
                                }
                            }
                            return null
                        })
                        }
                    </Row></>)
                }
                return null
            })

            }

        </div>
    </div>
}