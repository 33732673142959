import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form';
import { CustomButton } from '../../components/controls/CustomButton'
import { InputField } from '../../components/controls/Fields'
import { passwordRecoveryActions, passwordRecoverySelectors } from "./ducks";


class ForgetPassword extends Component {
    constructor() {
        super();

        this.state = {
            userId: '',
            usernameError: undefined,
            username: undefined,
            loading: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    static getDerivedStateFromProps(nextProps, state) {

        let newProps = {}

        if (nextProps.usernameError && nextProps.usernameError !== state.usernameError) {

            newProps.usernameError = nextProps.usernameError
            newProps.loading = false

        }

        if (nextProps.usernameError === undefined) {

            newProps.usernameError = undefined

        }
        if (nextProps.username && nextProps.username !== state.username) {

            newProps.username = nextProps.username
            newProps.loading = false

        }

        if (nextProps.username === undefined) {

            newProps.username = undefined

        }

        return {
            ...newProps
        }
    }

    initialState = () => {
        this.setState({
            usernameError: undefined
        })
    }


    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    handleSubmit(values) {
        this.setState({
            loading: true,
            username: undefined,
        })
        const { userId } = values;
        this.props.getUsername(userId)
    }

    render() {
        const { loading } = this.state
        const { handleSubmit, usernameError,username } = this.props;
        return (
            <div className="container card-page forgot-password">
                <div className="card col-sm-12">
                    <form className="register-form" onSubmit={handleSubmit(this.handleSubmit)}>
                        <div className="form-group row">
                            <label class="col-sm-12 col-md-4 col-form-label" required>Email (Username)</label>
                            <div className="col-sm-12 col-md-8">
                                <Field
                                    id="userId"
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    name="userId"
                                    component={InputField}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <CustomButton id="forgetPassword" disabled={loading} loading={loading} text="Submit" />

                        </div>
                    </form>
                    {
                        username &&
                        <div className="alert alert-success" role="alert">
                            Password reset link has been sent to your email
                        </div>
                    }

                    {
                        usernameError &&
                        <div className="alert alert-danger" role="alert">
                            {usernameError}
                        </div>
                    }
                </div>
            </div>
        )
    }
}

const validate = values => {
    const errors = {}
    if (!values.userId) {
        errors.userId = 'Username is required'
    }
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.userId)) {
        errors.userId = 'Invalid email'
    }

    return errors;
}

const mapStateToProps = (state) => {
    return {
        ...passwordRecoverySelectors.getUsername(state),
        ...passwordRecoverySelectors.getUsernameError(state)

    }
};

export default reduxForm({
    form: 'profile',
    validate,
    //enableReinitialize: true,
})(connect(mapStateToProps, passwordRecoveryActions)(ForgetPassword))