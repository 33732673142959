import React from 'react'

function DaysToExpire({daysToExpire, id}) {
    //show the days to expire if the days is inbetween 3 to 30 / calculate the expire date
    if (daysToExpire !== undefined && daysToExpire <= 3) {
        return <div id={id} style={{ backgroundColor: "red", marginTop: "auto" }}>
            {daysToExpire} {daysToExpire <= 1 ? "day to expire" : "days to expire"}</div>
    } else if (3 < daysToExpire && daysToExpire <= 30) {
        return <div id={id} style={{ backgroundColor: "#4FA1D9", marginTop: "auto" }}> {daysToExpire} days to expire</div>
    } else {    
        return <div style={{ marginTop: "auto" }}></div>
    }
}

export default DaysToExpire
