import React from 'react';
import { CustomCarousel } from '../carousel/CustomCarousel'
import DummyFeaturedChannel from '../DummyComponent/DummyFeaturedChannel';
import { getNestedValue } from '../../util/ArrayValidator';
import {carouselWidth } from "../../util/Constants";

const FeaturedChannels = ({ featuredChannels, onClick, showDate, isLoggedIn, channelProduct, informative, renderCenterRightControls, renderCenterLeftControls }) => {
    const loading = getNestedValue(featuredChannels, ['loading']);
    let fixedView = <>
        <h4 className="title">Featured Channels</h4>
        <div className="tstv-option">
            <i className="fa fa-history margin-right-5" /> TSTV
        </div>
        {isLoggedIn && <div className="additional-icon-channels">
            <i className="fas fa-plus landing-page-purchase-icon"></i> A-la-carte</div>}</>
    if (loading) {
        return (
            <>
                {fixedView}
                <DummyFeaturedChannel
                    renderCenterLeftControls={renderCenterLeftControls}
                    renderCenterRightControls={renderCenterRightControls}
                    onClick={onClick}
                    isLoggedIn={isLoggedIn}
                    isDummy={true}
                />
            </>
        )
    } else if (featuredChannels.featuredChannels && featuredChannels.featuredChannels.length !== 0) {
        return (
            <div className='landing-page-carousel'>
                {fixedView}
                <div className='justify-content-space-evenly'>
                    <CustomCarousel
                        slidesToShow={(900 < window.innerWidth ? 3 : (550 < window.innerWidth ? 2 : (440 < window.innerWidth ? 2 : 1)))}
                        renderCenterLeftControls={renderCenterLeftControls}
                        renderCenterRightControls={renderCenterRightControls}
                        renderBottomCenterControls={false}
                        heightMode="last"
                        slideWidth="185px"
                        initialSlideHeight={carouselWidth}
                        allProducts={Array.isArray(featuredChannels.featuredChannels) && featuredChannels.featuredChannels}
                        informative={true}
                        channelProduct={true}
                        onClick={onClick}
                        showDate={false}
                        isLoggedIn={isLoggedIn}
                    />
                </div>
            </div>
        )
    } else {
        return null
    }
}

export default FeaturedChannels;