import React from 'react';
import { CustomCarousel } from '../carousel/CustomCarousel'
import DummyFeaturedVod from '../DummyComponent/DummyFeaturedVod';
import { carouselWidth } from "../../util/Constants";

const MySvods = ({ svod, onClick, renderCenterLeftControls, renderCenterRightControls, isLoggedIn, purchasedSvod }) => {
  if (svod.loading) {
    // if (true) {
    return (
      //  <Spinner />
      <DummyFeaturedVod
        renderCenterLeftControls={renderCenterLeftControls}
        renderCenterRightControls={renderCenterRightControls}
        onClick={onClick}
        isLoggedIn={isLoggedIn}
        isDummy={true}
      />
    )
  }
  if (purchasedSvod && Array.isArray(purchasedSvod) && purchasedSvod !== 0) {
    return <div className='justify-content-space-evenly' style={{height: "50%"}}> <CustomCarousel
      slidesToShow={(900 < window.innerWidth ? 3 : (550 < window.innerWidth ? 2 : (440 < window.innerWidth ? 2 : 1)))}
      renderCenterLeftControls={renderCenterLeftControls}
      renderCenterRightControls={renderCenterRightControls}
      renderBottomCenterControls={false}
      heightMode="last"
      slideWidth="375px"
      initialSlideHeight={carouselWidth()}
      allProducts={purchasedSvod}
      informative={true}
      svod={true}
      onClick={onClick}
      isLoggedIn={true}
      isDummy={false}
    /></div>
  }
  else{
    return null
  }
}

export default MySvods;