import React, { Component } from 'react'
import history from './../../_helpers/history'
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { registrationActions, registrationSelectors } from './ducks'
import { CustomButton } from '../../components/controls/CustomButton';
import { ErrorMessage } from "./../../components/customMessages/ErrorMessage";
import DummyCustomerSelection from './../../components/DummyComponent/DummyCustomerSelection';

class CustomerSelection extends Component {

    constructor(props) {
        super(props);

        this.state = {
            option: null
        }
        this.handleChange = this.handleChange.bind(this);

    }
    componentDidMount() {
        this.props.getTypes()
    }
    
    handleChange(field, e) {

        this.setState({
            option: e.target.value,

        })

        this.props.types.map((type) => {
            if (type.id === parseInt(e.target.value)) {
                this.setState({
                    serviceType: type.type
                })
            }
            return null
        })
        // if (e.target.value === "7") {
        //     this.props.getCountry();
        // }
    }
    onSubmit = () => {
            // localStorage.setItem('@connectionType', this.state.option)
            sessionStorage.setItem('@connectionType', this.state.option)
            sessionStorage.setItem('@connectionTypeName', this.state.serviceType)
            history.push("/account-login")
        
    }

    render() {
        const { types, typesLoading, countryError } = this.props
        const { mobitelError } = this.state
        return (
            <div className="container card-page">
                {typesLoading ? <DummyCustomerSelection /> : <div className="card col-sm-8">
                    <h5>Please select the relevant option.<i className="fas fa-info-circle customer-info-circle"></i></h5>
                    {types && Array.isArray(types) && types.map(v => {
                        if (v.type !=="slt") {
                            return null
                        }
                        else {
                            return <div key={v.id} className="custom-control custom-radio" >
                                <input type="radio" id={v.id} name="customerSelection" className="custom-control-input" value={v.id}
                                    onChange={this.handleChange.bind(this, v.id)}
                                />
                                <label className="custom-control-label" htmlFor={v.id}>{v.name}</label>
                            </div>
                        }
                    })}
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <CustomButton id="customerSelection"
                            loading={this.state.loading}
                            disabled={!this.state.option
                                // || (this.state.option === "7" && countryLoading)
                                // || countryError
                            }
                            text="Proceed" onClick={this.onSubmit} />
                    </div>
                    <div>
                        {countryError !== undefined && (
                            <ErrorMessage
                                errorStatus={countryError}
                                errorMessage={countryError}
                            />
                        )}
                        {mobitelError && (
                            <ErrorMessage
                                errorStatus={mobitelError}
                                errorMessage={"Please try again with a mobitel network connection."}
                            />
                        )}
                    </div>
                    <p className="info-description"><i className="fas fa-info-circle description-info-circle"></i>Subscription charges will be added to your monthly SLT bill.</p>
                </div>
                }
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        ...registrationSelectors.getTypes(state),
        ...registrationSelectors.submitDetails(state),
        ...registrationSelectors.getErrors(state)

    }
};

export default withRouter(connect(
    mapStateToProps,
    registrationActions
)(CustomerSelection));

