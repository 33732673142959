const product_header = "product_header/"
// Types
export default {
    GET_PRODUCT: product_header + "GET_PRODUCT",
    GET_PRODUCT_SUCCESS: product_header + "GET_PRODUCT_SUCCESS",
    GET_PRODUCT_FAILED: product_header + "GET_PRODUCT_FAILED",

    PRODUCT_SUBSCRIBE: product_header + "PRODUCT_SUBSCRIBE",
    PRODUCT_SUBSCRIBE_SUCCESS: product_header + "PRODUCT_SUBSCRIBE_SUCCESS",
    PRODUCT_SUBSCRIBE_FAILED: product_header + "PRODUCT_SUBSCRIBE_FAILED",

    PRODUCT_UNSUBSCRIBE: product_header + "PRODUCT_UNSUBSCRIBE",
    PRODUCT_UNSUBSCRIBE_SUCCESS: product_header + "PRODUCT_UNSUBSCRIBE_SUCCESS",
    PRODUCT_UNSUBSCRIBE_FAILED: product_header + "PRODUCT_UNSUBSCRIBE_FAILED",

    GET_PURCHASE_TABLE: product_header + "GET_PURCHASE_TABLE",
    GET_PURCHASE_TABLE_SUCCESS: product_header + "GET_PURCHASE_TABLE_SUCCESS",
    GET_PURCHASE_TABLE_FAILED: product_header + "GET_PURCHASE_TABLE_FAILED",

    VERIFY_PRODUCT_PURCHASE: product_header + "VERIFY_PRODUCT_PURCHASE",
    VERIFY_PRODUCT_PURCHASE_SUCCESS: product_header + "VERIFY_PRODUCT_PURCHASE_SUCCESS",
    VERIFY_PRODUCT_PURCHASE_FAILED: product_header + "VERIFY_PRODUCT_PURCHASE_FAILED",

    VERIFY_PRODUCT_UNSUB: product_header + "VERIFY_PRODUCT_UNSUB",
    VERIFY_PRODUCT_UNSUB_SUCCESS: product_header + "VERIFY_PRODUCT_UNSUB_SUCCESS",
    VERIFY_PRODUCT_UNSUB_FAILED: product_header + "VERIFY_PRODUCT_UNSUB_FAILED",

    CLEAR_PURCHASE_MESSAGE: product_header + "CLEAR_PURCHASE_MESSAGE",

    CLEAR_PRODUCT_DETAILS: product_header + "CLEAR_PRODUCT_DETAILS",

    GET_BUNDLED_PRODUCTS: product_header + "GET_BUNDLED_PRODUCTS",
    GET_BUNDLED_PRODUCTS_SUCCESS: product_header + "GET_BUNDLED_PRODUCTS_SUCCESS",
    GET_BUNDLED_PRODUCTS_FAILED: product_header + "GET_BUNDLED_PRODUCTS_FAILED",
    GET_PRODUCT_PURCHASE_STATUS : product_header + "GET_PRODUCT_PURCHASE_STATUS",
    GET_PRODUCT_PURCHASE_STATUS_SUCCESS : product_header + "GET_PRODUCT_PURCHASE_STATUS_SUCCESS",
    GET_PRODUCT_PURCHASE_STATUS_FAILED : product_header + "GET_PRODUCT_PURCHASE_STATUS_FAILED",
    GET_PRODUCT_PURCHASE_STATUS_LOADING : product_header +"GET_PRODUCT_PURCHASE_STATUS_LOADING",

    GET_PRODUCT_DETAILS_WITH_PURCHASE_OPTION: product_header + "GET_PRODUCT_DETAILS_WITH_PURCHASE_OPTION",
    GET_PRODUCT_DETAILS_WITH_PURCHASE_OPTION_SUCCESS: product_header + "GET_PRODUCT_DETAILS_WITH_PURCHASE_OPTION_SUCCESS",
    GET_PRODUCT_DETAILS_WITH_PURCHASE_OPTION_FAILED: product_header + "GET_PRODUCT_DETAILS_WITH_PURCHASE_OPTION_FAILED",
    
    GET_REFERENCE_NUMBER: product_header +"GET_REFERENCE_NUMBER",
    GET_REFERENCE_NUMBER_SUCCESS: product_header +"GET_REFERENCE_NUMBER_SUCCESS",
    GET_REFERENCE_NUMBER_FAILED: product_header +"GET_REFERENCE_NUMBER_FAILED",

    GET_DATA_BUNDLE_PURCHASE_STATUS: product_header + "GET_DATA_BUNDLE_PURCHASE_STATUS",
    GET_DATA_BUNDLE_PURCHASE_STATUS_SUCCESS: product_header + "GET_DATA_BUNDLE_PURCHASE_STATUS_SUCCESS",
    GET_DATA_BUNDLE_PURCHASE_STATUS_FAILED: product_header + "GET_DATA_BUNDLE_PURCHASE_STATUS_FAILED",
    
} 