import React from 'react' 

export const DiscountPriceComponent = ({
    price,
    oldPrice,
    precentage,
    period,
    showTax
}) => {
    return <>
        <div className="discount-container-row">
            <h4>{`Rs. ${price && price.toFixed(2)} ${showTax ? "+tax" : `` }`}</h4>
        </div>
        { period && <div className="discount-container-row">
            <h4 className="period">{period}</h4>
        </div>}
    </>
}