import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { DummyVodRow, DummySvodName, DummySvodPrice, DummySvodDescription, CustomButtonPlaceholder } from './DummyComponent'
import { getLoggedInStatus } from '../../util/ArrayValidator';

export default function DummySvodPage() {
    return (
        <div style={{ marginTop: 40 }}>
            <Container style={{ color: "#d0d0d0" }}>
                <Row>
                    <Col sm={6}><DummySvodName /></Col>
                    <Col sm="4"></Col>
                    <Col><div style={{ display: 'flex', alignItems: 'end' }}>
                    </div>
                        <DummySvodPrice />
                    </Col>
                </Row>
                <DummySvodDescription />
                {getLoggedInStatus() && <CustomButtonPlaceholder />}
                <div style={{ marginTop: 40 }}>
                    <h4 style={{ color: '#bf800c' }}>Content this month</h4>
                    <hr style={{ backgroundColor: '#4fa1d9', borderWidth: 2 }} />
                    <DummyVodRow />
                    <DummyVodRow />
                    <DummyVodRow />
                </div>
            </Container>
        </div>
    )
}
