import React from 'react'
import { Card } from 'react-bootstrap';
import { CustomButton } from './../controls/CustomButton'

export const MyProfiles = ({
    connectionContent,
    onClick

}) => {

    return <>{connectionContent && connectionContent.map(content => {
        return < div style={{ border: '0.5px solid #4fa1d9', marginTop: '20px', marginRight: '30px', borderRadius: '5px', backgroundColor: '#ffff', width: '15rem' }}>
            <Card className="Profile" style={{ backgroundColor: '#4FA1D9', backgroundImage: 'initial', border: 'none', borderRadius: '0px', height: '70px' }} >
                {content.defaultProfile && <small class="ml-auto " style={{ background: '#000', color: '#f8921a', borderRadius: '5px', marginTop: '-25px', marginRight: '-26px', fontSize: 'x-small', padding: '0.3rem' }}>Primary</small>}
                <Card.Title style={{ textAlign: 'center', fontSize: '15px' }}>{content.name}</Card.Title>
            </Card>
            <Card className="Profile padding-18" style={{ backgroundImage: 'initial', border: 'none', borderRadius: '0px', }} >
                <Card.Body >
                    <Card.Text className="Pro-detail" style={{ color: 'black', fontSize: '13px' }}>
                        Age Group : {content.parentalRating}
                    </Card.Text>
                    <Card.Text className="Pro-detail" style={{ color: 'black', fontSize: '13px' }}>
                        Validation Code : {content.authPin}
                    </Card.Text>
                </Card.Body>
                {!content.defaultProfile ? <CustomButton id={content.profileUid}
                    onClick={onClick}
                    style={{ color: '#ffff', marginLeft: '15px' }}
                    text="Remove"
                /> : null}

            </Card>
        </div>
    })}
    </>
}