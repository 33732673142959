import { createSelector, createStructuredSelector } from 'reselect'

const getAllSvodSelector = state => state.Svod.allSvod
const getAllSvodLoadingSelector = state => state.Svod.allSvodLoading
const getAllSvodErrorSelector = state => state.Svod.allSvodError
const getSvod = state => state.Svod.svod
const getSvodLoadingSelector = state => state.Svod.svodLoading
const getSvodErrorSelector = state => state.Svod.svodError
const getSubSvodSelector = state => state.Svod.subSvod
const getSubSvodErrorSelector = state => state.Svod.subSvodError
const getSubSvodLoadingSelector = state => state.Svod.subSvodLoading
const getConnectionDetailsSelector = state => state.Products.connectionDetails
const getConnectionDetailsLoadingSelector = state => state.Products.connectionDetailsLoading
const getPurchaseOptionsSelector = state => state.Svod.purchaseTable
const getPurchaseStatusSelector = state => state.Svod.purchase
const getSelectedConnectionIdSelector = state => state.Products.selectedChannelIndex
const getBasePackageIdSelector = state => state.Products.secondaryAllowedProducts && state.Products.secondaryAllowedProducts[0] && state.Products.secondaryAllowedProducts[0].id
const getBundledProductsSelector = state => state.Svod.bundledProducts
const getPayhereInformation = state => state.Svod.payhereTransactionalDetails
const getUserEmailSelector = state => state.Products.userDetails
const getUserConnectionDetails = state => state.Products.connectionDetails
const getPurchaseLoadingSelector = state => state.Svod.purchase && state.Svod.purchase.purchaseLoading
const getSvodProductPurchaseStatus = state => state.Svod.svodProductPurchaseStatus
const getSvodPurchaseOptionLoading = state => state.Svod.svodPurchaseOptionLoading 
const getSvodProductPurchaseStatusLoading = state => state.Svod.svodProductPurchaseStatusLoading
const getSvodProductPurchaseStatusError = state => state.Svod.svodProductPurchaseStatusError
const getSubmitBtnLoading = state => state.Svod.submitBtnLoading
const getPurchaseReferenceId = state => state.Svod.referenceNumber && state.Svod.referenceNumber.referenceId



export default {
    getAllSvod: createStructuredSelector({
        allSvod: getAllSvodSelector,
        allSvodLoading: getAllSvodLoadingSelector,
        svod: getSvod,
        svodLoading: getSvodLoadingSelector,
        subSvod: getSubSvodSelector,
        subSvodLoading: getSubSvodLoadingSelector,
        purchaseOptions: getPurchaseOptionsSelector,
        purchaseStatus: getPurchaseStatusSelector,
        selectedConId: getSelectedConnectionIdSelector
    }),
    getAllSvodError: createStructuredSelector({
        allSvodError: getAllSvodErrorSelector,
        svodError: getSvodErrorSelector,
        subSvodError: getSubSvodErrorSelector
    }),
    getConnectionDetails: createSelector(getConnectionDetailsSelector, value => value),
    getConnectionDetailsLoading: createSelector(getConnectionDetailsLoadingSelector, value => value),
    getBasePackageId: createSelector(
        getBasePackageIdSelector,
        value => value
    ),
    getBundledProducts: createSelector(
        getBundledProductsSelector,
        value => value
    ),

    getPayhereDetails: createSelector(
        getPayhereInformation,
        value => value
    ),
    getUserDetails: createStructuredSelector({
        userEmail: getUserEmailSelector,
        userDetails: getUserConnectionDetails
    }),

    getPurchaseLoading: createSelector(getPurchaseLoadingSelector, value => value),

    getPayhereSvodPurchaseStatus : createStructuredSelector({
        payhereSvodPurchaseStatus : getSvodProductPurchaseStatus,
        payhereSvodPurchaseStatusLoading : getSvodProductPurchaseStatusLoading,
        productPurchaseStatusError: getSvodProductPurchaseStatusError
    }),

    getSvodPurchaseOptionLoading: createSelector(getSvodPurchaseOptionLoading, value => value),
    getSubmitLoading: createSelector(getSubmitBtnLoading, value => value),
    getReferenceId: createSelector(
        getPurchaseReferenceId,
        value => value
    )
}