import React from 'react'
import { CustomCarousel } from '../carousel/CustomCarousel'
import { DiscountPrices } from './../DiscountPrices/DiscountPrices';
import { carouselWidth, PRODUCT, USER, TRANSACTIONAL, SCREEN_PRODUCT_TYPE_TYPE_NAME } from '../../util/Constants';
import { validArray, getLoggedInStatus } from '../../util/ArrayValidator';
import { DiscountPriceComponent } from '../DiscountPriceComponent/DiscountPriceComponent';
import moment from 'moment';
import { ExpiryDate } from '../ProductPageView/ExpiryDate'

export const ProductModal = ({
    onClick,
    disabled,
    onClose,
    modalStyle,
    showModal,
    modalHeaderText,
    modalBodyText,
    buttonText,
    isForm,
    style,
    handleChange,
    data,
    disable,
    error,
    warning,
    districts,
    purchaseOption,
    svodContent,
    defaultPrice,
    serviceType,
    selectedIndex,
    discountPriceLoading,
    bundledProducts,
    showUtilizationPeriod,
    regionType,
    productType,
    utilizationPeriodUnit
}) => {
    let discountLevelProduct, discountLevelUser;
    if (data && data.discountOptionGroup && data.discountOptionGroup.discountOptions) {
        discountLevelProduct = data.discountOptionGroup && data.discountOptionGroup.discountOptions.filter(data => data.discountLevel === PRODUCT);
        discountLevelUser = data.discountOptionGroup && data.discountOptionGroup.discountOptions.filter(data => data.discountLevel === USER);
    }
    let pricing
    if (data && data.discountOptionGroup && validArray(data.discountOptionGroup.discountOptions)) {
        pricing = <DiscountPrices price={data.discountOptionGroup.purchasePrice === null ? data.discountOptionGroup.actualPrice : data.discountOptionGroup.purchasePrice} showTax={true} oldPrice={
            data.discountOptionGroup.actualPrice} precentage={
                data.purchaseOption.discountPercentage} productPeriod="Monthly" discountLevelProduct={discountLevelProduct && discountLevelProduct[0]} discountLevelUser={discountLevelUser && discountLevelUser[0]}
            discountPriceLoading={discountPriceLoading}
            purchaseOptionType={data.purchaseOption.purchaseType}
            showUtilizationPeriod={showUtilizationPeriod}
            utilizationPeriodUnit={utilizationPeriodUnit}
        />

    } else {
        pricing = <DiscountPriceComponent price={data && data.discountOptionGroup && data.discountOptionGroup.purchasePrice} showTax={true} period={showUtilizationPeriod} />
    }
    // if(data && data.purchaseOption && purchaseOption){
    //     pricing= <DiscountPrices price={selectedIndex&& purchaseOption? purchaseOption && purchaseOption[selectedIndex].defaultPrice : data && data.purchaseOption.defaultPrice} productPeriod="Monthly"showTax={serviceType}  />


    // }

    //all active promotional packages
    let activePromoPacks = [];
    if (bundledProducts && bundledProducts.length !== 0) {
        bundledProducts.map((data, index) => {
            if (data.status === "PRODUCT_STATUS_ACTIVE") {
                data.price = data.purchaseOption.defaultPrice
                activePromoPacks.push(data)
            }
            return null
        })
    }

    let purchaseUtilizationPeriodUnit = data && data.purchaseOption && data.purchaseOption.utilization && data.purchaseOption.utilization.utilizationPeriodUnit.toLowerCase()
    let purchaseUtilizationPeriod = data && data.purchaseOption && data.purchaseOption.utilization && data.purchaseOption.utilization.utilizationPeriod
    let billingDateIsAfter = moment().add(purchaseUtilizationPeriod, purchaseUtilizationPeriodUnit).isAfter(localStorage.getItem('basepackageEndDate'));
    let basepackagePurchaseType = localStorage.getItem('basepackagePurchaseType')

    return (
        <>
            <div className={`modal fade ${showModal ? "show" : ""}`} id="exampleModal" tabIndex="-1" role="dialog" style={modalStyle}>
                <div className=".modal-dialog-centered main-modal-body" role="document">
                    <div>
                        <div class="container">
                            <div class="purchase-main-container product-modal-body scrollbar-info">
                                <div>
                                    <button type="button" className="close" data-dismiss="modal" onClick={onClose}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="offset-md-1 col-md-10">
                                    <div class="title-div">
                                        <h3>{data && data.name}</h3>
                                    </div>
                                    <div class="product-content-div">
                                        <div class="product-image">
                                            <div className="display-inline-block">
                                                <div class="image-sub-container">
                                                    <img src={`/${data && (data.thumbnailURL || data.imageURL)}`} alt="" class={"img-fluid promo-thumbnail" } />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="product-content">
                                            <p>
                                                {data && data.description}
                                            </p>
                                        </div>
                                    </div>
                                    {billingDateIsAfter && basepackagePurchaseType === TRANSACTIONAL && serviceType && <ExpiryDate expireDate={moment(localStorage.getItem('basepackageEndDate')).format('DD/MM/YYYY')} />}
                                    <div className="product-purchase-content">
                                        <div className="price-box">
                                            {pricing}
                                        </div>
                                        <div className="purchase-options-main">
                                            <div className="purchase-option-select">
                                                <div className="form-group">
                                                    <label>Purchase Option</label>
                                                    <select id="purchaseOptionValues" onChange={handleChange} className="form-control">
                                                        <option
                                                            className="form-control"
                                                            value=""
                                                            disabled
                                                            selected
                                                        >
                                                            Purchase Option
                                                        </option>
                                                        {purchaseOption && purchaseOption.map((subOption, index) => {
                                                            return (
                                                                <option
                                                                    id={subOption && subOption.id}
                                                                    selected={data &&
                                                                        data.purchaseOption &&
                                                                        data.purchaseOption.id === subOption.id &&
                                                                        data.purchaseOption.id}
                                                                    value={index}
                                                                >
                                                                    {subOption && subOption.name}
                                                                </option>
                                                            )
                                                        })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="purchase-option-select">
                                                <div className="form-group">
                                                    <label>Voucher Code</label>
                                                    <input id="voucherPassword" type="password" name="couchercode" className="form-control" disabled />
                                                </div>
                                                {data && data.productTypeTypeName!==SCREEN_PRODUCT_TYPE_TYPE_NAME ? regionType ? <span>This can be only viewed within Sri Lanka</span>: <span>This can only be viewed from outside of Sri Lanka.</span>: false}
                                            </div>
                                        </div>

                                        <div class="paymentoptions-div">
                                            <div class="card-details-div">
                                                {/* <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1"/>
                                                <label class="form-check-label" for="defaultCheck1">
                                                    Saved Payment Option
                                                </label>
                                                <div class="saved-card-details">
                                                    <span class="visa-span">Visa</span> .... <span>9889</span>
                                                </div>
                                            </div> */}
                                            </div>
                                            <div class="purchase-button">
                                                {buttonText &&
                                                    <button type="button" onClick={onClick} disabled={discountPriceLoading} >{buttonText}</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {bundledProducts && validArray(bundledProducts) && <div class="slider-div">
                                        <h5>
                                            Can be purchased in the following bundles
                                    </h5>
                                        <div class="slider-items bundled-products">
                                            <CustomCarousel
                                                slidesToShow={(1200 < window.innerWidth ? 8 : (900 < window.innerWidth ? 6 : (550 < window.innerWidth ? 4 : (440 < window.innerWidth ? 3 : (300 < window.innerWidth ? 2 : 1)))))}
                                                renderBottomCenterControls={false}
                                                heightMode="last"
                                                slideWidth="300"
                                                initialSlideHeight={carouselWidth()}
                                                allProducts={activePromoPacks}
                                                informative={false}
                                                // onDemand={true}
                                                // onClick={onClick}
                                                isLoggedIn={true}
                                                isDummy={false}
                                                showPrice={getLoggedInStatus()}
                                            />
                                        </div>
                                    </div>}

                                </div>
                                <div class="col-md-12">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </div>
        </>
    )
};
