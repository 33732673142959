import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom'
import { CustomButton } from './../../components/controls/CustomButton'
import { InputField } from './../../components/controls/Fields'
import { passwordRecoveryActions, passwordRecoverySelectors } from "./ducks";
class RecoverPassword extends Component {
    constructor() {
        super();

        this.state = {
            passwordConfirmation: '',
            passwordError: undefined,
            password: undefined,
            token: '',
            loading: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    static getDerivedStateFromProps(nextProps, state) {
        let newProps = {}
        if (nextProps.password !== state.password) {
            debugger
          
            newProps.password= nextProps.password
            newProps.loading= false
           
        }
        if (nextProps.passwordError !== state.passwordError) {
            newProps.passwordError= nextProps.passwordError
            newProps.loading= false
        }

        return {
            ...newProps
        }
    }

    componentDidMount() {

        // this.props.accountLoginCacheClear();

        let token = this.props.match && this.props.match.params.id.split('=')[1]

        this.setState({
            token
        })
    }

    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    handleSubmit(values) {
        this.setState({
            loading: true,
            passwordError : undefined,
            password: undefined
        })
        const details = { password: values.password, token: this.state.token }
        this.props.changePassword(
            details
        )
    }

    render() {
        const { passwordError, loading } = this.state
        const { handleSubmit } = this.props;
        return (
            <div className="container card-page">
                <div class="card col-sm-12">
                    <form className="login-form" onSubmit={handleSubmit(this.handleSubmit)}>
                        <div class="form-group row">
                            <label class="col-sm-12 col-md-4 col-form-label" required>Password</label>
                            <div className="col-sm-12 col-md-8">
                                <Field
                                    id="password"
                                    type="password"
                                    class="form-control"
                                    placeholder=""
                                    name="password"
                                    component={InputField}
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-12 col-md-4 col-form-label" required>Confirm Password</label>
                            <div className="col-sm-12 col-md-8">
                                <Field
                                    id="passwordConfirmation"
                                    type="password"
                                    class="form-control"
                                    placeholder=""
                                    name="passwordConfirmation"
                                    component={InputField}
                                />
                            </div>
                        </div>
                        <CustomButton id="password" disabled={loading} loading={loading} text="Submit" style={{ float: 'right' }} />
                    </form>
                    {
                        passwordError &&
                        <div className="alert alert-danger" role="alert" >
                            {passwordError}
                        </div>
                    }
                </div>
            </div>
        )
    }
}
const validate = values => {

    const errors = {}

    if (!values.password) {
        errors.password = 'Password is required'
    }
    if (values.password && !/^[a-zA-Z0-9!@#$%^&*)(+=._-]{8,}$/i.test(values.password)) {
        errors.password = 'Password should be minimum 8 characters with numbers and letters'
    }
    if (!values.passwordConfirmation) {
        errors.passwordConfirmation = 'Confirm password is required'
    }
    if (values.password !== values.passwordConfirmation) {
        errors.passwordConfirmation = 'Password and Confirm Password is not matching'
    }
    return errors;
}
const mapStateToProps = (state) => {
    return {
        ...passwordRecoverySelectors.getUsername(state),
        ...passwordRecoverySelectors.getUsernameError(state)

    }
};

export default withRouter(reduxForm({
    form: 'profile',
    validate
})(connect(mapStateToProps, passwordRecoveryActions)(RecoverPassword)));