import React from 'react';
import { Row, Col } from "react-bootstrap";
import ProductStatus from "../ProductStatus/ProductStatus";

function SuspendedPackage({ products, loading, packageStatus }) {
    return (
        <React.Fragment>
            <Row className="overflow-product-column-scroll scrollbar-info">
                {products && products.map((item, index) => {
                    return <>
                        <Col md={5} className="vertical-center">
                            <p className="margin-none">{item.name}</p>
                        </Col>
                        <Col md={5} className="vertical-center" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <ProductStatus status={loading ? "loading" : packageStatus === "SUCCESS" ? "packageSuccess" : "packageFailed"} />

                        </Col>
                    </>

                })
                }
            </Row>
        </React.Fragment>
    )
}

export default SuspendedPackage
