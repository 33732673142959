// Selectors
import { createSelector, createStructuredSelector } from 'reselect'

const getProductTypesSelector = state => state.Products.productTypes
const errorGetProductTypesSelector = state => state.Products.error

const getFeaturedChannelsSelector = state => state.Products.featuredChannels
const getFeaturedChannelsLoadingSelector = state => state.Products.featuredChannelsLoading
const errorGetFeaturedChannelsSelector = state => state.Products.featuredChannelsError
const getfeaturedPackageChannelsLoading = state => state.Products.featuredPackageChannelsLoading
const getfeaturedPackageChannels = state => state.Products.featuredPackageChannels

const getFeaturedPackagesSelector = state => state.Products.featuredPackages
const getFeaturedPackagesLoadingSelector = state => state.Products.featuredPackagesLoading
const errorGetFeaturedPackagesSelector = state => state.Products.featuredPackagesError
const getpurchasedPrimaryPackage = state => state.Products.purchasedPrimaryPackage
const getpurchasedPrimaryPackageLoading = state => state.Products.purchasedPrimaryPackageLoading

const getOnDemandContentsSelector = state => state.Products.onDemandContents
const getOnDemandContentsLoadingSelector = state => state.Products.onDemandContentsLoading
const errorGetOnDemandContentsSelector = state => state.Products.onDemandContentsError
const getFeaturedPackageVodSeletor = state => state.Products.featuredPackageVod
const getFeaturedPackageVodLoadingSeletor = state => state.Products.featuredPackageVodLoading
const getonDemandSubTypesSelector = state => state.Products.onDemandSubTypes

const getFeaturedSvodsSelector = state => state.Products.featuredSvods
const getFeaturedSvodsLoadingSelector = state => state.Products.featuredSvodsLoading
const errorGetFeaturedSvodsSelector = state => state.Products.featuredSvodsError
const getFeaturedPackageSvodSeletor = state => state.Products.featuredPackageSvod
const getFeaturedPackageSvodLoadingSeletor = state => state.Products.featuredPackageSvodLoading

const getBannerContentSelector = state => state.Products.bannerContent
const getBannerContentLoadingSelector = state => state.Products.bannerContentLoading
const getBannerContentErrorSelector = state => state.Products.bannerContentError

const getUserDetailsSelector = state => state.Products.userDetails
const getUserDetailsLoadingSelector = state => state.Products.userDetailsLoading
const getConnectionDetailsSelector = state => state.Products.connectionDetails
const getConnectionDetailsLoadingSelector = state => state.Products.connectionDetailsLoading
const getConnectionContentSelector = state => state.Products.connectionContent
const getConnectionContentLoadingSelector = state => state.Products.connectionContentLoading

const secondaryAllowedProductsLoadingSelector = state => state.Products.secondaryAllowedProductsLoading
const secondaryAllowedProductSelector = state => state.Products.secondaryAllowedProducts
const secondaryIdSelector = state => state.Products.secondaryAllowedId
const secondaryAllowedIdLoadingSelector = state => state.Products.secondaryAllowedIdLoading
const purchasedPrimaryDataBundleLoadingSelector = state => state.Products.purchasedPrimaryDataBundleLoading
const purchasedPrimaryDataBundleSelector = state => state.Products.purchasedPrimaryDataBundle

const getUnsubSelector = state => state.Products.unsub
const getUnsubErrorSelector = state => state.Products.unsubError

const getSelectedConnectionIndexSelector = state => state.Products.selectedChannelIndex

const getPasswordUpdate = state => state.Products.passwordUpdate

const unSubLoadingSelector = state => state.Products.unsubLoading

const reactivateProductSelector = state => state.Products.reactivateProduct
const reactivateProductErrorSelector = state => state.Products.reactivateProductError
const reactivateProductLoadingSelector = state => state.Products.reactivateProductLoading

const packagePurchaseTableSelector = state => state.Products.packagePurchaseTable

const getPackagePurchaseStatus = state => state.Products.packagePurchaseStatus
const getPackagePurchaseStatusError = state => state.Products.packagePurchaseStatusError
const getPackagePurchaseStatusLoading = state => state.Products.packagePurchaseStatusLoading

const getPaymentVerfication = state => state.Products.verifyPayment
const getPaymentVerficationError = state => state.Products.verifyPaymentError
const getPaymentVerficationLoading = state => state.Products.verifyPaymentLoading

export default {
    getProductTypes: createSelector(getProductTypesSelector, (value) => value),
    getErrorProductTypes: createSelector({ error: errorGetProductTypesSelector }),
 
    getFeaturedChannels: createStructuredSelector({
        featuredChannels: getFeaturedChannelsSelector,
        error: errorGetFeaturedChannelsSelector,
        loading: getFeaturedChannelsLoadingSelector,
        featuredPackageChannelsLoading: getfeaturedPackageChannelsLoading,
        featuredPackageChannels: getfeaturedPackageChannels
    }),

    getFeaturedPackages: createStructuredSelector({
        featuredPackages: getFeaturedPackagesSelector,
        error: errorGetFeaturedPackagesSelector,
        loading: getFeaturedPackagesLoadingSelector,
        purchasedPrimaryPackageLoading: getpurchasedPrimaryPackageLoading,
        purchasedPrimaryPackage: getpurchasedPrimaryPackage,
        secondaryAllowedProducts: secondaryAllowedProductSelector,
        secondaryAllowedId: secondaryIdSelector,
        secondaryAllowedIdLoading: secondaryAllowedIdLoadingSelector,
        secondaryAllowedProductsLoading: secondaryAllowedProductsLoadingSelector,
        purchasedPrimaryDataBundleLoading: purchasedPrimaryDataBundleLoadingSelector,
        purchasedPrimaryDataBundle: purchasedPrimaryDataBundleSelector,
        packagePurchaseStatus:getPackagePurchaseStatus,
        packagePurchaseStatusError : getPackagePurchaseStatusError,
        packagePurchaseStatusLoading: getPackagePurchaseStatusLoading,
        verifyPayment : getPaymentVerfication,
        verifyPaymentError : getPaymentVerficationError,
        verifyPaymentLoading : getPaymentVerficationLoading
    }),

    getOnDemandContents: createStructuredSelector({
        onDemandContents: getOnDemandContentsSelector,
        error: errorGetOnDemandContentsSelector,
        loading: getOnDemandContentsLoadingSelector,
        featuredPackageVodLoading: getFeaturedPackageVodLoadingSeletor,
        featuredPackageVod: getFeaturedPackageVodSeletor,
        onDemandSubTypes: getonDemandSubTypesSelector
    }),

    getFeaturedSvods: createStructuredSelector({
        featuredSvods: getFeaturedSvodsSelector,
        error: errorGetFeaturedSvodsSelector,
        loading: getFeaturedSvodsLoadingSelector,
        featuredPackageSvodLoading: getFeaturedPackageSvodLoadingSeletor,
        featuredPackageSvod: getFeaturedPackageSvodSeletor
    }),

    getBannerContent: createStructuredSelector({
        bannerContent: getBannerContentSelector,
        error: getBannerContentErrorSelector,
        loading: getBannerContentLoadingSelector

    }),

    getDetails: createStructuredSelector({
        userDetails: getUserDetailsSelector,
        userDetailsLoading: getUserDetailsLoadingSelector,
        connectionDetails: getConnectionDetailsSelector,
        connectionDetailsLoading: getConnectionDetailsLoadingSelector,
        connectionContent: getConnectionContentSelector,
        connectionContentLoading: getConnectionContentLoadingSelector,
        unsub: getUnsubSelector,
        unsubError: getUnsubErrorSelector,
        selectedConnectionIndex: getSelectedConnectionIndexSelector,
        unsubLoading: unSubLoadingSelector
    }),

    getPasswordUpdate: createSelector(getPasswordUpdate, (value) => value),

    getReactivationDetails : createStructuredSelector({
        reactivateProductDetails : reactivateProductSelector,
        reactivateProductError : reactivateProductErrorSelector,
        reactivateProductLoading: reactivateProductLoadingSelector,
        packagePurchaseTable: packagePurchaseTableSelector
    })


    // getFeaturedPackages: createSelector(getFeaturedPackagesSelector, (value) => value),
    // getErrorFeaturedPackages: createSelector({ error: errorGetFeaturedPackagesSelector }),

    // getOnDemandContents: createSelector(getOnDemandContentsSelector, (value) => value),
    // getErrorOnDemandContents: createSelector({ error: errorGetOnDemandContentsSelector }),

}
