import types from './types'
import { handleActions } from 'redux-actions'

const initialState = {
    types: "",
    typesError: null,
    typesLoading: true
}
export default handleActions({

    [types.GET_TYPES]: (state, { payload }) => (
        { ...state, types: "", typesError: null }
    ),
    [types.GET_TYPES_SUCCESS]: (state, { payload }) => (
        { ...state, types: payload, typesError: null, typesLoading: false }
    ),
    [types.GET_TYPES_FAILED]: (state, { payload }) => (
        { ...state, types: {}, typesError: payload,typesLoading: false }
    ),
    [types.GET_DISTRICTS_SUCCESS]: (state, { payload }) => (
        { ...state, districts: payload, districtError: null }
    ),
    [types.GET_DISTRICTS_FAILED]: (state, { payload }) => (
        { ...state, districts: {}, districtError: payload }
    ),
    [types.NON_SLT_DETAILS_SUCCESS]: (state, { payload }) => (
        { ...state, details: payload, detailsError: null }
    ),
    [types.NON_SLT_DETAILS_FAILED]: (state, { payload }) => (
        { ...state, details: {}, detailsError: payload }
    ),
    [types.CONFIRM_EMAIL_SUCCESS]: (state, { payload }) => (
        { ...state, confirmation: payload, error: null }
    ),
    [types.CONFIRM_EMAIL_FAILED]: (state, { payload }) => (
        { ...state, confirmation: null, error: payload }
    ),

}, initialState)