import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";

import TermsOfUse from './TermsOfUse.jsx'
import { CustomButton } from './../../components/controls/CustomButton'
import { CustomCarousel } from './../../components/carousel/CustomCarousel'
import { AdditionalScreens } from './../../components/customBoxView/AdditionalScreens'
import './Subscription.scss'
import { subscriptionSelectors, subscriptionActions } from './ducks/index.js';


class SubscriptionView extends Component {
    constructor() {
        super();

        this.state = {
            value: "",
            selectedIndex: 0,
            agree: false,
            userName: "",
            packages: [],
            array: [],
            devices: undefined,
            loading: false,
            fields: {},
            errors: {},
            imageNotFoundErrors: [],
            terms: null,
            connectionId: null,
            extraScreen: false,
            extraPackageIds: []
        };
        // this.handleExtraScreen = this.handleExtraScreen.bind(this)
    }


    componentDidMount() {
        this.props.fetchProducts()
        let user = JSON.parse(localStorage.getItem('@user'));
        var connectionId = JSON.parse(localStorage.getItem("@connectionId"))
        this.setState({ connectionId: connectionId })
        if (user) {
            this.setState({
                userName: user.telephoneNumber
            })
        }


    }

    SampleNextArrow(props) {
        const { nextSlide } = props;
        return (
            <div
                onClick={nextSlide}
            ><img src={require("../../images/icons/next-white.svg")} style={{ width: "15px", marginLeft: 20 }} alt="prev-slide" /></div>
        );
    }

    SamplePrevArrow(props) {
        const { previousSlide } = props;
        return (
            <div
                onClick={previousSlide}
            ><img src={require("../../images/icons/back-white.svg")} style={{ width: "15px" }} alt="prev-slide"/></div>
        );
    }


    static getDerivedStateFromProps(prevProps, state) {
        var initialOptions = []
        if (prevProps.products && Array.isArray(prevProps.products.baseProducts)) {
            prevProps.products.baseProducts.map((v, index) => {
                var key = v.id
                var name = v.name
                var price = v.price
                var value = v.price
                var image = v.subProducts

                var obj = {
                    key: key,
                    value: value,
                    price: "Rs. " + price + " + Taxes",
                    text: name,
                    image: image
                };


                initialOptions.push(obj)
                return null
            })
        }
        if (prevProps.errorAgree) {
            return {
                loading: false
            }
        }
        if (initialOptions !== state.packages) {
            return {
                packages: initialOptions
            }
        }

        return null;
    }

    onSubmit = () => {
        this.setState({
            loading: true
        }, () => {
            const agreeStatus = { productId: this.state.value, connectionId: this.state.connectionId.connectionId, extraPackageIds: this.state.extraPackageIds };
            this.props.addConnection(agreeStatus)
        })

    }

    handleChange(field, e) {

        this.setState({
            [e.target.id]: e.target.value,
            agree: e.target.checked
        })
    }

    handleExtraScreen = (field, e, value) => {
        this.setState({
            [field.target.id]: field.target.value,
            extraScreen: field.target.value,
        })

        if (field.target.value === "true") {
            this.state.extraPackageIds.push(field.target.name)
        }
        else {
            var index = this.state.extraPackageIds.indexOf(field.target.name)
            this.state.extraPackageIds.splice(index, 1);
        }
    }



    handleChangeDevices(field, e) {

        let index = e.target.selectedIndex - 1
        this.setState({
            [e.target.id]: e.target.value,
            selectedIndex: index,
            value: this.state.packages && this.state.packages[index] && this.state.packages[index].key
        })
    }
    render() {

        const { errorAgree, products } = this.props;
        const { loading, packages, selectedIndex } = this.state;
        let errorMsg = errorAgree && (<React.Fragment>
            <div class="alert alert-danger col-sm-6" role="alert" style={{ textAlign: 'center' }}>
                {errorAgree}
            </div>
        </React.Fragment>
        );

        return (
            <>
                <div className="container content-box page-confirm-header" style={{ marginTop: 90, padding: "10px 35px" }}>
                    <div className="row align-items-center" style={{ marginTop: 20 }}>
                        <div className="col-sm-12">
                            <h4 style={{ display: 'flex' }}>Confirm Account</h4>

                            <div className="confirmAcc-form" style={{ float: "left" }}>
                                <div className="block-1">Select Package</div>
                                <select
                                    style={{ fontSize: 'smaller', padding: 1 }}
                                    className="block-select "
                                    id="devices"
                                    placeholder="Select a package"
                                    name="packages"
                                    required
                                    value={this.state.devices || ''}
                                    onChange={this.handleChangeDevices.bind(this, "devices")}
                                >
                                    <option value="" disabled >Select a package</option>
                                    {
                                        this.state.packages.map((item) => {
                                            return (
                                                <option id={item.key} name={item.name} value={item.price}>{item.text}</option>

                                            )
                                        })
                                    }
                                </select>
                                <span style={{ color: "red" }}> * </span>
                            </div>
                        </div>
                        {
                            this.state.devices &&
                            <div
                                className="col-md-12"
                                style={{ marginBottom: 10 }}
                            >
                                <span id="devices" className="price-tag">{this.state.devices}</span>
                            </div>
                        }
                    </div>
                    <div className="my-chanels-wrapper">
                        {((this.state.devices)) ?
                            <div className="row" style={{ textAlign: "center", margin: "0px 20px" }}>
                                <CustomCarousel
                                    slidesToShow={(1200 < window.innerWidth ? 8 : (900 < window.innerWidth ? 6 : (550 < window.innerWidth ? 4 : (440 < window.innerWidth ? 3 : (300 < window.innerWidth ? 2 : 1)))))}
                                    renderCenterLeftControls={this.SamplePrevArrow}
                                    renderCenterRightControls={this.SampleNextArrow}
                                    renderBottomCenterControls={false}
                                    heightMode="last"
                                    initialSlideHeight={(1200 < window.innerWidth ? 130 : (990 < window.innerWidth ? 140 : (900 < window.innerWidth ? 90 : (770 < window.innerWidth ? 140 : (550 < window.innerWidth ? 120 : (440 < window.innerWidth ? 170 : (330 < window.innerWidth ? 180 : (300 < window.innerWidth ? 110 : 200))))))))}
                                    channels={packages.length !== 0 && packages[selectedIndex].image}
                                    imageNotFoundStyle={{ bottom: 10, fontSize: 11 }}
                                // informative={false}
                                />
                            </div> : <div style={{ color: "white", fontSize: 13, marginBottom: 10, textAlign: "center" }}>No subscribed channels </div>}
                    </div>
                    <div className="col-md-12"
                        style={{ marginBottom: 10, textAlign: "right" }}
                    >
                        {((this.state.devices) && (Array.isArray(packages[selectedIndex].image)) && (packages[selectedIndex].image.length !== 0)) ? <span style={{ paddingRight: 75, textAlign: "right" }} className="price-tag">{packages[selectedIndex].image.length} Channels</span> : null}
                    </div>
                    {/* Additional screen requirement */}
                    <div style={{ marginTop: 20 }}>
                        {products !== undefined && products.extraProducts && packages && Array.isArray(products.extraProducts) && products.extraProducts.map(v => {
                            return <AdditionalScreens
                                id={v.id}
                                idYes={v.id}
                                idNo={v.id}
                                titleText={v.name}
                                description={v.description}
                                yesOption={true}
                                noOption={false}
                                onChange={this.handleExtraScreen}
                                defaultChecked={!this.state.extraScreen}
                            />
                        })
                        }
                    </div>

                    {/* <div className="row">
                                <div className="col">
                                    <div className="screen-requirment">
                                        <div className="row">
                                            <div className="col">
                                                <div className="terms-and-conditions">
                                                    <h6>Additional Screen Requirment</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-2">
                                                <div className="custom-control custom-radio" >
                                                    <input type="radio" id="additionalScreen1" name="extraScreen" className="custom-control-input" value={true} onChange={this.handleExtraScreen.bind(this, "additionalScreen1")} />
                                                    <label className="custom-control-label" htmlFor="additionalScreen1">Yes</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="custom-control custom-radio" >
                                                    <input type="radio" id="additionalScreen2" name="extraScreen" className="custom-control-input" defaultChecked={!this.state.extraScreen} value={false} onChange={this.handleExtraScreen.bind(this, "additionalScreen2")} />
                                                    <label className="custom-control-label" htmlFor="additionalScreen2">No</label>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <p>For any additional screen <b>Rs. 50/= + Taxes </b>will be added monthly. </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>  */}
                    {/* : null} */}

                    {/* Peo tv terms of use */}
                    <div style={{ marginTop: 10, marginBottom: 40 }}>

                        <div className="row">
                            <div className="col">
                                <div className="terms-content scrollbar-info">
                                    <div className="row">
                                        <div className="col">
                                            <div className="terms-and-conditions">
                                                <h6>PEOTV TERMS OF USE</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <TermsOfUse />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="accept-terms">
                                    <input
                                        type="checkbox"
                                        id="agree"
                                        onChange={this.handleChange.bind(this, "agree")}
                                        style={{
                                            marginRight: 10,
                                            marginTop: 3
                                        }}
                                    />
                                    <span style={{ fontSize: 14, color: "#fff" }}> I agree to the above terms & conditions </span>
                                    <CustomButton id="subscribe" disabled={this.state.agree === false ||
                                        //  this.state.devices == null ||
                                        loading} loading={loading} text="Submit" onClick={this.onSubmit} style={{ float: 'initial' }} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">

                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    {errorMsg}
                                </div>

                            </div>
                        </div>

                        <div />
                    </div>

                </div>

            </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        ...subscriptionSelectors.addConnection(state),
        ...subscriptionSelectors.addConnectionError(state),

    }
};

export default withRouter(connect(
    mapStateToProps,
    subscriptionActions
)(SubscriptionView));
