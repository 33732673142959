import reducers from "./reducers";

import generalPagesSelectors from "./selectors";
import generalPagesService from "./service";
import generalPagesTypes from "./types";
import generalPagesActions from "./actions";

export {
    generalPagesSelectors,
    generalPagesService,
    generalPagesActions,
    generalPagesTypes
};

export default reducers;