import React from 'react'
import { Container } from "react-bootstrap";
import { DeviceCardPlaceholder } from './DummyComponent'

export default function DummyDevicesPage() {
    return (
        <>
            <Container style={{ marginTop: "5%" }}>
                <DeviceCardPlaceholder />
                <DeviceCardPlaceholder />
            </Container>
        </>
    )
}
