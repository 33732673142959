import React from 'react'
import { Container, Tabs, Tab, } from 'react-bootstrap'
import VODCustomCarousel from "../../modules/productPage/VODCustomCarousel"
import dummy from '../../util/dummy.json'
import { VODFilterPlaceholder, DummyVodRow } from './DummyComponent'

export default function DummyVod({ defaultActiveKey }) {
    return (
        <Tabs
            className="on-demand-tabs"
            defaultActiveKey={defaultActiveKey}
            transition={false}
            id="noanim-tab-example"
            onSelect={() => console.log("Wait")}
        >
            {
                dummy.vod.VODSubType.map((v, index) => {
                    if (v != null && (v.displayName === "Educational" || v.displayName === "Movies" || v.displayName === "Music" || v.displayName === "PEO Originals" || v.displayName === "Premiere Movies")) {
                        return (
                            <Tab
                                // key={index}
                                // eventKey={`#${index}`}
                                key={v.typeName}
                                eventKey={`#${v.typeName}`}
                                title={v.displayName}
                                // activeKey={v.id}
                                activeKey={v.typeName}
                            >
                                <VODFilterPlaceholder />
                                <br />
                                <span className="trending-label">
                                    Trending {v.displayName}
                                </span>
                                <div >
                                    <Container>
                                        <div className="carousel-container">
                                            {
                                                <VODCustomCarousel
                                                    selectedTrendingTab={dummy.vod.featuredVod}
                                                    SamplePrevArrow={() => console.log("Wait")}
                                                    SampleNextArrow={() => console.log("Wait")}
                                                    onClick={() => console.log("Wait")}
                                                    isDummy={true}
                                                />
                                            }
                                        </div>
                                    </Container>
                                    <hr className="hr-line" />
                                    <DummyVodRow />
                                    <DummyVodRow />
                                    <DummyVodRow />
                                </div>
                            </Tab>
                        )
                    }
                    return null
                })
            }
        </Tabs >
    )
}
