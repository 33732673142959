import React from 'react'

export const AdditionalDevicesDropdown = ({
    onChange,
    purchasableProductList,
    option

}) => {
    return  <>{purchasableProductList && <span className="package-additional-devices-label">ADDITIONAL DEVICES :
    <select className="package-select" onChange={onChange}>
                <option id="0" value="0" >0</option>
                {option.map(screen => {
                        // if (screen.productTypeName == "Screens") {
                            return <option id={screen.id} value={screen.value} selected={screen.selected}>{screen.value}</option>
        
                    })}
            </select>
        </span>}</>
}