import types from './types'
import { handleActions } from 'redux-actions'

const initialState = {
    getUsernameLoading: true,
    passwordLoading : true
}

export default handleActions({
    [types.GET_USERNAME_SUCCESS]: (state, { payload }) => (
        { ...state, username: payload, usernameError: null, getUsernameLoading: false }
    ),
    [types.GET_USERNAME_FAILED]: (state, { payload }) => (
        { ...state, username: undefined, usernameError: payload , getUsernameLoading: false}
    ),
    [types.CHANGE_PASSWORD_SUCCESS]: (state, { payload }) => (
        { ...state, password: payload, passwordError: undefined, passwordLoading:false }
    ),
    [types.CHANGE_PASSWORD_FAILED]: (state, { payload }) => (
        { ...state, password: undefined, passwordError: payload, passwordLoading: false }
    ),
}, initialState)