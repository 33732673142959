import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { Row, Col, Container } from 'react-bootstrap'
import productTypeIDs from './ducks/productTypeIDs'
import { allProductsActions } from './ducks'
import CRMFilter from '../../components/CRMFilter/CRMFilter';
import "./styles/AllChannelPage.scss";
import { PAYMENT_SUCCESS, SingleProductPage } from '../../util/Constants';
import CardOptions from '../../components/CardOptions/CardOptions';
import Features from '../../components/Features/Features';
import Card from '../../components/CardComponent/Card';
import DummyAllChannelPage from '../../components/DummyComponent/DummyAllChannelPage';
import { getLoggedInStatus, getConnectionId, purchaseStatusValidation, getProductPrice, isDiscounted, discountType } from '../../util/ArrayValidator';

class AllChannelPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allChannels: undefined,
            selectedIndex: undefined
        }
    }

    componentDidMount() {
        if (getLoggedInStatus()) {
            if (!!getConnectionId()) {
                this.props.getAllProducts({
                    conid: getConnectionId(),
                    subTypeId: [productTypeIDs.CHANNEL_ID, productTypeIDs.CHANNEL_BUNDLE_ID]
                });
            }
        } else {
            this.props.getAllProducts([productTypeIDs.CHANNEL_ID, productTypeIDs.CHANNEL_BUNDLE_ID]);
        }
        this.props.getChennelCategories({ "productTypeId": productTypeIDs.CHANNEL_ID })

    }

    componentWillUnmount() {
        this.props.resetForm();
    }

    handleChange = (e) => {
        this.setState({
            selectedIndex: e.target.value
        })
    }

    onClick = (e, isChannelBundle) => {
        this.setState({ show: true, selectedImage: e});
        if(isChannelBundle){
            this.props.history.push(`/channel-bundle/${e}`)
        }else{
            this.props.history.push(`${SingleProductPage}/${e}/false`)
        }
    };

    render() {
        var isLoggedIn = getLoggedInStatus();
        const { selectedIndex } = this.state
        const { allChannels, allUserChannels } = this.props
        const channelData = isLoggedIn ? allUserChannels.allUserChannels : allChannels.allChannels
        const loading = isLoggedIn ? allUserChannels.loading : allChannels.loading
        const unique_categories = isLoggedIn ? allUserChannels.unique_categories : allChannels.unique_categories

        if (loading) {
            return (
                <React.Fragment>
                    <DummyAllChannelPage />
                </React.Fragment>
            )
        }
        else {
            return (
                <React.Fragment>
                    <div className="on-demand-title-container">
                        <Container className="on-demand-title" style={{paddingLeft: 0}}>
                            <h4>ALL CHANNELS</h4>
                        </Container>
                    </div>
                    <Container className="channels-container">
                        <Container className="channels-child-container">
                            <div className="channels-content" ></div>
                            <Row>
                                <Col md={6}>
                                    <CRMFilter
                                        type="CHANNEL"
                                        showFilter={true}
                                        handleFilterChange={this.handleChange}
                                        readyFiltersArray={Array.isArray(unique_categories)}
                                        filtersArray={unique_categories}
                                        label={"All"}
                                        allChannelSelect="all-channel-select"
                                    />
                                    <br />
                                    <br />
                                </Col>
                                <Col className="channels-info-tags-container">
                                    <div className="channels-info-tags-">
                                        <span><i className="fa fa-history" /> TSTV</span>
                                        {isLoggedIn &&
                                            <span style={{paddingLeft: "20px"}}>
                                                <i className="fas fa-plus channels-fa-plus" ></i>
                                                A-la-carte
                                             </span>
                                        }
                                    </div>
                                    <br />
                                </Col>
                            </Row>
                            {selectedIndex === undefined || selectedIndex === "ALL" ?
                                unique_categories && unique_categories.map((x, i) => {
                                    return (
                                        <>
                                            <p>{x.category}</p>
                                            <Row className="all-channels-container">
                                                {channelData.map((item, index) => {
                                                    var n = item.categories && Array.isArray(item.categories) && item.categories.length !== 0 && item.categories.some(yoy => yoy.category === x.category)
                                                    let purchaseStatusType = purchaseStatusValidation(item.purchaseStatus)
                                                    let discount = discountType(item)
                                                    let isChannelBundle = item.productTypeId == productTypeIDs.CHANNEL_BUNDLE_ID
                                                   
                                                    if (n &&  item.status === "PRODUCT_STATUS_ACTIVE") {
                                                        return (
                                                            <Card
                                                                id={item.id}
                                                                hover={true}
                                                                showAddNew={purchaseStatusType.productNotPurchased && !item.isBundled && isLoggedIn}
                                                                showPrice={true}
                                                                name={item.name}
                                                                imageURL={item.imageURL}
                                                                price={getProductPrice(item)}
                                                                onClick={()=>this.onClick(item.id,isChannelBundle)}
                                                                showOptions={item.feature}
                                                                isLoggedIn={isLoggedIn}
                                                                isPurchased={(purchaseStatusType.productPurchased || purchaseStatusType.productPendingRemove || item.paymentStatus===PAYMENT_SUCCESS)}
                                                                showPurchaseIcon={true}
                                                                type="channel"
                                                                hasDiscount={isDiscounted(item)}
                                                                discount={discount}
                                                                isChannelBundle={isChannelBundle}
                                                            >
                                                                <CardOptions isPurchased={(purchaseStatusType.productPurchased || purchaseStatusType.productPendingRemove || item.paymentStatus===PAYMENT_SUCCESS)} isBundled={item.isBundled} isLoggedIn={isLoggedIn} />
                                                                <Features feature={item.feature} />
                                                            </Card>
                                                        )
                                                    }
                                                    return null
                                                })}
                                            </Row> </>)

                                }) : unique_categories.map((x, i) => {
                                    if ((selectedIndex === x.category)) {
                                        return (<>
                                            <Row className="all-channels-container">
                                                {channelData.map((item, index) => {
                                                    var n = item.categories && Array.isArray(item.categories) && item.categories.length !== 0 && item.categories.some(yoy => yoy.category === x.category)
                                                    let purchaseStatusType = purchaseStatusValidation(item.purchaseStatus);
                                                    let discount = discountType(item);
                                                    let isChannelBundle = item.productTypeId == productTypeIDs.CHANNEL_BUNDLE_ID

                                                    if (n &&  item.status === "PRODUCT_STATUS_ACTIVE") {
                                                        return <Card
                                                            id={item.id}
                                                            hover={true}
                                                            showAddNew={purchaseStatusType.productNotPurchased && !item.isBundled && isLoggedIn}
                                                            showPrice={true}
                                                            name={item.name}
                                                            imageURL={item.imageURL}
                                                            price={getProductPrice(item)}
                                                            onClick={()=>this.onClick(item.id, isChannelBundle)}
                                                            showOptions={item.feature}
                                                            isLoggedIn={isLoggedIn}
                                                            isPurchased={(purchaseStatusType.productPurchased || purchaseStatusType.productPendingRemove ||item.paymentStatus===PAYMENT_SUCCESS)}
                                                            showPurchaseIcon={true}
                                                            type="channel"
                                                            hasDiscount={isDiscounted(item)}
                                                            discount={discount}
                                                            isChannelBundle={isChannelBundle}
                                                        >
                                                            <CardOptions isPurchased={purchaseStatusType.productPurchased || purchaseStatusType.productPendingRemove} isBundled={item.isBundled} isLoggedIn={isLoggedIn} />
                                                            <Features feature={item.feature} />
                                                        </Card>
                                                    }
                                                    return null
                                                })}
                                            </Row></>)
                                    }
                                    return null
                                })}
                        </Container>
                    </Container>
                </React.Fragment>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        allUserChannels: state.AllProducts.allUserChannels,
        allChannels: state.AllProducts.allChannels

    }
}

export default withRouter(connect(
    mapStateToProps,
    allProductsActions
)(AllChannelPage));
//224