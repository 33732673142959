import { SUBSCRIPTIONAL, TRANSACTIONAL } from "./Constants"

export const packageFiltering = (allPackages) => {
    let array = []
    let purchaseTypeArray = [SUBSCRIPTIONAL, TRANSACTIONAL]

    purchaseTypeArray.map(type => {
        let packageArray = []
        allPackages.map((prod, i) => {

            let packagePurchaseType = prod && prod.purchaseOption && prod.purchaseOption.purchaseType
            if (type === packagePurchaseType) {
                packageArray.push(prod)
            }
            return null
        })

        packageArray.length!==0 && array.push({
            filterType: type,
            filteredPackages: packageArray
        })
        return null

    })
    array.push({
        filterType: "ALL",
        filteredPackages: allPackages
    })
    return array

}
