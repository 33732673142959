// this component recieves following props from the parent
// type -> specify if "channel"/"onDemand"/"svod"
// name -> name of the product
// id -> id of the product
// isPurchased -> specify the purchase status of the product
// daysToExpire -> pass the nuber of days to expire
// hover -> accepts "true"/"false"
// showAddNew -> pass prop to show the plus icon for purchasable items
// onClick -> pass the callback
// isLoggedIn -> pass the logged in status

import React from 'react';
import ProgressiveImageComponent from '../ProgressiveImageComponent/ProgressiveImageComponent';
import './Card.scss';
import AlreadyPurchasedIcon from '../AlreadyPurchasedIcon/AlreadyPurchasedIcon';
import CardHover from '../CardHover/CardHover';
import FreeIcon from '../../images/icons/free-icon-resized.svg';
import ViewChannelBundle from '../ViewChannelBundle/ViewChannelBundle';

function Card(props) {
    const { isLoggedIn, isPurchased, onClick, id, name, price, daysToExpire, imageURL, hover, showAddNew, type, isDummy, showPrice, beforeLogin, isBundled, hasDiscount, discount, isChannelBundle } = props;   
    let  svodDivStyle, cardStyle

    //outside and inside card syles according to the product
    if (type === 'svod') {
        svodDivStyle = "svod-content";
        cardStyle = "-svod";
    } else {
        svodDivStyle = null;
        cardStyle = "";
    }

    let showFreeIcon;
    if(isLoggedIn && isBundled){
        showFreeIcon = <img src={FreeIcon} alt="Free-Logo" className={`free-img-icon${cardStyle}`}/>
    }

    let showDiscountedIcon;
    if((isLoggedIn || beforeLogin) && !isBundled){
        if(hasDiscount){
            showDiscountedIcon = <div class="discount-div-bg">
                                    <div class="red-bg-container">
                                        <span>{discount}</span>
                                    </div>
                                </div>
        }
    }

    return (
        //the cardStyle accepts the product type and applies the style
        <div className={`outside-crm-card-component${cardStyle}`}>
            <div className={svodDivStyle}>
            {/* <PremiereIcon isLoggedIn={isLoggedIn} isPurchased={isPurchased} cardStyle={cardStyle}/> */}
                <AlreadyPurchasedIcon isLoggedIn={isLoggedIn} isPurchased={isPurchased} cardStyle={cardStyle} />
                {showFreeIcon}
                {showDiscountedIcon}
                <CardHover
                    hover={hover}
                    cardStyle={cardStyle}
                    id={id}
                    onClick={onClick}
                    name={name}
                    daysToExpire={daysToExpire}
                    isPurchased={isPurchased}
                    isLoggedIn={isLoggedIn}
                    showAddNew={showAddNew}
                    price={price}
                    showPrice={showPrice}
                    beforeLogin={beforeLogin}

                />
                <ViewChannelBundle isChannelBundle={isChannelBundle} onClick={onClick} id={id}/>
                {props.children}
                <ProgressiveImageComponent
                    imageURL={imageURL}
                    showAddNew={showAddNew}
                    type={type}
                    isDummy={isDummy}
                />
            </div>
        </div>
    )
}

export default Card;