import React, { Component } from 'react';
import PaymentFailed from '../../components/PaymentDetailsViews/PaymentFailed';

export class PaymentStatus extends Component {
    render() {
        return (
            // <PaymentSuccessfull/>
            <PaymentFailed/>
        )
    }
}

export default PaymentStatus
