import { createAction } from 'redux-actions'
import types from './types'

export default {
    getTypes: createAction(types.GET_TYPES),
    getTypesSuccess: createAction(types.GET_TYPES_SUCCESS),
    getTypesFailed: createAction(types.GET_TYPES_FAILED),

    getDistricts: createAction(types.GET_DISTRICTS),
    getDistrictsSuccess: createAction(types.GET_DISTRICTS_SUCCESS),
    getDistrictsFailed: createAction(types.GET_DISTRICTS_FAILED),

    nonSltDetailsSubmit: createAction(types.NON_SLT_DETAILS),
    nonSltDetailsSubmitSuccess: createAction(types.NON_SLT_DETAILS_SUCCESS),
    nonSltDetailsSubmitFailed: createAction(types.NON_SLT_DETAILS_FAILED),

    accountLogin: createAction(types.ACCOUNT_LOGIN),
    accountLoginSuccess: createAction(types.ACCOUNT_LOGIN_SUCCESS),
    accountLoginFailed: createAction(types.ACCOUNT_LOGIN_FAILED),

    submitDetails: createAction(types.SUBMIT_DETAILS),
    submitDetailsSuccess: createAction(types.SUBMIT_DETAILS_SUCCESS),
    submitDetailsFailed: createAction(types.SUBMIT_DETAILS_FAILED),

    submitOtp: createAction(types.SUBMIT_OTP),
    submitOtpSuccess: createAction(types.SUBMIT_OTP_SUCCESS),
    submitOtpFailed: createAction(types.SUBMIT_OTP_FAILED),

    resendOtp: createAction(types.RESEND_OTP),
    resendOtpSuccess: createAction(types.RESEND_OTP_SUCCESS),
    resendOtpFailed: createAction(types.SUBMIT_OTP_FAILED),

    getCountry : createAction(types.GET_COUNTRY),
    getCountrySuccess : createAction(types.GET_COUNTRY_SUCCESS),
    getCountryFailed : createAction(types.GET_COUNTRY_FAILED),
        
    clearErrorMsg: createAction(types.CLEAR_ERROR_MESSAGE),

    //prepaid registration
    getAuthcode : createAction(types.GET_AUTH_CODE),
    getAuthcodeSuccess : createAction(types.GET_AUTH_CODE_SUCCESS),
    getAuthcodeFailed : createAction(types.GET_AUTH_CODE_FAILED)
}