// Types
export default {
    LOGIN: "login/LOGIN",
    LOGIN_FAILED: "login/LOGIN_FAILED",
    LOGIN_SUCCESS: "login/LOGIN_SUCCESS",

    LOGIN_CLEAR: "login/LOGIN_CLEAR",
    LOGIN_FIELD_CHANGED: "login/LOGIN_FIELD_CHANGED",

    LOGIN_SESSION_START: "login/LOGIN_SESSION_START",
    LOGIN_SESSION_END: "login/LOGIN_SESSION_END",

    CLEAR_ERROR_MSG: "login/CLEAR_ERROR_MSG"
}