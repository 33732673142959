// Selectors
import {
    createSelector,
    createStructuredSelector
} from 'reselect'

const getProduct = state => state.ProductDetails.productDetails;
const productDetailsLoading = state => state.ProductDetails.productDetailsLoading
const getPurchaseOptions = state => state.ProductDetails.purchaseTable
const getPurchaseStatus = state => state.ProductDetails.purchaseStatus
const purchaseLoading = state => state.ProductDetails.purchaseLoading
const productPurchaseStatus = state => state.ProductDetails.status
const getBasePackageIdSelector = state => state.Products.secondaryAllowedProducts && state.Products.secondaryAllowedProducts[0] && state.Products.secondaryAllowedProducts[0].id
const getBundledProductsSelector = state => state.ProductDetails.bundledProducts
const getPayhereInformation = state => state.ProductDetails.payhereTransactionalDetails
const getUserEmailSelector = state => state.Products.userDetails
const getUserConnectionDetails = state => state.Products.connectionDetails
const getProductPurchaseStatus = state => state.ProductDetails.productPurchaseStatus
const getProductPurchaseStatusLoading = state => state.ProductDetails.productPurchaseStatusLoading
const getProductPurchaseStatusError = state => state.ProductDetails.productPurchaseStatusError
const getDiscountPriceSlotLoading = state => state.ProductDetails.productDetailsWithPurchaseOptionLoading
const getSubmitBtnLoadingStatus = state => state.ProductDetails.submitBtnLoading
const getPurchaseReferenceId = state => state.ProductDetails.referenceNumber && state.ProductDetails.referenceNumber.referenceId
const getDataBundleStatus = state => state.ProductDetails.dataBundlePurchaseStatusError
export default {
    getProductDetails: createSelector(
        getProduct,
        value => value
    ),
    getProductDetailsLoading: createSelector(
        productDetailsLoading,
        value => value
    ),
    getPurchaseOptions: createSelector(
        getPurchaseOptions,
        value => value
    ),
    purchaseLoading: createSelector(
        purchaseLoading,
        value => value
    ),
    getPurchaseStatus: createSelector(
        getPurchaseStatus,
        value => value
    ),
    getProductPurchaseStatus: createSelector(
        productPurchaseStatus,
        value => value
    ),
    getBasePackageId: createSelector(
        getBasePackageIdSelector,
        value => value
    ),
    getBundledProducts: createSelector(
        getBundledProductsSelector,
        value => value
    ),
    getPayhereDetails: createSelector(
        getPayhereInformation,
        value => value
    ),
    getUserDetails: createStructuredSelector({
        userEmail: getUserEmailSelector,
        userDetails: getUserConnectionDetails
    }),
    payherePurchaseStatus : createStructuredSelector({
        payherePurchaseStatus : getProductPurchaseStatus,
        payherePurchaseStatusLoading : getProductPurchaseStatusLoading,
        productPurchaseStatusError: getProductPurchaseStatusError
    }),
    getDiscountPriceLoading: createSelector(
        getDiscountPriceSlotLoading,
        value => value
    ),
    getBtnLoadingStatus: createSelector(
        getSubmitBtnLoadingStatus,
        value => value
    ),
    getReferenceId: createSelector(
        getPurchaseReferenceId,
        value => value
    ),
    getDataBundlePurchaseStatusError: createSelector(
        getDataBundleStatus,
        value => value
    ),
}