import types from './types'
import {
    handleActions
} from 'redux-actions';
import { purchaseStatusValidation, getServiceType } from '../../../util/ArrayValidator';

const initialState = {
    purchaseLoading: true,
    unSubLoading: true,
    productDetailsLoading: true,
    payhereTransactionalDetails: null,
    productDetailsWithPurchaseOptionLoading: false,
    referenceNumber: '',
    dataBundlePurchaseStatusLoading: true
}

export default handleActions({
    [types.GET_PRODUCT]: (state, {
        payload
    }) => ({
        ...state,
        productDetails: null,
        productError: null,
        productDetailsLoading: true
    }),
    [types.GET_PRODUCT_SUCCESS]: (state, {
        payload
    }) => ({
        ...state,
        productDetails: payload,
        productError: null,
        productDetailsLoading: false
    }),
    [types.GET_PRODUCT_FAILED]: (state, {
        payload
    }) => ({
        ...state,
        productDetails: null,
        productError: payload,
        productDetailsLoading: false
    }),
    [types.PRODUCT_SUBSCRIBE]: (state, {
        payload
    }) => {
        return {
            ...state,
            purchaseStatus: null,
            purchaseLoading: true,
            status: null
        };
    },
    [types.PRODUCT_SUBSCRIBE_SUCCESS]: (state, {
        payload
    }) => {
        if (getServiceType()) {
            if(payload.status==="transaction"){
                return {
                    ...state,
                    // productDetails: payload.data,
                    // purchaseStatus: payload.message,
                    purchaseLoading: false,
                    // transactionDetails : payload.transactionDetails,
                    status: "success",
                    payhereTransactionalDetails: payload,
                    productPurchaseStatusLoading: true
                    
                };
            }else{
            return {
                ...state,
                // productDetails: payload.data,
                // purchaseStatus: payload.message,
                purchaseLoading: false,
                // transactionDetails : payload.transactionDetails,
                status: "success",
                productDetails: payload.data,
                productPurchaseStatusLoading: true
            };
        }
        }
        let purchaseStatusType = purchaseStatusValidation(payload.data.purchaseStatus);
        return {
            ...state,
            productDetails: payload.data,
            purchaseStatus: payload.message,
            purchaseLoading: false,
            transactionDetails: payload.transactionDetails,
            status: purchaseStatusType.productPurchased ? "success" : "failed",
        };
    },
    [types.PRODUCT_SUBSCRIBE_FAILED]: (state, {
        payload
    }) => ({
        ...state,
        status: "failed",
        purchaseStatus: payload,
        purchaseLoading: false
    }),

    [types.PRODUCT_UNSUBSCRIBE]: (state, {
        payload
    }) => {
        return {
            ...state,
            purchaseStatus: null,
            purchaseLoading: true,
            payherePurchaseStatus: undefined
        };
    },

    [types.PRODUCT_UNSUBSCRIBE_SUCCESS]: (state, {
        payload
    }) => {
        let purchaseStatusType = purchaseStatusValidation(payload.data.purchaseStatus);
        return {
            ...state,
            status: (purchaseStatusType.productPendingRemove || purchaseStatusType.productNotPurchased) ? "success" : "failed",
            purchaseStatus: payload.message,
            purchaseLoading: false,
            productDetails: payload.data
        };
    },
    [types.PRODUCT_UNSUBSCRIBE_FAILED]: (state, {
        payload
    }) => ({
        ...state,
        status: "failed",
        purchaseStatus: payload,
        purchaseLoading: false
    }),
    [types.GET_PURCHASE_TABLE]: (state, {
        payload
    }) => {
        return {
            ...state,
            purchaseTable: null,
            purchaseTableLoading: true,
            puchaseTableError: null
        };
    },
    [types.GET_PURCHASE_TABLE_SUCCESS]: (state, {
        payload
    }) => {
        return {
            ...state,
            purchaseTable: payload,
            purchaseTableLoading: false,
            puchaseTableError: null
        };
    },
    [types.GET_PURCHASE_TABLE_FAILED]: (state, {
        payload
    }) => {
        return {
            ...state,
            purchaseTable: null,
            purchaseTableLoading: false,
            puchaseTableError: payload
        };
    },
    [types.CLEAR_PURCHASE_MESSAGE]: (state, {
        payload
    }) => ({
        ...state,
        purchaseStatus: null,
        purchaseLoading: true,
        transactionDetails: undefined
    }),
    [types.CLEAR_PRODUCT_DETAILS]: (state, {
        payload
    }) => ({
        ...state,
        productDetails: null,
        productError: null,
        productDetailsLoading: true,
    }),
    [types.GET_BUNDLED_PRODUCTS]: (state, {
        payload
    }) => {
        return {
            ...state,
            bundledProducts: null,
            bundledProductsLoading: true
        };
    },
    [types.GET_BUNDLED_PRODUCTS_SUCCESS]: (state, {
        payload
    }) => ({
        ...state,
        bundledProducts: payload,
        bundledProductsLoading: true
    }),
    [types.GET_BUNDLED_PRODUCTS_FAILED]: (state, {
        payload
    }) => ({
        ...state,
        bundledProducts: null,
        bundledProductsError: payload
    }),

    [types.GET_PRODUCT_PURCHASE_STATUS]: (state, { payload }) => (
        { ...state, productPurchaseStatus: undefined, productPurchaseStatusError: undefined, productPurchaseStatusLoading: true }
    ),
    [types.GET_PRODUCT_PURCHASE_STATUS_SUCCESS]: (state, { payload }) => (
        { ...state, productPurchaseStatus: payload, productPurchaseStatusError: null }
    ),
    [types.GET_PRODUCT_PURCHASE_STATUS_FAILED]: (state, { payload }) => (
        { ...state, productPurchaseStatus: {}, productPurchaseStatusError: payload, productPurchaseStatusLoading: false }
    ),
    [types.GET_PRODUCT_PURCHASE_STATUS_LOADING]: (state, { payload }) => (
        { ...state, productPurchaseStatus: undefined, productPurchaseStatusError: undefined, productPurchaseStatusLoading: false }
    ), 
    [types.GET_PRODUCT_DETAILS_WITH_PURCHASE_OPTION]: (state, { payload }) => (
        { ...state, productDetailsWithPurchaseOption: null, productDetailsWithPurchaseOptionLoading: true  }
    ),
    [types.GET_PRODUCT_DETAILS_WITH_PURCHASE_OPTION_SUCCESS]: (state, { payload }) => (
        { ...state, productDetails: payload  , productDetailsWithPurchaseOption: payload, productDetailsWithPurchaseOptionLoading: false }
    ),
    [types.GET_PRODUCT_DETAILS_WITH_PURCHASE_OPTION_FAILED]: (state, { payload }) => (
        { ...state,  productDetailsWithPurchaseOption: null, productDetailsWithPurchaseOptionLoading: false }
    ),

    [types.VERIFY_PRODUCT_PURCHASE]: (state, {
        payload
    }) => {
        return {
            ...state,
            submitBtnLoading: true
        };
    },
    [types.VERIFY_PRODUCT_PURCHASE_SUCCESS]: (state, {
        payload
    }) =>{
        if(getServiceType()){
            let purchaseStatusVerify;
            if(payload === "Subscription Successful" || payload === "Purchase Successful" || payload==="Product un-subscription cancelled successfully"){
                purchaseStatusVerify = "success"
            }else{
                purchaseStatusVerify = "failed"
            }
            return{
                ...state, 
                purchaseStatus: payload,
                submitBtnLoading: false,
                productPurchaseStatusLoading: false,
                status: purchaseStatusVerify
            }
        }
        return{
            ...state,
        submitBtnLoading: false
        }
    },
    [types.VERIFY_PRODUCT_PURCHASE_FAILED]: (state, {
        payload
    }) => ({
        ...state,
        submitBtnLoading: false
    }),
    [types.VERIFY_PRODUCT_UNSUB]: (state, {
        payload
    }) => {
        return {
            ...state,
            submitBtnLoading: true
        };
    },
    [types.VERIFY_PRODUCT_UNSUB_SUCCESS]: (state, {
        payload
    }) =>{
        if(getServiceType()){
            return{
                ...state, 
                purchaseStatus: payload,
                submitBtnLoading: false,
                productPurchaseStatusLoading: false
            }
        }
        return{
            ...state,
        submitBtnLoading: false
        }
    },
    [types.VERIFY_PRODUCT_UNSUB_FAILED]: (state, {
        payload
    }) => ({
        ...state,
        submitBtnLoading: false,
        productPurchaseStatusLoading: false
    }),
    [types.GET_REFERENCE_NUMBER]: (state, {
        payload
    }) => {
        return {
            ...state,
            referenceNumber: ''
        };
    },
    [types.GET_REFERENCE_NUMBER_SUCCESS]: (state, {
        payload
    }) =>{
        return {
            ...state,
            referenceNumber: payload
        }
    },
    [types.GET_REFERENCE_NUMBER_FAILED]: (state, {
        payload
    }) => ({
        ...state,
        error: true
    }),
    [types.GET_DATA_BUNDLE_PURCHASE_STATUS]: (state, { payload }) => (
        { ...state, dataBundlePurchaseStatusLoading: true, dataBundlePurchaseStatus: false, dataBundlePurchaseStatusError: false }
    ),
    [types.GET_DATA_BUNDLE_PURCHASE_STATUS_SUCCESS]: (state, { payload }) => (
        { ...state, dataBundlePurchaseStatusLoading: false, dataBundlePurchaseStatus: payload, dataBundlePurchaseStatusError: false }
    ),
    [types.GET_DATA_BUNDLE_PURCHASE_STATUS_FAILED]: (state, { payload }) => (
        { 
            ...state, 
            dataBundlePurchaseStatusLoading: false, 
            dataBundlePurchaseStatus: false,
            dataBundlePurchaseStatusError: payload.data, 
            purchaseStatus: payload.data, 
            status: "failed", 
            submitBtnLoading: false,
            productPurchaseStatusLoading: false,
            purchaseLoading: false
        }
    ),

}, initialState)
