import React from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import { CustomButton } from '../controls/CustomButton';
import Description from '../controls/Description';
import Card from '../CardComponent/Card';
import { ProductModal } from '../controls/ProductModal';
import { PurchaseModal } from '../controls/PurchaseModal';
import { UnsubscribeModal } from '../controls/UnsubscribeModal'
import NotifyModal from '../controls/NotifyModal'
import DummyProductPageView from './../DummyComponent/DummyProductPageView';
import { getNestedValue, getLoggedInStatus, purchaseStatusValidation, getServiceType, getProductPrice } from '../../util/ArrayValidator';
import DaysToExpire from '../DaysToExpire/DaysToExpire';
import "./ProductPage.scss";
import Features from "../Features/Features";
import { CustomButtonPlaceholder } from "../DummyComponent/DummyComponent";

import moment from 'moment';
import { TRANSACTIONAL, PREPAID_SERVICE_TYPE, PAYMENT_SUCCESS } from '../../util/Constants';

import { ExpiryDate } from '../ProductPageView/ExpiryDate'
import { ProductProvisioningError } from '../ProductPageView/ProductProvisioningError'

function ProductPageView({
    productDetails,
    productDetailsLoading,
    priceDisplay,
    isLoggedIn,
    handleShowUnsub,
    handleShow,
    onClose,
    showModal,
    onClickOk,
    handleChange,
    purchaseOptions,
    showModals,
    onClickYes,
    onClickNo,
    password,
    showUnsub,
    onCloseUnsub,
    onClickYesUnsub,
    notifyStatus,
    closeNotify,
    purchaseLoading,
    getPurchaseStatus,
    getProductPurchaseStatus,
    purchaseButtonText,
    productType,
    customButtonText,
    absoluteDiscount,
    serviceType,
    defaultPrice,
    payherePurchaseStatus,
    payherePurchaseStatusLoading,
    selectedIndex,
    handleChangePurchaseOptions,
    discountPriceLoading,
    bundledProducts,
    submitBtnLoading,
    referenceId,
    showUtilizationPeriod,
    regionType,
    defaultPurchaseOptionType
}) {
    let showPurchasedIcon, showTstv, showPip, showCpvr, showUnsubBtn, showDaysToExpire
    let pgRating = getNestedValue(productDetails, ['pgRating']);
    let additionalDetails = getNestedValue(productDetails, ['additionalDetails']);
    let daysRemainingToExpire = getNestedValue(productDetails, ['daysRemainingToExpire']);
    let feature = getNestedValue(productDetails, ['feature']);
    let productName = getNestedValue(productDetails, ['name']);
    let imageURL = getNestedValue(productDetails, ['imageURL']);
    let description = getNestedValue(productDetails, ['description']);
    let isBundled = getNestedValue(productDetails, ['isBundled']);
    let purchaseStatus = getNestedValue(productDetails, ['purchaseStatus']);
    let productTypeName = getNestedValue(productDetails, ['productTypeName']);
    let billingEndDate = getNestedValue(productDetails, ['billingEndDate']);
    let productPurchaseOption = getNestedValue(productDetails, ["purchaseOption"])
    //get the status of the purchase type
    let purchaseType = purchaseStatusValidation(purchaseStatus);
    let paymentStatus = getNestedValue(productDetails, ['paymentStatus']);
    let provisioningStatus = paymentStatus && paymentStatus === PAYMENT_SUCCESS && purchaseType.productPurchasedFailed
    //get the last day of the month and the present year
    // var today = new Date();
    // var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    var expirationDate = moment(billingEndDate).format('DD/MM/YYYY');
    //show additional details for VOD's
    if (productType === 'vod') {
        additionalDetails = <Col>{productDetails && (pgRating || (additionalDetails && Array.isArray(additionalDetails))) &&
            <div className="information-vod">
                <div>
                    {additionalDetails && Array.isArray(additionalDetails) && additionalDetails.map(details => {
                        return <><p>{details.code} : <span>{details.name}</span></p></>
                    })
                    }
                    {pgRating && <><span>PG Rating</span> : <span>{pgRating}</span></>}
                </div>
            </div>}
        </Col>
    } else {
        additionalDetails = null
    }
    //show the already subscribed text
    if(productType === 'databundle'){
        if (isLoggedIn && (purchaseType.productPurchased || provisioningStatus || (productTypeName === "Screens" && isBundled))) {
            showPurchasedIcon = <span> <i className="fa fa-check-square" style={{ color: "#4FA1D9", fontSize: 18 }}></i>{" "}{defaultPurchaseOptionType === TRANSACTIONAL ? "Already Purchased" : "Already Subscribed"}</span>
        }
    } else {
        if (isLoggedIn && (purchaseType.productPurchased || purchaseType.productPendingRemove || provisioningStatus || (productTypeName === "Screens" && isBundled))) {
            showPurchasedIcon = <span> <i className="fa fa-check-square" style={{ color: "#4FA1D9", fontSize: 18 }}></i>{" "}{defaultPurchaseOptionType === TRANSACTIONAL ? "Already Purchased" : "Already Subscribed"}</span>
        }
    }

    //days remaining to expire
    if (productType === 'vod') {
        showDaysToExpire = <DaysToExpire daysToExpire={daysRemainingToExpire} />
    }
    //show the features in the channels
    if (productDetails && productDetails.feature !== null) {
        if (getNestedValue(productDetails, ['feature', 'tstv'])) {
            showTstv = <span><i className="fa fa-history margin-right-5" /> TSTV</span>
        } else {
            showTstv = null
        }
        // if (productDetails && productDetails.feature.pip) {
        if (getNestedValue(productDetails, ['feature', 'pip'])) {
            showPip = <span><i className="fa fa-battery-quarter margin-right-5" /> PIP</span>
        } else {
            showPip = null;
        }
        if (getNestedValue(productDetails, ['feature', 'cpvr'])) {
            showCpvr = <span><i className="fa fa-video margin-right-5" /> CPVR</span>
        } else {
            showCpvr = null
        }
    }

    //show the unsub button to specific product types
    if (purchaseType.productPurchased && defaultPurchaseOptionType !== TRANSACTIONAL) {
        showUnsubBtn = <CustomButton style={{ marginRight: "0" }} onClick={handleShowUnsub} text="UNSUBSCRIBE" />
    }

    //check usertype and enable subscribe button
    let subsBtnDisabled = false;
    if (getServiceType() === PREPAID_SERVICE_TYPE) {
        if (purchaseType.productPendingRemove) {
            subsBtnDisabled = true;
        }
    } else {
        subsBtnDisabled = false;
    }

    let unsubMessage = "";

    if (getServiceType()) {
        unsubMessage = ""
    } else {
        unsubMessage = "Please re-subscribe to cancel the unsubscription.";
    }

    let showFreeTag;
    if (getLoggedInStatus() && productType === 'vod' && isBundled) {
        showFreeTag = <div className="free-tag"><h6 className="free-tag-font">Free</h6></div>
    }
    let purchaseUtilizationPeriodUnit = productPurchaseOption && productPurchaseOption.utilization && productPurchaseOption.utilization.utilizationPeriodUnit.toLowerCase()
    let purchaseUtilizationPeriod = productPurchaseOption && productPurchaseOption.utilization && productPurchaseOption.utilization.utilizationPeriod
    let billingDateIsAfter = moment().add(purchaseUtilizationPeriod, purchaseUtilizationPeriodUnit).isAfter(localStorage.getItem('basepackageEndDate'));
    let basepackagePurchaseType = localStorage.getItem('basepackagePurchaseType')

    if (productDetailsLoading) {
        return (<React.Fragment>
            <DummyProductPageView
                productType={productType}
                showPurchasedIcon={showPurchasedIcon}
                priceDisplay={priceDisplay}
                productDetails={productDetails}
                customButtonText={customButtonText}
            />
        </React.Fragment>)
    } else {
        return (
            <Container style={{ color: "#d0d0d0", marginTop: 35 }}>
                <Row>
                    <Col sm={6}>
                        <Row>
                            <Col><h4>{productName}</h4></Col>
                            {feature && (feature.tstv || feature.pip || feature.cpvr) ?
                                <Col>
                                    <div style={{ display: 'grid' }}>
                                        {showTstv}
                                        {showPip}
                                        {showCpvr}
                                    </div>
                                </Col> : null
                            }
                        </Row>
                        <Row className="days-to-expire">
                            {showDaysToExpire}
                        </Row>
                    </Col>
                    <Col sm={2}></Col>
                    <Col className="price-display-col" style={{ "display": "flex" }}>
                        {showPurchasedIcon}
                        {priceDisplay}
                        {showFreeTag}
                    </Col>
                </Row>
                <br />
                <Row style={{ marginTop: (absoluteDiscount && absoluteDiscount !== 0) && "-7%" }}>
                    <Col sm={3} md={3} lg={2}>
                        <Card
                            hover={false}
                            showOptions={feature}
                            showAddNew={false}
                            imageURL={imageURL}
                            type={productType} >
                            {/* <CardOptions isPurchased={(purchaseTypeStatus.productPurchased || purchaseTypeStatus.productPendingRemove)} isBundled={isBundled} isLoggedIn={isLoggedIn} /> */}
                            <Features feature={feature} />
                        </Card>
                    </Col>
                    {additionalDetails}
                </Row>
                <Row style={{ textAlign: "justify" }}>
                    <Col>
                        <Description
                            description={description}
                        />
                    </Col>
                </Row>
                <Row style={{ display: 'initial' }}>
                    {productDetails && !productDetails.isBundled && billingDateIsAfter && basepackagePurchaseType === TRANSACTIONAL && serviceType && !provisioningStatus && <ExpiryDate expireDate={moment(localStorage.getItem('basepackageEndDate')).format('DD/MM/YYYY')} />}
                </Row>
                <Row style={{ display: 'initial' }}>
                    {provisioningStatus && <Row className="provision-alert-row">
                        <ProductProvisioningError />
                    </Row>}
                </Row>
                <br />
                {submitBtnLoading ? <CustomButtonPlaceholder /> : (getLoggedInStatus() && !isBundled && <Row className="vod-purchase-btn">
                    <Col className="d-flex justify-content-end">
                        {showUnsubBtn}
                        {((purchaseType.productPendingRemove || purchaseType.productNotPurchased ) || (isBundled === false && !purchaseType.productPurchased)) &&
                            <CustomButton
                                style={{ marginRight: "0" }}
                                onClick={handleShow}
                                text={customButtonText}
                                disabled={subsBtnDisabled}
                            >
                            </CustomButton>}
                    </Col>
                </Row>)}
                {
                    getLoggedInStatus() && productType !== 'databundle' && purchaseType.productPendingRemove &&
                    <Row className="renew-alert">
                        <div className="account-suspended">
                            <p>Unsubscribed content. You will have access to this only till {expirationDate}. {unsubMessage}</p>
                        </div>
                    </Row>
                }
                <div className="margin-bottom-10" />
                <ProductModal
                    showModal={showModal}
                    // showModal={true}
                    modalStyle={{ display: showModal ? 'flex' : 'none', overflow: "overlay" }}
                    //    / modalStyle={{ display: true ? 'flex' : 'none', overflow: "overlay" }}
                    onClose={onClose}
                    modalBodyText="Please confirm to unsubscribe?"
                    buttonText={customButtonText}
                    data={productDetails}
                    purchaseOption={purchaseOptions}
                    onClick={onClickOk}
                    handleChange={handleChangePurchaseOptions}
                    defaultPrice={defaultPrice}
                    serviceType={serviceType}
                    selectedIndex={selectedIndex}
                    discountPriceLoading={discountPriceLoading}
                    bundledProducts={bundledProducts}
                    showUtilizationPeriod={showUtilizationPeriod}
                    regionType={regionType}
                    utilizationPeriodUnit={purchaseUtilizationPeriodUnit}
                />
                <PurchaseModal
                    heading={`Are you sure you want to ${customButtonText.toLowerCase()}?`}
                    showModal={showModals}
                    modalStyle={{ display: showModals ? 'flex' : 'none', overflow: "overlay" }}
                    handleChange={handleChange}
                    onClose={onClose}
                    modalBodyText="Please confirm to unsubscribe?"
                    data={productDetails}
                    onClick={onClickOk}
                    onClickYes={onClickYes}
                    onClickNo={onClickNo}
                    value={password}
                    disable={password === undefined ? true : false}
                />
                <UnsubscribeModal
                    showUnsub={showUnsub}
                    modalStyle={{ display: showUnsub ? 'flex' : 'none', overflow: "overlay" }}
                    onClose={onCloseUnsub}
                    data={productDetails}
                    onClickYes={onClickYesUnsub}
                    onClickNo={onClickNo}
                />
                <NotifyModal
                    showModal={notifyStatus}
                    modalStyle={{
                        display: notifyStatus ? "flex" : "none",
                        overflow: "overlay",
                    }}
                    onClose={closeNotify}
                    type={getProductPurchaseStatus}
                    message={getPurchaseStatus}
                    loading={purchaseLoading || payherePurchaseStatusLoading}
                    dummyType={productType}
                    purchaseStatus={purchaseStatus}
                    serviceType={serviceType}
                    payherePurchaseStatus={payherePurchaseStatus}
                    data={productDetails}
                    defaultPrice={parseFloat(getProductPrice(productDetails))}
                    purchaseType={defaultPurchaseOptionType}
                    referenceId={referenceId}
                    provisioningStatus={provisioningStatus}
                />
            </Container>
        )
    }
}

export default ProductPageView
