import React from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import { ProductPageTitle, ProductCardPlaceholder, ProductAdditionDetailsPlaceholder, ProductPageDescription, CustomButtonPlaceholder } from './DummyComponent'
import {getLoggedInStatus} from '../../util/ArrayValidator';


export default function DummyProductPageView({ customButtonText }) {
    return (
        <Container style={{ color: "#d0d0d0", marginTop: 35 }}>
            <Row>
                <Col sm={4}>
                    <Row>
                        <Col>
                            <ProductPageTitle />
                        </Col>
                    </Row>
                </Col>
                <Col sm={6}></Col>
            </Row>
            <Row>
                <Col sm={3} md={3} lg={2}>
                    <ProductCardPlaceholder />
                </Col>

                <Col>
                    <ProductAdditionDetailsPlaceholder />
                </Col>
            </Row>
            <Row style={{ textAlign: "justify" }}>
                <Col>
                    <ProductPageDescription />
                </Col>
            </Row>
            {getLoggedInStatus() &&
                <CustomButtonPlaceholder />
            }
            <div className="margin-bottom-10" />

        </Container>
    )
}
