import reducers from "./reducers";

import singleProductSelectors from "./selectors";
import singleProductService from "./service";
import singleProductTypes from "./types";
import singleProductActions from "./actions";

export {
    singleProductSelectors,
    singleProductService,
    singleProductTypes,
    singleProductActions
};

export default reducers;