import React from 'react';
import { CustomCarousel } from '../carousel/CustomCarousel'
import DummyFeaturedVod from '../DummyComponent/DummyFeaturedVod';
import { carouselWidth } from "../../util/Constants";

const MyVods = ({ onDemandContents, onClick, renderCenterLeftControls, renderCenterRightControls, isLoggedIn,vod }) => {
  if (onDemandContents.loading) {
    // if (true) {
    return (
      //  <Spinner />
      <DummyFeaturedVod
        renderCenterLeftControls={renderCenterLeftControls}
        renderCenterRightControls={renderCenterRightControls}
        onClick={onClick}
        isLoggedIn={isLoggedIn}
        isDummy={true}
      />
    )
  }
  if (vod && Array.isArray(vod) && vod !== 0) {
    return <div className='landing-page-carousel'><CustomCarousel
      slidesToShow={(900 < window.innerWidth ? 3 : (550 < window.innerWidth ? 2 : (440 < window.innerWidth ? 2 : 1)))}
      renderCenterLeftControls={renderCenterLeftControls}
      renderCenterRightControls={renderCenterRightControls}
      renderBottomCenterControls={false}
      heightMode="last"
      slideWidth="185px"
      initialSlideHeight={carouselWidth()}
      allProducts={vod}
      informative={true}
      onDemand={true}
      onClick={onClick}
      isLoggedIn={true}
      isDummy={false}
    /></div>
  }
}

export default MyVods;