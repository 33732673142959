import React from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ViewAllButton = ({ path, className, btnText }) => {
    return <Col style={{ textAlign: "center", margin: 30 }}>
        <Link className={className} to={path}>{btnText}</Link>
    </Col>
}

export default ViewAllButton;