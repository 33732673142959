import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import history from "../../_helpers/history";

class ShowAuthCode extends Component {

    componentDidMount() {
        let auth = this.props.location.state && this.props.location.state.authPin
        if (!auth || !localStorage.getItem('showAuth')) {
            this.props.history.push("/")
        }
        localStorage.removeItem('showAuth')

        

        localStorage.removeItem('details','@connectionID')
        // localStorage.removeItem('@connectionID')
        // localStorage.removeItem()

    }
    onClick = () => {
        history.go(0); // Return at the beginning
    }
    render() {
        let jwt = localStorage.jwt;
        let auth = this.props.location.state && this.props.location.state.authPin
        if (!auth) {
            this.props.history.push("/")
        }
        return (
            <div className="container card-page" >
                <div className="">
                    <div className="" role="alert" style={{ color: '#fff', fontWeight: 'bold', marginBottom: 20, textAlign: 'center' }}>
                        Your Validation Code is
                        <span style={{ color: '#F8921A' }}> {auth && auth.authCode}</span>
                        <br />
                        {/* {jwt == undefined && <span  >Please verify the link sent to your email and click here</span>}<br/> */}
                        {/* {jwt == undefined ? <><span >Please verify the link sent to your email and </span><Link to="/login" id="login" style={{ fontSize: 15, textDecoration: 'underline', marginTop: 2, paddingLeft: 5 }}>click here</Link></> : <Link to="/" id="home" style={{ fontSize: 12, textDecoration: 'underline', float: 'right', marginTop: 10 }}>Access Selfcare</Link>} */}

                        {/* {jwt == undefined ? 
                            <>
                                <span >Please verify the link sent to your email and </span><Link to="/login" id="login" style={{ fontSize: 15, textDecoration: 'underline', marginTop: 2, paddingLeft: 5 }}>click here</Link></> 
                                : 
                                <Link to="/" id="home" style={{ fontSize: 12, textDecoration: 'underline', float: 'right', marginTop: 10 }}>Access Selfcare</Link>} */}
                        {jwt === undefined ?
                            <>
                                <span>Please verify the link sent to your email and then login.</span>
                            </>
                            : <Link to="/" id="home" style={{ fontSize: 12, textDecoration: 'underline', float: 'right', marginTop: 10 }} onClick={this.onClick}>Access Selfcare</Link>

                        }

                    </div>
                </div>
            </div>

        )
    }
}
export default withRouter(ShowAuthCode)
