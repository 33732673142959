import React from 'react';
import "./styles.scss";
import { Row, Col } from 'react-bootstrap';
import DiscountSlot from '../DiscountSlot/DiscountSlot'; 
import DummyDiscountPrices from "../../components/DummyComponent/DummyDiscountPrices";
import moment from 'moment';

export const DiscountPrices = ({
    price,
    oldPrice,
    precentage,
    productPeriod,
    showTax,
    discountLevelProduct,
    discountLevelUser,
    discountPriceLoading,
    type,
    isProduct,
    purchaseOptionType,
    showUtilizationPeriod,
    utilizationPeriodUnit
}) => {
    let productDiscountType, productDiscountPeriod, productDiscountValue, discount, productDiscountPeriodEnd
    let userDiscountType, userDiscountValue, userDurationType, userDiscount, userDuration, userDiscountPeriodEnd
    if (discountLevelProduct) {
        productDiscountType = discountLevelProduct.discountType;
        productDiscountPeriod = moment(discountLevelProduct.durationEnd).format('DD/MM/YYYY');
        productDiscountValue = discountLevelProduct.value;
        if (productDiscountType === "AMOUNT") {
            discount = `Rs.${productDiscountValue}`
        } else {
            discount = `${productDiscountValue}%`
        }

        if(purchaseOptionType === "PURCHASE_TYPE_TRANSACTIONAL"){
            productDiscountPeriodEnd = <h6>Offer valid till {productDiscountPeriod}</h6> //date
        }else{
            productDiscountPeriodEnd = <h6>For {discountLevelProduct.duration} {utilizationPeriodUnit==="MONTHS" ? "Month": "Day"}{discountLevelProduct.duration === 1 ? "" : "s"} until {productDiscountPeriod}</h6>  //for 3 months until date
        }
    }

    if (discountLevelUser) {
        userDiscountType = discountLevelUser.discountType;
        userDiscountPeriodEnd = moment(discountLevelUser.end).format('DD/MM/YYYY');
        userDiscountValue = discountLevelUser.value;
        userDurationType = discountLevelUser.durationType;
        userDuration = discountLevelUser.duration;
        if (userDiscountType === "AMOUNT") {
            userDiscount = `Rs.${userDiscountValue}`
        } else {
            userDiscount = `${userDiscountValue}%`
        }

        if(purchaseOptionType === "PURCHASE_TYPE_TRANSACTIONAL"){
            if (userDurationType === "FOREVER") {
                userDuration = <h6>Forever</h6>
            } else {
                userDuration = <h6>Offer valid till {userDiscountPeriodEnd}</h6>
            }
        }else{
            if (userDurationType === "FOREVER") {
                userDuration = <h6>Forever</h6>
            } else {
                // userDuration = `For ${userDuration} ${_.startCase(_.toLower(userDurationType))} until ${userDiscountPeriodEnd}`
                userDuration = <h6>For {userDuration} {utilizationPeriodUnit==="MONTHS" ? "Month": "Day"}{userDuration === 1 ? "" : "s"} until {userDiscountPeriodEnd}</h6>
            }    
        }
    }

    if(discountPriceLoading){
        return  <Row>
            <Col md={9}></Col>
            <Col md={3} className="dummy-discount"><DummyDiscountPrices/></Col>
        </Row>
    }
    
    // if(type === "package"){
    //     return  <>
    //     {<div className={isProduct ? "row display-flex-end" : "row"}>
    //         {discountLevelProduct || discountLevelUser ? <div className="col-xs-1"><h6 className="old-package-price">Rs.{oldPrice} </h6></div> : ""}
    //         {discountLevelProduct && <div className="col-xs-7"><DiscountSlot till={productDiscountPeriodEnd} discountpercentage={discount} productDiscountPeriod={productDiscountPeriod} type={type}/></div>}
    //         {discountLevelProduct && discountLevelUser ? <><div className="col-xs-1 addition-mark">+</div></> : ""}
    //         {discountLevelUser ? <><div className="col-xs-7"><DiscountSlot till={userDuration} discountpercentage={userDiscount} from="user" type={type}/></div></> : ""}
    //     </div>
    //     }
    //     <div className={isProduct ? "row display-flex-end" : "row"}>
    //         {/* <h4>{`Rs. ${price} ${showTax ? `` : "+tax"}`}</h4> */}
    //         <h4>{`Rs. ${price && price.toFixed(2)} ${showTax ? "+tax" : '' }`}</h4>
    //     </div>
    //     {productPeriod && <div className={isProduct ? "row display-flex-end" : "row"}>
    //         <h4 className="period">{`(${productPeriod})`}</h4>
    //     </div>}
    // </>
    // }else{
        return  <>
        {
            <>
                {!!discountLevelProduct && !discountLevelUser ? productDiscountPeriodEnd : ''}
                {!!discountLevelUser && !discountLevelProduct ? userDuration : ''}
                <div className="price-box-content">
                    {discountLevelProduct || discountLevelUser ? <div className="price-before"><h4><span>{`Rs.${oldPrice.toFixed(2)}`}</span></h4><hr/></div> : ""}
                    {discountLevelProduct && <DiscountSlot till={discountLevelProduct && discountLevelUser ? productDiscountPeriodEnd : ""} discountpercentage={discount} productDiscountPeriod={productDiscountPeriod} />}
                    {discountLevelProduct && discountLevelUser ? <div className="discount-plus">+</div> : ""}
                    {discountLevelUser ? <><DiscountSlot till={discountLevelProduct && discountLevelUser ? userDuration : ""} discountpercentage={userDiscount} from="user" /></> : ""}
                </div>
            </>
        }
        <div class="main-price">
            <h2><span>{`Rs. ${price && price.toFixed(2)}`}</span> {`${showTax ? "+tax " : ''}`}
                <span className="utilization-period">{showUtilizationPeriod}</span>
            </h2>
        </div>
        </>
    // }
}
