import {
    createLogic
} from 'redux-logic';
import {
    getLoggedInStatus,getConnectionId
} from '../../../util/ArrayValidator';

import actions from './actions';
import types from './types';
import endPoints from './../../../util/EndPoints';
import API from './../../../util/HTTPClient';
import axios from "axios";
import { PROMOTIONAL_PACKAGE_ID } from '../../../util/Constants';
import singleProductActions from '../../ProductContent/ducks/actions'
import _ from "lodash";

export default [
    createLogic({

        type: types.GET_BANNER, // only apply this logic to this type
        latest: true, // only take latest
        debounce: 1000, // Wait 1 s before triggering another call

        process({
            MockHTTPClient,
            getState,
            action
        }, dispatch, done) {
            let HTTPClient
            if (MockHTTPClient) {
                HTTPClient = MockHTTPClient
            } else {
                HTTPClient = API
            }

            if (getLoggedInStatus()) {
                HTTPClient.Get(`${endPoints.GET_BANNER}?basePackageId=${action.payload}`)
                    .then(res => res.data)
                    .then(data => {
                        dispatch(actions.getBannerContentSuccess(data))
                        if(data.length !== 0){
                            dispatch(actions.getBannerProducts(data))
                        }else{
                            dispatch(actions.getBannersBeforeLoginSuccess())
                        }
                    })
                    .catch(err => dispatch(actions.getBannerContentFailed(err)))
                    .then(() => done()); // call done when finished dispatching
            } else {
                HTTPClient.Get(endPoints.GET_BANNER)
                    .then(res => res.data)
                    .then(data => {
                        dispatch(actions.getBannerContentSuccess(data));
                        dispatch(actions.getBannersBeforeLoginSuccess());
                    })
                    .catch(err => dispatch(actions.getBannerContentFailed(err)))
                    .then(() => done()); // call done when finished dispatching
            }

        }
    }),
    createLogic({
        type: types.GET_BANNER_TYPE_PRODUCTS,
        latest: true,
        debounce: 1000,
        process({
            MockHTTPClient,
            getState,
            action
        }, dispatch, done) {
            let HTTPClient;
            if (MockHTTPClient) {
                HTTPClient = MockHTTPClient;
            } else {
                HTTPClient = API;
            }

            let promises = [];
            action.payload.map((product, index) => {
                if (product.productId) {
                    promises.push(
                        // HTTPClient.Get(`${endPoints.PURCHASED_PRODUCTS}${product.productId}?conid=${getConnectionId()}`)
                        HTTPClient.Get(`${endPoints.GET_PRODUCT}${product.productId}`)
                            .then(resp => {
                                let passObj = {
                                    id: index,
                                    data: resp.data.productTypeTypeName
                                }
                                return passObj;
                            })
                    )
                    // HTTPClient.Get(`${endPoints.GET_PRODUCT}${product.productId}`)
                    // .then(resp => console.log(resp.data))
                }
                return null
            })

            axios.all(promises)
                .then(data => {
                    dispatch(actions.getBannerProductsSuccess(data))
                })
                .catch(err => dispatch(actions.getBannerProductsFailed(err)))
                .then(() => done()); // call done when finished dispatching
        }
    }),
    createLogic({

        type: types.GET_PROMOTIONAL_PACKAGES, // only apply this logic to this type
        latest: true, // only take latest
        debounce: 1000, // Wait 1 s before triggering another call

        process({
            MockHTTPClient,
            getState,
            action
        }, dispatch, done) {
            let HTTPClient
            if (MockHTTPClient) {
                HTTPClient = MockHTTPClient
            } else {
                HTTPClient = API
            }
            let endPoint
            let conid= getConnectionId()
            if (getLoggedInStatus()) {
                
                // endPoint = `${endPoints.BASE_PRODUCT}?typeid=${PROMOTIONAL_PACKAGE_ID}`
                endPoint = `${endPoints.PURCHASED_PACKAGE}?conid=${conid}&packagetypeid=${PROMOTIONAL_PACKAGE_ID}`
            } else {
                endPoint = `${endPoints.BASE_PRODUCT}?typeid=${PROMOTIONAL_PACKAGE_ID}`
            }

            HTTPClient.Get(`${endPoint}`)
                .then(res => res.data)
                .then(data => {
                     _.map(data, function(o) {
                        if (o.status === "PRODUCT_STATUS_ACTIVE") return o;
                    });
                    data = _.without(data, undefined)
                    let purchaseOptionGroupId = data.length!==0 && data[0].purchaseOption && data[0].purchaseOption.purchaseOptionGroupId
                    dispatch(actions.getPromotionalPackagesSuccess(data))
                    purchaseOptionGroupId && dispatch(singleProductActions.getPurchaseTable(purchaseOptionGroupId))
                    data[0].id && dispatch(actions.getPromotionalPackageProducts(data[0].id))
                })
                .catch(err => dispatch(actions.getPromotionalPackagesFailed(err)))
                .then(() => done()); // call done when finished dispatching
        }
    }),
    createLogic({

        type: types.GET_PROMOTIONAL_PACKAGE_PRODUCTS, // only apply this logic to this type
        latest: true, // only take latest
        debounce: 1000, // Wait 1 s before triggering another call
        process({
            MockHTTPClient,
            getState,
            action
        }, dispatch, done) {
            let HTTPClient
            if (MockHTTPClient) {
                HTTPClient = MockHTTPClient
            } else {
                HTTPClient = API
            }
            let endPoint
            let conid= getConnectionId()
            if (getLoggedInStatus()) {
                
                // endPoint = `${endPoints.BASE_PRODUCT}?typeid=${PROMOTIONAL_PACKAGE_ID}`
                endPoint = `${endPoints.PURCHASED_PRODUCTS}${action.payload}?conid=${conid}`
            } else {
                endPoint = `${endPoints.GET_PRODUCT}${action.payload}`
            }

            HTTPClient.Get(`${endPoint}`)
                .then(res => res.data)
                .then(data => {
                   dispatch(actions.getPromotionalPackageProductsSuccess(data))
                })
                .catch(err => dispatch(actions.getPromotionalPackageProductsFailed(err)))
                .then(() => done()); // call done when finished dispatching
        }
    }),
]