import types from './types'
import { handleActions } from 'redux-actions';

const basicState = {
    loading: true,
    hasError: false,
    error: {},
}

const initialState = {
    connectionTypes: {
        ...basicState,
        connectionTypesLoading: true
    },
    districts: {
        ...basicState,
        districtsLoading: true
    },
    nonSltDetails: {
        ...basicState,
        nonSltDetailsLoading: true
    },
    userAccount: {
        ...basicState,
        userAccount: {}
    },
    submitUserDetails: {
        ...basicState
    },
    resendOtp: {
        ...basicState
    },
    otp: {
        ...basicState
    },
    country :{
        ...basicState,
        countryLoading: true,
        country: ""
    },
    authCode : {
        ...basicState,
        authCodeLoading:true
    }
}
export default handleActions({

    [types.GET_TYPES_SUCCESS]: (state, { payload }) => {

        for (var i=0; i < payload.length; i++) {
            if (payload[i].id === 6) {
                var a = payload.splice(i,2);
                payload.unshift(a[0], a[1]);
                break;
            }
            // if (payload[i].id === 7) {
            //     var a = payload.splice(i,2);
            //     payload.unshift(a[1]);
            //     break;
            // }
        }

       return {
            ...state,
            connectionTypes: {
                ...state.connectionTypes,
                connectionTypesLoading: false,
                connectionTypes: payload,
                connectionTypesError: null
            }
        }
    },
    [types.GET_TYPES_FAILED]: (state, { payload }) => (
        {
            ...state,
            connectionTypes: {
                ...state.connectionTypes,
                connectionTypesLoading: false,
                connectionTypes: null,
                connectionTypesError: payload
            }
        }),

    [types.GET_DISTRICTS_SUCCESS]: (state, { payload }) => (
        { ...state, districts: { districts: payload, districtError: null, districtsLoading: false } }
    ),
    [types.GET_DISTRICTS_FAILED]: (state, { payload }) => (
        { ...state, districts: { districts: null, districtError: payload, districtsLoading: false } }
    ),
    [types.NON_SLT_DETAILS_SUCCESS]: (state, { payload }) => (
        { ...state, nonSltDetails: { nonSltDetails: payload, nonSltDetailsError: null, nonSltDetailsLoading: false } }
    ),
    [types.NON_SLT_DETAILS_FAILED]: (state, { payload }) => (
        { ...state, nonSltDetails: { nonSltDetails: {}, nonSltDetailsError: payload, nonSltDetailsLoading: false } }
    ),
    [types.ACCOUNT_LOGIN_SUCCESS]: (state, { payload }) => (
        { ...state, userAccount: { ...state.userAccount, userAccount: payload, userAccountError: null } }
    ),
    [types.ACCOUNT_LOGIN_FAILED]: (state, { payload }) => (
        { ...state, userAccount: { ...state.userAccount, userAccount: {}, userAccountError: payload } }
    ),
    [types.SUBMIT_DETAILS_SUCCESS]: (state, { payload }) => (
        { ...state, submitUserDetails: { submitUserDetails: payload, submitUserDetailsFailed: null } }
    ),
    [types.SUBMIT_DETAILS_FAILED]: (state, { payload }) => (
        { ...state, submitUserDetails: { submitUserDetails: {}, submitUserDetailsFailed: payload } }
    ),
    [types.SUBMIT_OTP_SUCCESS]: (state, { payload }) => (
        { ...state, otp : {otp: payload, otpError: null }, resendOtp : {}}
    ),
    [types.SUBMIT_OTP_FAILED]: (state, { payload }) => (
        { ...state, otp :{otp: {}, otpError: payload }, resendOtp : {}}
    ),
    [types.RESEND_OTP_SUCCESS]: (state, { payload }) => (
        { ...state, resendOtp : {resendOtp: payload, resendOtpError: null }, otp:{}}
    ),
    [types.RESEND_OTP_FAILED]: (state, { payload }) => (
        { ...state, resendOtp : {resendOtp: false, resendOtpError: payload }, otp : {}}
    ),
    [types.GET_COUNTRY_SUCCESS]: (state, { payload }) => (
        { ...state, country : {country: payload, countryError: undefined, countryLoading: false }}
    ),
    [types.GET_COUNTRY_FAILED]: (state, { payload }) => (
        { ...state, country : {country: {}, countryError: payload, countryLoading: false }}
    ),
    [types.CLEAR_ERROR_MESSAGE]: (state, { payload }) => (
        { ...initialState }
    ),
    [types.GET_AUTH_CODE_SUCCESS]: (state, { payload }) => (
        { ...state, authCode: { authCode: payload, authCodeError: undefined, authCodeLoading: false } }
    ),
    [types.GET_AUTH_CODE_FAILED]: (state, { payload }) => (
        { ...state, authCode: { authCode: {}, authCodeError: payload, authCodeLoading: false } }
    ),
    [types.GET_AUTH_CODE_SUCCESS]: (state, { payload }) => (
        { ...state, authCode : {authCode: payload, authCodeError: undefined, authCodeLoading: false }}
    ),
    [types.GET_AUTH_CODE_FAILED]: (state, { payload }) => (
        { ...state, authCode : {authCode: {}, authCodeError: payload, authCodeLoading: false }}
    ),
}, initialState)