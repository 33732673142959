import React from 'react';
import { CustomCarousel } from '../carousel/CustomCarousel'
import { getIndex } from '../../util/ArrayValidator';

const MyPromoPackage = ({promotionalPackages, onClick }) => {
    let allProductState = getIndex(promotionalPackages,0)
    if (promotionalPackages && Array.isArray(promotionalPackages) && promotionalPackages !== 0) {
        return <div className='justify-content-space-evenly' style={{ height: "50%" }}>
            <CustomCarousel
                slidesToShow={(900 < window.innerWidth ? 3 : (550 < window.innerWidth ? 2 : (440 < window.innerWidth ? 2 : 1)))}
                renderBottomCenterControls={false}
                heightMode="last"
                slideWidth="300px"
                initialSlideHeight={(1200 < window.innerWidth ? 290 :
                    (990 < window.innerWidth ? 240 :
                        (900 < window.innerWidth ? 180 :
                            (770 < window.innerWidth ? 300 :
                                (550 < window.innerWidth ? 180 :
                                    (440 < window.innerWidth ? 210 :
                                        (330 < window.innerWidth ? 220 : 180)))))))}
                allProducts={promotionalPackages && Array.isArray(promotionalPackages) && promotionalPackages}
                featuredPackage={true}
                informative={true}
                onClick={onClick}
                isLoggedIn={allProductState && allProductState.name}
                selectedImage={0}
                slideIndex={0}
                viewPackage={false}
            //  imageNotFoundStyle={{ bottom: 10, fontSize: 11 }}
            /></div>
    }
    else {
        return null
    }
}

export default MyPromoPackage;