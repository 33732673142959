import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {CustomButton} from '../../components/controls/CustomButton'
import { generalPagesActions } from './ducks'
import ReactGA from 'react-ga';
import { trackingId } from '../../util/Constants';
import history from '../../_helpers/history'
class ConfirmEmail extends Component {
    state = {
        token: '',
        loading: false,
        emailConfrimation: undefined,
        emailError: undefined
    }


    static getDerivedStateFromProps(nextProps, state) {
        if (nextProps.emailConfrimation !== state.emailConfrimation) {
            return {
                emailConfrimation: nextProps.emailConfrimation,
                loading: false
            }
        }

        if (nextProps.emailError !== state.emailError) {
            return {
                emailError: nextProps.emailError,
                loading: false
            }
        }

        return null;
    }

    componentDidMount() {
        localStorage.clear()
        let token = this.props.match && this.props.match.params.id
        this.setState({
            token
        })
    }

    handleConfirm=() =>{
        //alert(this.state.token)
        this.setState({
            loading: true
        })
        this.props.confirmEmail(this.state.token)
    }

    render() {

        ReactGA.initialize(trackingId);

        // Initialize google analytics page view tracking
        history.listen(location => {
            ReactGA.set({ page: location.pathname }); // Update the user's current page
            ReactGA.pageview(location.pathname); // Record a pageview for the given page
        });

        const { loading } = this.state
        return (
            <div className="container card-page">
                <div class="card">
                    <h4 class="text-center" style={{ color: "#ffffff" }}>Press ‘Submit’ to access Selfcare portal. </h4><br />
                     <div style={{ display: 'flex', justifyContent: 'center' }}>
                         <CustomButton id="email" loading={loading} onClick={this.handleConfirm} text="Submit" />
                   </div>
                    
                    {
                        this.props.emailConfrimation &&
                        <div class="alert alert-success" role="alert">
                            Confirm verification is success
                        </div>
                    }
                    {
                        this.props.emailError &&
                        <div class="alert alert-danger" role="alert">
                            {this.props.emailError}
                        </div>
                    }

                </div>
            </div>
        )
    }
}

export default withRouter(
    connect((state) => ({
        emailConfrimation: state.GeneralPages.confirmation,
        emailError: state.GeneralPages.error
    }), generalPagesActions)(ConfirmEmail)
)
