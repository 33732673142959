// Actions
import { createAction } from 'redux-actions'
import types from './types'

export default {
    getProduct: createAction(types.GET_PRODUCT),
    getProductSuccess: createAction(types.GET_PRODUCT_SUCCESS),
    getProductFailed: createAction(types.GET_PRODUCT_FAILED),

    productSubscribe: createAction(types.PRODUCT_SUBSCRIBE),
    productSubscribeSuccess: createAction(types.PRODUCT_SUBSCRIBE_SUCCESS),
    productSubscribeFailed: createAction(types.PRODUCT_SUBSCRIBE_FAILED),

    productUnsubscribe: createAction(types.PRODUCT_UNSUBSCRIBE),
    productUnsubscribeSuccess: createAction(types.PRODUCT_UNSUBSCRIBE_SUCCESS),
    productUnsubscribeFailed: createAction(types.PRODUCT_UNSUBSCRIBE_FAILED),

    getPurchaseTable: createAction(types.GET_PURCHASE_TABLE),
    getPurchaseTableSuccess: createAction(types.GET_PURCHASE_TABLE_SUCCESS),
    getPurchaseTableFailed: createAction(types.GET_PURCHASE_TABLE_FAILED),

    verifyProductPurchase: createAction(types.VERIFY_PRODUCT_PURCHASE),
    verifyProductPurchaseSuccess: createAction(types.VERIFY_PRODUCT_PURCHASE_SUCCESS),
    verifyProductPurchaseFailed: createAction(types.VERIFY_PRODUCT_PURCHASE_FAILED),

    verifyProductUnsub: createAction(types.VERIFY_PRODUCT_UNSUB),
    verifyProductUnsubSuccess: createAction(types.VERIFY_PRODUCT_UNSUB_SUCCESS),
    verifyProductUnsubFailed: createAction(types.VERIFY_PRODUCT_UNSUB_FAILED),

    clearPurchaseMessage: createAction(types.CLEAR_PURCHASE_MESSAGE),

    clearProductDetails: createAction(types.CLEAR_PRODUCT_DETAILS),
    
    getBundledProducts: createAction(types.GET_BUNDLED_PRODUCTS),
    getBundledProductsSuccess: createAction(types.GET_BUNDLED_PRODUCTS_SUCCESS),
    getBundledProductsFailed: createAction(types.GET_BUNDLED_PRODUCTS_FAILED),

    getPrepaidPurchaseStatus : createAction(types.GET_PRODUCT_PURCHASE_STATUS),
    getPrepaidPurchaseStatusSuccess : createAction(types.GET_PRODUCT_PURCHASE_STATUS_SUCCESS),
    getPrepaidPurchaseStatusFailed : createAction(types.GET_PRODUCT_PURCHASE_STATUS_FAILED),
    getPrepaidPurchaseStatusLoading : createAction(types.GET_PRODUCT_PURCHASE_STATUS_LOADING),

    getProductDetailsWithPurchaseOption: createAction(types.GET_PRODUCT_DETAILS_WITH_PURCHASE_OPTION),
    getProductDetailsWithPurchaseOptionSuccess: createAction(types.GET_PRODUCT_DETAILS_WITH_PURCHASE_OPTION_SUCCESS),
    getProductDetailsWithPurchaseOptionFailed: createAction(types.GET_PRODUCT_DETAILS_WITH_PURCHASE_OPTION_FAILED),
    
    getReferenceNumber: createAction(types.GET_REFERENCE_NUMBER),
    getReferenceNumberSuccess: createAction(types.GET_REFERENCE_NUMBER_SUCCESS),
    getReferenceNumberFailed: createAction(types.GET_REFERENCE_NUMBER_FAILED),

    getDataBundlePurchaseStatus: createAction(types.GET_DATA_BUNDLE_PURCHASE_STATUS),
    getDataBundlePurchaseStatusSuccess: createAction(types.GET_DATA_BUNDLE_PURCHASE_STATUS_SUCCESS),
    getDataBundlePurchaseStatusFailed : createAction(types.GET_DATA_BUNDLE_PURCHASE_STATUS_FAILED)
    
}

