import React from 'react';
import { Container, Row } from 'react-bootstrap';
import './AccountSuspended.scss';
import { CustomButton } from '../controls/CustomButton';
import { ErrorMessage } from '../customMessages/ErrorMessage'
import { ProductPageTitle } from '../DummyComponent/DummyComponent'
import { MOBITEL_SERVICE_TYPE, PREPAID_SERVICE_TYPE, SUBSCRIPTIONAL, PEOTV_SERVICE_TYPE } from '../../util/Constants';

function AccountSuspended({ serviceType, onClickReactivate, error, loading, disable, status, basepackagePurchaseType, packageloading }) {
    //check the conection type and  show the re-activate button
    let showReactivateBtn
    let suspendedMessage
    if (serviceType === "Prepaid" || serviceType === "Mobitel") {
        showReactivateBtn = <div className="reactivate-btn">
            <CustomButton text={basepackagePurchaseType === SUBSCRIPTIONAL ? "RE-ACTIVATE" : "Select Packages"} onClick={onClickReactivate} loading={loading} disabled={disable} />
        </div>
    } else if (serviceType !== PEOTV_SERVICE_TYPE) {
        showReactivateBtn = <div className="reactivate-btn">
            <a target="_blank" className="form-control btn-md btn-block mb-1 submit-button" href="https://billpay.slt.lk/" rel="noopener noreferrer">Pay Now ! </a>
        </div>
    }
    if (serviceType === "Prepaid" || serviceType === "Mobitel") {
        if (basepackagePurchaseType === SUBSCRIPTIONAL) {
            if (serviceType === PREPAID_SERVICE_TYPE) {
                suspendedMessage = "This service is currently suspended. Your credit/debit card payment was unsuccessful. Please complete due payments to continue using this service."
            } else if (serviceType === MOBITEL_SERVICE_TYPE) {
                suspendedMessage = `This service is currently suspended. The account balance of ${localStorage.userTelephoneNumber} is insufficient to continue using this service. Please complete due payments to re-activate.`
            }

        } else {
            suspendedMessage = "Your package has expired. Please select and purchase a package of your choice to enjoy this service."
        }
    } else {
        suspendedMessage = "Your connection is in a suspended state. Please proceed the outstanding payment for re-activation."
    }
    return (
        <Container>
            <Row className="account-suspended-row">
                {packageloading ?
                    <div className="account-suspended">
                        <div>
                            <span style={{ display: 'flex', justifyContent: 'center', color: '#c2c2c2', marginTop: '10px' }}>Loading....</span>
                        </div>
                        <div className="row" style={{ margin: "12px 0px 12px 0px", textAlign: "center" }}>
                            <div className="col-sm-1"></div>
                            <div className="col-sm-10 " style={{ left: "18%" }}>
                                <ProductPageTitle />
                            </div>
                        </div>
                    </div>

                    :
                    loading ? <div className="account-suspended">
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {serviceType === PREPAID_SERVICE_TYPE && <img src={require('./../../images/pay-here-logo.png')} height="34" alt="payhere" />}
                                {serviceType === MOBITEL_SERVICE_TYPE && <img src={require('./../../images/mobitel-logo.png')} height="34" alt="mobitel" />}

                            </div>
                            <span style={{ display: 'flex', justifyContent: 'center', color: '#c2c2c2', marginTop: '10px' }}>Payment Processing....</span>
                        </div>
                        <div className="row" style={{ margin: "12px 0px 12px 0px", textAlign: "center" }}>
                            <div className="col-sm-1"></div>
                            <div className="col-sm-10 " style={{ left: "18%" }}>
                                <ProductPageTitle />
                            </div>
                        </div>
                    </div> : status ?
                            <div className="account-suspended">
                                <ion-icon name="checkmark-circle-outline" size="large" style={{ color: "#4a8900", size: "large", display: 'flex', margin: 'auto' }} ></ion-icon>
                                <p className="reactivate-payment-status">{status} </p>
                            </div> : error ?
                                <div className="account-suspended">
                                    <ion-icon name="close-circle-outline" size="large" style={{ color: "#B00020", size: "large", display: 'flex', margin: 'auto' }} ></ion-icon>
                                    <p className="reactivate-payment-status">Payment Unsuccessful</p>
                                    <p className="reactivate-payment-status">{error} </p>
                                    <div className="reactivate-btn">
                                        <CustomButton text="RETRY" onClick={onClickReactivate} loading={loading} disabled={disable} />
                                    </div>
                                </div> :
                                <div className="account-suspended">
                                    <p className="suspended-message">{suspendedMessage}</p>
                                    {showReactivateBtn}
                                    <ErrorMessage
                                        errorStatus={error}
                                        errorMessage={error}
                                    />
                                </div>}

            </Row>
        </Container>
    )
}

export default AccountSuspended
