import reducers from "./reducers";

import promotionSelectors from "./selectors";
import promotionService from "./service";
import promotionTypes from "./types";
import promotionActions from "./actions";

export {
    promotionSelectors,
    promotionService,
    promotionActions,
    promotionTypes
};

export default reducers;