import types from './types'
import {
    handleActions
} from 'redux-actions'

const initialState = {
    bannerContent: null,
    bannerContentError: null,
    bannerContentLoading: true,
    promotionalPackagesLoading: true,
    promotionalPackageProductLoading: true
}

export default handleActions({
    [types.GET_BANNER]: (state, {
        payload
    }) => ({
        ...state,
        bannerContent: null,
        bannerContentError: null,
        bannerContentLoading: true
    }),
    [types.GET_BANNER_SUCCESS]: (state, {
        payload
    }) => ({
        ...state,
        bannerContent: payload,
        bannerContentError: null,
        bannerContentLoading: true
    }),
    [types.GET_BANNER_FAILED]: (state, {
        payload
    }) => ({
        ...state,
        bannerContent: null,
        bannerContentError: payload,
        bannerContentLoading: false
    }),
    [types.GET_BANNER_TYPE_PRODUCTS]: (state, { payload }) => (
        {
            ...state,
        }
    ),
    [types.GET_BANNER_TYPE_PRODUCTS_SUCCESS]: (state, { payload }) => {
        payload.map((obj) => {
            state.bannerContent.map((banner, index) => {
                if (obj.id === index) {
                    state.bannerContent[index].type = obj.data
                }
                return null
            })
            return null
        })

        return {
            ...state,
            bannerContent: state.bannerContent,
            bannerContentLoading: false
        }
    },
    [types.GET_BANNER_TYPE_PRODUCTS_FAILED]: (state, { payload }) => {
        return {
            ...state
        }
    },
    [types.GET_BANNER_CONTENT_BEFORE_LOGIN_SUCCESS]: (state, { payload }) => {
        return {
            ...state,
            bannerContentLoading: false
        }
    },
    [types.GET_PROMOTIONAL_PACKAGES_SUCCESS]: (state, { payload }) => {
        return {
            ...state,
            promotionalPackagesLoading: false,
            promotionalPackages: payload,
            promotionalPackagesError: null
        }
    },
    [types.GET_PROMOTIONAL_PACKAGES_FAILED]: (state, { payload }) => {
        return {
            ...state,
            promotionalPackagesLoading: false,
            promotionalPackages: null,
            promotionalPackagesError: payload
        }
    },
    [types.RESET_BANNER_STATE]: (state, { payload }) => {
        return {
            ...state,
            bannerContent: null,
            bannerContentError: null,
            bannerContentLoading: true
        }
    },
    [types.VERITFY_PACKAGE_PURCHASE]: (state, { payload }) => {
        state.promotionalPackages && state.promotionalPackages.map((data, index) => {
            if (payload && data.id === payload.id) {
                state.promotionalPackages[index] = payload
            }
            return null
        })
        return {
            ...state,
            promotionalPackages: state.promotionalPackages
        }
    },
    [types.GET_PROMOTIONAL_PACKAGE_PRODUCTS]: (state, { payload }) => {
        return {
            ...state,
            promotionalPackageProductLoading: true,
        }
    },
    [types.GET_PROMOTIONAL_PACKAGE_PRODUCTS_SUCCESS]: (state, { payload }) => {
        return {
            ...state,
            promotionalPackageProductLoading: false,
            promotionalPackageProduct: payload,
            promotionalPackageProductError: null
        }
    },
    [types.GET_PROMOTIONAL_PACKAGE_PRODUCTS_FAILED]: (state, { payload }) => {
        return {
            ...state,
            promotionalPackageProductLoading: false,
            promotionalPackageProduct: null,
            promotionalPackageProductError: payload
        }
    },
}, initialState)