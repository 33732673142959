import types from './types'
import {
    handleActions
} from 'redux-actions';
import { getServiceType } from '../../../util/ArrayValidator';

const initialState = {
    allSvodLoading: true,
    svodLoading: true,
    subSvodLoading: true,
    allSvod: null,
    purchase: {
        purchaseLoading: true,
        unSubLoading: true
    }
}

export default handleActions({
    [types.GET_ALL_SVOD_SUCCESS]: (state, {
        payload
    }) => ({
        ...state,
        allSvod: payload,
        allSvodError: null,
        allSvodLoading: false
    }),
    [types.GET_ALL_SVOD_FAILED]: (state, {
        payload
    }) => ({
        ...state,
        allSvod: {},
        allSvodError: payload,
        allSvodLoading: false
    }),
    [types.GET_SVOD_SUCCESS]: (state, {
        payload
    }) => ({
        ...state,
        svod: payload,
        svodError: null,
        svodLoading: false
    }),
    [types.GET_ALL_SVOD_FAILED]: (state, {
        payload
    }) => ({
        ...state,
        svod: {},
        svodError: payload,
        svodLoading: false
    }),
    [types.GET_SUB_SVOD_SUCCESS]: (state, {
        payload
    }) => ({
        ...state,
        subSvod: payload,
        subSvodError: null,
        subSvodLoading: false
    }),
    [types.GET_ALL_SVOD_FAILED]: (state, {
        payload
    }) => ({
        ...state,
        subSvod: {},
        subSvodError: payload,
        subSvodLoading: false
    }),
    [types.CLEAR_SVOD]: (state, {
        payload
    }) => ({
        ...state,
        svodLoading: true,
        svod: null,
        purchase: {
            purchaseStatus: null,
            purchaseLoading: true,
            unSubLoading: true,
            payhereTransactionalDetails: undefined

        },
        subSvod: null,
        subSvodLoading: true,
    }),
    [types.CLEAR_ALL_SVOD_CONTENT]: (state, {
        payload
    }) => {
        // debugger
        return {
            ...state,
            allSvodLoading: true,
            allSvod: null,
            payhereTransactionalDetails: undefined
        }
    },
    [types.PRODUCT_SUBSCRIBE]: (state, {
        payload
    }) => {
        return {
            ...state,
            // svod: payload.data,
            purchase: {
                ...state.purchase,
                // status: "success",
                // status: payload.data.isPurchased ? "success" : "failed",
                // purchaseStatus: "Your request will be processed in few seconds",
                purchaseStatus: null,
                purchaseLoading: true
            }
        }
    },
    [types.PRODUCT_SUBSCRIBE_SUCCESS]: (state, {
        payload
    }) => {
        if (getServiceType()) {
            if (payload.status === "transaction") {
                return {
                    ...state,
                    purchase: {
                        ...state.purchase,
                        purchaseLoading: false,
                    },
                    status: "success",
                    payhereTransactionalDetails: payload,
                    svodProductPurchaseStatusLoading: true

                };
            } else {
                return {
                    ...state,
                    svod: payload.data,
                    purchase: {
                        ...state.purchase,
                        // status: "success",
                        status: "success",
                        // purchaseStatus: "Your request will be processed in few seconds",
                        purchaseStatus: payload.message,
                        purchaseLoading: false,
                    },
                    svodProductPurchaseStatusLoading: true
                }

            }

        }
        return {
            ...state,
            svod: payload.data,
            purchase: {
                ...state.purchase,
                // status: "success",
                status: payload.data.isPurchased ? "success" : "failed",
                // purchaseStatus: "Your request will be processed in few seconds",
                purchaseStatus: payload.message,
                purchaseLoading: false
            }
        }
    },
    [types.PRODUCT_SUBSCRIBE_FAILED]: (state, {
        payload
    }) => ({
        ...state,
        purchase: {
            ...state.purchase,
            status: "failed",
            purchaseStatus: payload,
            purchaseLoading: false,
            unSubLoading: false

        }
    }),

    [types.PRODUCT_UNSUBSCRIBE]: (state, {
        payload
    }) => {
        // state.svod.isPurchased = false
        return {
            ...state,
            // svod: payload.data,
            purchase: {
                ...state.purchase,
                // status: "success",
                // status: !payload.data.isPurchased ? "success" : "failed",
                // purchaseStatus: "Your request will be processed in few seconds",
                purchaseStatus: null,
                purchaseLoading: true
                // unSubLoading: false

            }
        }
    },

    [types.PRODUCT_UNSUBSCRIBE_SUCCESS]: (state, {
        payload
    }) => {
        state.svod.isPurchased = false
        return {
            ...state,
            svod: payload.data,
            purchase: {
                ...state.purchase,
                // status: "success",
                status: (payload.data.purchaseStatus === "PURCHASE_PENDING_REMOVE" || payload.data.purchaseStatus === "NOT_PURCHASED") ? "success" : "failed",
                // purchaseStatus: "Your request will be processed in few seconds",
                purchaseStatus: payload.message,
                purchaseLoading: false
                // unSubLoading: false

            }
        }
    },
    [types.PRODUCT_UNSUBSCRIBE_FAILED]: (state, {
        payload
    }) => ({
        ...state,
        purchase: {
            ...state.purchase,
            status: "failed",
            purchaseStatus: payload,
            purchaseLoading: false
        }
    }),
    [types.GET_PURCHASE_TABLE]: (state, {
        payload
    }) => {
        return {
            ...state,
            purchaseTable: null,
            purchaseTableLoading: true,
            puchaseTableError: null
        };
    },
    [types.GET_PURCHASE_TABLE_SUCCESS]: (state, {
        payload
    }) => {
        return {
            ...state,
            purchaseTable: payload,
            purchaseTableLoading: false,
            puchaseTableError: null
        };
    },
    [types.GET_PURCHASE_TABLE_FAILED]: (state, {
        payload
    }) => {
        return {
            ...state,
            purchaseTable: null,
            purchaseTableLoading: false,
            puchaseTableError: payload
        };
    },
    [types.CLEAR_PURCHASE]: (state, {
        payload
    }) => ({
        ...state,
        purchase: {
            purchaseStatus: null,
            purchaseLoading: true,
            unSubLoading: true,
            payhereTransactionalDetails: undefined
        },
    }),
    [types.GET_BUNDLED_PRODUCTS]: (state, {
        payload
    }) => {
        return {
            ...state,
            bundledProducts: null,
            bundledProductsLoading: true
        };
    },
    [types.GET_BUNDLED_PRODUCTS_SUCCESS]: (state, {
        payload
    }) => ({
        ...state,
        bundledProducts: payload,
        bundledProductsLoading: true
    }),
    [types.GET_BUNDLED_PRODUCTS_FAILED]: (state, {
        payload
    }) => ({
        ...state,
        bundledProducts: null,
        bundledProductsError: payload
    }),
    [types.GET_SVOD_PRODUCT_PURCHASE_STATUS]: (state, { payload }) => (
        { ...state, svodProductPurchaseStatus: undefined, svodProductPurchaseStatusError: undefined, svodProductPurchaseStatusLoading: true }
    ),
    [types.GET_SVOD_PRODUCT_PURCHASE_STATUS_SUCCESS]: (state, { payload }) => (
        { ...state, svodProductPurchaseStatus: payload, svodProductPurchaseStatusError: null }
    ),
    [types.GET_SVOD_PRODUCT_PURCHASE_STATUS_FAILED]: (state, { payload }) => (
        {
            ...state, svodProductPurchaseStatus: {}, svodProductPurchaseStatusError: payload, purchase: {
                purchaseLoading: false
            }, svodProductPurchaseStatusLoading: false
        }
    ),
    [types.GET_SVOD_PRODUCT_PURCHASE_STATUS_LOADING]: (state, { payload }) => (
        { ...state, svodProductPurchaseStatus: undefined, svodProductPurchaseStatusError: undefined, svodProductPurchaseStatusLoading: true }
    ),
    [types.GET_SVOD_PURCHASE_OPTIONS]: (state, { payload }) => (
        { ...state, svod: null, svodPurchaseOptionLoading: true }
    ),
    [types.GET_SVOD_PURCHASE_OPTIONS_SUCCESS]: (state, { payload }) => (
        { ...state, svod: payload, svodPurchaseOptionLoading: false }
    ),
    [types.GET_SVOD_PURCHASE_OPTIONS_FAILED]: (state, { payload }) => (
        { ...state, svod: null, svodPurchaseOptionLoading: false }
    ),
    [types.GET_SVOD_PURCHASE_OPTIONS]: (state, { payload }) => (
        { ...state, svod: null, svodPurchaseOptionLoading: true }
    ),
    [types.GET_SVOD_PURCHASE_OPTIONS_SUCCESS]: (state, { payload }) => (
        { ...state, svod: payload, svodPurchaseOptionLoading: false }
    ),
    [types.GET_SVOD_PURCHASE_OPTIONS_FAILED]: (state, { payload }) => (
        { ...state, svod: null, svodPurchaseOptionLoading: false }
    ),

    [types.VERIFY_SVOD_PURCHASE]: (state, {
        payload
    }) => {
        return {
            ...state,
            submitBtnLoading: true
        }
    },

    [types.VERIFY_SVOD_PURCHASE_SUCCESS]: (state, {
        payload
    }) => {
        if (getServiceType()) {
            let purchaseStatusVerify;
            if (payload === "Subscription Successful" || payload === "Purchase Successful" || payload === "Product un-subscription cancelled successfully") {
                purchaseStatusVerify = "success"
            } else {
                purchaseStatusVerify = "failed"
            }
            return {
                ...state,
                purchase: {
                    ...state.purchase,
                    purchaseLoading: false,
                },
                status: purchaseStatusVerify,
                svodProductPurchaseStatusLoading: false,
                submitBtnLoading: false
            };
        }
        return {
            ...state,
            submitBtnLoading: false
        }
    },
    [types.VERIFY_SVOD_PURCHASE_FAILED]: (state, {
        payload
    }) => ({
        ...state,
        submitBtnLoading: false
    }),
    [types.VERIFY_SVOD_UNSUB]: (state, {
        payload
    }) => {
        return {
            ...state,
            submitBtnLoading: true
        }
    },

    [types.VERIFY_SVOD_UNSUB_SUCCESS]: (state, {
        payload
    }) => {
        if (getServiceType()) {
            return {
                ...state,
                svodProductPurchaseStatusLoading: false,
                submitBtnLoading: false,
                purchase: {
                    ...state.purchase,
                    purchaseStatus: payload
                },
            };
        }
        return {
            ...state,
            submitBtnLoading: false
        }
    },
    [types.VERIFY_SVOD_UNSUB_FAILED]: (state, {
        payload
    }) => ({
        ...state,
        submitBtnLoading: false,
        svodProductPurchaseStatusLoading: false
    }),
    [types.GET_REFERENCE_NUMBER]: (state, {
        payload
    }) => {
        return {
            ...state,
            referenceNumber: ''
        };
    },
    [types.GET_REFERENCE_NUMBER_SUCCESS]: (state, {
        payload
    }) => {
        return {
            ...state,
            referenceNumber: payload
        }
    },
    [types.GET_REFERENCE_NUMBER_FAILED]: (state, {
        payload
    }) => ({
        ...state,
        error: true
    }),

}, initialState)