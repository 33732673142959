export const filterVOD = (state, key) => {
    var keyLanguage = state.languageKey
    var keyGenre = state.genreKey
    var tabType = key && key.substr(1)
    var index = state.allVods.findIndex(x => x.typeName === tabType);
    var filterTab = [];
    var filterTrendingTab = []
    var tempDataLanguage = []
    var tempTrendingDataLanguage = []
    var tempDataGenre = []
    var tempTrendingDataGenre = []
    if (keyLanguage) {
        if (keyLanguage === "ALL") {
            state.allVods && state.allVods[index].trending.map((tren, t) => {
                tempTrendingDataGenre.push(tren)
                return tempTrendingDataGenre
            })

            state.allVods[index].vods.map((vod, v) => {
                tempDataGenre.push(vod)
                return tempDataGenre
            })

        } else {

            state.allVods && state.allVods[index].trending.map((tren, t) => {
                tren.languages.map((language, j) => {
                    if (language.code === keyLanguage) {
                        tempTrendingDataLanguage.push(tren)
                    }
                    return null
                })
                return tempTrendingDataLanguage

            })
            state.allVods[index].vods.map((vod, v) => {
                vod.languages.map((language, j) => {
                    if (language.code === keyLanguage) {
                        tempDataLanguage.push(vod)
                    }
                    return null

                })
                return tempDataLanguage
            })
        }
    }
    if (keyGenre) {
        if (keyGenre === "ALL") {
            state.allVods && state.allVods[index].trending.map((tren, t) => {
                tempTrendingDataGenre.push(tren)
                return tempTrendingDataGenre
            })

            state.allVods[index].vods.map((vod, v) => {
                tempDataGenre.push(vod)
                return tempDataGenre
            })

        } else {
            state.allVods && state.allVods[index].trending.map((tren, t) => {
                tren.categories.map((category, j) => {
                    if (category.category === keyGenre) {
                        tempTrendingDataGenre.push(tren)
                    }
                    return null

                })
                return tempTrendingDataGenre
            })
            state.allVods[index].vods.map((vod, v) => {
                vod.categories.map((category, j) => {
                    if (category.category === keyGenre) {
                        tempDataGenre.push(vod)
                    }
                    return null

                })
                return tempDataGenre
            })
        }

    }
    if (keyGenre && keyLanguage) {
        filterTab = tempDataLanguage.filter(x => tempDataGenre.includes(x));
        filterTrendingTab = tempTrendingDataLanguage.filter(x => tempTrendingDataGenre.includes(x));
    } else {
        filterTab = tempDataLanguage.concat(tempDataGenre)
        filterTrendingTab = tempTrendingDataLanguage.concat(tempTrendingDataGenre)
    }
    keyLanguage = null
    keyGenre = null
    return ({ filterTab: filterTab, filterTrendingTab: filterTrendingTab })
}

export const selectedTabHandler = (state, key) => {
    var selectedTabName = key ? key : state.selectedTabName
    var tabTypeName = selectedTabName.substr(1);
    var index = state.allVods && state.allVods.findIndex(x => x.typeName === tabTypeName);
    // if(key)
    if (tabTypeName === state.allVods[index].typeName) {
        return ({
            selectedTab: state.allVods[index].vods,
            selectedTrendingTab: state.allVods[index].trending,
            unique_content_language: state.allVods[index].languages,
            unique_content_genre: state.allVods[index].genre,
            loading: false
        })
    } else {
        return ({
            selectedTab: [],
            selectedTrendingTab: [],
            unique_content_language: [],
            unique_content_genre: []
        })
    }

}