// Actions
import { createAction } from 'redux-actions'
import types from './types'

export default {
    getAllProducts: createAction(types.GET_ALL_PRODUCTS),
    //---------------------------------------------------------------------
    //---------------------------------------------------------------------

    getAllSVODSuccess: createAction(types.GET_ALL_SVOD_SUCCESS),
    getAllSVODFail: createAction(types.GET_ALL_SVOD_FAIL),

    getUserSVODSuccess: createAction(types.GET_USER_SVOD_SUCCESS),
    getUserSVODFail: createAction(types.GET_USER_SVOD_FAIL),

    //---------------------------------------------------------------------
    //---------------------------------------------------------------------

    getUserDeviceSuccess: createAction(types.GET_USER_DEVICE_SUCCESS),
    getUserDeviceFail: createAction(types.GET_USER_DEVICE_FAIL),

    //---------------------------------------------------------------------
    //---------------------------------------------------------------------

    getUserDataBundleSuccess: createAction(types.GET_USER_DATA_BUNDLE_SUCCESS),
    getUserDataBundleFail: createAction(types.GET_USER_DATA_BUNDLE_FAIL),


    //---------------------------------------------------------------------
    //---------------------------------------------------------------------

    getAllChannelSuccess: createAction(types.GET_ALL_CHANNEL_SUCCESS),
    getAllChannelFail: createAction(types.GET_ALL_CHANNEL_FAIL),

    getUserChannelSuccess: createAction(types.GET_USER_CHANNEL_SUCCESS),
    getUserChannelFail: createAction(types.GET_USER_CHANNEL_FAIL),

    //---------------------------------------------------------------------
    //---------------------------------------------------------------------

    getAllVODSuccess: createAction(types.GET_ALL_VOD_SUCCESS),
    getAllVODFail: createAction(types.GET_ALL_VOD_FAIL),

    getUserVODSuccess: createAction(types.GET_USER_VOD_SUCCESS),
    getUserVODFail: createAction(types.GET_USER_VOD_FAIL),

    //---------------------------------------------------------------------

    //---------------------------VOD UTILS------------------------------------------
    //---------------------------GET_ONDEMAND_SUB_TYPES------------------------------

    getOnDemandSubTypes: createAction(types.GET_ONDEMAND_SUB_TYPES),
    getOnDemandSubTypesSuccess: createAction(types.GET_ONDEMAND_SUB_TYPES_SUCCESS),
    getOnDemandSubTypesFail: createAction(types.GET_ONDEMAND_SUB_TYPES_FAIL),

    //---------------------------------------------------------------------

    //---------------------------CHANNEL UTILS------------------------------------------
    //---------------------------GET_ONDEMAND_SUB_TYPES------------------------------

    getChennelCategories: createAction(types.GET_CHANNEL_CATEGORIES),
    getChennelCategoriesSuccess: createAction(types.GET_CHANNEL_CATEGORIES_SUCCESS),
    getChennelCategoriesFail: createAction(types.GET_CHANNEL_CATEGORIES_FAIL),

    //---------------------------PRODUCT UTILS------------------------------------------
    //---------------------------GET_ALLOWED_PACKAGE_ID---------------------------------
    getAllowedPackageIds: createAction(types.GET_ALLOWED_PACKAGE_IDS),
    getAllowedPackageIdsSuccess: createAction(types.GET_ALLOWED_PACKAGE_IDS_SUCCESS),
    getAllowedPackageIdsFailed: createAction(types.GET_ALLOWED_PACKAGE_IDS_FAILED),

    //------------------------GET ALL PACKAGES FROM ALLOWED IDS----------------------------
    getAllPackages: createAction(types.GET_ALL_PACKAGES),
    getAllPackagesSuccess: createAction(types.GET_ALL_PACKAGES_SUCCESS),
    getAllPackagesFailed: createAction(types.GET_ALL_PACKAGES_FAILED),

     //------------------------GET DISTINCT PRODUCTS OF THE PACKAGES----------------------------
    getDistinctProducts : createAction(types.GET_DISTINCT_PRODUCTS),
    getDistinctProductsSuccess : createAction(types.GET_DISTINCT_PRODUCTS_SUCCESS),
    getDistinctProductsFailed : createAction(types.GET_DISTINCT_PRODUCTS_FAILED),
    getDistinctProductsLoading : createAction(types.GET_DISTINCT_PRODUCTS_LOADING),

     //------------------------GET PRODUCT TYPES----------------------------
    getProductType : createAction(types.GET_PRODUCT_TYPES),
    getProductTypeSuccess : createAction(types.GET_PRODUCT_TYPES_SUCCESS),
    getProductTypeFailed : createAction(types.GET_PRODUCT_TYPES_FAILED),
    //---------------------------------------------------------------------
    resetForm: createAction(types.RESET_FORM),
    resetPackages : createAction(types.RESET_PACKAGES),

    //-------------------------PROMOTIONAL PACKAGES-----------------
    getPromotionalPackages : createAction(types.GET_PROMOTIONAL_PACKAGES),
    getPromotionalPackagesSuccess : createAction(types.GET_PROMOTIONAL_PACKAGES_SUCCESS),
    getPromotionalPackagesFailed : createAction(types.GET_PROMOTIONAL_PACKAGES_FAILED),

    filterPackages : createAction(types.FILTER_PACKAGES),
}

