import React from 'react'
import { Col } from "react-bootstrap";
import dummy from '../../util/dummy.json'
import FeaturedPackage from '../LandingPageComponents/FeaturedPackage'
import { LoggedInPackage } from './DummyComponent'
import { getLoggedInStatus } from '../../util/ArrayValidator';

export default function DummyPackageView() {
    // if (!!localStorage.jwt) {
    var isLoggedIn = getLoggedInStatus();
    if (isLoggedIn) {
        return (
            <Col key={0} xs={12} md={isLoggedIn ? 12 : 6} lg={isLoggedIn ? 12 : 4}>
                <LoggedInPackage />
            </Col>
        )
    } else {
        return dummy.package.slice(0, isLoggedIn ? 1 : 3).map((featuredPackage, index) => {
            if (featuredPackage.status === "PRODUCT_STATUS_ACTIVE") {
                return <>
                    <Col
                        key={featuredPackage.id}
                        xs={12}
                        md={isLoggedIn ? 12 : 6}
                        lg={isLoggedIn ? 12 : 4}
                    >
                        <FeaturedPackage
                            id={index}
                            imagePath={featuredPackage.imageURL}
                            // id={`${featuredPackage.id}#${featuredPackages.productTypeId}`}
                            name={featuredPackage.name}
                            isLoggedIn={isLoggedIn}
                            onClick={() => console.log("Loading....")}
                            price={featuredPackage.purchaseOption && featuredPackage.purchaseOption.defaultPrice}
                            isDummy={true}
                        />
                    </Col>
                </>
            }
            return null
        })
        // }
    }
}
