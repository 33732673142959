import React from 'react'
import ContentLoader from "react-content-loader"
import { primaryColor, secondaryColor, cutomerBtnprimaryColor, cutomerBtnsecondaryColor } from '../../util/Constants';


//All ChannelPage category n six cards
export const DummyCategoryLabel = () => (
    <ContentLoader
        height={258}
        // width={1110}
        width={1028}
        speed={2}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
    >
        <rect x="0" y="0" rx="0" ry="0" width="142" height="6" />
        <rect x="0" y="20" rx="0" ry="0" width="158" height="222" />
        <rect x="174" y="20" rx="0" ry="0" width="158" height="222" />
        <rect x="348" y="20" rx="0" ry="0" width="158" height="222" />
        <rect x="522" y="20" rx="0" ry="0" width="158" height="222" />
        <rect x="696" y="20" rx="0" ry="0" width="158" height="222" />
        <rect x="870" y="20" rx="0" ry="0" width="158" height="222" />

    </ContentLoader>
)

//All ChannelPage Filter
export const DummyFilter = () => (
    <ContentLoader
        height={9}
        width={100}
        speed={2}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
    >
        <rect x="0" y="0" rx="0" ry="0" width="50%" height="6" />
    </ContentLoader>
)

//All Databundle page
export const DatabundlePlaceHolder = () => (
    <ContentLoader
        height={250}
        width={400}
        speed={2}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
    >
        <rect x="0" y="0" rx="0" ry="0" width="350" height="222" />
    </ContentLoader>
)

//Purchased Databundle PlaceHolder
export const PurchasedDatabundlePlaceHolder = () => (
    <ContentLoader
        height={154}
        width={285}
        speed={2}
        primaryColor="#252525"
        secondaryColor="#313131"
    >
        <rect x="20%" y="0" rx="5" ry="5" width="170" height="155" />
    </ContentLoader>
)

//All SVOD page
export const DummySvodCard = () => (
    <ContentLoader
        height={250}
        width={1110}
        speed={2}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
    >
        <rect x="0" y="0" rx="0" ry="0" width="20%" height="12" />
        <rect x="0" y="18" rx="0" ry="0" width="1106" height="192" />
    </ContentLoader>
)

//Featured SVOD Card
export const FeaturedSVODCard = () => (
    <ContentLoader
        height={120}
        width={350}
        speed={2}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
    >
        <rect x="-4" y="6" rx="3" ry="3" width="355" height="120" />
    </ContentLoader>
)

//All Device page
export const DeviceCardPlaceholder = () => (
    <ContentLoader
        height={250}
        width={1110}
        speed={2}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
    >
        <rect x="0" y="0" rx="0" ry="0" width="169" height="15" />
        <rect x="15" y="45" rx="5" ry="5" width="244" height="148" />
    </ContentLoader>
)

//All VOD page VOD Row
// All SVOD -> All VOD Page
export const DummyVodRow = () => (
    <ContentLoader
        height={232}
        // width={1110}
        width={1028}
        speed={2}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
    >
        <rect x="0" y="16" rx="0" ry="0" width="158" height="222" />
        <rect x="174" y="16" rx="0" ry="0" width="158" height="222" />
        <rect x="348" y="16" rx="0" ry="0" width="158" height="222" />
        <rect x="522" y="16" rx="0" ry="0" width="158" height="222" />
        <rect x="696" y="16" rx="0" ry="0" width="158" height="222" />
        <rect x="870" y="16" rx="0" ry="0" width="158" height="222" />

    </ContentLoader>
)

//All VOD page Filter
export const VODFilterPlaceholder = () => (
    <ContentLoader
        height={12}
        width={400}
        speed={2}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
    >
        <rect x="0" y="0" rx="0" ry="0" width="50" height="10" />
        <rect x="52" y="0" rx="0" ry="0" width="50" height="10" />
    </ContentLoader>
)

// All SVOD -> All VOD Page SVOD Name
export const DummySvodName = () => (
    <ContentLoader
        height={50}
        // width={1110}
        width={1028}
        speed={2}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
    >
        <rect x="0" y="16" rx="0" ry="0" width="40%" height="40" />

    </ContentLoader>
)

// All SVOD -> All VOD Page SVOD Price Tag
export const DummySvodPrice = () => (
    <ContentLoader
        height={70}
        // width={1110}
        width={200}
        speed={2}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
    >
        <rect x="0" y="16" rx="0" ry="0" width="100%" height="30" />

    </ContentLoader>
)

// All SVOD -> All VOD Page Description
export const DummySvodDescription = () => (
    <ContentLoader
        height={40}
        width={1110}
        speed={2}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
    >
        <rect x="0" y="0" rx="0" ry="0" width="100%" height="8" />
        <rect x="0" y="12" rx="0" ry="0" width="100%" height="8" />
        <rect x="0" y="24" rx="0" ry="0" width="70%" height="8" />
    </ContentLoader>
)

//-----------------------------------------------------------------
//                          Product page
//-----------------------------------------------------------------
//product page title
export const ProductPageTitle = () => (
    <ContentLoader
        height={9}
        width={100}
        speed={2}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
    >
        <rect x="0" y="0" rx="0" ry="0" width="50%" height="6" />
    </ContentLoader>
)

//product page description 
export const ProductPageDescription = () => (
    <ContentLoader
        height={80}
        width={1110}
        speed={2}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        style={{ marginTop: '-60px' }}
    >
        <rect x="0" y="5" rx="0" ry="10" width="100%" height="8" />
        <rect x="0" y="17" rx="0" ry="10" width="100%" height="8" />
        <rect x="0" y="29" rx="0" ry="10" width="70%" height="8" />
    </ContentLoader>

)

//product page additional details
export const ProductAdditionDetailsPlaceholder = () => (
    <ContentLoader
        // height={110}
        // width={400}
        speed={2}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
    >
        <rect x="0" y="2" rx="5" ry="5" width="400" height="74" />
    </ContentLoader>
)

//product page card
export const ProductCardPlaceholder = () => (
    <ContentLoader
        height={195}
        width={135}
        speed={2}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
    >
        <rect x="-23" y="3" rx="5" ry="5" width="400" height="400" />
    </ContentLoader>
)

//Product page custom button placeholder
export const CustomButtonPlaceholder = () => (
    <ContentLoader
        height={70}
        width={1115}
        speed={2}
        primaryColor={cutomerBtnprimaryColor}
        secondaryColor={cutomerBtnsecondaryColor}
    >
        <rect x="1000" y="0" rx="3" ry="3" width="100" height="35" />
    </ContentLoader>
)

//-----------------------------------------------------------------
//                          Featured Package
//-----------------------------------------------------------------
//Logged IN Featured Package
export const LoggedInPackage = () => (
    <ContentLoader
        height={520}
        width={1110}
        speed={2}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
    >
        <rect x="0" y="70" rx="5" ry="5" width="540" height="360" />
        <rect x="0" y="450" rx="5" ry="5" width="540" height="68" />
        <rect x="560" y="450" rx="5" ry="5" width="60" height="68" />

        <rect x="600" y="70" rx="0" ry="0" width="100%" height="12" />
        <rect x="600" y="86" rx="0" ry="0" width="100%" height="12" />
        <rect x="600" y="102" rx="0" ry="0" width="100%" height="12" />
        <rect x="600" y="118" rx="0" ry="0" width="100%" height="12" />
        <rect x="600" y="134" rx="0" ry="0" width="20%" height="12" />
    </ContentLoader>
)

//Before Login Featured Package
export const BeforeLogInPackages = () => (
    <ContentLoader
        height={191}
        width={286}
        speed={2}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
    >
        <rect x="-4" y="6" rx="3" ry="3" width="355" height="120" />
    </ContentLoader>
)

//-----------------------------------------------------------------
//                          ProgressiveImageComponent
//-----------------------------------------------------------------
//ProgressiveImageComponent card
export const DummyCard = () => (
    <ContentLoader
        height={239}
        width={166}
        speed={2}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
    >
        <rect x="0" y="0" rx="5" ry="5" width="155" height="239" />
    </ContentLoader>
)

export const CustomerSelectionCard = () => (
    <ContentLoader
        height={20}
        width={100}
        speed={2}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
    >
        <rect x="0" y="0" rx="0" ry="0" width="100%" height="20" />
    </ContentLoader>
)

//discount price slot
export const DiscountPriceSlot = () => (
    <ContentLoader
        height={9}
        width={100}
        speed={2}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
    >
        <rect x="0" y="0" rx="0" ry="0" width="100%" height="8" />
    </ContentLoader>
)

//Loader for package purchase
export const PurchaseLoader = () => (
    <ContentLoader
        height={125}
        width={700}
        speed={2}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
    >
        <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
    </ContentLoader>
)
export const DummyProfile = () => (
    <ContentLoader
        height={250}
        width={1110}
        speed={2}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
    >
        <rect x="0" y="18" rx="0" ry="0" width="1106" height="250" />
    </ContentLoader>
)