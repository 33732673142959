import React from 'react'
import { Container } from 'react-bootstrap';
import { PurchaseLoader } from './DummyComponent'

export default function DummyLoadingPage({ type, purchaseStatus, user }) {
    return (
        <div className="mobitel-loading-outer">
            <div className="mobitel-loading-inner">
                <Container style={{ color: "#d0d0d0", marginTop: 40 }}>
                    <div className="row" style={{ marginLeft: "-2px" }}>
                        <img src={require('../../images/mobitel-logo.png')} alt="mobitel-logo" />
                    </div>
                    <div className="row">
                        <div
                            style={{
                                color: "#4fa1d9",
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            <p className="text-sm" style={{ fontSize: 'medium', fontWeight: 'bold' }}>

                                Payment Processing....
                        </p>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col" >
                            <PurchaseLoader />
                        </div>
                    </div>
                </Container>
                <Container >
                    <p style={{ position: "fixed", bottom: "10%", marginLeft: "-25px" }}>Please do not close this window.</p>
                </Container>
            </div >
        </div>
    )
}
