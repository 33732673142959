import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux'
import { InputField } from '../../components/controls/Fields'
import { CustomButton } from '../../components/controls/CustomButton'
import { ErrorMessage } from '../../components/customMessages/ErrorMessage'
import './../login/LoginStyle.scss';
import { registrationActions, registrationSelectors } from './ducks'
import { getConnectionType } from '../../util/ArrayValidator';

class TelevisionCustomer extends Component {
    constructor() {
        super();

        this.state = {
            detailsError: undefined,
            loading: false,
            userType: undefined,


        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.getDistricts()
        let type = getConnectionType()
        let user = JSON.parse(localStorage.getItem('@user'))

        this.setState({
            userType: type
        })
        this.props.initialize(user);
    }
    static getDerivedStateFromProps(nextProps, state) {
        if (nextProps.detailsError && nextProps.detailsError !== state.detailsError) {
            return {
                detailsError: nextProps.detailsError,
                loading: false
            }
        }

        if (nextProps.detailsError === undefined) {
            return {
                detailsError: undefined
            }
        }

        return null;
    }

    initialState = () => {
        this.setState({
            detailsError: undefined
        })
    }

    componentWillUnmount() { }


    handleSubmit(values) {
        this.setState({
            loading: true
        })
        const { name, mobile, email, district, telephoneNumber } = values;
        var res = district.split(",");
        var districtSubmit = {
            id: res[1],
            districtName: res[0]
        }
        this.props.nonSltDetailsSubmit({
            name,
            mobile,
            email,
            district: districtSubmit,
            sltNumber: telephoneNumber
        })
    }

    render() {
        const { loading, userType, detailsError } = this.state
        const { handleSubmit, touched, error, warning, districts } = this.props;

        return (
            <div className="container card-page detail-gathering">
                <div className="card col-sm-12">
                    <h5> <span class="text-center" style={{ color: "#ffffff" }}>Please enter your details to start enjoying this service</span></h5><br />
                    <form className="register-form" onSubmit={handleSubmit(this.handleSubmit)}>
                        {userType !== 6 &&
                            <div className="form-group row">
                                <label class="col-sm-12 col-md-4 col-form-label" > SLT Number
                          </label>
                                <div className="col-sm-12 col-md-8">
                                    <Field
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="telephoneNumber"
                                        component={InputField}
                                        disabled
                                    />
                                </div>
                            </div>
                        }
                        <div className="form-group row required">
                            <label class="col-sm-12 col-md-4 col-form-label" >District</label>
                            <div className="col-sm-12 col-md-8" >
                                <Field
                                    className="input-group sm-2 input-group-sm"
                                    placeholder="Select a district"
                                    name="district"
                                    component={InputField}
                                    type="select"
                                    style={{ height: 30, border: "1px solid #ffffff", borderRadius: 4 }}
                                >

                                    <option className="form-control" value="" disabled selected>Select a district</option>
                                    {districts && Array.isArray(districts) && districts.length !== 0 && districts.map(item => {
                                        var obj = [item.districtName, item.id]
                                        // var value= {districtName: item.districtName, id: item.id}
                                        return <option id={item.id} value={obj}>{item.districtName}</option>
                                    })}

                                    {touched &&
                                        ((error &&
                                            <div class="invalid-feedback" style={{ display: 'block' }}>
                                                {error}
                                            </div>
                                        ) ||
                                            (warning && <span>{warning}</span>))}
                                </Field>
                            </div>
                        </div>
                        <div className="form-group row required">
                            <label class="col-sm-12 col-md-4 col-form-label" required>Name
                            </label>
                            <div className="col-sm-12 col-md-8">
                                <Field
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    name="name"
                                    component={InputField}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label class="col-sm-12 col-md-4 col-form-label" > Mobile <img src={require("../../images/icons/icon.svg")} style={{ width: "25px", marginLeft: -9, paddingLeft: 3 }} alt="info" />
                            </label>
                            <div className="col-sm-12 col-md-8">
                                <Field
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    name="mobile"
                                    component={InputField}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label class="col-sm-12 col-md-4 col-form-label" >E-mail <img src={require("../../images/icons/icon.svg")} style={{ width: "25px", marginLeft: -9, paddingLeft: 3 }}  alt="info"/></label>
                            <div className="col-sm-12 col-md-8">
                                <Field
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    name="email"
                                    component={InputField}
                                />
                            </div>
                        </div>
                        <div class="form-group row btn-group">
                            <CustomButton disabled={loading} loading={loading} onClick={this.handleConfirm} text="Proceed" />
                            <p style={{ fontSize: 13, textAlign: 'center', width: '100%', marginBottom: 0, marginTop: 10 }}>
                                <span style={{ color: '#e32' }}>* </span>Required Fields</p>
                            <p style={{ fontSize: 13, textAlign: 'center', width: '100%' }}>
                                <img src={require("../../images/icons/icon.svg")} style={{ width: "25px", marginLeft: 20, }}  alt="info" />Either mobile or e-mail is required</p>
                        </div>

                    </form>
                    <ErrorMessage
                        errorStatus={detailsError}
                        errorMessage={detailsError}
                    />


                </div>
            </div>
        )
    }
}
const validate = values => {
    const errors = {}
    if (!values.name) {
        errors.name = 'Name is required'
    }
    if ((!values.mobile) && (!values.email)) {
        errors.mobile = 'Either mobile or e-mail is required'
        errors.email = 'Either mobile or e-mail is required'
    }
    if (!values.district) {
        errors.district = 'District is required'
    }

    if ((values.email) && (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))) {
        errors.email = 'Invalid email'
    }

    if (values.mobile && values.mobile && (!/^07[0-9]{8}$/i.test(values.mobile))) {
        errors.mobile = 'Invalid mobile'
    }
    return errors;
}

const mapStateToProps = (state) => {
    return {
        ...registrationSelectors.getDistricts(state),
        ...registrationSelectors.submitDetails(state),
        ...registrationSelectors.getErrors(state)

    }
};


export default reduxForm({
    form: 'profile',
    validate,
    //enableReinitialize: true,
})(connect(mapStateToProps, registrationActions)(TelevisionCustomer))

