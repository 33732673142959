import React, { Component } from 'react';
import { singleProductActions, singleProductSelectors } from './ducks';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import history from '../../_helpers/history';
import ProductView from '../../components/ProductPageView/ProductPageView';
import './ProductPage.scss';
import { getNestedValue, getLoggedInStatus, getConnectionId, getServiceType, getPurchaseOptionAmount, getIndex, getBasepackage, getProductPrice, validArray, getRegion, getUtilizationPeriod } from '../../util/ArrayValidator';
// import {DiscountPrices} from '../../components/DiscountPrices/DiscountPrices';
import { PRODUCT, USER, TRANSACTIONAL, PREPAID_SERVICE_TYPE, SCREEN_PRODUCT_TYPE_TYPE_NAME } from '../../util/Constants';
import { DiscountPriceComponent } from '../../components/DiscountPriceComponent/DiscountPriceComponent';
import { DiscountPrices } from '../../components/DiscountPrices/DiscountPrices';
import { Payhere } from '../../util/PayhereScripts'
export class ProductPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            productDetails: null,
            productDetailsPurchase: true
        }
    }

    componentDidMount() {
        let getBasePackageId = getBasepackage()
        let id = this.props.match && this.props.match.params.id
        let price = Boolean(this.props.match && this.props.match.params.purchasable);
        this.setState({ purchasable: price })
        if (getLoggedInStatus()) {
            if (!!getConnectionId()) {
                const params = this.props.location.state && this.props.location.state.dataBundle
                this.props.getBundledProducts({ productId: id, basePackageId: getBasePackageId })
                this.props.getProduct({ productId: id, connectionId: getConnectionId(), dataBundle: params });

                if (this.props.location && this.props.location.search) {
                    let purchaseOptionId = this.props.location.search.split("=")[1];

                    this.setState({
                        purchaseOption: purchaseOptionId
                    })

                    this.getPriceOnPromotions();
                }
            } else {
                history.push("/")
            }
        }
        else {
            this.props.getProduct(id)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (getServiceType() === PREPAID_SERVICE_TYPE && !this.props.purchaseLoading && !this.props.payherePurchaseStatus && this.props.payhereDetails !== null && this.state.showPayhere && this.state.notifyStatus) {
            // let id = this.props.match && this.props.match.params.id
            const { payhereDetails, purchaseOptions, userDetails } = this.props;

            let payementStatus = Payhere(purchaseOptions, userDetails, payhereDetails.transactionDetails, this.state.purchaseOption)
            window.payhere.onCompleted = (orderId) => {

                console.log("Payment completed. OrderID:" + orderId)
                this.getPurchaseSatus(payhereDetails.transactionDetails.transactionId)
                //Note: validate the payment and show success or failure page to the customer
            };

            // Called when user closes the payment without completing
            window.payhere.onDismissed = () => {
                //Note: Prompt user to pay again or show an error page
                console.log("Payment dismissed");
                this.closeNotify()

            };

            // Called when error happens when initializing payment such as invalid parameters
            window.payhere.onError = (error) => {
                // Note: show an error page
                console.log("Error:" + error);
                // this.getPurchaseSatusDissmissed()
            };

            window.payhere.startPayment(payementStatus);
        }
        //carousel resize issue fixed on bundled products carousel slider arrows not working
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 0);
    }

    componentWillUnmount() {
        this.props.clearProductDetails();
        this.setState({
            password: undefined,
            purchaseOption: undefined,
            purchaseOptionValues: undefined
        });
    }

    onClick = (e) => {
        this.setState({ show: true, selectedImage: e.target.id });
        this.props.getproductDetailss(e.target.id)
    };
    onClose = () => {
        this.setState({ showModal: false, showModals: false })
    }

    onClickOk = (e) => {
        this.setState({
            showModal: false,
            showModals: true
        })
    }

    closeNotify = () => {
        // debugger
        this.setState({
            notifyStatus: false,
            purchaseOption: undefined,
            password: undefined
        })
        this.props.clearPurchaseMessage();
        this.props.getPrepaidPurchaseStatusLoading()
        this.setState({
            password: null,
            purchaseOption: null,
            showPayhere: false,
            purchaseOptionValues: undefined
        })
    }

    handleShow = () => {
        this.setState({
            showModal: true,
            purchaseOption: this.props.productDetails && this.props.productDetails.purchaseOption && this.props.productDetails.purchaseOption.id
        })

    }

    onCloseUnsub = () => {
        this.setState({ showUnsub: false })
    }

    handleShowUnsub = () => {
        this.setState({ showUnsub: true })
    }

    onClickYes = () => {
        const { productDetails, purchaseOptions } = this.props;
        let id = getNestedValue(productDetails, ['id']);
        let productTypeId = getNestedValue(productDetails, ['productTypeId']);
        let productPOGroupId = getNestedValue(productDetails, ['purchaseOptionGroupId']);
        // let amount = getNestedValue(productDetails, ['purchaseOption', 'defaultPrice']);
        let amount
        let purchaseOptionId
        let selectedPurchaseOptionGID
        let purchaseOptionIndex = parseInt(this.state['purchaseOptionValues'])
        let params = this.props.location.state && this.props.location.state.dataBundle

        let defaultPurchaseOptionType = getNestedValue(productDetails, ['purchaseOption', 'purchaseType']);

        if (purchaseOptionIndex) {
            var value = getIndex(purchaseOptions, purchaseOptionIndex)
            selectedPurchaseOptionGID = getNestedValue(value, ['purchaseOptionGroupId'])
            amount = getProductPrice(productDetails);
            purchaseOptionId = value && value.id
        } else {
            purchaseOptionId = getNestedValue(productDetails, ['purchaseOption', 'id']);
            selectedPurchaseOptionGID = getNestedValue(productDetails, ['purchaseOptionGroupId']);
            amount = getProductPrice(productDetails);
        }
        this.setState({
            showModal: false,
            showModals: false,
            notifyStatus: true,
            showPayhere: true
        })
        this.props.productSubscribe({
            // connectionId: this.props.connectionDetails && Array.isArray(this.props.connectionDetails) && this.props.connectionDetails[localStorage.selectedUserIndex].connectionId,
            connectionId: getConnectionId(),
            pin: this.state["password"],
            productId: id,
            productType: productTypeId,
            purchaseOption: purchaseOptionId,
            amount: amount,
            productPOGroupId: productPOGroupId,
            selectedPurchaseOptionGID: selectedPurchaseOptionGID,
            defaultPurchaseOptionType: defaultPurchaseOptionType,
            dataBundle: params
            // amount: getPurchaseOptionAmount(purchaseOptions, this.state["purchaseOption"])
        });
        this.setState({
            password: null
        });
        // if(getServiceType()){ 
        //     this.Payhere()
        // }
    }

    onClickYesUnsub = () => {
        const { productDetails, purchaseOptions } = this.props;
        const id = getNestedValue(productDetails, ['id']);
        const productTypeId = getNestedValue(productDetails, ['productTypeId']);
        let purchaseOptionId
        let purchaseOptionIndex = this.state['purchaseOptionValues']
        let params = this.props.location.state && this.props.location.state.dataBundle
        if (purchaseOptionIndex) {
            var value = getIndex(purchaseOptions, purchaseOptionIndex)
            purchaseOptionId = value.id
        } else {
            purchaseOptionId = getNestedValue(productDetails, ['purchaseOption', 'id']);
        }

        const purchaseId = getNestedValue(productDetails, ['purchaseId'])
        let defaultPurchaseOptionType = getNestedValue(productDetails, ['purchaseOption', 'purchaseType']);

        this.setState({
            showUnsub: false,
            notifyStatus: true
        })
        this.props.productUnsubscribe({
            // connectionId: this.props.connectionDetails && Array.isArray(this.props.connectionDetails) && this.props.connectionDetails[localStorage.selectedUserIndex].connectionId,
            connectionId: getConnectionId(),
            productId: id,
            productType: productTypeId,
            purchaseOption: purchaseOptionId,
            purchaseId: purchaseId,
            defaultPurchaseOptionType: defaultPurchaseOptionType,
            dataBundle: params
        })
    }

    onClickNo = () => {
        this.setState({
            showModal: false,
            showModals: false,
            showUnsub: false,
            password: null,
            showPayhere: false
        })
    }

    handleChangePurchaseOptions = (e) => {
        const { purchaseOptions, getProductDetailsWithPurchaseOption } = this.props;
        let id = this.props.match && this.props.match.params.id
        this.setState({
            [e.target.id]: e.target.value, selectedIndex: e.target.value
        });
        let selectedPurchaseOptionId = purchaseOptions && purchaseOptions[e.target.value].id;
        getProductDetailsWithPurchaseOption({
            productId: id,
            connectionId: getConnectionId(),
            purchaseOptionId: selectedPurchaseOptionId
        })
    }

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value })
    }

    static getDerivedStateFromProps(nextProps, state) {
        let newProps = {}

        if (nextProps.productDetails && nextProps.productDetails !== state.productDetails) {
            newProps.productDetails = nextProps.productDetails
        }

        if (nextProps.subscribe !== null) {
            newProps.purchaseStatus = nextProps.purchaseStatus
        }

        if (nextProps.payhereDetails !== null) {
            newProps.payhereDetails = nextProps.payhereDetails
        }

        if (nextProps.location && nextProps.location.search && state.productDetailsPurchase && (nextProps.productDetailsLoading === false)) {
            if (!nextProps.productDetails.isBundled) {
                debugger
                if (getServiceType()) {
                    if (nextProps.productDetails.purchaseStatus === "NOT_PURCHASED") {
                        newProps.showModal = true
                        newProps.productDetailsPurchase = false
                    }
                } else {
                    if (nextProps.productDetails.purchaseStatus === "NOT_PURCHASED" || nextProps.productDetails.purchaseStatus === "PURCHASE_PENDING_REMOVE") {
                        newProps.showModal = true
                        newProps.productDetailsPurchase = false
                    }
                }
            }
        }

        return {
            ...newProps
        };
    }

    getPurchaseSatus = (transactionId) => {
        this.setState({ showPayhere: false })
        let id = this.props.match && this.props.match.params.id
        this.props.getPrepaidPurchaseStatus({ transactionId: transactionId, productId: id })

    }

    getPriceOnPromotions = () => {
        const { getProductDetailsWithPurchaseOption } = this.props;
        let id = this.props.match && this.props.match.params.id;
        let selectedPurchaseOptionId = this.props.location.search.split("=")[1];
        getProductDetailsWithPurchaseOption({
            productId: id,
            connectionId: getConnectionId(),
            purchaseOptionId: selectedPurchaseOptionId
        })
    }

    render() {
        const { productDetails, showModal, showModals, password, showUnsub, notifyStatus, selectedIndex } = this.state;
        const { productDetailsLoading, purchaseOptions, purchaseLoading, getPurchaseStatus, getProductPurchaseStatus, payherePurchaseStatus, payherePurchaseStatusLoading, productPurchaseStatusError, discountLoading, bundledProducts, submitBtnLoading, referenceId } = this.props;
        let defaultPrice = parseFloat(getNestedValue(productDetails, ['discountOptionGroup', 'purchasePrice'])).toFixed(2);
        let selectedPurchaseOptionDefaultPrice = getPurchaseOptionAmount(purchaseOptions, this.state["purchaseOption"])
        let bundledStatus = getNestedValue(productDetails, ['isBundled']);
        let purchaseStatus = getNestedValue(productDetails, ['isPurchased']);
        let productTypeTypeName = getNestedValue(productDetails, ['productTypeTypeName']);
        let absoluteDiscount = getNestedValue(productDetails, ['purchaseOption', 'absoluteDiscount'])
        let defaultPurchaseOptionType = productTypeTypeName === SCREEN_PRODUCT_TYPE_TYPE_NAME ? getNestedValue(productDetails, ['purchaseType']) : getNestedValue(productDetails, ['purchaseOption', 'purchaseType']);
        let serviceType = getServiceType();
        let regionType = getRegion(productDetails && productDetails.regions)
        let priceDisplay, customButtonText

        let showPeriod, showUtilizationPeriod;
        let utilizationPeriodUnit = getNestedValue(productDetails && productDetails.purchaseOption, ['utilization', 'utilizationPeriodUnit'])
        //show the utilization period of the purchase option
        showUtilizationPeriod = getUtilizationPeriod(productDetails, utilizationPeriodUnit)

        let discountLevelProduct, discountLevelUser;
        if (productDetails && productDetails.discountOptionGroup && productDetails.discountOptionGroup.discountOptions) {
            discountLevelProduct = productDetails.discountOptionGroup && productDetails.discountOptionGroup.discountOptions.filter(productDetails => productDetails.discountLevel === PRODUCT);
            discountLevelUser = productDetails.discountOptionGroup && productDetails.discountOptionGroup.discountOptions.filter(productDetails => productDetails.discountLevel === USER);
        }
        let pricing
        if (productDetails && productDetails.discountOptionGroup) {
            if (productDetails.discountOptionGroup.discountOptions && validArray(productDetails.discountOptionGroup.discountOptions)) {
                pricing =
                    <div className="product-purchase-content">
                        <div className="price-box">
                            <DiscountPrices price={productDetails.discountOptionGroup.purchasePrice === null ? productDetails.discountOptionGroup.actualPrice : productDetails.discountOptionGroup.purchasePrice} showTax={true} oldPrice={
                                productDetails.discountOptionGroup.actualPrice} precentage={
                                    productDetails.purchaseOption.discountPercentage} productPeriod={showPeriod} discountLevelProduct={discountLevelProduct && discountLevelProduct[0]} discountLevelUser={discountLevelUser && discountLevelUser[0]}
                                discountPriceLoading={false} type="package" isProduct={true} purchaseOptionType={productDetails.purchaseOption.purchaseType} showUtilizationPeriod={showUtilizationPeriod} utilizationPeriodUnit={utilizationPeriodUnit}
                            />
                        </div>
                    </div>
            } else {
                pricing = <div className="price-box"><DiscountPriceComponent price={productDetails && productDetails.discountOptionGroup && productDetails.discountOptionGroup.purchasePrice} showTax={true} period={showUtilizationPeriod} /></div>
            }
        }

        if (getLoggedInStatus()) {
            if (productDetails && (!bundledStatus && !purchaseStatus)) {
                if (defaultPrice === 0) {
                    priceDisplay = <div style={{ display: 'grid' }}> <h4>Rs. 0 +tax</h4></div>
                } else {
                    priceDisplay = pricing
                }
            }
        } else {
            if (this.props.match && this.props.match.params.purchasable === "true") {
                priceDisplay = <div style={{ display: 'grid' }}>
                    {/* <h4>{"Rs." + defaultPrice + " +tax"}</h4> */}
                    {/* <DiscountPriceComponent price={defaultPrice} showTax={true} oldPrice={absoluteDiscount} precentage={discountPercentage} period={showPeriod} /> */}
                </div>
            } else {
                priceDisplay = null
            }
        }

        if (defaultPurchaseOptionType === TRANSACTIONAL) {
            customButtonText = "PURCHASE";
        } else {
            customButtonText = "SUBSCRIBE";
        }

        //set the purchase option id of the promotion for the default PO to auto select on purchase window
        if (this.props.location && this.props.location.search) {
            let purchaseOptionId = this.props.location.search.split("=")[1];
            if (productDetails) {
                productDetails.purchaseOption.id = purchaseOptionId;
            }
        }
        return (
            <ProductView
                productDetails={productDetails}
                productDetailsLoading={productDetailsLoading}
                priceDisplay={priceDisplay}
                isLoggedIn={getLoggedInStatus()}
                handleShowUnsub={this.handleShowUnsub}
                handleShow={this.handleShow}
                showModal={showModal}
                onClose={this.onClose}
                onClickOk={this.onClickOk}
                handleChange={this.handleChange}
                handleChangePurchaseOptions={this.handleChangePurchaseOptions}
                purchaseOptions={purchaseOptions}
                showModals={showModals}
                onClickYes={this.onClickYes}
                onClickNo={this.onClickNo}
                password={password}
                showUnsub={showUnsub}
                onCloseUnsub={this.onCloseUnsub}
                onClickYesUnsub={this.onClickYesUnsub}
                notifyStatus={notifyStatus}
                closeNotify={this.closeNotify}
                purchaseLoading={purchaseLoading}
                getPurchaseStatus={getPurchaseStatus || productPurchaseStatusError}
                getProductPurchaseStatus={getProductPurchaseStatus}
                productType={productTypeTypeName}
                customButtonText={customButtonText}
                absoluteDiscount={absoluteDiscount}
                serviceType={serviceType}
                defaultPrice={selectedPurchaseOptionDefaultPrice && selectedPurchaseOptionDefaultPrice}
                payherePurchaseStatus={payherePurchaseStatus}
                payherePurchaseStatusLoading={payherePurchaseStatusLoading}
                selectedIndex={selectedIndex}
                discountPriceLoading={discountLoading}
                bundledProducts={bundledProducts}
                submitBtnLoading={submitBtnLoading}
                referenceId={referenceId}
                showUtilizationPeriod={showUtilizationPeriod}
                regionType={regionType}
                defaultPurchaseOptionType={defaultPurchaseOptionType}
                utilizationPeriodUnit={utilizationPeriodUnit}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        productDetails: singleProductSelectors.getProductDetails(state),
        productDetailsLoading: singleProductSelectors.getProductDetailsLoading(state),
        purchaseOptions: singleProductSelectors.getPurchaseOptions(state),
        purchaseLoading: singleProductSelectors.purchaseLoading(state),
        getPurchaseStatus: singleProductSelectors.getPurchaseStatus(state),
        getProductPurchaseStatus: singleProductSelectors.getProductPurchaseStatus(state),
        getBasePackageId: singleProductSelectors.getBasePackageId(state),
        bundledProducts: singleProductSelectors.getBundledProducts(state),
        payhereDetails: singleProductSelectors.getPayhereDetails(state),
        userDetails: singleProductSelectors.getUserDetails(state),
        discountLoading: singleProductSelectors.getDiscountPriceLoading(state),
        ...singleProductSelectors.payherePurchaseStatus(state),
        submitBtnLoading: singleProductSelectors.getBtnLoadingStatus(state),
        referenceId: singleProductSelectors.getReferenceId(state)
    }
}

export default withRouter(connect(mapStateToProps, singleProductActions)(ProductPage))
