import React from 'react'
import { CustomCarousel } from '../carousel/CustomCarousel'
import dummy from '../../util/dummy.json'

export default function DummyFeaturedVod({ renderCenterLeftControls, renderCenterRightControls, isLoggedIn, onClick }) {
    return (
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <CustomCarousel
                slidesToShow={(900 < window.innerWidth ? 3 : (550 < window.innerWidth ? 2 : (440 < window.innerWidth ? 2 : 1)))}
                renderCenterLeftControls={renderCenterLeftControls}
                renderCenterRightControls={renderCenterRightControls}
                renderBottomCenterControls={false}
                heightMode="last"
                slideWidth="185px"
                initialSlideHeight={(1200 < window.innerWidth ? 225 :
                    (990 < window.innerWidth ? 240 :
                        (900 < window.innerWidth ? 180 :
                            (770 < window.innerWidth ? 300 :
                                (550 < window.innerWidth ? 180 :
                                    (440 < window.innerWidth ? 210 :
                                        (330 < window.innerWidth ? 220 : 180)))))))}
                allProducts={dummy.featuredVod}
                informative={true}
                onDemand={true}
                onClick={onClick}
                isDummy={true}
            />
        </div>
    )
}
