import React from 'react'


export const PaymentFailed = ({ error, onClickBack, onClickRetry, retryError }) => {
    return <><div className="row" style={{
        color: "#fff", display: "flex",
        justifyContent: "center"
    }}>
        <ion-icon
            name="close-circle-outline"
            size="large"
            style={{
                color: "#B00020",
                size: "large"
            }}
        ></ion-icon>
    </div>

        <div className="row pament-status" >Payment Unsuccessful</div>
        <div className="row payment-info" style={{ textAlign: 'center' }}>{(error != null && error.message) || (retryError && retryError.message)}</div>
        {/* <div className="row"><CustomButton id="accountlogin" text="GO BACK" disabled={loading} loading={loading} /><CustomButton id="accountlogin" text="RETRY PAYMENT" disabled={loading} loading={loading} /></div> */}
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
            <> <button type="button" className="btn  go-back" onClick={onClickBack}>GO BACK</button>
                <button type="button" className="btn retry-payment" onClick={onClickRetry}
                    disabled={(error && error.status !== "RETRY") || (retryError && retryError.status !== "RETRY")}
                >RETRY PAYMENT</button></>
        </div>
    </>
}
