import {
    createSelector,
    createStructuredSelector
} from 'reselect';

const getBannerContentSelector = state => state.Promotions.bannerContent
const getBannerContentLoadingSelector = state => state.Promotions.bannerContentLoading
const getBannerContentErrorSelector = state => state.Promotions.bannerContentError
const getConnectionDetailsSelector = state => state.Products.connectionDetails
const getPromotionalPackagesSelector = state => state.Promotions.promotionalPackages
const getPromotionalPackagesLoadingSelector = state => state.Promotions.promotionalPackagesLoading
const getFeaturedPackagesSelector = state => state.Products.secondaryAllowedProducts
const getPurchaseOptionTableSelector = state => state.ProductDetails.purchaseTable
const getPurchasedDataSelector = state => state.ProductDetails && state.ProductDetails.productDetails
const getPromoPackProductsSelector = state => state.Promotions.promotionalPackageProduct
const getPromoPackProductsLoadingSelector = state => state.Promotions.promotionalPackageProductLoading

export default {
    getBannerContent: createStructuredSelector({
        bannerContent: getBannerContentSelector,
        error: getBannerContentErrorSelector,
        loading: getBannerContentLoadingSelector
    }),
    getConnectionDeatils: createSelector(
        getConnectionDetailsSelector,
        (value) => value
    ),
    getPromotionalPackages: createStructuredSelector({
        promotionalPackages: getPromotionalPackagesSelector,
        loading: getPromotionalPackagesLoadingSelector,
        promotionalPackageProductLoading: getPromoPackProductsLoadingSelector,
        promotionalPackageProduct: getPromoPackProductsSelector

    }),
    getFeaturedPackage: createSelector(
        getFeaturedPackagesSelector,
        (value) => value
    ),
    getPurchaseOptionTable: createSelector(
        getPurchaseOptionTableSelector,
        (value) => value
    ),
    getPurchasedData: createSelector(
        getPurchasedDataSelector,
        (value) => value
    )
}