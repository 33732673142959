import React from 'react'

function NoProductAvailable() {
    return (
        <div style={{ backgroundColor: "#42424250", padding: 20, margin: 20, borderRadius: 25, color: "#ffffff" }}>
                There is no product available
        </div>
    )
}

export default NoProductAvailable
