import React from 'react'
import { Container } from 'react-bootstrap';
import { ProductPageTitle } from './DummyComponent'
import { PREPAID_SERVICE_TYPE, MOBITEL_SERVICE_TYPE } from "../../util/Constants";

export default function DummyNotifyModal({ type, purchaseStatus, user, serviceType, purchaseType }) {
    let text, unsubContent
    if ((purchaseStatus === ("PURCHASE_COMPLETE") && purchaseType !== 'PURCHASE_TYPE_TRANSACTIONAL') || (user)) {
        text = "Unsubscribing";
        unsubContent = true
    }
    else if (purchaseType === "PURCHASE_TYPE_TRANSACTIONAL") {
        text = "Purchasing";
    } else {
        text = "Subscribing";
    }
    return (
        <div style={{ marginTop: 40 }}>
            <Container style={{ color: "#d0d0d0" }}>
                {serviceType === PREPAID_SERVICE_TYPE && !unsubContent && <div style={{ padding: "15px" }} class="text-center padder clearfix text-muted">
                    <div style={{ padding: "15px", fontSize: "13px" }}><img src={require("./../../images/lock.png")} width="12" alt="close" />Secured by</div>
                    <div><img src={require('./../../images/pay-here-logo.png')} height="34" alt="payhere" /></div>
                    <div style={{ padding: "15px" }} class="copy">Central Bank approved Secure Payment Gateway Service</div>
                </div>}
                {serviceType === MOBITEL_SERVICE_TYPE && !unsubContent && <div style={{ padding: "15px" }} class="text-center padder clearfix text-muted">
                    <div><img src={require('./../../images/mobitel-logo.png')} height="34" alt="mobitel" /></div>
                    <div style={{ padding: "15px" }} class="copy">Payment Processing...</div>
                </div>}
                <div className="row">
                    <div
                        className="col-sm-12"
                        style={{
                            color: "#4fa1d9",
                            display: "flex",
                            justifyContent: "center"
                        }}
                    >
                        <p className="text-sm">

                            {text}....
                        </p>

                    </div>
                </div>

                <div className="row" style={{ margin: "12px 0px 12px 0px", textAlign: "center" }}>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10 " style={{ left: "20%" }}>
                        <ProductPageTitle />
                    </div>
                </div>
                {serviceType === MOBITEL_SERVICE_TYPE && <div style={{ padding: "15px" }} class="text-center padder clearfix text-muted"> <div style={{ padding: "15px" }} class="copy">Please do not close this window.</div></div>}

                {!serviceType && <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10 d-flex justify-content-center">
                        <button
                            type="button"
                            className="btn"
                            disabled
                            style={{ margin: 0, backgroundColor: "#a0a0a0" }}
                        >
                            <p
                                style={{ color: "#000000", fontWeight: "bold", margin: "0" }}
                            >
                                Close
              </p>
                        </button>
                    </div>
                    <div className="col-sm-1"></div>
                </div>}
            </Container>
        </div>
    )
}
