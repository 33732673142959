import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { CustomButton } from "./../../components/controls/CustomButton";
import history from "./../../_helpers/history";
import { InputField } from "./../../components/controls/Fields";
import { registrationActions, registrationSelectors } from "./ducks";
import { ErrorMessage } from "./../../components/customMessages/ErrorMessage";
import { getLoggedInStatus, getConnectionType } from '../../util/ArrayValidator';
import dynamicDetailForm from '../../util/DynamicDetailForm.json'
import { MOBITEL_HEADER_ENRICHMENT } from "../../util/Constants";

class PersonalDetailsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitUserDetailsFailed: undefined,
      loading: false,
      userType: undefined
    };
    this.onSubmit = this.onSubmit.bind(this);
  }
  static getDerivedStateFromProps(nextProps, state) {
    let newProps = {};

    if (nextProps.submitUserDetailsFailed === undefined) {
      newProps.submitUserDetailsFailed = undefined;
    } else {
      if (nextProps.submitUserDetailsFailed !== state.submitUserDetailsFailed) {
        newProps.submitUserDetailsFailed = nextProps.submitUserDetailsFailed;
        newProps.loading = false;
      }
    }

    return {
      ...newProps
    };
  }

  onChange = (e) => {
    function forceInputUppercase(e) {
      e.target.value = e.target.value.toLowerCase();
    }
    document.getElementById("email").addEventListener("keyup", forceInputUppercase, false);
  }

  onSubmit(values) {
    this.setState({
      loading: true,
      submitUserDetailsFailed: undefined
    })
    let { userAccount } = this.props
    var user = getConnectionType();
    var countryName = this.props.country && this.props.country.country_name;
    var cityName = this.props.country && this.props.country.city_name;
    if (getLoggedInStatus()) {
      var details = {}
      switch (user) {
        case "7":
          details.firstName = values.firstName
          details.lastName = values.lastName
          details.country = countryName
          details.city = values.city_name
          details.mobileNo = (this.props.country && this.props.country.country_name === "Sri Lanka" ? `+${94}` + values.mobile : values.mobile)
          details.address = `${values.city_name}, ${countryName}`
          details.connectionType = parseInt(this.state.userType)
          // const connectionId = this.props.userAccount.connectionId
          // const { email, password } = values
          break;
        case "6":
          details.firstName = values.firstName
          details.lastName = values.lastName
          details.country = countryName
          details.city = cityName
          details.address = values.address
          details.mobileNo = values.mobile
          details.mobile = values.contactNo
          details.connectionType = parseInt(this.state.userType)
          userAccount.mobileNo = values.mobile
          // const connectionId = this.props.userAccount.connectionId
          // const { email, password } = values
          break;
        default:
          details = { accountNumber: userAccount.accountNumber, connectionType: parseInt(this.state.userType), telephoneNumber: userAccount.telephoneNumber, mobile: values.mobile }
      }
      this.props.submitDetails(details)
    }
    else {
      var res = values.email.toLowerCase();
      // var countryName = this.props.country && this.props.country.country_name;
      // var cityName = this.props.country && this.props.country.city_name;

      userAccount.password = values.password
      userAccount.confirmPassword = values.confirmPassword
      userAccount.email = res
      userAccount.connectionType = parseInt(this.state.userType)
      userAccount.mobileNo = values.mobile
      userAccount.mobile = values.mobile
      switch (user) {
        case "7":
          userAccount.firstName = values.firstName
          userAccount.lastName = values.lastName
          userAccount.country = countryName
          userAccount.city = values.cityName
          userAccount.mobileNo = (this.props.country && this.props.country.country_name === "Sri Lanka" ? `+${94}` + values.mobile : values.mobile)
          userAccount.address = `${values.city_name}, ${countryName}`
          // const connectionId = this.props.userAccount.connectionId
          // const { email, password } = values
          break;
        case "6":
          userAccount.firstName = values.firstName
          userAccount.lastName = values.lastName
          userAccount.country = countryName
          userAccount.city = cityName
          userAccount.address = values.address
          userAccount.mobileNo = values.mobile
          userAccount.mobile = values.contactNo
          // const connectionId = this.props.userAccount.connectionId
          // const { email, password } = values
          break;
        default:
        // const connectionId = this.props.userAccount.connectionId
        // const { email, password } = values 
      }
      this.props.submitDetails(userAccount);
    }
  }

  componentDidMount() {
    var user = getConnectionType()

    const { userAccount, country } = this.props;
    if (user !== "7") {
      this.props.initialize(userAccount);
    } else {
      this.props.initialize(country);
    }


    this.setState({ userType: user });
    if (user) {
      if ((user !== "7" && user !== "6") && Object.values(userAccount).every(o => o === undefined || o === "")) {
        history.push("/account-login");
      }
      if ((user === "7" && user !== "6") && Object.values(country).every(o => o === undefined || o === "")) {
        history.push("/customer/selection");
      }
      if (user !== "6" && userAccount && !/^07[0-9]{8}$/i.test(userAccount.mobile)) {
        userAccount.mobile = "";
      }
      else if (user === "6" && sessionStorage.mobitelNumber) {
        var s2 = sessionStorage.mobitelNumber && sessionStorage.mobitelNumber.substr(2);
        userAccount.mobile = "0" + s2
      }
    } else {
      history.push("/customer/selection");
    }
  }

  componentWillUnmount() {
    this.props.clearErrorMsg();
  }

  onClickLink = () => {
    window.location = MOBITEL_HEADER_ENRICHMENT
  }

  render() {
    const { handleSubmit } = this.props;
    const { loading, submitUserDetailsFailed, userType } = this.state
    let jwt = localStorage.jwt
    let formElements = null
    if (userType) {
      switch (userType) {
        case "1":
          formElements = dynamicDetailForm.peotv
          break;
        case "6":
          formElements = dynamicDetailForm.mobitel
          break;
        case "7":
          formElements = dynamicDetailForm.prepaid
          break;
        default:
          formElements = dynamicDetailForm.slt
      }
    }
    return (
      <div className={`container card-page ${jwt ? "personal-details-after-login" : "personal-details"}`}>
        <div className="card col-sm-12">
          <h4>
            <span className="fa fa-user fa-icon-header" />
            Personal Details
          </h4>
          <br />
          <form onSubmit={handleSubmit(this.onSubmit)}>
            {userType && formElements.map(v => {
              return <div className={`form-group row ${v.required ? "required" : null}`}>
                <label className="col-sm-12 col-md-4 col-form-label">
                  {v.label}{" "}
                  {v.name === "contactNo" && <span className="col-form-label" style={{ marginLeft: "10px" }}>(Mobile)</span>}
                </label>

                <div className="col-sm-12 col-md-8">
                  <Field
                    id={v.id}
                    type={v.type}
                    className="form-control"
                    placeholder={v.placeholder}
                    name={v.name}
                    disabled={v.disabled}
                    component={InputField}
                    userType={userType === "7" ? v.id === "mobile" && this.props.country &&
                      this.props.country.country_name === "Sri Lanka" ? "+94" : v.id === "mobile" && "+" : null}
                  />
                  {(userType === "6" && (v.name === "mobile" || v.name === "contactNo")) && <p style={{ fontSize: 13, textAlign: "justify", width: "100%", marginTop: '10px' }}>
                    {(v.name === "mobile") ? "Same number should be used in Mobitel Customer Consent Portal. Your PEOTVGO account will be created with this number." : "This number will be used for communication purposes."}
                  </p>}
                </div>

              </div>
            }
            )}
            {jwt === undefined ? (
              <div
                style={{
                  border: "1px solid #4FA1D9",
                  padding: 15,
                  marginLeft: -15,
                  marginRight: -15
                }}
              >
                <div className="form-group row required">
                  <label className="col-sm-12 col-md-4 col-form-label" required>
                    Email (Username){" "}
                    <img
                      src={require("../../images/icons/icon.svg")}
                      style={{ width: "25px", marginLeft: -9, paddingLeft: 3 }}
                      alt="info"
                    />
                  </label>
                  <div className="col-sm-12 col-md-8">
                    <Field
                      id="email"
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      component={InputField}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
                <div className="form-group row required">
                  <label className="col-sm-12 col-md-4 col-form-label" required>
                    Password{" "}
                    <img
                      src={require("../../images/icons/icon.svg")}
                      style={{ width: "25px", marginLeft: -9, paddingLeft: 3 }}
                      alt="icon"
                    />
                  </label>
                  <div className="col-sm-12 col-md-8">
                    <Field
                      id="password"
                      type="password"
                      name="password"
                      className="form-control"
                      placeholder="Password"
                      component={InputField}
                    />
                  </div>
                </div>
                <div className="form-group row required">
                  <label className="col-sm-12 col-md-4 col-form-label" required>
                    Confirm Password{" "}
                    <img
                      src={require("../../images/icons/icon.svg")}
                      style={{ width: "25px", marginLeft: -9, paddingLeft: 3 }}
                      alt="info"
                    />
                  </label>
                  <div className="col-sm-12 col-md-8">
                    <Field
                      id="confirmPassword"
                      type="password"
                      name="confirmPassword"
                      className="form-control"
                      placeholder="Confirm Password"
                      component={InputField}
                    />
                  </div>
                </div>
                <p
                  style={{
                    fontSize: 13,
                    textAlign: "center",
                    width: "100%",
                    marginBottom: 0
                  }}
                >
                  <span style={{ color: "#e32" }}>* </span>Required Fields
                </p>
                <p style={{ fontSize: 13, textAlign: "center", width: "100%" }}>
                  <img
                    src={require("../../images/icons/icon.svg")}
                    style={{ width: "25px", marginLeft: 20 }}
                    alt="info"
                  />{" "}
                  The username and password should be used to login to
                  <b> PEOTVGO Web Selfcare Portal</b>
                </p>
              </div>
            ) : null}
            <div className="form-group row">
              <div
                className="col-sm-12 col-md-8 offset-md-4"
                style={{ marginTop: 15 }}
              >
                <CustomButton
                  id="personalDetails"
                  disabled={loading}
                  loading={loading}
                  text="Submit"
                  style={{ float: "right", margin: 0 }}
                />

                {/* {
                  submitUserDetailsFailed && loading&&
                  <div className="alert alert-danger" role="alert" style={{ marginTop: 40 }}>
                    {submitUserDetailsFailed}
                  </div>
                } */}
                <div style={{ marginTop: 40 }}>
                  {submitUserDetailsFailed !== undefined && (
                    <ErrorMessage
                      errorStatus={submitUserDetailsFailed}
                      errorMessage={submitUserDetailsFailed}
                      mobitelError={userType === "6" && submitUserDetailsFailed === "Duplicate mobitel number" ? this.props.userAccount.mobileNo : false}
                      onClick= {this.onClickLink}
                    />
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const validate = values => {
  const errors = {};
  if (getLoggedInStatus() && !values.mobile) {
    errors.mobile = "Mobile Number is Required";
  } else if (!values.mobile) {
    errors.mobile = "Mobile Number is Required";
  }
  if (getConnectionType() === "6") {
    if (!/^07[0-9]{8}$/i.test(values.contactNo)) {
      errors.contactNo = "Please enter 10 digits starting with 07";
    }
  }
  if (getConnectionType() === "7") {
    if (!/^7[0-9]{8}$/i.test(values.mobile)) {
      errors.mobile = "Please enter 9 digits starting with 7";
    }
  } else {
    if (!/^07[0-9]{8}$/i.test(values.mobile)) {
      errors.mobile = "Please enter 10 digits starting with 07";
    }
  }
  if (getConnectionType() === 7) {
    if (values.telephoneNumber && values.telephoneNumber && (!/^070[0-9]{7}$|071[0-9]{7}$/i.test(values.telephoneNumber))) {
      errors.telephoneNumber = 'Invalid telephone number'
    }
  }
  if (!values.password) {
    errors.password = "Password is Required";
  }

  if (values.password && !/^[a-zA-Z0-9!@#$%^&*+=._-]{8,}$/i.test(values.password)) {
    errors.password =
      "Password should be minimum 8 characters with numbers and letters";
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = "Password is Required";
  }

  if (values.password !== values.confirmPassword) {
    errors.confirmPassword = "Password and Confirm Password is not matching";
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = "Confirm Password is Required";
  }
  if (!values.email) {
    errors.email = " Email is Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (getConnectionType() === "6") {
    if (!values.firstName) {
      errors.firstName = "Name is Required";
    }
  }else{
    if (!values.firstName) {
      errors.firstName = "First Name is Required";
    }
  }
    if (!values.lastName && (getConnectionType() === "7")) {
      errors.lastName = "Family Name is Required";
    }
    if (!values.lastName && (getConnectionType() === "6")) {
      errors.lastName = "Last Name is Required";
    }
    if (!values.address && sessionStorage.getItem("@connectionType") !== "1") {
      errors.address = "Address is Required";
    }
    if (!values.country_name) {
      errors.country_name = "Country is Required";
    }

    return errors;
  };

  const mapStateToProps = state => {
    return {
      ...registrationSelectors.submitDetails(state),
      ...registrationSelectors.getErrors(state)
    };
  };

  export default withRouter(
    reduxForm({
      form: "profile",
      validate
      //enableReinitialize: true,
    })(
      connect(
        mapStateToProps,
        registrationActions
      )(PersonalDetailsView)
    )
  );
