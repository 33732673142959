import React from 'react'
import { Container, Col } from "react-bootstrap";
import { DatabundlePlaceHolder } from './DummyComponent'

export default function DummyAllDataBundle() {
    return (
        <div className="all-databundle-container">
            <Container>
                <div className="all-databundle-header">
                    <h2 className="all-databundle-header-label" >SUBSCRIPTION DATA BUNDLES</h2>
                    <span><i className="fa fa-check-square data-bundle-already-sub-label"></i> Already Subscribed</span>
                </div>
                <br />
                <Col sm={12} md={6} lg={4} >
                    <DatabundlePlaceHolder />
                </Col>
            </Container>

        </div>
    )
}
