import React from "react";
import { Link } from "react-router-dom";
import CustomSelectBox from '../customSelect/CustomListboxContainer'
import { getLoggedInStatus } from '../../util/ArrayValidator';
import "./style.scss";
import { DEFAULT_ONDEMAND_SUBTYPE } from "../../util/Constants";
export default function SideNavBar({
  isSideNavBarClose,
  closeAction,
  isBroadband,
  goToProfile,
  logOut,
  handleChangePassword,
  options,
  currentOption,
  currentOptionValue,
  placeHolder,
  handleSubmit,
  showDropDown,
  serviceType,
  userName,
  connectionStatus
}) {
  const isLoggedIn = getLoggedInStatus();
  let sideBarContent;
  if(getLoggedInStatus()){
    if(connectionStatus.activeStatus === "active"){
      sideBarContent = <><li>
      <Link to="/" onClick={() => closeAction()}>
        <i className="fa fa-home"></i> Home
      </Link>
    </li>
      <li>
        <Link to="/packages" onClick={() => closeAction()}>
          <i className="fa fa-cube"></i> Packages
      </Link>
      </li>
      <li>
        <Link to="/channels" onClick={() => closeAction()}>
          {/* <i className="fa fa-stream"></i> */}
          <i className="fas fa-tv"></i> Channels
      </Link>
      </li>
      <li>
        <Link to={`/ondemand/#${DEFAULT_ONDEMAND_SUBTYPE}` }onClick={() => closeAction()}>
          <i className="fa fa-caret-square-right"></i> On Demand
      </Link>
      </li>
      <li>
        <Link to="/svod" onClick={() => closeAction()}>
          <i className="fa fa-caret-square-right"></i> SVOD
      </Link>
      </li>
      {isLoggedIn && <li>
            <Link to="/promotions/packages" onClick={() => closeAction()}>
              <i className="fa fa-caret-square-right"></i> Promotional Packages
            </Link>
          </li>}
      {isLoggedIn && isBroadband ? (
        <li>
          <Link to="/databundles" onClick={() => closeAction()}>
            {/* <i className="fa fa-caret-square-right"> </i> */}
            <i className="fas fa-globe-asia"></i> Data Bundles
        </Link>
        </li>
      ) : (
          ""
        )}
      {isLoggedIn ? (
        <li>
          <Link to="/screens" onClick={() => closeAction()}>
            <i className="fas fa-laptop"></i> Devices
        </Link>
        </li>
      ) : (
          ""
        )
      }
      <li>
        <Link to="/promotions" onClick={() => closeAction()}>
          <i className="fa fa-caret-square-right"></i> Promotions
        </Link>
      </li>
      </>
    }
  }else{
    sideBarContent = <><li>
    <Link to="/" onClick={() => closeAction()}>
      <i className="fa fa-home"></i> Home
    </Link>
  </li>
    <li>
      <Link to="/packages" onClick={() => closeAction()}>
        <i className="fa fa-cube"></i> Packages
    </Link>
    </li>
    <li>
      <Link to="/channels" onClick={() => closeAction()}>
        {/* <i className="fa fa-stream"></i> */}
        <i className="fas fa-tv"></i> Channels
    </Link>
    </li>
    <li>
      <Link to={`/ondemand/#${DEFAULT_ONDEMAND_SUBTYPE}`} onClick={() => closeAction()}>
        <i className="fa fa-caret-square-right"></i> On Demand 
    </Link>
    </li>
    <li>
      <Link to="/svod" onClick={() => closeAction()}>
        <i className="fa fa-caret-square-right"></i> SVOD
    </Link>
    </li>
    <li>
        <Link to="/promotions/packages" onClick={() => closeAction()}>
        <i className="fa fa-caret-square-right"></i> Promotional Packages
      </Link>
    </li>
    {isLoggedIn && isBroadband ? (
      <li>
        <Link to="/databundles" onClick={() => closeAction()}>
          {/* <i className="fa fa-caret-square-right"> </i> */}
          <i className="fas fa-globe-asia"></i> Data Bundles
      </Link>
      </li>
    ) : (
        ""
      )}
    {isLoggedIn ? (
      <li>
        <Link to="/screens" onClick={() => closeAction()}>
          <i className="fas fa-laptop"></i> Devices
      </Link>
      </li>
    ) : (
        ""
      )
    }
    <li>
        <Link to="/promotions" onClick={() => closeAction()}>
          <i className="fa fa-caret-square-right"></i> Promotions
        </Link>
      </li>
    </>
  }

  return (
    <>
      <nav id="sidebar" className={isSideNavBarClose && "closeSideNavBar"}>
        <i className="fas fa-times cross" onClick={() => closeAction()}></i>
        <ul className="list-unstyled components">
          {window.innerWidth < 576 ? <><li style={{ textAlign: "left" }}>
          <Link
                                className="nav-link self-care-text"
                                to="/"
                                style={{
                                    color: '#fff',
                                    fontFamily: "SegoeUI-Bold",
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                    marginLeft: "-15px"
                                }}
                            >
                                Selfcare
                                </Link>
            <img src={require("../../images/user.png")} className="profile-img show-on-small-screen" style={{ backgroundColor: "#232223" }} alt="profile-img" />
          </li>
            <li className="responsive-item show-on-small-screen" style={{ textAlign: "left" }}>{localStorage.jwt && userName}</li>
            <li className="responsive-item show-on-small-screen" style={{ textAlign: "left" }}>
              {showDropDown ?
                <CustomSelectBox
                  options={options}
                  // currentOption={localStorage.selectedUserIndex == selectedIndex && connectionDetails[selectedIndex] && connectionDetails[selectedIndex].telephoneNumber}

                  // if flag == base -> handle base account unSub
                  currentOption={currentOption}
                  // currentOption={ 
                  //     "Helloooo"
                  // }
                  currentOptionValue={currentOptionValue}
                  placeHolder={placeHolder}
                  handleSubmit={handleSubmit}
                /> :
                localStorage.jwt ?
                  <div
                    style={{
                      backgroundColor: "#7F7F7F",
                      width: "180px",
                      color: "#fff ",
                      borderRadius: "5px",
                      position: "relative",
                      textAlign: "initial",
                      padding: "0px 6px",
                    }}
                  > <p style={{ fontSize: "16px" }} >
                      {currentOption}
                    </p>
                  </div>
                  :
                  null}
            </li>
            <li className="responsive-item show-on-small-screen" style={{ textAlign: "left" }}>
              <p style={{margin: 0}}>{serviceType}</p>
            </li></> : null}
          {sideBarContent}

          {localStorage.jwt ? (
            <li className="responsive-item show-on-small-screen">
              <Link to="/profile" onClick={() => closeAction()}>
                <i className="fas fa-user-circle"></i> Go to Profile
              </Link>
            </li>
          ) : (
              ""
            )
          }

          {localStorage.jwt ? (
            <li className="responsive-item show-on-small-screen">
              <button className="link-button" id="changePassword" onClick={() => handleChangePassword()}>
                <i className="fas fa-key"></i> Change Password
              </button>
            </li>
          ) : (
              ""
            )
          }

          {localStorage.jwt ? (
            <li className="responsive-item show-on-small-screen" style={{
              position: "absolute",
              // bottom: "1%",
              width: "83%",
            }}>

              <hr style={{ borderColor: "#ffffff", marginTop: 0, marginBottom: '5px' }} />
              <button className="link-button" onClick={() => logOut()}>
                <i className="fas fa-sign-out-alt"></i> Logout
              </button>
            </li>
          ) : (
              <li className="responsive-item show-on-small-screen" style={{
                position: "absolute",
                // bottom: "1%",
                width: "83%",
              }}>
                <hr style={{ borderColor: "#ffffff", marginTop: 0, marginBottom: '5px' }} />
                <Link to="/login" onClick={() => closeAction()}>
                  <i className="fa fa-caret-square-right"></i> Login
                </Link>
              </li>
            )
          }
        </ul>
      </nav>
    </>
  );
}