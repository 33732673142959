import React from 'react'
import DaysToExpire from '../DaysToExpire/DaysToExpire';

function CardHover({ hover, cardStyle, id, onClick, name, isLoggedIn, isPurchased, showAddNew, price, daysToExpire, showPrice, beforeLogin }) {
    let priceView
    //show the price of the product if the user is logged in and product is not purchased and its not bundled
    if ((isLoggedIn||beforeLogin) && (!isPurchased || isPurchased == null) && showAddNew) {
        price = price || 0
        priceView = showPrice && (<h6 id={id} className="price"> Rs. {price}</h6>)
    }
    //check if the card should be hoverable and add the hoverable content inside
    if (hover) {
        //the cardStyle accepts the product type and applies the style
        return <div className={`inside-crm-card-component${cardStyle}`} id={id} onClick={onClick}>
            <h6
                id={id}
                onClick={onClick} style={{ marginTop: "auto" }}>{name}</h6>
            {priceView}
            <DaysToExpire daysToExpire={daysToExpire} id={id} />
        </div>
    } else {
        return null
    }
}

export default CardHover
