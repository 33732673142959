import React, { Component } from 'react'
import { Container } from 'react-bootstrap';
import { CustomCarousel } from '../../components/carousel/CustomCarousel'

import { CustomButton } from '../../components/controls/CustomButton'
import { withRouter, Link } from 'react-router-dom';
import { connect } from "react-redux";
import _ from 'lodash'
import { ErrorMessage } from './../../components/customMessages/ErrorMessage'
import { subscriptionSelectors, subscriptionActions } from './ducks/index.js';
import { PackageDetails } from '../../components/Package/PackageDetails'
import { DataBundle } from '../../components/Package/DataBundle'
import { DeviceCount } from '../../components/Package/DeviceCount'
import { Pricing } from '../../components/Package/Pricing'
import { AdditionalDevicesDropdown } from '../../components/Package/AdditionalDevicesDropdown'
import DummyPackageCarousel from '../../components/DummyComponent/DummyPackageCarousel';
import DummyAllChannelPage from '../../components/DummyComponent/DummyAllChannelPage';
import { CustomTabHeader } from '../../components/customTabs/CustomTabHeader'
import { CustomTabBody } from '../../components/customTabs/CustomTabBody'
import { CustomTabBodyContent } from '../../components/customTabs/CustomTabBodyContent'
import { CustomVodTab } from "../../components/customTabs/CustomVodTab"
import { CustomSvodTab } from "../../components/customTabs/CustomSvodTab"
import { productSorting, purchasableProductSorting, filterScreen, isActiveScreenProduct, filterVod } from './../AllProducts/functions/PackagePageFuctions'
import OverlaySpinner from '../../components/OverlaySpinner/OverlaySpinner';
import { getIndex, validArray, getConnectionType, getRegistrationConnectionId, taxCalculation } from '../../util/ArrayValidator';
import { IS_SANDBOX, SUBSCRIPTIONAL, MOBITEL_CONSENT_RETURN_URL } from '../../util/Constants'
import { PackageFilter } from '../../components/customTabs/PackageFilter'
import NoProductAvailable from '../../components/NoProductAvailable/NoProductAvailable';
class ViewPackage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedIndex: 0,
            additionalScreen: undefined,
            productType: undefined,
            agree: false,
            subscribe: undefined,
            error: "",
            loading: false,
            secondaryAllowedProductsError: undefined,
            selectedImages: [],
            additionalPrice: 0,
            dataBundle: undefined,
            secondaryLoading: true,
            selectedCategory: undefined,
            statusError: undefined,
            showPayhere: false,
            selectedTabIndex: "0"
        }
    }

    componentDidMount() {
        let connectionType = getConnectionType() 
        let connectionId = getRegistrationConnectionId()
        if(connectionType==="6"|| connectionType==="7"){
            let connectionTypeName = connectionType==="6"? "mobitel" : "prepaid"
            this.props.checkPackageProvisioning({connectionType : connectionTypeName,connectionId})
        }
        let hash = this.props.location.hash
        let tabActiveKeys = hash ? hash : "#0"
        var ret = tabActiveKeys.replace('#', '');
        var selected = [];
        if (selected[ret] !== -1) selected.push(ret);
        this.setState({ selectedImages: selected, selectedIndex: ret });
        this.props.getAllowedSecondaryPackages()
    }
    componentDidUpdate() {
        if (this.props.products && this.props.baseProductsLoading && this.props.baseProducts === undefined && this.props.allPackageTypes === undefined && !this.props.productsLoading) {
            let products = getIndex(this.props.products, this.state.selectedIndex)
            let packageId = products.id && products.id !== undefined ? products.product[0].id : null
            if(packageId){
                this.props.getProductTypes(packageId)
                this.props.getProducts({
                    "productId": packageId,
                    "productTypeIds": [products.id && products.product && products.product[0].productTypeId],
                    "connectionType": sessionStorage.getItem("@connectionTypeName")
                })
                products.id && products.id !== undefined && products.dataBundle[0].dataId !== undefined && this.props.getDataBundle(products.dataBundle[0].dataId)
                if(this.state.selectedIndex === '0' && products.id && products.id !== undefined && products.dataBundle[0].dataId !== undefined){
                    localStorage.setItem("databundleIncluded", true)
                } else {
                    localStorage.setItem("databundleIncluded", false)
                }
            }
            else{
                this.props.getProductsSuccess() 
            }
        }
    }


    static getDerivedStateFromProps(nextProps, state) {
        let newProps = {}

        if (nextProps.error === undefined) {
            newProps.error = undefined
        }
        else {
            if (nextProps.error !== state.error) {
                newProps.error = nextProps.error
                newProps.loading = false
            }
            if (nextProps.secondaryAllowedProductsError !== state.secondaryAllowedProductsError) {
                newProps.secondaryAllowedProductsError = nextProps.secondaryAllowedProductsError
                newProps.secondaryLoading = false

            }
            if (nextProps.dataBundle !== state.dataBundle) {
                newProps.dataBundle = nextProps.dataBundle

            }

        }
        if (nextProps.statusError === undefined) {
            newProps.statusError = undefined
        } else {
            if (nextProps.statusError !== state.statusError) {
                newProps.statusError = nextProps.statusError
                newProps.loading = false
            }
        }
        return {
            ...newProps
        }
    }

    componentWillUnmount() {
        this.props.clearProducts();
    }

    onClick = (e) => {
        this.setState({ selectedIndex: e.target.id, additionalPrice: 0, additionalScreen: undefined, dataBundle: undefined, selectedCategory: undefined })
        var selected = [];
        if (selected[e.target.id] !== -1) selected.push(e.target.id);
        this.setState({ selectedImages: selected, selectedTabIndex: "0" });
        var productTypeIds = []
        productTypeIds.push(this.props.products[this.state.selectedIndex].product[0].productTypeId)
        this.props.getProductTypes(this.props.products[e.target.id].product[0].id)
        this.props.getProducts({
            "productId": this.props.products[e.target.id].product[0].id,
            "productTypeIds": productTypeIds
        })// products of base packaages
        let products = getIndex(this.props.products, e.target.id)
        this.props.getDataBundle(products.id && products.dataBundle[e.target.id].dataId)
        if(products.id !== undefined && products.dataBundle[e.target.id].dataId !== undefined){
            localStorage.setItem("databundleIncluded", true)
        } else {
            localStorage.setItem("databundleIncluded", false)
        }
        this.props.getProductsLoading()
    }

    handleChangeAgree = (e) => {
        this.setState({
            agree: e.target.checked
        })

    }

    handleChange = (e) => {
        let purchasableProductList = this.props.baseProducts && validArray(this.props.baseProducts.purchasableProductList)
        let index = e.target.selectedIndex;
        let price = 0;
        let screenPurchaseOptionId = "";
        let productTypeTypeName = "";
        this.props.baseProducts && purchasableProductList.map(i => {
            if (i.productTypeName === "Screens" && i.id === e.target[index].id) {
                // price = i.purchaseOption && i.purchaseOption.defaultPrice;
                price = i.discountOptionGroup && i.discountOptionGroup.purchasePrice
                screenPurchaseOptionId = i.purchaseOption && i.purchaseOption.id;
                productTypeTypeName = i.productTypeTypeName;
            }
            return productTypeTypeName = i.productTypeTypeName;
        })
        this.setState({
            additionalScreen: e.target[index].id,
            dataBundle: undefined,
            // productType: e.target[index].value,
            productType: productTypeTypeName,
            additionalPrice: e.target.selectedIndex === 0 ? 0 : price,
            screenPurchaseOptionId: screenPurchaseOptionId
        })
    }

    onSubmit = () => {
        var user = getRegistrationConnectionId()
        let products = getIndex(this.props.products, this.state.selectedIndex)
        this.setState({ loading: true, showPayhere: true })
        let details = {

            "additionalScreenDetailDto": {
                "productId": this.state.additionalScreen,
                "productType": this.state.productType,
                "connectionId": user,
                "pin": "",
                "purchaseOption": this.state.screenPurchaseOptionId
            },
            "connectionId": user,
            "productType": products.productTypeId,
            "purchaseOptionUid": products.purchaseOptionGroupId,
            "secondaryPackageId": products.id,
            "currency": "LKR"

        }
        let connectionType = getConnectionType()
        if (connectionType === "6") {
            const { consent } = this.props
            localStorage.setItem('package', JSON.stringify(details))
            var obj = {
                id: products.id,
                name: products.name,
                price: products.discountOptionGroup.purchasePrice.toFixed(2)
            }
            localStorage.setItem('basepackagePurchased', JSON.stringify(obj))
            localStorage.setItem('index', this.state.selectedIndex)
            this.props.createConsent()

        } else {
            if (connectionType === "7") {
                var object = {
                    id: products.id,
                    name: products.name,
                    price: products.discountOptionGroup.purchasePrice.toFixed(2)
                }
                localStorage.setItem('basepackagePurchased', JSON.stringify(object))
                localStorage.setItem('index', this.state.selectedIndex)
                localStorage.setItem('package', JSON.stringify(details))
            }
            this.props.addConnection(details)
        }

    }

    handleChangeCustomTab = (e) => {
        this.setState({
            selectedCategory: e.target.value
        })
    }

    getPurchaseSatusDissmissed = (payment) => {

        this.setState({
            loading: false,
            showPayhere: false
        })

    }
    getPurchaseSatus = () => {
        let connectionType = getConnectionType() 
        let connectionId = getRegistrationConnectionId()
        if(connectionType==="6"|| connectionType==="7"){
            let connectionTypeName = connectionType==="6"? "mobitel" : "prepaid"
            this.props.checkPackageProvisioning({connectionType : connectionTypeName,connectionId, isPayhere : true, transactionId: this.props.subscribe.transactionId})
        }
        // this.props.getPackagePurchasedStatus(this.props.subscribe.transactionId)
    }
    Payhere = (data) => {
        this.setState({ showPayhere: false })
        var details = JSON.parse(sessionStorage.getItem("details"))
        let cityNameArr = details && details.address && details.address.split(",");
        let selectedCityName
        if (cityNameArr && cityNameArr[0]) {
            selectedCityName = cityNameArr[0];
        }
        let payableAmount = taxCalculation(data.amount)
        let selectedPurchaseType = getIndex(this.props.products, this.state.selectedIndex)
        var payment = {
            "sandbox": IS_SANDBOX,
            "merchant_id": data.merchantId,       // Replace your Merchant ID
            "return_url": "",
            "cancel_url": data.cancelUrl,
            "notify_url": data.notifyUrl,
            "order_id": data.referenceNumber,
            "items": data.purchaseItemName,
            "amount": payableAmount,
            "currency": "LKR",
            "first_name": details && details.firstName,
            "last_name": details && details.otherName,
            "email": details && details.email,
            "phone": details && details.mobile,
            "address": details && details.address,
            "city": selectedCityName,
            "country": details && details.country,
            // "recurrence": data.recurrence,
            // "duration": data.duration,

        };
        if (selectedPurchaseType && selectedPurchaseType.purchaseOption && selectedPurchaseType.purchaseOption.purchaseType === SUBSCRIPTIONAL) {
            payment.recurrence = data.recurrence;
            payment.duration = data.duration;
        }

        window.payhere.onCompleted = (orderId) => {

            console.log("Payment completed. OrderID:" + orderId)
            this.getPurchaseSatus()
            //Note: validate the payment and show success or failure page to the customer
        };

        // Called when user closes the payment without completing
        window.payhere.onDismissed = () => {
            //Note: Prompt user to pay again or show an error page
            console.log("Payment dismissed");
            this.getPurchaseSatusDissmissed()
            this.getPurchaseSatus()

        };

        // Called when error happens when initializing payment such as invalid parameters
        window.payhere.onError = (error) => {
            // Note: show an error page
            console.log("Error:" + error);
            this.getPurchaseSatusDissmissed()
        };

        window.payhere.startPayment(payment);
    }
    onSelect = (e) => {
        this.setState({
            selectedTabIndex: e
        })

    }
    onSelectFilters = (e) => {
        this.setState({ selectedIndex: "0" })
        this.props.filterPackages(e)
        let target = { target: { id: "0" } }
        setTimeout(
            () => this.onClick(target),
            50
        );


    }
    render() {
        const connectionType = getConnectionType()
        const { products, productsError, baseProducts, baseProductsLoading, allPackageTypes, subscribe, statusLoading, status, statusError, filteredPackages, productsLoading } = this.props
        const { selectedIndex, loading, error, secondaryAllowedProductsError, additionalPrice, secondaryLoading, selectedCategory, showPayhere, selectedTabIndex } = this.state;
        let userType = getConnectionType()

        let validSecondaryProductsArray = validArray(products)
        let selectedIndexOfProduct = getIndex(products, selectedIndex)
        let baseProductList = baseProducts && validArray(baseProducts.productsList)
        let basePurchasableProductList = baseProducts && validArray(baseProducts.purchasableProductList)
        let subscrptionMessage
        if (connectionType !== "1") {
            subscrptionMessage = <p className="subscrptionMessage">To enjoy PEOTVGO Service on your TV, purchase the OTT Set-top Box from the nearest SLT Office at Rs. 9,000/=+Taxes.</p>
        }
        let selectedTabIndexTypeName = allPackageTypes && allPackageTypes[parseInt(selectedTabIndex)].typeName

        let product = baseProducts && baseProducts.productsList
        let purchasableProducts = baseProducts && baseProducts.purchasableProductList
        let channelArray = productSorting(product, selectedTabIndexTypeName)
        let purchasableChannelArray = purchasableProductSorting(purchasableProducts)

        let unionArray = _.unionBy(channelArray, purchasableChannelArray, "id");
        let deviceOptions = filterScreen(basePurchasableProductList)

        let paymentStatus = userType === "7" && subscribe !== null && loading && !statusLoading && status === undefined && statusError === undefined && showPayhere && this.Payhere(subscribe)
        let serviceType;
        if (userType === "7") {
            serviceType = true
        } else {
            serviceType = false
        }
        //check whether are there any screen products available
       let isScreenProduct= isActiveScreenProduct(baseProductList) || (getConnectionType() !== "7" && getConnectionType() !== "6" && isActiveScreenProduct(basePurchasableProductList))
       let vods = baseProducts &&filterVod( baseProducts.productsList).reverse();
        let purchasableVods = baseProducts && filterVod(baseProducts.purchasableProductList).reverse();
        return (
            <>
                <style type="text/css">
                    {`
                    .btn-submit:focus {
                        box-shadow: 0 0 0 0.2rem rgba(247, 182, 67, 0.5);
                    }
                `}
                </style>
                <div className="subscription-message">
                    {(secondaryLoading || productsLoading) && <Container>
                        <div style={{marginRight: 20, marginTop: 100 }}>
                        <DummyPackageCarousel
                            onClick={this.onClick}
                            isDummy={true}
                        />
                        </div>

                    </Container>}

                    {validSecondaryProductsArray ?
                        <Container className="view-package-page-carousel">
                            <div style={{ marginLeft: 20, marginRight: 20, marginTop: 100 }}>
                                {(userType === "6" || userType === "7") && filteredPackages && filteredPackages.length === 3 && <><div className="row" style={{ marginLeft: '1px' }}>
                                    <div className="col-md-9">
                                        <ion-icon name="options" style={{ color: "#c2c2c2" }} ></ion-icon><span style={{ margin: '10px', color: "#c2c2c2" }}>Filter</span>
                                    </div>
                                </div>
                                    <div className="row" style={{ marginLeft: '1px', marginTop: '10px' }}>
                                        <div className="col-md-9">
                                            <PackageFilter onSelect={this.onSelectFilters} />
                                        </div>
                                    </div></>}
                                <div className="row" style={{ textAlign: "center", marginLeft: "-20px", marginBottom: "20px" }}>
                                    <CustomCarousel
                                        slidesToShow={(900 < window.innerWidth ? 3 : (550 < window.innerWidth ? 2 : (440 < window.innerWidth ? 2 : 1)))}
                                        renderBottomCenterControls={false}
                                        heightMode="last"
                                        slideWidth="275px"
                                        initialSlideHeight={(1200 < window.innerWidth ? 290 :
                                            (990 < window.innerWidth ? 240 :
                                                (900 < window.innerWidth ? 180 :
                                                    (770 < window.innerWidth ? 300 :
                                                        (550 < window.innerWidth ? 180 :
                                                            (440 < window.innerWidth ? 210 :
                                                                (330 < window.innerWidth ? 220 : 180)))))))}
                                        allProducts={products && Array.isArray(products) && products}
                                        featuredPackage={true}
                                        informative={true}
                                        onClick={this.onClick}
                                        isLoggedIn={selectedIndexOfProduct.name}
                                        selectedImage={this.state.selectedImages}
                                        slideIndex={this.state.selectedImages}
                                        viewPackage={true}
                                    //  imageNotFoundStyle={{ bottom: 10, fontSize: 11 }}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-md-9">
                                        <PackageDetails
                                            name={selectedIndexOfProduct.name}
                                            description={selectedIndexOfProduct.description}
                                            price={selectedIndexOfProduct.price && parseInt(selectedIndexOfProduct.price + additionalPrice)}
                                            purchaseOption={selectedIndexOfProduct.purchaseOption}
                                            serviceType={serviceType}
                                            isPrepaid={serviceType}
                                            productDetails={selectedIndexOfProduct}
                                            isRegistration={true}
                                            additionalScreenPrice={additionalPrice}
                                        />
                                        {/* <h6 style={styles.oldPrice}>Rs. 599</h6>
                                        <span style={styles.discount}>10% OFF</span> */}
                                        {selectedIndexOfProduct.name !== "PEOTVGO" && <div className="package-include-header"><p className="package-includes-p">This Package Includes: </p></div>}
                                    </div>
                                    {!baseProductsLoading && <div className="col-md-3" style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                                        <DataBundle dataQuota={localStorage.databundleIncluded==="true" && this.state.dataBundle && this.state.dataBundle.dataQuota} />
                                        {getConnectionType() && isScreenProduct && <div style={{
                                            color: '#ffff',
                                            padding: '5px 10px',
                                            border: '1px solid #bf800c',
                                            borderRadius: 5,
                                            marginTop: 15,
                                            flexDirection: 'column',
                                            display: 'flex',
                                            alignItems: 'center',
                                            lineHeight: 2
                                        }}>
                                            {baseProductList && baseProductList.map(prodScreen => {
                                                if (prodScreen.productTypeName === "Screens" && prodScreen.status === "PRODUCT_STATUS_ACTIVE") {
                                                    return <DeviceCount allScreens={prodScreen.numberOfScreens} />
                                                }
                                                return null
                                            })}

                                            {(getConnectionType() !== "7" && getConnectionType() !== "6") && deviceOptions.length !== 0 && <AdditionalDevicesDropdown
                                                purchasableProductList={basePurchasableProductList}
                                                onChange={this.handleChange} option={deviceOptions}
                                            />}
                                            {additionalPrice !== 0 && <Pricing price={additionalPrice} />}
                                        </div>}
                                    </div>}
                                </div>

                            </div>
                        </Container >:
                         !secondaryLoading && !baseProductsLoading && <div style={{ marginLeft: 20, marginRight: 20, marginTop: 100 }}><NoProductAvailable/></div>
                    }
                    {
                        baseProductsLoading ? <Container > <div ><DummyAllChannelPage /> </div></Container> :
                            baseProducts && baseProductList &&

                            <Container style={{ marginTop: 20, paddingLeft: 0 }}>
                                <div style={{ marginLeft: 30, marginRight: 20 }}>
                                    <CustomTabHeader tabs={allPackageTypes} onSelect={this.onSelect} >
                                        {selectedTabIndexTypeName === "channel" && <> <CustomTabBody handleChange={this.handleChangeCustomTab}
                                            unionArray={unionArray}
                                            name="select" id={1} />
                                            <CustomTabBodyContent
                                                unionArray={unionArray}
                                                product={baseProducts && baseProducts.productsList}
                                                purchasableProducts={baseProducts && baseProducts.purchasableProductList}
                                                selectedCategory={selectedCategory}
                                                selectedCategoryName="Channels"
                                                viewPackage={true}
                                                userRegiter={true}
                                                beforeLogin={true}
                                            /></>}
                                        {selectedTabIndexTypeName === "vod" && <CustomVodTab
                                            unionArray={unionArray}
                                            product={vods}
                                            purchasableProducts={purchasableVods}
                                            selectedCategory={selectedCategory}
                                            selectedCategoryName="Video on Demand"
                                            viewPackage={true}
                                            userRegiter={true}
                                            beforeLogin={true}
                                            vodIndex={vods && vods.length}
                                        />}
                                        {selectedTabIndexTypeName === "svod" && <CustomSvodTab
                                            unionArray={unionArray}
                                            product={baseProducts && baseProducts.productsList}
                                            purchasableProducts={baseProducts && baseProducts.purchasableProductList}
                                            selectedCategory={selectedCategory}
                                            selectedCategoryName="SVOD"
                                            viewPackage={true}
                                            userRegiter={true}
                                            beforeLogin={true}
                                        />}
                                    </CustomTabHeader>
                                </div>
                            </Container>
                    }
                    <br />
                    {validSecondaryProductsArray && <Container style={{ marginBottom: "5px", paddingLeft: 0 }}>
                        <div className="subscription-error-message" >
                            {subscrptionMessage}</div></Container>}
                    {(loading || statusLoading || paymentStatus) && <OverlaySpinner />}
                    {
                        !baseProductsLoading && validSecondaryProductsArray && <Container style={{ marginBottom: 80, paddingLeft: 0 }}>
                            <div className="subscription-error-message">
                                <input type="checkbox" onChange={this.handleChangeAgree} style={{ transform: "scale(1.25)" }} />
                                <span style={{ color: '#ffff', fontSize: 13, marginLeft: 10 }}>By clicking 'SUBMIT', I confirm that I have read and agreed the
                            <Link to="/termsandconditions" id="rules" target="_blank" style={{ color: "#4fa1d9" }}> terms and conditions </Link>
                                </span>
                                <div style={{ width: 15 }} />
                                <CustomButton id="subscribe"
                                    disabled={!this.state.agree || loading || statusLoading}
                                    loading={loading || statusLoading}
                                    text="SUBMIT" onClick={this.onSubmit}
                                />
                            </div>
                            <div class="subscription-error-message">
                                <ErrorMessage
                                    errorStatus={error || productsError || secondaryAllowedProductsError || statusError}
                                    errorMessage={(error && error.message) || productsError || secondaryAllowedProductsError || statusError}
                                />
                            </div>

                        </Container>
                    }
                </div >

            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...subscriptionSelectors.addConnection(state),
        ...subscriptionSelectors.addConnectionError(state),

    }
};

export default withRouter(connect(
    mapStateToProps,
    subscriptionActions
)(ViewPackage));

