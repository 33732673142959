import { createLogic } from "redux-logic";
import actions from "./actions";
import types from "./types";
import endPoints from "./../../../util/EndPoints";
import API from "./../../../util/HTTPClient";
import history from "../../../_helpers/history";
import { getConnectionType } from './../../../util/ArrayValidator';

const getConnectiontypes = createLogic({
  type: types.GET_TYPES,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(endPoints.GET_TYPES, action.payload)
      .then(resp => resp.data)
      .then(data => {
        dispatch(actions.getTypesSuccess(data));
      })
      .catch(err => {
        // Log error
        dispatch(actions.getTypesFailed(err.response));
      })
      .then(() => done()); // call done when finished dispatching
  }
})

const getDistricts = createLogic({
  type: types.GET_DISTRICTS,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }
    HTTPClient.Get(endPoints.GET_DISTRICTS)
      .then(resp => resp.data)
      .then(data => {
        dispatch(actions.getDistrictsSuccess(data));
      })
      .catch(err => {
        // Log error
        dispatch(actions.getDistrictsFailed(err.response));
      })
      .then(() => done()); // call done when finished dispatching
  }
})

const nonSltDetails = createLogic({
  type: types.NON_SLT_DETAILS,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient;
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient;
    } else {
      HTTPClient = API;
    }

    HTTPClient.Post(endPoints.NON_SLT_DETAILS, action.payload)
      .then(resp => resp.data)
      .then(data => {
        dispatch(actions.nonSltDetailsSubmitSuccess(data));
        history.push("/furtherdetails");
      })
      .catch(err => {
        // Log error
        dispatch(
          actions.nonSltDetailsSubmitFailed(
            (err.response.data && err.response.data.message) ||
            "Something went wrong, please contact your service provider"
          )
        );
      })
      .then(() => done()); // call done when finished dispatching
  }
})

const submitUserAccount = createLogic({
  type: types.ACCOUNT_LOGIN,
  // latest: true, 
  // debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient
    } else {
      HTTPClient = API
    }
    HTTPClient.Get(`${endPoints.GET_CONNECTION_DETAILS}?accountNumber=${action.payload.accountNumber}&connectionType=${action.payload.connectionType}&telephoneNumber=${action.payload.telephoneNumber}`)
      .then(resp => resp.data)
      .then((data) => {
        dispatch(actions.accountLoginSuccess(data))
        history.push('/personaldetails')
      })
      .catch(err => {
        // Log error
        dispatch(actions.accountLoginFailed((err && err.response && err.response.data && err.response.data.message && err.response.data.message) || "Something went wrong, please contact your service provider"))
      })
      .then(() => done()); // call done when finished dispatching
  }
})

const personaldetails = createLogic({
  type: types.SUBMIT_DETAILS,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient
    } else {
      HTTPClient = API
    }
    let endpoint
    if (localStorage.jwt !== undefined) {
      if (getConnectionType() === "7") {
        endpoint = endPoints.SUBMIT_ADD_NEW_PREPAID_CONNECTIONS
      }else if (getConnectionType() === "6") {
        debugger
        endpoint = endPoints.SUBMIT_MOBITEL_ADD_NEW_USER_DETAILS
      }
      else {
        endpoint = endPoints.SAVE_DETAILS
      }
    }
    else {
      if (getConnectionType() === "7") {
        endpoint = endPoints.SUBMIT_PREPAID_USER_DETAILS
      }  
      else if (getConnectionType() === "6") {
        debugger
        endpoint = endPoints.SUBMIT_MOBITEL_USER_DETAILS
      }
      else {
        endpoint = endPoints.GET_CONNECTION_DETAILS
      }
    }

    // const data = "100 CONTINUE"
    // dispatch(actions.submitDetailsSuccess(data)) 
    // history.push('/verify')
    // return
    HTTPClient.Post(endpoint, action.payload)
      .then(resp => resp.data)
      .then((data) => {
        dispatch(actions.submitDetailsSuccess(data))
        if (getConnectionType() === "7") {
          // localStorage.setItem('@connectionID', data.id)
          sessionStorage.setItem('@connectionID', data.connectionId)
        } else if (getConnectionType()==="6"){
          sessionStorage.setItem('@connectionID', data.connectionId)
          sessionStorage.setItem('msisdn', data.telephoneNumber)
        }
          else {
          // localStorage.setItem('@connectionID', data.connectionId)
          sessionStorage.setItem('@connectionID', data.connectionId)
        }
        // localStorage.setItem('details', JSON.stringify(data))
        sessionStorage.setItem('details', JSON.stringify(data))
        if(getConnectionType()==="6"){
          debugger
          history.push('/viewpackage')
        }else{
          history.push('/verify')
        }
       
      })
      .catch(err => {
        var text = "Email is already used in another subscribed account. Please proceed with a new email."
        var duplicateMobitel= "This account is already subscribed for PEOTVGO."
        var duplicateMobitelAfterUnsub ="There is an existing PEOTVGO account for this number."
        // Log error
        err.response && err.response.data && dispatch(actions.submitDetailsFailed(err.response.data.message || "Something went wrong, please contact your service provider"))
        if (err && err.response && err.response.data && err.response.data.message && err.response.data.message === text) {
          history.push('/customer/already_registered')
        }
        let connectionType = getConnectionType()
        if(connectionType==="6"&&((err && err.response && err.response.data && err.response.data.message && err.response.data.message === duplicateMobitel) || (err.response.data.message === duplicateMobitelAfterUnsub))){
          dispatch(actions.submitDetailsFailed("Duplicate mobitel number"))
        }
      })
      .then(() => done()); // call done when finished dispatching
  }
})

const submitOtp = createLogic({
  type: types.SUBMIT_OTP,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient
    } else {
      HTTPClient = API
    }
    // const data = "100 CONTINUE"
    // dispatch(actions.submitOtpSuccess(data))
    // history.push('/subscribe')
    // return
    HTTPClient.Post(endPoints.SUBMIT_OTP, action.payload)
      .then(resp => resp.data)
      .then((data) => {
        dispatch(actions.submitOtpSuccess(data))
        history.push('/viewpackage')
      })
      .catch(err => {
        // Log error
        err.response && err.response.data && dispatch(actions.submitOtpFailed(err.response.data.message || "Something went wrong, please contact your service provider"))
      })
      .then(() => done()); // call done when finished dispatching
  }
})

const resendOtp = createLogic({
  type: types.RESEND_OTP,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient
    } else {
      HTTPClient = API
    }
    // const data = "100 CONTINUE"
    // dispatch(actions.resendOtpSuccess(data))
    // return
    HTTPClient.Get(`${endPoints.RESEND_OTP}${action.payload}`)
      .then(resp => resp.data)
      .then((data) => {
        dispatch(actions.resendOtpSuccess("OTP Sent Successfully"))
      })
      .catch(err => {
        // Log error
        dispatch(actions.resendOtpFailed(err.response.data.message || "Something went wrong, please contact your service provider"))
      })
      .then(() => done()); // call done when finished dispatching
  }
})

const getCountry = createLogic({
  type: types.GET_COUNTRY,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let apiKey = 'wVBQQXSRXqx5VnviWexeMHlp6gIfAYLinVYmqEGA';
    let urlIp = `${endPoints.GET_COUNTRY}user-info/?format=json&apiKey=` + apiKey
    // fetch(urlIp)
    //   .then((resp) => resp.json()) // Transform the data into json
    //   .then(function (data) {
    //     var urlLocation = `${endPoints.GET_COUNTRY}city/?apiKey=${apiKey}&ip=${data.ip}`
    //     fetch(urlLocation)
    //       .then((resp) => resp.json()) // Transform the data into json
    //       .then((data) => {
    //         if(data.error){
    //           dispatch(actions.getCountryFailed("This Service is not curently available. please try again later."))
    //         }else{
    //           dispatch(actions.getCountrySuccess(data))
    //           history.push("/personaldetails")
    //         }
           
    //       })
    //       .catch(err => {
    //         // Log error
    //         dispatch(actions.getCountryFailed("This Service is not curently available. please try again later."))
    //       })
    //       .then(() => done());
    //   })

    //HOT FIX :: Hard coded country
    let data ={country_name: "Sri Lanka"}
    dispatch(actions.getCountrySuccess(data))
    history.push("/personaldetails")
  }
})

const getAuthcode = createLogic({
  type: types.GET_AUTH_CODE,
  latest: true,
  debounce: 1000,

  process({ MockHTTPClient, getState, action }, dispatch, done) {
    let HTTPClient
    if (MockHTTPClient) {
      HTTPClient = MockHTTPClient
    } else {
      HTTPClient = API
    }

    HTTPClient.Get(`${endPoints.GET_AUTH_CODE}${action.payload}`)
      .then(resp => resp.data)
      .then((data) => {
        dispatch(actions.getAuthcodeSuccess(data))
      })
      .catch(err => {
        // Log error
        dispatch(actions.getAuthcodeFailed(err.response.data.message || "Something went wrong, please contact your service provider"))
      })
      .then(() => done()); // call done when finished dispatching
  }
})

export default [getConnectiontypes, getDistricts, nonSltDetails, submitUserAccount, personaldetails, submitOtp, resendOtp, getCountry, getAuthcode]
