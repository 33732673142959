import React, { Component } from 'react';

import './footer.scss';

export default class Footer extends Component {
  render() {
    return (
      <footer className="footer-landing  mt-auto"/>
    )
  }
}
