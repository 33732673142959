// Redux-login service

import {
    createLogic
} from 'redux-logic';
import actions from './actions';
import types from './types';
import endPoints from './../../../util/EndPoints';
import API from "./../../../util/HTTPClient";
import featuredActions from '../../landingPage/ducks/actions';
import { getLoggedInStatus, purchaseStatusValidation } from '../../../util/ArrayValidator';
import { PREPAID_SERVICE_TYPE, MOBITEL_SERVICE_TYPE, TRANSACTIONAL, PAYMENT_SUCCESS } from "../../../util/Constants";

export default [
    createLogic({
        type: types.GET_PRODUCT,
        latest: true,
        debounce: 1000,
        process({
            MockHTTPClient,
            getState,
            action
        }, dispatch, done) {
            let HTTPClient;
            if (MockHTTPClient) {
                HTTPClient = MockHTTPClient;
            } else {
                HTTPClient = API;
            }
            if (getLoggedInStatus() === false) {
                HTTPClient.Get(`${endPoints.GET_PRODUCT}${action.payload}`)
                    .then(res => res.data)
                    .then(data => dispatch(actions.getProductSuccess(data)))
                    .catch(err => dispatch(actions.getProductFailed(err)))
                    .then(() => done()); // call done when finished dispatching
            } else {
                let endPoint
                if (action.payload.dataBundle) {
                    endPoint = `${endPoints.GET_DATA_BUNDLE}?conId=${action.payload.connectionId}&productId=${action.payload.productId}`
                } else if (action.payload.subProduct) {
                    endPoint = `${endPoints.GET_PRODUCT}${action.payload.productId}`
                } else {
                    endPoint = `${endPoints.PURCHASED_PRODUCTS}${action.payload.productId}?conid=${action.payload.connectionId}`
                }
                HTTPClient.Get(endPoint)
                    .then(res => res.data)
                    .then(data => {
                        let purchaseOptionGroupId = data.purchaseOption && data.purchaseOption.purchaseOptionGroupId
                        dispatch(actions.getProductSuccess(data))
                        dispatch(actions.getPurchaseTable(purchaseOptionGroupId))
                    })
                    .catch(err => dispatch(actions.getProductFailed(err)))
                    .then(() => done()); // call done when finished dispatching
            }
        }
    }),
    createLogic({
        type: types.PRODUCT_UNSUBSCRIBE,
        latest: true,
        debounce: 1000,
        process({
            MockHTTPClient,
            getState,
            action
        }, dispatch, done) {
            function delay(t, v) {
                return new Promise(function (resolve) {
                    setTimeout(resolve.bind(null, v), t)
                });
            }
            let HTTPClient;
            if (MockHTTPClient) {
                HTTPClient = MockHTTPClient;
            } else {
                HTTPClient = API;
            }
            let endPoint
            let payload
            let serviceType = localStorage.serviceType
            if (serviceType === PREPAID_SERVICE_TYPE || serviceType === MOBITEL_SERVICE_TYPE) {
                let connectionId = localStorage.conID
                let productId = action.payload.productId
                let payloadServiceType = serviceType === PREPAID_SERVICE_TYPE ? "prepaid" : "mobitel"
                let productIdPayload = serviceType === PREPAID_SERVICE_TYPE ? `&purchaseId=${productId}` : `&productId=${productId}`
                endPoint = serviceType === PREPAID_SERVICE_TYPE ? endPoints.PREPAID_PRODUCT_PURCHASE : endPoints.MOBITEL_PRODUCT_SUBSCRIPTION
                payload = `?connectionId=${connectionId}${productIdPayload}&connectionType=${payloadServiceType}`

                HTTPClient.Delete(endPoint + payload)
                    .then(res => res.status === 200)
                    .then(async res => {
                        await delay(3000)
                        return dispatch(actions.verifyProductUnsub({
                            productId: action.payload.productId,
                            connectionId: localStorage.conID,
                            res: res
                        }))
                    })
                    .catch(err => {
                        // Log error
                        err && err.response && err.response.data && dispatch(actions.productUnsubscribeFailed(err.response.data.message || "Something went wrong, please contact your service provider"))
                    })
                    .then(() => done()); // call done when finished dispatching
            }
            else {

                endPoint = endPoints.PRODUCT_UNSUBSCRIBE
                payload = action.payload;

                HTTPClient.Post(endPoint, payload)
                    .then(res => res.status === 200)
                    .then(async res => {
                        await delay(3000)
                        return dispatch(actions.verifyProductUnsub({
                            productId: action.payload.productId,
                            connectionId: localStorage.conID,
                            res: res,
                            dataBundle : action.payload.dataBundle
                        }))
                    })
                    .catch(err => dispatch(actions.productUnsubscribeFailed(err && err.response && err.response.data && err.response.data.message && err.response.data.message) || "Something went wrong, please contact your service provider"))
                    .then(() => done()); // call done when finished dispatching    
            }
        }
    }),
    createLogic({
        type: types.PRODUCT_SUBSCRIBE,
        latest: true,
        debounce: 1000,
        process({
            MockHTTPClient,
            getState,
            action
        }, dispatch, done) {

            function delay(t, v) {
                return new Promise(function (resolve) {
                    setTimeout(resolve.bind(null, v), t)
                });
            }
            let serviceType = localStorage.serviceType
            let endPoint
            let payload = {}
            let { productPOGroupId, selectedPurchaseOptionGID } = action.payload;
            if (selectedPurchaseOptionGID === productPOGroupId) {
                if (serviceType === PREPAID_SERVICE_TYPE) {
                    endPoint = endPoints.PREPAID_PRODUCT_PURCHASE
                    payload.purchaseOptionId = action.payload.purchaseOption
                    payload.currency = "LKR"
                    payload.amount = action.payload.amount
                    payload.productId = action.payload.productId
                    payload.productTypeId = action.payload.productTypeId
                    payload.userId = localStorage.userId
                    payload.userConnectionId = action.payload.connectionId
                    payload.userConnectionType = serviceType
                    payload.userTelephoneNumber = localStorage.userTelephoneNumber
                    payload.pin = action.payload.pin

                } else if (serviceType === MOBITEL_SERVICE_TYPE) {
                    endPoint = endPoints.MOBITEL_PRODUCT_SUBSCRIPTION
                    payload.purchaseOptionId = action.payload.purchaseOption
                    payload.currency = "LKR"
                    payload.amount = action.payload.amount
                    payload.productId = action.payload.productId
                    payload.userId = localStorage.userId
                    payload.userConnectionId = action.payload.connectionId
                    payload.userConnectionType = serviceType
                    payload.pin = action.payload.pin
                }
                else {
                    endPoint = endPoints.PRODUCT_SUBSCRIBE
                    payload = action.payload
                }
                let HTTPClient;
                if (MockHTTPClient) {
                    HTTPClient = MockHTTPClient;
                } else {
                    HTTPClient = API;
                }
                if (serviceType !== PREPAID_SERVICE_TYPE && serviceType !== MOBITEL_SERVICE_TYPE) {
                    HTTPClient.Post(endPoints.PRODUCT_SUBSCRIBE, action.payload)
                        .then(res => res.status === 200)
                        .then(async res => {
                            if (serviceType === PREPAID_SERVICE_TYPE) {
                                dispatch(actions.productSubscribeSuccess(res))
                            }
                            else {
                                let isBroadband = localStorage.getItem("isBroadBand")
                                if(action.payload.dataBundle && isBroadband){
                                    dispatch(actions.getDataBundlePurchaseStatus({
                                        productId: action.payload.productId,
                                        connectionId: localStorage.conID,
                                        res: res,
                                        dataBundle : action.payload.dataBundle
                                    }));
                                } else {
                                    await delay(3000);
                                    return dispatch(actions.verifyProductPurchase({
                                        productId: action.payload.productId,
                                        connectionId: localStorage.conID,
                                        res: res,
                                        dataBundle : action.payload.dataBundle
                                    }));
                                }
                            }
                        })
                        .catch(err => dispatch(actions.productSubscribeFailed(err && err.response && err.response.data && err.response.data.message && err.response.data.message) || "Something went wrong, please contact your service provider"))
                        .then(() => done()); // call done when finished dispatching

                }
                else if (serviceType === MOBITEL_SERVICE_TYPE) {
                    HTTPClient.Post(endPoint, payload)
                        .then(resp => resp.data)
                        .then(data => {
                            var type = null
                            switch (action.payload.defaultPurchaseOptionType) {
                                case "PURCHASE_TYPE_SUBSCRIPTION":
                                    type = "Subscription"
                                    break;
                                default:
                                    type = "Purchase"
                            }
                            if (data.paymentStatus === "FAILURE" && data.paymentStatus !== null) {
                                data.message = `${data.description}`
                                dispatch(actions.productSubscribeFailed(data.message))
                            } else if (data.status === "SUCCESS" && data.paymentStatus === null) {
                                data.message = data.description
                                data.retry = true
                                dispatch(actions.productSubscribeSuccess({ transactionDetails: data, status: "transaction" }));
                                dispatch(actions.verifyProductPurchase({
                                    productId: action.payload.productId,
                                    connectionId: localStorage.conID,
                                    res: data
                                }));
                            } else {
                                data.message = `${type} Successful`
                                dispatch(actions.getReferenceNumberSuccess({ referenceId: data.referenceNo }))
                                dispatch(actions.productSubscribeSuccess({ transactionDetails: data, status: "transaction" }));
                                dispatch(actions.verifyProductPurchase({
                                    productId: action.payload.productId,
                                    connectionId: localStorage.conID,
                                    res: data
                                }));
                            }


                        })
                        .catch(err => dispatch(actions.productSubscribeFailed(err && err.response && err.response.data && err.response.data.message && err.response.data.message) || "Something went wrong, please contact your service provider"))
                        .then(() => done()); // call done when finished dispatching
                }

                else {
                    HTTPClient.Post(endPoint, payload)
                        .then(resp => resp.data)
                        .then(data => {
                            dispatch(actions.productSubscribeSuccess({ transactionDetails: data, status: "transaction" }));

                        })
                        .catch(err => dispatch(actions.productSubscribeFailed(err && err.response && err.response.data && err.response.data.message && err.response.data.message) || "Something went wrong, please contact your service provider"))
                        .then(() => done()); // call done when finished dispatching
                }
            } else {
                dispatch(actions.productSubscribeFailed("Error purchasing the product. Please refresh the browser and try again."))
            }
        }
    }),

    createLogic({
        type: types.GET_PURCHASE_TABLE,
        latest: true,
        debounce: 1000,

        process({
            MockHTTPClient,
            getState,
            action
        }, dispatch, done) {
            let HTTPClient;
            if (MockHTTPClient) {
                HTTPClient = MockHTTPClient;
            } else {
                HTTPClient = API;
            }
            HTTPClient.Get(endPoints.GET_PURCHASE_TABLE + action.payload)
                .then(resp => resp.data)
                .then(data => {
                    return data;
                })
                .then(purchaseTable => {
                    dispatch(actions.getPurchaseTableSuccess(purchaseTable))
                })
                .catch(err => {
                    var errorMessage = "Failed to get prerequisites";
                    if (err && err.code === "ECONNABORTED") {
                        errorMessage = "Please check your internet connection.";
                    }
                    dispatch(
                        actions.getPurchaseTableFailed({
                            title: "Error!",
                            message: errorMessage,
                            respErr: err.message
                        })
                    );
                })
                .then(() => done());
        }
    }),
    createLogic({
        type: types.VERIFY_PRODUCT_PURCHASE,
        latest: true,
        debounce: 1000,
        process({
            MockHTTPClient,
            getState,
            action
        }, dispatch, done) {
            let HTTPClient;
            if (MockHTTPClient) {
                HTTPClient = MockHTTPClient;
            } else {
                HTTPClient = API;
            }
            let endPoint
            if (action.payload.dataBundle) {
                endPoint = `${endPoints.GET_DATA_BUNDLE}?conId=${action.payload.connectionId}&productId=${action.payload.productId}`
            } else {
                endPoint = `${endPoints.PURCHASED_PRODUCTS}${action.payload.productId}?conid=${action.payload.connectionId}`
            }
            HTTPClient.Get(endPoint)
                .then(res => res.data)
                .then(data => {
                    let mobitelResub = action.payload.res && action.payload.res.retry
                    let purchaseTypeStatus = purchaseStatusValidation(data.purchaseStatus)
                    let paymentStatus = data.paymentStatus === PAYMENT_SUCCESS
                    var type = null
                    switch (data.purchaseOption && data.purchaseOption.purchaseType) {
                        case TRANSACTIONAL:
                            type = "Purchase"
                            break;
                        default:
                            type = "Subscription"

                    }
                    dispatch(actions.productSubscribeSuccess({
                        data: data,
                        message: mobitelResub === true ? action.payload.res.message : (purchaseTypeStatus.productPurchased || paymentStatus)? `${type} Successful` : `${type} Failed`
                    }))
                    dispatch(featuredActions.productPurchaseContent(data));
                    dispatch(actions.verifyProductPurchaseSuccess(mobitelResub === true ? action.payload.res.message : (purchaseTypeStatus.productPurchased || paymentStatus) ? `${type} Successful` : `${type} Failed`))
                })
                .catch(err => {
                    dispatch(actions.productSubscribeFailed(err && err.response && err.response.data && err.response.data.message && err.response.data.message) || "Something went wrong, please contact your service provider")
                    dispatch(actions.verifyProductPurchaseFailed());
                })
                .then(() => done()); // call done when finished dispatching            
        }
    }),

    createLogic({
        type: types.VERIFY_PRODUCT_UNSUB,
        latest: true,
        debounce: 1000,
        process({
            MockHTTPClient,
            getState,
            action
        }, dispatch, done) {
            let HTTPClient;
            if (MockHTTPClient) {
                HTTPClient = MockHTTPClient;
            } else {
                HTTPClient = API;
            }
            
            let endPoint
            if (action.payload.dataBundle) {
                endPoint = `${endPoints.GET_DATA_BUNDLE}?conId=${action.payload.connectionId}&productId=${action.payload.productId}`
            } else {
                endPoint = `${endPoints.PURCHASED_PRODUCTS}${action.payload.productId}?conid=${action.payload.connectionId}`
            }
            HTTPClient.Get(endPoint)
                .then(res => res.data)
                .then(data => {
                    let purchaseTypeStatus = purchaseStatusValidation(data.purchaseStatus)
                    if(data.productTypeTypeName === "databundle"){
                        if( purchaseTypeStatus.productPurchased === true ){
                            dispatch(actions.getDataBundlePurchaseStatus({
                                connectionId: localStorage.conID,
                                unsub: true,
                                productId: action.payload.productId
                            }))
                        } else if( purchaseTypeStatus.productPendingRemove || purchaseTypeStatus.productNotPurchased ){
                            dispatch(actions.productUnsubscribeSuccess({
                                data: data,
                                message: (purchaseTypeStatus.productPendingRemove || purchaseTypeStatus.productNotPurchased) ? "Unsubscription Successful" : "Unsubscription Failed. Please try again later."
                            }))
                            dispatch(featuredActions.productPurchaseContent(data));
                            dispatch(actions.verifyProductUnsubSuccess((purchaseTypeStatus.productPendingRemove || purchaseTypeStatus.productNotPurchased) ? "Unsubscription Successful" : "Unsubscription Failed. Please try again later."));
                        }
                    } else {
                        dispatch(actions.productUnsubscribeSuccess({
                            data: data,
                            message: (purchaseTypeStatus.productPendingRemove || purchaseTypeStatus.productNotPurchased) ? "Unsubscription Successful" : "Unsubscription Failed. Please try again later."
                        }))
                        dispatch(featuredActions.productPurchaseContent(data));
                        dispatch(actions.verifyProductUnsubSuccess((purchaseTypeStatus.productPendingRemove || purchaseTypeStatus.productNotPurchased) ? "Unsubscription Successful" : "Unsubscription Failed. Please try again later."));
                    }
                })
                .catch(err => {
                    dispatch(actions.verifyProductUnsubFailed(err && err.response && err.response.data && err.response.data.message && err.response.data.message) || "Something went wrong, please contact your service provider")
                    dispatch(actions.verifyProductUnsubFailed());
                })
                .then(() => done()); // call done when finished dispatching 
        }
    }),
    createLogic({
        type: types.GET_PRODUCT_PURCHASE_STATUS,
        latest: true,
        debounce: 1000,

        process({ MockHTTPClient, getState, action }, dispatch, done) {
            let HTTPClient
            if (MockHTTPClient) {
                HTTPClient = MockHTTPClient
            } else {
                HTTPClient = API
            }
            function delay(t, v) {
                return new Promise(function (resolve) {
                    setTimeout(resolve.bind(null, v), t)
                });
            }
            HTTPClient.Get(`${endPoints.HAS_PAID}${action.payload.transactionId}${endPoints.PAID}`)
                .then(resp => resp.data)
                .then(async data => {
                    dispatch(actions.getReferenceNumber(action.payload.transactionId))
                    dispatch(actions.getPrepaidPurchaseStatusSuccess(data))
                    await delay(3000);
                    return dispatch(actions.verifyProductPurchase({
                        productId: action.payload.productId,
                        connectionId: localStorage.conID
                    }))
                })
                .catch(err => {
                    // Log error
                    err.response && err.response.data && dispatch(actions.getPrepaidPurchaseStatusFailed(err.response.data.message || "Something went wrong, please contact your service provider"));
                    dispatch(actions.verifyProductPurchaseFailed());
                })
                .then(() => done()); // call done when finished dispatching
        }
    }),
    createLogic({
        type: types.GET_PRODUCT_DETAILS_WITH_PURCHASE_OPTION,
        latest: true,
        debounce: 1000,
        process({
            MockHTTPClient,
            getState,
            action
        }, dispatch, done) {
            let HTTPClient;
            if (MockHTTPClient) {
                HTTPClient = MockHTTPClient;
            } else {
                HTTPClient = API;
            }
            HTTPClient.Get(`${endPoints.PURCHASED_PRODUCTS}${action.payload.productId}?conid=${action.payload.connectionId}&purchaseOptionId=${action.payload.purchaseOptionId}`)
                .then(res => res.data)
                .then(data => {
                    dispatch(actions.getProductDetailsWithPurchaseOptionSuccess(data))
                })
                .catch(err => dispatch(actions.getProductDetailsWithPurchaseOptionFailed(err)))
                .then(() => done()); // call done when finished dispatching            
        }
    }),

    createLogic({
        type: types.GET_BUNDLED_PRODUCTS,
        latest: true,
        debounce: 1000,
        process({
            MockHTTPClient,
            getState,
            action
        }, dispatch, done) {
            let HTTPClient;
            if (MockHTTPClient) {
                HTTPClient = MockHTTPClient;
            } else {
                HTTPClient = API;
            }

            HTTPClient.Get(`${endPoints.GET_BUNDLED_PRODUCTS}?basePackageId=${action.payload.basePackageId}&productId=${action.payload.productId}`)
                .then(res => res.data)
                .then(data => dispatch(actions.getBundledProductsSuccess(data)))
                .catch(err => dispatch(actions.getBundledProductsFailed(err)))
                .then(() => done()); // call done when finished dispatching
        }
    }),
    createLogic({
        type: types.GET_REFERENCE_NUMBER,
        latest: true,
        debounce: 1000,
        process({ MockHTTPClient, getState, action }, dispatch, done) {
            let HTTPClient;
            if (MockHTTPClient) {
                HTTPClient = MockHTTPClient;
            } else {
                HTTPClient = API;
            }
            HTTPClient.Get(`${endPoints.GET_MY_PURCHASES}/${action.payload}`)
                .then(res => res.data)
                .then(data => dispatch(actions.getReferenceNumberSuccess(data)))
                .catch(err => dispatch(actions.getReferenceNumberFailed(err)))
                .then(() => done()); // call done when finished dispatching
        }
    }),
    createLogic({
        type: types.GET_DATA_BUNDLE_PURCHASE_STATUS,
        latest: true,
        debounce: 1000,
    
        process({ MockHTTPClient, getState, action }, dispatch, done) {
          let HTTPClient
          if (MockHTTPClient) {
            HTTPClient = MockHTTPClient
          } else {
            HTTPClient = API
          }
    
          HTTPClient.Get(`${endPoints.DATA_BUNDLE_PURCHASE_STATUS}?conId=${action.payload.connectionId}&productId=${action.payload.productId}`)
            .then(resp => resp.data)
            .then((data) => {
                if(action.payload.unsub === true){
                    if(data.trim() !== ''){
                        dispatch(actions.getDataBundlePurchaseStatusFailed({data: data, unsub: action.payload.unsub}))
                    } else {
                        dispatch(actions.getDataBundlePurchaseStatusSuccess(data))
                        dispatch(actions.verifyProductPurchase({
                            productId: action.payload.productId,
                            connectionId: localStorage.conID,
                            res: data,
                            dataBundle : action.payload.dataBundle
                        }));
                    }
                } else {
                    if (data.trim() !== '') {
                      dispatch(actions.getDataBundlePurchaseStatusFailed({data: data, unsub: action.payload.unsub}))
                    } else {
                      dispatch(actions.getDataBundlePurchaseStatusSuccess(data))
                      dispatch(actions.verifyProductPurchase({
                          productId: action.payload.productId,
                          connectionId: localStorage.conID,
                          res: data,
                          dataBundle : action.payload.dataBundle
                      }));
                    }
                }
            })
            .catch(err => {
              // Log error
              err.response && err.response.data && dispatch(actions.getDataBundlePurchaseStatusFailed(err.response.data.message && "Payment cancelled. " || "Something went wrong, please contact your service provider"))
            })
            .then(() => done());
        }
      }),
]